import { Component, Input, OnInit } from '@angular/core';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-number',
    templateUrl: './number.component.html',
})
export class NumberComponent implements OnInit {

    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() keyupEnter;
    @Input() maxlength
    @Input() mantissalength
    @Input() maxWidth

    constructor() {
    }

    ngOnInit() {
    }

    round() {
        if (this.fc.value) {
            if (typeof this.fc.value == "string") {
                this.fc.setValue(parseFloat(this.fc.value))
            }
            this.fc.setValue(this.fc.value.toFixed(this.mantissalength))
        }
    }
}
