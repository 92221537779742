import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-page-controls-button',
    templateUrl: './page-controls-button.component.html',
    styleUrls: ['./page-controls-button.component.scss']
})
export class PageControlsButtonComponent implements OnInit {
    @Input() label;
    @Input() clickFunction;
    @Input() icon;
    @Input() altStyle;
    processing = false;
    faSpinner = faSpinner;
    activated = false

    constructor() { }

    ngOnInit() {
    }

}
