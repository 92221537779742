<div class="row page-title">
    <div class="col">
        Outreach Bank Account
    </div>
</div>

<div *ngIf="!noDataFound; else noDataWarning">
    <div class="header">
        <div class="org-name">{{ headerData.data[0]?.organizationName }}</div>
        <div class="header-details">
        <div class="header-subtitle">Outreach Bank Account</div>
        <div class="header-subtitle">Fiscal Year: {{ headerData.data[0]?.fiscalYearId }}</div>
        </div>
    </div>

    <div class="half-width-table">
        <table mat-table [dataSource]="headerData" class="">  

            <ng-container matColumnDef="budget">
                <td mat-cell *matCellDef="let row" class="bold large-text">Budget</td>
            </ng-container>
        
            <ng-container matColumnDef="childNutrition">
                <td mat-cell *matCellDef="let row" class="large-text">Child Nutrition (CN)/SNAP: {{ row.debitState | currency:'USD':'symbol':'1.0-0' }}</td>
            </ng-container>
        
            <ng-container matColumnDef="cnSnap">
                <td mat-cell *matCellDef="let row" class="large-text">SNAP: {{ row.debitFederal | currency:'USD':'symbol':'1.0-0' }}</td>
            </ng-container>
            
            <tr mat-row *matRowDef="let row; columns: headerDisplayedColumns;"></tr>
        </table>
    </div>

    <div>
        <div class="details-table">
            <table mat-table [dataSource]="detailData" class="mat-elevation-z8" matSort>  
                <ng-container matColumnDef="referenceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Reference # </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.referenceNumber }} </td>
                    <td mat-footer-cell *matFooterCellDef class="bold"> Total </td>
                </ng-container>
            
                <ng-container matColumnDef="county">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> County </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.county }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
            
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.status }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
            
                <ng-container matColumnDef="stateOnly">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header> CN/SNAP $ (State Only) </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.creditStateEst | currency:'USD':'symbol':'1.0-0' }} </td>
                    <td mat-footer-cell *matFooterCellDef class="bold"> {{ calcTotalStateOnlyEst(detailData.data)  | currency:'USD':'symbol':'1.0-0' }} </td>
                </ng-container>
            
                <ng-container matColumnDef="stateFederal">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header> SNAP $ (State/Fed) </th>
                    <td mat-cell *matCellDef="let row"  [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.creditFederalEst | currency:'USD':'symbol':'1.0-0' }} </td>
                    <td mat-footer-cell *matFooterCellDef class="bold" > {{ calcTotalStateFederalEst(detailData.data) | currency:'USD':'symbol':'1.0-0' }} </td>
                </ng-container>
            
                <ng-container matColumnDef="vouchered">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Vouchered </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.vouchered }} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                
                <ng-container matColumnDef="voucherAMNT1">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header> CN/SNAP $ Vouchered </th>
                    <td mat-cell *matCellDef="let row"  [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.stateOnly  | currency:'USD':'symbol':'1.0-0' }} </td>
                    <td mat-footer-cell *matFooterCellDef class="bold" > {{ calcTotalStateOnlyActual(detailData.data) | currency:'USD':'symbol':'1.0-0' }}  </td>
                </ng-container>

                <ng-container matColumnDef="voucherAMNT2">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header> SNAP $ Vouchered </th>
                    <td mat-cell *matCellDef="let row"  [ngClass]="{'green-background': row.vouchered === 'Y'}"> {{ row.stateFederal | currency:'USD':'symbol':'1.0-0' }} </td>
                    <td mat-footer-cell *matFooterCellDef class="bold" > {{ calcTotalStateFederalActual(detailData.data) | currency:'USD':'symbol':'1.0-0' }} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="detailDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: detailDisplayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="detailDisplayedColumns;"></tr>
            </table>
        </div>

        <div class="centered-text">
            <p>
                The “Estimated $ Remaining” column looks at all your anticipated costs in your Outreach
                Requests, and provides an estimated amount left in your Outreach Bank Account. Review this
                column when creating new Outreach Requests.
            </p>
            <p>
                The “Actual $ Remaining (Post Voucher)” column looks only at all Outreach Requests that have
                been vouchered for by your fiscal department. It shows the actual dollars left.
            </p>
            <p>            
                Hunger Solutions New York is providing the Outreach Bank Account as a guide only. Please check
                with your fiscal department before purchasing any items.
            </p>
        </div>

        <div class="table-container">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Estimated $ Remaining</th>
                        <th>Actual $ Remaining (Post Voucher)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CN/SNAP</td>
                        <td>{{ estimatedRemainingStateOnly | currency:'USD':'symbol':'1.0-0' }}</td>
                        <td>{{ actualRemainingStateOnly | currency:'USD':'symbol':'1.0-0' }}</td>
                    </tr>
                    <tr>
                        <td>SNAP</td>
                        <td>{{ estimatedRemainingStateFederal | currency:'USD':'symbol':'1.0-0' }}</td>
                        <td>{{ actualRemainingStateFederal | currency:'USD':'symbol':'1.0-0' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #noDataWarning>
    <div>
        <p>There is no data available.</p>
    </div>
</ng-template>
