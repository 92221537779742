import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CrudService } from '../crud.service';
import { faBan, faDatabase, faExclamationTriangle, faMinus, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackRouteService } from '../snack-route.service';
import { v4 as uuid } from 'uuid';
import { tap } from 'rxjs/operators';

export enum DisplayType {
    fk,
    date,
    textArea,
    number,
    text
}

@Component({
    selector: 'app-crud',
    templateUrl: './crud.component.html',
})
export class CrudComponent implements OnInit {
    initialized = false
    localStorageTypeName = "hsny_crud_type"
    typeFC = new UntypedFormControl(localStorage.getItem(this.localStorageTypeName))
    faPlus = faPlus
    faDatabase = faDatabase

    currentPage = 1
    count
    saveError

    entriesPerPageOptions = [
        { id: 10, name: "10" },
        { id: 25, name: "25" },
        { id: 50, name: "50" },
        { id: 100, name: "100" },
    ]

    entriesPerPageFC = new UntypedFormControl(10)

    sortField
    sortIsDescending

    setPage(page) {
        this.currentPage = page
        this.fetchData()
    }

    constructor(
        private crudService: CrudService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
    ) { }

    types
    entries
    referencedTypes = {}

    //TODO - referencedTypes cache needs to key off both type name and column name
    //TODO - skip the query if the type and column have already been cached
    //todo - add limit of 101? then if more than 100 options, show text input with note that very large fk exists
    getReferencedType(typeName, columnName) {
        return new Promise((resolve, reject) => {

            //todo - add limit of 101?

            this.crudService.put({ type: typeName }).subscribe((response) => {
                //console.log("ref type response", response)
                if (response.success) {
                    var enumNameAndId = []
                    for (var i = 0; i < response.result.length; ++i) {
                        enumNameAndId.push({
                            id: response.result[i][columnName],
                            name: response.result[i][columnName],
                        })
                    }

                    this.referencedTypes[typeName] = {
                        entries: response.result,
                        enumNameAndId
                    }
                }
            })
        })
    }

    fetchData() {
        this.crudService.put({
            type: this.typeFC.value,
            limit: this.entriesPerPageFC.value,
            offset: (this.currentPage - 1) * this.entriesPerPageFC.value,
            sort: [{
                columnName: this.sortField,
                isDescending: this.sortIsDescending,
            }]
        }).subscribe((response) => {
            //console.log("get response", response)
            if (response.success) {
                this.count = response.count
                this.entries = response.result
            } else {
                this.entries = null
            }
        })
    }

    typeChange() {
        localStorage.setItem(this.localStorageTypeName, this.typeFC.value)
        this.currentPage = 1

        this.entries = null

        if (this.typeFC.value && this.types[this.typeFC.value]) {
            var columns = this.types[this.typeFC.value].definition

            for (var i = 0; i < columns.length; ++i) {
                if (columns[i].referenced_table_name) {
                    this.getReferencedType(columns[i].referenced_table_name, columns[i].referenced_column_name)
                }
            }

            this.sortField = columns[0].Field
            this.sortIsDescending = false

            this.fetchData()
        }
    }

    entriesPerPageChange() {
        this.setPage(1)
    }

    sortClick(sortField) {
        if (this.sortField == sortField) {
            this.sortIsDescending = !this.sortIsDescending
        } else {
            this.sortField = sortField
            this.sortIsDescending = false
        }
        this.fetchData()
    }

    typesNameAndId = []
    noKey = {}

    isColumnTypeAlignedRight(type) {
        if (type
            && (type.startsWith('decimal')
                || type.startsWith('int')
                || type.startsWith('tinyint'))) {
            return true
        }
        return false
    }

    showFieldAsFK(column) {
        return column.referenced_table_name && this.referencedTypes[column.referenced_table_name]
    }

    showFieldAsDate(column) {
        return !this.showFieldAsFK(column) && column.Type == 'datetime'
    }

    showFieldAsTextArea(column) {
        if (this.showFieldAsFK(column)) {
            return false
        }
        const typePrefix = "varchar("
        if (!column.Type.startsWith(typePrefix)) {
            return false
        }
        var lenString = column.Type.substring(typePrefix.length, column.Type.indexOf(")", typePrefix.length))
        if (parseInt(lenString) > 256) {
            return true
        }
        return false
    }

    showFieldAsNumber(column) {
        if (this.showFieldAsFK(column)) {
            return false
        }
        return column.Type.startsWith("int(") || column.Type.startsWith("decimal(")
    }

    showFieldAsText(column) {
        return !this.showFieldAsFK(column) && !this.showFieldAsDate(column) && !this.showFieldAsTextArea(column) && !this.showFieldAsNumber(column)
    }

    getPromise() {
        return new Promise((resolve, reject) => {
            this.crudService.getSchema().subscribe((response) => {
                console.log("schema", response)
                this.types = response.result
                for (var key in response.result) {
                    if (response.result.hasOwnProperty(key)) {
                        this.typesNameAndId.push({ name: key.replace(/_/g, " "), id: key })
                        for (var i = 0; i < response.result[key].definition.length; ++i) {
                            response.result[key].definition[i].createFC = new UntypedFormControl()
                            if (this.isColumnTypeAlignedRight(response.result[key].definition[i].Type)) {
                                response.result[key].definition[i].align = "right"
                            }
                            ["varchar(", "int("].forEach((item) => {
                                if (response.result[key].definition[i].Type.startsWith(item)) {
                                    response.result[key].definition[i].length = response.result[key].definition[i].Type.substring(item.length,
                                        response.result[key].definition[i].Type.indexOf(")"))
                                }
                            })
                            if (response.result[key].definition[i].Type.startsWith("decimal(")) {
                                response.result[key].definition[i].length = response.result[key].definition[i].Type.substring("decimal(".length,
                                    response.result[key].definition[i].Type.indexOf(","))
                                response.result[key].definition[i].mantissalength = response.result[key].definition[i].Type.substring(
                                    response.result[key].definition[i].Type.indexOf(",") + 1,
                                    response.result[key].definition[i].Type.indexOf(")"))
                            }

                            if (this.showFieldAsFK(response.result[key].definition[i])) {
                                response.result[key].definition[i].displayType = DisplayType.fk
                            }
                            if (this.showFieldAsDate(response.result[key].definition[i])) {
                                response.result[key].definition[i].displayType = DisplayType.date
                            }
                            if (this.showFieldAsTextArea(response.result[key].definition[i])) {
                                response.result[key].definition[i].displayType = DisplayType.textArea
                            }
                            if (this.showFieldAsNumber(response.result[key].definition[i])) {
                                response.result[key].definition[i].displayType = DisplayType.number
                            }
                            if (this.showFieldAsText(response.result[key].definition[i])) {
                                response.result[key].definition[i].displayType = DisplayType.text
                            }
                        }
                    }
                }
                this.typesNameAndId.sort(function (a, b) {
                    if (a.id < b.id) {
                        return -1;
                    }
                    if (a.id > b.id) {
                        return 1;
                    }
                    return 0;
                });
                if (response.noKey) {
                    for (var i = 0; i < response.noKey.length; ++i) {
                        this.noKey[response.noKey[i]] = true
                    }
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        this.getPromise().then(() => {
            if (this.typeFC.value) {
                this.typeChange()
            }
            this.initialized = true
        })
    }

    showNewButton() {
        return !this.noKey[this.typeFC.value]
    }

    cancel() {
    }

    clear() {
        for (var i = 0; i < this.types[this.typeFC.value].definition.length; ++i) {
            this.types[this.typeFC.value].definition[i].createFC.setValue(null)
        }
    }

    new() {
        this.clear()
        // this.shouldToggle = "true"
        // this.inputState = InputState.new

        const dialogRef = this.dialog.open(CrudDialog, {
            panelClass: 'add-account-dialog-container',
            data: {
                types: this.types,
                typeFC: this.typeFC,
                referencedTypes: this.referencedTypes,
                crud: this,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            //     this.create()
            // } else {
            //     this._snackBar.open("Action cancelled", null, {
            //         duration: 4000,
            //     });
            // }
        });
    }

    entryClick(entry) {
        if (this.noKey[this.typeFC.value]) {
            return
        }
        // for (var i = 0; i < this.types[this.typeFC.value].definition.length; ++i) {
        //     this.types[this.typeFC.value].definition[i].createFC.setValue(entry[this.types[this.typeFC.value].definition[i].Field])
        // }
        // this.inputState = InputState.edit
        // this.shouldToggle = "true"
        const dialogRef = this.dialog.open(CrudDialog, {
            panelClass: 'add-account-dialog-container',
            data: {
                types: this.types,
                typeFC: this.typeFC,
                referencedTypes: this.referencedTypes,
                value: entry,
                crud: this
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            // if (result) {
            //     this.update()
            // } else {
            //     this._snackBar.open("Action cancelled", null, {
            //         duration: 4000,
            //     });
            // }
        });

    }

    submitChange(operation) {
        this.saveError = null
        var entry: any = {}
        for (var i = 0; i < this.types[this.typeFC.value].definition.length; ++i) {
            var value = this.types[this.typeFC.value].definition[i].createFC.value
            if (this.types[this.typeFC.value].definition[i].Type == "datetime" && value) {
                value = value.replace("T", " ").replace("Z", "")
            }
            entry[this.types[this.typeFC.value].definition[i].Field] = value
        }

        return this.crudService.applyOperations({
            entries: [{
                type: this.typeFC.value,
                operation,
                value: entry,
            }]
            // }).subscribe((response) => {
            //     if (!response.success) {
            //         this.saveError = response.message
            //         console.log("error while submitting change", response)
            //         this._snackBar.open("Error occurred: " + response.message, null, {
            //             duration: 4000,
            //         });
            //         return
            //     }
            //     this._snackBar.open("Operation complete", null, {
            //         duration: 4000,
            //     });
            //     this.fetchData()
        }).pipe(tap((response) => {
            this.fetchData()
        }))
    }

    create() {
        return this.submitChange("insert")
    }

    update() {
        return this.submitChange("update")
    }

    delete() {
        return this.submitChange("delete")
    }
}

@Component({
    selector: 'crud-dialog',
    templateUrl: './crud-dialog.html',
})
export class CrudDialog {
    newEntry
    DisplayType = DisplayType

    faExclamationTriangle = faExclamationTriangle
    types
    typeFC
    referencedTypes

    constructor(
        public dialogRef: MatDialogRef<CrudDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackRoute: SnackRouteService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,

    ) {
        this.types = data.types
        this.typeFC = data.typeFC
        this.referencedTypes = data.referencedTypes

        if (!data.value) {
            this.newEntry = true
        } else {
            this.newEntry = false
            for (var i = 0; i < this.types[this.typeFC.value].definition.length; ++i) {
                this.types[this.typeFC.value].definition[i].createFC.setValue(data.value[this.types[this.typeFC.value].definition[i].Field])
            }

            //
        }
    }
    faTimes = faTimes
    faBan = faBan
    faMinus = faMinus
    faSave = faSave

    cancel() {
        //this.snackRoute.snack("Action cancelled.")
        this.dialogRef.close();
    }

    delete() {
        const dialogRef = this.dialog.open(CrudDeleteConfirmationDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
                types: this.types,
                typeFC: this.typeFC,
                referencedTypes: this.referencedTypes,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.data.crud.delete().subscribe((response) => {
                    if (!response.success) {
                        //this.saveError = response.message
                        console.log("error while submitting change", response)
                        this._snackBar.open("Error occurred: " + response.message, null, {
                            duration: 4000,
                        });
                        return
                    }
                    this._snackBar.open("Operation complete", null, {
                        duration: 4000,
                    });
                    //this.fetchData()
                })

                this.dialogRef.close();
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }

    showCreateButton() {
        return this.newEntry
    }

    showClearButton() {
        return this.newEntry
    }

    showUpdateButton() {
        return !this.newEntry
    }

    showFieldGenerateUuid(column) {
        return this.newEntry && column.Type == "varchar(36)" && !column.createFC.value
    }

    fieldGenerateUuid(column) {
        column.createFC.setValue(uuid())
    }

    clear() {
        this.data.crud.clear()
    }

    update() {
        this.data.crud.update().subscribe((response) => {
            if (!response.success) {
                //this.saveError = response.message
                console.log("error while submitting change", response)
                this._snackBar.open("Error occurred: " + response.message, null, {
                    duration: 4000,
                });
                return
            }
            this._snackBar.open("Operation complete", null, {
                duration: 4000,
            });
            this.dialogRef.close({});
        })
    }

    create() {
        this.data.crud.create().subscribe((response) => {
            if (!response.success) {
                //this.saveError = response.message
                console.log("error while submitting change", response)
                this._snackBar.open("Error occurred: " + response.message, null, {
                    duration: 4000,
                });
                return
            }
            this._snackBar.open("Operation complete", null, {
                duration: 4000,
            });
            this.dialogRef.close({});
            //this.fetchData()
        })

    }
}

@Component({
    selector: 'crud-delete-confirmation-dialog',
    templateUrl: './crud-delete-confirmation-dialog.html',
})
export class CrudDeleteConfirmationDialog {
    faExclamationTriangle = faExclamationTriangle
    constructor(
        public dialogRef: MatDialogRef<CrudDeleteConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackRoute: SnackRouteService,
    ) { }
    faTimes = faTimes
    faBan = faBan
    faMinus = faMinus

    cancel() {
        //this.snackRoute.snack("Action cancelled.")
        this.dialogRef.close();
    }

    delete() {
        this.dialogRef.close({});
    }
}
