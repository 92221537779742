<div style="position: relative; white-space: nowrap; cursor: pointer;">

    <span class="py-1 px-1 split-button-item" style="border-radius: .2em 0 0 .2em; border-width: 1px; border-style: solid;"
        (click)="clickAction(options[0])">
        <fa-icon [icon]="options[0].icon"></fa-icon>
        {{options[0].display}}
    </span>
    <span class="py-1 px-1 split-button-item"
        style="border-radius: 0 .2em .2em 0; border-width: 1px 1px 1px 0; border-style: solid;"
        (click)="toggleDropdown()">
        <fa-icon [icon]="faChevronDown">
        </fa-icon>
    </span>

    <span class="userDropdownMenu" #dropdown style="display: none;">
        <div *ngFor="let option of subOptions" class="userDropdownItem user-dropdown-border-top"
            (click)="clickAction(option)">
            <fa-icon [icon]="option.icon"></fa-icon>
            {{option.display}}
        </div>
    </span>
</div>