<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faFile"></fa-icon>
            Document List
        </div>
    </div>

    <div class="row py-1">
        <div class="col" align="right">
            <app-button3 class="mx-1" label="Add" [clickFunction]="add.bind(this)" [icon]="faPlus" [small]="true">
            </app-button3>
        </div>
    </div>

    <div class="row py-1">
        <div class="col" align="right">
            Program
            <app-input-enum label="Program" [fc]="programFC" [enumValuesNameAndId]="programs" [style]="'none'"
                [change]="changeProgram.bind(this)">
            </app-input-enum>
        </div>
    </div>

    <span *ngFor="let item of listing; let i = index">
        <span *ngIf="i == 0 || listing[i - 1].submission_type_id != item.submission_type_id">
            <div class="row">
                <div *ngIf="item.submission_type_name" class="col bold">
                    {{item.submission_type_name}}
                </div>
                <div *ngIf="!item.submission_type_name" class="col bold">
                    -None-
                </div>
            </div>
            <div class="row">
                <div class="col-2">
                </div>
                <div class="col-4" style="background-color: lightgrey;" align="center">
                    Document Name
                </div>
                <div class="col-2" style="background-color: lightgrey;" align="center">
                    Required
                </div>
                <div class="col-4" style="background-color: lightgrey;" align="center">
                    Reoccurs
                </div>
            </div>
        </span>
        <div class="row">
            <div class="col-2">
            </div>
            <div class="col-4 bold">
                <a routerLink="/document-master" [queryParams]="{ documentUuid: item.uuid }">{{item.name}}</a>
            </div>
            <div class="col-2" align="center">
                <fa-icon *ngIf="item.required" [icon]="faTimes"></fa-icon>
            </div>
            <div class="col-4">
                {{item.reoccur_description}}
            </div>
        </div>
    </span>
</div>