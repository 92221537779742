import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    @Input() label;
    @Input() clickFunction;
    @Input() clickRoute;
    @Input() clickRouteParams;
    @Input() icon;
    @Input() title;
    @Input() disabled;
    @Input() selected;
    @Input() unselected;
    @Input() small;
    @Input() skinny;

    //     Final (finish, submit, OK) = solid background
    // Delete = Solid light red background
    // Disabled = light grey coloring
    // All other (cancel, next, pervious) = same color as final (green) but white (transparent background)

    @Input() isFinal;
    @Input() isDelete;

    processing = false;
    faSpinner = faSpinner;
    activated = false

    constructor(
        private router: Router,
    ) {
    }

    ngOnInit() {
    }

    click() {
        if (this.clickFunction) {
            this.clickFunction()
        } else if (this.clickRoute) {
            this.router.navigate([this.clickRoute], { queryParams: this.clickRouteParams })
        }
    }

}

