import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CognitoFormsService } from '../cognito-forms.service';
import { CrudService } from '../crud.service';

@Component({
    selector: 'app-wic22-results',
    templateUrl: './wic22-results.component.html',
})
export class Wic22ResultsComponent implements OnInit {
    faFileExport = faFileExport

    constructor(
        private crudService: CrudService,
        private cognitoFormsService: CognitoFormsService,
    ) { }

    headers = []

    typeOf(val) {
        return (typeof (val))
    }

    ngOnInit() {
    }

    download(uuid, filename) {
        this.cognitoFormsService.downloadFile(uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
        });
    }

    entriesPerPageOptions = [
        //{ id: 3, name: "3" },//for testing
        { id: 10, name: "10" },
        { id: 25, name: "25" },
        { id: 50, name: "50" },
        { id: 100, name: "100" },
    ]

    entriesPerPageFC = new UntypedFormControl(10)
    paginationHandlers$ = new BehaviorSubject<any>({});
    sortHandlers$ = new BehaviorSubject<any>({ columnName: "created", isDescending: true });

    results$ = combineLatest(
        this.sortHandlers$,
        this.paginationHandlers$,
    ).pipe(
        switchMap(([sort, paging]) => {
            var sortVal = null
            if (sort && sort.columnName) {
                sortVal = [{ columnName: sort.columnName, isDescending: sort.isDescending }]
            }

            var criteria = {
                where: "form_number = ? and replaced = ?",
                whereArgs: ["2", false],
                sort: sortVal,
                type: "data_cognito_forms_value { data_cognito_forms_value_file }",
                limit: this.entriesPerPageFC.value,
                offset: (this.currentPage - 1) * this.entriesPerPageFC.value,
            }

            return this.crudService.put(criteria);
        }),
        tap((results) => {
            //console.log("tap", results)
            this.count = results.count

            this.headers = []
            var uniqueHeaders = {}

            for (var i = 0; i < results.result.length; ++i) {
                results.result[i].valueParsed = JSON.parse(results.result[i].value)
                results.result[i].valueFormatted = JSON.stringify(results.result[i].valueParsed, null, 4)

                var value = JSON.parse(results.result[0].value)
                for (var prop in value) {
                    if (value.hasOwnProperty(prop)) {
                        if (prop == "Form"
                            || prop == "Entry"
                            || prop == "Id"
                            || (prop && prop.startsWith("$"))) {
                            continue
                        }

                        if (uniqueHeaders[prop]) {
                            continue
                        }
                        uniqueHeaders[prop] = true

                        var headerLabel = prop.replace(/([A-Z])/g, ' $1')
                        var title = headerLabel
                        if (title.length > 20) {
                            title = title.substring(0, 20) + "..."
                        }
                        this.headers.push({ title: title, label: headerLabel, field: prop })
                    }
                }
            }
        }),
    )

    currentPage = 1
    count

    setPage(page) {
        this.currentPage = page
        this.paginationHandlers$.next(page)
    }

    entriesPerPageChange() {
        this.setPage(1)
    }
}
