import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DashboardService } from '../dashboard.service';
import { SubmissionService } from '../submission.service';

@Component({
    selector: 'app-program-submission-answers',
    templateUrl: './program-submission-answers.component.html',
})
export class ProgramSubmissionAnswersComponent implements OnInit {
    initialized = false
    submissionUuid
    stepUuid
    routeSubscription
    submission$
    submissionStatuses$
    faQuestionCircle = faQuestionCircle

    constructor(
        private activatedRoute: ActivatedRoute,
        private submissionService: SubmissionService,
        private dashboardService: DashboardService
    ) { }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe()
        }
    }

    ngOnInit() {
        this.routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
            this.initialized = false
            this.submissionUuid = params["submissionUuid"]
            this.stepUuid = params["stepUuid"]
            this.initialized = true
        })
        this.submission$ = this.submissionService.fetchSubmissionHeaders(this.submissionUuid);
        this.submissionStatuses$ = this.dashboardService.getStatusesById();
    }
}
