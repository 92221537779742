<div *ngIf="!style" class="row" [ngClass]="calcRowClass">
    <div class="col-4 pb-2 unedit-padding bold">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
    </div>
    <div *ngIf="disabled" class="col-8 pb-2 unedit-padding">
        <span>{{getDisplayValue()}}</span>
    </div>
    <div *ngIf="!disabled && enumValues" class="col-8 pb-2">
        <select class="input-style" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </div>
    <div *ngIf="!disabled && enumValuesNameAndId" class="col-8 pb-2">
        <select class="input-style" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngIf="includeUnset || includeUnsetDisplay" value="null">
                {{includeUnsetDisplay}}
            </option>
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row mt-3">
    <div class="col bold" style="color: darkgrey">
        {{label}}
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row mt-1" style="color: darkgrey">
    <div *ngIf="disabled" class="col pb-2 unedit-padding">
        <span>{{getDisplayValue()}}</span>
    </div>
    <div *ngIf="!disabled && enumValues" class="col pb-2">
        <select class="input-style ml-2" style="vertical-align: top; color: darkgrey;" [formControl]="fc"
            (change)="doChange()" [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </div>
    <div *ngIf="!disabled && enumValuesNameAndId" class="col pb-2">
        <select class="input-style ml-2" style="vertical-align: top; color: darkgrey;" [formControl]="fc"
            (change)="doChange()" [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngIf="includeUnset" value="null">
            </option>
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </div>
</div>

<div *ngIf="style == 'rebrand2-role'" class="row mt-3">
    <div class="col bold">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
    </div>
</div>
<span *ngIf="style == 'rebrand2-role'">
    <span *ngFor="let enumValue of enumValues">
        <div class="row">
            <div class="col-1 px-0" align="right">
                <input class="ml-2" type="radio" [id]="enumValue.id" [name]="attributeIdentifier" [value]="enumValue.id"
                    [formControl]="fc" />
            </div>
            <div class="col-11">
                <label class="mb-0 bold" [for]="enumValue.id" [ngClass]="{'role-selected': fc.value==enumValue.id}"
                    style="vertical-align: top;">{{enumValue.name}}</label>
                <span *ngIf="dateInputs">
                    <span *ngFor="let dateInput of dateInputs">
                        <span *ngIf="enumValue.id == dateInput.id">
                            &nbsp;<app-input-date [fc]="dateInput.fc" [format]="InputDateFormat.none"></app-input-date>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-1">
            </div>
            <div class="col-11" style="font-size: .8em;">
                {{enumValue.comment}}
            </div>
        </div>
    </span>
</span>
<span *ngIf="style == 'radio-style'">
    <div class="row">
        <div class="col-4 bold">
            {{label}}
        </div>
        <div class="col-8 pt-1">
            <span *ngFor="let enumValue of enumValuesNameAndId">
                <div class="row">
                    <div class="col">
                        <input class="mr-2" type="radio" [id]="enumValue.id" [name]="attributeIdentifier"
                            [value]="enumValue.id" [formControl]="fc" />
                        <label class="mb-0 bold" [for]="enumValue.id"
                            [ngClass]="{'role-selected': fc.value==enumValue.id}"
                            style="vertical-align: top;">{{enumValue.name}}</label>
                        <span *ngIf="editValue && editValue == enumValue.id && fc.value == enumValue.id && editClick"
                            (click)="editClick()" class="ml-1 link-rebrand">[edit]</span>
                    </div>
                </div>
            </span>
        </div>
    </div>
</span>
<div *ngIf="style == 'edit-sbl'" class="row mt-1">
    <div *ngIf="disabled" class="col pb-2 unedit-padding">
        <span>{{getDisplayValue()}}</span>
    </div>
    <div *ngIf="!disabled && enumValues" class="col pb-2">
        <select class="input-style" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </div>
    <div *ngIf="!disabled && enumValuesNameAndId" class="col pb-2">
        <select class="input-style" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngIf="includeUnset" value="null">
            </option>
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </div>
</div>

<div *ngIf="style == 'inside-list'" class="row mt-1">
    <div *ngIf="disabled" class="col pb-2 unedit-padding">
        <span>{{getDisplayValue()}}</span>
    </div>
    <div *ngIf="!disabled && enumValues" class="col pb-2">
        <select class="input-style-in-list" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </div>
    <div *ngIf="!disabled && enumValuesNameAndId" class="col pb-2">
        <select class="input-style-in-list" style="vertical-align: top;" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngIf="includeUnset" value="null">
                {{includeUnsetDisplay}}
            </option>
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </div>
</div>

<div *ngIf="style=='none'" [class]="class">
    <span *ngIf="enumValues">
        <select class="input-style" [formControl]="fc" (change)="doChange()"
            [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngFor="let enumValue of enumValues" value={{enumValue}}>
                {{enumValue}}
            </option>
        </select>
    </span>
    <span *ngIf="enumValuesNameAndId">
        <select data-cy="program_dropdown" class="input-style" [formControl]="fc" (change)="doChange()"
            style="min-width: 4em" [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngIf="includeUnset" value="null">
            </option>
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </span>
    <span *ngIf="enumValuesDisplayAndId">
        <select data-cy="program_dropdown" class="input-style" [formControl]="fc" (change)="doChange()"
            style="min-width: 4em" [ngStyle]="{width: naturalWidth ? 'unset':'inherit'}">
            <option *ngIf="includeUnset" value="null">
            </option>
            <option *ngFor="let enumValue of enumValuesDisplayAndId" value={{enumValue.id}}>
                {{enumValue.display_name}}
            </option>
        </select>
    </span>
</div>