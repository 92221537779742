import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DashboardService } from './dashboard.service';
import { SystemService } from './system.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class SubmissionService {

    constructor(
        private http: HttpClient,
        private systemService: SystemService,
        private dashboardService: DashboardService
    ) { }

    voidSubmission(submissionUuid: string, previousUuid: string): Observable<any> {
        console.log(previousUuid)
        return this.http.put<any>("api/voidSubmission/", {submissionUuid: submissionUuid, previousUuid: previousUuid})
    }

    getSubmission(submissionUuid): Observable<any> {
        return this.http.get<any>("api/submission/" + submissionUuid)
    }

    getSubmissionByFiscalUuid(fiscalUuid): Observable<any> {
        return this.http.get<any>("api/submissionByFiscalUuid/" + fiscalUuid)
    }

    getSubmissionPromise(submissionUuid) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.getSubmission(submissionUuid).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            if (subscription) {
                subscription.unsubscribe()
            }
        })
    }

    getSubmissionActivityFeed(submissionUuid): Observable<any> {
        return this.http.get<any>("api/submissionActivityFeed/" + submissionUuid)
    }

    getSubmissionHistory(submissionUuid): Observable<any> {
        return this.http.get<any>("api/submissionHistory/" + submissionUuid)
    }

    saveSubmission(submission): Observable<any> {
        return this.http.post<any>("api/submission", { submission: submission }, httpOptions)
    }

    stageSubmissionAttachment(
        attachment_uuid,
        submission_uuid,
        description,
        file_name,
        mime_type,
        file_size,
        voucher_account_uuid?,
    ): Observable<any> {
        return this.http.put<any>("api/submissionAttachment", {
            attachment_uuid,
            submission_uuid,
            description,
            file_name,
            mime_type,
            file_size,
            voucher_account_uuid,
        }, httpOptions)
    }

    deleteVoucherAttachment(attachmentUuid: string) {
        return this.http.delete<any>("api/voucherAttachmentDelete/" + attachmentUuid)
    }

    deleteSubmissionAttachment(attachmentUuid: string) {
        return this.http.delete<any>("api/submissionAttachmentDelete/" + attachmentUuid)
    }

    getSubmissionAttachments(submissionUuid, sortField, sortDirection, voucherAccountUuid?): Observable<any> {
        return this.http.get<any>("api/submissionAttachments/" + submissionUuid + "/" + sortField + "/" + sortDirection + (voucherAccountUuid ? "/" + voucherAccountUuid : ""))
    }

    submitSubmission(submissionUuid): Observable<any> {
        return this.http.post<any>("api/submitSubmission", { uuid: submissionUuid }, httpOptions)
    }

    finishSubmission(submissionUuid, reviewType, notes): Observable<any> {
        return this.http.post<any>("api/finishSubmission", { uuid: submissionUuid, reviewType, notes }, httpOptions)
    }

    searchAgencies() {
        return this.http.get<any>("api/searchAgencies");
    }

    getOverdueSubmissionsPromise(sortField, sortDirection) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/overdueSubmissions/" + sortField + "/" + sortDirection).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    submissionHasDocumentsPromise(submissionUuid) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/submissionHasDocuments/" + submissionUuid).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.hasDocuments)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    fetchFeedAndAppendImagesToDom(submissionUuid) {
        return this.getSubmissionActivityFeed(submissionUuid).pipe(
            map((response) => {
                if (response.success) {
                    const submissionActivityFeed = response.result;

                    for (let i = 0; i < submissionActivityFeed.length; ++i) {
                        setTimeout(function (i) {
                            this.systemService.loadProfileImage(
                                submissionActivityFeed[i].photo_id,
                                "photo_" + submissionActivityFeed[i].uuid,
                                submissionActivityFeed[i].full_name,
                                submissionActivityFeed[i].email,
                            );
                        }.bind(this, i), 0);
                    }

                    return submissionActivityFeed;
                } else {
                    let errorMessage;

                    try {
                        errorMessage = JSON.stringify(response);
                    } catch (error) {
                        errorMessage = `(Unable to parse server response: ${error})`;
                    }

                    throw new Error(errorMessage);
                }
            }),
            catchError((error) => {
                console.log("error while getting activity feed", error);
                return of({});
            })
        );
    }

    fetchSubmissionHistory(submissionUuid) {
        return this.getSubmissionHistory(submissionUuid).pipe(
            map((response) => {
                if (response.success) {
                    return response.result;
                } else {
                    let errorMessage;

                    try {
                        errorMessage = JSON.stringify(response);
                    } catch (error) {
                        errorMessage = `(Unable to parse server response: ${error})`;
                    }

                    throw new Error(errorMessage);
                }
            }),
            catchError((error) => {
                console.log("error while getting submission history", error);
                return of({});
            })
        );
    }

    fetchSubmissionHeaders(submissionUuid) {
        return this.getSubmission(submissionUuid).pipe(
            map((response: any) => {
                if (!response || response.result.length !== 1) {
                    throw new Error("non-singular submission encountered")
                }
                //console.log("submission headers response", response)
                if (response.result[0].agency_date) {
                    response.result[0].agency_date *= 1000
                }

                return response.result[0];
            })
        );
    }

    attachmentComment(attachment_uuid: string, comment: string, voucherUuid?: string): Observable<any> {
        return this.http.put<any>("api/attachmentComment", {attachment_uuid, comment, voucherUuid}, httpOptions)
    }

    deleteAttachmentComment(attachment_uuid: string, voucherUuid?: string): Observable<any> {
        return this.http.put<any>("api/deleteAttachmentComment/", {attachment_uuid}, httpOptions)
    }

}
