import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { REPORTPROGRAM } from '../mock/mock-report-program';
import { ProgramService } from '../program.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-report-program',
  templateUrl: './report-program.component.html',
  styleUrls: ['./report-program.component.css']
})
export class ReportProgramComponent implements OnChanges{
  //program = REPORTPROGRAM;
  displayedColumns: string[] = ['title', 'total'];

  //inputs
  @Input() groupedData: {[key: string]: { question_name: string, value: string }[] } = {};
  @Input() groupDataArray: any[] = [];
  @Input() submissionUuid: string;
  isLoading: boolean = true;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.groupDataArray && !changes.groupDataArray.firstChange) {
      this.isLoading = false;
      console.log(this.submissionUuid)
    }
  }
}
