import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { format } from 'sql-formatter';

export enum DisplayType {
    fk,
    date,
    textArea,
    number,
    text
}

@Component({
    selector: 'app-crud-child-tree',
    templateUrl: './crud-child-tree.component.html',
})
export class CrudChildTreeComponent implements OnInit {
    faDatabase = faDatabase
    typeValuesTree = { fc: new UntypedFormControl(), values: null, children: [], depth: 0 }
    typeFCsChange$ = new BehaviorSubject(null)
    schema$ = this.crudService.getSchema().pipe(shareReplay(1))

    addChildFCs(list, node) {
        list.push(node)
        for (var i = 0; i < node.children.length; ++i) {
            this.addChildFCs(list, node.children[i])
        }
    }

    getFCsFromTree() {
        var list = []
        this.addChildFCs(list, this.typeValuesTree)
        return list
    }

    ensureSiblingsAndChildren(node) {
        if (node.fc.value && !node.children.length) {
            node.children.push({ fc: new UntypedFormControl(), values: null, children: [], depth: node.depth + 1 })
            return true
        } else {
            var changed = false
            for (var i = 0; i < node.children.length; ++i) {
                var childChanged = this.ensureSiblingsAndChildren(node.children[i])
                if (childChanged) {
                    changed = true
                }
            }
            if (node.children.length && node.children[node.children.length - 1].fc.value) {
                node.children.push({ fc: new UntypedFormControl(), values: null, children: [], depth: node.depth + 1 })
                return true
            }
            return changed
        }
    }

    treeCleanup(node) {
        //if a non-last child goes null, remove it
        var treeChanged = false
        if (!node.fc.value || node.fc.value == "null") {
            node.children = []
            return
        }
        for (var i = node.children.length - 2; i >= 0; --i) {
            if (!node.children[i].fc.value || node.children[i].fc.value == "null") {
                treeChanged = true
                node.children.splice(i, 1)
            } else {
                if (this.treeCleanup(node.children[i])) {
                    treeChanged = true
                }
            }
        }
        return treeChanged
    }

    setTypeValues(schema, node, parent, childIndex) {
        node.values = []
        for (var key in schema.result) {
            if (schema.result.hasOwnProperty(key)) {
                if (parent) {
                    for (var i = 0; i < schema.result[key].definition.length; ++i) {
                        if (schema.result[key].definition[i].referenced_table_name == parent.fc.value) {
                            var doesSiblingHaveValue = false
                            for (var j = 0; j < parent.children.length; ++j) {
                                if (j == childIndex) {
                                    continue
                                }
                                if (parent.children[j].fc.value == key) {
                                    doesSiblingHaveValue = true
                                    break
                                }
                            }
                            if (!doesSiblingHaveValue) {
                                node.values.push({ name: key.replace(/_/g, " "), id: key })
                            }
                        }
                    }
                } else {
                    node.values.push({ name: key.replace(/_/g, " "), id: key })
                }
            }
        }
        node.values.sort(function (a, b) {
            return a.id.localeCompare(b.id);
        })
        for (var i = 0; i < node.children.length; ++i) {
            this.setTypeValues(schema, node.children[i], node, i)
        }
    }

    convertTreeToDisplayList(display, node) {
        display.push(node)
        for (var i = 0; i < node.children.length; ++i) {
            this.convertTreeToDisplayList(display, node.children[i])
        }
    }

    clear() {
        this.typeValuesTree = { fc: new UntypedFormControl(), values: null, children: [], depth: 0 }
        this.typeFCsChange$.next(null)
    }

    getSavedType(node) {
        return {
            type: node.fc.value,
            children: node.children.map((item) => { return this.getSavedType(item) })
        }
    }
    saveTypes() {
        localStorage.setItem("crud-child-type", JSON.stringify(this.getSavedType(this.typeValuesTree)))
        //console.log("saved", localStorage.getItem("crud-child-type"))
    }

    setNodeTypeFromSaved(node, saved) {
        if (!saved) {
            return
        }
        node.fc.setValue(saved.type)
        this.ensureSiblingsAndChildren(this.typeValuesTree)
        if (saved.children && saved.children.length) {
            for (var i = 0; i < saved.children.length; ++i) {
                this.setNodeTypeFromSaved(node.children[i], saved.children[i])
            }
        }
    }

    loadFromLocalStorage = true
    typeTree$ = this.typeFCsChange$.pipe(
        switchMap(() => {
            return combineLatest(
                this.schema$,
                ...this.getFCsFromTree().map(item => {
                    return item.fc.valueChanges.pipe(startWith(null))
                }),
            )
        }),
        switchMap(([schema, ...types]) => {
            //console.log("schema", schema)

            if (this.loadFromLocalStorage) {
                this.loadFromLocalStorage = false
                this.setNodeTypeFromSaved(
                    this.typeValuesTree,
                    JSON.parse(localStorage.getItem("crud-child-type")))
                this.typeFCsChange$.next(null)
            } else {
                this.saveTypes()
            }

            var changeTree = false
            if (this.ensureSiblingsAndChildren(this.typeValuesTree)) {
                changeTree = true
            }
            if (this.treeCleanup(this.typeValuesTree)) {
                changeTree = true
            }
            if (changeTree) {
                this.typeFCsChange$.next(null)
            } else {
                this.setTypeValues(schema, this.typeValuesTree, null, null)
            }

            var obs = this.updateReferenceTypes(schema, this.typeValuesTree)
            if (obs.length) {
                return combineLatest(...obs)
            } else {
                return of([null])
            }
        }),
        switchMap(([...referencedTypesResponses]) => {
            return of(this.typeValuesTree)
        }),
        shareReplay(1),
    )

    addReferenceType(typeName, columnName, response) {
        if (response.success) {
            var enumNameAndId = []
            for (var i = 0; i < response.result.length; ++i) {
                enumNameAndId.push({
                    id: response.result[i][columnName],
                    name: response.result[i][columnName],
                })
            }
            this.referencedTypes[typeName] = {
                entries: response.result,
                enumNameAndId
            }
        }
    }

    updateNodeReferenceTypes(observables, schema, node) {
        //console.log("node", node.fc.value)
        //console.log("schema", schema)
        if (!node.fc.value) {
            return
        }
        var columns = schema.result[node.fc.value].definition
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].referenced_table_name) {
                //this.getReferencedType(columns[i].referenced_table_name, columns[i].referenced_column_name)

                if (!this.referencedTypes[columns[i].referenced_table_name]) {
                    observables.push(this.crudService.put({ type: columns[i].referenced_table_name }).pipe(
                        tap((response) => {
                            this.addReferenceType(columns[i].referenced_table_name, columns[i].referenced_column_name, response)
                            return null
                        })
                    ))
                }
            }
        }
        for (var i = 0; i < node.children.length; ++i) {
            this.updateNodeReferenceTypes(observables, schema, node.children[i])
        }
    }

    updateReferenceTypes(schema, node) {
        var observables = []
        this.updateNodeReferenceTypes(observables, schema, node)
        return observables
    }

    typeDisplay$ = this.typeTree$.pipe(
        switchMap(() => {
            var display = []
            this.convertTreeToDisplayList(display, this.typeValuesTree)
            return of(display)
        })
    )

    getNodeString(node) {
        var string = ""
        if (!node.fc.value || node.fc.value == "null") {
            return string
        }
        string += node.fc.value
        if (node.children.length && !node.children[0].fc.value) {
            return string
        }
        string += " { "
        for (var i = 0; i < node.children.length; ++i) {
            if (i != 0 && i != node.children.length - 1) {
                string += ", "
            }
            string += this.getNodeString(node.children[i])
        }
        string += " } "
        return string
    }

    typeRawDisplay$ = this.typeTree$.pipe(switchMap((typeValues: any) => {
        return of(this.getNodeString(this.typeValuesTree))
    }))

    typeSearchResultObject$ = this.typeRawDisplay$.pipe(
        switchMap((value) => {
            return this.crudService.search({ type: value })
        }),
        switchMap((value) => {
            if (!value.success) {
                return of([])
            } else {
                return of(value)
            }
        }),
        shareReplay(1),
    )

    typeSearchResults$ = this.typeSearchResultObject$.pipe(
        switchMap((value) => {
            if (!value.success) {
                return of([])
            } else {
                return of(value.result)
            }
        })
    )

    showFieldAsFK(column) {
        return column.referenced_table_name && this.referencedTypes[column.referenced_table_name]
    }

    showFieldAsDate(column) {
        return !this.showFieldAsFK(column) && column.Type == 'datetime'
    }

    showFieldAsTextArea(column) {
        if (this.showFieldAsFK(column)) {
            return false
        }
        const typePrefix = "varchar("
        if (!column.Type.startsWith(typePrefix)) {
            return false
        }
        var lenString = column.Type.substring(typePrefix.length, column.Type.indexOf(")", typePrefix.length))
        if (parseInt(lenString) > 256) {
            return true
        }
        return false
    }

    showFieldAsNumber(column) {
        if (this.showFieldAsFK(column)) {
            return false
        }
        return column.Type.startsWith("int(") || column.Type.startsWith("decimal(")
    }

    showFieldAsText(column) {
        return !this.showFieldAsFK(column) && !this.showFieldAsDate(column) && !this.showFieldAsTextArea(column) && !this.showFieldAsNumber(column)
    }

    setDisplayType(definition) {
        if (this.showFieldAsFK(definition)) {
            definition.displayType = DisplayType.fk
        }
        if (this.showFieldAsDate(definition)) {
            definition.displayType = DisplayType.date
        }
        if (this.showFieldAsTextArea(definition)) {
            definition.displayType = DisplayType.textArea
        }
        if (this.showFieldAsNumber(definition)) {
            definition.displayType = DisplayType.number
        }
        if (this.showFieldAsText(definition)) {
            definition.displayType = DisplayType.text
        }
    }

    attachFCs(schema, node, value) {
        //console.log("attach", node.fc.value)
        var def = schema.result[node.fc.value]
        //console.log("Def", def)
        for (var i = 0; i < def.definition.length; ++i) {
            this.setDisplayType(def.definition[i])
            if (!value[def.definition[i].Field + "FC"]) {
                value[def.definition[i].Field + "FC"] = new UntypedFormControl(value[def.definition[i].Field])
            }
        }
        for (var i = 0; i < node.children.length; ++i) {
            //console.log("CHECK!!!!!", node.children[i].fc.value + "s", value)
            if (node.children[i].fc.value && value[node.children[i].fc.value + "s"]) {
                for (var j = 0; j < value[node.children[i].fc.value + "s"].length; ++j) {
                    this.attachFCs(schema, node.children[i], value[node.children[i].fc.value + "s"][j])
                }
            }
        }
    }

    firstEntry$ = combineLatest(this.schema$, this.typeSearchResults$).pipe(
        switchMap(([schema, value]) => {
            if (value.length == 0) {
                return of("")
            }
            var firstValue = JSON.parse(JSON.stringify(value[0]));
            this.attachFCs(schema, this.typeValuesTree, firstValue)
            return of(firstValue)
        })
    )

    typeRawResults$ = this.typeSearchResults$.pipe(
        switchMap((value) => {
            if (value.length == 0) {
                return of("")
            }
            return of(JSON.stringify(value, null, 4))
        })
    )

    results$ = combineLatest(this.schema$, this.typeSearchResults$).pipe(
        switchMap(([schema, value]) => {
            if (value.length == 0) {
                return of("")
            }
            // for (var i = 0; i < value.length; ++i) {
            //     value[i].rawValue = JSON.stringify(value[i], null, 4)
            //     value[i] = JSON.parse(JSON.stringify(value[i]))
            //     this.attachFCs(schema, this.typeValuesTree, value[i])
            // }
            console.log("RESULTS", value)
            return of(value)

            // var _ret = []
            // for (var i = 0; i < value.length; ++i) {
            //     _ret.push(JSON.stringify(value[i], null, 4))
            // }
            // return of(_ret)
        }),
    )

    isObjectList(item) {
        if (!Array.isArray(item)) {
            return false
        }
        if (!item.length) {
            return false
        }
        for (var i = 0; i < item.length; ++i) {
            if (typeof item[i] === 'object' && item[i] !== null) {
                return true
            }
        }
        return false
    }

    addRows(rows, item) {
        var childRows = []
        var row = {}
        for (var prop in item) {
            if (item.hasOwnProperty(prop)) {
                if (this.isObjectList(item[prop])) {
                    for (var i = 0; i < item[prop].length; ++i) {
                        this.addRows(childRows, item[prop][i])
                    }
                } else {
                    row[prop] = item[prop]
                }
            }
        }
        rows.push(row)
        for (var i = 0; i < childRows.length; ++i) {
            rows.push(childRows[i])
        }
    }

    cols

    addCol(colName) {
        for (var i = 0; i < this.cols.length; ++i) {
            if (this.cols[i] == colName) {
                return
            }
        }
        this.cols.push(colName)
    }

    resultsRows$ = this.results$.pipe(
        switchMap((value) => {
            var rows = []

            for (var i = 0; i < value.length; ++i) {
                this.addRows(rows, value[i])
            }

            this.cols = []
            for (var i = 0; i < rows.length; ++i) {
                for (var prop in rows[i]) {
                    if (rows[i].hasOwnProperty(prop)) {
                        this.addCol(prop)
                    }
                }
            }
            //console.log("CCCCCCCCCCCCCCCCCCOLS", this.cols)
            return of(rows)
        })
    )

    typeQuery$ = this.typeSearchResultObject$.pipe(
        switchMap((value) => {
            if (!value.sql) {
                return of("")
            }
            var formatSplit = format(value.sql).split("\n")
            for (var i = 0; i < formatSplit.length; ++i) {
                var moreSpaces = ""
                for (var j = 0; j < formatSplit[i].length; ++j) {
                    if (formatSplit[i][j] != " ") {
                        break
                    }
                    moreSpaces += " "
                }
                formatSplit[i] = moreSpaces + formatSplit[i]
            }
            return of(formatSplit.join("\n"))
        })
    )

    referencedTypes = {}

    constructor(
        private crudService: CrudService
    ) { }

    ngOnInit() {
    }
}
