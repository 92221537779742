import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SubmissionService } from '../submission.service';
import { DashboardService } from '../dashboard.service';
import { UntypedFormControl } from '@angular/forms';
import { SystemService } from '../system.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    faBan, faUpload, faSave, faShare, faFlagCheckered, faChevronLeft, faCheck, faThumbsUp, faPrint, faArrowLeft, faEye
} from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';
import { SubmissionAttachmentsComponent } from '../submission-attachments/submission-attachments.component';
import { ParamService } from '../param.service';
import { SubmissionStepService } from '../submission-step.service';

@Component({
    selector: 'app-submission-review',
    templateUrl: './submission-review.component.html',
})
export class SubmissionReviewComponent implements OnInit {
    @ViewChild(SubmissionAttachmentsComponent) private _attachmentsComponent: SubmissionAttachmentsComponent;
    faEye = faEye
    faBan = faBan
    faUpload = faUpload
    faSave = faSave
    faShare = faShare
    faFlagCheckered = faFlagCheckered
    faChevronLeft = faChevronLeft
    faCheck = faCheck
    faThumbsUp = faThumbsUp
    faPrint = faPrint
    faArrowLeft = faArrowLeft
    initialized = false
    submission
    showPrimeButtons
    userOrganizationType
    approvalFC = new UntypedFormControl()
    approvalNotesFC = new UntypedFormControl({ value: '', disabled: true })
    requestInformationNotesFC = new UntypedFormControl({ value: '', disabled: true })
    showAddAttachmentControls = false

    showOkayButton
    showCancelAndSubmitButtons

    submissionUuid
    submissionActivityFeed$
    submissionHistory$
    submission$
    submissionStatuses$
    sub:any

    fiscal_uuid: string

    constructor(
        private dashboardService: DashboardService,
        private submissionService: SubmissionService,
        private systemService: SystemService,
        private router: Router,
        private _snackBar: MatSnackBar,
        private authService: AuthService,
        private paramService: ParamService,
        private submissionStepService: SubmissionStepService,
        private route: ActivatedRoute,
    ) { }

    get attachmentsComponent() {
        return this._attachmentsComponent
    }

    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    getSubmissionPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmission(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.submission = response.result[0]
                    console.log("submission for review", this.submission)
                } else {
                    console.log("error while getting submission", response)
                }
                resolve(null)
            })
        })
    }

    stepUuid: string
    showFlag: boolean
    ngOnInit() {

        this.paramService.getParamPromise("submissionUuid").then((paramValue) => {
            this.submissionUuid = paramValue
        }).then(() => {
            this.route.queryParams.subscribe(params => {
                this.stepUuid = params['stepUuid']
            })
            console.log(this.stepUuid)

            this.submissionActivityFeed$ = this.submissionService.fetchFeedAndAppendImagesToDom(
                this.submissionUuid
            );
            this.submissionHistory$ = this.submissionService.fetchSubmissionHistory(this.submissionUuid);
            this.submission$ = this.submissionService.fetchSubmissionHeaders(this.submissionUuid);
            this.submissionStatuses$ = this.dashboardService.getStatusesById();

            var promises = [
                this.getSubmissionPromise(),
                this.getUserOrganizationType(),
            ]
            return Promise.all(promises).then(() => {
                this.showPrimeButtons = (this.userOrganizationType == "PRIME" || this.userOrganizationType == "SYSTEM")
                    && this.submission.status_id != 'APPROVED' && this.submission.status_id != 'MORE_INFO'
                this.showAddAttachmentControls = true

                this.showCancelAndSubmitButtons = this.userOrganizationType != "PRIME" && this.userOrganizationType != "SYSTEM"
                    && (this.submission.status_id == 'NEW' || this.submission.status_id == 'DRAFT' || this.submission.status_id == 'MORE_INFO')
                this.showOkayButton = !this.showPrimeButtons && !this.showCancelAndSubmitButtons

                if(this.userOrganizationType == 'SUB' || this.submission.status_id == 'SUBMITTED' || this.submission.status_id == 'MORE_INFO') {
                    this.showFlag = true
                } else {
                    this.showFlag = false
                }

                // this.showPrimeButtons = (this.userOrganizationType == 'SUB' && this.)
                this.submission$.subscribe((result: any) => {
                    this.fiscal_uuid = result.fiscal_uuid
                },(error: any) => {
                    console.log(error)
                })
                this.initialized = true
            })
        })
    }

    primeCancel() {
        var snack = "Action cancelled"
        this._snackBar.open(snack, null, { duration: 2000, });
        this.router.navigate(["/dashboard"])
    }

    primeProgramCancel() {
        window.history.go(-1)
    }

    primeFinish() {
        this.saveError = null
        if (this.reviewType == null) {
            this.saveError = "Please select a review type"
            return
        }
        var notes
        if (this.reviewType == "approve") {
            notes = this.approvalNotesFC.value
        } else {
            notes = this.requestInformationNotesFC.value
        }
        this.submissionService.finishSubmission(this.submissionUuid, this.reviewType, notes).subscribe((response) => {
            if (response.success) {
                var snack = "Submission finished"
                this._snackBar.open(snack, null, { duration: 2000, });
                this.router.navigate(["/confirmation-review"])
            } else {
                this.saveError = response.message
                console.log("error while finishing submission", response)
            }
        })
    }

    subPrevious() {
        this.router.navigate(["/submission"])
    }

    subSaveForLater() {
        var snack = "Submission saved for later"
        this._snackBar.open(snack, null, { duration: 2000, });
        this.router.navigate(["/dashboard"])
    }

    subSubmit() {
        this.submissionService.submitSubmission(this.submissionUuid).subscribe((response) => {
            if (response.success) {
                //todo - snackbar
                var snack = "Submission submitted"
                this._snackBar.open(snack, null, { duration: 2000, });
                this.router.navigate(["/confirmation"])
            } else {
                console.log("error while submitting submission", response)
            }
        })
    }

    reviewType = null

    approvalChange(event) {
        switch (event.target.value) {
            case "approve":
                this.reviewType = "approve"
                this.approvalNotesFC.enable()
                this.requestInformationNotesFC.disable()
                break;
            case "request":
                this.reviewType = "request"
                this.approvalNotesFC.disable()
                this.requestInformationNotesFC.enable()
                break;
        }
    }

    ok() {
        history.back()
    }

    cancel() {
        history.back()
    }

    saveError
    submit() {
        this.submissionService.submitSubmission(this.submissionUuid).subscribe((response) => {
            if (!response.success) {
                this.saveError = response.message
                console.log(response)
                return
            }
            this.router.navigate(["/confirmation"])
        })
    }

    print() {
        this.router.navigate(['report-budget'], {queryParams: { submissionUuid: this.submissionUuid }})
        //window.print()
    }

    printVoucher() {
        window.print()
    }
    
    printDetail() {
        this.router.navigate(['budget-summary-detail'], { queryParams: { submissionUuid: this.submissionUuid, fiscalUuid: this.fiscal_uuid } })
    }

    printVoucherDetail() {
        const url = this.router.serializeUrl(this.router.createUrlTree(['voucher-detail'], {queryParams: {submissionUuid: this.submissionUuid}}))
        window.open(url, '_blank')
    }
}
