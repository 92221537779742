import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SubmissionCommentService {

    constructor(
        private http: HttpClient,
    ) { }

    putCommentPromise(submission_uuid, target, target_uuid, target_column, comment) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.put<any>("api/submissionComment", {
                submission_uuid,
                target,
                target_column,
                target_uuid,
                comment
            }).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                return resolve(response.uuid)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    postCommentPromise(comment_uuid, comment) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.post<any>("api/submissionComment", {
                comment_uuid,
                comment
            }).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                return resolve(null)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    deleteCommentPromise(comment_uuid) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.delete<any>("api/submissionComment/" + comment_uuid).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                return resolve(null)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    getSubmissionCommentsPromise(submissionUuid, targetUuid?) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/submissionComment/" + submissionUuid + "/" + (targetUuid ? targetUuid : '')).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                return resolve(response.result)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }
}
