<h4 mat-dialog-title>Confirm Action</h4>
<div mat-dialog-content>
    <p>Creating a new {{dialogMessage}}. Do you wish to continue?</p>
    <div class="confirm-checkbox">
        <mat-checkbox [(ngModel)]="confirmCheck" color="primary">
            Yes, confirm {{dialogName}} creation.
        </mat-checkbox>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeDialog()">Cancel</button>
    <button 
        mat-raised-button 
        color="primary" 
        (click)="confirmDialog()" 
        [disabled]="!confirmCheck || isConfirming">
        Create
    </button>
</div>
