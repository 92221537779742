import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan, faBullseye, faCheck, faFlagCheckered, faLongArrowAltLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { map, switchMap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { SubmissionScheduleService } from '../submission-schedule.service';

@Component({
    selector: 'app-main-target',
    templateUrl: './main-target.component.html',
    styleUrls: ['./main-target.component.scss']
})
export class MainTargetComponent implements OnInit {
    faLongArrowAltLeft = faLongArrowAltLeft
    faSave = faSave
    faFlagCheckered = faFlagCheckered
    faBan = faBan
    faCheck = faCheck
    faBullseye = faBullseye

    columns = [
        {
            //Field: "agency",
            label: "Agency"
        },
        {
            label: "Jurisdiction"
        },
        {
            label: "Main Target"
        },
        {
            label: "Outreach Campaign Target"
        },
        {
            label: "Person to Person Target"
        },
        {
            label: "Prescreen Target"
        },
        {
            label: "Recertification Target"
        },
        {
            label: "Barrier Narrative Target"
        },
        {
            label: "Collaboration Narrative Target"
        },
        {
            label: "Tracking % Target"
        },
    ]



    mainTarget$ = this.activatedRoute.queryParams.pipe(
        switchMap((queryParams) => {
            //return this.crudService.search({ type: "data_main_target", where: "fiscal_uuid = ?", whereArgs: [queryParams.uuid] })
            return this.submissionScheduleService.getScheduleMainTarget(queryParams.uuid)
        }),
        map((crudResponse) => {
            //console.log("resp", crudResponse)

            if (crudResponse && crudResponse.result && crudResponse.result.length > 0) {
                this.canEdit = !crudResponse.result[0].data_entry_complete
            }

            for (var i = 0; i < crudResponse.result.length; ++i) {
                crudResponse.result[i].mainTargetFC = new UntypedFormControl(crudResponse.result[i].main_target)
                crudResponse.result[i].outreachTargetFC = new UntypedFormControl(crudResponse.result[i].outreach_target)
                crudResponse.result[i].personTargetFC = new UntypedFormControl(crudResponse.result[i].person_target)
                crudResponse.result[i].prescreenTargetFC = new UntypedFormControl(crudResponse.result[i].prescreen_target)
                crudResponse.result[i].recertificationTargetFC = new UntypedFormControl(crudResponse.result[i].recertification_target)
                crudResponse.result[i].barrierTargetFC = new UntypedFormControl(crudResponse.result[i].barrier_target)
                crudResponse.result[i].collaborationTargetFC = new UntypedFormControl(crudResponse.result[i].collaboration_target)
                crudResponse.result[i].trackingTargetFC = new UntypedFormControl(crudResponse.result[i].tracking_percent)
            }
            return crudResponse.result
        })
    )

    canEdit = false
    saveError

    constructor(
        private activatedRoute: ActivatedRoute,
        private crudService: CrudService,
        private submissionScheduleService: SubmissionScheduleService,
        private _snackBar: MatSnackBar,
        private router: Router,
    ) { }

    ngOnInit() {
    }

    getUpdateOperations(budgetAppropriation) {
        var operations = []
        budgetAppropriation.forEach(element => {
            operations.push(
                {
                    type: "data_main_target",
                    operation: "update",
                    value: {
                        uuid: element.uuid,
                        main_target: element.mainTargetFC.value,
                        outreach_target: element.outreachTargetFC.value,
                        person_target: element.personTargetFC.value,
                        prescreen_target: element.prescreenTargetFC.value,
                        recertification_target: element.recertificationTargetFC.value,
                        barrier_target: element.barrierTargetFC.value,
                        collaboration_target: element.collaborationTargetFC.value,
                        tracking_percent: element.trackingTargetFC.value,
                    }
                })
        });
        return operations
    }

    doSave(operations) {
        this.crudService.applyOperations({
            entries: operations
        }).subscribe((response) => {
            if (!response.success) {
                this.saveError = response.message
                console.log("error while submitting change", response)
                this._snackBar.open("Error occurred: " + response.message, null, {
                    duration: 4000,
                });
                return
            }
            this._snackBar.open("Operation complete", null, {
                duration: 4000,
            });
            this.router.navigate(["/submission-schedule"])
        })

    }

    saveForLater(budgetAppropriation) {
        var operations = this.getUpdateOperations(budgetAppropriation)
        this.doSave(operations)
    }

    finish(budgetAppropriation) {
        var operations = this.getUpdateOperations(budgetAppropriation)
        operations.push({
            type: "config_fiscal",
            operation: "update",
            value: {
                uuid: budgetAppropriation[0].fiscal_uuid,
                data_entry_complete: '1',
            }
        })
        this.doSave(operations)
    }

}
