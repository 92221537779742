import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root',
})

export class HelperService {
    constructor(private http: HttpClient) {}

    getSubmissionData(uuid: string): Observable<any> {
        return this.http.get<any>('api/submissionData/' + uuid)
    }

    getSubmissionStatus(uuid: string): Observable<any> {
        return this.http.get<any>('api/submissionStatus/' + uuid)
    }

    getStatus(status: string): Observable<any> {
        return this.http.get<any>('api/getStatus/' + status)
    }
}