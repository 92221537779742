<div class="container-fluid">
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faFileInvoiceDollar"></fa-icon>
            Budget Summary
        </div>
    </div>
    <div class="row">
        <div class="col px-0 text-right">
            <app-button3 *ngIf="showPrintButton" label="Print" [clickFunction]="print.bind(this)"
                [icon]="faPrint" [small]="true">
            </app-button3>
        </div>
    </div>
</div>

<div *ngIf="entries$ | async as entries">
    <app-budget-summary-header [submission_uuid]="submissionUuid"></app-budget-summary-header>

    <div *ngFor="let row of entries.result;"  style="page-break-after: always">
        <div *ngIf="fiscalUuid != '3';else old_personnel">
            <app-personnel *ngIf="row.screen == '_PERSONNEL'" 
                [financeUuidInput]="row.uuid"
                [editable]="false" 
                [hideFringe]="true" 
                [hideButtons]="true">
            </app-personnel>
            <app-budget-account *ngIf="row.screen != '_PERSONNEL'" [accountUuid]="row.uuid" [hideButtons]="true">
            </app-budget-account>
        </div>
        <ng-template #old_personnel>
            <app-budget-personnel *ngIf="row.screen == '_PERSONNEL'" [submissionUuidInput]="submissionUuid"
            [personnelEntryUuidInput]="row.uuid" [disableOverride]="true" [hideFringe]="true" [hideButtons]="true">
            </app-budget-personnel>
            <app-budget-account *ngIf="row.screen != '_PERSONNEL'" [accountUuid]="row.uuid" [hideButtons]="true">
            </app-budget-account>
        </ng-template>
    </div>
</div>