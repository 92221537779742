<h1 mat-dialog-title>Edit voucher line</h1>
<mat-dialog-content>
    <form [formGroup]="form" class="form">
    <p class="form-field"*ngIf="data.line_type == '_TRAVEL' || data.line_type == '_DEFAULT' || data.line_type == '_PRINTING' || data.line_type == '_OUTREACH_AGENCY'">
        <mat-form-field>
            <mat-label>Description</mat-label>
            <input matInput
                    type="text"
                    placeholder="Description"
                    value="{{data.description}}"
                    formControlName="description"
                    maxlength="255"
                    required>
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_TRAVEL' || data.line_type == '_OUTREACH' || data.line_type == '_DEFAULT' || data.line_type == '_PRINTING' || data.line_type == '_BENEFITS' || data.line_type == '_OUTREACH_AGENCY'">
        <mat-form-field>
            <mat-label>Vendor Name</mat-label>
            <input matInput
                    type="text"
                    placeholder="Vendor Name"
                    value="{{data.name}}"
                    formControlName="name"
                    maxlength="255">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type =='_PERSONNEL' || data.line_type == '_BENEFITS' || data.line_type == '_TRAVEL' || data.line_type == '_DEFAULT' || data.line_type == '_OUTREACH_AGENCY'">
        <mat-form-field>
            <mat-label>Period Covered</mat-label>
            <input matInput
                type="text"
                placeholder="Period Covered"
                value="{{data.period}}"
                formControlName="period" 
                maxlength="50">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_PERSONNEL'">
        <mat-form-field>
            <mat-label># Hours Worked</mat-label>
            <input matInput
                type="number"
                placeholder="# Hours Worked"
                value="{{data.quantity_est}}"
                formControlName="quantity_est">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_PERSONNEL'">
        <mat-form-field>
            <mat-label># Hours Charged</mat-label>
            <input matInput
                type="number"
                placeholder="# Hours Charged"
                value="{{data.quantity}}"
                formControlName="quantity">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_PERSONNEL'">
        <mat-form-field>
            <mat-label>Hourly Rate</mat-label>
            <input matInput
                type="number"
                placeholder="Hourly Rate"
                value="{{data.unit_price}}"
                formControlName="unit_price">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_OUTREACH'">
        <mat-form-field>
            <mat-label>Per Unit Amount</mat-label>
            <input matInput
                type="number"
                placeholder="Per Unit Amount"
                value="{{data.unit_price}}"
                formControlName="unit_price">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_BENEFITS'">
        <mat-form-field>
            <mat-label>Rate Used/Premium Amount</mat-label>
            <input matInput
                type="text"
                placeholder="Rate Used/Premium Amount"
                value="{{data.premium}}"
                formControlName="premium"
                maxlength="10">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_TRAVEL'">
        <mat-form-field>
            <mat-label>Travel Dates</mat-label>
            <input matInput
                type="text"
                placeholder="Travel Dates"
                value="{{data.dates}}"
                formControlName="dates"
                maxlength="50">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_BENEFITS' || data.line_type == '_DEFAULT' || data.line_type == '_OUTREACH' || data.line_type == '_TRAVEL' || data.line_type == '_PRINTING' || data.line_type == '_OUTREACH_AGENCY'">
        <mat-form-field>
            <mat-label>Invoice #</mat-label>
            <input matInput
                type="text"
                placeholder="Invoice #"
                value="{{data.invoice_number}}"
                formControlName="invoice_number"
                maxlength="25">
        </mat-form-field>
    </p>


    <p class="form-field" *ngIf="data.line_type == '_TRAVEL' || data.line_type == '_OUTREACH' || data.line_type == '_PRINTING' || data.line_type == '_DEFAULT' || data.line_type == '_BENEFITS' || data.line_type == '_OUTREACH_AGENCY'">
        <mat-form-field appearance="fill">
            <mat-label>Invoice Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="line_date" value="{{data.line_date}}" >
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type != '_PERSONNEL'">
        <mat-form-field>
            <mat-label>Total Invoice</mat-label>
            <input matInput
                    type="number"
                    placeholder="Total Invoice"
                    value="{{data.other_amount}}"
                    formControlName="other_amount" >
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.allow_percent == 'Y'">
        <mat-form-field>
            <mat-label>Percent Charged</mat-label>
            <input matInput
                type="text"
                placeholder="Percent Charged"
                value="{{data.percent}}"
                formControlName="percent">
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.allow_method == 'Y'">
        <mat-form-field>
            <mat-label>Select allocation method</mat-label>
            <mat-select [formControlName]="'method'">
                <mat-option value="DIRECT">DIRECT</mat-option>
                <mat-option value="FTE">FTE</mat-option>
            </mat-select>
        </mat-form-field>
    </p>

    <p class="form-field" *ngIf="data.line_type == '_PERSONNEL'">
        <mat-form-field>
            <mat-label>Gross Pay</mat-label>
            <input matInput
                type="number"
                placeholder="Gross Pay"
                value="{{data.other_amount}}"
                formControlName="other_amount"
                required>
        </mat-form-field>
    </p>

    <p class="form-field">
        <mat-form-field>
            <mat-label>State Only</mat-label>
            <input matInput
                    type="number"
                    placeholder="State Only"
                    value="{{data.state_only}}"
                    formControlName="state_only"
                    required>
        </mat-form-field>
    </p>
    
    <p class="form-field">
        <mat-form-field>
            <mat-label>State/Fed/Match</mat-label>
            <input matInput
                    type="number"
                    placeholder="State/Fed/Match"
                    value="{{data.state_federal}}"
                    formControlName="state_federal"
                    required>
        </mat-form-field>
    </p>

    <p class="form-field">
        <mat-form-field>
            <mat-label>Check / ACH #</mat-label>
            <input matInput
                    type="text"
                    placeholder="Check / ACH #"
                    value="{{data.line_reference_number}}"
                    formControlName="line_reference_number"
                    maxlength="25" >
        </mat-form-field>
    </p>

    <p class="form-field">
        <mat-form-field>
            <mat-label>Check/ACH Date</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="line_reference_date" value="{{data.line_reference_date}}" >
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </p>

    <p class="form-field">
        <mat-form-field>
            <mat-label>Check/ACH Amount</mat-label>
            <input matInput
                    type="number"
                    placeholder="Check/ACH Amount"
                    value="{{data.check_amount}}"
                    formControlName="check_amount" >
        </mat-form-field>
    </p>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <mat-error *ngIf="saveError" style="font-weight: bold;">You cannot exceed gross pay / invoice amount.</mat-error>
    <button mat-raised-button mat-primary (click)="editLine()" [disabled]="!form.valid" color="primary">Save</button>
    <button *ngIf="data.line_number == 1" mat-raised-button color="warn" (click)="deleteLine()">Delete</button>
    <button mat-raised-button (click)="close()" style="margin-right: 5px;">Close</button>
</mat-dialog-actions>