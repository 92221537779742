import { Component, OnInit, Input } from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-sort-indicator',
    templateUrl: './sort-indicator.component.html',
})
export class SortIndicatorComponent implements OnInit {
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp
    @Input() sortFieldName;
    @Input() sortIsDescending;
    @Input() columnFieldName;

    constructor() { }

    ngOnInit() {
    }

}
