<div class="container-fluid">

    <div class="row page-title-no-font mb-2">
        <div class="col">
            <span class="page-title-font">
                <fa-icon [icon]="faDatabase"></fa-icon>
                Data Definition
            </span>
        </div>
    </div>

    <app-input-enum label="Type" [fc]="typeFC" [enumValuesNameAndId]="schemaTypes$ | async"
        includeUnsetDisplay="Select a type">
    </app-input-enum>

    <span *ngIf="typeFC.value">
        <div class="row">
            <div class="col-6">
                <h2>{{typeFC.value | dbtitlecase | titlecase}}</h2>
            </div>
        </div>
    </span>

    <div class="row ml-4" *ngFor="let col of typeFCs$ | async" style="height: 2em;">
        <div class="col-2">
            <span (mouseenter)="col.nameMouseOver = true" (mouseleave)="colNameLeave(col)">
                <span *ngIf="!col.nameMouseOver">
                    {{ col.definition.Field }}
                </span>
                <span *ngIf="col.nameMouseOver" (click)="col.nameFC.markAsDirty()">
                    <app-typed [fc]="col.nameFC" [width]="'100%'" [columnDefinition]="{ Type: 'varchar(255)'}">
                    </app-typed>
                </span>
            </span>
        </div>
        <div class="col">
            <span (mouseenter)="col.typeMouseOver = true" (mouseleave)="colTypeLeave(col)">
                <span *ngIf="!col.typeMouseOver">
                    {{ col.definition.Type }}
                </span>
                <span *ngIf="col.typeMouseOver" (click)="col.typeFC.markAsDirty()">
                    <app-enum [fc]="col.typeFC" [enum]="dbTypes" [maxWidth]="'1em'"></app-enum>
                    <app-number *ngIf="dbTypesById[col.typeFC.value] && dbTypesById[col.typeFC.value].sized"
                        [fc]="col.sizeFC" [maxWidth]="'4em'"></app-number>
                    <span *ngIf="col.typeFC.dirty"> (cancel)</span>
                </span>
            </span>
        </div>
        <div class="col">
        </div>
    </div>

    <div class="row ml-4" *ngFor="let col of newColumns" style="height: 2em;">
        <div class="col-2">
            <app-typed [fc]="col.nameFC" [width]="'100%'" [columnDefinition]="{ Type: 'varchar(255)'}">
            </app-typed>
        </div>
        <div class="col" (mouseenter)="col.typeMouseOver = true" (mouseleave)="colTypeLeave(col)">
            <app-enum [fc]="col.typeFC" [enum]="dbTypes" [maxWidth]="'1em'"></app-enum>
            <app-number *ngIf="dbTypesById[col.typeFC.value] && dbTypesById[col.typeFC.value].sized" [fc]="col.sizeFC"
                [maxWidth]="'4em'"></app-number>
            <app-button [icon]="faTimes" (click)="deleteColumn(col)" [small]="true" [skinny]="true"></app-button>
        </div>
        <div class="col">
        </div>
    </div>

    <div class="row ml-4">
        <div class="col">
            <app-button [icon]="faPlus" [small]="'true'" [clickFunction]="addColumn.bind(this)"></app-button>
        </div>
    </div>

    <div class="row" *ngIf="showButtons">
        <div class="col" align="center">
            <app-button label="Save" (click)="save()"></app-button>
            <app-button label="Cancel" (click)="cancel()"></app-button>
        </div>
    </div>

    <div *ngIf="updateSQL$ | async as updateSQL" class="row mt-2">
        <div class="col" style="white-space: pre; background-color: lightgrey;">{{updateSQL}}</div>
    </div>

</div>