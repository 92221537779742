<div *ngIf="initialized" class="container-fluid">
    <div class="row mb-2">
        <div class="col bold" style="background-color: lightgrey; font-size: 1.2em;">
            Submission Review Confirmation
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-3">
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col bold" style="font-size: 1.2em;">
                    Whats Next?
                </div>
            </div>
            <div class="row pt-1">
                <div class="col">
                    <p class="ml-3">Your submission review has been completed.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="OK" [clickFunction]="returnToDashboard.bind(this)" [icon]="faCheck">
            </app-button3>
        </div>
    </div>
</div>