<div *ngIf="format == InputDateFormat.verticalLabel" class="row mt-3">
    <div class="col bold">
        {{label}}
        <span *ngIf="tooltip" data-toggle="tooltip" data-placement="right" [title]="tooltip" class="saq-tooltip ml-1">
            <fa-icon [icon]="faInfoCircle">
            </fa-icon>
        </span>
    </div>
</div>

<div *ngIf="format == InputDateFormat.verticalLabel" class="row mt-1">
    <div *ngIf="!disabled" class="col">
        <input type="date" class="input-style" [formControl]="fc" (change)="doChange()" />
    </div>
    <div *ngIf="disabled" class="col">
        {{fc.value| date : disabledFormat}}
    </div>
</div>

<div *ngIf="format == InputDateFormat.horizontalLabel" class="row edit-height">
    <div class="col-4 pb-2 unedit-padding bold">
        {{label}}
    </div>
    <div *ngIf="disabled" class="col-8 pb-2 unedit-padding">
        {{fc.value | date : disabledFormat}}
    </div>
    <div *ngIf="!disabled" class="col-8 pb-2">
        <input type="date" class="input-style" [formControl]="fc" (change)="doChange()" />
    </div>
</div>

<span *ngIf="format == InputDateFormat.none">
    <div *ngIf="!disabled">
        <input type="date" class="input-style" [formControl]="fc" (change)="doChange()" />
    </div>
    <div *ngIf="disabled">
        {{fc.value| date : disabledFormat}}
    </div>
</span>