import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailService } from '../services/email.service';
import { iAdminDocument } from '../model/admin-document.model';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-email-notifications',
  templateUrl: './email-notifications.component.html',
  styleUrls: ['./email-notifications.component.css']
})
export class EmailNotificationsComponent implements OnInit
{
  // @ViewChild(MatSort, { static: false }) sort: MatSort;
  adminDocuments: MatTableDataSource<iAdminDocument> = new MatTableDataSource<iAdminDocument>([]);


  constructor(private emailService: EmailService)
  {
  }

  onSendEmail(emailData: any) {
    this.emailService.sendEmail(emailData).subscribe(
      (response) => {
        console.log(response);
        if (response && response.success) {
          window.location.reload();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  loadDocsData(): void
  {
    this.emailService.getDocsInfo().subscribe((data) => {
      this.adminDocuments.data = data.result;

      // this.adminDocuments.sort = this.sort;
      // this.adminDocuments.sort.active = 'orgName';
      // this.adminDocuments.sort.direction = 'asc';
      console.log('Page data loaded', this.adminDocuments);
    },
    (error) => {
      console.error('error getting page data', error);
    });
  }

  ngOnInit(): void
  {
    this.loadDocsData();
  }
}