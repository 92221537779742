import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CrudService {
    constructor(
        private http: HttpClient,
    ) { }

    //names corresponding to the http call
    private get(): Observable<any> {
        return this.http.get<any>("api/crud")
    }

    private post(body): Observable<any> {
        return this.http.post<any>("api/crud", body, httpOptions)
    }

    put(body): Observable<any> {
        return this.http.put<any>("api/crud", body, httpOptions)
    }

    //names corresponding to functionality
    getSchema(): Observable<any> {
        return this.get()
    }

    search(body: {
        type: string,
        limit?: number,
        offset?: number,
        where?: string,
        whereArgs?: string[],
        keyword?: string,
        sort?: [{
            columnName: string,
            isDescending: boolean,
        }]
    }): Observable<any> {
        return this.put(body)
    }

    applyOperations(body): Observable<any> {
        return this.post(body)
    }

    //convenience methods to wrap single object operations in a batch
    create(type, value): Observable<any> {
        return this.applyOperations({
            entries: [{
                operation: "insert",
                type,
                value,
            }]
        })
    }

    update(type, value): Observable<any> {
        return this.applyOperations({
            entries: [{
                operation: "update",
                type,
                value,
            }]
        })
    }

    delete(type, value): Observable<any> {
        return this.applyOperations({
            entries: [{
                operation: "delete",
                type,
                value,
            }]
        })
    }
}
