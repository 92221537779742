import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-input-text',
    templateUrl: './input-text.component.html',
})
export class InputTextComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() keyupEnter;
    @Input() requiredMarker;
    @Input() exampleText;
    @Input() style;
    @Input() halfLabelSize;
    @Input() units;
    @Input() rowClass;
    @Input() colClass;
    @Input() prefix;
    @Input() maxlength;
    calcRowClass
    calcColClass

    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    attributeIdentifier = this.randomString();
    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    constructor() { 
    }

    ngOnInit() {
        this.calcRowClass = {};
        this.calcRowClass[this.rowClass] = true;
        this.calcColClass = {};
        this.calcColClass[this.colClass] = true;
    }

    doChange(e) {
        if (this.change) {
            this.change(e);
        }
    }

    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }
}
