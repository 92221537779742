import { Injectable } from '@angular/core'
import { iUserClaims } from './model/user-claim.model'

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    constructor() {}

    clear() : void
    {
        localStorage.clear()
        sessionStorage.clear()
    }

    setMirrorSubOrg(subOrgUuid: string)
    {
        localStorage.setItem('mirrored_organization_uuid', subOrgUuid)
    }

    removeMirrorSubOrg()
    {
        localStorage.removeItem('mirrored_organization_uuid')
    }
    
    setClaims(claims: any)
    {
        const claimsStr: string = JSON.stringify(claims)
        console.log(claimsStr)
        sessionStorage.setItem("claims", claimsStr)
        localStorage.setItem("claims", claimsStr)
    }

    getClaims(): iUserClaims
    {
        const claimsStr = localStorage.getItem("claims")
        if(claimsStr)
        {
            let claimsObject: iUserClaims = JSON.parse(claimsStr)
            return claimsObject
        }
        else
        {
            return
        }
    }

    // getRoles(): string[]
    // {
    //     const claims = this.getClaims()
    //     return claims.organization_type
    // }

    // getFeatures(): string[]
    // {
    //     const claims = this.getClaims()
    //     return claims.features 
    // }

    // getFullName(): string
    // {
    //     const claims = this.getClaims()
    //     return claims.fullName
    // }

    getEmailAddress(): string
    {
        const claims = this.getClaims()
        return claims.user_name
    }

    getToken(): string
    {
        return sessionStorage.getItem("access_token")
    }

    setFiscalYear(fiscalYear: string): void {
        localStorage.setItem('fiscalYear', fiscalYear);
    }

    getFiscalYear(): string {
        return localStorage.getItem('fiscalYear');
    }

    getMirroredOrganizationUuid(): string | null {
        const value = localStorage.getItem('mirrored_organization_uuid');
        if (value) {
            try {
                return JSON.parse(value) as string;
            } catch (e) {
                return value; // Return as string if parsing fails
            }
        }
        return null; // Return null if the key doesn't exist or is null
    }
    
    setDontShowDialog(isChecked: string): void {
        localStorage.setItem('isChecked', isChecked)
    }

    getDontShowDialog(): string {
        if(localStorage.getItem('isChecked') == null || localStorage.getItem('isChecked') == '') {
            localStorage.setItem('isChecked', '0')
        }

        return localStorage.getItem('isChecked')
    }
}
