import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    faCheck
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-confirmation-review',
    templateUrl: './confirmation-review.component.html',
})
export class ConfirmationReviewComponent implements OnInit {
    initialized = false
    faCheck = faCheck

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        this.initialized = true
    }

    returnToDashboard() {
        this.router.navigate(["/dashboard"])
    }
}
