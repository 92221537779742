import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-budget-amendment',
    templateUrl: './budget-amendment.component.html',
})
export class BudgetAmendmentComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
