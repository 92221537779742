import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CognitoFormsService {

    constructor(
        private http: HttpClient,
    ) { }


    downloadFile(uuid) {
        var options: any = { responseType: 'blob' };
        return this.http.get<any>("api/cognitoFormsFile/" + uuid, options);
    }

}
