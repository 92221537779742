import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';
import { switchMap, tap } from 'rxjs/operators';
import { BudgetService } from '../budget.service';

@Component({
    selector: 'app-budget-summary-detail',
    templateUrl: './budget-summary-detail.component.html',
})
export class BudgetSummaryDetailComponent implements OnInit {
    faPrint = faPrint
    // this.activatedRoute.queryParams.subscribe(params => {
    //     this.submissionUuid = params["submissionUuid"]
    //     resolve(null)
    // }))

    //this.budgetService.getSummary(this.submissionUuid)
    constructor(
        private activatedRoute: ActivatedRoute,
        private budgetService: BudgetService,
    ) { }
    faFileInvoiceDollar = faFileInvoiceDollar
    submissionUuid: string
    fiscalUuid: string

    entries$ = this.activatedRoute.queryParams.pipe(
        switchMap((params) => {
            console.log("submission uuid", params["submissionUuid"])
            this.submissionUuid = params["submissionUuid"]
            this.fiscalUuid = params['fiscalUuid']
            return this.budgetService.getSummary(this.submissionUuid, this.fiscalUuid)
        }),
        tap((summaryResponse) => {
            console.log("budget summary", summaryResponse)
        })
    )

    ngOnInit() {
        // setTimeout(() => {
        //     window.print()
        // }, 3000);
    }

    showPrintButton = true
    print() {
        this.showPrintButton = false
        setTimeout(() => {
            window.print()
        }, 0);
    }

}
