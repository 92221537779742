import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { SubmissionService } from '../submission.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-overdue-items',
    templateUrl: './overdue-items.component.html',
})
export class OverdueItemsComponent implements OnInit {
    initialized = false
    faClock = faClock

    userOrganizationType
    dash
    statuses
    statusesById
    listData
    sortField = "days_overdue"
    sortDirection = 0

    list = {
        title: "Overdue Report",
        columns: [
            {
                display: "Program",
                fieldName: "program_name",
            }, {
                display: "Submission Type",
                fieldName: "submission_type_name",
            }, {
                display: "Jurisdiction",
                fieldName: "jurisdiction_name",
            }, {
                display: "Description",
                fieldName: "name",
            }, {
                display: "Agency",
                fieldName: "organization_name",
            }, {
                display: "Due Date",
                fieldName: "due_date",
            }, {
                display: "Days Overdue",
                fieldName: "days_overdue",
            }
        ],
    }

    constructor(
        private submissionService: SubmissionService,
        private authService: AuthService,
    ) { }

    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    getDataPromise() {
        return this.submissionService.getOverdueSubmissionsPromise(this.sortField, this.sortDirection).then((result) => {
            this.listData = result
        })
    }

    ngOnInit() {
        Promise.all([
            this.getUserOrganizationType(),
            this.getDataPromise(),
        ]).then(() => {
            this.initialized = true
        })
    }

    sortClick(fieldName) {
        if (this.sortField == fieldName) {
            if (this.sortDirection == 0) {
                this.sortDirection = 1
            } else {
                this.sortDirection = 0
            }
            return this.getDataPromise()
        }
        this.sortDirection = 0;
        this.sortField = fieldName;
        return this.getDataPromise()
    }
}
