import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AgencyService } from '../agency.service';
import { QuestionService } from '../question.service';
import { Router } from '@angular/router';
import { faPlus, faQuestion, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { SnackRouteService } from '../snack-route.service';

@Component({
    selector: 'app-manage-questions',
    templateUrl: './manage-questions.component.html',
    styleUrls: ['./manage-questions.component.css']
})
export class ManageQuestionsComponent implements OnInit {
    faPlus = faPlus
    initialized = false
    faQuestionCircle = faQuestionCircle
    constructor(
        private snackService: SnackRouteService,
        private agencyService: AgencyService,
        private questionService: QuestionService,
        private router: Router,
    ) { }

    programs
    programFC = new UntypedFormControl(localStorage.getItem("manage-questions-program"))

    stepSelectValues
    stepSelectFC = new UntypedFormControl(localStorage.getItem("manage-questions-step"))
    stepSelectDisplay

    groupData


    reloadTestProgram() {
        this.questionService.reloadTestProgram().subscribe((response) => {
            if (response.success == true) {
                this.snackService.snack("Reload test program complete.")
            } else {
                this.snackService.snack("Error resetting test program questions.")
            }
        })
    }

    getProgramsPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getProgramsWithAll().subscribe((response) => {
                if (response.success) {
                    this.programs = response.result
                    //console.log("programs", this.programs)
                }
                if (!this.programFC.value && this.programs && this.programs.length > 0) {
                    this.programFC.setValue(this.programs[0].id)
                }
                resolve(null)
            })
        })
    }

    setStepSelectDisplay() {
        for (var i = 0; i < this.stepSelectValues.length; ++i) {
            if (this.stepSelectValues[i].id == this.stepSelectFC.value) {
                this.stepSelectDisplay = this.stepSelectValues[i].name
                break
            }
        }
    }

    getStepPromise() {
        return new Promise((resolve, reject) => {
            this.questionService.getStepsByProgram(this.programFC.value).subscribe((response) => {
                if (response.success) {
                    //console.log("step data", response)
                    this.stepSelectValues = response.result
                }
                if (!this.stepSelectFC.value && this.stepSelectValues && this.stepSelectValues.length > 0) {
                    this.stepSelectFC.setValue(this.stepSelectValues[0].id)
                    this.setStepSelectDisplay()
                }
                resolve(null)
            })
        })
    }

    getGroupsPromise() {
        return new Promise((resolve, reject) => {
            this.questionService.getGroupsByStep(this.stepSelectFC.value).subscribe((response) => {
                if (response.success) {
                    //console.log("group response", response)
                    this.groupData = []
                    var lastGroup = null
                    for (var i = 0; i < response.result.length; ++i) {
                        if (lastGroup == null || lastGroup.group_uuid != response.result[i].group_uuid) {
                            lastGroup = response.result[i]
                            this.groupData.push(lastGroup)
                            lastGroup.children = []
                        }
                        lastGroup.children.push(response.result[i])
                    }
                    //console.log("group data", this.groupData)
                } else {
                    console.log("error while fetching group data", response)
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        this.getProgramsPromise().then(() => {
            return this.getStepPromise()
        }).then(() => {
            return this.getGroupsPromise()
        }).then(() => {
            this.initialized = true
        })
    }

    changeProgram() {
        localStorage.setItem("manage-questions-program", this.programFC.value)
        this.getStepPromise().then(() => {
            this.stepSelectFC.setValue(this.stepSelectValues[0].id)
            return this.getGroupsPromise()
        }).then(() => {
        })
    }

    changeStepSelection() {
        localStorage.setItem("manage-questions-step", this.stepSelectFC.value)
        this.setStepSelectDisplay()
        this.getGroupsPromise().then(() => {
        })
    }

    addGroupClick() {
        this.router.navigate(['/edit-question-group'], { queryParams: { stepId: this.stepSelectFC.value } })
    }

    addQuestionClick(groupUuid) {
        this.router.navigate(['/edit-question'], { queryParams: { groupId: groupUuid } })
    }
}
