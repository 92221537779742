import { Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { FlagCommentDialog } from './flag-comment-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PersonnelService } from '../services/personnel.service';
import { SessionService } from '../session.service';


@Component({
  selector: 'app-flag-comments',
  templateUrl: './flag-comments.component.html',
  styleUrls: ['./flag-comments.component.css']
})
export class FlagCommentsComponent implements OnInit {
  @Input() masterUuid: string
  @Input() submissionUuid: string
  @Input() accountUuid?: string
  @Input() targetColumn: string
  @Output() commentExistsChange = new EventEmitter<boolean>();

  commentExists: boolean
  commentValue: string = ''
  orgType: string

  
  constructor(
    private dialog: MatDialog,
    private personnelService: PersonnelService,
    private sessionService: SessionService
  ) { }

  showCommentDialog() {
    const dialogRef = this.dialog.open(FlagCommentDialog, {
      width: '600px',
      data: {
        masterUuid: this.masterUuid, 
        submissionUuid: this.submissionUuid,
        targetColumn: this.targetColumn,
        comment: this.commentValue
      }
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      if(result == 'success') {
        console.log('Comment saved successfully')
        window.location.reload()
      }
      if(result == 'deleted') {
        console.log('Comment deleted successfully')
        window.location.reload()
      }
    });

  }

  ngOnInit() {
    if(this.accountUuid) {
      this.targetColumn = this.targetColumn + "_" + this.accountUuid
    }

    // console.log(this.targetColumn)
    this.personnelService.getPersonnelComment(
      this.masterUuid, 
      this.submissionUuid, 
      this.targetColumn).subscribe(response => {
      // console.log(response)
      if(response.success === true) {
        this.commentExists = true
        this.commentValue = response.result
      } else {
        this.commentExists = false
      }
    })

    const claims = this.sessionService.getClaims()
    this.orgType = claims.organization_type
  }
}
