import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-input-enum-async',
    templateUrl: './input-enum-async.component.html',
    styleUrls: ['./input-enum-async.component.css']
})
export class InputEnumAsyncComponent implements OnInit {
    @Input() fc;
    @Input() enum;
    @Input() disabled;

    constructor() { }

    ngOnInit() {
    }

}
