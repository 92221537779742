import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { ProgramReportView } from './model/program-report-view.model';
import { ProgramReport } from './model/program-report.model';

// Created a new service component for program - Brock 3/7/23

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  constructor( 
    private http: HttpClient
  ) { }

  setProgramReportModelProperty(programReportModel: ProgramReportView, programReport: ProgramReport) {
    switch(programReport.columnId) {
      //case "description": programReportModel.description = programReport.value; break; // removed in model update
      case "Q0": programReportModel.q0 = programReport.value; break;
      case "Q1": programReportModel.q1 = programReport.value; break;
      case "Q2": programReportModel.q2 = programReport.value; break;
      case "Q3": programReportModel.q3 = programReport.value; break;
      case "Q4": programReportModel.q4 = programReport.value; break;
      default: // do nothing
    }
  }

  convertEntityToModel(entities: ProgramReport[]): ProgramReportView[] {
    let programModels: ProgramReportView[] = []
    let programModelMap = new Map<any, ProgramReportView>()

    entities.forEach(entity => {
      let programModel = programModelMap.get(entity.rowId)

      if(programModel) {
        this.setProgramReportModelProperty(programModel, entity)
      } else {
        programModel = new ProgramReportView(entity.uuid, entity.rowId, entity.description)
        this.setProgramReportModelProperty(programModel, entity)
      }
      programModelMap.set(entity.rowId, programModel)
    })

    programModelMap.forEach(element => {
      programModels.push(element)
    })


    return programModels
  }


  programDropDown(): Observable<any> {
    return this.http.get<any>("api/programDropDown")
  }

  yearDropDown(programId: string): Observable<any> {
    return this.http.get<any>("api/yearDropDown/" + programId)
  }

  agencyDropDown(programId: string, fiscalYearId: string): Observable<any> {
    return this.http.get<any>("api/agencyDropDown/" + programId + "/" + fiscalYearId)
  }

  quarterDropDown(programId: string, fiscalYearId: string, organizationUuid: string): Observable<any> {
    return this.http.get<any>("api/quarterDropDown/" + programId + "/" + fiscalYearId + "/" + organizationUuid)
  }

  countyDropDown(organizationUuid: string, fiscalYearId: string): Observable<any> {
    console.log(fiscalYearId)
    return this.http.get<any>("api/countyDropDown/" + organizationUuid + "/" + fiscalYearId)
  }

  getProgramReport(programId: string, fiscalYearId: string, organizationUuid: string, quarterId: string, countyId: string): Observable<any> {
    return this.http.post<any>("api/programReport/", {programId: programId, fiscalYearId: fiscalYearId, organizationUuid: organizationUuid, quarterId: quarterId, countyId: countyId})
  }
}
