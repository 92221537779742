<div *ngIf="initialized" class="container-fluid">
    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <div class="row mb-2">
                <div class="col bold" style="background-color: lightgrey; font-size: 1.2em;">
                    Document Information
                </div>
            </div>

            <app-input-text label="Document Name" [fc]="documentNameFC" [maxlength]="50"></app-input-text>
            <div class="row mt-2">
                <div class="col">
                </div>
            </div>
            <app-input-text-area label="Document Instructions" [fc]="documentInstructionFC" [maxlength]="4000">
            </app-input-text-area>
            <hr />
            <app-input-enum label="Document For" [fc]="documentForFC" [enumValues]="documentForValues"
                [style]="'rebrand2-role'"></app-input-enum>
            <hr />
            <div class="row">
                <div class="col bold">
                    Applies To
                </div>
            </div>
            <span *ngFor="let submissionType of submissionTypes">
                <div class="row">
                    <div class="col-1 px-0" align="right">
                        <app-checkbox [fc]="submissionType.fc" [id]="'cb_'+submissionType.name"></app-checkbox>
                    </div>
                    <div class="col-11 bold">
                        <label class="ml-1 mb-0" [for]="'cb_'+submissionType.name"
                            style="cursor: pointer;">{{submissionType.name}}</label>
                    </div>
                </div>
            </span>
            <hr />
            <app-input-enum label="Cardinality" [fc]="requiredFC" [enumValues]="requiredValues"
                [style]="'rebrand2-role'">
            </app-input-enum>
            <hr />

            <div class="row">
                <div class="col">
                    <app-checkbox [id]="'cb_recurring'" [fc]="isRecurringFC"
                        [change]="updateReoccurEnableState.bind(this)"></app-checkbox>
                    <label class="ml-1 mb-0 bold" [for]="'cb_recurring'" style="cursor: pointer;">This document is on a
                        recurring schedule</label>
                </div>
            </div>

            <div class="row pt-2">
                <div class="col">
                </div>
            </div>
            <div id="reoccur_attributes">
                <div class="row pt-2">
                    <div class="col">
                    </div>
                </div>
                <app-input-text-and-enum label="Reoccur every" [fc]="reoccurEveryValueFC" [enumFC]="reoccurEveryUnitsFC"
                    [enumValues]="frequencies"></app-input-text-and-enum>
                <app-input-text label="Create" units="days in advance" [fc]="createFC"></app-input-text>

                <app-input-enum label="Reoccurs on" [fc]="reoccursOnFC" [enumValues]="reoccurAgencyDates"
                    [dateInputs]="dateInputs" [style]="'rebrand2-role'"></app-input-enum>
                <div class="row pt-2">
                    <div class="col">
                    </div>
                </div>
            </div>

            <div class="row mt-4 mb-1">
                <div class="col mb-4" align="center">
                    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                    </app-button3>
                    <app-button3 *ngIf="!documentUuid" class="mx-1" label="Add" [clickFunction]="add.bind(this)"
                        [icon]="faSave" [isFinal]="true">
                    </app-button3>
                    <app-button3 *ngIf="documentUuid" class="mx-1" label="Update" [clickFunction]="update.bind(this)"
                        [icon]="faSave" [isFinal]="true">
                    </app-button3>
                </div>
            </div>
        </div>
    </div>
</div>