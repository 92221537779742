import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MainTargetService } from '../main-target.service'

@Component({
    selector: 'main-target-entity-edit',
    templateUrl: './main-target-entity-edit.component.html',
    styleUrls: ['./main-target-entity-edit.component.css']
})
export class MainTargetEntityEditComponent implements OnInit {
    form: UntypedFormGroup
    agency_uuid: string
    agency_name: string
    county_name: string
    county_id: string
    main_target: number
    outreach_target: number
    person_target: number
    prescreen_target: number
    recertification_target: number
    barrier_target: number
    collaboration_target: number

	constructor(
        //@Inject(MAT_DIALOG_DATA) public data: string,
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private mainTargetService: MainTargetService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            this.agency_uuid = data.agency_uuid
            this.agency_name = data.agency_name
            this.county_name = data.county_name
            this.county_id = data.county_id
            this.main_target = data.main_target
            this.outreach_target = data.outreach_target
            this.person_target = data.person_target
            this.prescreen_target = data.prescreen_target
            this.recertification_target = data.recertification_target
            this.barrier_target = data.barrier_target
            this.collaboration_target = data.collaboration_target
        }

    close() {
        this.dialog.closeAll()
        window.location.reload()
    }

    displayError() {
        this.dialog.open(
            MainTargetEntityEditErrorComponent,
            { data: { error: 'Unable to edit record!'}}
        )
    }

    save() {
        this.mainTargetService.mainTargetEntityUpdate(
            this.agency_uuid,
            this.agency_name,
            this.county_name,
            this.county_id,
            this.form.value.main_target,
            this.form.value.outreach_target,
            this.form.value.person_target,
            this.form.value.prescreen_target,
            this.form.value.recertification_target,
            this.form.value.barrier_target,
            this.form.value.collaboration_target
        ).subscribe((result) => {
            if(result.success == true) {
                this.close()
            } else {
                this.displayError()
            }
        }, err => console.log("error:", err))
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            main_target: this.main_target,
            outreach_target: this.outreach_target,
            person_target: this.person_target,
            prescreen_target: this.prescreen_target,
            recertification_target: this.recertification_target,
            barrier_target: this.barrier_target,
            collaboration_target: this.collaboration_target
        })
    }
}


@Component({
  selector: 'main-target-entity-error',
  template: 'Error {{ data.error }}',
})
export class MainTargetEntityEditErrorComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {error: string}) { }
}