<div *ngIf="!isFooter" class="row">
    <div class="col">
        <div class="bold" style="float: left; font-size: 1.3em;">
            {{title}}
        </div>
        <div style="float: right">
            <span (click)="previous()" class="link bold mx-3">Previous</span>

            <app-page-controls-button *ngIf="pageNumber > 2" class="mx-1" label="..."
                [clickFunction]="first.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber > 1" class="mx-1" [label]="pageNumber - 1"
                [clickFunction]="previous.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button class="mx-1" [label]="pageNumber" [clickFunction]="current.bind(this)"
                [altStyle]="'true'">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber < maxPage" class="mx-1" [label]="pageNumber + 1"
                [clickFunction]="next.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber < maxPage - 1" class="mx-1" label="..."
                [clickFunction]="afterNext.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber < maxPage - 2" class="mx-1" [label]="maxPage"
                [clickFunction]="last.bind(this)">
            </app-page-controls-button>
            <span (click)="next()" class="link bold mx-3">Next</span>
        </div>

        <div class="mr-3 bold" style="float: right; font-size: 1.1em;">
            Showing {{firstResultNum}}-{{lastResultNum}} / {{entryCount}} Results
        </div>
    </div>
</div>

<div *ngIf="isFooter" class="row">
    <div class="col">
        <div class="mr-3 bold" style="float: left; font-size: 1.1em;">
            Showing {{firstResultNum}}-{{lastResultNum}} / {{entryCount}} Results
        </div>

        <div style="float: right">
            <span (click)="previous()" class="link bold mx-3">Previous</span>

            <app-page-controls-button *ngIf="pageNumber > 2" class="mx-1" label="..."
                [clickFunction]="first.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber > 1" class="mx-1" [label]="pageNumber - 1"
                [clickFunction]="previous.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button class="mx-1" [label]="pageNumber" [clickFunction]="current.bind(this)"
                [altStyle]="'true'">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber < maxPage" class="mx-1" [label]="pageNumber + 1"
                [clickFunction]="next.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber < maxPage - 1" class="mx-1" label="..."
                [clickFunction]="afterNext.bind(this)">
            </app-page-controls-button>
            <app-page-controls-button *ngIf="pageNumber < maxPage - 2" class="mx-1" [label]="maxPage"
                [clickFunction]="last.bind(this)">
            </app-page-controls-button>
            <span (click)="next()" class="link bold mx-3">Next</span>
        </div>

    </div>
</div>
