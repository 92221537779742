<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faShoePrints"></fa-icon>
            Edit Step
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <span *ngIf="stepId == null">
                No step specified
            </span>

            <span *ngIf="stepId">
                <app-input-text label="Step Name" [fc]="stepFC" [maxlength]="50">
                </app-input-text>
                <app-input-text label="Sort Order" [fc]="displayFC" [maxlength]="6">
                </app-input-text>
                <app-input-text-area rows="6" label="Instructions" [fc]="instructionsFC" [maxlength]="1000">
                </app-input-text-area>

                <div class="row mt-3">
                    <div class="col" align="center">
                        <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                        </app-button3>
                        <app-button3 class="mx-1" label="Save" [clickFunction]="save.bind(this)" [icon]="faSave"
                            [isFinal]="true">
                        </app-button3>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>