<form [formGroup]="filterForm">
    <div class="form-container">
      <mat-form-field class="filter-field">
        <mat-label>Filter</mat-label>
        <input matInput formControlName="filter" placeholder="Type to filter">
      </mat-form-field>
  
      <mat-form-field class="dropdown-field">
        <mat-label>User Status:</mat-label>
        <mat-select formControlName="selectedUserStatus" placeholder="Select User Status">
          <mat-option *ngFor="let status of userStatuses" [value]="status.id">{{ status.name }}</mat-option>
        </mat-select>
      </mat-form-field>
  
      <button mat-raised-button color="primary" (click)="addUserDialog()">Add User</button>
      <button mat-raised-button color="primary" (click)="addOrg()">Add Agency</button>
    </div>
</form>

<div class="table-container">
  <table mat-table [dataSource]="users" class="mat-elevation-z8" matSort>
    <!-- Organization -->
    <ng-container matColumnDef="orgName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization Name </th>
        <td mat-cell *matCellDef="let user">
          <div>
            <div class="icon-and-name">
              <span class="clickable-data" (click)="viewOrg(user.orgUuid)">{{ user.orgName }}</span>
              <div *ngIf="user.orgUuid != 'HSNY' && isAdmin">
                <fa-icon [icon]="faCog" (click)="openRolesDialog(currentUserUuid, user.orgUuid)"></fa-icon>
              </div>
            </div>
          </div>
        </td> 
    </ng-container>
  
    <!-- Document Column -->
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let user"> {{ user.fullName }} </td>
    </ng-container>
  
    <!-- Email -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let user" class="clickable-data" (click)="viewUser(user)"> {{ user.email }} </td>
    </ng-container>
  
    <!-- Status Column -->
    <ng-container matColumnDef="userStatusId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let user" class="clickable-data" (click)="editStatusDialog(user)"> {{ user.userStatusId }} </td>
      </ng-container>
  
    <!-- Last Login Column -->
    <ng-container matColumnDef="lastLogin">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Login </th>
      <td mat-cell *matCellDef="let user"> {{ user.lastLogin | date:'MM/dd/yyyy' }} </td>
    </ng-container>
  
    <!-- Button Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
      <td mat-cell *matCellDef="let user">
        <button mat-raised-button 
                [color]="(userActionStatus[user.uuid] && userActionStatus[user.uuid]['resetPassword']) ? 'green' : 'primary'"
                [disabled]="(userActionStatus[user.uuid] && userActionStatus[user.uuid]['resetPassword'])"
                (click)="userAction('resetPassword', user)">
          {{ (userActionStatus[user.uuid] && userActionStatus[user.uuid]['resetPassword']) ? 'Sent' : 'Reset Password' }}
        </button>
      </td>
    </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

