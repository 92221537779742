<div *ngIf="!style" class="row">
    <div class="col-4 pb-2 unedit-padding bold">
        <fa-icon *ngIf="icon" [icon]="icon" class="mr-2"></fa-icon>
        {{label}}
    </div>
    <div class="col-8">
        <input *ngIf="!disabled" type="file" [formControl]="fileFormControl" id="file"
            (change)="fileSelect($event.target.files)">
    </div>
</div>
<div *ngIf="!style && error" class="row">
    <div class="col-4">
    </div>
    <div class="col-8 bold">
        {{error}}
    </div>
</div>
<!-- <div *ngIf="!style && uploadStatus" class="row pt-4">
    <div class="col attribute-h2 bold">
        {{uploadStatus}}
    </div>
</div> -->
<div *ngIf="!style && percentDone != null" class="row py-2 ">
    <div class="col">
        <div style="position: relative; height: 1.6em;">
            <div [ngStyle]="{ 'background-color': 'lightblue', 'width': percentDone + '%'}"
                style="height: 1.6em; position: absolute; left: 0; top: 0;">
            </div>
            <div [ngStyle]="{ 'width':  '100%'}"
                style="height: 1.6em; position: absolute; left: 0; top: 0; font-weight: bold;text-align: center;">
                {{percentDone}}%
            </div>
            <div
                style="width: 100%; border-style: solid; border-width: 1px; border-color: black; height: 1.6em; position: absolute; left: 0; top: 0;">
            </div>
        </div>
    </div>
</div>