<h1 mat-dialog-title>Edit Fringe</h1>
<mat-dialog-content>
<form class="form" [formGroup]="tableTwoForm">
    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Benefit</mat-label>
          <input matInput formControlName="benefit">
        </mat-form-field>
    </p>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Annual Premium</mat-label>
          <input matInput type="number" formControlName="annualPremium" appRoundUp>
        </mat-form-field>

        <app-flag-comments 
        *ngIf="showFlag"
        class="flagButton" 
        [masterUuid]="masterUuid" 
        [submissionUuid]="submissionUuid"
        [accountUuid]="data.fringe.accountUuid"
        targetColumn="fringe_annual_premium">
      </app-flag-comments>
    </p>

    <p class="form-field">
      <mat-form-field appearance="fill">
        <mat-label>Employer %</mat-label>
        <input matInput type="number" formControlName="employerPercent">
        <mat-error *ngIf="tableTwoForm.get('employerPercent').hasError('invalidPercentage')">
          Invalid percentage value. Please enter a value less than or equal to 100.
        </mat-error>
      </mat-form-field>

      <app-flag-comments 
      *ngIf="showFlag"
      class="flagButton" 
      [masterUuid]="masterUuid" 
      [submissionUuid]="submissionUuid"
      [accountUuid]="data.fringe.accountUuid"
      targetColumn="employer_percent">
    </app-flag-comments>
    </p>

    <!-- <p class="form-field">
      <mat-form-field appearance="fill">
        <mat-label>Employee %</mat-label>
        <input matInput type="number" formControlName="employeePercent">
        <mat-error *ngIf="tableTwoForm.get('employeePercent').hasError('invalidPercentage')">
          Invalid percentage value. Please enter a value less than or equal to 100.
        </mat-error>
      </mat-form-field>

      <app-flag-comments 
      *ngIf="showFlag"
      class="flagButton" 
      [masterUuid]="masterUuid" 
      [submissionUuid]="submissionUuid"
      [accountUuid]="data.fringe.accountUuid"
      targetColumn="employee_percent">
    </app-flag-comments>
    </p> -->

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Calculated Amount</mat-label>
          <input matInput formControlName="calculatedAmount">
        </mat-form-field>
    </p>

    <p class="form-field">
      <mat-form-field appearance="fill">
        <mat-label>Amount Charged to Contract</mat-label>
        <input matInput type="number" formControlName="amountChargedToContract" appRoundUp>
        <mat-error *ngIf="tableTwoForm.get('amountChargedToContract').hasError('invalidAmount')">
          Amount charged to contract is greater than calculated amount
        </mat-error>
        <mat-error *ngIf="tableTwoForm.get('amountChargedToContract').hasError('amountMismatch')">
          Amount charged to contract is not equal to total charged to contract
        </mat-error>
      </mat-form-field>
      <app-flag-comments 
      *ngIf="showFlag"
      class="flagButton" 
      [masterUuid]="masterUuid" 
      [submissionUuid]="submissionUuid"
      [accountUuid]="data.fringe.accountUuid"
      targetColumn="unit_price">
    </app-flag-comments>
    </p>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>State Only</mat-label>
          <input matInput type="number" formControlName="stateOnly" appRoundUp>
        </mat-form-field>
        <app-flag-comments 
        *ngIf="showFlag"
        class="flagButton" 
        [masterUuid]="masterUuid" 
        [submissionUuid]="submissionUuid"
        [accountUuid]="data.fringe.accountUuid"
        targetColumn="debit_state">
      </app-flag-comments>
    </p>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>State/Federal Match</mat-label>
          <input matInput type="number" formControlName="stateFedMatch" appRoundUp>
        </mat-form-field>

        <app-flag-comments 
        *ngIf="showFlag"
        class="flagButton" 
        [masterUuid]="masterUuid" 
        [submissionUuid]="submissionUuid"
        [accountUuid]="data.fringe.accountUuid"
        targetColumn="debit_federal">
      </app-flag-comments>
    </p>

    <ng-container *ngIf="showCalculations">
      <p class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>State Only +</mat-label>
            <input matInput formControlName="stateOnlyCalc">
          </mat-form-field>
      </p>

      <p class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>State Match +</mat-label>
            <input matInput formControlName="stateMatchCalc">
          </mat-form-field>
      </p>

      <p class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>Federal Match +</mat-label>
            <input matInput formControlName="fedMatchCalc">
          </mat-form-field>
      </p>
    </ng-container>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>= Total Charged to Contract</mat-label>
          <input matInput formControlName="totalChargedToContractCalc">
        </mat-form-field>
    </p>

    <p class="form-field">
      <mat-form-field appearance="fill">
        <mat-label>Calculation Description</mat-label>
        <textarea matInput formControlName="agencyComment"></textarea>
        <mat-error *ngIf="tableTwoForm.get('agencyComment').hasError('isRequired')">
          Please enter your calculations for annual premium
        </mat-error>
      </mat-form-field>
      <app-flag-comments 
      *ngIf="showFlag"
      class="flagButton" 
      [masterUuid]="masterUuid" 
      [submissionUuid]="submissionUuid"
      [accountUuid]="data.fringe.accountUuid"
      targetColumn="agency_comment">
    </app-flag-comments>
    </p>
</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button class="save-button" color="primary" (click)="onSave()">Save</button>
    <button mat-raised-button mat-dialog-close class="cancel-button">Cancel</button>
</mat-dialog-actions>