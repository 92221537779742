import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-outreach-approval-validation-error',
    templateUrl: './outreach-approval-validation-error.component.html',
})
export class OutreachApprovalValidationErrorComponent implements OnInit {
    @Input() error
    @Input() odd
    constructor() { }

    ngOnInit() {
    }

}
