<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faClock"></fa-icon>
            Overdue Transactions
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h2 class="table-title push-y-4">{{this.list.title}}</h2>
        </div>
    </div>
    <div class="row">
        <div class="col px-0">
            <table width="100%">
                <tr style="background-color: lightgrey">
                    <th *ngFor="let column of this.list.columns; let index = index">
                        <span [style.paddingLeft]="index == 0 ? '15px' : ''" class="sort-column-header"
                            (click)="sortClick(column.fieldName)">
                            {{column.display}}
                        </span>
                        <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                            [sortIsDescending]="this.sortDirection" [columnFieldName]="column.fieldName">
                        </app-sort-indicator>
                    </th>
                </tr>

                <div *ngIf="listData.length == 0" class="row">
                    <div class="col" align="center">
                        &lt;No records to display&gt;
                    </div>
                </div>

                <tr *ngFor="let item of listData">
                    <td class="pl-3">
                        {{item.program_name}}
                    </td>
                    <td>
                        {{item.submission_type_name}}
                    </td>
                    <td>
                        {{item.jurisdiction_name}}
                    </td>
                    <td>
                        {{item.name}}
                    </td>
                    <td>
                        {{item.organization_name}}
                    </td>
                    <td>
                        {{item.due_date | date : 'M/d/y'}}
                    </td>
                    <td>
                        {{item.days_overdue}}
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>