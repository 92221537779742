<div
    style="width: 100%; height: 2.5em; background-color: lightgrey; padding-top: .5em; padding-left: .5em; position: relative">
    <span *ngIf="newEntry">Add</span>
    <span *ngIf="!newEntry">Edit</span>
    {{typeFC.value}}
    <div #theButton style="position: absolute; right: .5em; top: .5em;">
        <fa-icon [icon]="faTimes" title="Cancel" (click)="cancel()" style="cursor: pointer"
            (mouseenter)="theButton.style.color='blue'" (mouseleave)="theButton.style.color=null">
        </fa-icon>
    </div>
</div>

<div style="padding-left: .5em" class="mt-3">
    <div class="container-fluid">
        <span *ngFor="let column of data.definition">

            <span *ngIf="showFieldAsFK(column)">
                <app-input-enum [label]="column.Field" [fc]="column.createFC"
                    [enumValuesNameAndId]="referencedTypes[column.referenced_table_name].enumNameAndId"
                    includeUnsetDisplay="" [disabled]="!newEntry && column.Key == 'PRI'">
                </app-input-enum>
            </span>

            <span *ngIf="showFieldAsDate(column)">
                <app-input-date-validatable [label]="column.Field" [fc]="column.createFC"
                    [disabled]="!newEntry && column.Key == 'PRI'">
                </app-input-date-validatable>
            </span>

            <span *ngIf="showFieldAsTextArea(column)">
                <app-input-text-area [label]="column.Field" [fc]="column.createFC"
                    [disabled]="!newEntry && column.Key == 'PRI'">
                </app-input-text-area>
            </span>

            <span *ngIf="showFieldAsText(column)">
                <app-input-text [label]="column.Field" [fc]="column.createFC"
                    [disabled]="!newEntry && column.Key == 'PRI'">
                </app-input-text>
                <div *ngIf="showFieldGenerateUuid(column)" class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-8">
                        <span (click)="fieldGenerateUuid(column)"
                            style="font-size: .8em; cursor: pointer;">(generate)</span>
                    </div>
                </div>
            </span>
        </span>

        <div class="row mt-3">
            <div class="col" align="center">
                <app-button3 *ngIf="showUpdateButton()" class="mx-1" label="Delete" [clickFunction]="delete.bind(this)"
                    [icon]="faMinus" [isDelete]="true">
                </app-button3>
                <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                </app-button3>
                <app-button3 *ngIf="showClearButton()" class="mx-1" label="Clear" [clickFunction]="clear.bind(this)"
                    [icon]="faBan">
                </app-button3>
                <app-button3 *ngIf="showCreateButton()" class="mx-1" label="Create" [clickFunction]="create.bind(this)"
                    [icon]="faSave">
                </app-button3>
                <app-button3 *ngIf="showUpdateButton()" class="mx-1" label="Update" [clickFunction]="update.bind(this)"
                    [icon]="faSave" [isFinal]="true">
                </app-button3>
            </div>
        </div>
    </div>
</div>