<span style="white-space: normal; position: relative;">
    <fa-icon class="filter-icon"
        [ngClass]="{'filter-icon-active': matchControls[0].valueFC.value, 'filter-icon-showing': showFilterInput }"
        [icon]="faFilter" (click)="openFilterInput()" #filtericon></fa-icon>
    <div class="filter-container px-2 py-2" [ngClass]="{'filter-left': !alignRight, 'filter-right': alignRight }"
        *ngIf="showFilterInput" style="opacity: 0;" #container id="{{containerUuid}}">
        <div class="row">
            <div class="col text-center">
                {{column.name}} Filter
            </div>
        </div>

        <div class="row mt-1" *ngFor="let control of matchControls; let index = index">
            <div class="col text-center" style="white-space: nowrap;">
                <span *ngIf="!values
                    && (!column || !column.Type || column.Type != 'datetime')
                    && (!column || !column.Type || column.Type != 'int')
                    && (!column || !column.Type || !column.Type.startsWith('decimal'))">
                    <select [formControl]="control.operationFC">
                        <option *ngFor="let enumValue of stringOperationOptions" value={{enumValue.id}}>
                            {{enumValue.name}}
                        </option>
                    </select>
                    <input *ngIf="!values" type="text" [formControl]="control.valueFC">
                </span>

                <span *ngIf="values">
                    <select [formControl]="control.operationFC">
                        <option *ngFor="let enumValue of enumOperationOptions" value={{enumValue.id}}>
                            {{enumValue.name}}
                        </option>
                    </select>

                    <select [formControl]="control.valueFC">
                        <option *ngFor="let enumValue of values.result" value={{enumValue.id}}>
                            {{enumValue.name}}
                        </option>
                    </select>
                </span>

                <span *ngIf="column && column.Type == 'datetime'">
                    <select [formControl]="control.operationFC">
                        <option *ngFor="let enumValue of dateOperationOptions" value={{enumValue.id}}>
                            {{enumValue.name}}
                        </option>
                    </select>
                    <app-input-date [fc]="control.valueFC" [format]="InputDateFormat.none"></app-input-date>
                </span>

                <span *ngIf="column && column.Type && column.Type.startsWith('decimal') || column.Type == 'int'">
                    <select [formControl]="control.operationFC">
                        <option *ngFor="let enumValue of numberOperationOptions" value={{enumValue.id}}>
                            {{enumValue.name}}
                        </option>
                    </select>
                    <input type="number" [formControl]="control.valueFC">
                </span>

                <fa-icon class="filter-icon ml-1" [icon]="faPlus" (click)="addFilter(index)" title="Add Filter Rule">
                </fa-icon>
                <fa-icon *ngIf="matchControls.length > 1" class="filter-icon ml-1" [icon]="faMinus"
                    title="Remove Filter Rule" (click)="removeFilter(index)"></fa-icon>
            </div>
        </div>

        <div *ngIf="matchControls.length > 1" class="row mt-1">
            <div class="col text-center">
                Rule Logic&nbsp;
                <select *ngIf="!values" [formControl]="matchTypeFC">
                    <option *ngFor="let enumValue of matchTypeOptions" value={{enumValue.id}}>
                        {{enumValue.name}}
                    </option>
                </select>
                <select *ngIf="values" [formControl]="matchTypeFC">
                    <option *ngFor="let enumValue of enumMatchTypeOptions" value={{enumValue.id}}>
                        {{enumValue.name}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col text-center" style="white-space: nowrap;">
                <app-button3 label="Clear" [clickFunction]="clear.bind(this)" [icon]="faBan" [small]="true">
                </app-button3>
                <app-button3 label="Close" [clickFunction]="closeFilterInput.bind(this)" [icon]="faTimes"
                    [small]="true">
                </app-button3>
            </div>
        </div>
    </div>
</span>