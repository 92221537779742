<div>
    <div class="radio-group" mat-dialog-content>
        <label>Choose a Personnel Type</label>
        <mat-radio-group [formControl]="personnelType">
            <mat-radio-button value="NOEP_PRSNL_OH">Administrative</mat-radio-button>
            <mat-radio-button value="NOEP_PRSNL">Program</mat-radio-button>
        </mat-radio-group>
    </div>

    <mat-dialog-actions align="end">
      <button mat-raised-button color="primary" (click)="addPersonnel()">Confirm</button>
      <button mat-raised-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</div>