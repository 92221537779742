import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SnackRouteService } from '../snack-route.service';
import { SubmissionService } from '../submission.service';

@Component({
    selector: 'app-void-submission-dialog',
    templateUrl: 'void-submission-dialog.component.html',
    styleUrls: ['void-submission-dialog.component.css']
})
export class VoidDialogElements {
  submissionUuid: string
  voidCheck: boolean
  previousUuid: string

  constructor(
    public dialog: MatDialog,
    public submissionService: SubmissionService,
    public snackRoute: SnackRouteService,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
        this.submissionUuid = data.submissionUuid
        this.previousUuid = data.previousUuid
    }

  closeVoidDialog() {
    this.dialog.closeAll()
  }

  voidDialog() {
    console.log(this.submissionUuid, this.previousUuid)
    this.submissionService.voidSubmission(this.submissionUuid, this.previousUuid).subscribe((result => {
      console.log(result)
      if(result.success === true) {
        this.snackRoute.snack('Void successful!')
        this.dialog.closeAll()
        console.log('Void Successful')
        window.location.assign('/dashboard')
      }
    }))
  }
}