import { Component, Input, OnInit } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-budget-agency-comment',
    templateUrl: './budget-agency-comment.component.html',
})
export class BudgetAgencyCommentComponent implements OnInit {
    @Input() commentFC: any
    @Input() organizationType: any
    @Input() submission_status: string
    @Input() sub_action: string
    @Input() prime_action: string

    showCreateButton = true
    faEdit = faEdit
    editable = true
    

    constructor() { }


    ngOnInit() {
        console.log('prime', this.prime_action, 'sub', this.sub_action)

        if(this.sub_action == 'READ_ONLY') {
            this.showCreateButton = false
            this.editable = false
        }

        if(this.sub_action == 'READ_ONLY') {
            this.showCreateButton = false
            this.editable = false
        }

        if(this.organizationType == 'PRIME') {
            this.showCreateButton = false
            this.editable = false
        }

        if(this.submission_status == 'APPROVED') {
            this.showCreateButton = false
            this.editable = false
        }

        /*
        if(this.submission_status == 'SUBMITTED') {
            this.showCreateButton = false
            this.editable = false
        }
        */

        if (this.commentFC.value) {
            this.showCreateButton = false
        }

    }

    showCommentInput() {
        this.showCreateButton = false
    }

}
