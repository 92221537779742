import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-text',
    templateUrl: './text.component.html',
})
export class TextComponent implements OnInit {
    @Input() fc;
    @Input() disabled;
    @Input() maxlength;
    @Input() width;

    constructor() {
    }

    ngOnInit() {
    }

}
