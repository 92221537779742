<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faHandsHelping"></fa-icon>
            New Outreach Campaign
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">

            <div class="row pt-2">
                <div class="col-3 bold">
                    Program
                </div>
                <div class="col-3">
                    <app-input-enum [fc]="programFC" [enumValuesNameAndId]="programs" [style]="'none'"
                        [change]="inputChange.bind(this)">
                    </app-input-enum>
                </div>
            </div>
            <app-outreach-approval-validation-error [error]="programValidationError">
            </app-outreach-approval-validation-error>

            <div class="row pt-2">
                <div class="col-3 bold">
                    County
                </div>
                <div class="col-3">
                    <app-input-enum [fc]="countyFC" [enumValuesNameAndId]="countiesByProgramId[programFC.value]"
                        [style]="'none'" [change]="inputChange.bind(this)">
                    </app-input-enum>
                </div>
            </div>
            <app-outreach-approval-validation-error [error]="countyValidationError">
            </app-outreach-approval-validation-error>

            <div class="row pt-2">
                <div class="col-3 bold">
                    Campaign/Outreach
                </div>
                <div class="col-3">
                    <app-input-enum [fc]="campaignFC" [enumValuesDisplayAndId]="campaignsByCounty[countyFC.value]"
                        [style]="'none'" [change]="campaignChange.bind(this)">
                    </app-input-enum>
                </div>
            </div>
            <app-outreach-approval-validation-error [error]="campaignValidationError">
            </app-outreach-approval-validation-error>

            <div class="row pt-2">
                <div class="col-3 bold">
                    Slogan
                </div>
                <div class="col-3">
                    <input type="text" style="width: 16em; border-color: lightgrey;" class="input-style"
                        [formControl]="sloganFC" (keyup)="inputChange()" />
                </div>
            </div>
            <app-outreach-approval-validation-error [error]="sloganValidationError">
            </app-outreach-approval-validation-error>

            <div class="row pt-2">
                <div class="col-3 bold">
                    Request Number
                </div>
                <div class="col-3">
                    <input type="text" style="width: 16em; border-color: lightgrey;" class="input-style"
                        [formControl]="referenceNumberFC" (keyup)="inputChange()" />
                </div>
            </div>
            <app-outreach-approval-validation-error [error]="referenceNumberValidationError">
            </app-outreach-approval-validation-error>

            <div *ngIf="saveError" class="row mt-3">
                <div class="col" align="center" style="white-space: pre-wrap;">
                    <span class="overall-validation-error">
                        {{saveError}}
                    </span>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col" align="center">
                    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                    </app-button3>
                    <app-button3 class="mx-1" label="Create" [clickFunction]="submit.bind(this)" [icon]="faSave"
                        [isFinal]="true">
                    </app-button3>
                </div>
            </div>
        </div>
    </div>
</div>