/*

motivations for v2:

- ensure the value in the provided form control can always be directly persisted as a mysql datetime value
    i'm sick of having to convert every date value for persistence
- fix the dropdown formatting
- removal of bootstrap row container
- add "invalid date" indicator on invalid format
- using hover css instead of mouseover and mouseleave

*/

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
    faArrowLeft, faArrowRight, faCalendarDay, faCaretDown, faCaretUp
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    providers: [DatePipe],
    styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit {
    @Input() fc
    @Input() change
    @Input() disabled
    @Input() disabledFormat
    @Input() class
    displayFormControl
    displayFullYear = true
    showCalendar = false
    calendarDisplayYear
    calendarDisplayMonth
    faArrowLeft = faArrowLeft;
    faArrowRight = faArrowRight;
    faCalendarDay = faCalendarDay;
    faCaretDown = faCaretDown
    faCaretUp = faCaretUp

    validDateInput = true

    constructor(
        private datePipe: DatePipe,
    ) { }

    get calendarDisplayMonthName() {
        return new Date(this.calendarDisplayYear, this.calendarDisplayMonth).toLocaleString('default', { month: 'long' })
    }

    ngOnInit() {
        this.displayFormControl = new UntypedFormControl(this.getDateDisplay());
        if (!this.disabledFormat) {
            this.disabledFormat = 'M/d/y'
        }
        this.setCurrentDate(this.fc.value)
    }

    updateCalendarDisplay() {
        if (this.fc.value) {
            var curDate = new Date(this.fc.value)
            if (curDate) {
                this.calendarDisplayYear = curDate.getFullYear()
                this.calendarDisplayMonth = curDate.getMonth()
            }
        }
        if (this.calendarDisplayYear == null) {
            this.calendarDisplayYear = new Date().getFullYear()
        }
        if (this.calendarDisplayMonth == null) {
            this.calendarDisplayMonth = new Date().getMonth()
        }
    }

    getDateDisplay() {
        if (!this.fc.value) {
            return null
        }
        if (this.displayFullYear) {
            return this.datePipe.transform(this.fc.value, 'M/d/y')
        }
        return this.datePipe.transform(this.fc.value, 'M/d/yy')
    }

    toggleShowCalendar() {
        this.showCalendar = !this.showCalendar;
    }

    sameDate(dateA, dateB) {
        var a = new Date(dateA)
        var b = new Date(dateB)
        if (a && b) {
            return a.getFullYear() == b.getFullYear()
                && a.getMonth() == b.getMonth()
                && a.getDate() == b.getDate()
        }
        return false
    }

    sameMonth(dateA, dateB) {
        var a = new Date(dateA)
        var b = new Date(dateB)
        if (a && b) {
            return a.getFullYear() == b.getFullYear()
                && a.getMonth() == b.getMonth()
        }
        return false
    }

    isSetDate(day) {
        return this.sameDate(day, this.fc.value)
    }

    isCurrentDate(day) {
        return this.sameDate(day, new Date())
    }

    isDisplayMonth(day) {
        return this.sameMonth(new Date(this.calendarDisplayYear, this.calendarDisplayMonth, 1), day)
    }

    get days() {
        if (this._days == null) {
            this.initializeDays();
        }
        return this._days;
    }

    _days;
    initializeDays() {
        var firstDayOfMonth = new Date(this.calendarDisplayYear, this.calendarDisplayMonth, 1)
        var startDate = new Date(firstDayOfMonth.getTime() - firstDayOfMonth.getDay() * 24 * 60 * 60 * 1000)
        var lastDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0);
        var endDate = new Date(lastDayOfMonth.getTime() + (7 - lastDayOfMonth.getDay()) * 24 * 60 * 60 * 1000)

        this._days = [];
        var curDayChunk = null;
        for (var i = 0; startDate.getTime() < endDate.getTime(); ++i) {
            if (i % 7 == 0) {
                curDayChunk = [];
                this._days.push(curDayChunk);
            }
            curDayChunk.push(
                {
                    time: startDate.getTime(),
                    isCurrent: this.isCurrentDate(startDate),
                    isSelected: this.isSetDate(startDate),
                    isCurrentMonth: this.isDisplayMonth(startDate),
                }
            );
            startDate.setDate(startDate.getDate() + 1)
        }
        //console.log("days", this._days);
    }

    previousYear() {
        --this.calendarDisplayYear
        this.initializeDays();
    }

    nextYear() {
        ++this.calendarDisplayYear
        this.initializeDays();
    }

    previousMonth() {
        --this.calendarDisplayMonth
        if (this.calendarDisplayMonth < 0) {
            this.calendarDisplayMonth = 11
            --this.calendarDisplayYear
        }
        this.initializeDays();
    }

    nextMonth() {
        ++this.calendarDisplayMonth
        if (this.calendarDisplayMonth > 11) {
            this.calendarDisplayMonth = 0
            ++this.calendarDisplayYear
        }
        this.initializeDays();
    }

    clickDate(date) {
        this.fc.setValue(date.time);
        this.displayFormControl.setValue(this.getDateDisplay());
        this.doChange();
        this.showCalendar = false;
    }

    setCurrentDate(dateValue) {
        if (!dateValue) {
            this.fc.setValue(null)
            this.updateCalendarDisplay()
            this.initializeDays()
            return
        }
        var d = new Date(dateValue)
        if (d) {
            this.validDateInput = true
            var newVal = d.toISOString().replace("T", " ")
            newVal = newVal.substring(0, newVal.length - 5)
            this.fc.setValue(newVal)
            this.displayFormControl.setValue(this.getDateDisplay());
            this.updateCalendarDisplay()
            this.initializeDays()
        } else {
            this.validDateInput = false
        }
    }

    doChange() {
        if (!this.displayFormControl.value) {
            this.validDateInput = true
            this.setCurrentDate(null)
            if (this.change) {
                this.change();
            }
            return
        }
        var matched = false;
        if (/^\d\d?[\/-]\d\d?[\/-]\d\d$/.test(this.displayFormControl.value)) {
            this.displayFullYear = false;
            matched = true;
        }
        if (/^\d\d?[\/-]\d\d?[\/-]\d\d\d\d$/.test(this.displayFormControl.value)) {
            this.displayFullYear = true;
            matched = true;
        }

        if (matched) {
            this.setCurrentDate(this.displayFormControl.value)
        } else {
            this.setCurrentDate(null)
            this.validDateInput = false
        }
        if (this.change) {
            this.change();
        }
    }
}
