import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { VoucherService } from '../voucher.service'


@Component({
    selector: 'voucher-flag-comment',
    templateUrl: './voucher-flag-comment.component.html',
})
export class VoucherFlagCommentComponent implements OnInit {
    form!: UntypedFormGroup
    line_uuid: string
    comment: string
    saveError = false

	constructor(
        private dialog: MatDialog,
        private voucherService: VoucherService,
        @Inject(MAT_DIALOG_DATA) public data: any)
        {
            this.line_uuid = data.line_uuid
            this.comment = data.comment
        }


    close() {
        this.dialog.closeAll()
    }

    saveComment() {
        this.comment = this.form.value.comment
        console.log(this.line_uuid, this.comment)

        this.voucherService.flagComment(this.line_uuid, this.comment).subscribe((result) => {
            console.log(result)
            if(result.success === true) {
                this.close()
            }
        })
    }

    deleteComment() {
        this.voucherService.deleteFlagComment(this.line_uuid).subscribe((result) => {
            if(result.success === true) {
                this.close()
            }
        })
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            comment: new UntypedFormControl(this.comment, [])
        })
    }
}
