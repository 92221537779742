import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SnackRouteService } from '../snack-route.service';
import { SubmissionAnswersService } from '../submission-answers.service';

@Component({
    selector: 'app-confirm-delete-elements',
    templateUrl: 'confirm-delete-elements.component.html',
    styleUrls: ['confirm-delete-elements.component.css']
})
export class ConfirmDeleteElements {
    dialogMessage: string
    answerGroup: string
    addSequence: number
    groupData: any
    confirmCheck: boolean

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ConfirmDeleteElements>,
        //service
        public snackService: SnackRouteService,
        public submissionAnswerService: SubmissionAnswersService,
        @Inject(MAT_DIALOG_DATA) public data: any) 
        {
            this.dialogMessage = data.dialogMessage,
            this.answerGroup = data.answerGroup,
            this.addSequence = data.addSequence,
            this.groupData = data.groupData
        }

    closeDialog() {
        this.dialog.closeAll()
    }
    
    deleteGroup() {
        // console.log(this.answerGroup, this.addSequence)
        
        this.submissionAnswerService.deleteAnswerGroup(this.answerGroup, this.addSequence).subscribe(response => {
            if(response.success === true) {
                this.snackService.snack('Question group deleted.')
                window.location.reload()
            } else {
                this.snackService.snack('Error deleting question group.')
            }
        })
    }


}