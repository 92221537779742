import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
})
export class FaqsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
