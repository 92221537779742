import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
// import { PERSONNEL } from '../mock/mock-personnel';
import { Personnel } from '../model/personnel.model';
import { iFringe } from '../model/fringe.model';
import { iPayPeriod } from '../model/pay-period.model';
import { PAYPERIODS } from '../mock/mock-pay-period';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root',
})


export class PersonnelService
{
    constructor(private http: HttpClient) { }

    getDataByUuid(uuid: string): Observable<Personnel[]>
    {
        return this.http.get<Personnel[]>('api/getPersonnel/' + uuid);
        // const matchingData = PERSONNEL.filter((data) => data.uuid === uuid);
        // return of(matchingData)
    }

    getFringeDataByUuid(masterUuid: string, submissionUuid: string): Observable<iFringe[]> 
    {
        return this.http.get<iFringe[]>('api/getFringe/' + masterUuid + '/' + submissionUuid);
    }

    getPayPeriods(): Observable<iPayPeriod[]>
    {
        return this.http.get<iPayPeriod[]>('api/getPayPeriods/')
        // return of(PAYPERIODS)
    }

    updatePersonnel(
        masterUuid: string,
        submissionUuid: string,
        dataFinanceDescription: string,
        dataFinanceName: string,
        hireDate: string,
        terminationDate: string,
        raiseDate: string,
        percentTimeOnContract: number,
        exclusive: number,
        payPeriod: string,
        hoursPerPayPeriod: number,
        raiseExpected: number,
        bonusExpected: number,
        salaried: number,
        bonusAmount: number,
        annualSalary: number,
        hourlyRate: number,
        newAnnualSalary: number,
        newHourlyRate: number,
        unitPrice: number,
        debitState: number,
        debitFederal: number,
        agencyComment: string,
        isSaved: number,
        accountUuid: string
    ): Observable<any>
    {
        return this.http.put<any>("api/updatePersonnel", 
        {
            masterUuid: masterUuid,
            submissionUuid: submissionUuid,
            dataFinanceDescription: dataFinanceDescription,
            dataFinanceName: dataFinanceName,
            hireDate: hireDate,
            terminationDate: terminationDate,
            raiseDate: raiseDate,
            percentTimeOnContract: percentTimeOnContract,
            exclusive: exclusive,
            payPeriod: payPeriod,
            hoursPerPayPeriod: hoursPerPayPeriod,
            raiseExpected: raiseExpected,
            bonusExpected: bonusExpected,
            salaried: salaried,
            bonusAmount: bonusAmount,
            annualSalary: annualSalary,
            hourlyRate: hourlyRate,
            newAnnualSalary: newAnnualSalary,
            newHourlyRate: newHourlyRate,
            unitPrice: unitPrice,
            debitState: debitState,
            debitFederal: debitFederal,
            agencyComment: agencyComment,
            isSaved: isSaved,
            accountUuid: accountUuid
        })
    }

    updateTopFringe(
        masterUuid: string,
        submissionUuid: string,
        accountUuid: string,
        percent: number, 
        unitPrice: number,
        debitState: number,
        debitFederal: number,
        agencyComment: string
    ): Observable<any> {
        return this.http.put<any>("api/updateTopFringe", {
            masterUuid: masterUuid,
            submissionUuid: submissionUuid,
            accountUuid: accountUuid,
            percent: percent,
            unitPrice: unitPrice,
            debitState: debitState,
            debitFederal: debitFederal,
            agencyComment: agencyComment
        })
    }

    updateBottomFringe(
        masterUuid: string,
        submissionUuid: string,
        accountUuid: string,
        percent: number, 
        unitPrice: number,
        debitState: number,
        debitFederal: number,
        fringeAmount: number,
        agencyComment: string
    ): Observable<any> {
        return this.http.put<any>("api/updateBottomFringe", {
            masterUuid: masterUuid,
            submissionUuid: submissionUuid,
            accountUuid: accountUuid,
            percent: percent,
            unitPrice: unitPrice,
            debitState: debitState,
            debitFederal: debitFederal,
            fringeAmount: fringeAmount,
            agencyComment: agencyComment
        })
    }

    updateProgramType(masterUuid: string, programType: string): Observable<any> {
        return this.http.put<any>("api/updateProgramType", {
            masterUuid: masterUuid,
            programType: programType
        })
    }

    fringeCommentExists(
        masterUuid: string,
        submissionUuid: string,
        accountUuid: string
    ): Observable<any> {
        return this.http.get<any>("api/fringeCommentExists/" + masterUuid + "/" + submissionUuid + "/" + accountUuid)
    }

    getPersonnelComment(
        masterUuid: string, 
        submissionUuid: string, 
        targetColumn: string): Observable<any> {
        return this.http.get<any>("api/getPersonnelComment/" + masterUuid + "/" + submissionUuid + "/" + targetColumn)
    }

    addPersonnelComment(
        masterUuid: string, 
        submissionUuid: string, 
        targetColumn: string,
        comment: string,
        userUuid: string,
        userName: string,
    ): Observable<any> {
        return this.http.put<any>("api/addPersonnelComment", {
            masterUuid: masterUuid,
            submissionUuid: submissionUuid,
            targetColumn: targetColumn,
            comment: comment,
            userUuid: userUuid,
            userName: userName,
        })
    }

    deletePersonnelComment(
        masterUuid: string,
        submissionUuid: string,
        targetColumn: string
    ): Observable<any> {
        return this.http.put<any>("api/deletePersonnelComment", {
            masterUuid: masterUuid,
            submissionUuid: submissionUuid,
            targetColumn: targetColumn
        })
    }

    deletePersonnel(financeUuid: string, masterUuid: string): Observable<any>
    {
        return this.http.delete<any>("api/deletePersonnel/" + financeUuid + "/" + masterUuid)
    }
}