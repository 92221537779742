import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-budget-calculator',
    templateUrl: './budget-calculator.component.html',
})
export class BudgetCalculatorComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
