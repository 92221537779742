import { Component, OnInit, Inject, Input, ViewChild } from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router"
import { faTimes, faBan, faExclamationTriangle, faCheck, faSave, faMinus, faFileInvoiceDollar, faTicketAlt } from "@fortawesome/free-solid-svg-icons"
import { AuthService } from "../auth.service"
import { BudgetService } from "../budget.service"
import { SnackRouteService } from "../snack-route.service"
import { SubmissionCommentService } from "../submission-comment.service"
import { SubmissionAttachmentsComponent } from '../submission-attachments/submission-attachments.component';
import { VoucherService } from "../voucher.service"
import { SubmissionService } from "../submission.service"
//import { fromEvent, Subject } from 'rxjs';
//import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-voucher-edit',
    templateUrl: './voucher-edit.component.html',
})
export class VoucherEditComponent implements OnInit {
    @ViewChild(SubmissionAttachmentsComponent) private _attachmentsComponent: SubmissionAttachmentsComponent;

    faTimes = faTimes;
    faBan = faBan;
    faExclamationTriangle = faExclamationTriangle;
    faCheck = faCheck;
    faSave = faSave;
    faMinus = faMinus;
    faFileInvoiceDollar = faFileInvoiceDollar
    faTicketAlt = faTicketAlt

    newEntry = true;
    accountNameFC = new UntypedFormControl("Other");
//    showFlag;
    submissionUuid;
    showAddAttachmentControls = true;

    stateOnlyLines = 0;
    stateFedLines = 0;
    voucherTotalLines = 0;

    debitState
    debitFederal
    debitStateEst
    debitFederalEst
    creditStateEst
    creditFederalEst

    commentFields = [
        "name",
        "quantity",
        "narrative",
        "snap",
        "child_nutrition_snap",
        "unit_price",
        "percent_allocated",
        "agency_comments"
    ];

    inputDataById = {};
    allocationMethodOptions;
    addAccountOptions;
    userOrganizationType;
    initialized = false;

    accountFC = new UntypedFormControl()
    nameFC = new UntypedFormControl()
    allocationMethodFC = new UntypedFormControl()
    totalSquareFootageFC = new UntypedFormControl()
    narrativeFC = new UntypedFormControl()
    snapFC = new UntypedFormControl()
    childNutritionSnapFC = new UntypedFormControl()
    totalFC = new UntypedFormControl()
    pricePerSquareFootFC = new UntypedFormControl()
    percentAllocatedFC = new UntypedFormControl()
    agencyCommentsFC = new UntypedFormControl()
    userFlagFC = new UntypedFormControl()

    saveError
    accountError
    nameError
    allocationMethodError
    totalSquareFootageError
    narrativeError
    snapError
    childNutritionSnapError
    totalError
    pricePerSquareFootError
    percentAllocatedError
    agencyCommentsError

    account
    editable: boolean

    @Input() dialogRef: any
    @Input() data: any
    @Input() showPopupHeader: boolean
    @Input() accountUuid: string
    @Input() hideButtons: boolean
    @Input() canEdit: boolean = true
    @Input() showFlag: boolean
    @Input() subUuid: string
    @Input() accUuid: string

    //private unsubscriber: Subject<void> = new Subject<void>();

    constructor(
        private budgetService: BudgetService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private submissionCommentService: SubmissionCommentService,
        private router: Router,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private snackRoute: SnackRouteService,
        private voucherService: VoucherService,
        private submissionService: SubmissionService
    ) {}

    paramPromise() {
        return new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe(params => {
                console.log(params)
                if(params['submissionUuid']) {
                    this.submissionUuid = params['submissionUuid']
                }

                if(params['accountUuid']) {
                    this.accountUuid = params['accountUuid']
                } else {
                    if(this.accUuid) {
                        this.accountUuid = this.accUuid
                    }
                }

                // this.submissionUuid = params["submissionUuid"]
                // this.accountUuid = params["accountUuid"]

                if (params["editable"]) {
                    if (params["editable"].toLowerCase() == "false") {
                        this.editable = false
                    } else {
                        this.editable = true
                    }
                } else {
                    this.editable = this.canEdit
                }

                if(params["showFlag"]) {
                    if(params["showFlag"].toLowerCase() == "true") {
                        this.showFlag = true
                    } else {
                        this.showFlag = false
                    }
                }

                resolve(null)
            })
        })
    }
    
    flagClick(inputId) {
        var inputData = this.inputDataById[inputId]
        if (inputData) {
            inputData.showInputContainer.value = !inputData.showInputContainer.value
        } else {
            this.inputDataById[inputId] = {
                showInputContainer: { value: true }
            }
        }
    }

    submission
    approvalDate: Date
    dueDate: Date
    status: string
    getSubmissionPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmission(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.submission = response.result[0]
                    this.approvalDate = this.submission.approval_date
                    this.status = this.submission.status_id
                    console.log("submission for review", this.submission)
                } else {
                    console.log("error while getting submission", response)
                }
                resolve(null)
            })
        })
    }


    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            if(params['dd']) {
                this.dueDate = params['dd']
            }
        })
        /*
        window.history.pushState(null, '');

        fromEvent(window, 'popstate').pipe(
          takeUntil(this.unsubscriber)
        ).subscribe((_) => {
          window.history.pushState(null, '');
          this.snackRoute.snack("Please save your changes before going back!")
        });

        window.onbeforeunload = function() {
            this.snackRoute.snack("Please save your changes before closing this window!")
        }
        */

        this.getUserOrganizationType().then(() => {
            return this.paramPromise()
        }).then(() => {
            console.log("init data", this.data, this.accountUuid, this.submissionUuid)
            if (this.data) {
                this.showFlag = this.data.showFlag
                this.submissionUuid = this.data.submissionUuid
            }

            if (!this.data && this.accountUuid) {
                this.getAddAccountOptionsPromise().then(() => {

                    this.budgetService.getAccount(this.accountUuid).subscribe((response) => {
                        if (!response.success) {
                            return console.log("error while getting account", response)
                        }
                        this.data = {
                            submissionUuid: this.submissionUuid,
                            addAccountOptions: this.addAccountOptions,
                            accountUuid: this.accountUuid,
                            entryData: response.result,
                            editable: this.editable,
                            showFlag: this.showFlag,
                        }

                        console.log('data', this.data)
                        this.getLineTotalsAndInitializeData()
                    })
                })
            }

            if (this.data && this.data.entryData && this.data.entryData.length) {
                this.getLineTotalsAndInitializeData();
            } 
            else if (!this.accountUuid) {
                for (var i = 0; i < this.commentFields.length; ++i) {
                    this.inputDataById[this.commentFields[i]] = {
                        showInputContainer: { value: false }
                    }
                }
                this.initialized = true
            }
        })
    }

    getLineTotals(): void
    {
        this.getLineTotalsAndInitializeData();
    }

    getLineTotalsAndInitializeData() {
        this.stateOnlyLines = 0;
        this.stateFedLines = 0;
        this.voucherTotalLines = 0;

        this.voucherService.totalVoucherLines(this.accountUuid).subscribe((results: any) => {
            console.log(results)
            for(let i = 0; i < results.response.length; i++) {
                this.stateOnlyLines += parseInt(results.response[i].state_only)
                this.stateFedLines += parseInt(results.response[i].state_federal)
                this.voucherTotalLines += parseInt(results.response[i].other_amount)
            }
            console.log(`Line Totals Set: ${this.stateOnlyLines}, ${this.stateFedLines}, ${this.voucherTotalLines}`);
            this.doDataInit();
            this.getSubmissionPromise();
            this.accountChange();
        });
    }

    triggerSave(): void {
        console.log(this.snapFC.value, this.debitState)
        console.log('save event', this.data.entryData[0])

        /*
        console.log(this.stateOnlyLines)
        let errorEncountered = false
        let so_balance = this.debitState - this.snapFC.value - this.creditStateEst

        console.log(so_balance)

        if(so_balance < 0) {
            this.saveError = "State only cannot be greater than budget minus previously spent"
            errorEncountered = true
        }
        if (this.snapFC.value > this.debitState - this.creditStateEst) {
            console.log(this.debitState, this.snapFC.value, this.debitStateEst)
            this.saveError = "State Only cannot be greater than budget minus previously spent"
            errorEncountered = true
            console.log('test')
        }

        if (this.childNutritionSnapFC.value > this.debitFederal - this.creditFederalEst ) {
            this.saveError = "State Match/Federal Match cannot be greater than federal budget minus federal match previously spent"
            errorEncountered = true
        }
        */

    }

    budgetLink: any
    accountType: string
    doDataInit() {
        this.newEntry = false
        var entry = this.data.entryData[0]
        this.accountNameFC.setValue(entry.account_name)
        this.accountFC.setValue(entry.account_uuid)
        this.nameFC.setValue(entry.finance_name)
        this.totalSquareFootageFC.setValue(entry.quantity)
        this.narrativeFC.setValue(entry.narrative)
        this.snapFC.setValue(this.stateOnlyLines)
        this.childNutritionSnapFC.setValue(this.stateFedLines)
        this.totalFC.setValue(this.stateOnlyLines + this.stateFedLines)
        this.pricePerSquareFootFC.setValue(entry.unit_price)
        this.percentAllocatedFC.setValue(entry.percentage)
        this.allocationMethodFC.setValue(entry.allocation_method_id)
        this.agencyCommentsFC.setValue(entry.agency_comment)
        this.userFlagFC.setValue(entry.user_flag)
        this.debitState = entry.debit_state
        this.debitFederal = entry.debit_federal
        this.debitStateEst = entry.debit_state_est
        this.debitFederalEst = entry.debit_federal_est
        this.creditStateEst = entry.credit_state_est
        this.creditFederalEst = entry.credit_federal_est
        this.budgetLink = entry.link
        this.accountType = entry.account_uuid
        this.submissionCommentService.getSubmissionCommentsPromise(this.data.submissionUuid, this.data.accountUuid).then((result: any) => {
            for (var i = 0; i < this.commentFields.length; ++i) {
                this.inputDataById[this.commentFields[i]] = {
                    showInputContainer: { value: false }
                }
            }
            if (result) {
                for (var i = 0; i < result.length; ++i) {
                    this.inputDataById[result[i].target_column] = {
                        comment: result[i].comment,
                        commentUuid: result[i].uuid,
                        showInputContainer: { value: false }
                    }
                }
            }
            this.getAllocationMethodOptionsPromise(entry.account_uuid).then(() => {
                this.initialized = true
            })
        })
    }

    accountChange() {
        this.accountError = null
        for (var i = 0; i < this.data.addAccountOptions.length; ++i) {
            if (this.accountFC.value == this.data.addAccountOptions[i].id) {
                this.account = this.data.addAccountOptions[i]
                break
            }
        }
        this.getAllocationMethodOptionsPromise(this.accountFC.value)
    }

    get attachmentsComponent() {
        return this._attachmentsComponent;
    }

    round(fc, decimalPlaces?) {
        if (fc.value) {
            if (typeof fc.value == "string") {
                fc.setValue(parseFloat(fc.value))
            }
            fc.setValue(fc.value.toFixed(decimalPlaces))
        }
    }

    getAllocationMethodOptionsPromise(accountUuid) {
        return new Promise((resolve, reject) => {
            this.budgetService.getAllocationMethodOptions(accountUuid).subscribe((result) => {
                this.allocationMethodOptions = result
                if (this.allocationMethodOptions.length) {
                    this.allocationMethodOptions.unshift({ name: "", id: null })
                }
                resolve(null)
            })
        })
    }

    getAddAccountOptionsPromise() {
        return new Promise((resolve, reject) => {
            this.budgetService.getAddAccountOptions(this.submissionUuid).subscribe(response => {
                this.addAccountOptions = response.result
                for (var i = 0; i < this.addAccountOptions.length; ++i) {
                    this.addAccountOptions[i].id = this.addAccountOptions[i].uuid
                }
                resolve(null)
            })
        })
    }

    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    nameChange() {
        this.nameError = null
    }

    allocationMethodChange() {
        this.allocationMethodError = null
    }

    totalSquareFootageChange() {
        this.totalSquareFootageError = null
    }

    narrativeChange() {
        this.narrativeError = null
    }

    updateTotal() {
        var newTotal = 0
        if (this.snapFC.value) {
            newTotal += parseFloat(this.snapFC.value)
        }
        if (this.childNutritionSnapFC.value) {
            newTotal += parseFloat(this.childNutritionSnapFC.value)
        }
        this.totalFC.setValue(newTotal)
    }

    snapChange() {
        this.snapError = null
        this.updateTotal()
    }

    childNutritionSnapChange() {
        this.childNutritionSnapError = null
        this.updateTotal()
    }

    totalChange() {
        this.totalError = null
    }

    pricePerSquareFootChange() {
        this.pricePerSquareFootError = null
    }

    percentAllocatedChange() {
        this.percentAllocatedError = null
    }

    agencyCommentsChange() {
        this.agencyCommentsError = null
    }

    userFlagChange() {
        this.budgetService.updateUserFlag(this.data.entryData[0].finance_uuid, this.userFlagFC.value).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("User flag updated.", null, {
                    duration: 4000,
                });
            } else {
                this._snackBar.open("Error occurred while updating user flag.", null, {
                    duration: 4000,
                });
            }
        })
    }

    //NOTE: isNumber and isMoney methods assume american-style money formatting, using , as the thousands separator and . as the decimal.
    //may need a locale update to potentially flip these if we ever market to europe, etc.

    //assumes n is a string
    isNumber(n) {
        if (n == null) {
            return false
        }
        if (typeof n == "number") {
            return true
        }
        n = n.replace(/,/g, '')
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    //assumes n is a string
    isMoney(n) {
        if (!this.isNumber(n)) {
            return false
        }
        //ensure has zero to two decimal places
        // this.toString().split(".")[1].length || 0
        var parts = n.toString().split(".")
        return parts.length < 2 || parts[1].length <= 2
    }

    clearErrors() {
        this.saveError = null
        this.accountError = null
        this.nameError = null
        this.totalSquareFootageError = null
        this.narrativeError = null
        this.snapError = null
        this.childNutritionSnapError = null
        this.totalError = null
        this.pricePerSquareFootError = null
        this.percentAllocatedError = null
        this.allocationMethodError = null
    }

    validate() {
        this.clearErrors()
        var errorEncountered = false

        if (!this.accountFC.value) {
            this.saveError = "Please select an account"
            errorEncountered = true
        }

        if (this.totalSquareFootageFC.value && !this.isNumber(this.totalSquareFootageFC.value)) {
            this.saveError = "Total Square Footage must be a number."
            errorEncountered = true
        }

        if (this.snapFC.value && !this.isMoney(this.snapFC.value)) {
            this.saveError = "SNAP must be a number."
            errorEncountered = true
        }
        if (this.childNutritionSnapFC.value && !this.isMoney(this.childNutritionSnapFC.value)) {
            this.saveError = "Child Nutrition / SNAP must be number."
            errorEncountered = true
        }
        if (this.totalFC.value && !this.isMoney(this.totalFC.value)) {
            this.saveError = "Total must be a number."
            errorEncountered = true
        }
        // if (this.pricePerSquareFootFC.value && !this.isMoney(this.pricePerSquareFootFC.value)) {
        //     this.saveError = "Price per Square Foot must be a number."
        //     errorEncountered = true
        // }
        if (this.percentAllocatedFC.value && !this.isNumber(this.percentAllocatedFC.value)) {
            this.saveError = "Percent Allocated must be a number."
            errorEncountered = true
        }

        if (this.snapFC.value > this.debitState - this.creditStateEst) {
            console.log(this.debitState, this.snapFC.value, this.debitStateEst)
            this.saveError = "State Only cannot be greater than budget minus previously spent"
            errorEncountered = true
        }

        if (this.childNutritionSnapFC.value > this.debitFederal - this.creditFederalEst ) {
            this.saveError = "State Match/Federal Match cannot be greater than federal budget minus federal match previously spent"
            errorEncountered = true
        }

        /* alternative through saveError 
        if(this.debitState - this.snapFC.value - this.creditStateEst < 0) {
            console.log(this.debitState - this.snapFC.value - this.creditStateEst)
            console.log(this.debitState)
            this.saveError = "State only balance cannot be less than zero."
            errorEncountered = true
        }
        */
       
        return errorEncountered
    }

    returnToSubmission() {
        if (this.dialogRef) {
            this.dialogRef.close({});
        } else {
            this.router.navigate(["/voucher-summary"], { queryParams: { submissionUuid: this.submissionUuid } })
        }
    }

    save() {
        if (this.validate()) {
            return
        }

        if (this.data && this.data.entryData && this.data.entryData.length) {
            this.budgetService.updateVoucherAccount(
                this.data.entryData[0].finance_uuid,
                this.nameFC.value,
                this.totalSquareFootageFC.value,
                this.narrativeFC.value,
                this.snapFC.value,
                this.childNutritionSnapFC.value,
                this.totalFC.value,
                this.pricePerSquareFootFC.value,
                this.percentAllocatedFC.value,
                this.allocationMethodFC.value,
                this.agencyCommentsFC.value,
            ).subscribe((response) => {
                if (!response.success) {
                    this.saveError = response.message
                    return
                }
                this.snackRoute.snack("Account updated.")
                this.returnToSubmission()
            })
        } else {
            this.budgetService.addAccount(
                this.data.submissionUuid,
                this.accountFC.value,
                this.nameFC.value,
                this.totalSquareFootageFC.value,
                this.narrativeFC.value,
                this.snapFC.value,
                this.childNutritionSnapFC.value,
                this.totalFC.value,
                this.pricePerSquareFootFC.value,
                this.percentAllocatedFC.value,
                this.allocationMethodFC.value,
                this.agencyCommentsFC.value,
            ).subscribe((response) => {
                if (!response.success) {
                    this.saveError = response.message
                    return
                }
                this.snackRoute.snack("Account added.")
                this.returnToSubmission()
            })
        }
    }

    cancel() {
        this._snackBar.open("Action cancelled", null, {
            duration: 4000,
        });
        this.returnToSubmission()
    }

    ok() {
        if (this.dialogRef) {
            this.dialogRef.close({});
        } else {
            this.router.navigate(["/submission-review"], { queryParams: { submissionUuid: this.submissionUuid } })
        }
    }

    delete() {
        const dialogRef = this.dialog.open(VoucherSubmissionDeleteConfirmationDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.budgetService.deleteAccount(this.data.entryData[0].finance_uuid).subscribe((response) => {
                    if (!response.success) {
                        console.log("delete error", response)
                        return
                    }
                    if (response.success) {
                        this._snackBar.open("Deleted", null, {
                            duration: 4000,
                        });
                        this.returnToSubmission()
                    }
                });
            }
        });
    }
}

@Component({
    selector: 'voucher-submission-delete-confirmation-dialog',
    templateUrl: './voucher-submission-delete-confirmation-dialog.html',
})
export class VoucherSubmissionDeleteConfirmationDialog {
    faExclamationTriangle = faExclamationTriangle;
    faTimes = faTimes;
    faBan = faBan;
    faMinus = faMinus;

    constructor(
        public dialogRef: MatDialogRef<VoucherSubmissionDeleteConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackRoute: SnackRouteService,
    ) { }

    cancel() {
        this.snackRoute.snack("Action cancelled.")
        this.dialogRef.close();
    }

    delete() {
        this.dialogRef.close({});
    }
}
