<hr>
<h4 class="header">Calculation Worksheet</h4>

<div class="page-container">
    <div class="parent-container">
        <div class="calculation-container">
          <div>
            <h6>Hourly Rate From Salary Calculation</h6>
            <p class="calculation-description">annual salary / (number of paychecks * hours per pay period)</p>
            <p class="calculation-description">{{ annualSalary }} / ({{ numberOfPaychecks }} * {{ hoursPerPayPeriod }})</p>
          </div>
          <div class="align-right">
            <h6>{{ hourlyRateFromSalaryTotal | currency}}</h6>
          </div>
        </div>
    
        <div class="calculation-container">
          <div>
            <h6>New Hourly Rate From New Salary Calculation</h6>
            <p class="calculation-description">new annual salary / (number of paychecks * hours per pay period)</p>
            <p class="calculation-description" *ngIf="receiveRaise">{{ newAnnualSalary }} / ({{ numberOfPaychecks }} * {{ hoursPerPayPeriod }})</p>
          </div>
          <div class="align-right">
            <h6 *ngIf="receiveRaise">{{ newHourlyRateFromSalaryTotal | currency}}</h6>
          </div> 
        </div>
    
        <div class="calculation-container">
          <div>
            <h6>Hours Per Day</h6>
            <p class="calculation-description">hours per pay period * paychecks / 260</p>
            <p class="calculation-description">{{ hoursPerPayPeriod }} * {{ numberOfPaychecks }} / 260</p>
          </div>
          <div class="align-right">
            <h6 *ngIf="hoursPerDay">{{ hoursPerDay }} hours</h6>
          </div>
        </div>
    
        <div class="calculation-container">
          <div>
            <h6>Pre Raise Amount</h6>
            <p class="calculation-description">(business days between fiscal start and raise day) * hours per day * pre raise hourly rate</p>
            <p class="calculation-description">(weekdaysSince(7/1/2022, 9/30/2022) + 1) * 8 * 35.96</p>
            <p class="calculation-description" *ngIf="receiveRaise">{{ preRaiseDays }} * {{ hoursPerDay }} * {{ preRaiseHourlyRate }}</p>
          </div>
          <div class="align-right">
            <h6 *ngIf="receiveRaise">{{ preRaiseAmountTotal | currency}}</h6>
          </div>
        </div>
    
        <div class="calculation-container">
          <div>
            <h6>Post Raise Amount</h6>
            <p class="calculation-description">(business days between raise date and fiscal end - 0) * hours per day * post raise hourly rate</p>
            <p class="calculation-description">261 - (weekdaysSince(7/1/2022, 9/30/2022) + 1) * 8 * 35.96</p>
            <p class="calculation-description" *ngIf="receiveRaise">{{ postRaiseDays }} * {{ hoursPerDay }} * {{ postRaiseHourlyRate }}</p>
          </div>
          <div class="align-right">
            <h6 *ngIf="receiveRaise">{{ postRaiseAmountTotal | currency}}</h6>
          </div>
        </div>
    
        <div class="calculation-container">
          <div>
            <h6>Bonus</h6>
            <p class="calculation-description" *ngIf="receiveBonus"></p>
          </div>
          <div class="align-right">
            <h6 *ngIf="receiveBonus">{{ bonus | currency}}</h6>
          </div>
        </div>
    
        <div class="calculation-container">
          <div>
            <h6>Calculated Amount</h6>
            <p class="calculation-description">(Pre Raise Amount + Post Raise Amount) * estimated percent time on contract + Bonus</p>
            <p class="calculation-description"></p>
          </div>
          <div class="align-right">
            <h6>{{ calculatedAmountTotal | currency}}</h6>
          </div>
        </div>
    </div>
</div>
