import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-submission-schedule-action-dropdown',
    templateUrl: './submission-schedule-action-dropdown.component.html',
    styleUrls: ['./submission-schedule-action-dropdown.component.css']
})
export class SubmissionScheduleActionDropdownComponent implements OnInit {

    faCalendar = faCalendar
    @Input() entry
    actionButtons = []
    constructor() { }

    ngOnInit() {

        //always view schedule
        this.actionButtons = []
        this.actionButtons.push({
            label: "View Schedule",
            icon: this.faCalendar,
            routerLink: "/submission-maintenance-wizard",
            queryParams: { uuid: this.entry.fiscal_uuid, viewMode: "v" },
        })

        //edit, if user role = user_admin or admin, and status isn't published
        this.actionButtons.push({
            label: "Edit Schedule",
            icon: this.faCalendar,
            routerLink: "/submission-maintenance-wizard",
            queryParams: { uuid: this.entry.fiscal_uuid, viewMode: "e" },
        })

        //run, if user is user admin or admin and status is ready
        this.actionButtons.push({
            label: "Run Schedule",
            icon: this.faCalendar,
            routerLink: "/submission-maintenance-wizard",
            queryParams: { uuid: this.entry.fiscal_uuid },
        })
    }
}
