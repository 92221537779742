<div class="container-fluid" *ngIf="claims.organization_type == 'PRIME'; else no_permission">
    <div class="row page-title mb-2">
        <div class="col">
            <fa-icon [icon]="faDatabase"></fa-icon>
            Program Data Collection
        </div>
    </div>

    <div class="dropdown-container">
        <p>
        <!-- <mat-form-field appearance="fill" *ngIf="!reportDisplay">
                <mat-label>Program</mat-label>
                <mat-select [formControl]="program" required>
                    <mat-option *ngFor="let programs of programDropDown" [value]="programs.id">
                        {{programs.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="program.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field> -->

        <mat-form-field appearance="fill" class="year-drop-down">
                <mat-label>Year</mat-label>
                <mat-select [formControl]="year" required>
                    <mat-option *ngFor="let years of yearDropDown" [value]="years.id">
                        {{years.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="year.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="agency-drop-down">
            <mat-label>Agency</mat-label>
            <mat-select [formControl]="agency">
                <mat-option *ngFor="let agencies of agencyDropDown" [value]="agencies.uuid">
                    {{agencies.short_name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="quarter-drop-down">
            <mat-label>Quarter</mat-label>
            <mat-select [formControl]="quarter">
                <mat-option *ngFor="let quarters of quarterDropDown" [value]="quarters.uuid">
                    {{quarters.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="county-drop-down">
            <mat-label>County</mat-label>
            <mat-select [formControl]="county">
                <mat-option *ngFor="let counties of countyDropDown" [value]="counties.id" >
                    {{counties.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        
        <button class="generate_button" mat-raised-button mat-primary color="primary" (click)="generateReportTableData()">Generate Report</button>

        </p>
    </div>

    <div *ngIf="reportDisplay">
        <app-report-program
            [groupedData]="groupedData"
            [groupDataArray]="groupDataArray"
            [submissionUuid]="submissionUuid">
        </app-report-program>
    </div>

</div>

<ng-template #no_permission>
    <p style="padding: 25px;">This page is only accessible by HSNY users.</p>
</ng-template>