import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logo-header',
    templateUrl: './logo-header.component.html',
})
export class LogoHeaderComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
