<span *ngIf="isDisabled == 'true'">
    <input type="checkbox" [id]="id" [formControl]="fc" (click)="doClick($event)" (change)="doChange($event)"
    (keyup.enter)="doEnter()" disabled>
</span>
<span *ngIf="isDisabled == 'false'">
    <input type="checkbox" [id]="id" [formControl]="fc" (click)="doClick($event)" (change)="doChange($event)"
        (keyup.enter)="doEnter()" style="cursor: pointer;">
</span>
<span *ngIf="!isDisabled">
    <input type="checkbox" [id]="id" [formControl]="fc" (click)="doClick($event)" (change)="doChange($event)"
        (keyup.enter)="doEnter()" style="cursor: pointer;">
</span>

<!--
<span *ngIf="isDisabled == 'true' && pageStyle == 'user-page'">
    <input type="checkbox" [id]="id" [formControl]="fc" (click)="doClick($event)" (change)="doChange($event)"
    (keyup.enter)="doEnter()" disabled>
</span>
<span *ngIf="isDisabled == 'false' && pageStyle == 'user-page'" class="col-8">
    <input type="checkbox" [id]="id" [formControl]="fc" (click)="doClick($event)" (change)="doChange($event)"
    (keyup.enter)="doEnter()" style="cursor: pointer;">
</span>
-->