<span *ngIf="initialized">
    <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'slogan'"
        [commentUuid]="inputDataById['slogan'].commentUuid" [comment]="inputDataById['slogan'].comment"
        [showInputContainer]="inputDataById['slogan'].showInputContainer" [showFlag]="showFlag" [odd]="true">
    </app-outreach-submission-comment>

    <div class="row odd-row">
        <div class="col-3 bold data-row">
            Slogan
        </div>
        <div class="col-6" [ngClass]="{ 'data-row': !editable }">
            <input *ngIf="editable" type="text" style="width: 30em; border-color: lightgrey;" class="input-style mt-1"
                [formControl]="sloganFC" (keyup)="inputChange()" maxlength="255" />
            <span *ngIf="!editable">
                {{sloganFC.value}}
            </span>
            <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('slogan')"
                [title]="inputDataById['slogan'].showInputContainer.value ? 'Cancel' : 'Edit'"></fa-icon>
        </div>
    </div>
    <app-outreach-approval-validation-error [odd]="true" [error]="sloganValidationError">
    </app-outreach-approval-validation-error>

    <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'fed'"
        [commentUuid]="inputDataById['fed'].commentUuid" [comment]="inputDataById['fed'].comment"
        [showInputContainer]="inputDataById['fed'].showInputContainer" [showFlag]="showFlag">
    </app-outreach-submission-comment>

    <div class="row">
        <div class="col-3 bold data-row">
            <!-- {{outreachSubmission.fed_label}} --> SNAP (State/Fed Match)
        </div>
        <div class="col-3" [ngClass]="{ 'data-row': !editable }">
            $ <input *ngIf="editable" type="text"
                style="width: 4em; border-color: lightgrey; text-align: right; padding-right: .25em;"
                class="input-style mt-1" [formControl]="fedValueFC" (keyup)="calculateBudgetTotal()" />
            <span *ngIf="!editable" class="right-aligned-money">
                {{fedValueFC.value}}
            </span>
            <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('fed')"
                [title]="inputDataById['fed'].showInputContainer.value ? 'Cancel' : 'Edit'"></fa-icon>
        </div>
    </div>
    <app-outreach-approval-validation-error [error]="fedValidationError"></app-outreach-approval-validation-error>

    <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'state'"
        [commentUuid]="inputDataById['state'].commentUuid" [comment]="inputDataById['state'].comment"
        [showInputContainer]="inputDataById['state'].showInputContainer" [showFlag]="showFlag" [odd]="true">
    </app-outreach-submission-comment>

    <div class="row odd-row">
        <div class="col-3 bold data-row">
            <!--{{outreachSubmission.nys_label}}-->Child Nutrition/SNAP (State Only)
        </div>
        <div class="col-3" [ngClass]="{ 'data-row': !editable }">
            $ <input *ngIf="editable" type="text"
                style="width: 4em; border-color: lightgrey; text-align: right; padding-right: .25em;"
                class="input-style mt-1" [formControl]="stateValueFC" (keyup)="calculateBudgetTotal()" />
            <span *ngIf="!editable" class="right-aligned-money">
                {{stateValueFC.value}}
            </span>
            <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('state')"
                [title]="inputDataById['state'].showInputContainer.value ? 'Cancel' : 'Edit'"></fa-icon>
        </div>
    </div>
    <app-outreach-approval-validation-error [error]="stateValidationError" [odd]="true">
    </app-outreach-approval-validation-error>

    <div class="row data-row">
        <div class="col-3 bold">
            Total
        </div>
        <div class="col-3">
            $ <span class="right-aligned-money">{{fedStateTotal}}</span>
        </div>
    </div>

    <div class="row odd-row data-row">
        <div class="col-3 bold">
            Campaign Number
        </div>
        <div class="col-3">
            {{outreachSubmission.reference_number}}
        </div>
    </div>

    <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'method'"
        [commentUuid]="inputDataById['method'].commentUuid" [comment]="inputDataById['method'].comment"
        [showInputContainer]="inputDataById['method'].showInputContainer" [showFlag]="showFlag">
    </app-outreach-submission-comment>

    <div class="row">
        <div class="col-3 bold data-row">
            Outreach Activity
        </div>
        <div class="col-6" [ngClass]="{ 'data-row': !editable }">
            <app-input-enum 
                *ngIf="editable" 
                [fc]="outreachMethodFC" 
                [enumValuesNameAndId]="outreachMethods"
                [style]="'none'" 
                class="pt-1">
            </app-input-enum>
            <span *ngIf="!editable">
                {{outreachMethodsById[outreachMethodFC.value] ? outreachMethodsById[outreachMethodFC.value].name : ""}}
            </span>
            <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('method')"
                [title]="inputDataById['method'].showInputContainer.value ? 'Cancel' : 'Edit'"></fa-icon>
        </div>
    </div>
    <app-outreach-approval-validation-error [error]="methodValidationError"></app-outreach-approval-validation-error>

    <span *ngIf="outreachSubmission.status_id == 'APPROVED'">
        <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'agency_date'"
            [commentUuid]="inputDataById['agency_date'].commentUuid" [comment]="inputDataById['agency_date'].comment"
            [showInputContainer]="inputDataById['agency_date'].showInputContainer" [showFlag]="showFlag" [odd]="true">
        </app-outreach-submission-comment>

        <div class="row odd-row">
            <div class="col-3 bold data-row">
                {{outreachSubmission.agency_date_label}}
            </div>
            <div class="col-6" [ngClass]="{ 'data-row': !editable }">
                <app-input-date [fc]="agencyDateFC"
                    [disabled]="!editable && outreachSubmission.status_id != 'APPROVED' && userOrganizationType != 'SUB'"
                    [change]="inputChange.bind(this)" class="pt-1" [format]="InputDateFormat.none">
                </app-input-date>
                <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('agency_date')"
                    [title]="inputDataById['agency_date'].showInputContainer.value ? 'Cancel' : 'Edit'"></fa-icon>
            </div>
        </div>
        <app-outreach-approval-validation-error [error]="agencyDateValidationError" [odd]="true">
        </app-outreach-approval-validation-error>
    </span>

    <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'location'"
        [commentUuid]="inputDataById['location'].commentUuid" [comment]="inputDataById['location'].comment"
        [showInputContainer]="inputDataById['location'].showInputContainer" [showFlag]="showFlag">
    </app-outreach-submission-comment>

    <div class="row">
        <div class="col-3 bold data-row">
            Location
        </div>
        <div class="col-6" [ngClass]="{ 'pt-2': !editable }">
            <textarea *ngIf="editable" [rows]="3" [formControl]="locationFC"
                style="vertical-align: top; resize: both; border-radius: .3em; width: 16em;" class="input-style my-1"
                (keyup)="inputChange()" maxlength="400"></textarea>
            <span *ngIf="!editable">
                {{locationFC.value}}
            </span>
            <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('location')"
                [title]="inputDataById['location'].showInputContainer.value ? 'Cancel' : 'Edit'"></fa-icon>

        </div>
    </div>
    <app-outreach-approval-validation-error [error]="locationValidationError"></app-outreach-approval-validation-error>

    <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'comment'"
    [commentUuid]="inputDataById['comment'].commentUuid" [comment]="inputDataById['comment'].comment"
    [showInputContainer]="inputDataById['comment'].showInputContainer" [showFlag]="showFlag">
    </app-outreach-submission-comment>

    <div class="row odd-row">
        <div class="col-3 bold data-row">
            Agency Comment
        </div>
        <div class="col-6">
            <textarea *ngIf="editable" [rows]="3" [formControl]="commentFC"
                style="vertical-align: top; resize: both; border-radius: .3em; width: 16em;" (keyup)="inputChange()"
                class="input-style my-1" maxlength="4000">
            </textarea>
            <span *ngIf="!editable">
                {{commentFC.value}}
            </span>
            <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('comment')"
            [title]="inputDataById['comment'].showInputContainer.value ? 'Cancel' : 'Edit'"></fa-icon>
        </div>
    </div>
    <app-outreach-approval-validation-error [error]="commentValidationError" [odd]="true">
    </app-outreach-approval-validation-error>

    <div class="row">
        <div class="col-3 bold data-row">
            Outreach Requirements
        </div>
        <div class="col-6" [ngClass]="{ 'pt-2': !editable }">
            <ol style="padding-left: 1em;">
                <li>Slogan</li>
                <li>Mention of SNAP or the Summer Food Service Program</li>
                <li>Nutrition Outreach and Education Program or NOEP</li>
                <li>Agency Name</li>
                <li>NOEP Coordinator Number (w/ extension)</li>
                <li>County Name (Upstate only)</li>
                <li>Funding Disclosure</li>
                <li>Anti-discrimination Disclosure</li>
            </ol>
        </div>
    </div>

    <div *ngIf="saveError" class="row mt-3">
        <div class="col" align="center" style="white-space: pre-wrap;">
            <span class="overall-validation-error">
                {{saveError}}
            </span>
        </div>
    </div>

    <div *ngIf="editable" class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
            </app-button3>
            <app-button3 class="mx-1" label="Save for Later" [clickFunction]="saveForLater.bind(this)" [icon]="faSave"
                [isFinal]="true">
            </app-button3>
            <app-button3 class="mx-1" label="Review and Submit" [clickFunction]="submit.bind(this)" [icon]="faSave"
                [isFinal]="true">
            </app-button3>
        </div>
    </div>

    <div *ngIf="outreachSubmission.status_id == 'APPROVED' && userOrganizationType == 'SUB'" class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="Save" [clickFunction]="saveForLater.bind(this)" [icon]="faSave"
                [isFinal]="true">
            </app-button3>
        </div>
    </div>
</span>