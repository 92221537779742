import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(
    private http: HttpClient
  ) { }

  // addVoucherOutreach(submissionUuid: string, accountUuid: string): Observable<any> {
  //   return this.http.post<any>("api/addVoucherOutreach/", {submissionUuid, accountUuid})
  // }

  getVoucherOutreach(organizationUuid: string, dueDate: Date): Observable<any> {
    return this.http.get<any>("api/voucherOutreach/" + organizationUuid + "/" + dueDate)
  }

  getVoucherReport(submissionUuid: string): Observable<any> {
    return this.http.get<any>("api/voucherReport/" + submissionUuid)
  }

  getVoucherLines(account_uuid: string): Observable<any> {
    return this.http.get<any>("api/voucherLines/" + account_uuid)
  }

  getDataFinanceLines(account_uuid: string): Observable<any> {
    return this.http.get<any>("api/dataFinanceLines/" + account_uuid)
  }

  /* before stored procedure */
  addVoucherLine(
    account_uuid: string, 
    description: string,
    name: string,
    line_date: string,
    state_only: number,
    state_federal: number,
    other_amount: number,
    line_reference_number: string,
    comment: string
  ): Observable<any> {
    return this.http.post<any>("api/addVoucherLine", {
      finance_uuid: account_uuid,
      description: description,
      name: name,
      line_date: line_date,
      state_only: state_only,
      state_federal: state_federal,
      other_amount: other_amount,
      line_reference_number: line_reference_number,
      comment:  comment
    }, httpOptions)
  }

  editVoucherLine(
    line_uuid: string,
    description: string,
    name: string,
    period: string,
    quantity_est: number,
    quantity: number,
    unit_price: number,
    invoice_number: string,
    premium: number,
    dates: string,
    line_date: string,
    state_only: number,
    state_federal: number,
    other_amount: number,
    line_reference_number: string,
    line_reference_date: string,
    check_amount: number,
    metadata: string,
    method: string,
    line_number: number,
    percent: number
  ): Observable<any> {
    console.log(line_uuid)
    return this.http.put<any>("api/editVoucherLine", {
      line_uuid: line_uuid,
      description: description,
      name: name,
      period: period,
      quantity_est: quantity_est,
      quantity: quantity,
      unit_price: unit_price,
      invoice_number: invoice_number,
      premium: premium,
      dates: dates,
      line_date: line_date,
      state_only: state_only,
      state_federal: state_federal,
      other_amount: other_amount,
      line_reference_number: line_reference_number,
      line_reference_date: line_reference_date,
      check_amount: check_amount,
      metadata: metadata,
      method: method,
      line_number: line_number,
      percent: percent
    }, httpOptions)
  }

  deleteVoucherLine(line_uuid: string): Observable<any> {
    return this.http.delete<any>("api/deleteVoucherLine/" + line_uuid)
  }

  totalVoucherLines(account_uuid: string): Observable<any> {
    return this.http.get<any>("api/totalVoucherLines/" + account_uuid)
  }

  voucherPaymentReport(submission_uuid: string): Observable<any> {
    return this.http.get<any>("api/voucherPaymentReport/" + submission_uuid)
  }

  flagComment(line_uuid: string, comment: string): Observable<any> {
    return this.http.put<any>("api/voucherFlagComment", {line_uuid: line_uuid, comment: comment})
  }

  deleteFlagComment(line_uuid: string): Observable<any> {
    return this.http.put<any>("api/deleteFlagComment", {line_uuid: line_uuid})
  }

  getOutBalance(submissionId: string): Observable<any>
  {
    return this.http.get<any>("api/voucherOutBalance/" + submissionId)
  }
}

