import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan, faCheck, faFileInvoiceDollar, faFlagCheckered, faLongArrowAltLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, of } from 'rxjs';
import { debounceTime, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { SubmissionScheduleService } from '../submission-schedule.service';
import { UserService } from '../user.service';

@Component({
    selector: 'app-budget-appropriation',
    templateUrl: './budget-appropriation.component.html',
    styleUrls: ['./budget-appropriation.component.scss']
})
export class BudgetAppropriationComponent implements OnInit {
    faLongArrowAltLeft = faLongArrowAltLeft
    faSave = faSave
    faFlagCheckered = faFlagCheckered
    faBan = faBan
    faCheck = faCheck
    faFileInvoiceDollar = faFileInvoiceDollar

    columns = [
        {
            //Field: "agency",
            label: "Agency"
        },
        {
            label: "State/Federal Match"
        },
        {
            label: "State Only"
        },
        {
            label: "Total"
        },
    ]


    federalGrandTotal
    stateGrandTotal
    grandGrandTotal

    canEdit

    budgetAppropriation$ = combineLatest(
        this.activatedRoute.queryParams.pipe(
            switchMap((queryParams) => {
                return this.submissionScheduleService.getBudgetAppropriation(queryParams.uuid)
            }),
        ),
        this.userService.isAppropriator(),
    ).pipe(switchMap(([budgetAppropriationResponse, isAppropriatorResponse]) => {
        console.log("budget appropriation response", budgetAppropriationResponse)

        budgetAppropriationResponse.result.sort((a, b) => a.organization_name.localeCompare(b.organization_name))

        this.canEdit = isAppropriatorResponse.result && budgetAppropriationResponse.result[0].schedule_status_id == 'PREREQ_NEEDED'

        var federalControls = []
        if (budgetAppropriationResponse && budgetAppropriationResponse.result && budgetAppropriationResponse.result.length > 0) {
            this.canEdit = !budgetAppropriationResponse.result[0].data_entry_complete
        }
        budgetAppropriationResponse.result.forEach(element => {
            element.federalFC = new UntypedFormControl(element.federal)
            element.stateFC = new UntypedFormControl(element.state)
            federalControls.push(element.federalFC.valueChanges.pipe(startWith(element.federalFC.value), debounceTime(300)))
        });
        return combineLatest(
            of(budgetAppropriationResponse.result),
            ...federalControls)
    })).pipe(
        switchMap(([result, ...federalValues]) => {
            this.federalGrandTotal = 0
            federalValues.forEach(element => {
                if (typeof element === 'string' || element instanceof String) {
                    this.federalGrandTotal += parseFloat(element as string);
                }
            })
            var stateControls = []
            result.forEach(element => {
                stateControls.push(element.stateFC.valueChanges.pipe(startWith(element.stateFC.value), debounceTime(300)))
            });

            return combineLatest(
                of(result),
                ...stateControls)
        })
    ).pipe(
        switchMap(([result, ...stateValues]) => {
            this.stateGrandTotal = 0
            stateValues.forEach(element => {
                if (typeof element === 'string' || element instanceof String) {
                    this.stateGrandTotal += parseFloat(element as string);
                }
            })
            result.forEach(element => {
                element.total = parseFloat(element.stateFC.value) + parseFloat(element.federalFC.value)
            });
            this.grandGrandTotal = parseFloat(this.federalGrandTotal) + parseFloat(this.stateGrandTotal)
            return of(result)
        }),
        shareReplay(1),
    )

    constructor(
        private activatedRoute: ActivatedRoute,
        private submissionScheduleService: SubmissionScheduleService,
        private crudService: CrudService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit() {

    }

    saveError

    getUpdateOperations(budgetAppropriation) {
        var operations = []
        budgetAppropriation.forEach(element => {
            operations.push(
                {
                    type: "data_budget",
                    operation: "update",
                    value: { uuid: element.uuid, federal: element.federalFC.value, state: element.stateFC.value }
                })
        });
        return operations
    }

    doSave(operations) {
        this.crudService.applyOperations({
            entries: operations
        }).subscribe((response) => {
            if (!response.success) {
                this.saveError = response.message
                console.log("error while submitting change", response)
                this._snackBar.open("Error occurred: " + response.message, null, {
                    duration: 4000,
                });
                return
            }
            this._snackBar.open("Operation complete", null, {
                duration: 4000,
            });
            this.router.navigate(["/submission-schedule"])
        })

    }

    saveForLater(budgetAppropriation) {
        var operations = this.getUpdateOperations(budgetAppropriation)
        this.doSave(operations)
    }

    finish(budgetAppropriation) {
        var operations = this.getUpdateOperations(budgetAppropriation)
        operations.push({
            type: "config_fiscal",
            operation: "update",
            value: {
                uuid: budgetAppropriation[0].fiscal_uuid,
                data_entry_complete: '1',
            }
        })
        this.doSave(operations)
    }
}
