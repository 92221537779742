<h4 mat-dialog-title>Personnel Type Change</h4>

<div mat-dialog-content>
  <p>The personal entry and all associated fringe will be moved to {{ personnelOrAdmin }} category</p>
</div>
<div *ngIf="changeError">
  <p class="dialogError">{{changeError}}</p>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="programTypeChange()">Ok</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
