import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faSave, faBan, faTimes, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AgencyService } from '../agency.service';
import { ParamService } from '../param.service';
import { UserService } from '../user.service';
import { formatDate } from '@angular/common';
import { InputDateFormat } from '../input-date/input-date.component';

@Component({
    selector: 'app-agency',
    templateUrl: './agency.component.html',
})
export class AgencyComponent implements OnInit {
    InputDateFormat = InputDateFormat
    faBan = faBan
    faSave = faSave
    faTimes = faTimes
    faBuilding = faBuilding
    initialized = false

    shortName = new UntypedFormControl()
    nameFC = new UntypedFormControl()
    addressFC = new UntypedFormControl()
    mbeCertifiedFC = new UntypedFormControl(false)
    mbeDateFC = new UntypedFormControl()
    wbeCertifiedFC = new UntypedFormControl(false)
    wbeDateFC = new UntypedFormControl()
    statusFC = new UntypedFormControl("A")
    logoFC = new UntypedFormControl()

    agencyStatuses
    payPeriods
    programs
    counties
    addablePrograms
    newProgramFC = new UntypedFormControl()
    agencyPrograms = []

    addProgramError
    saveError

    constructor(
        private router: Router,
        private _agencyService: AgencyService,
        private paramService: ParamService,
        private userService: UserService,
    ) { }

    get agencyService() {
        return this._agencyService
    }

    getAgencyStatusesPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getAgencyStatuses().subscribe((response) => {
                if (response.success) {
                    this.agencyStatuses = response.result
                    //console.log(this.agencyStatuses)
                    resolve(null)
                } else {
                    reject()
                }
            })
        })
    }

    getProgramsPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getPrograms().subscribe((response) => {
                if (response.success) {
                    this.programs = response.result
                    this.resetProgramSelection()
                    return resolve(null)
                }
                console.log("error while fetching programs", response)
                reject()
            })
        })
    }

    countiesByProgram = {}
    getCountiesPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getCounties().subscribe((response) => {
                //console.log("counties by program response", response)
                if (response.success) {
                    for (var i = 0; i < response.result.length; ++i) {
                        if (!this.countiesByProgram[response.result[i].program_id]) {
                            this.countiesByProgram[response.result[i].program_id] = []
                        }
                        this.countiesByProgram[response.result[i].program_id].push(response.result[i])
                    }
                    return resolve(null)
                }
                console.log("error while fetching counties", response)
                reject()
            })
        })
    }

    agencyUuid
    ngOnInit() {
        Promise.all([
            this.getUserPermissionsPromise(),
            this.paramService.getParamPromise("agencyUuid").then((paramValue) => {
                this.agencyUuid = paramValue
            }),
            this.getAgencyStatusesPromise(),
            this.getProgramsPromise(),
            this.getCountiesPromise()
        ]).then(() => {
            if (this.agencyUuid) {
                this.agencyService.getAgency(this.agencyUuid).subscribe((response) => {
                    if (response.success) {
                        //console.log("agency response", response)
                        this.nameFC.setValue(response.result[0].organization_name)
                        this.shortName.setValue(response.result[0].short_name)
                        this.addressFC.setValue(response.result[0].address)
                        this.mbeCertifiedFC.setValue(response.result[0].mbe)
                        if (response.result[0].mbe_date) {
                            this.mbeDateFC.setValue(formatDate(response.result[0].mbe_date, 'yyyy-MM-dd', 'en'))
                        }
                        this.wbeCertifiedFC.setValue(response.result[0].wbe)
                        if (response.result[0].wbe_date) {
                            this.wbeDateFC.setValue(formatDate(response.result[0].wbe_date, 'yyyy-MM-dd', 'en'))
                        }
                        this.statusFC.setValue(response.result[0].organization_status_id)
                        this.logoFC.setValue(response.result[0].photo_uuid)

                        var curProgramId = null;
                        var curProgram = null
                        for (var i = 0; i < response.result.length; ++i) {
                            if (curProgramId == null || curProgramId != response.result[i].program_program_id) {
                                curProgram = this.addProgramById(response.result[i].program_program_id)
                                curProgramId = response.result[i].program_id
                            }
                            if (response.result[i].jurisdiction_id) {
                                this.addCountyById(curProgram, response.result[i].jurisdiction_id)
                            }
                        }

                        this.initialized = true
                    } else {
                        console.log("error while fetching agency data", response)
                    }
                })
            } else {
                this.initialized = true
            }
        })
    }

    cancel() {
        window.history.back();
    }

    addAgency() {
        if (!this.statusFC.value) {
            this.saveError = "Please specify a status"
            return
        }

        if(!this.shortName.value) {
            this.saveError = "Please specify a short name"
            return
        }
        
        this.saveError = null

        this.agencyService.addAgency(
            this.nameFC.value,
            this.shortName.value,
            this.addressFC.value,
            this.mbeCertifiedFC.value,
            this.mbeDateFC.value,
            this.wbeCertifiedFC.value,
            this.wbeDateFC.value,
            this.statusFC.value,
            this.logoFC.value,
            this.agencyPrograms,
        ).subscribe((response) => {
            if (response.success) {
                console.log("agency successfully added")
                this.cancel()
            } else {
                this.saveError = response.message
                console.log(response)
            }
        })
    }

    updateAgency() {
        if (!this.statusFC.value) {
            this.saveError = "Please specify a status"
            return
        }
        this.saveError = null

        this.agencyService.updateAgency(
            this.agencyUuid,
            this.nameFC.value,
            this.shortName.value,
            this.addressFC.value,
            this.mbeCertifiedFC.value,
            this.mbeDateFC.value,
            this.wbeCertifiedFC.value,
            this.wbeDateFC.value,
            this.statusFC.value,
            this.logoFC.value,
            this.agencyPrograms,
        ).subscribe((response) => {
            if (response.success) {
                console.log("agency successfully updated")
                this.cancel()
            } else {
                this.saveError = response.message
                console.log(response)
            }
        })
    }

    addProgramById(programId) {
        var program
        for (var i = 0; i < this.programs.length; ++i) {
            if (this.programs[i].id == programId) {
                program = this.programs[i]
                this.programs[i].newCountyFC = new UntypedFormControl()
                this.programs[i].addableCounties = []
                this.programs[i].counties = []
                this.resetProgramCountySelection(this.programs[i])
                this.agencyPrograms.push(this.programs[i])
                break
            }
        }
        this.resetProgramSelection()
        return program
    }

    addProgram() {
        this.addProgramError = null
        if (!this.newProgramFC.value) {
            if (this.addablePrograms.length == 0) {
                this.addProgramError = "All programs have been added."
            } else {
                this.addProgramError = "Please select a program from the dropdown before adding it to the agency."
            }
            return
        }
        this.addProgramById(this.newProgramFC.value)
    }

    removeProgram(program) {
        for (var i = 0; i < this.agencyPrograms.length; ++i) {
            if (this.agencyPrograms[i].id == program.id) {
                this.agencyPrograms.splice(i, 1)
                break
            }
        }
        this.resetProgramSelection()
    }

    resetProgramSelection() {
        //exclude any added programs from dropdown
        this.addablePrograms = []
        for (var i = 0; i < this.programs.length; ++i) {
            var alreadyExists = false;
            for (var j = 0; j < this.agencyPrograms.length; ++j) {
                if (this.agencyPrograms[j].id == this.programs[i].id) {
                    alreadyExists = true
                    break
                }
            }
            if (!alreadyExists) {
                this.addablePrograms.push(this.programs[i])
            }
        }
        if (this.addablePrograms.length > 0) {
            this.newProgramFC.setValue(this.addablePrograms[0].id)
        } else {
            this.newProgramFC.setValue(null)
        }
    }

    addCountyById(program, countyId) {
        for (var i = 0; i < this.countiesByProgram[program.id].length; ++i) {
            if (this.countiesByProgram[program.id][i].id == countyId) {
                program.counties.push(this.countiesByProgram[program.id][i])
                break
            }
        }
        this.resetProgramCountySelection(program)
    }

    addCounty(program) {
        program.addCountyError = null
        if (!program.newCountyFC.value) {
            if (program.addableCounties.length == 0) {
                program.addCountyError = "All counties have been added."
            } else {
                program.addCountyError = "Please select a County from the dropdown before adding it to the agency."
            }
            return
        }
        this.addCountyById(program, program.newCountyFC.value)
    }

    removeCounty(program, county) {
        for (var i = 0; i < program.counties.length; ++i) {
            if (program.counties[i].id == county.id) {
                program.counties.splice(i, 1)
                break
            }
        }
        this.resetProgramCountySelection(program)
    }

    resetProgramCountySelection(program) {
        //exclude any added programs from dropdown
        program.addableCounties = []
        if (this.countiesByProgram[program.id]) {
            for (var i = 0; i < this.countiesByProgram[program.id].length; ++i) {
                var alreadyExists = false;
                for (var j = 0; j < program.counties.length; ++j) {
                    if (program.counties[j].id == this.countiesByProgram[program.id][i].id) {
                        alreadyExists = true
                        break
                    }
                }
                if (!alreadyExists) {
                    program.addableCounties.push(this.countiesByProgram[program.id][i])
                }
            }
        }
        if (program.addableCounties.length > 0) {
            program.newCountyFC.setValue(program.addableCounties[0].id)
        } else {
            program.newCountyFC.setValue(null)
        }
    }

    userPermissions
    canAddAgency
    getUserPermissionsPromise() {
        return new Promise((resolve, reject) => {
            this.userService.getUserPermissions().subscribe((response) => {
                if (response.success) {
                    this.userPermissions = response.result
                    this.canAddAgency = this.canUserPerformAction('add-agency-organization')
                } else {
                    console.log("get user permissions error")
                    console.log(response)
                }
                resolve(null)
            })
        })
    }
    private canUserPerformAction(actionId) {
        return this.userService.canUserPerformAction(this.userPermissions, actionId)
    }
}
