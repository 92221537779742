import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControlName, NgControl } from '@angular/forms';

@Directive({
  selector: '[appRoundUp]'
})
export class RoundUpDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostListener('blur')
  onBlur() {
    const value = this.el.nativeElement.value;
    if (value) {
      const roundedValue = Math.ceil(parseFloat(value));
      
      this.el.nativeElement.value = roundedValue.toString();
    }
  }
}
