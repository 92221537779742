<div class="container-fluid">
    <div class="row mb-2">
        <div class="col bold" style="background-color: lightgrey; font-size: 1.2em;">
            Submission Schedule Error
        </div>
    </div>

    <div class="row mb-2">
        <div class="col" align="center">
            <div class="pane py-2" align="center">
                <fa-icon [icon]="faExclamationCircle" style="color: red;font-size: 4em;"></fa-icon><br />
                <span style="font-size: 2em;">Confirmation</span><br />
                Warning! One or more issues have occurred when attempting to create submission entries and send email
                notifications. Please contract SaQus for assistance (<a
                    href="www.saqus.com">www.saqus.com</a>).<br /><br />
                <app-button3 class="mx-1 my-2" label="Go To Dashboard" [clickFunction]="returnToDashboard.bind(this)"
                    [icon]="faUndo">
                </app-button3>
            </div>
        </div>
    </div>

</div>