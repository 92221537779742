<h4 mat-dialog-title>Confirmation</h4>
<div mat-dialog-content>
    <p>Are you sure you want to permanently remove this item?</p>
</div>
<div mat-dialog-actions align="end">
    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
    </app-button3>
    <app-button3 class="mx-1" label="Delete" [clickFunction]="delete.bind(this)" [icon]="faMinus" [isDelete]="true">
    </app-button3>
</div>
