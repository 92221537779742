import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { faCheck, faCircle, faClock, faListUl, faLock, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { CrudService } from '../crud.service';
import { SubmissionScheduleService } from '../submission-schedule.service';
import { UserService } from '../user.service';
import { iPrimengColumn } from '../model/primeng-column.model';
import { iPrimengPageEvent } from '../model/primeng-page-event.model';

@Component({
    selector: 'app-submission-schedule',
    templateUrl: './submission-schedule.component.html',
    styleUrls: ['./submission-schedule.component.scss']
})
export class SubmissionScheduleComponent implements OnInit {
    initialized = false
    faPlus = faPlus
    faClock = faClock

    first: number = 0;
    rows: number = 20;
    subs: any[] = [];
    columns: iPrimengColumn[] =
    [
      { header: 'Program Name', field: 'programName' },
      { header: 'Submission Type', field: 'submissionTypeName' },
      { header: 'Fiscal Name', field: 'fiscalName' },
      { header: 'Fiscal Status', field: 'scheduleStatusName' },
      { header: 'Run Date', field: 'runDate' },
      { header: 'Due Date', field: 'dueDate' },
      { header: 'Actions', field: 'a' }
    ];

    constructor(
        private crudService: CrudService,
        private submissionScheduleService: SubmissionScheduleService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        private datePipe: DatePipe
    ) { }

    onPageChange(event: iPrimengPageEvent)
    {
        this.first = event.first
        this.rows = event.rows
    }

    formatValue(value: any): string 
    {
        if (typeof value === 'string' && !isNaN(Date.parse(value))) 
        {
          const date = new Date(value);
          return this.datePipe.transform(date, 'MM/dd/yyyy');
        }
        return value;
    }

    getSeverity(subType: string): string
    {
        console.log(subType)
        switch(subType)
        {
            case 'Budget':
                return 'success';
            case 'Outreach':
                return 'warning';
            case 'Program':
                return 'danger';
            case 'Administrative Document':
                return 'info';
            case 'Voucher':
                return 'help';
        }
    }

    userOrganizationType
    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    isAppropriator
    getUserIsAppropriator() {
        return new Promise((resolve, reject) => {
            this.userService.isAppropriator().subscribe((response) => {
                if (response.success) {
                    this.isAppropriator = response.result
                } else {
                    console.log("error while getting user is appropriator", response)
                }
                resolve(null)
            })
        })
    }

    showNewButton() {
        return (this.userOrganizationType == "PRIME" || this.userOrganizationType == "SYSTEM")
    }

    fetchData()
    {
        this.submissionScheduleService.getSubmissionSchedules().subscribe({
            next: (response) => {
                console.log("subs", response);
                if (response) 
                {
                    this.subs = response;
                }
            },
            error: (err) => {
                console.error("Error fetching submission schedules:", err);
            },
            complete: () => {
                this.initialized = true;
                console.log("Fetch data complete");
            }
        });
    }

    ngOnInit() {
        this.fetchData()
        this.getUserOrganizationType()
        this.getUserIsAppropriator()
    }

    new() {
        this.router.navigate(["/submission-maintenance-wizard"])
    }

    entryClick(entry) {
        this.router.navigate(["/submission-maintenance-wizard"], { queryParams: { uuid: entry.uuid } })
    }

    navigateEditSubmission(entry: any) {
        this.router.navigate(['/submission-maintenance-wizard'], { queryParams: { uuid: entry.fiscalUuid, viewMode: 'e' } });
    }

}
