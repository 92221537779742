import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan, faCheck, faLongArrowAltLeft, faLongArrowAltRight, faMinus, faSave, faShare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, of } from 'rxjs';
import { delay, map, reduce, shareReplay, startWith, switchMap, tap, } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CrudService } from '../crud.service';
import { SnackRouteService } from '../snack-route.service';
import { SubmissionScheduleService } from '../submission-schedule.service';
import { SubmissionService } from '../submission.service';
import { v4 as uuid } from 'uuid';
import { SystemService } from '../system.service';
import * as Mustache from 'mustache';
import { InputDateFormat } from '../input-date/input-date.component';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-submission-maintenance-wizard',
    templateUrl: './submission-maintenance-wizard.component.html',
    styleUrls: ['./submission-maintenance-wizard.component.scss']
})
export class SubmissionMaintenanceWizardComponent implements OnInit {
    InputDateFormat = InputDateFormat
    faLongArrowAltRight = faLongArrowAltRight
    faLongArrowAltLeft = faLongArrowAltLeft
    faSave = faSave
    faMinus = faMinus
    faBan = faBan
    faTimes = faTimes
    faCheck = faCheck
    faShare = faShare
    uuid = uuid
    mustache = Mustache

    canDelete = false
    editable = false

    steps = [
        "Setup Schedule",
        "Prerequisite",
        "Verify Submission Creation",
        "Publish Submission to Agency"
    ]
    prerequisitesColumns = [
        {
            label: "Agency",
        },
        {
            label: "Program",
        },
        {
            label: "Submission",
        },
        {
            label: "Jurisdiction",
        },

    ]

    verifySubmissionsColumns = [
        {
            label: "Agency",
        },
        {
            label: "Program",
        },
        {
            label: "Submission",
        },
        {
            label: "Jurisdiction",
        },
        {
            label: "Due Date",
        },
    ]

    currentStepIndex = 0

    programFC = new UntypedFormControl()
    submissionTypeFC = new UntypedFormControl()
    fiscalYearFC = new UntypedFormControl()
    statusFC = new UntypedFormControl()
    submissionNameFC = new UntypedFormControl()
    sequenceNumberFC = new UntypedFormControl()
    runDateFC = new UntypedFormControl()
    dueDateFC = new UntypedFormControl()

    programs$ = this.crudService.search({ type: "sys_program" }).pipe(map((response) => {
        return response.result
    }))

    fiscalYear$ = this.crudService.search({ type: "config_fiscal_year" }).pipe(map((response) => {
        return response.result
    }))

    submissionStatus$ = this.crudService.search({ type: "sys_fiscal_status" }).pipe(map((response) => {
        return response.result
    }))


    missingMapping$ = this.crudService.search({ type: "vw_missing_mapping" }).pipe(
        switchMap((response) => {
            return of(response.result)
        })
    )

    newSubmissions$ = this.activatedRoute.queryParams.pipe(
        switchMap((queryParams) => {
            return this.submissionService.getSubmissionByFiscalUuid(queryParams.uuid)
        }),
        switchMap((newSubs) => {
            return of(newSubs.result)
        }),
    )

    viewMode$ = this.activatedRoute.queryParams.pipe(
        switchMap((queryParams) => {
            var ret = { disabled: false, id: queryParams.viewMode }
            if (queryParams.viewMode == "v") {
                ret.disabled = true
            }
            return of(ret)
        }),
    )

    newSubmissionEmailTemplate$ = combineLatest(
        this.systemService.getEmailTemplate("emailSubmissionNew"),
        this.activatedRoute.queryParams.pipe(
            switchMap((queryParams) => {
                return this.submissionScheduleService.getNewSubmissionEmailData(queryParams.uuid)
            }),
        )
    ).pipe(
        switchMap(([templateResponse, emailData]) => {
            document.getElementById('newSubmissionEmailSubject').innerHTML = this.mustache.render(templateResponse.result[0].subject,
                { data_submission_name: emailData.result[0].submission_name })
            document.getElementById('newSubmissionEmailContents').innerHTML = this.mustache.render(templateResponse.result[0].contents,
                {
                    data_user: { full_name: "<Full Name>" },
                    data_submission: {
                        name: emailData.result[0].submission_name,
                        submission_due_date: new Date(emailData.result[0].due_date).toDateString(),
                    }
                })
            return of(templateResponse.result[0])
        }),
    )

    fiscalUuid

    submissionSchedule$ = combineLatest(
        this.activatedRoute.queryParams.pipe(
            switchMap((queryParams) => {
                //return this.crudService.search({ type: "config_fiscal", where: "uuid = ?", whereArgs: [queryParams.uuid] })
                this.fiscalUuid = queryParams.uuid
                if (this.fiscalUuid) {
                    return this.submissionScheduleService.getSubmissionSchedule(queryParams.uuid)
                } else {
                    return of(null)
                }
            }),
        )
    ).pipe(
        tap(([response]) => {
            if (response) {
                console.log("submission schedule search response", response)
                this.programFC.setValue(response.result[0].program_id)
                this.submissionTypeFC.setValue(response.result[0].submission_type_id)
                this.fiscalYearFC.setValue(response.result[0].fiscal_year_id)
                this.statusFC.setValue(response.result[0].fiscal_status_id)
                this.submissionNameFC.setValue(response.result[0].name)
                this.sequenceNumberFC.setValue(response.result[0].campaign_number)
                if (response.result[0].run_date) {
                    this.runDateFC.setValue(formatDate(response.result[0].run_date, 'yyyy-MM-dd', 'en'))
                }
                if (response.result[0].due_date) {
                    this.dueDateFC.setValue(formatDate(response.result[0].due_date, 'yyyy-MM-dd', 'en'))
                }
                if (this.statusFC.value == "D") {
                    this.canDelete = true
                }
            }
        }),
        map(([response]) => {
            if (response) {
                return response.result
            }
            return []
        }),
        shareReplay(1),
    )

    submissionType$ = combineLatest(
        this.submissionSchedule$,
        this.programFC.valueChanges.pipe(startWith(this.programFC.value)),
        this.crudService.search({ type: "sys_submission_type" }),
    ).pipe(
        switchMap(([submission, program, submissionTypeResponse]) => {
            var ret = []
            if (!program) {
                if (submission && submission[0]) {
                    program = submission[0].program_id
                }
            }

            if (submissionTypeResponse.result) {
                submissionTypeResponse.result.forEach(row => {
                    if (row.program_id == program) {
                        ret.push(row)
                    }
                });
            }
            if (ret.length == 0) {
                if (!program) {
                    ret.push({ name: "Please select a program" })
                } else {
                    ret.push({ name: "Selected program has no submission types" })
                }
            } else {
                ret.unshift("")
            }
            return of(ret)
        })
    )

    cancel() {
        this.router.navigate(["/submission-schedule"])
    }

    delete() {

    }

    saveForLater() {

        //todo - validation

        var operation = "insert"
        var uuid
        if (this.fiscalUuid) {
            operation = "update"
            uuid = this.fiscalUuid
        } else {
            uuid = this.uuid()
        }

        var entry = {
            uuid,

            name: this.submissionNameFC.value,
            fiscal_year_id: this.fiscalYearFC.value,
            submission_type_id: this.submissionTypeFC.value,
            //config_account_uuid:,
            //sub_only,
            due_date: this.dueDateFC.value,
            campaign_number: this.sequenceNumberFC.value,
            fiscal_status_id: this.statusFC.value,
            schedule_status_id: "STAGED",
            run_date: this.runDateFC.value,
            //exceptions,
            creator: this.authService.userId,
            //created: ,
            //modifier,
            //modified,
        }

        this.crudService.applyOperations({
            entries: [{
                type: "config_fiscal",
                operation,
                value: entry,
            }]
        }).subscribe((response) => {
            this.fiscalUuid = uuid
            this.snackRoute.snackRoute("schedule saved for later", "/submission-schedule")
        })
    }

    get isFirstStep() {
        return this.currentStepIndex <= 0
    }

    get isLastStep() {
        return this.currentStepIndex >= this.steps.length - 1
    }

    nextAllowed = true
    next(submission, missingMapping) {

        if (this.currentStepIndex == 1) {

            if (missingMapping.length > 0) {
                this.snackRoute.snack("Please correct missing mappings before proceeding.")
                return
            }

            if (!submission || !submission.length ||
                (submission[0].schedule_status_id != "READY" && submission[0].schedule_status_id != "STAGED")) {
                this.snackRoute.snack("Schedule must be in a Ready (or Staged) state to continue.")
                return
            }

            var prereqRequired = this.submissionTypeFC.value == "NOEP_BUDGET" || this.submissionTypeFC.value == "NOEP_PROGRAM"
            var prereqIncomplete = true
            if (submission && submission.length && submission[0].data_entry_complete) {
                prereqIncomplete = false
            }

            if (prereqRequired && prereqIncomplete) {
                if (this.submissionTypeFC.value == "NOEP_BUDGET") {
                    this.snackRoute.snack("Please complete budget appropriation data entry before continuing.")
                } else if (this.submissionTypeFC.value == "NOEP_PROGRAM") {
                    this.snackRoute.snack("Please complete main target data entry before continuing.")
                } else {
                    this.snackRoute.snack("Please complete data entry before continuing.")
                }
                return
            }

            this.nextAllowed = false
            this.submissionScheduleService.submissionWizardStepThreeSubmit(this.fiscalUuid).subscribe((response) => {
                if (response.success) {
                    this.nextAllowed = true
                    ++this.currentStepIndex
                } else {
                    console.log(response)
                    this.router.navigate(["/submission-maintenance-wizard-error"])
                }
            })
        }

        if (this.nextAllowed && !this.isLastStep) {
            ++this.currentStepIndex
        }
    }

    previous() {
        if (!this.isFirstStep) {
            --this.currentStepIndex
        } else {
            this.cancel()
        }
    }

    finishing = false
    finish() {
        if (!(<HTMLInputElement>document.getElementById("verifyCorrect")).checked) {
            this.snackRoute.snack("Please verify the information is correct before continuing.")
            return
        }
        if (!this.finishing) {
            this.finishing = true
            this.submissionScheduleService.submissionWizardFinish(this.fiscalUuid).subscribe((response) => {
                this.finishing = false
                if (response.success) {
                    this.router.navigate(["/submission-maintenance-wizard-confirmation"])
                } else {
                    console.log(response)
                    this.router.navigate(["/submission-maintenance-wizard-error"])
                }
            })
        }
    }

    constructor(
        private router: Router,
        private submissionScheduleService: SubmissionScheduleService,
        private activatedRoute: ActivatedRoute,
        private crudService: CrudService,
        private submissionService: SubmissionService,
        private authService: AuthService,
        private snackRoute: SnackRouteService,
        private systemService: SystemService,
    ) { }

    initialized = false
    ngOnInit() {
        this.initialized = true
    }

}
