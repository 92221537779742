<h1 mat-dialog-title>Select Outreach to add to Voucher</h1>

<mat-dialog-content class="dialog-content">
  <div class="table-container">
    <table mat-table [dataSource]="data" class="mat-elevation-z8">
      <!-- county Column -->
      <ng-container matColumnDef="county">
        <th mat-header-cell *matHeaderCellDef class="table-header"> County </th>
        <td mat-cell *matCellDef="let data"> {{data.county}} </td>
      </ng-container>
      <!--request number column-->
      <ng-container matColumnDef="referenceNumber">
        <th mat-header-cell *matHeaderCellDef class="table-header"> Request # </th>
        <td mat-cell *matCellDef="let data"> {{data.referenceNumber}} </td>
      </ng-container>
      <!--request name column-->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="table-header"> Request Name </th>
        <td mat-cell *matCellDef="let data" style="text-align: left; padding-left: 3rem;"> {{data.name}} </td>
      </ng-container>
      <!--date placed column-->
      <ng-container matColumnDef="agencyDate">
        <th mat-header-cell *matHeaderCellDef> Date Placed </th>
        <td mat-cell *matCellDef="let data"> {{data.agencyDate | date: 'M/d/yyyy'}} </td>
      </ng-container>
      <!--date approved column-->
      <ng-container matColumnDef="approvalDate">
        <th mat-header-cell *matHeaderCellDef> Date Approved </th>
        <td mat-cell *matCellDef="let data"> {{data.approvalDate  | date: 'M/d/yyyy'}} </td>
      </ng-container>
      <!--snap column-->
      <ng-container matColumnDef="creditFederalEst">
        <th mat-header-cell *matHeaderCellDef> SNAP </th>
        <td mat-cell *matCellDef="let data"> {{data.stateOnly}} </td>
      </ng-container>
      <!--child nutrition column-->
      <ng-container matColumnDef="debitStateEst">
        <th mat-header-cell *matHeaderCellDef> Child Nutrition/SNAP </th>
        <td mat-cell *matCellDef="let data"> {{data.stateFederal}} </td>
      </ng-container>
      <ng-container matColumnDef="button">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let data">
          <button mat-raised-button color="add-line-button" (click)="addOutreachLine(data.uuid)">
            <fa-icon [icon]="faPlus"></fa-icon>&nbsp;Add
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button mat-raised-button (click)="closeDialog()" class="close-button">
    Close
  </button>
</mat-dialog-actions>
