import { Component, OnInit } from '@angular/core';
import { QuestionService } from '../question.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { SnackRouteService } from '../snack-route.service';
import { faBan, faLayerGroup, faSave } from '@fortawesome/free-solid-svg-icons';
import { ParamService } from '../param.service';

@Component({
    selector: 'app-edit-question-group',
    templateUrl: './edit-question-group.component.html',
})
export class EditQuestionGroupComponent implements OnInit {
    faBan = faBan
    faSave = faSave
    faLayerGroup = faLayerGroup
    stepNameFC = new UntypedFormControl()
    groupNumberFC = new UntypedFormControl()
    nameFC = new UntypedFormControl()
    longNameFC = new UntypedFormControl()
    subtotalGroupFC = new UntypedFormControl(false)
    allowAdditionalFC = new UntypedFormControl(false)
    subtotalAdditionalFC = new UntypedFormControl(false)
    initialized = false
    group

    constructor(
        private _questionService: QuestionService,
        private snackRouteService: SnackRouteService,
        private paramService: ParamService,
    ) { }

    get questionService() {
        return this._questionService
    }

    getGroupPromise() {
        return new Promise((resolve, reject) => {
            this.questionService.getGroupById(this.groupId).subscribe((response) => {
                if (response.success) {
                    if (response.result.length != 1) {
                        console.log("unexpected nonsingular result encountered")
                    } else {
                        this.group = response.result[0]
                        this.stepNameFC.setValue(this.group.step_name)
                        this.groupNumberFC.setValue(this.group.group_number)
                        this.nameFC.setValue(this.group.name)
                        this.longNameFC.setValue(this.group.long_name)
                        this.subtotalGroupFC.setValue(this.group.subtotal_group)
                        this.allowAdditionalFC.setValue(this.group.allow_additional)
                        this.subtotalAdditionalFC.setValue(this.group.subtotal_additional)
                        this.allowAdditionalChange()
                    }
                } else {
                    console.log("error while fetching group", response)
                }
                resolve(null)
            })
        })
    }

    getStepPromise() {
        return new Promise((resolve, reject) => {
            this.questionService.getStepById(this.stepId).subscribe((response) => {
                if (response.success) {
                    if (response.result.length != 1) {
                        console.log("unexpected nonsingular result encountered")
                    } else {
                        this.stepNameFC.setValue(response.result[0].name)
                    }
                } else {
                    console.log("error while fetching step", response)
                }
                resolve(null)
            })
        })
    }

    stepId
    groupId
    ngOnInit() {
        this.paramService.getParamPromise("stepId").then((paramValue) => {
            this.stepId = paramValue
            return this.paramService.getParamPromise("groupId")
        }).then((paramValue) => {
            this.groupId = paramValue
            if (!this.stepId && !this.groupId) {
                return this.snackRouteService.snackRoute("No step or group specified", "/manage-questions")
            }
            var initPromise
            if (this.groupId != null) {
                initPromise = this.getGroupPromise()
            } else {
                initPromise = this.getStepPromise()
            }
            return initPromise
        }).then(() => {
            this.initialized = true
        })
    }

    error
    validate() {
        this.error = null
        if (this.groupNumberFC.value && !Number.isInteger(parseFloat(this.groupNumberFC.value))) {
            return this.error = "Group # must be an integer."
        }
        if (!this.groupNumberFC.value) {
            return this.error = "Please specify a group number."
        }
        if (!this.nameFC.value) {
            return this.error = "Please specify a name."
        }
        if (!this.longNameFC.value) {
            return this.error = "Please specify a long name."
        }
    }

    save() {
        this.validate()
        if (this.error) {
            return
        }
        this.questionService.saveGroup(
            this.stepId,
            this.groupId,
            this.groupNumberFC.value,
            this.nameFC.value,
            this.longNameFC.value,
            this.subtotalGroupFC.value,
            this.allowAdditionalFC.value,
            this.subtotalAdditionalFC.value,
        ).subscribe((response) => {
            if (response.success) {
                this.snackRouteService.snackRoute("Step successfully saved", "/manage-questions")
            } else {
                console.log("error while saving step", response)
                this.snackRouteService.snack("Error encountered while saving step")
            }
        })
    }

    cancel() {
        return this.snackRouteService.snackRoute("Action Cancelled", "/manage-questions")
    }

    allowAdditionalChange() {
        if (this.allowAdditionalFC.value) {
            this.subtotalAdditionalFC.enable()
        } else {
            this.subtotalAdditionalFC.setValue(false)
            this.subtotalAdditionalFC.disable()
        }
    }
}
