<div *ngIf="!style" class="row">
    <div class="col-4 pb-2 unedit-padding bold">
        {{label}}
    </div>
    <div class="col-8">
        <div class="row">
            <div class="col-4 unedit-padding" #imageCol>
                <span *ngIf="none">None</span>
            </div>
            <div class="col-md-8">
                <input *ngIf="!disabled" type="file" [formControl]="fileFormControl" id="file"
                    (change)="handleFileInput($event.target.files)">
            </div>
        </div>
    </div>
</div>
<div *ngIf="!style && error" class="row">
    <div class="col-4">
    </div>
    <div class="col-8 bold">
        {{error}}
    </div>
</div>
<div *ngIf="!style && uploadStatus" class="row pt-4">
    <div class="col attribute-h2 bold">
        {{uploadStatus}}
    </div>
</div>
<div *ngIf="!style && percentDone != null" class="row py-2 ">
    <div class="col">
        <div style="position: relative; height: 1.6em;">
            <div [ngStyle]="{ 'background-color': 'lightblue', 'width': percentDone + '%'}"
                style="height: 1.6em; position: absolute; left: 0; top: 0;">
            </div>
            <div [ngStyle]="{ 'width':  '100%'}"
                style="height: 1.6em; position: absolute; left: 0; top: 0; font-weight: bold;text-align: center;">
                {{percentDone}}%
            </div>
            <div
                style="width: 100%; border-style: solid; border-width: 1px; border-color: black; height: 1.6em; position: absolute; left: 0; top: 0;">
            </div>
        </div>
    </div>
</div>


<div *ngIf="style == 'rebrand2'" class="row mt-3">
    <div class="col bold" style="color: darkgrey">
        {{label}}
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row">
    <div class="col ml-2 unedit-padding" align="center">
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row">
    <div class="col ml-2" #imageCol align="center">
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row">
    <div class="col ml-2" align="center">
        <label for="file" class="mb-0" style="width: 100%; cursor: pointer;">
            <div *ngIf="none" class="px-2 py-2 none-border" align="center">
                <div class="none-image" align="center">
                    <fa-icon [icon]="getUnsetIcon()" flip="horizontal"></fa-icon>
                </div>
            </div>
        </label>
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row mt-1">
    <div class="col ml-2" style="color: #6c89a5">
        <input type="file" style="display: none;" [formControl]="fileFormControl" id="file"
            (change)="handleFileInput($event.target.files)">
        <label for="file" class="mb-0 bold" style="cursor: pointer;">
            <fa-icon [icon]="faUpload"></fa-icon> Upload photo
        </label>
        <span style="color: lightgrey;"> not more than 1mb</span>
        <span *ngIf="!none" style="color: #6c89a5; cursor: pointer;" (click)="clearImage()"> (clear)</span>
    </div>
</div>