import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes, faBan, faExclamationTriangle, faCheck, faSave, faMoneyBillWave, faHourglass, faMinus, faFlag, faUser, faFileSignature, faStamp, faPercent, faThumbsUp, faAmericanSignLanguageInterpreting } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { BudgetService } from '../budget.service';
import { SnackRouteService } from '../snack-route.service';
import { SubmissionCommentService } from '../submission-comment.service';
import { SubmissionService } from '../submission.service';
import { SystemService } from '../system.service';

@Component({
    selector: 'app-budget-personnel',
    templateUrl: './budget-personnel.component.html',
    styleUrls: ['./budget-personnel.component.scss'],
})
export class BudgetPersonnelComponent implements OnInit {
    saveError
    faTimes = faTimes
    faBan = faBan
    faExclamationTriangle = faExclamationTriangle
    faCheck = faCheck
    faSave = faSave
    faMoneyBillWave = faMoneyBillWave
    faHourglass = faHourglass
    faMinus = faMinus
    faFlag = faFlag
    faUser = faUser
    faFileSignature = faFileSignature
    faStamp = faStamp
    faPercent = faPercent
    faThumbsUp = faThumbsUp

    userFlagFC = new UntypedFormControl()

    showWorksheet = true

    inputDataById = {}

    round(fc, decimalPlaces?) {
        if (fc.value) {
            if (typeof fc.value == "string") {
                fc.setValue(parseFloat(fc.value))
            }
            fc.setValue(fc.value.toFixed(decimalPlaces))
        }
    }

    flagClick(inputId) {

        var inputData = this.inputDataById[inputId]
        if (inputData) {
            inputData.showInputContainer.value = !inputData.showInputContainer.value
        } else {
            this.inputDataById[inputId] = {
                showInputContainer: { value: true }
            }
        }
    }

    submissionUuid
    personnelEntryUuid

    constructor(
        private budgetService: BudgetService,
        private _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private submissionCommentService: SubmissionCommentService,
        private snackRoute: SnackRouteService,
        private router: Router,
        public dialog: MatDialog,
        private authService: AuthService,
        private submissionService: SubmissionService,
        private systemService: SystemService,
    ) { }

    payPeriodOptions
    payPeriodsById = {}
    getPayPeriodOptions() {
        return new Promise((resolve, reject) => {
            this.budgetService.getPayPeriods().subscribe(response => {
                //console.log("pay period response", response)
                if (response.success) {
                    this.payPeriodOptions = response.result
                    this.payPeriodOptions.forEach(element => {
                        this.payPeriodsById[element.id] = element
                    });
                }
                resolve(null)
            })
        })
    }

    @Input() submissionUuidInput
    @Input() personnelEntryUuidInput
    @Input() disableOverride
    @Input() hideFringe
    @Input() hideButtons

    paramPromise() {
        return new Promise((resolve, reject) => {
            //console.log("PERSONNEL INIT", this.submissionUuidInput, this.personnelEntryUuidInput)
            if (this.submissionUuidInput && this.personnelEntryUuidInput) {
                this.submissionUuid = this.submissionUuidInput
                this.personnelEntryUuid = this.personnelEntryUuidInput
                resolve(null)
            } else {
                this.activatedRoute.queryParams.subscribe(params => {
                    this.submissionUuid = params["submissionUuid"]
                    this.personnelEntryUuid = params["personnelEntryUuid"]
                    resolve(null)
                })
            }
        })
    }
    benefits
    getBudgetBenefitsPromise() {
        return new Promise((resolve, reject) => {
            this.budgetService.getBenefits().subscribe((response) => {
                //console.log("benefits response", response)
                if (response.success) {
                    this.benefits = response.result
                }
                resolve(null)
            })
        })
    }

    entryData
    entryData$

    submission
    submission_status: string
    getSubmissionPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmission(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.submission = response.result[0]
                    console.log("submission for review", this.submission)
                    this.submission_status = this.submission.status_id
                } else {
                    console.log("error while getting submission", response)
                }
                resolve(null)
            })
        })
    }

    newEntry = true
    masterUuid

    fixedBenefitsEntries = []
    controllableBenefitsEntries = []

    fixedBenefitPercentChange(benefitEntry, salaryCalculatedAmount, hourlyCalculatedAmount) {
        benefitEntry.employeePercentFC.setValue(100 - benefitEntry.percentAllocationFC.value)
        this.setFringeTotals(benefitEntry, salaryCalculatedAmount, hourlyCalculatedAmount)
    }

    fixedBenefitEmployeePercentChange(benefitEntry, salaryCalculatedAmount, hourlyCalculatedAmount) {
        benefitEntry.percentAllocationFC.setValue(100 - benefitEntry.employeePercentFC.value)
        this.setFringeTotals(benefitEntry, salaryCalculatedAmount, hourlyCalculatedAmount)
    }


    setFringeTotals(benefitEntry, salaryCalculatedAmount, hourlyCalculatedAmount) {
        //console.log("fringe totaling", entry)
        // entry.totalOne = parseFloat(entry.employeePortionFC.value)
        //     + parseFloat(entry.employerPortionFC.value)
        //     * parseFloat(entry.percentAllocationFC.value)
        //     / 100
        // entry.totalTwo = parseFloat(entry.federalFC.value) + parseFloat(entry.stateFC.value)
        // entry.federalPercent = parseFloat(entry.federalFC.value) / entry.totalTwo
        // entry.statePercent = parseFloat(entry.stateFC.value) / entry.totalTwo

        /*
                                        <div *ngIf="salarySelected" class="col" style="font-size: .8em;">
                                            {{salaryCalculatedAmount.calculatedAmount * benefitEntry.percentAllocationFC.value}}
                                        </div>
                                        <div *ngIf="hourlySelected" class="col" style="font-size: .8em;">
                                            {{hourlyCalculatedAmount.calculatedAmount * benefitEntry.percentAllocationFC.value}}
                                        </div>
        */

        if (this.salarySelected) {
            benefitEntry.calculatedAmount = salaryCalculatedAmount.calculatedAmount * benefitEntry.percentAllocationFC.value / 100
        }

        if (this.hourlySelected) {
            benefitEntry.calculatedAmount = hourlyCalculatedAmount.calculatedAmount * benefitEntry.percentAllocationFC.value / 100
        }

    }

    initialized

    commentFields = [
        "employee_name",
        "job_title",
        "date_of_hire",
        "date_of_termination",
        "salary_estimated_date_of_raise",
        "hourly_estimated_date_of_raise",
        "program",
        "federal",
        "state",
        "total_allocation",
        "annual_salary",
        "salary_annual_hours",
        "hourly_rate",
        "estimated_weekly_hours",
        "hourly_annual_hours",
        "new_salary_amount",
        "new_hourly_rate",
        "estimated_bonus_amount",
        "estimated_bonus_date",
        "estimated_weekly_hours_on_contract",
        "estimated_annual_hours_on_contract",
        "estimated_percent_time_on_contract",
        "term_start_date",
        "term_end_date",
        "type_of_personnel",
        "salary_pay_period",
        "hourly_pay_period",
        "salary_hours_per_pay_period",
        "hourly_hours_per_pay_period",
        "amount_charged_to_contract",
        "state_only",
        "state_match_federal_match",
        "employee_allocation",
        "pay_period",
    ]
    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    showSubWorksheet = "false"
    getShowWorksheetToSub() {
        return new Promise((resolve, reject) => {
            this.systemService.getSystemProperty("agency.display.calculation_worksheet").subscribe((response) => {
                if (response.success) {
                    this.showSubWorksheet = response.value
                }
                resolve(null)
            })
        })
    }
    userOrganizationType
    showFlag
    editable
    ngOnInit() {
        Promise.all([
            this.paramPromise(),
            this.getBudgetBenefitsPromise(),
            this.getUserOrganizationType(),
            this.getPayPeriodOptions(),
            this.getShowWorksheetToSub(),
        ]).then(() => {
            return Promise.all([
                this.getSubmissionPromise(),
                console.log(this.submission_status)
            ]).then(() => {
                if (this.userOrganizationType == "SUB" && this.showSubWorksheet != "true") {
                    this.showWorksheet = false
                }

                /* prime should be only org type to view calculation worksheet, per client request HSNY-1689 */
                if(this.userOrganizationType != "PRIME") {
                    this.showWorksheet = false
                } else {
                    this.showWorksheet = true
                }

                if (this.personnelEntryUuid) {
                    console.log("not new")
                    this.personnelTypeFC.disable()
                    if (!this.disableOverride) {
                        this.editable = this.userOrganizationType == "SUB" && this.submission.sub_action == 'EDIT' 
                        this.showFlag = (this.userOrganizationType == "PRIME" || this.userOrganizationType == "SYSTEM")
                            && this.submission.prime_action == 'AUDIT'
                    }
                    return this.submissionCommentService.getSubmissionCommentsPromise(this.submissionUuid, this.personnelEntryUuid)
                } else {
                    console.log("new")
                    this.editable = true
                }
            }).then((result: any) => {
                //console.log("comments result", result)

                this.benefits.forEach(element => {
                    this.commentFields.push("agent_comment_" + element.uuid)
                    this.commentFields.push("hsny_comment_" + element.uuid)
                });

                for (var i = 0; i < this.commentFields.length; ++i) {
                    this.inputDataById[this.commentFields[i]] = {
                        showInputContainer: { value: false }
                    }
                }
                if (result) {
                    for (var i = 0; i < result.length; ++i) {
                        this.inputDataById[result[i].target_column] = {
                            comment: result[i].comment,
                            commentUuid: result[i].uuid,
                            showInputContainer: { value: false }
                        }
                    }
                }

                if (!this.editable) {
                    this.anticipateRaiseFC.disable()
                    this.anticipateBonusFC.disable()
                    this.contractTermChoiceFC.disable()

                    for (var i = 0; i < this.fixedBenefitsEntries.length; ++i) {
                        this.fixedBenefitsEntries[i].employeePercentFC.disable()
                        this.fixedBenefitsEntries[i].percentAllocationFC.disable()
                        this.fixedBenefitsEntries[i].stateFC.disable()
                        this.fixedBenefitsEntries[i].federalFC.disable()
                        this.fixedBenefitsEntries[i].unitPriceFC.disable()
                        this.fixedBenefitsEntries[i].fringeAmount.disable()
                        this.fixedBenefitsEntries[i].agencyCommentFC.disable()
                    }
                    for (var i = 0; i < this.controllableBenefitsEntries.length; ++i) {
                        this.controllableBenefitsEntries[i].employeePercentFC.disable()
                        this.controllableBenefitsEntries[i].percentAllocationFC.disable()
                        this.controllableBenefitsEntries[i].stateFC.disable()
                        this.controllableBenefitsEntries[i].federalFC.disable()
                        this.controllableBenefitsEntries[i].unitPriceFC.disable()
                        this.controllableBenefitsEntries[i].fringeAmountFC.disable()
                        this.controllableBenefitsEntries[i].agencyCommentFC.disable()
                    }
                }

                if (!this.editable) {
                    this.personnelTypeFC.disable()
                    this.employeeAllocationFC.disable()
                    this.estimatedPercentTimeOnContractFC.disable()
                }

                this.initialized = true

                this.entryData$ = this.activatedRoute.queryParams.pipe(
                    switchMap((queryParams) => {
                        var entryUuid
                        if (queryParams.personnelEntryUuid) {
                            entryUuid = queryParams.personnelEntryUuid
                        } else if (this.personnelEntryUuidInput) {
                            entryUuid = this.personnelEntryUuidInput
                        }
                        if (entryUuid) {
                            //console.log("personnel uuid", entryUuid)
                            return this.budgetService.getPersonnel(entryUuid)
                        } else {
                            return of(null)//?
                        }
                    }),
                    switchMap((personnelResponse) => {
                        var _return = {}
                        if (personnelResponse) {
                            this.entryData = personnelResponse.result
                            _return = this.entryData
                        }
                        this.initForm()
                        this.initSalCal()
                        this.initHouCal()
                        return of(_return)
                    }),
                    shareReplay(1),
                )

            })
        })
    }

    debitState
    debitFederal
    debitStateEst
    debitFederalEst
    initForm() {

        if (this.entryData) {
            this.newEntry = false
            var entry
            //console.log("entry data", this.entryData)
            for (var i = 0; i < this.entryData.length; ++i) {
                if (this.entryData[i].account_uuid == "NOEP_PRSNL" || this.entryData[i].account_uuid == "NOEP_PRSNL_OH") {
                    entry = this.entryData[i]
                    this.masterUuid = this.entryData[i].finance_uuid
                } else {
                    if (this.entryData[i].is_fixed) {
                        this.fixedBenefitsEntries.push(this.entryData[i])
                    } else {
                        //console.log("adding controllable", this.entryData[i])
                        this.controllableBenefitsEntries.push(this.entryData[i])
                    }

                    this.entryData[i].percentAllocationFC = new UntypedFormControl(this.entryData[i].percentage)
                    this.entryData[i].employeePercentFC = new UntypedFormControl(100 - this.entryData[i].percentage)
                    this.entryData[i].unitPriceFC = new UntypedFormControl(this.entryData[i].unit_price)
                    
                    this.entryData[i].stateFC = new UntypedFormControl(this.entryData[i].debit_state)
                    this.entryData[i].federalFC = new UntypedFormControl(this.entryData[i].debit_federal)
                    
                    this.entryData[i].fringeAmountFC = new UntypedFormControl(this.entryData[i].fringe_amount)
                    this.entryData[i].agencyCommentFC = new UntypedFormControl(this.entryData[i].agency_comment)


                    //this.setFringeTotals(this.entryData[i])
                }
            }

            if (!entry) {
                console.log("internal error - no NOEP_PRSNL found")
            } else {
                this.employeeNameFC.setValue(entry.finance_name)
                this.jobDescriptionFC.setValue(entry.finance_description)
                this.dateOfHireFC.setValue(formatDate(entry.hire_date, 'yyyy-MM-dd', 'en'))
                if (entry.termination_date) {
                    this.dateOfTerminationFC.setValue(formatDate(entry.termination_date, 'yyyy-MM-dd', 'en'))
                }
                this.personnelTypeFC.setValue(entry.account_uuid)
                this.programSBPFC.setValue(entry.sbp)
                this.programSFSPFC.setValue(entry.sfsp)
                this.programSNAPFC.setValue(entry.snap)

                this.amountChargedToContractFC.setValue(entry.unit_price)
                this.stateOnlyFC.setValue(entry.debit_state)
                this.stateMatchFederalMatchFC.setValue(entry.debit_federal)

                this.hourlySelected = entry.salaried == 0
                this.salarySelected = entry.salaried == 1


                this.annualSalaryFC.setValue(entry.annual_salary)
                this.salaryHoursPerPayPeriodFC.setValue(entry.hours_per_pay_period)
                this.hourlyHoursPerPayPeriodFC.setValue(entry.hours_per_pay_period)
                this.hourlyRateFC.setValue(entry.hourly_rate)

                this.salaryPayPeriodFC.setValue(entry.pay_period)
                this.hourlyPayPeriodFC.setValue(entry.pay_period)

                this.anticipateRaiseFC.setValue(entry.raise_expected)
                this.newSalaryAmountFC.setValue(entry.new_annual_salary)
                this.newHourlyRateFC.setValue(entry.new_hourly_rate)
                if (entry.raise_date) {
                    this.salaryEstimatedDateOfRaiseFC.setValue(formatDate(entry.raise_date, 'yyyy-MM-dd', 'en'))
                    this.hourlyEstimatedDateOfRaiseFC.setValue(formatDate(entry.raise_date, 'yyyy-MM-dd', 'en'))
                }

                this.anticipateBonusFC.setValue(entry.bonus_expected)
                this.estimatedBonusAmountFC.setValue(entry.bonus_amount)
                if (entry.bonus_date) {
                    this.estimatedBonusDateFC.setValue(formatDate(entry.bonus_date, 'yyyy-MM-dd', 'en'))
                }

                this.contractTermChoiceFC.setValue(entry.full_term)
                if (entry.term_start_date) {
                    this.termStartDateFC.setValue(formatDate(entry.term_start_date, 'yyyy-MM-dd', 'en'))
                }
                if (entry.term_end_date) {
                    this.termEndDateFC.setValue(formatDate(entry.term_end_date, 'yyyy-MM-dd', 'en'))
                }

                var employeeAllocationOptionValue
                if (entry.exclusive) {
                    employeeAllocationOptionValue = 3
                } else {
                    employeeAllocationOptionValue = 4
                }
                this.employeeAllocationFC.setValue(employeeAllocationOptionValue)
                this.estimatedPercentTimeOnContractFC.setValue(entry.percent_time_on_contract)
                this.userFlagFC.setValue(entry.user_flag)

                this.debitState = entry.debit_state
                this.debitFederal = entry.debit_federal
                this.debitStateEst = entry.debit_state_est
                this.debitFederalEst = entry.debit_federal_est
            }
        }
        //ensure benefitsEntries entry exists for every benefits type
        for (var i = 0; i < this.benefits.length; ++i) {
            var matched = false
            for (var j = 0; j < this.fixedBenefitsEntries.length; ++j) {
                if (this.fixedBenefitsEntries[j].account_uuid == this.benefits[i].uuid) {
                    matched = true
                    break
                }
            }
            for (var j = 0; j < this.controllableBenefitsEntries.length; ++j) {
                if (this.controllableBenefitsEntries[j].account_uuid == this.benefits[i].uuid) {
                    matched = true
                    break
                }
            }
            //console.log("MATCHED?", matched, this.benefits[i].uuid, this.benefits[i].is_fixed)
            if (!matched) {
                var newEntry: any = {
                    account_uuid: this.benefits[i].uuid,
                    screen: this.benefits[i].screen,
                    name: this.benefits[i].name,
                    display: this.benefits[i].display,
                }
                if (this.benefits[i].is_fixed) {
                    this.fixedBenefitsEntries.push(newEntry)
                } else {
                    this.controllableBenefitsEntries.push(newEntry)
                }
                newEntry.reporting_group = this.benefits[i].reporting_group
                newEntry.unitPriceFC = new UntypedFormControl()
                newEntry.percentAllocationFC = new UntypedFormControl('0')
                newEntry.employeePercentFC = new UntypedFormControl('0')
                newEntry.stateFC = new UntypedFormControl('0')
                newEntry.federalFC = new UntypedFormControl('0')
                newEntry.fringeAmountFC = new UntypedFormControl('0')
                newEntry.agencyCommentFC = new UntypedFormControl()
            }
        }
         //console.log("FIXED", this.fixedBenefitsEntries)
        // console.log("CONTROLLABLE", this.controllableBenefitsEntries)

        this.fixedBenefitsEntries.sort(function (a, b) {
            return a.display - b.display;
        })
        this.controllableBenefitsEntries.sort(function (a, b) {
            return a.display - b.display;
        })

        this.fringeSetup()
        this.setInputState()
    }

    fringeSetup() {
        this.fixedFringeTableEntries.length = 0
        this.controllableFringeTableEntries.length = 0

        if (!this.personnelTypeFC.value) {
            return
        }

        for (var i = 0; i < this.fixedBenefitsEntries.length; ++i) {
            if (this.fixedBenefitsEntries[i].screen != "_BENEFITS") {
                continue
            }
            if (this.personnelTypeFC.value == "NOEP_PRSNL_OH" && this.fixedBenefitsEntries[i].reporting_group != "Overhead") {
                continue
            }
            if (this.personnelTypeFC.value == "NOEP_PRSNL" && this.fixedBenefitsEntries[i].reporting_group != "Fringe") {
                continue
            }

            this.fixedFringeTableEntries.push({ type: this.fringeTableEntryType.entry, entry: this.fixedBenefitsEntries[i], odd: i % 2 == 0 })
            this.fixedFringeTableEntries.push({ type: this.fringeTableEntryType.error, entry: this.fixedBenefitsEntries[i], odd: i % 2 == 0 })
            this.fixedFringeTableEntries.push({ type: this.fringeTableEntryType.comment, entry: this.fixedBenefitsEntries[i], odd: i % 2 == 0 })
        }
        for (var i = 0; i < this.controllableBenefitsEntries.length; ++i) {
            if (this.controllableBenefitsEntries[i].screen != "_BENEFITS") {
                continue
            }
            if (this.personnelTypeFC.value == "NOEP_PRSNL_OH" && this.controllableBenefitsEntries[i].reporting_group != "Overhead") {
                continue
            }
            if (this.personnelTypeFC.value == "NOEP_PRSNL" && this.controllableBenefitsEntries[i].reporting_group != "Fringe") {
                continue
            }
            console.log("control output", this.controllableFringeTableEntries[i])
            this.controllableFringeTableEntries.push({ type: this.fringeTableEntryType.entry, entry: this.controllableBenefitsEntries[i], odd: i % 2 == 0 })
            this.controllableFringeTableEntries.push({ type: this.fringeTableEntryType.error, entry: this.controllableBenefitsEntries[i], odd: i % 2 == 0 })
            this.controllableFringeTableEntries.push({ type: this.fringeTableEntryType.comment, entry: this.controllableBenefitsEntries[i], odd: i % 2 == 0 })
        }

    }

    fringeTableEntryType = {
        entry: 0,
        comment: 1,
        error: 2,
    }

    fixedFringeTableEntries = []
    controllableFringeTableEntries = []

    salaryPayPeriodFC = new UntypedFormControl()
    salaryPayPeriodError

    hourlyPayPeriodFC = new UntypedFormControl()
    hourlyPayPeriodError

    employeeNameFC = new UntypedFormControl()
    employeeNameError

    jobDescriptionFC = new UntypedFormControl()
    jobDescriptionError

    dateOfHireFC = new UntypedFormControl()
    dateOfHireError

    dateOfTerminationFC = new UntypedFormControl()
    dateOfTerminationError

    personnelTypeFC = new UntypedFormControl()
    personnelTypeError

    programSBPFC = new UntypedFormControl()
    programSFSPFC = new UntypedFormControl()
    programSNAPFC = new UntypedFormControl()
    programError

    programOptions = [
        {
            fc: this.programSBPFC,
            label: "SBP",
        },
        {
            fc: this.programSFSPFC,
            label: "SFSP",
        },
        {
            fc: this.programSNAPFC,
            label: "SNAP",
        },
    ]

    disableFCs(fcs, disabled) {
        for (var i = 0; i < fcs.length; ++i) {
            if (disabled) {
                fcs[i].disable()
            } else {
                fcs[i].enable()
            }
        }
    }

    salaryOrHourlyError
    salarySelected = false
    hourlySelected = false

    salary() {
        if (!this.editable) {
            return
        }
        this.salarySelected = true
        this.hourlySelected = false
        this.setInputState()
        this.salaryOrHourlyError = null
    }

    hourly() {
        if (!this.editable) {
            return
        }
        this.salarySelected = false
        this.hourlySelected = true
        this.setInputState()
        this.salaryOrHourlyError = null
    }

    contractTimeAllocationChange() {
        this.employeeAllocationError = null
        if (this.employeeAllocationFC.value != 4) {
            this.estimatedPercentTimeOnContractFC.setValue(100)
        }
        this.setInputState()
    }

    annualSalaryFC = new UntypedFormControl()
    annualSalaryError
    hourlyRateFC = new UntypedFormControl()
    hourlyRateError

    salaryHoursPerPayPeriodFC = new UntypedFormControl()
    salaryHoursPerPayPeriodError
    hourlyHoursPerPayPeriodFC = new UntypedFormControl()
    hourlyHoursPerPayPeriodError

    anticipateRaiseFC = new UntypedFormControl(false)

    newSalaryAmountFC = new UntypedFormControl()
    newSalaryAmountError

    newHourlyRateFC = new UntypedFormControl()
    newHourlyRateError
    salaryEstimatedDateOfRaiseFC = new UntypedFormControl()
    salaryEstimatedDateOfRaiseError
    hourlyEstimatedDateOfRaiseFC = new UntypedFormControl()
    hourlyEstimatedDateOfRaiseError

    anticipateBonusFC = new UntypedFormControl(false)

    estimatedBonusAmountFC = new UntypedFormControl()
    estimatedBonusAmountError

    estimatedBonusDateFC = new UntypedFormControl()
    estimatedBonusDateError

    salaryFCs = [
        this.salaryPayPeriodFC,
        this.annualSalaryFC,
        this.salaryHoursPerPayPeriodFC,
    ]

    hourlyFCs = [
        this.hourlyPayPeriodFC,
        this.hourlyRateFC,
        this.hourlyHoursPerPayPeriodFC,
    ]

    contractTermChoiceFC = new UntypedFormControl()
    contractTermChoiceOptions = [
        {
            name: "Employee will work full contract year (12 months)",
            id: 0,
        },
        {
            name: "Employee will work part of contract year (less than 12 months)",
            id: 1,
        }
    ]
    contractTermChoiceError

    termStartDateFC = new UntypedFormControl()
    termStartDateError

    termEndDateFC = new UntypedFormControl()
    termEndDateError

    employeeAllocationFC = new UntypedFormControl()
    employeeAllocationError

    //employeeExclusive: this.employeeAllocationFC.value != 4,
    //so, employee allocation is true (1) if id = 3, false (0) if 4

    employeeAllocationOptions = [
        {
            name: "Employee is allocated exclusively to the contract (100%)",
            id: 3,
        },
        {
            name: "Employee allocation is shared across multiple contracts (less than 100%)",
            id: 4,
        }
    ]

    estimatedPercentTimeOnContractFC = new UntypedFormControl()
    estimatedPercentTimeOnContractError

    amountChargedToContractFC = new UntypedFormControl()
    amountChargedToContractError
    stateOnlyFC = new UntypedFormControl()
    stateOnlyError
    stateMatchFederalMatchFC = new UntypedFormControl()
    stateMatchFederalMatchError
    stateOnlyStateFedCombined

    weekdaysSince(startDate: Date, endDate: Date) {
        if (startDate > endDate) {
            throw new Error("start after end")
        }
        //console.log("weekdays since", startDate, endDate)
        var iter = new Date(startDate)
        var iterDay
        var weekdayCount = 0
        while (iter < endDate) {
            iterDay = iter.getDay()
            if (iterDay != 0 && iterDay != 6) {
                ++weekdayCount
                //console.log("iter day inc", iterDay, iter)
            } else {
                //console.log("iter day uninc", iterDay, iter)
            }
            iter.setDate(iter.getDate() + 1)
        }
        return weekdayCount
    }

    //so.. for now, calculate the fiscal start as the july 1st before (or on) the raise date
    getFiscalStart(raiseDate): Date {
        var fiscalStart = new Date(raiseDate)
        fiscalStart.setMonth(6)
        fiscalStart.setDate(1)
        if (raiseDate < fiscalStart) {
            fiscalStart.setFullYear(fiscalStart.getFullYear() - 1)
        }
        return fiscalStart
    }

    salaryCalculatedAmount$
    initSalCal() {
        this.salaryCalculatedAmount$ = combineLatest(
            this.salaryPayPeriodFC.valueChanges.pipe(startWith(this.salaryPayPeriodFC.value)),
            this.salaryHoursPerPayPeriodFC.valueChanges.pipe(startWith(this.salaryHoursPerPayPeriodFC.value)),
            this.annualSalaryFC.valueChanges.pipe(startWith(this.annualSalaryFC.value)),
            this.anticipateRaiseFC.valueChanges.pipe(startWith(this.anticipateRaiseFC.value)),
            this.salaryEstimatedDateOfRaiseFC.valueChanges.pipe(startWith(this.salaryEstimatedDateOfRaiseFC.value)),
            this.newSalaryAmountFC.valueChanges.pipe(startWith(this.newSalaryAmountFC.value)),
            this.employeeAllocationFC.valueChanges.pipe(startWith(this.employeeAllocationFC.value)),
            this.estimatedPercentTimeOnContractFC.valueChanges.pipe(startWith(this.estimatedPercentTimeOnContractFC.value)),
            this.anticipateBonusFC.valueChanges.pipe(startWith(this.anticipateBonusFC.value)),
            this.estimatedBonusAmountFC.valueChanges.pipe(startWith(this.estimatedBonusAmountFC.value)),
        ).pipe(

            switchMap(([
                payPeriod,
                hoursPerPayPeriod,
                annualSalary,
                anticipateRaise,
                raiseDate,
                newSalaryAmount,
                employeeAllocation,
                estimatedPercentTimeOnContract,
                anticipateBonus,
                bonusAmount,
            ]) => {
                if (!payPeriod) {
                    return of({})
                }
                var preRaiseHourlyRate = annualSalary / (this.payPeriodsById[payPeriod].paychecks * hoursPerPayPeriod)
                var hoursPerDay = hoursPerPayPeriod * this.payPeriodsById[payPeriod].paychecks / 260 // this could change every year, was 260, changed to 261 -brock 8/17/22

                var _return: any = {
                    annualSalary: annualSalary,
                    numberOfPaychecks: this.payPeriodsById[payPeriod].paychecks,
                    hoursPerPayPeriod: hoursPerPayPeriod,
                    hourlyRate: preRaiseHourlyRate,
                    newAnnualSalary: newSalaryAmount,
                    hoursPerDay,

                }

                var subtotal = annualSalary
                if (anticipateRaise && raiseDate) {
                    raiseDate = new Date(raiseDate)

                    var fiscalStart = this.getFiscalStart(raiseDate)
                    var fiscalEnd = new Date(fiscalStart)
                    fiscalEnd.setFullYear(fiscalEnd.getFullYear() + 1)

                    _return.fiscalStart = fiscalStart
                    _return.fiscalEnd = fiscalEnd
                    _return.raiseDate = raiseDate


                    /*
                    if(this.dateOfHireFC.value) {
                        console.log(this.dateOfHireFC.value)
                        fiscalStart = this.dateOfHireFC.value
                    }
                    */

                    console.log(fiscalStart)
                    var preRaiseEnd = new Date(raiseDate)
                    preRaiseEnd.setDate(raiseDate.getDate() - 0)
                    var preRaiseDayCount = this.weekdaysSince(fiscalStart, preRaiseEnd) + 1
                    console.log(preRaiseDayCount)
                    //let postRaiseDayCount = fiscalStart + raiseDate - 261 + 1

                    let postRaiseDayCount = 261 - (this.weekdaysSince(fiscalStart, preRaiseEnd) + 1)

                    console.log(postRaiseDayCount)
                    var postRaiseHourlyRate = newSalaryAmount / (this.payPeriodsById[payPeriod].paychecks * hoursPerPayPeriod)
                    //console.log("pre", fiscalStart, preRaiseEnd, preRaiseDayCount)
                    //console.log("post", raiseDate, fiscalEnd, postRaiseDayCount)
                    console.log(_return)

                    _return.newHourlyRate = postRaiseHourlyRate
                    _return.preDayCount = preRaiseDayCount
                    _return.preRaiseAmount = preRaiseDayCount * preRaiseHourlyRate * hoursPerDay
                    _return.postDayCount = postRaiseDayCount
                    _return.postRaiseAmount = postRaiseDayCount * postRaiseHourlyRate * hoursPerDay

                    // console.log("prerate", preRaiseHourlyRate, "postrate", postRaiseHourlyRate)
                    subtotal = (preRaiseDayCount * preRaiseHourlyRate + postRaiseDayCount * postRaiseHourlyRate) * hoursPerDay
                }

                var percentOnContract = 100
                //employeeAllocation += 2//correct for value offset in employeeAllocationOptions

                if (employeeAllocation == '4') {
                    percentOnContract = estimatedPercentTimeOnContract
                }
                _return.estimatedPercentTimeOnContract = percentOnContract / 100

                var contractAmount = subtotal * percentOnContract / 100
                if (anticipateBonus && bonusAmount) {
                    _return.bonus = parseFloat(bonusAmount)
                    contractAmount += parseFloat(bonusAmount)
                }

                //console.log("initsal calculating", contractAmount)
                _return.calculatedAmount = Math.round(contractAmount)

                return of(_return)
            })
        )
    }
    hourlyCalculatedAmount$
    initHouCal() {
        this.hourlyCalculatedAmount$ = combineLatest(
            this.hourlyPayPeriodFC.valueChanges.pipe(startWith(this.hourlyPayPeriodFC.value)),
            this.hourlyHoursPerPayPeriodFC.valueChanges.pipe(startWith(this.hourlyHoursPerPayPeriodFC.value)),
            this.hourlyRateFC.valueChanges.pipe(startWith(this.hourlyRateFC.value)),
            this.anticipateRaiseFC.valueChanges.pipe(startWith(this.anticipateRaiseFC.value)),
            this.hourlyEstimatedDateOfRaiseFC.valueChanges.pipe(startWith(this.hourlyEstimatedDateOfRaiseFC.value)),
            this.newHourlyRateFC.valueChanges.pipe(startWith(this.newHourlyRateFC.value)),
            this.employeeAllocationFC.valueChanges.pipe(startWith(this.employeeAllocationFC.value)),
            this.estimatedPercentTimeOnContractFC.valueChanges.pipe(startWith(this.estimatedPercentTimeOnContractFC.value)),
            this.anticipateBonusFC.valueChanges.pipe(startWith(this.anticipateBonusFC.value)),
            this.estimatedBonusAmountFC.valueChanges.pipe(startWith(this.estimatedBonusAmountFC.value)),
        ).pipe(

            switchMap(([
                payPeriod,
                hoursPerPayPeriod,
                hourlyRate,
                anticipateRaise,
                raiseDate,
                newHourlyRate,
                employeeAllocation,
                estimatedPercentTimeOnContract,
                anticipateBonus,
                bonusAmount,
            ]) => {
                if (!payPeriod) {
                    return of({})
                }
                var hoursPerDay = hoursPerPayPeriod * this.payPeriodsById[payPeriod].paychecks / 260 // could change every year, was 260, changed to 261
                var _return: any = {
                    hourlyRate,
                    newHourlyRate,
                    numberOfPaychecks: this.payPeriodsById[payPeriod].paychecks,
                    hoursPerPayPeriod: hoursPerPayPeriod,
                    hoursPerDay,
                }
                var subtotal = hoursPerPayPeriod * this.payPeriodsById[payPeriod].paychecks * hourlyRate
                if (anticipateRaise && raiseDate) {
                    raiseDate = new Date(raiseDate)

                    var fiscalStart = this.getFiscalStart(raiseDate)
                    var fiscalEnd = new Date(fiscalStart)
                    fiscalEnd.setFullYear(fiscalEnd.getFullYear() + 1)

                    _return.fiscalStart = fiscalStart
                    _return.fiscalEnd = fiscalEnd
                    _return.raiseDate = raiseDate

                    var preRaiseEnd = new Date(raiseDate)
                    preRaiseEnd.setDate(raiseDate.getDate() - 0)
                    var preRaiseDayCount = this.weekdaysSince(fiscalStart, preRaiseEnd) + 1

                    console.log(_return)
                    let postRaiseDayCount = 261 - (this.weekdaysSince(fiscalStart, preRaiseEnd) + 1)
                    console.log(postRaiseDayCount)

                    //let postRaiseDayCount = fiscalStart + raiseDate - 261 + 1
                    //var postRaiseDayCount = this.weekdaysSince(raiseDate, fiscalEnd) - 0
                    var postRaiseHourlyRate = newHourlyRate
                    //console.log("pre", fiscalStart, preRaiseEnd, preRaiseDayCount)
                    //console.log("post", raiseDate, fiscalEnd, postRaiseDayCount)

                    _return.newHourlyRate = postRaiseHourlyRate
                    _return.preDayCount = preRaiseDayCount
                    _return.preRaiseAmount = preRaiseDayCount * hourlyRate * hoursPerDay
                    _return.postDayCount = postRaiseDayCount
                    _return.postRaiseAmount = postRaiseDayCount * postRaiseHourlyRate * hoursPerDay

                    console.log(_return.postRaiseAmount)

                    // console.log("prerate", preRaiseHourlyRate, "postrate", postRaiseHourlyRate)
                    subtotal = (preRaiseDayCount * hourlyRate + postRaiseDayCount * postRaiseHourlyRate) * hoursPerDay
                }

                var percentOnContract = 100
                if (employeeAllocation == '4') {
                    percentOnContract = estimatedPercentTimeOnContract
                }
                _return.estimatedPercentTimeOnContract = percentOnContract / 100
                var contractAmount = subtotal * percentOnContract / 100
                if (anticipateBonus && bonusAmount) {
                    _return.bonus = parseFloat(bonusAmount)
                    contractAmount += _return.bonus
                }

                console.log("inithour calculating", contractAmount)
                _return.calculatedAmount = Math.round(contractAmount)
                return of(_return)
            })
        )
    }
    setInputState() {
        //given the currently selected buttons and checkboxes, set the appropriate inputs as enabled or disabled

        this.disableFCs(this.hourlyFCs, !this.hourlySelected)
        this.disableFCs(this.salaryFCs, !this.salarySelected)

        if (!this.editable) {
            return
        }

        if (this.salarySelected && this.anticipateRaiseFC.value) {
            this.newSalaryAmountFC.enable()
            this.salaryEstimatedDateOfRaiseFC.enable()
        } else {
            this.newSalaryAmountFC.disable()
            this.salaryEstimatedDateOfRaiseFC.disable()
        }

        if (this.hourlySelected && this.anticipateRaiseFC.value) {
            this.newHourlyRateFC.enable()
            this.hourlyEstimatedDateOfRaiseFC.enable()
        } else {
            this.newHourlyRateFC.disable()
            this.hourlyEstimatedDateOfRaiseFC.disable()
        }

        if (this.anticipateBonusFC.value) {
            this.estimatedBonusAmountFC.enable()
            this.estimatedBonusDateFC.enable()
        } else {
            this.estimatedBonusAmountFC.disable()
            this.estimatedBonusDateFC.disable()
        }

        if (!this.contractTermChoiceFC.value) {
            this.termStartDateFC.disable()
            this.termEndDateFC.disable()
        } else {
            this.termStartDateFC.enable()
            this.termEndDateFC.enable()
        }
        if (this.employeeAllocationFC.value == 4) {
            this.estimatedPercentTimeOnContractFC.enable()
        } else {
            this.estimatedPercentTimeOnContractFC.disable()
        }
    }

    anticipateRaiseChange(e) {
        this.setInputState()
    }

    anticipateBonusChange(e) {
        this.setInputState()
    }

    personnelTypeChange() {
        this.fringeSetup()
        this.personnelTypeError = null
    }

    userFlagChange() {
        //console.log("user flag change", this.userFlagFC.value, this.accountUuid, this.data.entryData[0].finance_uuid)
        this.budgetService.updateUserFlag(this.personnelEntryUuid, this.userFlagFC.value).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("User flag updated.", null, {
                    duration: 4000,
                });
            } else {
                this._snackBar.open("Error occurred while updating user flag.", null, {
                    duration: 4000,
                });
            }
        })
    }

    //NOTE: isNumber and isMoney methods assume american-style money formatting, using , as the thousands separator and . as the decimal.
    //may need a locale update to potentially flip these if we ever market to europe, etc.

    //assumes n is a string
    isNumber(n) {
        if (n == null) {
            return false
        }
        if (typeof n == "number") {
            return true
        }
        n = n.replace(/,/g, '')
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    //assumes n is a string
    isMoney(n) {
        if (!this.isNumber(n)) {
            return false
        }
        //ensure has zero to two decimal places
        // this.toString().split(".")[1].length || 0
        var parts = n.toString().split(".")
        return parts.length < 2 || parts[1].length <= 2
    }

    isPercent(n) {
        return this.isNumber(n) && n >= 0 && n <= 100
    }

    clearErrors() {
        this.salaryPayPeriodError = null
        this.hourlyPayPeriodError = null
        this.employeeNameError = null
        this.jobDescriptionError = null
        this.dateOfHireError = null
        this.dateOfTerminationError = null
        this.programError = null
        this.salaryOrHourlyError = null
        this.annualSalaryError = null
        this.hourlyRateError = null
        this.newSalaryAmountError = null
        this.newHourlyRateError = null
        this.salaryEstimatedDateOfRaiseError = null
        this.hourlyEstimatedDateOfRaiseError = null
        this.estimatedBonusAmountError = null
        this.estimatedBonusDateError = null
        this.termStartDateError = null
        this.termEndDateError = null
        this.employeeAllocationError = null
        this.estimatedPercentTimeOnContractError = null
        this.salaryPayPeriodError = null
        this.hourlyPayPeriodError = null
        this.salaryHoursPerPayPeriodError = null
        this.hourlyHoursPerPayPeriodError = null
    }

    moneyErrorString = " must be a number containing at most two places to the right of the decimal"
    hoursInOneWeek = 7 * 24
    hoursInOneYear = 365.25 * 24

    validate(salaryCalculatedAmount, hourlyCalculatedAmount) {
        this.clearErrors()
        var errorEncountered = false

        if (!this.employeeNameFC.value) {
            this.employeeNameError = "Please specify employee title"
            errorEncountered = true
        }

        if (!this.jobDescriptionFC.value) {
            this.jobDescriptionError = "Please specify an employee name"
            errorEncountered = true
        }

        if (!this.dateOfHireFC.value) {
            this.dateOfHireError = "Please specify a date of hire"
            errorEncountered = true
        }

        if (!this.personnelTypeFC.value) {
            this.personnelTypeError = "Please specify a personnel type"
            errorEncountered = true
        }

        if (!this.salarySelected && !this.hourlySelected) {
            this.salaryOrHourlyError = "Please select salary or hourly"
            errorEncountered = true
        }
        if (this.salarySelected) {
            if (!this.salaryPayPeriodFC.value) {
                this.salaryPayPeriodError = "Please select a value"
                errorEncountered = true
            }

            if (!this.annualSalaryFC.value) {
                this.annualSalaryError = "Please specify an annual salary"
                errorEncountered = true
            } else if (!this.isMoney(this.annualSalaryFC.value)) {
                this.annualSalaryError = "Annual salary" + this.moneyErrorString
                errorEncountered = true
            }

            if (!this.salaryHoursPerPayPeriodFC.value) {
                this.salaryHoursPerPayPeriodError = "Please specify a number"
                errorEncountered = true
            } else if (!this.isNumber(this.salaryHoursPerPayPeriodFC.value)) {
                this.salaryHoursPerPayPeriodError = "Hours per Pay Period must be a number"
                errorEncountered = true
            }

            if (this.anticipateRaiseFC.value) {
                if (!this.newSalaryAmountFC.value) {
                    this.newSalaryAmountError = "Please specify a new salary amount"
                    errorEncountered = true
                } else if (!this.isMoney(this.newSalaryAmountFC.value)) {
                    this.newSalaryAmountError = "New salary amount" + this.moneyErrorString
                    errorEncountered = true
                }

                if (!this.salaryEstimatedDateOfRaiseFC.value) {
                    this.salaryEstimatedDateOfRaiseError = "Please specify an estimated date of raise"
                    errorEncountered = true
                }
            }
        }
        if (this.hourlySelected) {
            if (!this.hourlyPayPeriodFC.value) {
                this.hourlyPayPeriodError = "Please select a value"
                errorEncountered = true
            }

            if (!this.hourlyRateFC.value) {
                this.hourlyRateError = "Please specify an hourly rate"
                errorEncountered = true
            } else if (!this.isMoney(this.hourlyRateFC.value)) {
                this.hourlyRateError = "Hourly rate" + this.moneyErrorString
                errorEncountered = true
            }

            if (!this.hourlyHoursPerPayPeriodFC.value) {
                this.hourlyHoursPerPayPeriodError = "Please specify a number"
                errorEncountered = true
            } else if (!this.isNumber(this.hourlyHoursPerPayPeriodFC.value)) {
                this.hourlyHoursPerPayPeriodError = "Hours per Pay Period must be a number"
                errorEncountered = true
            }

            if (this.anticipateRaiseFC.value) {
                if (!this.newHourlyRateFC.value) {
                    this.newHourlyRateError = "Please specify a new hourly rate"
                    errorEncountered = true
                } else if (!this.isMoney(this.newHourlyRateFC.value)) {
                    this.newSalaryAmountError = "New hourly rate" + this.moneyErrorString
                    errorEncountered = true
                }

                if (!this.hourlyEstimatedDateOfRaiseFC.value) {
                    this.hourlyEstimatedDateOfRaiseError = "Please specify an estimated date of raise"
                    errorEncountered = true
                }
            }
        }

        if (this.anticipateBonusFC.value) {
            if (!this.estimatedBonusAmountFC.value) {
                this.estimatedBonusAmountError = "Please specify an estimated bonus amount"
                errorEncountered = true
            } else if (!this.isMoney(this.estimatedBonusAmountFC.value)) {
                this.estimatedBonusAmountError = "Estimated bonus amount" + this.moneyErrorString
                errorEncountered = true
            }

            //keeping this code as per HSNY-1261
            // if (!this.estimatedBonusDateFC.value) {
            //     this.estimatedBonusDateError = "Please specify an estimated bonus date"
            //     errorEncountered = true
            // }
        }

        //keeping the code as per HSNY-1259
        // if (this.contractTermChoiceFC.value == null) {
        //     this.contractTermChoiceError = "Please select the employee contract term full or partial"
        //     errorEncountered = true
        // }
        // if (this.contractTermChoiceFC.value == 1) {
        //     if (!this.termStartDateFC.value) {
        //         this.termStartDateError = "Please specify a value"
        //         errorEncountered = true
        //     }
        //     if (!this.termEndDateFC.value) {
        //         this.termEndDateError = "Please specify a value"
        //         errorEncountered = true
        //     }
        // }

        if (this.employeeAllocationFC.value == null) {
            this.employeeAllocationError = "Please select a value"
            errorEncountered = true
        }
        if (this.employeeAllocationFC.value == 4) {
            if (!this.estimatedPercentTimeOnContractFC.value) {
                this.estimatedPercentTimeOnContractError = "Please specify a value"
                errorEncountered = true
            } else if (!this.isPercent(this.estimatedPercentTimeOnContractFC.value)) {
                this.estimatedPercentTimeOnContractError = "Value must be a valid number between 0 and 100 (inclusive)"
                errorEncountered = true
            }
        }

        //benefits validation
        //for each benefit, ensure values match total
        for (var i = 0; i < this.fixedFringeTableEntries.length; ++i) {
            if (this.fixedFringeTableEntries[i].type == this.fringeTableEntryType.entry) {
                this.fixedFringeTableEntries[i + 1].error = null

                if (!this.doesBenefitSum(this.fixedFringeTableEntries[i].entry)) {
                    errorEncountered = true
                    this.fixedFringeTableEntries[i + 1].error = "Please ensure the totals match"
                }

                if (!this.isBenefitAmountLessThanCalculatedFixed(this.fixedFringeTableEntries[i].entry, salaryCalculatedAmount, hourlyCalculatedAmount)) {
                    errorEncountered = true
                    this.fixedFringeTableEntries[i + 1].error = "Amount Charged to Contract must be less than or equal to Calculated Amount"
                }
            }
        }

        for (var i = 0; i < this.controllableFringeTableEntries.length; ++i) {
            if (this.controllableFringeTableEntries[i].type == this.fringeTableEntryType.entry) {
                this.controllableFringeTableEntries[i + 1].error = null

                if (!this.doesBenefitSum(this.controllableFringeTableEntries[i].entry)) {
                    errorEncountered = true
                    this.controllableFringeTableEntries[i + 1].error = "Please ensure the totals match"
                }

                if (!this.isBenefitAmountLessThanCalculatedControllable(this.controllableFringeTableEntries[i].entry)) {
                    errorEncountered = true
                    this.controllableFringeTableEntries[i + 1].error = "Amount Charged to Contract must be less than or equal to Calculated Amount"
                }
                if(isNaN(this.controllableFringeTableEntries[i].entry.fringeAmountFC.value)) {
                    errorEncountered = true
                    this.controllableFringeTableEntries[i + 1].error = "Annual Premium must contain a numerical value"
                }

                if(this.controllableFringeTableEntries[i].entry.fringeAmountFC.value === '') {
                    errorEncountered = true
                    this.controllableFringeTableEntries[i + 1].error = "Annual premium must not be an empty value"
                }
            }
        }

        if (this.salarySelected) {
            if (salaryCalculatedAmount.calculatedAmount < +this.amountChargedToContractFC.value) {
                errorEncountered = true
                this.amountChargedToContractError = "Amount Charged to Contract must be less than or equal to Calculated Amount."
            }
        }
        if (this.hourlySelected) {
            if (hourlyCalculatedAmount.calculatedAmount < +this.amountChargedToContractFC.value) {
                errorEncountered = true
                this.amountChargedToContractError = "Amount Charged to Contract must be less than or equal to Calculated Amount."
            }
        }

        this.stateOnlyStateFedCombined = +this.stateOnlyFC.value + +this.stateMatchFederalMatchFC.value

        if (this.stateOnlyFC.value) {
            if (this.stateOnlyStateFedCombined != this.amountChargedToContractFC.value) {
                errorEncountered = true
                this.stateOnlyError = "State only plus state match/federal match must be equal to amount charged to contract."
            }
        }
        
        if (this.stateMatchFederalMatchFC.value) {
            if (this.stateOnlyStateFedCombined != this.amountChargedToContractFC.value) {
                errorEncountered = true
                this.stateMatchFederalMatchError = "State match/Federal match plus state only must be equal to amount charged to contract."
            }
        }
        
        return errorEncountered
    }

    doesBenefitSum(entry) {
        if (!entry.unitPriceFC.value && !entry.federalFC.value && !entry.stateFC.value) {
            return true
        }
        return parseFloat(entry.unitPriceFC.value) == parseFloat(entry.federalFC.value) + parseFloat(entry.stateFC.value)
    }

    isBenefitAmountLessThanCalculatedFixed(entry, salaryCalculatedAmount, hourlyCalculatedAmount) {
        var val
        if (this.salarySelected) {
            val = salaryCalculatedAmount.calculatedAmount
        } else {
            val = hourlyCalculatedAmount.calculatedAmount
        }
        val *= parseFloat(entry.percentAllocationFC.value) / 100
        val = Math.round(val)
        return parseFloat(entry.unitPriceFC.value) <= val
    }

    isBenefitAmountLessThanCalculatedControllable(entry) {
        var val = entry.fringeAmountFC.value
        val *= parseFloat(entry.percentAllocationFC.value) / 100
        val = Math.round(val)
        return parseFloat(entry.unitPriceFC.value) <= val
    }

    save(salaryCalculatedAmount, hourlyCalculatedAmount) {
        this.saveError = null
        if (this.validate(salaryCalculatedAmount, hourlyCalculatedAmount)) {
            this.saveError = "Please address the errors highlighted above"
            return
        }

        var benefitsToSave = []
        for (var i = 0; i < this.fixedBenefitsEntries.length; ++i) {
            benefitsToSave.push({
                account_uuid: this.fixedBenefitsEntries[i].account_uuid,
                percentAllocation: this.fixedBenefitsEntries[i].percentAllocationFC.value,
                state: this.fixedBenefitsEntries[i].stateFC.value,
                federal: this.fixedBenefitsEntries[i].federalFC.value,
                fringeAmount: this.fixedBenefitsEntries[i].fringeAmountFC.value,
                unitPrice: this.fixedBenefitsEntries[i].unitPriceFC.value,
                agencyComment: this.fixedBenefitsEntries[i].agencyCommentFC.value,
            })
        }

        for (var i = 0; i < this.controllableBenefitsEntries.length; ++i) {
            benefitsToSave.push({
                account_uuid: this.controllableBenefitsEntries[i].account_uuid,
                percentAllocation: this.controllableBenefitsEntries[i].percentAllocationFC.value,
                state: this.controllableBenefitsEntries[i].stateFC.value,
                federal: this.controllableBenefitsEntries[i].federalFC.value,
                fringeAmount: this.controllableBenefitsEntries[i].fringeAmountFC.value,
                unitPrice: this.controllableBenefitsEntries[i].unitPriceFC.value,
                agencyComment: this.controllableBenefitsEntries[i].agencyCommentFC.value,
            })
        }

        var payPeriodValue
        if (this.salarySelected) {
            payPeriodValue = this.salaryPayPeriodFC.value
        } else {
            payPeriodValue = this.hourlyPayPeriodFC.value
        }

        var newEntryData = {
            master_uuid: this.masterUuid,
            submission_id: this.submissionUuid,
            name: this.employeeNameFC.value,
            jobDescription: this.jobDescriptionFC.value,
            dateOfHire: this.dateOfHireFC.value,
            dateOfTermination: this.dateOfTerminationFC.value,
            programSBP: this.programSBPFC.value,
            programSFSP: this.programSFSPFC.value,
            programSNAP: this.programSNAPFC.value,
            salaried: this.salarySelected,
            annualSalary: this.annualSalaryFC.value,
            hourlyRate: this.hourlyRateFC.value,
            anticipateRaise: this.anticipateRaiseFC.value,
            newSalaryAmount: this.newSalaryAmountFC.value,
            estimatedDateOfRaise: this.salarySelected ? this.salaryEstimatedDateOfRaiseFC.value : this.hourlyEstimatedDateOfRaiseFC.value,
            newHourlyRate: this.newHourlyRateFC.value,
            anticipateBonus: this.anticipateBonusFC.value,
            estimatedBonusAmount: this.estimatedBonusAmountFC.value,
            estimatedBonusDate: this.estimatedBonusDateFC.value,
            isFullTerm: this.contractTermChoiceFC.value == 0,
            termEnd: this.termEndDateFC.value,
            termStart: this.termStartDateFC.value,
            employeeExclusive: this.employeeAllocationFC.value != 4,
            estimatedPercentTimeOnContract: this.employeeAllocationFC.value != 4 ? 100 : this.estimatedPercentTimeOnContractFC.value,
            benefits: benefitsToSave,
            payPeriod: payPeriodValue,
            personnelType: this.personnelTypeFC.value,
            hoursPerPayPeriod: this.salarySelected ? this.salaryHoursPerPayPeriodFC.value : this.hourlyHoursPerPayPeriodFC.value,
            federal: this.stateMatchFederalMatchFC.value,
            state: this.stateOnlyFC.value,
            unitPrice: this.amountChargedToContractFC.value,
        }

        if (this.entryData) {
            this.budgetService.updatePersonnel(newEntryData).subscribe((response) => {
                if (!response.success) {
                    this.saveError = response.message
                    return
                }
                this.snackRoute.snack("Account updated.")
                this.router.navigate(["/budget-summary"], { queryParams: { submissionUuid: this.submissionUuid } })
            })
        } else {
            this.budgetService.addPersonnel(newEntryData).subscribe((response) => {
                if (!response.success) {
                    this.saveError = response.message
                    return
                }
                this.snackRoute.snack("Account added.")
                this.router.navigate(["/budget-summary"], { queryParams: { submissionUuid: this.submissionUuid } })
            })
        }
    }

    cancel() {
        this.snackRoute.snack("Action cancelled.")
        this.router.navigate(["/budget-summary"], { queryParams: { submissionUuid: this.submissionUuid } })
    }

    ok() {
        this.router.navigate(["/submission-review"], { queryParams: { submissionUuid: this.submissionUuid } })
    }

    delete() {
        const dialogRef = this.dialog.open(BudgetPersonnelDeleteConfirmationDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                //this.fetchDataPromise()
                this.budgetService.deletePersonnel(this.masterUuid).subscribe((response) => {
                    if (!response.success) {
                        console.log("delete error", response)
                        return
                    }
                    if (response.success) {
                        this._snackBar.open("Deleted", null, {
                            duration: 4000,
                        });
                        this.router.navigate(["/budget-summary"], { queryParams: { submissionUuid: this.submissionUuid } })
                    }
                })
            } else {
            }
        });
    }
}

@Component({
    selector: 'budget-personnel-delete-confirmation-dialog',
    templateUrl: './budget-personnel-delete-confirmation-dialog.html',
})
export class BudgetPersonnelDeleteConfirmationDialog {
    faExclamationTriangle = faExclamationTriangle
    constructor(
        public dialogRef: MatDialogRef<BudgetPersonnelDeleteConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackRoute: SnackRouteService,
    ) { }
    faTimes = faTimes
    faBan = faBan
    faMinus = faMinus

    cancel() {
        this.snackRoute.snack("Action cancelled.")
        this.dialogRef.close();
    }

    delete() {
        this.dialogRef.close({});
    }
}
