<div *ngIf="initialized" class="container-fluid">

    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
            Program Question Administration
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>

    <div class="row" style="margin-left: 125px; margin-top: 5px; margin-bottom: 5px;">
        <button
        class="reloadButton"
        (click)="reloadTestProgram()">
         Reload Test Program
        </button>
    </div>

    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <div class="row py-1">
                <div class="col-6">
                    Step
                    <app-input-enum label="Select Step" [fc]="stepSelectFC" [enumValuesNameAndId]="stepSelectValues"
                        [style]="'none'" [change]="changeStepSelection.bind(this)">
                    </app-input-enum>
                </div>
                <div class="col-6" align="right">
                    Program
                    <app-input-enum label="Program" [fc]="programFC" [enumValuesNameAndId]="programs" [style]="'none'"
                        [change]="changeProgram.bind(this)">
                    </app-input-enum>
                </div>
            </div>

            <div class="row py-1">
                <div class="col-6">
                    <span class="bold">Step: {{stepSelectDisplay}}</span><a class="ml-2"
                        routerLink="/edit-question-step" [queryParams]="{ stepId: this.stepSelectFC.value }">[edit]</a>
                </div>
                <div class="col-6" align="right">
                    <app-button3 class="mx-1" label="Add Group" [clickFunction]="addGroupClick.bind(this)"
                        [icon]="faPlus" [small]="true"></app-button3>
                </div>
            </div>

            <span *ngFor="let group of groupData">
                <div class="row py-1" style="background-color: lightgrey;">
                    <div class="col-2 bold">
                        Group {{group.group_number}}
                    </div>
                    <div class="col-6">
                        {{group.group_name}}<a class="ml-2" routerLink="/edit-question-group"
                            [queryParams]="{ groupId: group.group_uuid }">[edit]</a>
                    </div>
                    <div class="col-4">
                        <app-button3 class="mx-1" label="Add Question"
                            [clickFunction]="addQuestionClick.bind(this, group.group_uuid)" [icon]="faPlus"
                            [small]="true"></app-button3>
                    </div>
                </div>
                <div *ngFor="let child of group.children" class="row py-1">
                    <div class="col-2">
                        {{child.question_number}}
                    </div>
                    <div class="col-10">
                        <a class="ml-2" routerLink="/edit-question"
                            [queryParams]="{ questionId: child.question_uuid }">{{child.question_name}}</a>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>