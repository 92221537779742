import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SessionService } from '../session.service';
import { iUserClaims } from '../model/user-claim.model';
import { OutreachService } from '../services/outreach.service';
import { iCounty } from '../model/county.model';
import { iCampaign } from '../model/campaign.model';

@Component({
  selector: 'app-outreach-request',
  templateUrl: './outreach-request.component.html',
  styleUrl: './outreach-request.component.css'
})
export class OutreachRequestComponent implements OnInit
{
  claims: iUserClaims;
  fiscalYearId: string;
  sequence: string;
  countyDropdownOptions: iCounty[];
  campaignDropdownOptions: iCampaign[];
  newOutreachDialogVisable: boolean = false;
  outreachForm: FormGroup;

  constructor
  (
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private sessionService: SessionService,
    private outreachService: OutreachService
  ) {}

  ngOnInit() 
  {
    this.claims = this.sessionService.getClaims();
    this.fiscalYearId = this.sessionService.getFiscalYear();
    this.initOutreachForm();
  
    this.outreachService.getCountyDropdown(this.claims.user_id, this.claims.organization_uuid).subscribe({
      next: (counties: { id: string, name: string }[]) => {
        this.countyDropdownOptions = counties;
      },
      error: (error) => {
        console.error('Error while getting county dropdown', error);
      }
    });

    this.outreachForm.get('county').valueChanges.subscribe({
      next: (jurisdictionId) => {
        this.outreachService.getCampaignDropdown(this.claims.organization_uuid, jurisdictionId, this.fiscalYearId).subscribe({
          next: (campaigns) => {
            console.log(campaigns)
            this.campaignDropdownOptions = campaigns;
          },
          error: (error) => {
            console.error('Error while getting campaign dropdown', error);
          }
        });
      }
    });

    this.outreachForm.get('outreach').valueChanges.subscribe({
      next: (value) => {
        const selectedCampaign = this.campaignDropdownOptions.find(campaign => campaign.fiscalUuid === value);
    
        if (selectedCampaign) 
        {
          this.sequence = selectedCampaign.nextSequence
          this.outreachForm.patchValue({
            slogan: selectedCampaign.name,
            requestNumber: selectedCampaign.nextReferenceNumber
          },
          { emitEvent: false });
        }
      }
    });
  }

  showNewOutreachDialog()
  {
    this.newOutreachDialogVisable = true;
  }

  hideNewOutreachDialog()
  {
    this.newOutreachDialogVisable = false;
  }

  createNewOutreach()
  {
    if (this.outreachForm.invalid)
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please fill out all required fields.' });
      return;
    }

    const formData = this.outreachForm.value;

    this.outreachService.createNewOutreach
    (
      this.claims.user_id,
      this.claims.organization_uuid,
      formData.outreach,
      formData.slogan,
      formData.county,
      formData.requestNumber,
      this.sequence
    ).subscribe({
      next: (response) => {
        if (response.success)
        {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'New outreach request created successfully!' });
          this.hideNewOutreachDialog();
          this.resetOutreachForm();
          window.location.reload();
        } else
        {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: response.message || 'Failed to create outreach request.' });
        }
      },
      error: (error) => {
        console.error('Error while creating outreach request:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred while creating the outreach request.' });
      }
    });
  }

  initOutreachForm()
  {
    this.outreachForm = this.fb.group({
      county: ['', [Validators.required]],
      outreach: ['', [Validators.required]],
      slogan: ['', [Validators.required]],
      requestNumber: ['', [Validators.required]]
    })
  }

  resetOutreachForm()
  {
    this.outreachForm.reset({
      county: '',
      outreach: '',
      slogan: '',
      requestNumber: ''
    }, { emitEvent: false });
  }
  
  
}
