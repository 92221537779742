import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { iTrendReportOrganization } from './model/trend-report-organization.model';
import { iTrendReportDetail } from './model/trend-report-detail.model';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class BudgetService {

    constructor(
        private http: HttpClient,
    ) { }

    getReportAllocation(submissionUuid: string): Observable<any> {
        return this.http.get<any>("api/budgetReportAllocation/" + submissionUuid)
    }

    getReportHeader(submissionUuid: string): Observable<any> {
        return this.http.get<any>("api/budgetReportHeader/" + submissionUuid)
    }

    getReport(submissionUuid: string): Observable<any> {
        return this.http.get<any>("api/budgetReport/" + submissionUuid)
    }

    getSummaryHeader(submissionUuid): Observable<any> {
        return this.http.get<any>("api/budgetSummaryHeader/" + submissionUuid)
    }
    
    getSummary(submissionUuid: string, fiscalUuid: string): Observable<any> {
        return this.http.get<any>("api/budgetSummary/" + submissionUuid + "/" + fiscalUuid)
    }


    getVoucherSummary(submissionUuid: string, fiscalYearId?: string): Observable<any> {
        if(fiscalYearId) {
            console.log('has fiscal year id', fiscalYearId)
            return this.http.get<any>("api/voucherSummary/" + submissionUuid + "/" + fiscalYearId)
        } else {
            console.log('does not have fiscal year id', fiscalYearId)
            return this.http.get<any>("api/voucherSummary/" + submissionUuid)
        }
    }

    getAllocation(submissionUuid): Observable<any> {
        return this.http.get<any>("api/budgetAllocation/" + submissionUuid)
    }

    getVoucherAllocation(submissionUuid): Observable<any> {
        return this.http.get<any>("api/voucherAllocation/" + submissionUuid)
    }

    getAddAccountOptions(submissionUuid): Observable<any> {
        return this.http.get<any>("api/budgetAddAccountOptions/" + submissionUuid)
    }

    addAccount(
        submission_id,
        account_uuid,
        name,
        total_square_footage,
        narrative,
        snap,
        child_nutrition_snap,
        total,
        price_per_square_foot,
        percent_allocated,
        allocation_method,
        agency_comment,
    ): Observable<any> {
        return this.http.put<any>("api/budgetAddAccount", {
            submission_id,
            account_uuid,
            name,
            total_square_footage,
            narrative,
            snap,
            child_nutrition_snap,
            total,
            price_per_square_foot,
            percent_allocated,
            allocation_method,
            agency_comment,
        })
    }

    updateAccount(
        uuid,
        name,
        total_square_footage,
        narrative,
        snap,
        child_nutrition_snap,
        total,
        price_per_square_foot,
        percent_allocated,
        allocation_method,
        agency_comment,
    ): Observable<any> {
        return this.http.post<any>("api/budgetUpdateAccount", {
            uuid,
            name,
            total_square_footage,
            narrative,
            snap,
            child_nutrition_snap,
            total,
            price_per_square_foot,
            percent_allocated,
            allocation_method,
            agency_comment,
        })
    }

    updateVoucherAccount(
        uuid,
        name,
        total_square_footage,
        narrative,
        snap,
        child_nutrition_snap,
        total,
        price_per_square_foot,
        percent_allocated,
        allocation_method,
        agency_comment,
    ): Observable<any> {
        return this.http.post<any>("api/voucherUpdateAccount", {
            uuid,
            name,
            total_square_footage,
            narrative,
            snap,
            child_nutrition_snap,
            total,
            price_per_square_foot,
            percent_allocated,
            allocation_method,
            agency_comment,
        })
    }

    getPayPeriods(): Observable<any> {
        return this.http.get<any>("api/budgetPayPeriods/")
    }

    getBenefits(): Observable<any> {
        return this.http.get<any>("api/budgetBenefits")
    }

    addPersonnelProcedure(submissionUuid: string, personnelType: string) {
        return this.http.put<any>("api/addPersonnelProcedure", {submissionUuid: submissionUuid, personnelType: personnelType})
    }

    addPersonnel(personnel): Observable<any> {
        return this.http.put<any>("api/budgetAddPersonnel", personnel)
    }

    updatePersonnel(personnel): Observable<any> {
        return this.http.post<any>("api/budgetUpdatePersonnel", personnel)
    }

    getAccount(financeUuid): Observable<any> {
        return this.http.get<any>("api/budgetAccount/" + financeUuid)
    }

    getPersonnel(financeUuid): Observable<any> {
        return this.http.get<any>("api/budgetPersonnel/" + financeUuid)
    }

    deleteAccount(financeUuid): Observable<any> {
        return this.http.delete<any>("api/budgetAccount/" + financeUuid)
    }

    deletePersonnel(financeUuid): Observable<any> {
        return this.http.delete<any>("api/budgetPersonnel/" + financeUuid)
    }

    getAllocationMethodOptions(accountUuid): Observable<any> {
        return this.http.get<any>("api/budgetAllocationMethodOptions/" + accountUuid)
    }

    getAdministrativeAllocated(submissionUuid): Observable<any> {
        return this.http.get<any>("api/budgetAdministrativeAllocated/" + submissionUuid)
    }

    amendBudget(submissionUuid): Observable<any> {
        return this.http.put<any>("api/budgetAmend/" + submissionUuid, {})
    }

    updateUserFlag(financeUuid, userFlag): Observable<any> {
        return this.http.post<any>("api/budgetUpdateUserFlag/", {financeUuid, userFlag})
    }

    /* voucher service calls */

    addVoucherLine(account_uuid: string, accountType: string): Observable<any> {
        return this.http.put<any>("api/addVoucherLine/" + account_uuid + "/" + accountType, {})
    }

    createVoucher(budget_id: string, submission_uuid: string): Observable<any> {
        return this.http.post<any>("api/createVoucher/", {budget_id, submission_uuid})
    }

    addVoucherOutreach(submissionUuid: string, accountUuid: string): Observable<any> {
        return this.http.post<any>("api/addVoucherOutreach/", {submissionUuid, accountUuid})
    }

    updateSubmissionStatus(submissionUuid: string, statusId: string): Observable<any> {
        return this.http.post<any>("api/updateSubmissionStatus/", {submissionUuid, statusId})
    }
}
