import { Component, OnInit } from '@angular/core';
import { AgencyService } from '../agency.service';
import { UntypedFormControl } from '@angular/forms';
import { DocumentMasterService } from '../document-master.service';
import { faFile, faFileAlt, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
    selector: 'app-document-master-listing',
    templateUrl: './document-master-listing.component.html',
})
export class DocumentMasterListingComponent implements OnInit {
    initialized = false
    faFile = faFile
    programs
    programFC = new UntypedFormControl()
    listing
    faPlus = faPlus
    faTimes = faTimes

    constructor(
        private agencyService: AgencyService,
        private documentMasterService: DocumentMasterService,
        private router: Router,
    ) { }

    getListingPromise() {
        return new Promise((resolve, reject) => {
            this.documentMasterService.getDocumentMasterListing2(this.programFC.value).subscribe((response) => {
                if (response.success) {
                    this.listing = response.result
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        this.agencyService.getPrograms().subscribe((response) => {
            if (response.success) {
                this.programs = response.result
            }
            if (this.programs && this.programs.length > 0) {
                this.programFC.setValue(this.programs[0].id)
            }
            this.getListingPromise().then(() => {
                this.initialized = true
            })
        })
    }

    changeProgram() {
        this.getListingPromise()
    }

    add() {
        this.router.navigate(["/document-master"])
    }
}
