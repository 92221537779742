import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, map, mergeMap, pairwise, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { faBan, faExclamationTriangle, faMinus, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackRouteService } from '../snack-route.service';
import { v4 as uuid } from 'uuid';
import { Subject } from 'rxjs';

interface CachedEntry {
    columns: { [key: string]: Array<{ id: string, name: string }> };
    entries: { [key: string]: any };
}

@Component({
    selector: 'app-crud-observabled',
    templateUrl: './crud-observabled.component.html',
})
export class CrudObservabledComponent implements OnInit {
    initialized = false
    localStorageTypeName = "hsny_crud_type"
    localStorageSortFieldName = "hsny_crud_sort_field"
    localStorageSortIsDescending = "hsny_crud_sort_is_descending"
    faPlus = faPlus
    typeFC = new UntypedFormControl(localStorage.getItem(this.localStorageTypeName))
    saveError
    entriesPerPageOptions = [
        { id: 10, name: "10" },
        { id: 25, name: "25" },
        { id: 50, name: "50" },
        { id: 100, name: "100" },
    ]
    entriesPerPageFC = new UntypedFormControl(10)

    setPage(page) {
        this.currentPage$.next(page)
    }

    currentPage$: Subject<any> = new BehaviorSubject<any>(1)
    sortField$: BehaviorSubject<any> = new BehaviorSubject<any>(localStorage.getItem(this.localStorageSortFieldName))
    sortIsDescending$: BehaviorSubject<any> = new BehaviorSubject<any>(localStorage.getItem(this.localStorageSortIsDescending))

    types$: Observable<any> = this.crudService.getSchema().pipe(shareReplay(1))
    typesEnum$: Observable<any> = this.types$.pipe(
        map((typesResult) => {
            if (typesResult.noKey) {
                for (var i = 0; i < typesResult.noKey.length; ++i) {
                    this.noKey[typesResult.noKey[i]] = true
                }
            }

            var types = typesResult.result
            var typeList = []
            for (var key in types) {
                if (types.hasOwnProperty(key)) {
                    typeList.push({ name: key.replace(/_/g, " "), id: key })
                    // for (var i = 0; i < types[key].definition.length; ++i) {
                    //     types[key].definition[i].createFC = new FormControl()
                    // }
                }
            }
            typeList.sort(function (a, b) {
                if (a.id < b.id) {
                    return -1;
                }
                if (a.id > b.id) {
                    return 1;
                }
                return 0;
            });
            return typeList
        })
    )

    createCacheEntryColumnList(cachedEntry: CachedEntry, columnName: string) {
        if (!cachedEntry.columns[columnName]) {
            let columnValuesList: Array<{ id: string, name: string }> = []
            for (var i = 0; i < cachedEntry.entries.length; ++i) {
                columnValuesList.push({
                    id: cachedEntry.entries[i][columnName],
                    name: cachedEntry.entries[i][columnName],
                })
            }
            cachedEntry.columns[columnName] = columnValuesList
        }
    }

    referencedTypeCache: { [key: string]: CachedEntry } = {}

    typeChange$: Observable<any> = combineLatest(
        this.types$,
        this.typeFC.valueChanges.pipe(
            startWith(localStorage.getItem(this.localStorageTypeName)),
            tap((typeName) => {
                localStorage.setItem(this.localStorageTypeName, typeName)
            })
        )
    ).pipe(switchMap(([typesResponse, typeName]) => {
        this.sortField$.next(typesResponse.result[typeName].definition[0].Field)
        this.sortIsDescending$.next(false)

        var referencedTypeNames = {}
        var columns = typesResponse.result[typeName].definition
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].referenced_table_name) {
                referencedTypeNames[columns[i].referenced_table_name] = true
            }
        }
        var observables = []
        for (const [key, value] of Object.entries(referencedTypeNames)) {
            if (!this.referencedTypeCache[key]) {
                observables.push(this.crudService.put({ type: key }))
            }
        }
        return combineLatest(of(typesResponse), of(typeName), ...observables)
    }), map(([typesResponse, typeName, ...referencedTypeResponses]: any) => {
        //first, cache all types in the responses
        referencedTypeResponses.forEach((referencedTypeResponse) => {
            if (!referencedTypeResponse.success) {
                throw new Error(referencedTypeResponse)
            }
            this.referencedTypeCache[referencedTypeResponse.type] = {
                entries: referencedTypeResponse.result,
                columns: {},
            }
        })
        //just check the column has been created
        var columns = typesResponse.result[typeName].definition
        for (var i = 0; i < columns.length; ++i) {
            if (columns[i].referenced_table_name) {
                this.createCacheEntryColumnList(
                    this.referencedTypeCache[columns[i].referenced_table_name],
                    columns[i].referenced_column_name)
            }
        }
        return typeName
    }))

    entriesRefresh: BehaviorSubject<number> = new BehaviorSubject(1)
    noKey = {}

    entries$: Observable<any> = combineLatest(
        this.types$,
        this.typeChange$,
        this.entriesRefresh,
        this.entriesPerPageFC.valueChanges.pipe(startWith(10)),
        this.currentPage$,
        this.sortField$,
        this.sortIsDescending$,
    ).pipe(
        switchMap(([typesResponse, typeName, refreshDummy, entriesPerPage, pageNumber, sortField, sortIsDescending]) => {
            //console.log("type and types", typeName, typesResponse)
            var request = {
                type: typeName,
                limit: entriesPerPage,
                offset: (pageNumber - 1) * entriesPerPage,
                sort: [{
                    columnName: sortField,
                    isDescending: sortIsDescending,
                }]
            }
            //console.log("entries request", request)
            return this.crudService.put(request).pipe(map((response) => {
                //console.log("entries response", response)
                response.create_table = typesResponse.result[typeName].create_table
                response.definition = typesResponse.result[typeName].definition
                for (var i = 0; i < response.definition.length; ++i) {
                    response.definition[i].createFC = new UntypedFormControl()
                }
                response.sortField = sortField
                response.sortIsDescending = sortIsDescending
                response.currentPage = pageNumber
                response.entriesPerPage = entriesPerPage
                for (var i = 0; i < response.result.length; ++i) {
                    response.result[i].definition = typesResponse.result[typeName].definition
                }
                return response
            }))
        }),
    )

    constructor(
        private crudService: CrudService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
    ) { }

    sortClick(oldSortField, newSortField, sortIsDescending) {
        if (oldSortField == newSortField) {
            this.sortIsDescending$.next(!sortIsDescending)
        } else {
            this.sortField$.next(newSortField)
            this.sortIsDescending$.next(false)
        }
    }

    ngOnInit() {
        this.typeFC.setValue(localStorage.getItem(this.localStorageTypeName))
        this.entriesPerPageFC.setValue(10)
        this.initialized = true
    }

    showNewButton() {
        return !this.noKey[this.typeFC.value]
    }

    cancel() {
    }

    clear(definition) {
        for (var i = 0; i < definition.length; ++i) {
            definition[i].createFC.setValue(null)
        }
    }

    new(definition) {
        this.clear(definition)

        const dialogRef = this.dialog.open(CrudObservabledDialog, {
            panelClass: 'add-account-dialog-container',
            data: {
                definition,
                typeFC: this.typeFC,
                referencedTypes: this.referencedTypeCache,
                crud: this,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }

    entryClick(definition, entry) {
        if (this.noKey[this.typeFC.value]) {
            return
        }
        for (var i = 0; i < definition.length; ++i) {
            definition[i].createFC.setValue(entry[definition[i].Field])
        }
        // this.inputState = InputState.edit
        // this.shouldToggle = "true"
        const dialogRef = this.dialog.open(CrudObservabledDialog, {
            panelClass: 'add-account-dialog-container',
            data: {
                definition,
                typeFC: this.typeFC,
                referencedTypes: this.referencedTypeCache,
                value: entry,
                crud: this
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }

    submitChange(definition, operation) {
        this.saveError = null
        var entry: any = {}
        for (var i = 0; i < definition.length; ++i) {
            var value = definition[i].createFC.value
            if (definition[i].Type == "datetime" && value) {
                value = value.replace("T", " ").replace("Z", "")
            }
            entry[definition[i].Field] = value
        }

        this.crudService.applyOperations({
            entries: [{
                type: this.typeFC.value,
                operation,
                value: entry,
            }]
        }).subscribe((response) => {
            if (!response.success) {
                this.saveError = response.message
                console.log("error while submitting change", response)
                this._snackBar.open("Error occurred: " + response.message, null, {
                    duration: 4000,
                });
                return
            }
            this._snackBar.open("Operation complete", null, {
                duration: 4000,
            });
            this.entriesRefresh.next(0)
        })
    }

    create(definition) {
        this.submitChange(definition, "insert")
    }

    update(definition) {
        this.submitChange(definition, "update")
    }

    delete(definition) {
        this.submitChange(definition, "delete")
    }
}

@Component({
    selector: 'crud-observabled-dialog',
    templateUrl: './crud-observabled-dialog.html',
})
export class CrudObservabledDialog {
    newEntry

    faExclamationTriangle = faExclamationTriangle
    definition
    typeFC
    referencedTypes

    constructor(
        public dialogRef: MatDialogRef<CrudObservabledDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackRoute: SnackRouteService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,

    ) {
        this.definition = data.definition
        this.typeFC = data.typeFC
        this.referencedTypes = data.referencedTypes

        if (!data.value) {
            this.newEntry = true
        } else {
            this.newEntry = false
            for (var i = 0; i < this.definition.length; ++i) {
                this.definition[i].createFC.setValue(data.value[this.definition[i].Field])
            }

            //
        }
    }
    faTimes = faTimes
    faBan = faBan
    faMinus = faMinus
    faSave = faSave

    cancel() {
        this.dialogRef.close();
    }

    delete() {
        const dialogRef = this.dialog.open(CrudObservabledDeleteConfirmationDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.data.crud.delete(this.definition)
                this.dialogRef.close();
            } else {
                this._snackBar.open("Action cancelled", null, {
                    duration: 4000,
                });
            }
        });
    }

    showCreateButton() {
        return this.newEntry
    }

    showClearButton() {
        return this.newEntry
    }

    showUpdateButton() {
        return !this.newEntry
    }

    showFieldAsFK(column) {
        return column.referenced_table_name && this.referencedTypes[column.referenced_table_name]
    }

    showFieldAsDate(column) {
        return !this.showFieldAsFK(column) && column.Type == 'datetime'
    }

    showFieldAsTextArea(column) {
        if (this.showFieldAsFK(column)) {
            return false
        }
        const typePrefix = "varchar("
        if (!column.Type.startsWith(typePrefix)) {
            return false
        }
        var lenString = column.Type.substring(typePrefix.length, column.Type.indexOf(")", typePrefix.length))
        if (parseInt(lenString) > 256) {
            return true
        }
        return false
    }

    showFieldAsText(column) {
        return !this.showFieldAsFK(column) && !this.showFieldAsDate(column) && !this.showFieldAsTextArea(column)
    }

    showFieldGenerateUuid(column) {
        return this.newEntry && column.Type == "varchar(36)" && !column.createFC.value
    }

    fieldGenerateUuid(column) {
        column.createFC.setValue(uuid())
    }

    clear() {
        this.data.crud.clear(this.definition)
    }

    update() {
        this.data.crud.update(this.definition)
        this.dialogRef.close({});
    }

    create() {
        this.data.crud.create(this.definition)
        this.dialogRef.close({});
    }
}

@Component({
    selector: 'crud-observabled-delete-confirmation-dialog',
    templateUrl: './crud-observabled-delete-confirmation-dialog.html',
})
export class CrudObservabledDeleteConfirmationDialog {
    faExclamationTriangle = faExclamationTriangle
    constructor(
        public dialogRef: MatDialogRef<CrudObservabledDeleteConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) { }
    faTimes = faTimes
    faBan = faBan
    faMinus = faMinus

    cancel() {
        this.dialogRef.close();
    }

    delete() {
        this.dialogRef.close({});
    }
}
