<div *ngIf="initialized" class="container-fluid">

    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faHandsHelping"></fa-icon>
            Outreach Campaign Request Submission
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>

    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">

            <app-submission-header [submissionUuid]="submissionUuid" [submission]="submission$ | async" [statusesById]="submissionStatuses$ | async">
            </app-submission-header>

            <!-- HSNY 1756 
            <app-submission-activity-feed [submissionActivityFeed]="submissionActivityFeed$ | async">
            </app-submission-activity-feed>

            <app-submission-history [submissionHistory]="submissionHistory$ | async"></app-submission-history>
            -->

            <app-submission-attachments [submissionUuid]="submissionUuid"></app-submission-attachments>

            <app-submission-attachment-controls [submissionUuid]="submissionUuid">
            </app-submission-attachment-controls>

            <app-outreach-submission [showFlag]="false" [editable]="true"></app-outreach-submission>
            
            <app-submission-activity-feed [submissionActivityFeed]="submissionActivityFeed$ | async">
            </app-submission-activity-feed>

            <app-submission-history [submissionHistory]="submissionHistory$ | async"></app-submission-history>

            <!-- <div class="row pt-2">
        <div class="col-3 bold">
            Instructions
        </div>
        <div class="col-9" style="white-space: pre-wrap;">{{outreachSubmission.instructions}}</div>
    </div>

    <div class="row pt-2 mt-4">
        <div class="col-1">
        </div>
        <div class="col-6">
            Submitted By
        </div>
        <div class="col-5">
            Approved By
        </div>
    </div>
    <div class="row pt-2">
        <div class="col-1">
        </div>
        <div class="col-6">
            <span class="ml-5 value-grey-background">{{submitterName}}</span>
        </div>
        <div class="col-5">
            <div class="ml-5 value-grey-background">{{approverName}}</div>
        </div>
    </div> -->

            <!-- <div *ngIf="saveError" class="row mt-3">
        <div class="col" align="center" style="white-space: pre-wrap;">
            <span class="overall-validation-error">
                {{saveError}}
            </span>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan"
                ">
            </app-button3>
            <app-button3 class="mx-1" label="Save for Later" [clickFunction]="saveForLater.bind(this)" [icon]="faSave">
            </app-button3>
            <app-button3 class="mx-1" label="Submit" [clickFunction]="submit.bind(this)" [icon]="faSave">
            </app-button3>
        </div>
    </div>

    <div *ngIf="!editable" class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="OK" [clickFunction]="ok.bind(this)" [icon]="faCheck" ">
            </app-button3>
        </div>
    </div> -->

        </div>
    </div>
</div>