import { Directive, ElementRef, Input, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: 'input[appNumberFormat]'
})
export class NumberFormatDirective {
  @Input() currencyFormat: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) 
  {
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue)) 
    {
      if (this.currencyFormat) 
      {
        const formattedValue = numericValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      } 
      else 
      {
        const formattedValue = numericValue.toLocaleString('en-US');
        this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      }

      // Store the numeric value as a property on the input element
      this.el.nativeElement.numberValue = numericValue;
    } 
    else 
    {
      this.renderer.setProperty(this.el.nativeElement, 'value', '');
      this.el.nativeElement.numberValue = null;
    }
  }
}

