import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { faFileInvoiceDollar, faCheck, faPlus, faTrash, faFlag } from '@fortawesome/free-solid-svg-icons';
import { VoucherService } from '../voucher.service';
import { VoucherLinesModel } from './voucher-lines.model';
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table'
import { VoucherLinesAddComponent } from './voucher-lines-add.component'
import { DatePipe, Location, formatDate } from '@angular/common';
import { VoucherLinesEditComponent } from './voucher-lines-edit.component';
import { BudgetService } from '../budget.service';
import { SnackRouteService } from '../snack-route.service';
import { SessionService } from '../session.service'
import { VoucherFlagCommentComponent } from './voucher-flag-comment.component';
import { EmptyVoucherOutreachDialog, VoucherOutreachComponent } from './voucher-outreach-add.component';
import { VOUCHEROUTREACH } from '../mock/mock-voucher-outreach';

@Component({
  selector: 'app-voucher-lines',
  templateUrl: './voucher-lines.component.html',
  styleUrls: ['./voucher-lines.component.css']
})
export class VoucherLinesComponent implements OnInit {
  @Input() accountUuid: string
  @Input() submissionUuid: string
  @Input() showFlag: boolean
  @Input() status: string
  @Input() accountType: string
  @Input() approvalDate: Date
  @Input() dueDate: Date
  @Input() canEdit: boolean
  @Output('getLineTotals') getLineTotalsEmitter: EventEmitter<any> = new EventEmitter()
  @Output('triggerSave') triggerSave: EventEmitter<any> = new EventEmitter()

  displayedColumns =
  [
    'information',
    'state_only',
    'state_federal',
    'charged_amount',
    'check_info',
    'check_amount',
    'flag_comment',
    'edit'
  ]

  faFileInvoiceDollar = faFileInvoiceDollar
  faCheck = faCheck
  faPlus = faPlus
  faTrash = faTrash
  faFlag = faFlag

  vlDataSource: MatTableDataSource<VoucherLinesModel>
  voucherLines = []
  voucherLineUuid: string
  callTotals = false;
  
  stateOnlySum: number = 0
  stateFederalSum: number = 0
  value: number

  line_type: string
  link: string
  line_number: number
  link_metadata: string
  allow_percent: string
  allow_method: string
  claims: any

  constructor(
    private voucherService: VoucherService,
    private dialog: MatDialog,
    private location: Location,
    private budgetService: BudgetService,
    private snackService: SnackRouteService,
    private sessionService: SessionService,
    public datePipe: DatePipe
  ) {}

  saveEvent() {
    this.triggerSave.emit()
  }

  eventUpdate() {
    this.callTotals = true
    this.getLineTotalsEmitter.emit()
  }

  goBack() {
    this.location.back()
  }

  addLine() {
    this.budgetService.addVoucherLine(this.accountUuid, this.accountType).subscribe((result: any) => {
      console.log(result)
      if(result) {
        console.log("Adding voucher line..")
        this.snackService.snack("Adding new voucher line, refreshing.")

        window.location.reload()
      } else if (result.success == false) {
        this.snackService.snack("Error adding voucher line.")
      }
    })

  }

  getLines() {
    this.claims = this.sessionService.getClaims()
    this.voucherService.getVoucherLines(this.accountUuid).subscribe((result: any) => {
      if(result.success == true) {
        this.vlDataSource = new MatTableDataSource(result.response)
        this.vlDataSource.data.forEach(row => {
          this.line_type = row.line_type
          this.link = row.link
          this.link_metadata = row.metadata
          this.allow_percent = row.allow_percent
          this.allow_method = row.allow_method
          this.stateOnlySum += Number(row.state_only)
          this.stateFederalSum += Number(row.state_federal)
        })
      }
    })
  }


  editLine(
    line_uuid: string,
    description: string,
    name: string,
    period: string,
    quantity_est: number,
    quantity: number,
    unit_price: number,
    invoice_number: string,
    premium: number,
    dates: string,
    line_date: string,
    state_only: number,
    state_federal: number,
    other_amount: number,
    line_reference_number: string,
    line_reference_date: string,
    check_amount: number,
    metadata: string,
    method: string,
    line_type: string,
    line_number: number,
    percent: number
  ) {
    this.dialog.open(
      VoucherLinesEditComponent,
      {
        width: '400px',
        data: {
          line_uuid: line_uuid,
          finance_uuid: this.accountUuid,
          description: description,
          name: name,
          period: period,
          quantity_est: quantity_est,
          quantity: quantity,
          unit_price: unit_price,
          invoice_number: invoice_number,
          premium: premium,
          dates: dates,
          line_date: line_date,
          state_only: state_only,
          state_federal: state_federal,
          other_amount: other_amount,
          line_reference_number: line_reference_number,
          line_reference_date: line_reference_date,
          check_amount: check_amount,
          metadata: metadata,
          method: method,
          line_type: line_type,
          line_number: line_number,
          percent: percent,
          allow_percent: this.allow_percent,
          allow_method: this.allow_method
        }
      }
    ).afterClosed().subscribe(_result => { this.eventUpdate(), window.location.reload() })

  }


  addOutreach(submissionUuid: string, accountUuid: string) {
    this.dialog.open(VoucherOutreachComponent, {
      minWidth: '86vw',
      data: {
        submissionUuid: submissionUuid,
        accountUuid: accountUuid,
        approvalDate: this.approvalDate,
        dueDate: this.dueDate
      }
    })
  }

  flagComment(line_uuid: string, comment: string) {
    console.log(line_uuid, comment)
    this.dialog.open(
        VoucherFlagCommentComponent,
        {
          width: '400px',
          data: {
            line_uuid: line_uuid,
            comment: comment
          }
        }
      ).afterClosed().subscribe(_result => { window.location.reload() }
    )
  }

  ngOnInit() {
    this.getLines()
    this.eventUpdate()
  }

}