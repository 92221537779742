<div class="open-dialog-button" (click)="showNewOutreachDialog()">
    <span class="pi pi-pen-to-square" style="font-size: 1.15rem"></span> New Request
</div>
<p-dialog header="New Outreach Request" [modal]="true" [(visible)]="newOutreachDialogVisable" [style]="{'width':'600px'}" (onHide)="resetOutreachForm()">
    <form [formGroup]="outreachForm" *ngIf="outreachForm">
        <div class="input-container">
            <label for="county">County:</label>
            <p-dropdown
            [options]="countyDropdownOptions"
            [style]="{'width':'100%'}"
            optionLabel="name"
            optionValue="id"
            id="county"
            formControlName="county"
            placeholder="Select a County"
            ></p-dropdown>
        </div>
        <div class="input-container">
            <label for="outreach">Outreach:</label>
            <p-dropdown
            [options]="campaignDropdownOptions"
            [style]="{'width':'100%'}"
            optionLabel="name"
            optionValue="fiscalUuid"
            id="outreach"
            formControlName="outreach"
            placeholder="Select a Outreach"
            ></p-dropdown>
        </div>
        <div class="input-container">
            <label for="slogan">Slogan:</label>
            <input pInputText id="slogan" autocomplete="off" formControlName="slogan">
        </div>
        <div class="input-container">
            <label for="requestNumber">Request Number:</label>
            <p id="requestNumber">{{ outreachForm.get('requestNumber').value }}</p>
        </div>        
        <div class="button-container">
            <p-button label="Submit" (click)="createNewOutreach()" />
            <p-button label="Cancel" severity="secondary" (click)="hideNewOutreachDialog()" />
        </div>
    </form>
</p-dialog>