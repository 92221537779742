import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-validation-error',
    templateUrl: './validation-error.component.html',
})
export class ValidationErrorComponent implements OnInit {
    @Input() error
    @Input() odd
    constructor() { }

    ngOnInit() {
    }

}
