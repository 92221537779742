import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-submission-maintenance-wizard-confirmation',
    templateUrl: './submission-maintenance-wizard-confirmation.component.html',
    styleUrls: ['./submission-maintenance-wizard-confirmation.component.css']
})
export class SubmissionMaintenanceWizardConfirmationComponent implements OnInit {

    faCheck = faCheck
    faCheckCircle = faCheckCircle
    constructor(
        private router: Router,

    ) { }

    ngOnInit() {
    }

    returnToDashboard() {
        this.router.navigate(["/submission-schedule"])
    }
}
