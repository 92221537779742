import { Component, OnInit, Input } from '@angular/core';
import { BudgetService } from '../budget.service';

@Component({
  selector: 'app-voucher-summary-header',
  templateUrl: './voucher-summary-header.component.html',
  styleUrls: ['./voucher-summary-header.component.css']
})
export class VoucherSummaryHeaderComponent implements OnInit {
  @Input() submissionUuid: string
  organizationName: string
  description: string

  constructor(private budgetService: BudgetService) { }

  ngOnInit() {
    if(this.submissionUuid) {
      this.budgetService.getSummaryHeader(this.submissionUuid).subscribe(results => {
        this.organizationName = results.result[0].organization_name
        this.description = results.result[0].description
      })
    }
  }
}
