import { Component, OnInit } from '@angular/core';
import { DocumentMasterService } from '../document-master.service';
import { UntypedFormControl } from '@angular/forms';
import { faSave, faBan } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AgencyService } from '../agency.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParamService } from '../param.service';

@Component({
    selector: 'app-document-master',
    templateUrl: './document-master.component.html',
})
export class DocumentMasterComponent implements OnInit {
    document
    initialized = false
    faSave = faSave
    faBan = faBan

    documentNameFC = new UntypedFormControl()
    documentInstructionFC = new UntypedFormControl()
    documentForFC = new UntypedFormControl()
    requiredFC = new UntypedFormControl()
    isRecurringFC = new UntypedFormControl()
    reoccurEveryValueFC = new UntypedFormControl()
    reoccurEveryUnitsFC = new UntypedFormControl()
    createFC = new UntypedFormControl()
    reoccursOnFC = new UntypedFormControl()
    reoccurStartFC = new UntypedFormControl()

    // documentForValues = [
    //     {
    //         name: "HSNY Only",
    //         id: "PRIME"
    //     }, {
    //         name: "Agency Submission",
    //         id: "SUB"
    //     }
    // ]

    requiredValues = [
        {
            name: "Required - Agency cannot submit without this document attached",
            id: "required"
        }, {
            name: "Optional - Agency can submit without this document attached",
            id: "optional"
        }
    ]

    updateReoccurEnableState() {
//        console.log(this.isRecurringFC.value)
        if (this.isRecurringFC.value) {
            this.reoccurEveryValueFC.enable()
            this.reoccurEveryUnitsFC.enable()
            this.createFC.enable()
            this.reoccursOnFC.enable()
            this.reoccurStartFC.enable()
            document.getElementById("reoccur_attributes").style.backgroundColor = "unset"
        } else {
            this.reoccurEveryValueFC.disable()
            this.reoccurEveryUnitsFC.disable()
            this.createFC.disable()
            this.reoccursOnFC.disable()
            this.reoccurStartFC.disable()
            document.getElementById("reoccur_attributes").style.backgroundColor = "lightgrey"
        }
    }

    mapDocument(rows) {
        console.log(rows)
        this.documentNameFC.setValue(rows[0].name)
        this.documentInstructionFC.setValue(rows[0].instructions)
        this.documentForFC.setValue(rows[0].organization_type_id)
        this.requiredFC.setValue(rows[0].required ? "required" : "optional")
        this.isRecurringFC.setValue(rows[0].reoccur)
        this.reoccurEveryValueFC.setValue(rows[0].interval_number)
        this.reoccurEveryUnitsFC.setValue(rows[0].frequency_id)
        this.createFC.setValue(rows[0].days_in_advance)
        this.reoccursOnFC.setValue(rows[0].agency_date_id)
        if (rows[0].reoccur_start_date_unix) {
            this.reoccurStartFC.setValue(rows[0].reoccur_start_date_unix * 1000)
        }

        for (var i = 0; i < rows.length; ++i) {
            if (rows[i].submission_type_id) {
                for (var j = 0; j < this.submissionTypes.length; ++j) {
                    if (this.submissionTypes[j].id == rows[i].submission_type_id) {
                        this.submissionTypes[j].fc.setValue(true)
                        break
                    }
                }
            }
        }
    }

    constructor(
        private _documentMasterService: DocumentMasterService,
        private router: Router,
        private agencyService: AgencyService,
        private _snackBar: MatSnackBar,
        private paramService: ParamService,
    ) { }

    get documentMasterService() {
        return this._documentMasterService
    }

    submissionTypes
    getSubmissionTypesPromise() {
        return new Promise((resolve, reject) => {
            this.documentMasterService.getSubmissionTypes().subscribe((response) => {
                if (response.success) {
                    this.submissionTypes = response.result
                    for (var i = 0; i < this.submissionTypes.length; ++i) {
                        this.submissionTypes[i].fc = new UntypedFormControl()
                    }
                } else {
                    console.log("error when fetching submission types", response)
                }
                resolve(null)
            })
        })
    }

    documentForValues
    getDocumentOrganizationTargetsPromise() {
        return new Promise((resolve, reject) => {
            this.documentMasterService.getDocumentOrganizationTargets().subscribe((response) => {
                if (response.success) {
                    this.documentForValues = response.result
                    for (var i = 0; i < this.documentForValues.length; ++i) {
                        this.documentForValues[i].name = this.documentForValues[i].document_reference_title
                    }
                } else {
                    console.log("error when fetching submission types", response)
                }
                resolve(null)
            })
        })
    }

    frequencies
    getFrequenciesPromise() {
        return new Promise((resolve, reject) => {
            this.documentMasterService.getFrequencies().subscribe((response) => {
                if (response.success) {
                    this.frequencies = response.result
                } else {
                    console.log("error when fetching frequencies", response)
                }
                resolve(null)
            })
        })
    }

    agencyDates
    reoccurAgencyDates = []
    dateInputs = [
        {
            id: "reoccur_start_date",
            fc: this.reoccurStartFC,
        }
    ]

    getAgencyDatesPromise() {
        return new Promise((resolve, reject) => {
            this.documentMasterService.getAgencyDates().subscribe((response) => {
                console.log(response)
                if (response.success) {
                    this.agencyDates = response.result
                    for (var i = 0; i < this.agencyDates.length; ++i) {
                        if (this.agencyDates[i].reoccur) {
                            this.reoccurAgencyDates.push(this.agencyDates[i])
                        }
                    }
                } else {
                    console.log("error when fetching agency dates", response)
                }
                resolve(null)
            })
        })
    }

    documentData
    getEditDocumentPromise() {
        if (this.documentUuid) {
            return new Promise((resolve, reject) => {
                this.documentMasterService.getDocument(this.documentUuid).subscribe((response) => {
                    if (response.result && response.result.length > 0) {
                        this.mapDocument(response.result)
                        this.documentData = response.result
                    } else {
                        console.log("error - no document data retrieved")
                    }
                    resolve(null)
                })
            })
        } else {
            return Promise.resolve(null)
        }
    }

    programs
    programFC = new UntypedFormControl()

    getProgramsPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getPrograms().subscribe((response) => {
                if (response.success) {
                    this.programs = response.result
                }
                resolve(null)
            })
        })
    }

    setProgram() {
        if (this.documentData && this.documentData.length > 0) {
            this.programFC.setValue(this.documentData[0].program_id)

        }
    }

    documentUuid
    ngOnInit() {
        this.paramService.getParamPromise("documentUuid").then((paramValue) => {
            this.documentUuid = paramValue
        }).then(() => {
            var promises = [
                this.getSubmissionTypesPromise().then(() => { return this.getEditDocumentPromise() }),
                this.getFrequenciesPromise(),
                this.getAgencyDatesPromise(),
                this.getDocumentOrganizationTargetsPromise(),
            ]
            return Promise.all(promises).then(() => {
                this.initialized = true
                setTimeout(this.updateReoccurEnableState.bind(this), 0)
            })
        })
    }

    cancel() {
        var snack = "Action Cancelled"
        this._snackBar.open(snack, null, { duration: 2000, });
        this.router.navigate(["/document-master-listing"])
    }

    convertAppliesTo() {
        var applies_to = []
        for (var j = 0; j < this.submissionTypes.length; ++j) {
            if (this.submissionTypes[j].fc.value) {
                applies_to.push(this.submissionTypes[j].id)
            }
        }
        return applies_to
    }

    add() {
        // HSNY-1833 reoccur value in db being set to null - brock
        console.log(this.isRecurringFC.value)
        if(this.isRecurringFC.value == null) {
            this.isRecurringFC.setValue(0)
        }
        console.log(this.isRecurringFC.value)

        this.documentMasterService.addDocument(
            this.documentNameFC.value,
            this.documentForFC.value,
            this.requiredFC.value == "required" ? true : false,
            this.documentInstructionFC.value,
            this.isRecurringFC.value,
            this.reoccurEveryValueFC.value,
            this.reoccurEveryUnitsFC.value,
            this.createFC.value,
            this.reoccursOnFC.value,
            this.reoccurStartFC.value,
            this.convertAppliesTo(),
        ).subscribe((response) => {
            if (response.success) {
                var snack = "Document Added"
                this._snackBar.open(snack, null, { duration: 2000, });
                this.router.navigate(["/document-master-listing"])
            } else {
                console.log("error while adding", response)
            }
        })
    }

    update() {
        this.documentMasterService.updateDocument(
            this.documentUuid,
            this.documentNameFC.value,
            this.documentForFC.value,
            this.requiredFC.value == "required" ? true : false,
            this.documentInstructionFC.value,
            this.isRecurringFC.value,
            this.reoccurEveryValueFC.value,
            this.reoccurEveryUnitsFC.value,
            this.createFC.value,
            this.reoccursOnFC.value,
            this.reoccurStartFC.value,
            this.convertAppliesTo(),
        ).subscribe((response) => {
            if (response.success) {
                var snack = "Document Updated"
                this._snackBar.open(snack, null, { duration: 2000, });
                this.router.navigate(["/document-master-listing"])
            } else {
                console.log("error while updating", response)
            }
        })
    }
}
