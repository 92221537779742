<form [formGroup]="filterForm">
    <div class="form-container">
      <mat-form-field class="filter-field">
        <mat-label>Filter</mat-label>
        <input matInput formControlName="filter" placeholder="Type to filter">
      </mat-form-field>
  
      <mat-form-field class="dropdown-field">
        <mat-label>Fiscal Year</mat-label>
        <mat-select formControlName="selectedFiscalYear" placeholder="Select a Fiscal Year">
          <mat-option *ngFor="let fiscalYear of fiscalYearOptions" [value]="fiscalYear.id">{{ fiscalYear.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</form>
<div class="table-container">
    <table mat-table [dataSource]="bankAccounts" class="mat-elevation-z8" matSort>
        <!-- Year Column -->
      <ng-container matColumnDef="fiscalYear" >
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fiscal Year </th>
        <td mat-cell *matCellDef="let account"> {{ account.fiscalYear }} </td>
      </ng-container>
    
      <!-- Organization/Agency -->
      <ng-container matColumnDef="organizationName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Agency </th>
        <td mat-cell *matCellDef="let account" class="clickable-data" (click)="viewBankAccountReport(account.organizationUuid)"> {{ account.organizationName }} </td>
      </ng-container>
    
      <!-- State Only -->
      <ng-container matColumnDef="debitState">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> State Only </th>
          <td mat-cell *matCellDef="let account"> {{ account.debitState | currency:'USD':'symbol':'1.0-0' }} </td>
        </ng-container>
    
      <!-- State Federal -->
      <ng-container matColumnDef="debitFederal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> State Federal </th>
        <td mat-cell *matCellDef="let account"> {{ account.debitFederal | currency:'USD':'symbol':'1.0-0' }} </td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let account; columns: displayedColumns"></tr>
    </table>    
</div>
