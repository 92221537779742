import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-help-center',
    templateUrl: './help-center.component.html',
})
export class HelpCenterComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
