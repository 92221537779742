<!--
<div class="row mt-3">
    <div class="col pt-2" style="background-color: #e6e6e6;">
        <span style="font-size: 1.2em;"><span style="font-weight: bold;">{{stepName}}</span>
        </span>
    </div>
</div>

<div class="row">
    <div class="col pt-2" style="background-color: white;">
        <span style="font-size: 1.0em; color: black; font-weight: bold;">
            Step Instructions:
        </span>
        <span style="font-size: 1.0.em; color: black; font-weight: bold;">
            {{ stepInstructions }}
        </span>
    </div>
</div>
-->
<span *ngFor="let group of questionGroups; let i = index">

    <div class="row mt-3" *ngIf="i >= 1;else first_step_name">
        <div class="col pt-2" style="background-color: #e6e6e6;">
            <span style="font-size: 1.2em;">
                <span style="font-weight: bold;" *ngIf="questionGroups[i].step_name != questionGroups[i-1].step_name">{{group.step_name}}</span>
            </span>
        </div>
    </div>
    
    <div class="row" *ngIf="i >= 1;else first_step_instructions">
        <div class="col pt-2" style="background-color: white;" *ngIf="questionGroups[i].step_name != questionGroups[i-1].step_name">
            <span style="font-size: 1.0em; color: black; font-weight: bold;">
                Step Instructions:
            </span>
            <span style="font-size: 1.0.em; color: black; font-weight: bold;">
                {{ group.step_instructions }}
            </span>
        </div>
    </div>

    <ng-template #first_step_name>
        <div class="row mt-3">
            <div class="col pt-2" style="background-color: #e6e6e6;">
                <span style="font-size: 1.2em;">
                    <span style="font-weight: bold;">{{group.step_name}}</span>
                </span>
            </div>
        </div>
    </ng-template>

    <ng-template #first_step_instructions>
        <div class="row">
            <div class="col pt-2" style="background-color: white;">
                <span style="font-size: 1.0em; color: black; font-weight: bold;">
                    Step Instructions:
                </span>
                <span style="font-size: 1.0.em; color: black; font-weight: bold;">
                    {{ group.step_instructions }}
                </span>
            </div>
        </div>
    </ng-template>

    <div class="row">
        <div class="col pt-2" style="background-color: lightgrey;" id="{{group.group_number}}">
            <span style="font-size: 1.2em;">
                <span style="font-weight: bold;">{{group.group_number}}.</span>
                {{group.group_long_name}}
            </span>
            <fa-icon *ngIf="showFlag && !group.commentValue" style="float: right;" [icon]="faFlag" class="flag"
                (click)="toggleCommentInput(group)" [title]="group.showCommentInput ? 'Cancel' : 'Edit'"></fa-icon>
            <fa-icon *ngIf="group.commentValue" style="float: right; font-size: 1.5em;" [icon]="faExclamationTriangle"
                class="alert-icon" (click)="toggleCommentInput(group)"
                [title]="group.showCommentInput ? 'Cancel' : 'Edit'">
            </fa-icon>
        </div>
    </div>
    <div *ngIf="!group.showCommentInput && group.commentValue" class="row">
        <div class="col">
            <span style="float: right;" class="comment-text">
                {{group.commentValue}}
            </span>
        </div>
    </div>

    <div *ngIf="group.showCommentInput" class="row">
        <div class="col pt-1" style="background-color: #f6f6f6;">
            <span style="float: right;">
                <textarea [rows]="1" [formControl]="group.commentFC"
                    style="vertical-align: top; resize: both; border-radius: .3em; width: 16em; border-color: lightgrey;">
                    </textarea>
                <div class="comment-button" (click)="saveComment(group)" [title]="group.commentUuid ? 
                        (group.commentFC.value ? 'Update comment' : 'Delete comment') 
                        : (group.commentFC.value ? 'Create comment' : 'Cancel')">
                    <fa-icon [icon]="faCheck"></fa-icon>
                </div>
                <div class="comment-button" (click)="deleteComment(group)"
                    [title]="group.commentUuid ? 'Delete comment' : 'Cancel'">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </div>
            </span>
        </div>
    </div>

    <span *ngFor="let question of group.children; let odd = odd; let index = index">

        <div *ngIf="index && question.additional_answer_sequence_number && question.additional_answer_sequence_number != group.children[index - 1].additional_answer_sequence_number"
            class="row">
            <div class="col py-2" style="background-color: lightgrey;">
                &nbsp;
            </div>
        </div>

        <div class="row pt-1" [ngStyle]="{ 
            backgroundColor: 
                editable && question.step_status_id != 'T' 
                && question.required 
                && (question.fc.value == null || question.fc.value == '') ? 'lightyellow' 
                : odd ? '#ECECEC' : null}">
            <div class="col-1">
                {{question.group_number}}.{{question.question_number}}
            </div>
            <div class="col-6">
                {{question.question_name}}<span *ngIf="question.popup_html && !question.additional_answer_sequence_number" class="popup hs-orange-border"
                    style="display: inline;">
                    <span class="hs-orange-border hs-orange bg-white">
                        <fa-icon class="question-circle ml-1" [icon]="faQuestionCircle" (click)="togglePopup(question)">
                        </fa-icon>
                    </span>
                    <span class="popup-arrow" id='qpopup_arrow_{{question.uuid}}' style='display: none;'></span>
                    <span class='popup-text' id='qpopup_{{question.question_uuid}}' style='display: none;'></span>
                    <span class='popup-border' id='qpopup_border_{{question.uuid}}' style='display: none;'></span>
                </span>
            </div>
            <div *ngIf="!editable" class="col-5">
                <span *ngIf="question.answer_datatype == 'answer_number'
                                || question.answer_datatype == 'answer_text'
                                || question.answer_datatype == 'answer_number_and_text'
                                || question.answer_datatype == 'answer_comment'">
                    {{question.fc.value}}
                </span>
                <span *ngIf="question.answer_datatype == 'answer_special'">
                    <!--
                    {{ question.special_answers_dropdown_item[question.fc.value] ? question.special_answers_dropdown_item[0].name : "" }}
                    -->
                    {{question.answer_special_name}}
                    <!--
                    {{ question.answer_special_name}}
                    
                    {{question.special_answers_dropdown_item[question.fc.value] ? 
                        question.special_answers_dropdown_item[0].name : ""}}
                    -->
                    
                </span>
                <span *ngIf="question.answer_datatype == 'answer_number_and_text'">
                    {{question.fc2.value}}
                </span>
                <span *ngIf="question.answer_datatype == 'answer_date'">
                    {{question.fc.value | date : 'shortDate'}}
                </span>
            </div>

            <div *ngIf="editable" class="col-5">
                <input *ngIf="question.answer_datatype == 'answer_number'" type="text"
                    style="width: 4em; border-color: lightgrey; text-align: right; padding-right: .25em;"
                    class="input-style" [formControl]="question.fc" (keyup)="calculateSubtotals()" />

                <input *ngIf="question.answer_datatype == 'answer_text'" type="text"
                    style="width: 8em; border-color: lightgrey" class="input-style" [formControl]="question.fc"
                    maxlength="255" />

                <input *ngIf="question.answer_datatype == 'answer_number_and_text'" type="text"
                    style="width: 4em; border-color: lightgrey; text-align: right; padding-right: .25em;"
                    [formControl]="question.fc" (keyup)="calculateSubtotals()" />
                <textarea *ngIf="question.answer_datatype == 'answer_number_and_text'" [rows]="3"
                    [formControl]="question.fc2" class="ml-2"
                    style="vertical-align: top; resize: both; border-radius: .3em; width: 16em;" maxlength="255">
                    </textarea>

                <textarea *ngIf="question.answer_datatype == 'answer_comment'" [rows]="3" [formControl]="question.fc"
                    style="vertical-align: top; resize: both; border-radius: .3em; width: 16em;" maxlength="4000">
                </textarea>

                <app-input-date *ngIf="question.answer_datatype == 'answer_date'" [fc]="question.fc"
                    [format]="InputDateFormat.none">
                </app-input-date>

                <app-input-enum *ngIf="question.answer_datatype == 'answer_special'" [fc]="question.fc"
                    [enumValuesNameAndId]="question.special_answers_dropdown_item" [style]="'none'">
                </app-input-enum>
            </div>

        </div>

        <div *ngIf="question.validationError || question.help_html" class="row" [ngStyle]="{ 
            backgroundColor: 
                editable && question.step_status_id != 'T' 
                && question.required 
                && (question.fc.value == null || question.fc.value == '') ? 'lightyellow' 
                : odd ? '#ECECEC' : null}">
            <div class="col-7">
                <span id="{{question.uuid}}_help_html_span" style="font-style: italic;">{{question.help_html}}</span>
            </div>
            <div class="col-5">
                <span *ngIf="question.validationError" class="px-2 validation-error">
                    {{question.validationError}}
                </span>
            </div>
        </div>

        <div class="row pb-1" [ngStyle]="{ 
            backgroundColor: 
                editable && question.step_status_id != 'T' 
                && question.required 
                && (question.fc.value == null || question.fc.value == '') ? 'lightyellow' 
                : odd ? '#ECECEC' : null}">
            <div class="col">
            </div>
        </div>

        <div class="row pb-1">
            <div class="col-7">
            </div>
            <div class="col-5">
                <app-button *ngIf="editable && question.allow_duplicate && question.last_question_add_other"
                    label="Add Another" 
                    [clickFunction]="addAnswer.bind(this, question.answer_uuid, question.group_number)" 
                    [icon]="faPlus">
                </app-button>
                &nbsp;
                <app-button *ngIf="editable && question.allow_duplicate && question.last_question_add_other"
                    label="Delete Another"
                    [clickFunction]="deleteAnswer.bind(this, question.answer_uuid, question.group_number)"
                    [icon]="faMinus"
                    isDelete="true">
                </app-button>
            </div>
        </div>

    </span>


    <div class="row pb-1">
        <div class="col-7">
        </div>
        <div class="col-5" *ngIf="editable && group && group.subtotal_additional">
            <app-button 
                label="Create Another" 
                [clickFunction]="addGroupAnswers.bind(this, group, group.group_number)"
                [icon]="faRedo">
            </app-button>
            <app-confirm-delete-dialog
                dialogMessage="question group"
                [groupData]="group">
            </app-confirm-delete-dialog>
        </div>
    </div>

    <div *ngIf="group.subtotal_group" class="row mt-3">
        <div class="col-7" style="text-align: right;">
            Total {{group.group_name}}
        </div>
        <div class="col-2">
            <div style="width: 4em; text-align: right;">
                {{group.group_subtotal}}
            </div>
        </div>
    </div>

    <span *ngIf="group.subtotal_additional">
        <div class="row mt-3" *ngIf="stepUuid !== 'NOEP_DATA_OVERCOME_BAR' && stepUuid !== 'NOEP_DATA_NARRATIVE'">
            <div class="col-7" style="text-align: right;">
                Total # of {{group.group_name}}
            </div>
            <div class="col-2">
                <div style="width: 4em; text-align: right;">
                    {{group.answer_count}}
                </div>
            </div>
        </div>
        <span *ngFor="let item of group.additional_subtotals | keyvalue">
            <div class="row">
                <div class="col-7" style="text-align: right;">
                    <!-- Total # of {{item.value.label}} -->
                    {{item.value.label}}
                </div>
                <div class="col-2">
                    <div style="width: 4em; text-align: right;">
                        {{item.value.subtotal}}
                    </div>
                </div>
            </div>
        </span>
    </span>
</span>

<div *ngIf="saveError" class="row mt-3">
    <div class="col" align="center" style="white-space: pre-wrap;">
        <span class="overall-validation-error">
            {{saveError}}
        </span>
    </div>
</div>

<div *ngIf="editable" class="row mt-3">
    <div class="col" align="center">
        <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
        </app-button3>
        <app-button3 *ngIf="previousStepUuid" class="mx-1" label="Previous" [clickFunction]="this.previous.bind(this)"
            [icon]="faSave">
        </app-button3>
        <app-button3 class="mx-1" label="Save For Later" [clickFunction]="saveForLater.bind(this)" [icon]="faSave"
            [isFinal]="true">
        </app-button3>
        <app-button3 *ngIf="nextStepUuid" class="mx-1" label="Next" [clickFunction]="next.bind(this)" [icon]="faSave"
            [isFinal]="true">
        </app-button3>
        <app-button3 *ngIf="!nextStepUuid" class="mx-1" label="Finish" [clickFunction]="finish.bind(this)"
            [icon]="faSave" [isFinal]="true">
        </app-button3>
    </div>
</div>