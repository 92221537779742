import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { VOUCHEROUTREACH } from '../mock/mock-voucher-outreach';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { VoucherService } from '../voucher.service';
import { SessionService } from '../session.service';
import { BudgetService } from '../budget.service';
import { DatePipe, formatDate } from '@angular/common';




@Component({
    selector: 'voucher-outreach-add',
    templateUrl: './voucher-outreach-add.component.html',
    styleUrls: ['./voucher-outreach-add.component.css'],
})
export class VoucherOutreachComponent implements OnInit {
    faPlus = faPlus

    // data = VOUCHEROUTREACH
    data: any
    claims: any
    mirroredOrgUuid: string
    submissionUuid: string
    accountUuid: string
    dueDate: Date
    approvalDate: Date

    displayedColumns: string[] = [
        'county', 
        'referenceNumber', 
        'name', 
        'agencyDate', 
        'approvalDate', 
        'creditFederalEst', 
        'debitStateEst',
        'button'
        ]
    

	constructor(
        private dialog: MatDialog,
        private voucherService: VoucherService,
        private budgetService: BudgetService,
        private sessionService: SessionService,
        public datePipe: DatePipe,
        @Inject(MAT_DIALOG_DATA) public uuidData: any) 
        {
            this.submissionUuid = uuidData.submissionUuid,
            this.accountUuid = uuidData.accountUuid,
            this.dueDate = uuidData.dueDate,
            this.approvalDate = uuidData.approvalDate
        }


    addOutreachLine(submissionUuid: string) {
        console.log(submissionUuid)
        this.budgetService.addVoucherOutreach(submissionUuid, this.accountUuid).subscribe((response) => {
            console.log(response)
            if(response.success === true) {
                window.location.reload()
            }
        })
    }

    closeDialog() {
        this.dialog.closeAll()
    }

    compareDates(approvalDate: Date, dueDate: Date): boolean {
        return approvalDate > dueDate
    }

    loadVoucherOutreach(organizationUuid: string)
    {
        this.voucherService.getVoucherOutreach(organizationUuid, this.dueDate).subscribe((response) => {
            if (response.success === true) 
            {
                this.data = response.results;
            }
        });
    }

    ngOnInit() 
    {
        this.mirroredOrgUuid = this.sessionService.getMirroredOrganizationUuid();
        this.claims = this.sessionService.getClaims();
    
        const organizationUuid = this.mirroredOrgUuid || this.claims.organization_uuid;
        this.loadVoucherOutreach(organizationUuid);
    }
    
}

 @Component({
        selector: 'empty-voucher-outreach-add',
        templateUrl: './empty-voucher-outreach-add.component.html',
        styleUrls: ['./voucher-outreach-add.component.css'],
})
export class EmptyVoucherOutreachDialog{

}
