import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubmissionStepService {

    constructor(
        private http: HttpClient,
    ) { }

    getStepsBySubmission(submissionId): Observable<any> {
        return this.http.get<any>("api/questionStep/" + submissionId)
    }

    getSubmissionQuestionGroupAnswers(editable, submissionId, stepId?): Observable<any> {
        return this.http.get<any>("api/submissionQuestionGroupAnswers/" + editable + "/" + submissionId + (stepId ? ("/" + stepId) : ""))
    }
}
