<button *ngIf="!commentExists && orgType == 'PRIME'" 
  class="flagButton" 
  mat-mini-fab aria-label="" 
  (click)="showCommentDialog()">
    <mat-icon class="flagIcon">flag</mat-icon>
</button>

<button *ngIf="commentExists"
  class="flagButtonCommentExists" 
  mat-mini-fab aria-label=""
  (click)="showCommentDialog()">
    <mat-icon class="flagIconCommentExists">flag</mat-icon>
</button><span *ngIf="commentExists" class="seeMessage">Flagged!</span>
<!-- <p *ngIf="commentExists" class="seeMessage"></p> -->
<!-- <p class="commentValue" *ngIf="commentExists">{{comment}}</p>

<div>
    <input type="text" [(ngModel)]="commentValue" (focus)="showCommentDialog()" />
    <ng-container *ngIf="commentExists">
      <app-flag-comment-dialog
        [masterUuid]="masterUuid"
        [submissionUuid]="submissionUuid"
        [targetColumn]="targetColumn"
        [(commentValue)]="commentValue"
        (commentSaved)="onCommentSaved()"
      ></app-flag-comment-dialog>
    </ng-container>
  </div> -->