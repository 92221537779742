<p-table #dt [styleClass]="'p-datatable-gridlines'" [columns]="trendReportColumns" [value]="trendReportDetails">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{ col.header }}
            </th>
            <th>$ Spent YTD</th>
            <th>% Spent YTD</th>
            <th>Remaining</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns" [ngClass]="{'text-right': isNumber(rowData[col.field])}">
                {{ isNumber(rowData[col.field]) ? ((rowData[col.field] | number:'1.0-0')) : rowData[col.field] }}
            </td>
            <td class="text-right">{{ calculateTotalSpent(rowData) | number:'1.0-0' }}</td>
            <td class="text-right">{{ calculatePercentageSpent(rowData) | number:'1.0-0' }} %</td>
            <td class="text-right text-bold">{{ calculateRemainingBudget(rowData) | number:'1.0-0' }}</td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer">
        <tr class="italic-text">
            <td>Total (State Only)</td>
            <td></td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m07 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m08 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m09 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m10 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m11 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m12 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m01 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m02 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m03 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m04 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m05 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m06 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ stateOnlyBudget | number:'1.0-0'  }}</td>
            <td class="text-right">$ {{ stateOnlyTotalSpent | number:'1.0-0'  }}</td>
            <td class="text-right">{{ stateOnlyTotalPercent | number:'1.0-0'  }} %</td>
            <td class="text-right">$ {{ stateOnlyRemainingBudget | number:'1.0-0'  }}</td>
        </tr>
    
        <tr class="italic-text">
            <td>Total (State/Fed)</td>
            <td></td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m07 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m08 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m09 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m10 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m11 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m12 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m01 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m02 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m03 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m04 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m05 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateFed.m06 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ stateFedBudget | number:'1.0-0'  }}</td>
            <td class="text-right">$ {{ stateFedTotalSpent | number:'1.0-0'  }}</td>
            <td class="text-right">{{ stateFedTotalPercent | number:'1.0-0'  }} %</td>
            <td class="text-right">$ {{ stateFedRemainingBudget | number:'1.0-0'  }}</td>
        </tr>
        <tr>
            <td>Total</td>
            <td></td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m07 + monthlyTotals.stateFed.m07 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m08 + monthlyTotals.stateFed.m08 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m09 + monthlyTotals.stateFed.m09 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m10 + monthlyTotals.stateFed.m10 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m11 + monthlyTotals.stateFed.m11 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m12 + monthlyTotals.stateFed.m12 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m01 + monthlyTotals.stateFed.m01 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m02 + monthlyTotals.stateFed.m02 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m03 + monthlyTotals.stateFed.m03 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m04 + monthlyTotals.stateFed.m04 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m05 + monthlyTotals.stateFed.m05 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ monthlyTotals.stateOnly.m06 + monthlyTotals.stateFed.m06 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ (stateOnlyBudget + stateFedBudget) | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ (stateOnlyTotalSpent + stateFedTotalSpent) | number:'1.0-0' }}</td>
            <td class="text-right">{{ totalPercent | number:'1.0-0' }} %</td>
            <td class="text-right">$ {{ (stateOnlyRemainingBudget + stateFedRemainingBudget) | number:'1.0-0' }}</td>
        </tr>
    </ng-template>
</p-table>
