import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan, faCheck, faCircle, faExclamationTriangle, faFlag, faHourglass, faMinus, faMoneyBillWave, faPlusSquare, faSave, faShare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { BudgetService } from '../budget.service';
import { CrudService } from '../crud.service';
import { SnackRouteService } from '../snack-route.service';
import { SubmissionCommentService } from '../submission-comment.service';
import { SubmissionService } from '../submission.service';
import { VoucherService } from '../voucher.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-voucher-submission',
    templateUrl: './voucher-submission.component.html',
    styleUrls: ['./voucher-submission.component.css']
})
export class VoucherSubmissionComponent implements OnInit {
    @Input() showFlag: boolean
    @Input() editable: boolean
    @Input() dd: Date

    isOffBalanced: boolean = false;

    faFlag = faFlag

    inputDataById = {}
    flagClick(inputId) {
        var inputData = this.inputDataById[inputId]
        if (inputData) {
            inputData.showInputContainer.value = !inputData.showInputContainer.value
        } else {
            this.inputDataById[inputId] = {
                showInputContainer: { value: true }
            }
        }
    }

    initialized: boolean = false
    faPlusSquare = faPlusSquare
    faBan = faBan
    faShare = faShare
    faSave = faSave
    faCircle = faCircle
    constructor(
        private budgetService: BudgetService,
        private activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        private snackRoute: SnackRouteService,
        private router: Router,
        private authService: AuthService,
        private submissionService: SubmissionService,
        private crudService: CrudService,
        private voucherService: VoucherService
    ) { }

    UIEntryType = {
        title: 0,
        columnNames: 1,
        entry: 2,
        totals: 3,
        comment: 4,
    }

    subscriptions = []
    submissionUuid

    allocation: any = {};

    sortField = "account_name"
    sortDirection
    columns = [
        {
            display: "Account",
            fieldName: "account_name",
        }, {
            display: "Description",
            fieldName: "name",
        }, {
            display: "SNAP",
            fieldName: "total_state",
            isMoney: true,
        }, {
            display: "SNAP / Child Nutrition",
            fieldName: "total_federal",
            isMoney: true,
        }, {
            display: "Total",
            fieldName: "total",
            isMoney: true,
        }, {
            display: "Spent",
            fieldName: "total_spent",
            isMoney: true,
        }, {
            display: "Balance",
            fieldName: "balance",
            isMoney: true,
        }
    ]

    groups = []
    fiscalYearId: string
    paramPromise() {
        return new Promise((resolve, reject) => {
            this.subscriptions.push(this.activatedRoute.queryParams.subscribe(params => {
                this.submissionUuid = params["submissionUuid"]
                this.fiscalYearId = params['fiscalYearId']
                resolve(null)
            }))
        })
    }

    flattenedEntries

    initTotals(totals) {
        totals.total_state = 0
        totals.total_federal = 0
        totals.total = 0
        totals.total_spent = 0
        totals.balance = 0
    }

    sumTotals(totals, entry) {
        totals.total_state += entry.total_state
        totals.total_federal += entry.total_federal
        totals.total += entry.total
        totals.total_spent += entry.total_spent
        totals.balance += entry.balance
    }

    addAccountOptions
    getAddAccountOptionsPromise() {
        return new Promise((resolve, reject) => {
            this.subscriptions.push(this.budgetService.getAddAccountOptions(this.submissionUuid).subscribe(response => {
                //console.log("add account options response", response)
                this.addAccountOptions = response.result
                for (var i = 0; i < this.addAccountOptions.length; ++i) {
                    this.addAccountOptions[i].id = this.addAccountOptions[i].uuid
                }
                resolve(null)
            }))
        })
    }

    administrativeAllocated = 0
    getVoucherSummaryPromise() {
        return new Promise((resolve, reject) => {

            this.budgetService.getVoucherSummary(this.submissionUuid, this.fiscalYearId).subscribe(response => {
                //console.log("summary response", response)
                this.flattenedEntries = []

                var lastGroup
                var totals
                var isOdd = true
                for (var i = 0; i < response.result.length; ++i) {
                    response.result[i].uiEntryType = this.UIEntryType.entry

                    if (response.result[i].reporting_group == "Overhead") {
                        this.administrativeAllocated += response.result[i].total_state
                        this.administrativeAllocated += response.result[i].total_federal
                    }

                    if (lastGroup && lastGroup.reporting_group == response.result[i].reporting_group) {

                        isOdd = !isOdd
                        response.result[i].isOdd = isOdd

                        if (isOdd) {
                            response.result[i].backgroundColor = '#f0f0f0'
                        }

                        lastGroup.children.push(response.result[i])
                        this.flattenedEntries.push(response.result[i])
                        this.sumTotals(totals, response.result[i])
                    } else {
                        lastGroup = response.result[i]
                        lastGroup.children = [response.result[i]]
                        this.groups.push(lastGroup)

                        if (totals) {
                            isOdd = !isOdd
                            this.flattenedEntries.push({ uiEntryType: this.UIEntryType.totals, totals, backgroundColor: isOdd ? '#f0f0f0' : null })
                        }
                        totals = {}
                        this.initTotals(totals)

                        this.flattenedEntries.push({ uiEntryType: this.UIEntryType.title, group: response.result[i].reporting_group_name })
                        this.flattenedEntries.push({ uiEntryType: this.UIEntryType.comment, group: response.result[i].reporting_group })

                        if (!this.inputDataById[response.result[i].reporting_group]) {
                            this.inputDataById[response.result[i].reporting_group] = {
                                showInputContainer: { value: false }
                            }
                        }

                        this.flattenedEntries.push({ uiEntryType: this.UIEntryType.columnNames, group: response.result[i].reporting_group, backgroundColor: 'lightgrey' })
                        isOdd = true
                        response.result[i].isOdd = isOdd
                        response.result[i].backgroundColor = '#f0f0f0'
                        this.flattenedEntries.push(response.result[i])
                        this.sumTotals(totals, response.result[i])
                    }
                }
                if (totals) {
                    isOdd = !isOdd
                    this.flattenedEntries.push({ uiEntryType: this.UIEntryType.totals, totals, backgroundColor: isOdd ? '#f0f0f0' : null })
                }
                resolve(null)
            })
        })
    }

    getVoucherAllocationPromise() {
        return new Promise((resolve, reject) => {
            this.budgetService.getVoucherAllocation(this.submissionUuid).subscribe((response) => {
                //console.log("allocation response", response)
                if (response.success && response.result.length > 0) {
                    this.allocation = response.result[0]
                }
                resolve(null)
            })
        })
    }

    benefits
    getVoucherBenefitsPromise() {
        return new Promise((resolve, reject) => {
            this.budgetService.getBenefits().subscribe((response) => {
                //console.log("benefits response", response)
                if (response.success) {
                    this.benefits = response.result
                }
                resolve(null)
            })
        })
    }

    fetchDataPromise() {
        return Promise.all(
            [
                this.getVoucherSummaryPromise(),
                this.getVoucherAllocationPromise()
            ])
    }

    openOutBalanceDialog(data: any)
    {
        this.dialog.open(VoucherSubmissionBalanceOffDialog,
        {
            minWidth: `400px`,
            data: {submissions: data}
        })
    }

    userOrganizationType
    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                //console.log("org type", response)
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        Promise.all([
            this.paramPromise(),
            this.getVoucherBenefitsPromise(),
            this.getUserOrganizationType(),
        ]).then(() => {
            return Promise.all([
                this.getAddAccountOptionsPromise(),
                this.fetchDataPromise()
            ])
        }).then(() => {
            this.initialized = true
        })
    }

    ngOnDestroy() {
        for (var i = 0; i < this.subscriptions.length; ++i) {
            this.subscriptions[i].unsubscribe()
        }
    }

    sortClick(group, fieldName) {
        console.log("sort todo")
    }

    // addPersonnel() {
    //     this.router.navigate(["/budget-personnel"], { queryParams: { submissionUuid: this.submissionUuid } })
    // }

    // addOther() {
    //     this.router.navigate(["/budget-account"], { queryParams: { submissionUuid: this.submissionUuid, editable: true } })
    // }

    entryClick(entry) {
         this.router.navigate(["/voucher-edit"], { queryParams: { accountUuid: entry.uuid, submissionUuid: this.submissionUuid, editable: this.editable, showFlag: this.showFlag } })
    }

    checkBalance(): boolean
    {
        if (this.allocation.balance_state < 0 || this.allocation.balance_federal < 0)
        {
            this.isOffBalanced = true;
        }
        else
        {
            this.isOffBalanced = false;
        }
        return this.isOffBalanced
    }

    cancel() {
        this.snackRoute.snackRoute("Action cancelled", "/dashboard")
    }

    saveForLater() {
        this.voucherService.getOutBalance(this.submissionUuid).subscribe((response) => {
            console.log(response);
            if (response.success) {
                if (response.result.length === 0) 
                {
                    if (!this.checkBalance())
                    {
                        this.snackRoute.snackRoute("Saved for later", "/dashboard");
                    }
                    else
                    {
                        this.snackRoute.snack("Voucher amount cannot exceed available balance and cannot be pending budget or voucher.")
                    }
                } 
                else 
                {
                    this.openOutBalanceDialog(response.result);
                }
            }
            else
            {
                console.log("Request failed:", response.error);
            }
        })  
    }
    totalAdministrative
    fifteenPercentExceeded = false
    submit() {
        this.fifteenPercentExceeded = false

        this.voucherService.getOutBalance(this.submissionUuid).subscribe((response) => {
            console.log(response);
            if (response.success) {
                if (this.allocation.budget_pending == 'N' && this.allocation.voucher_pending == 'N' && response.result.length === 0) 
                {
                    if (!this.checkBalance())
                    {
                        this.router.navigate(["/submission-review"], {queryParams: { submissionUuid: this.submissionUuid }})
                    }
                    else
                    {
                        this.snackRoute.snack("Voucher amount cannot exceed available balance and cannot be pending budget or voucher.")
                    }

                    if(this.allocation.flag_outbalance == 'Y') {
                        this.openOutBalanceDialog(response.result)
                    }
                } 
                else 
                {
                    this.openOutBalanceDialog(response.result);
                }
            }
            else
            {
                console.log("Request failed:", response.error);
            }
        })   
    }
}

@Component({
    selector: 'voucher-submission-account-dialog',
    templateUrl: './voucher-submission-account-dialog.html',
})
export class VoucherSubmissionAccountDialog {
    faTimes = faTimes
    accountName

    constructor(
        public dialogRef: MatDialogRef<VoucherSubmissionAccountDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private budgetService: BudgetService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private submissionCommentService: SubmissionCommentService,
    ) {
        if (this.data.entryData) {
            var entry = this.data.entryData[0]
            this.accountName = entry.account_name
        }
    }
}

@Component({
    selector: 'voucher-submission-balance-off',
    templateUrl: './voucher-submission-balance-off-dialog.html',
    styleUrls: ['./voucher-submission.component.css']
})
export class VoucherSubmissionBalanceOffDialog {

    submissions: any 

    constructor(
        public dialogRef: MatDialogRef<VoucherSubmissionBalanceOffDialog>,
        @Inject(MAT_DIALOG_DATA) public data: {submissions: any},
        public dialog: MatDialog
    )
    {
        this.submissions = data.submissions
    }

    ngOnInit(): void
    {

        // console.log(this.submissions)
    }
}