import { AfterViewInit, Component, ElementRef, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faBan, faFilter, faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { InputDateFormat } from 'src/app/input-date/input-date.component';
import * as uuid from 'uuid';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, AfterViewInit {
    InputDateFormat = InputDateFormat
    @ViewChild('container', { read: ElementRef }) containerRef: ElementRef;
    @ViewChild('filtericon', { read: ElementRef }) iconRef: ElementRef;
    arrayChange$ = new BehaviorSubject(true)

    @Output() filterChange = this.arrayChange$.pipe(
        switchMap(() => {
            //console.log("filter changed")
            var lis = []
            for (var i = 0; i < this.matchControls.length; ++i) {
                lis.push(this.matchTypeFC.valueChanges.pipe(startWith(this.matchTypeFC.value)))
                lis.push(this.matchControls[i].operationFC.valueChanges.pipe(startWith(this.matchControls[i].operationFC.value)))
                lis.push(this.matchControls[i].valueFC.valueChanges.pipe(startWith(this.matchControls[i].valueFC.value)))
            }
            return combineLatest(...lis)
        }),
        map((...lis) => {
            if (!this.column) {
                return ""
            }
            var _ret = {
                where: "",
                whereArgs: []
            }
            if (!this.matchControls[0].valueFC.value) {
                return _ret
            }
            for (var i = 0; i < this.matchControls.length; ++i) {
                if (i != 0) {
                    if (this.matchTypeFC.value == 1) {
                        _ret.where += " and "
                    } else {
                        _ret.where += " or "
                    }
                }

                //console.log("op value", this.matchControls[i].operationFC.value)

                if (this.values) {
                    if (this.column.searchField) {
                        _ret.where += this.column.searchField
                    } else {
                        _ret.where += this.column.Field
                    }

                    //enum operations
                    switch (this.matchControls[i].operationFC.value) {
                        case 1:
                        case "1":
                            _ret.where += " = ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 2:
                        case "2":
                            _ret.where += " != ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        default:
                            throw new Error("unknown match type id " + this.matchControls[i].operationFC.value)
                    }
                } else if (this.column && this.column.Type && this.column.Type == "datetime") {
                    if (this.column.searchField) {
                        _ret.where += "DATE(" + this.column.searchField + ")"
                    } else {
                        _ret.where += "DATE(" + this.column.Field + ")"
                    }
                    // dateOperationOptions = [
                    //     { id: 1, name: "Is" },
                    //     { id: 2, name: "Is Not" },
                    //     { id: 3, name: "Is Before" },
                    //     { id: 4, name: "Is After" },
                    // ]
                    switch (this.matchControls[i].operationFC.value) {
                        case 1:
                        case "1":
                            _ret.where += " = DATE(?)"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 2:
                        case "2":
                            _ret.where += " != DATE(?)"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 3:
                        case "3":
                            _ret.where += " < DATE(?)"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 4:
                        case "4":
                            _ret.where += " > DATE(?)"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        default:
                            throw new Error("unknown match type id " + this.matchControls[i].operationFC.value)
                    }
                } else if (this.column && this.column.Type && (this.column.Type == "int" || this.column.Type.startsWith("decimal"))) {
                    if (this.column.searchField) {
                        _ret.where += this.column.searchField
                    } else {
                        _ret.where += this.column.Field
                    }
                    // numberOperationOptions = [
                    //     { id: 1, name: "Equals" },
                    //     { id: 2, name: "Does not equal" },
                    //     { id: 3, name: "Is less than" },
                    //     { id: 4, name: "Is less than or equal to" },
                    //     { id: 5, name: "Is greater than" },
                    //     { id: 6, name: "Is greater than or equal to" },
                    // ]
                    switch (this.matchControls[i].operationFC.value) {
                        case 1:
                        case "1":
                            _ret.where += " = ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 2:
                        case "2":
                            _ret.where += " != ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 3:
                        case "3":
                            _ret.where += " < ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 4:
                        case "4":
                            _ret.where += " <= ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 5:
                        case "5":
                            _ret.where += " > ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case 6:
                        case "6":
                            _ret.where += " >= ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        default:
                            throw new Error("unknown match type id " + this.matchControls[i].operationFC.value)
                    }

                } else {
                    if (this.column.searchField) {
                        _ret.where += this.column.searchField
                    } else {
                        _ret.where += this.column.Field
                    }

                    //string operations

                    // { id: 1, name: "Starts with" },
                    // { id: 2, name: "Contains" },
                    // { id: 3, name: "Does not contain" },
                    // { id: 4, name: "Ends with" },
                    // { id: 5, name: "Equals" },
                    // { id: 6, name: "Not equals" },

                    switch (this.matchControls[i].operationFC.value) {
                        case "1":
                        case 1:
                            _ret.where += " like ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value + "%")
                            break;
                        case "2":
                        case 2:
                            _ret.where += " like ?"
                            _ret.whereArgs.push("%" + this.matchControls[i].valueFC.value + "%")
                            break;
                        case "3":
                            _ret.where += " not like ?"
                            _ret.whereArgs.push("%" + this.matchControls[i].valueFC.value + "%")
                            break;
                        case "4":
                            _ret.where += " like ?"
                            _ret.whereArgs.push("%" + this.matchControls[i].valueFC.value)
                            break;
                        case "5":
                            _ret.where += " = ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        case "6":
                            _ret.where += " != ?"
                            _ret.whereArgs.push(this.matchControls[i].valueFC.value)
                            break;
                        default:
                            throw new Error("unknown operation id " + this.matchControls[i].operationFC.value)
                    }
                }
            }
            return _ret
        }),
        tap((_ret) => {
            //console.log("tapping", _ret)
        })
    )

    @Input() column
    @Input() closeAll
    @Input() values
    @Input() alignRight
    faFilter = faFilter
    faPlus = faPlus
    faMinus = faMinus
    faTimes = faTimes
    faBan = faBan
    showFilterInput = false
    containerUuid = uuid.v4();
    matchTypeOptions = [
        { id: 1, name: "Match All" },
        { id: 2, name: "Match Any" },
    ]
    enumMatchTypeOptions = [
        { id: 2, name: "Match Any" },
    ]
    matchTypeFC = new UntypedFormControl(1)

    stringOperationOptions = [
        { id: 1, name: "Starts with" },
        { id: 2, name: "Contains" },
        { id: 3, name: "Does not contain" },
        { id: 4, name: "Ends with" },
        { id: 5, name: "Equals" },
        { id: 6, name: "Not equals" },
    ]

    enumOperationOptions = [
        { id: 1, name: "Is" },
        { id: 2, name: "Is Not" },
    ]

    dateOperationOptions = [
        { id: 1, name: "Is" },
        { id: 2, name: "Is Not" },
        { id: 3, name: "Is Before" },
        { id: 4, name: "Is After" },
    ]

    numberOperationOptions = [
        { id: 1, name: "Equals" },
        { id: 2, name: "Does not equal" },
        { id: 3, name: "Is less than" },
        { id: 4, name: "Is less than or equal to" },
        { id: 5, name: "Is greater than" },
        { id: 6, name: "Is greater than or equal to" },
    ]

    matchControls = [{
        operationFC: new UntypedFormControl(1),
        valueFC: new UntypedFormControl(),
    }]

    addFilter(index) {
        if (this.values) {
            this.matchTypeFC.setValue(2)
        }
        this.matchControls.splice(index + 1, 0, {
            operationFC: new UntypedFormControl(1),
            valueFC: new UntypedFormControl(),
        })
        this.arrayChange$.next(true)
    }

    removeFilter(index) {
        this.matchControls.splice(index, 1)
        this.arrayChange$.next(true)
    }

    constructor(
        private renderer: Renderer2,
    ) {
    }

    ngOnInit() {
        //console.log("filter init col", this.column, this.values)
    }

    setPopupAlignment() {
        var containerWidth = this.containerRef.nativeElement.offsetWidth
        var iconRect = this.iconRef.nativeElement.getBoundingClientRect()
        var filterXPos = iconRect.left
        var windowWidth = document.body.clientWidth
        if (filterXPos + 24 + containerWidth > windowWidth) {
            this.alignRight = true
        } else {
            this.alignRight = false
        }
    }

    ngAfterViewInit() {
        this.iconRef.nativeElement.addEventListener("click", function (e) {
            e.stopPropagation()
        })
    }

    clickDocumentEventListener

    /**
     * the following is not best practice, but i could not find a better way to deal with the observable case
     * trying to use viewchild, the element wasn't present
     */
    setEventListeners() {
        var elem = document.getElementById(this.containerUuid)
        if (!elem) {
            return false
        }
        elem.addEventListener("click", function (e) {
            e.stopPropagation()
        })

        this.clickDocumentEventListener = this.closeFilterInput.bind(this)
        document.addEventListener("click", this.clickDocumentEventListener);
        return true
    }

    setEventListenersAndIReallyMeanIt() {
        if (!this.setEventListeners()) {
            setTimeout(() => {
                this.setEventListenersAndIReallyMeanIt()
            }, 5)
        } else {
            this.setPopupAlignment()
            this.containerRef.nativeElement.style.opacity = "1"
        }
    }

    openFilterInput() {
        if (!this.showFilterInput) {
            this.closeAll()
        }


        this.showFilterInput = !this.showFilterInput
        if (this.showFilterInput) {
            this.clickDocumentEventListener = this.closeFilterInput.bind(this)
            this.setEventListenersAndIReallyMeanIt()
        }
    }

    closeFilterInput() {
        //console.log("close")
        if (this.clickDocumentEventListener) {
            document.removeEventListener("click", this.clickDocumentEventListener);
            this.clickDocumentEventListener = null
        }
        this.showFilterInput = false
    }

    clear() {
        this.matchControls = [{
            operationFC: new UntypedFormControl(1),
            valueFC: new UntypedFormControl(),
        }]
        this.arrayChange$.next(true)
    }

}
