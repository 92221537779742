<div *ngIf="initialized" class="container-fluid">

    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
            <span *ngIf="groupId != null">
                New
            </span>
            <span *ngIf="groupId == null">
                Edit
            </span>
            Question
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>

    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <app-input-text label="Step" [fc]="stepFC" disabled="true">
            </app-input-text>
            <app-input-text label="Group #" [fc]="groupNumberFC" disabled="true">
            </app-input-text>
            <app-input-text label="Group Name" [fc]="groupNameFC" disabled="true">
            </app-input-text>
            <app-input-text label="Question #" [fc]="questionNumberFC" [maxlength]="6">
            </app-input-text>

            <app-input-text-area label="Question" [fc]="questionFC" [maxlength]="255">
            </app-input-text-area>
            <app-input-text-area label="Help HTML" [fc]="helpHtmlFC" [maxlength]="4000">
            </app-input-text-area>
            <app-input-text-area label="Popup HTML" [fc]="popupHtmlFC" [maxlength]="4000">
            </app-input-text-area>

            <div class="row edit-height">
                <div class="col-4 unedit-padding bold">
                    Active
                </div>
                <div class="col-8">
                    <app-checkbox [fc]="activeFC"></app-checkbox>
                </div>
            </div>

            <div class="row edit-height">
                <div class="col-4 unedit-padding bold">
                    Required
                </div>
                <div class="col-8">
                    <app-checkbox [fc]="requiredFC"></app-checkbox>
                </div>
            </div>

            <div class="row edit-height">
                <div class="col-4 unedit-padding bold">
                    Allow Duplicate
                </div>
                <div class="col-8">
                    <app-checkbox [fc]="allowDuplicateFC"></app-checkbox>
                </div>
            </div>

            <div class="row edit-height">
                <div class="col-4 unedit-padding bold">
                    Exclude from External Report
                </div>
                <div class="col-8">
                    <app-checkbox [fc]="excludeExternalFC"></app-checkbox>
                </div>
            </div>

            <app-input-enum label="Answer Type" [fc]="answerTypeFC" [enumValuesNameAndId]="answerDatatypes"
                [style]="'radio-style'">
            </app-input-enum>

            <button mat-raised-button style="margin-bottom: 5px;" color="primary" *ngIf="answerTypeFC.value == 'answer_special'" (click)="openDialog(false)">Add Answer</button>
            <div class="table-container">
            <table mat-table [dataSource]="answerSet" class="mat-elevation-z8" *ngIf="answerTypeFC.value == 'answer_special'">

                <ng-container matColumnDef="display">
                  <th mat-header-cell *matHeaderCellDef> Display Order </th>
                  <td mat-cell *matCellDef="let answer">{{ answer.display }}</td>
                </ng-container>
          
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Answers </th>
                  <td mat-cell *matCellDef="let answer" class="name" (click)="openDialog(true, answer)">{{ answer.name }}</td>
                </ng-container>

          
                <tr mat-header-row *matHeaderRowDef="['display', 'name']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['display', 'name'];"></tr>
            </table>
            </div>

            <div *ngIf="error" class="row">
                <div class="col" align="center" style="color: red;">
                    {{error}}
                </div>
            </div>

            <div class="row mt-3">
                <div class="col" align="center">
                    <app-button3 *ngIf="questionId" class="mx-1" label="Delete" [clickFunction]="delete.bind(this)"
                        [icon]="faMinus" [isDelete]="true">
                    </app-button3>
                    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                    </app-button3>
                    <app-button3 class="mx-1" label="Save" [clickFunction]="save.bind(this)" [icon]="faSave"
                        [isFinal]="true">
                    </app-button3>
                </div>
            </div>
        </div>
    </div>
</div>