import { Component, OnInit, ViewChild } from '@angular/core';
import { BudgetService } from '../services/budget.service';
import { ActivatedRoute } from '@angular/router';
import { iTrendReportDetail } from '../model/trend-report-detail.model';
import { iTrendReportOrganization } from '../model/trend-report-organization.model';
import { SessionService } from '../session.service';
import { switchMap } from 'rxjs';
import { iTrendReportQtr } from '../model/trend-report-qtr.model';
import { TrendReportMonthlyTableComponent } from '../trend-report-monthly-table/trend-report-monthly-table.component';
import { TrendReportQuarterlyTableComponent } from '../trend-report-quarterly-table/trend-report-quarterly-table.component';

@Component({
  selector: 'app-trend-report',
  templateUrl: './trend-report.component.html',
  styleUrl: './trend-report.component.css'
})
export class TrendReportComponent implements OnInit
{
  organizationUuid: string;
  fiscalYearId: string;
  organizationName: string;
  fiscalYear: string;
  stateAmount: number;
  federalAmount: number;
  trendReportHead: iTrendReportOrganization[];
  trendReportDetails: iTrendReportDetail[];
  trendReportQtr: iTrendReportQtr[];
  tableOptions: any[] = [{ label: 'Monthly', value: 'monthly' },{ label: 'Quarterly', value: 'quarterly' }];
  defaultTable: string = 'monthly'
  @ViewChild(TrendReportMonthlyTableComponent) monthlyTable: TrendReportMonthlyTableComponent;
  @ViewChild(TrendReportQuarterlyTableComponent) quarterlyTable: TrendReportQuarterlyTableComponent;

  constructor
  (
    private budgetService: BudgetService,
    private sessionService: SessionService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() 
  {
    this.route.queryParams.subscribe(params => {
      this.organizationUuid = params['organizationUuid']
    })

    this.fiscalYearId = this.sessionService.getFiscalYear();

    this.budgetService.postTrendData(this.organizationUuid, this.fiscalYearId)
    .pipe(
      switchMap(() => {
        return this.budgetService.getTrendReportHead(this.organizationUuid, this.fiscalYearId);
      }),
      switchMap((trendHead) => {
        this.organizationName = trendHead[0].organizationName;
        this.fiscalYear = trendHead[0].fiscalYear;
        this.stateAmount = trendHead[0].state;
        this.federalAmount = trendHead[0].federal;
        
        return this.budgetService.getTrendReportDetial(this.organizationUuid);
      }),
      switchMap((trendDetail) => {
        this.trendReportDetails = trendDetail.sort((a, b) => {
          if (a.sortOrder !== b.sortOrder)
          {
            return a.sortOrder - b.sortOrder;
          }
          const fundingTypeOrder = ['State/Fed', 'State Only'];
          return fundingTypeOrder.indexOf(a.fundingType) - fundingTypeOrder.indexOf(b.fundingType);
        });

        return this.budgetService.getTrendReportQtr(this.organizationUuid);
      })
    ).subscribe({
      next: (trendQtr) => {
        this.trendReportQtr = trendQtr.sort((a, b) => {
          if (a.sortOrder !== b.sortOrder)
          {
            return a.sortOrder - b.sortOrder;
          }
          const fundingTypeOrder = ['State/Fed', 'State Only'];
          return fundingTypeOrder.indexOf(a.fundingType) - fundingTypeOrder.indexOf(b.fundingType);
        });
      },
      error: (error) => {
        console.error('An error occurred: ', error);
      }
    });
  }

  exportTableData(): void {
    if (this.defaultTable === 'monthly')
    {
      this.monthlyTable?.exportToCSV();
    } else if (this.defaultTable === 'quarterly')
    {
      this.quarterlyTable?.exportToCSV();
    }
  }
}
