<div *ngIf="initialized" class="container-fluid">
    <div class="row mb-2">
        <div class="col page-title-no-font">
            <span class="page-title-font">
                <fa-icon [icon]="faEye"></fa-icon>
                Review Submission
            </span>
        </div>
    </div>

    <div *ngIf="submission.submission_type_id == 'NOEP_BUDGET'" class="row">
        <div class="col px-0 text-right">
            <app-button3 
                label="Print Summary"
                [clickFunction]="print.bind(this)"
                [icon]="faPrint"
                [small]="true">
            </app-button3>
            <app-button3 
                label="Print Detail"
                [clickFunction]="printDetail.bind(this)"
                [icon]="faPrint"
                [small]="true"
                class="ml-2">
            </app-button3>
        </div>
    </div>

    <div *ngIf="submission.submission_type_id == 'NOEP_VOUCHER'" class="row">
        <div class="col px-0 text-right">
            <app-button3 
                label="Print Summary"
                routerLink="/voucher-report"
                [queryParams]="{submissionUuid: submissionUuid}"
                [icon]="faPrint"
                [small]="true"
                style="padding: 10px;">
            </app-button3>
            <app-button3 
                label="Print Detail"
                (click)="printVoucherDetail()"
                [icon]="faPrint"
                [small]="true"
                class="ml-2">
            </app-button3>
        </div>
    </div>
    
    <app-submission-header [submission]="submission" [statusesById]="submissionStatuses$ | async" 
    [hideRecertificationDate]="submission.submission_type_id == 'NOEP_BUDGET'">
    </app-submission-header>

    <div class="row py-2" *ngIf="submission.submission_type_id == 'NOEP_PROGRAM'">
        <div class="col px-0 text-right" style="margin-right: 275px;">
            <!--<button mat-raised-button 
            style="background-color: green; color: white;"
            routerLink="/agency-main-target">
            Main Target Report</button> -->
            <app-button3 
                class="mx-1" 
                label="Main Target Report" 
                [routerLink]="['/agency-main-target']"
                [queryParams]="{uuid: submissionUuid}" 
                [isFinal]="true">
            </app-button3>
        </div>
    </div>

    <!-- repeated below -->
    <div *ngIf="saveError" class="row">
        <div class="col" align="center">
            {{saveError}}
        </div>
    </div>

    <div *ngIf="showOkayButton" class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="OK" [clickFunction]="ok.bind(this)" [icon]="faCheck">
            </app-button3>
        </div>
    </div>

    <div *ngIf="showCancelAndSubmitButtons" class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="Previous" [clickFunction]="cancel.bind(this)" [icon]="faArrowLeft">
            </app-button3>
            <app-button3 class="mx-1"
                [label]="submission.submission_type_id == 'NOEP_BUDGET' ? 'Submit to HSNY' : 'Submit'"
                [clickFunction]="submit.bind(this)" [icon]="faSave" [isFinal]="true">
            </app-button3>
            <div *ngIf="submission.submission_type_id == 'NOEP_VOUCHER'" style="font-size: smaller;">Submitting a voucher will disable budget amendments until the voucher is approved</div>
        </div>
    </div>
    <!-- end repeated below -->

    <app-budget-submission *ngIf="submission.submission_type_id == 'NOEP_BUDGET' && !showFlag" [showFlag]="showPrimeButtons"
        [editable]="false">
    </app-budget-submission>

    <app-budget-submission *ngIf="submission.submission_type_id == 'NOEP_BUDGET' && showFlag" [showFlag]="showFlag"
    [editable]="false">
    </app-budget-submission>


    <app-submission-attachment-controls *ngIf="showAddAttachmentControls" [submissionUuid]="submissionUuid">
    </app-submission-attachment-controls>

    <app-submission-attachments [submissionUuid]="submissionUuid"></app-submission-attachments>
    <!--
    <app-submission-activity-feed [submissionActivityFeed]="submissionActivityFeed$ | async">
    </app-submission-activity-feed>

    <app-submission-history [submissionHistory]="submissionHistory$ | async"></app-submission-history>
    -->
    <!-- <app-program-submission *ngIf="submission.submission_type_id == 'NOEP_PROGRAM'" [submissionUuid]="submissionUuid"
        [showFlag]="showPrimeButtons" [editable]="false">
    </app-program-submission> -->

    <app-program-submission 
        *ngIf="submission.submission_type_id == 'NOEP_PROGRAM' && stepUuid;else no_stepuuid"
        [showFlag]="showPrimeButtons"
        [submissionUuid]="submissionUuid"
        [stepUuid]="stepUuid"
        [editable]="false">
    </app-program-submission>

    <ng-template #no_stepuuid>
        <app-program-submission
            *ngIf="submission.submission_type_id == 'NOEP_PROGRAM'"
            [showFlag]="showPrimeButtons"
            [submissionUuid]="submissionUuid"
            [editable]="false">
        </app-program-submission>
    </ng-template>

    <app-outreach-submission *ngIf="submission.submission_type_id == 'NOEP_OUTREACH'" [showFlag]="showPrimeButtons"
        [editable]="false">
    </app-outreach-submission>

    <app-voucher-submission *ngIf="submission.submission_type_id == 'NOEP_VOUCHER'" [showFlag]="showPrimeButtons"
        [editable]="false">
    </app-voucher-submission>

    <span *ngIf="showPrimeButtons">
        <div class="row mt-3" *ngIf="!stepUuid">
            <div class="col" style="font-size: 1.2em;">
                <fa-icon [icon]="faThumbsUp" class="mr-2"></fa-icon>
                Submission Approval
            </div>
        </div>

        <div class="row mt-3" *ngIf="stepUuid && stepUuid == 'NOEP_DATA_OVERCOME_BAR'">
            <div class="col" style="font-size: 1.2em;">
                <fa-icon [icon]="faThumbsUp" class="mr-2"></fa-icon>
                Submission Approval
            </div>
        </div>

        <div class="row" style="background-color: lightgrey;">
            <div class="col">
                &nbsp;
            </div>
        </div>

        <div class="row mt-2" *ngIf="!stepUuid">
            <div class="col-6">
                <input type="radio" id="approval_approve" name="approval" value="approve" [formControl]="approvalFC"
                    (change)="approvalChange($event)" />
                <label class="ml-3" for="approval_approve">Approve</label>
            </div>
            <div class="col-6">
                <input type="radio" id="approval_request" name="approval" value="request" [formControl]="approvalFC"
                    (change)="approvalChange($event)" />
                <label class="ml-3" for="approval_request">Request Additional Information</label>
            </div>
        </div>

        <div class="row mt-2" *ngIf="stepUuid && stepUuid == 'NOEP_DATA_OVERCOME_BAR'">
            <div class="col-6">
                <input type="radio" id="approval_approve" name="approval" value="approve" [formControl]="approvalFC"
                    (change)="approvalChange($event)" />
                <label class="ml-3" for="approval_approve">Approve</label>
            </div>
            <div class="col-6">
                <input type="radio" id="approval_request" name="approval" value="request" [formControl]="approvalFC"
                    (change)="approvalChange($event)" />
                <label class="ml-3" for="approval_request">Request Additional Information</label>
            </div>
        </div>

        


        <div class="row" *ngIf="!stepUuid">
            <div class="col-6">
                <app-input-text-area label="Approval Notes" [fc]="approvalNotesFC" [maxlength]="255">
                </app-input-text-area>
            </div>
            <div class="col-6">
                <app-input-text-area label="Request Information Notes" [fc]="requestInformationNotesFC"
                    [maxlength]="255">
                </app-input-text-area>
            </div>
        </div>

        <div class="row" *ngIf="stepUuid && stepUuid == 'NOEP_DATA_OVERCOME_BAR'">
            <div class="col-6">
                <app-input-text-area label="Approval Notes" [fc]="approvalNotesFC" [maxlength]="255">
                </app-input-text-area>
            </div>
            <div class="col-6">
                <app-input-text-area label="Request Information Notes" [fc]="requestInformationNotesFC"
                    [maxlength]="255">
                </app-input-text-area>
            </div>
        </div>

    </span>

    <div *ngIf="saveError" class="row">
        <div class="col" align="center">
            {{saveError}}
        </div>
    </div>

    <div *ngIf="showPrimeButtons" class="row mt-2">
        <div class="col" align="center" *ngIf="!stepUuid">
            <app-button3 class="mx-1" label="Previous" [clickFunction]="primeCancel.bind(this)" [icon]="faArrowLeft">
            </app-button3>
            <app-button3 class="mx-1" label="Finish" [clickFunction]="primeFinish.bind(this)" [icon]="faFlagCheckered"
                [isFinal]="true">
            </app-button3>
        </div>

        <div class="col" align="center" *ngIf="stepUuid">
            <app-button3
                *ngIf="stepUuid"
                class="mx-1"
                label="Previous"
                [clickFunction]="primeProgramCancel.bind(this)"
                [icon]="faArrowLeft">
            </app-button3>

            <app-button3
                *ngIf="stepUuid && stepUuid == 'NOEP_DATA_OVERCOME_BAR'"
                class="mx-1"
                label="Finish"
                [clickFunction]="primeFinish.bind(this)"
                [icon]="faFlagCheckered"
                [isFinal]="true">
            </app-button3>
        </div>
<!-- 
        <div class="col" align="center" *ngIf="!stepUuid">
            <app-button3
                class="mx-1"
                label="Previous"
                [clickFunction]="primeProgramCancel.bind(this)"
                [icon]="faArrowLeft">
            </app-button3>

            <app-button3
                class="mx-1"
                label="Finish"
                [clickFunction]="primeFinish.bind(this)"
                [icon]="faFlagCheckered"
                [isFinal]="true">
            </app-button3>
        </div> -->

    </div>

    <!-- repeated above -->
    <div *ngIf="showOkayButton" class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="OK" [clickFunction]="ok.bind(this)" [icon]="faCheck">
            </app-button3>
        </div>
    </div>

    <div *ngIf="showCancelAndSubmitButtons" class="row mt-3">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="Previous" [clickFunction]="cancel.bind(this)" [icon]="faBan">
            </app-button3>
            <app-button3 class="mx-1"
                [label]="submission.submission_type_id == 'NOEP_BUDGET' ? 'Submit to HSNY' : 'Submit'"
                [clickFunction]="submit.bind(this)" [icon]="faSave" [isFinal]="true">
            </app-button3>
        </div>
    </div>

    <app-submission-activity-feed [submissionActivityFeed]="submissionActivityFeed$ | async">
    </app-submission-activity-feed>

    <app-submission-history [submissionHistory]="submissionHistory$ | async"></app-submission-history>

    <!-- end repeated above -->

</div>