import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from "rxjs"
import { MAIN_TARGET_ENTITIES_OB } from './mock/main-target-report';
import { MainTargetReport } from './model/main-target-report.model';
import { MainTargetReportView } from './model/main-target-report-view.model';
import { MAIN_TARGET_YTD_OB } from './mock/main-target-ytd-report';
import { MainTargetYtdReport } from './model/main-target-ytd-report.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class MainTargetService {

  constructor(
    private http: HttpClient
  ) { }

  setMainTargetModelProperty(mtReportModel: MainTargetReportView, mtReportEntity: MainTargetReport) {
    switch(mtReportEntity.columnId) {
      //case "description": mtReportModel.description = mtReportEntity.value; break; // removed in model update
      case "Q0": mtReportModel.q0 = mtReportEntity.value; break;
      case "Q1": mtReportModel.q1 = mtReportEntity.value; break;
      case "Q2": mtReportModel.q2 = mtReportEntity.value; break;
      case "Q3": mtReportModel.q3 = mtReportEntity.value; break;
      case "Q4": mtReportModel.q4 = mtReportEntity.value; break;
      default: // do nothing
    }
  }

  convertEntityToModel(entities: MainTargetReport[]): MainTargetReportView[] {
    let mtModels: MainTargetReportView[] = []
    let mtModelMap = new Map<any, MainTargetReportView>()

    entities.forEach(entity => {
      let mtModel = mtModelMap.get(entity.rowId)

      console.log(entity)
      if(mtModel) {
        this.setMainTargetModelProperty(mtModel, entity)
      } else {
        mtModel = new MainTargetReportView(entity.uuid, entity.rowId, entity.description)
        this.setMainTargetModelProperty(mtModel, entity)
      }
      mtModelMap.set(entity.rowId, mtModel)
    })

    mtModelMap.forEach(element => {
      mtModels.push(element)
    })


    return mtModels
  }

  getMainTargetHeader(uuid: string): Observable<any> {
    return this.http.get<any>('api/mainTargetHeader/' + uuid)
  }

  getMainTargetYtdReport(uuid: string): Observable<any> {
    // return MAIN_TARGET_YTD_OB // mock data ; note: when using the mock data, the Observable type can become MainTargetReport[] instead of any type

    return this.http.get<any>('api/mainTargetAchieved/' + uuid)
  }

  getMainTargetReport(uuid: string): Observable<any> {
     //return MAIN_TARGET_ENTITIES_OB // mock data

    return this.http.get<any>('api/mainTargetReport/' + uuid)
  }


  mainTargetEntity(fiscalYear: string): Observable<any> {
    return this.http.get<any>('api/mainTargetEntity/' + fiscalYear)
  }

  mainTargetEntityUpdate(
    agency_uuid: string,
    agency_name: string,
    county_name: string,
    county_id: string,
    main_target: number,
    outreach_target: number,
    person_target: number,
    prescreen_target: number,
    recertification_target: number,
    barrier_target: number,
    collaboration_target: number,
  ): Observable<any> {
    return this.http.put<any>('api/mainTargetEntityUpdate', {
      agency_uuid,
      agency_name,
      county_name,
      county_id,
      main_target, 
      outreach_target,
      person_target,
      prescreen_target,
      recertification_target,
      barrier_target,
      collaboration_target
    }, httpOptions)
  }
}
