<div *ngIf="initialized" class="container-fluid">

    <div class="row page-title-no-font mb-2">
        <div class="col">
            <span class="page-title-font">
                <fa-icon [icon]="faDatabase"></fa-icon>
                Database Administration
            </span>
        </div>
    </div>

    <app-input-enum label="Type" [fc]="typeFC" [enumValuesNameAndId]="typesNameAndId"
        includeUnsetDisplay="Select a type" [change]="typeChange.bind(this)">
    </app-input-enum>

    <span *ngIf="typeFC.value">
        <div class="row">
            <div class="col-6">
                <h2 style="display: inline-block;" data-cy="program_header">{{typeFC.value | dbtitlecase | titlecase}}
                </h2>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col">
                        Entries Per Page&nbsp;
                        <app-input-enum label="Entries Per Page" [fc]="entriesPerPageFC" [style]="'none'"
                            [class]="'inline-block'" [enumValuesNameAndId]="entriesPerPageOptions"
                            [change]="entriesPerPageChange.bind(this)">
                        </app-input-enum>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <app-button3 *ngIf="showNewButton()" style="float: right;" class="mx-1" label="New"
                    [clickFunction]="new.bind(this)" [icon]="faPlus" [small]="true">
                </app-button3>
            </div>
        </div>

        <app-page-controls [pageNumber]="currentPage" [pageSize]="entriesPerPageFC.value" [entryCount]="count"
            [setPage]="this.setPage.bind(this)">
        </app-page-controls>

        <div class="row mt-3">
            <div class="col px-0">
                <table *ngIf="types[typeFC.value]" class="table table-striped table-hover" style="min-width: 100%">
                    <thead>
                        <tr style="background-color: lightgrey">
                            <th class="px-1" *ngFor="let column of types[typeFC.value].definition; let index = index">
                                <span class="sort-column-header" (click)="sortClick(column.Field)">
                                    {{column.Field | dbtitlecase | titlecase}}
                                </span>
                                <app-sort-indicator class="ml-1" [sortFieldName]="sortField"
                                    [sortIsDescending]="sortIsDescending" [columnFieldName]="column.Field">
                                </app-sort-indicator>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="table-row" *ngFor="let entry of entries">
                            <td class="px-1" *ngFor="let column of types[typeFC.value].definition; let index = index"
                                [ngStyle]="{'text-align': column.align }">
                                <span class="link" *ngIf="index == 0" (click)="entryClick(entry)">
                                    <span *ngIf="column.Type == 'datetime'">
                                        {{entry[column.Field] | date : 'medium'}}
                                    </span>
                                    <span *ngIf="column.Type != 'datetime'">
                                        {{entry[column.Field]}}
                                    </span>
                                </span>
                                <span *ngIf="index != 0">
                                    <span *ngIf="column.Type == 'datetime'">
                                        {{entry[column.Field] | date : 'medium'}}
                                    </span>
                                    <span *ngIf="column.Type != 'datetime'">
                                        {{entry[column.Field]}}
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <app-page-controls [pageNumber]="currentPage" [pageSize]="entriesPerPageFC.value" [entryCount]="count"
            [setPage]="this.setPage.bind(this)" [isFooter]="true">
        </app-page-controls>

        <hr />
        <div *ngIf="types[typeFC.value]" class="row">
            <div class="col">
                <span
                    style="font-family: 'Courier New', Courier, monospace; white-space: pre-wrap;">{{types[typeFC.value].create_table
                    | createTable}}
                </span>
            </div>
        </div>
    </span>
</div>