import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faBan, faEdit, faHandsHelping, faSave } from '@fortawesome/free-solid-svg-icons';
import { InputDateFormat } from '../input-date/input-date.component';
import { OutreachService } from '../outreach.service';
import { SnackRouteService } from '../snack-route.service';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'app-outreach-approval',
    templateUrl: './outreach-approval.component.html',
})
export class OutreachApprovalComponent implements OnInit {
    InputDateFormat = InputDateFormat
    faHandsHelping = faHandsHelping
    programs
    campaigns
    countiesByProgramId = {}
    initialized = false
    programFC = new UntypedFormControl()
    sloganFC = new UntypedFormControl()
    countyFC = new UntypedFormControl()
    referenceNumberFC = new UntypedFormControl()
    campaignsByCounty = {}
    campaignFC = new UntypedFormControl()
    sequence

    faBan = faBan
    faSave = faSave
    saveError

    constructor(
        private outreachService: OutreachService,
        private snackRouteService: SnackRouteService,
    ) { }

    ngOnInit() {
        this.referenceNumberFC.disable()
        Promise.all([
            this.outreachService.getOutreachProgramsPromise().then((result) => {
                this.programs = result
                console.log("programs", this.programs)
            }),
            this.outreachService.getOutreachCountiesPromise().then((result: any) => {
                console.log("counties", result)
                for (var i = 0; i < result.length; ++i) {
                    if (!this.countiesByProgramId[result[i].fiscal_uuid]) {
                        this.countiesByProgramId[result[i].fiscal_uuid] = []
                    }
                    this.countiesByProgramId[result[i].fiscal_uuid].push({ id: result[i].id, name: result[i].name })
                }
            }),
            this.outreachService.getOutreachCampaigns().then((result: any) => {
                console.log("campaigns", result)
                for (var i = 0; i < result.length; ++i) {
                    if (!this.campaignsByCounty[result[i].jurisdiction_id]) {
                        this.campaignsByCounty[result[i].jurisdiction_id] = []
                    }
                    result[i].id = uuid()
                    this.campaignsByCounty[result[i].jurisdiction_id].push({
                        id: result[i].id,
                        name: result[i].name, 
                        display_name: result[i].display_name,
                        next_reference_number: result[i].next_reference_number,
                        next_sequence: result[i].next_sequence,
                        fiscal_uuid: result[i].fiscal_uuid
                    })
                    
                }
            })
        ]).then(() => {
            this.initialized = true
        })
    }

    cancel() {
        history.back()
    }

    fiscal_id: string
    campaignChange() {
        console.log(this.campaignsByCounty[this.countyFC.value])
        if (this.campaignsByCounty[this.countyFC.value]) {
            for (var i = 0; i < this.campaignsByCounty[this.countyFC.value].length; ++i) {
                if (this.campaignFC.value == this.campaignsByCounty[this.countyFC.value][i].id) {
                    this.sloganFC.setValue(this.campaignsByCounty[this.countyFC.value][i].name)
                    this.referenceNumberFC.setValue(this.campaignsByCounty[this.countyFC.value][i].next_reference_number)
                    this.sequence = this.campaignsByCounty[this.countyFC.value][i].next_sequence
                    this.fiscal_id = this.campaignsByCounty[this.countyFC.value][i].fiscal_uuid
                    break;
                }
            }
        }
        this.inputChange()
    }

    inputChange() {
        if (this.doValidation) {
            this.validate()
        }
    }

    doValidation
    programValidationError
    sloganValidationError
    dueDateValidationError
    countyValidationError
    referenceNumberValidationError
    campaignValidationError
    validate() {
        this.doValidation = true//after the first press of "submit", fields will validate on the fly
        this.saveError = null

        this.programValidationError = null
        this.sloganValidationError = null
        this.dueDateValidationError = null
        this.countyValidationError = null
        this.campaignValidationError = null
        this.referenceNumberValidationError = null

        if (!this.programFC.value) {
            this.programValidationError = "Please specify a program."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (!this.sloganFC.value) {
            this.sloganValidationError = "Please specify a slogan."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (!this.countyFC.value) {
            this.countyValidationError = "Please specify a county."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (!this.campaignFC.value) {
            this.campaignValidationError = "Please specify a campaign."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        return !!this.saveError
    }

    submit() {
        if (this.validate()) {
            return
        }
        //call submit new campaign on outreach service
        console.log(this.programFC.value, this.fiscal_id)
        this.outreachService.newCampaignPromise(
            this.fiscal_id,
            this.sloganFC.value,
            this.countyFC.value,
            this.referenceNumberFC.value,
            this.sequence,
        ).then(() => {
            this.snackRouteService.snackRoute("Outreach Campaign Created.", "/dashboard")
        }).catch((err) => {
            this.saveError = "Submission error"
            if (err) {
                if (err.message) {
                    this.saveError += ": " + err.message
                } else {
                    this.saveError += ": " + JSON.stringify(err, null, 4)
                }
            }
        })
    }
}
