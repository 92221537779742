import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { 
    faQuestionCircle, 
    faSave, 
    faBan, 
    faChevronLeft, 
    faFlag, 
    faTimes, 
    faCheck, 
    faExclamationTriangle, 
    faPlus, 
    faRedoAlt, 
    faMinus 
} from '@fortawesome/free-solid-svg-icons';
import { InputDateFormat } from '../input-date/input-date.component';
import { SnackRouteService } from '../snack-route.service';
import { SubmissionAnswersService } from '../submission-answers.service';
import { SubmissionCommentService } from '../submission-comment.service';
import { SubmissionStepService } from '../submission-step.service';
import { SubmissionService } from '../submission.service';

@Component({
    selector: 'app-program-submission',
    templateUrl: './program-submission.component.html',
    styleUrls: ['./program-submission.component.scss']
})
export class ProgramSubmissionComponent implements OnInit {
    InputDateFormat = InputDateFormat
    @Input() submissionUuid
    @Input() stepUuid
    @Input() showFlag: boolean
    @Input() editable: boolean
    faPlus = faPlus
    faRedo = faRedoAlt
    faMinus = faMinus

    constructor(
        private submissionService: SubmissionService,
        private _submissionStepService: SubmissionStepService,
        private submissionAnswersService: SubmissionAnswersService,
        private router: Router,
        private snackRouteService: SnackRouteService,
        private activatedRoute: ActivatedRoute,
        private submissionCommentService: SubmissionCommentService,
    ) { }

    get submissionStepService() {
        return this._submissionStepService
    }
    initialized = false
    questionData

    faFlag = faFlag
    faTimes = faTimes
    faCheck = faCheck
    faExclamationTriangle = faExclamationTriangle

    faQuestionCircle = faQuestionCircle
    faSave = faSave
    faBan = faBan
    faChevronLeft = faChevronLeft
    program
    organization
    status
    submissionName
    dueDate
    county
    stepName
    stepInstructions

    saveError
    questionGroups
    getAnswersSubscription
    submissionStatusId
    add_sequence_number: number
    answer_group_uuid: string


    getSubmissionQuestionGroupAnswersPromise() {
        return new Promise((resolve, reject) => {
            this.getAnswersSubscription = this.submissionStepService.getSubmissionQuestionGroupAnswers(
                this.editable,
                this.submissionUuid,
                this.stepUuid).subscribe((response) => {
                    this.getAnswersSubscription.unsubscribe()
                    this.questionData = response.result
                    if (this.questionData && this.questionData.length > 0) {
                        this.submissionStatusId = this.questionData[0].status_id
                        this.program = this.questionData[0].program_name
                        this.organization = this.questionData[0].organization_name
                        this.status = this.questionData[0].status_name
                        this.submissionName = this.questionData[0].submission_name
                        this.dueDate = this.questionData[0].due_date
                        this.county = this.questionData[0].jurisdiction_name
                        this.stepName = this.questionData[0].step_name
                        this.stepInstructions = this.questionData[0].step_instructions


                        var lastQuestionId
                        var lastGroup
                        this.questionGroups = []

                        for (var i = 0; i < this.questionData.length; ++i) {
                            this.questionData[i].fc = new UntypedFormControl()
                            this.questionData[i].fc2 = new UntypedFormControl()

                            //set fc and fc2 values based on answer type
                            switch (this.questionData[i].answer_datatype) {
                                case "answer_number_and_text":
                                    this.questionData[i].fc = new UntypedFormControl(this.questionData[i].answer_number)
                                    this.questionData[i].fc2 = new UntypedFormControl(this.questionData[i].answer_text)
                                    break;
                                case "answer_text":
                                    this.questionData[i].fc = new UntypedFormControl(this.questionData[i].answer_text)
                                    break;
                                case "answer_comment":
                                    this.questionData[i].fc = new UntypedFormControl(this.questionData[i].answer_comment)
                                    break;
                                case "answer_date":
                                    var dateVal
                                    
                                    if(this.questionData[i].answer_data == null) {
                                        dateVal = this.questionData[i].answer_data
                                    }

                                    if (this.questionData[i].answer_date) {
                                        dateVal = formatDate(this.questionData[i].answer_date, 'yyyy-MM-dd', 'en')
                                    }

                                    this.questionData[i].fc = new UntypedFormControl(dateVal)
                                    break;
                                case "answer_number":
                                    if (this.questionData[i].answer_number != null) {
                                        this.questionData[i].fc = new UntypedFormControl(this.questionData[i].answer_number + "")
                                    } else {
                                        this.questionData[i].fc = new UntypedFormControl(this.questionData[i].answer_number)
                                    }
                                    break;
                                case "answer_special":
                                    if(this.questionData[i].fc) {    
                                        this.questionData[i].fc = new UntypedFormControl(this.questionData[i].answer_special)
                                        break;
                                    }
                            }

                            if (i == 0 || lastGroup.group_uuid != this.questionData[i].group_uuid) {
                                lastGroup = this.questionData[i]
                                lastGroup.children = []


                                lastGroup.commentFC = new UntypedFormControl(this.questionData[i].comment)
                                lastGroup.commentValue = this.questionData[i].comment
                                lastGroup.commentUuid = this.questionData[i].comment_uuid


                                this.questionGroups.push(lastGroup)
                            }
                            lastGroup.children.push(this.questionData[i])

                            this.questionData[i].last_question_add_other = true
                            if (lastQuestionId == this.questionData[i].question_uuid) {
                                this.questionData[i - 1].last_question_add_other = false
                            }
                            lastQuestionId = this.questionData[i].question_uuid
                        }
                    }
                    resolve(null)
                })
        })
    }

    isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    calculateSubtotals() {
        for (var i = 0; i < this.questionGroups.length; ++i) {
            if (!this.questionGroups[i].allow_additional && this.questionGroups[i].subtotal_group) {
                this.questionGroups[i].group_subtotal = 0
                for (var j = 0; j < this.questionGroups[i].children.length; ++j) {
                    if (this.questionGroups[i].children[j].answer_datatype == "answer_number"
                        || this.questionGroups[i].children[j].answer_datatype == "answer_number_and_text") {
                        if (this.isNumber(this.questionGroups[i].children[j].fc.value)) {
                            this.questionGroups[i].group_subtotal += parseInt(this.questionGroups[i].children[j].fc.value)
                        }
                    }
                }
            }

            var highestSequenceNumber = 0
            if (this.questionGroups[i].allow_additional && this.questionGroups[i].subtotal_additional) {
                this.questionGroups[i].additional_subtotals = {}
                for (var j = 0; j < this.questionGroups[i].children.length; ++j) {
                    if (this.questionGroups[i].children[j].answer_datatype == "answer_number"
                        || this.questionGroups[i].children[j].answer_datatype == "answer_number_and_text") {

                        if (!this.questionGroups[i].additional_subtotals[this.questionGroups[i].children[j].question_number]) {
                            this.questionGroups[i].additional_subtotals[this.questionGroups[i].children[j].question_number] = {
                                label: this.questionGroups[i].children[j].question_name,
                                subtotal: 0,
                            }
                        }
                        if (this.isNumber(this.questionGroups[i].children[j].fc.value)) {
                            this.questionGroups[i].additional_subtotals[this.questionGroups[i].children[j].question_number].subtotal
                                += parseInt(this.questionGroups[i].children[j].fc.value)
                        }
                        if (this.questionGroups[i].children[j].additional_answer_sequence_number) {
                            if (this.questionGroups[i].children[j].additional_answer_sequence_number > highestSequenceNumber) {
                                highestSequenceNumber = this.questionGroups[i].children[j].additional_answer_sequence_number
                            }
                        }
                    }
                }
                this.questionGroups[i].answer_count = highestSequenceNumber + 1
            }
        }
    }

    previousStepUuid
    nextStepUuid
    getNextAndPreviousSteps() {
        if (!this.stepUuid) {
            return Promise.resolve()
        }
        return new Promise((resolve, reject) => {
            this.submissionStepService.getStepsBySubmission(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    if (response.result) {
                        var previousStepUuid
                        for (var i = 0; i < response.result.length; ++i) {
                            if (response.result[i].step_id == this.stepUuid) {
                                this.previousStepUuid = previousStepUuid
                                if (i < response.result.length - 1) {
                                    this.nextStepUuid = response.result[i + 1].step_id
                                }
                                break;
                            }
                            previousStepUuid = response.result[i].step_id
                        }
                    }
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        return Promise.all([
            this.getSubmissionQuestionGroupAnswersPromise(),
            this.getNextAndPreviousSteps(),
        ]).then(() => {
            this.calculateSubtotals()
            this.initialized = true
        })
    }

    copyQuestion(question) {
        var newQuestion: any = {}
        for (const prop in question) {
            if (question.hasOwnProperty(prop)) {
                if (prop != "fc"
                    && prop != "fc2"
                    && prop != "special_answers_dropdown_item"
                    && prop != "answer_uuid") {
                    newQuestion[prop] = question[prop]
                }
            }
        }
        newQuestion.fc = new UntypedFormControl()
        newQuestion.fc2 = new UntypedFormControl()
        return newQuestion
    }

    addGroupAnswers(group, id) {
        let element = document.getElementById(id)
        this.saveForLater(true)
        this.submissionAnswersService.addAnswerGroup(group.answer_group_uuid).subscribe(response => {
            if(response.success == true) {
                window.location.reload()
                //element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
                //console.log(window.location.pathname + window.location.search)
                //window.location.href = window.location.pathname + window.location.search + '#' + id
            }
        })
        /*
        var newQuestions = []
        for (var i = 0; i < group.children.length; ++i) {
            if (group.children[i].additional_answer_sequence_number && group.children[i].additional_answer_sequence_number != 0) {
                break
            }
            var newQuestion: any = this.copyQuestion(group.children[i])
            newQuestion.additional_answer_sequence_number = group.answer_count
            newQuestions.push(newQuestion)
        }
        for (var i = 0; i < newQuestions.length; ++i) {
            group.children.push(newQuestions[i])
        }
        ++group.answer_count
        */
    }

    sameSequenceNumber(a, b) {
        if (!a && !b) {
            return true
        }
        return a == b
    }

    removeGroupAnswers(group, sequenceNumber) {
        //count the number of questions with null or 0 sequence number in the group
        var questionCount = 0
        for (var i = 0; i < group.children.length; ++i) {
            if (!group.children[i].additional_answer_sequence_number) {
                ++questionCount
            }
        }
        //want to copy up the values, so the id's and sequence numbers remain intact
        var sequenceNumberEncountered = false
        for (var i = 0; i < group.children.length; ++i) {
            if (this.sameSequenceNumber(group.children[i].additional_answer_sequence_number, sequenceNumber)) {
                sequenceNumberEncountered = true
            } else if (sequenceNumberEncountered) {
                group.children[i - questionCount].fc.setValue(group.children[i].fc.value)
                group.children[i - questionCount].fc2.setValue(group.children[i].fc2.value)
            }
        }
        group.children.length -= questionCount
        --group.answer_count
    }

    cancel() {
        this.router.navigate(["submission-question-steps"], { queryParams: { submissionUuid: this.submissionUuid } })
    }

    getAnswerData() {
        var _return = []

        for (var i = 0; i < this.questionGroups.length; ++i) {
            for (var j = 0; j < this.questionGroups[i].children.length; ++j) {
                var answer_value = this.questionGroups[i].children[j].fc.value
                if (this.questionGroups[i].children[j].answer_datatype == "answer_date") {
                    if (answer_value != null) {
                        answer_value = new Date(answer_value).toISOString().replace("T", " ")
                        answer_value = answer_value.substring(0, answer_value.length - 5)
                    }
                }

                if(this.questionGroups[i].children[j].answer_datatype == 'answer_number_and_text') {
                    if(answer_value != null) {
                        answer_value = String(answer_value)
                    }
                }

                var answer_value2
                if (this.questionGroups[i].children[j].fc2) {
                    answer_value2 = this.questionGroups[i].children[j].fc2.value
                }
                _return.push({
                    group_uuid: this.questionGroups[i].children[j].group_uuid,
                    answer_uuid: this.questionGroups[i].children[j].answer_uuid,
                    answer_datatype: this.questionGroups[i].children[j].answer_datatype,
                    answer_value,
                    answer_value2,
                    additional_answer_sequence_number: this.questionGroups[i].children[j].additional_answer_sequence_number,
                    required: this.questionGroups[i].children[j].required,
                })
            }
        }
        //console.log("returning answer data", _return)
        return _return
    }

    validateAnswerData() {
        var invalidValueEncountered = false
        for (var i = 0; i < this.questionGroups.length; ++i) {
            for (var j = 0; j < this.questionGroups[i].children.length; ++j) {
                this.questionGroups[i].children[j].validationError = ""

                var answer_value = this.questionGroups[i].children[j].fc.value

                if (this.questionGroups[i].children[j].answer_datatype == "answer_number") {
                    if (answer_value != null && answer_value != "" && !this.isNumber(answer_value)) {
                        invalidValueEncountered = true
                        this.questionGroups[i].children[j].validationError = "Please enter a number."
                    }
                }

                // if(this.questionGroups[i].children[j].answer_datatype == 'answer_number_and_text') {
                //     let answer_val = String(this.questionGroups[i].children[j].fc.value)


                //     console.log(answer_val)
                // }
                //todo - more checks, and checks for values in fc2 if needed
                // var answer_value2
                // if (this.questionGroups[i].children[j].fc2) {
                //     answer_value2 = this.questionGroups[i].children[j].fc2.value
                // }
            }
        }
        if (invalidValueEncountered) {
            this.saveError = "Please address the issues highlighted above then click the button again."
        }
        return invalidValueEncountered
    }

    saveForLater(page) {
        this.saveError = null
        if (this.validateAnswerData()) {
            return
        }
        this.submissionAnswersService.postSubmissionAnswers(
            this.submissionUuid,
            this.stepUuid,
            false,
            this.getAnswerData(),
        ).subscribe((response) => {
            //console.log("save response", response)
            if (!response.success) {
                return this.saveError = response.message
            }
            this.snackRouteService.snack("Saved for later")

            if(!page) {
                this.router.navigate(["/submission-question-steps"], {
                    queryParams: {
                        submissionUuid: this.submissionUuid,
                    }
                })
            } else {
                this.router.navigate(["/program-submission-answers"], {
                    queryParams: {
                        submissionUuid: this.submissionUuid,
                        stepUuid: this.stepUuid
                    }
                })
            }
        })
    }

    previous() {
        this.saveError = null
        if (this.validateAnswerData()) {
            return
        }
        this.submissionAnswersService.postSubmissionAnswers(
            this.submissionUuid,
            this.stepUuid,
            true,
            this.getAnswerData(),
        ).subscribe((response) => {
            if (!response.success) {
                return this.saveError = response.message
            }
            this.router.navigate(['/'], { skipLocationChange: true }).then(() =>
                this.router.navigate(["/program-submission-answers"], {
                    queryParams: {
                        submissionUuid: this.submissionUuid,
                        stepUuid: this.previousStepUuid
                    }
                })
            );
        })
    }

    next() {
        this.saveError = null
        if (this.validateAnswerData()) {
            return
        }
        this.submissionAnswersService.postSubmissionAnswers(
            this.submissionUuid,
            this.stepUuid,
            true,
            this.getAnswerData(),
        ).subscribe((response) => {
            //console.log("next response", response)
            if (!response.success) {
                return this.saveError = response.message
            }

            this.router.navigate(['/'], { skipLocationChange: true }).then(() =>
                this.router.navigate(["/program-submission-answers"], {
                    queryParams: {
                        submissionUuid: this.submissionUuid,
                        stepUuid: this.nextStepUuid
                    }
                })
            );
        })
    }

    finish() {
        this.saveError = null
        if (this.validateAnswerData()) {
            return
        }
        this.submissionAnswersService.postSubmissionAnswers(
            this.submissionUuid,
            this.stepUuid,
            true,
            this.getAnswerData(),
        ).subscribe((response) => {
            //console.log("finish response", response)
            if (!response.success) {
                return this.saveError = response.message
            }
            this.snackRouteService.snack("Finished")
            this.router.navigate(["/submission-question-steps"], {
                queryParams: {
                    submissionUuid: this.submissionUuid,
                }
            })
        })
    }

    togglePopup(question) {
        // console.log(question)
        var text = document.getElementById("qpopup_" + question.question_uuid)

        // var arrow = document.getElementById("qpopup_arrow_"+question.uuid)


        text.innerHTML = question.popup_html

        if (text.style.display != "block") {
            text.style.display = "block"
            // arrow.style.display="block"
            text.classList.add("show")
            // arrow.classList.add("show")
        } else {
            text.style.display = "none"
            // arrow.style.display="none"
        }
    }

    back() {
        this.router.navigate(["/dashboard"])
    }

    toggleCommentInput(group) {
        if (!this.showFlag) {
            return
        }
        if (group.showCommentInput) {
            this.hideCommentInput(group)
        } else {
            this.showCommentInput(group)
        }
    }

    showCommentInput(group) {
        group.showCommentInput = true
    }

    hideCommentInput(group) {
        group.showCommentInput = false
        group.commentFC.setValue(group.commentValue)
    }

    deleteComment(group) {
        if (group.commentUuid) {
            this.submissionCommentService.deleteCommentPromise(group.commentUuid).then(() => {
                group.commentUuid = null
                group.commentValue = null
                group.commentFC.setValue(null)
                this.snackRouteService.snack("Comment deleted.")
                this.hideCommentInput(group)
            })
        } else {
            this.snackRouteService.snack("Action cancelled.")
            this.hideCommentInput(group)
        }
    }

    updateComment(group) {
        this.submissionCommentService.postCommentPromise(group.commentUuid, group.commentFC.value).then(() => {
            group.commentValue = group.commentFC.value
            this.snackRouteService.snack("Comment updated.")
            this.hideCommentInput(group)
        })
    }

    insertComment(group) {
        this.submissionCommentService.putCommentPromise(
            this.submissionUuid,
            "data_answer_group",
            group.group_uuid,
            "name",
            group.commentFC.value,
        ).then((uuid) => {
            group.commentUuid = uuid
            group.commentValue = group.commentFC.value
            this.snackRouteService.snack("Comment created.")
            this.hideCommentInput(group)
        })
    }

    saveComment(group) {
        if (group.commentUuid) {
            if (group.commentFC.value) {
                this.updateComment(group)
            } else {
                this.deleteComment(group)
            }
        } else {
            if (group.commentFC.value) {
                this.insertComment(group)
            } else {
                this.snackRouteService.snack("Action cancelled.")
                this.hideCommentInput(group)
            }
        }
    }

    deleteAnswer(answer_uuid, id) {
        let element = document.getElementById(id)
        // console.log("deleting answer for uuid", answer_uuid)

        this.submissionAnswersService.deleteAnotherAnswer(answer_uuid).subscribe((response) => {
            if(!response.success) {
                console.log(response.message)
            } else {
                this.snackRouteService.snack("Deleted. Refreshing..")
                window.location.reload()
            }
        })

    }

    addAnswer(answer_uuid, id) {
        let element = document.getElementById(id)
        // console.log("adding answer for uuid", answer_uuid)
        this.submissionAnswersService.addAnotherAnswer(answer_uuid).subscribe((response) => {
            // console.log("add answer", response)
            this.saveError = null
            if (this.validateAnswerData()) {
                return
            }
            this.submissionAnswersService.postSubmissionAnswers(
                this.submissionUuid,
                this.stepUuid,
                false,
                this.getAnswerData(),
            ).subscribe((response) => {
                //console.log("save response", response)
                if (!response.success) {
                    return this.saveError = response.message
                }
                this.snackRouteService.snack("Saved. Refreshing...")
                window.location.reload()
                //element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})

                
            })
            //element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        })

    }
}
