<app-logo-header></app-logo-header>

<div *ngIf="currentView == 'showUsernameInput'" class="container noep-container-medium shadow mt-4">
    <div class="row mt-2">
        <div class="col mt-3 bold" style="font-size: 1.2em;" align="center">
            Forgot your password?
        </div>
    </div>
    <div class="row mt-2">
        <div class="col mt-3">
            Enter the email address you use to sign in, and we'll send you a link to reset your password.
        </div>
    </div>
    <div class="row mt-2">
        <div class="col mt-3" align="center">
            <input type="text" class="input-style" placeholder="Username (email)" id="username" [formControl]="username"
                type="email" required />
        </div>
    </div>
    <div class="row mt-2" *ngIf="username.invalid && (username.dirty || username.touched)" class="error-marker"
        align="center">
        <div class="col mt-3">
            <div *ngIf="username.errors.required">
                Username is required.
            </div>
            <div *ngIf="username.errors.email">
                Username must be a valid email address.
            </div>
        </div>
    </div>
    <div class="row mt-4 mb-1">
        <div class="col mb-4" align="center">
            <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
            </app-button3>
            <app-button3 class="mx-1" label="Send Link" [clickFunction]="sendRecoveryEmail.bind(this)"
                [icon]="faShareSquare">
            </app-button3>
        </div>
    </div>
</div>
<div *ngIf="currentView == 'showSending'" class="container noep-container-medium shadow mt-4">
    <div class="row mt-2">
        <div class="col mt-3 mb-3" align="center">
            Sending.
        </div>
    </div>
</div>
<div *ngIf="currentView == 'showSent'" class="container noep-container-medium shadow mt-4">
    <div class="row mt-2">
        <div class="col-2">
        </div>
        <div class="col-8 mt-3 attribute-h1">
            Password Reset Sent
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-2">
        </div>
        <div class="col-8 mt-3">
            Please check your email for a password reset link.
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-2">
        </div>
        <div class="col-8 mt-3">
            If you have not yet received a password reset email, please be patient - email may sometimes experience
            delays.
        </div>
    </div>
    <div class="row mt-2 pb-4">
        <div class="col-2">
        </div>
        <div class="col-8 mt-3">
            Please close this tab, or return to the <a routerLink="/login">login</a> page.
        </div>
    </div>
</div>
<div *ngIf="currentView == 'serviceError'" class="container noep-container-medium shadow mt-4">
    <div class="row mt-2">
        <div class="col mt-3 mb-4" align="center">
            Service Error.
        </div>
    </div>
</div>