<div *ngIf="userOrganizationType == 'PRIME' && searchResults$ | async as searchResults" class="container-fluid">
    <div class="row mb-1" style="background-color: lightgrey;">
        <div class="col-4 page-title">
            <fa-icon [icon]="faSearch"></fa-icon>
            Submission Search
        </div>
        <div class="col-12 d-flex justify-content-end align-items-center">
            <div class="mr-2">
                Entries Per Page&nbsp;
                <app-input-enum [fc]="entriesPerPageFC" [style]="'none'" [class]="'inline-block'" [enumValuesNameAndId]="entriesPerPageOptions" [change]="entriesPerPageChange.bind(this)"></app-input-enum>
            </div>
            <div class="mr-2">
                Keyword Search&nbsp;
                <input type="text" [formControl]="keywordSearchFC" style="width: 8em;" />
            </div>
            <div class="mr-2">
                Column Logic&nbsp;
                <app-input-enum [fc]="columnLogicFC" [style]="'none'" [class]="'inline-block'" [enumValuesNameAndId]="logicOptions" [change]="entriesPerPageChange.bind(this)"></app-input-enum>
            </div>
            <app-button3 label="Clear" [small]="true" [isDelete]="true" style="padding: 5px; margin-left: .50em;" (click)="reloadPage()"></app-button3>
            <div class="fiscal-year-container">
                <span class="fiscal-year-label">Fiscal Year</span>
                <mat-select class="custom-select" [(ngModel)]="selectedYear" (selectionChange)="fiscalYearChange()" style="min-width: 200px;">
                    <mat-option *ngFor="let option of fiscalYearOptions" [value]="option.id">{{ option.name }}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    


    <app-page-controls [pageNumber]="currentPage" [pageSize]="entriesPerPageFC.value" [entryCount]="count"
        [setPage]="this.setPage.bind(this)">
    </app-page-controls>

    <div class="row mt-1">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%">
                <thead>
                    <tr style="background-color: lightgrey">
                        <th class="px-1" *ngFor="let column of columns; let index = index" style="white-space: nowrap;">
                            <span class="sort-column-header" (click)="sortClick(column.Field)">
                                {{column.name | dbtitlecase | titlecase}}
                            </span>
                            <app-sort-indicator [sortFieldName]="sortField" [sortIsDescending]="sortIsDescending"
                                [columnFieldName]="column.Field">
                            </app-sort-indicator>
                            <app-filter [column]="column" [closeAll]="closeAllFilters.bind(this)"
                                [values]="column.values | async" (filterChange)="handleFilterEvent($event)"
                                [alignRight]="column.name == 'Agency Ref #' || column.name == 'Total'">
                            </app-filter>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let entry of searchResults.result">
                        <td *ngIf="entry.status_id != 'VOID'">
                            {{entry.organization_name}}
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            {{entry.program_name}}
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            {{entry.submission_type_name}}
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            {{entry.jurisdiction_name}}
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            <a *ngIf="entry.submission_type_id === 'NOEP_PROGRAM';else not_program" [routerLink]="
                            userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM'
                            && (entry.status_id == 'NEW' || entry.status_id == 'DRAFT' || entry.status_id == 'MORE_INFO')
                            ? entry.editRoute
                            : '/submission-question-steps'" [queryParams]="{ submissionUuid: entry.uuid }" target="_blank">
                                {{entry.name}}
                            </a>
                            <ng-template #not_program>
                                <a [routerLink]="
                                userOrganizationType != 'PRIME' && userOrganizationType != 'SYSTEM'
                                && (entry.status_id == 'NEW' || entry.status_id == 'DRAFT' || entry.status_id == 'MORE_INFO')
                                ? entry.editRoute
                                : '/submission-review'" [queryParams]="{ submissionUuid: entry.uuid, fiscalYearId: fiscalYearId }" target="_blank">
                                    {{entry.name}}
                                </a>
                            </ng-template>
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            <!-- {{entry.sequence}} -->
                            {{entry.reference_number}}
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            {{entry.due_date | date : 'MM/dd/yyyy'}}
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            <span class="px-2" style="border-radius: .25em; color: white; font-weight: bold;"
                                [ngClass]="['submission_status_' + entry.status_id ]">
                                {{entry.status_name | uppercase}}
                            </span>
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            {{entry.agency_date | date : 'MM/dd/yyyy'}}
                        </td>
                        <td *ngIf="entry.status_id != 'VOID'">
                            {{entry.total}}
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <app-page-controls [pageNumber]="currentPage" [pageSize]="entriesPerPageFC.value" [entryCount]="count"
        [setPage]="this.setPage.bind(this)" [isFooter]="true">
    </app-page-controls>
</div>