<div mat-dialog-title style="width: 100%; height: 2.5em; position: relative;">
    <span *ngIf="newEntry">Add</span>
    <span *ngIf="!newEntry">Edit</span>
    {{typeFC.value}}
    <div #theButton style="position: absolute; right: .5em; top: 0;">
        <fa-icon [icon]="faTimes" title="Cancel" (click)="cancel()" style="cursor: pointer"
            (mouseenter)="theButton.style.color='blue'" (mouseleave)="theButton.style.color=null">
        </fa-icon>
    </div>
</div>

<div mat-dialog-content>
    <div class="container-fluid">
        <span *ngFor="let column of types[typeFC.value].definition; let index = index">
            <div class="row mt-2">
                <div class="col-4 bold">
                    {{column.Field}}
                </div>
                <div class="col-8">
                    <app-typed [fc]="column.createFC" [disabled]="!newEntry && column.Key == 'PRI'"
                        [columnDefinition]="column"
                        [values]="referencedTypes[column.referenced_table_name] ? referencedTypes[column.referenced_table_name].enumNameAndId : null">
                    </app-typed>
                </div>
            </div>
            <div *ngIf="showFieldGenerateUuid(column)" class="row">
                <div class="col-4">
                </div>
                <div class="col-8">
                    <span (click)="fieldGenerateUuid(column)"
                        style="font-size: .8em; cursor: pointer;">(generate)</span>
                </div>
            </div>
        </span>

        <div class="row my-3">
            <div class="col" align="center">
                <app-button3 *ngIf="showUpdateButton()" class="mx-1" label="Delete" [clickFunction]="delete.bind(this)"
                    [icon]="faMinus" [isDelete]="true">
                </app-button3>
                <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                </app-button3>
                <app-button3 *ngIf="showClearButton()" class="mx-1" label="Clear" [clickFunction]="clear.bind(this)"
                    [icon]="faBan">
                </app-button3>
                <app-button3 *ngIf="showCreateButton()" class="mx-1" label="Create" [clickFunction]="create.bind(this)"
                    [icon]="faSave" [isFinal]="true">
                </app-button3>
                <app-button3 *ngIf="showUpdateButton()" class="mx-1" label="Update" [clickFunction]="update.bind(this)"
                    [icon]="faSave" [isFinal]="true">
                </app-button3>
            </div>
        </div>
    </div>
</div>