import { Component, OnInit, Inject, Input } from "@angular/core"
import { UntypedFormControl, Validators } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router"
import { faTimes, faBan, faExclamationTriangle, faCheck, faSave, faMinus } from "@fortawesome/free-solid-svg-icons"
import { AuthService } from "../auth.service"
import { BudgetService } from "../budget.service"
import { SnackRouteService } from "../snack-route.service"
import { SubmissionCommentService } from "../submission-comment.service"
import { SubmissionService } from "../submission.service"

@Component({
    selector: 'app-budget-account',
    templateUrl: './budget-account.component.html',
})
export class BudgetAccountComponent implements OnInit {
    faTimes = faTimes
    faBan = faBan
    faExclamationTriangle = faExclamationTriangle
    faCheck = faCheck
    faSave = faSave
    faMinus = faMinus

    newEntry = true
    accountNameFC = new UntypedFormControl("Other")
    showFlag
    submissionUuid


    round(fc, decimalPlaces?) {
        if (fc.value) {
            if (typeof fc.value == "string") {
                fc.setValue(parseFloat(fc.value))
            }
            fc.setValue(fc.value.toFixed(decimalPlaces))
            console.log(fc)
        }
    }


    @Input() dialogRef
    @Input() data
    @Input() showPopupHeader

    @Input() accountUuid
    @Input() hideButtons

    constructor(
        // public dialogRef: MatDialogRef<BudgetSubmissionAccountDialog>,
        // @Inject(MAT_DIALOG_DATA) public data,
        private budgetService: BudgetService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private submissionCommentService: SubmissionCommentService,
        private submissionService: SubmissionService,
        private router: Router,
        private authService: AuthService,
    ) {
    }

    editable = false

    inputDataById = {}
    flagClick(inputId) {
        var inputData = this.inputDataById[inputId]
        if (inputData) {
            inputData.showInputContainer.value = !inputData.showInputContainer.value
        } else {
            this.inputDataById[inputId] = {
                showInputContainer: { value: true }
            }
        }
    }

    commentFields = [
        "name",
        "quantity",
        "narrative",
        "snap",
        "child_nutrition_snap",
        "unit_price",
        "percent_allocated",
        "agency_comments"
    ]

    allocationMethodOptions
    getAllocationMethodOptionsPromise(accountUuid) {
        return new Promise((resolve, reject) => {
            this.budgetService.getAllocationMethodOptions(accountUuid).subscribe((result) => {
                // console.log("allocation method options", result)
                this.allocationMethodOptions = result.filter(option => option.allow_budget == 'Y')
                if (this.allocationMethodOptions.length == 1)
                {
                    this.allocationMethodFC.setValue(this.allocationMethodOptions[0].id)
                }
                else if (this.allocationMethodOptions.length) {
                    
                    this.allocationMethodOptions.unshift({ name: "", id: null })
                }
                resolve(null)
            })
        })
    }

    debitState
    debitFederal
    debitStateEst
    debitFederalEst

    doDataInit() {

        this.newEntry = false

        var entry = this.data.entryData[0]

        if(entry.debit_state == null) {
            entry.debit_state = 0
        }

        if(entry.debit_federal == null) {
            entry.debit_federal = 0
        }
        
        this.accountNameFC.setValue(entry.account_name)
        this.accountFC.setValue(entry.account_uuid)
        this.nameFC.setValue(entry.finance_name)
        this.totalSquareFootageFC.setValue(entry.quantity)
        this.narrativeFC.setValue(entry.narrative)
        this.snapFC.setValue(entry.debit_state)
        this.childNutritionSnapFC.setValue(entry.debit_federal)
        this.totalFC.setValue(entry.debit_federal + entry.debit_state)
        this.pricePerSquareFootFC.setValue(entry.unit_price)
        this.percentAllocatedFC.setValue(entry.percentage)
        this.allocationMethodFC.setValue(entry.allocation_method_id)
        this.agencyCommentsFC.setValue(entry.agency_comment)
        this.userFlagFC.setValue(entry.user_flag)
        this.debitState = entry.debit_state
        this.debitFederal = entry.debit_federal
        this.debitStateEst = entry.debit_state_est
        this.debitFederalEst = entry.debit_federal_est


        this.submissionCommentService.getSubmissionCommentsPromise(this.data.submissionUuid, this.data.accountUuid).then((result: any) => {
            //console.log("comments", result)
            for (var i = 0; i < this.commentFields.length; ++i) {
                this.inputDataById[this.commentFields[i]] = {
                    showInputContainer: { value: false }
                }
            }
            if (result) {
                for (var i = 0; i < result.length; ++i) {
                    this.inputDataById[result[i].target_column] = {
                        comment: result[i].comment,
                        commentUuid: result[i].uuid,
                        showInputContainer: { value: false }
                    }
                }
            }
            this.getAllocationMethodOptionsPromise(entry.account_uuid).then(() => {
                this.initialized = true
            })
        })
    }

    addAccountOptions
    getAddAccountOptionsPromise() {
        return new Promise((resolve, reject) => {
            this.budgetService.getAddAccountOptions(this.submissionUuid).subscribe(response => {
                // console.log("add account options response", response)
                this.addAccountOptions = response.result
                for (var i = 0; i < this.addAccountOptions.length; ++i) {
                    this.addAccountOptions[i].id = this.addAccountOptions[i].uuid
                }
                resolve(null)
            })
        })
    }

    userOrganizationType
    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                //console.log("org type", response)
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }


    submission
    getSubmissionPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmission(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.submission = response.result[0]
                    console.log("submission for review", this.submission)
                } else {
                    console.log("error while getting submission", response)
                }
                resolve(null)
            })
        })
    }

    initialized = false
    ngOnInit() {
        this.getUserOrganizationType().then(() => {
            console.log("account init", this.data, this.accountUuid, this.editable)
            if (this.data) {
                this.editable = this.data.editable
                this.showFlag = this.data.showFlag
                this.submissionUuid = this.data.submissionUuid
            } else {
                this.editable = false
                this.showFlag = false
            }
            if (!this.data && this.accountUuid) {
                this.getAddAccountOptionsPromise().then(() => {
                    this.budgetService.getAccount(this.accountUuid).subscribe((response) => {
                        //console.log("get account", response)
                        if (!response.success) {
                            return console.log("error while getting account", response)
                        }
                        this.data = {
                            submissionUuid: this.submissionUuid,
                            addAccountOptions: this.addAccountOptions,
                            accountUuid: this.accountUuid,
                            entryData: response.result,
                            editable: this.editable,
                            showFlag: this.showFlag,
                        }
                        this.doDataInit()
                        this.getSubmissionPromise()
                        this.accountChange()
                    })
                })
            }

            if (this.data && this.data.entryData && this.data.entryData.length) {
                this.doDataInit()
                this.getSubmissionPromise()
                this.accountChange()
            } else if (!this.accountUuid) {
                for (var i = 0; i < this.commentFields.length; ++i) {
                    this.inputDataById[this.commentFields[i]] = {
                        showInputContainer: { value: false }
                    }
                }
                this.initialized = true
            }
        })
    }

    accountFC = new UntypedFormControl()
    nameFC = new UntypedFormControl()
    allocationMethodFC = new UntypedFormControl()
    totalSquareFootageFC = new UntypedFormControl()
    narrativeFC = new UntypedFormControl()
    snapFC = new UntypedFormControl('0')
    childNutritionSnapFC = new UntypedFormControl('0')
    totalFC = new UntypedFormControl()
    pricePerSquareFootFC = new UntypedFormControl()
    percentAllocatedFC = new UntypedFormControl()
    agencyCommentsFC = new UntypedFormControl()
    userFlagFC = new UntypedFormControl()

    saveError
    accountError
    nameError
    allocationMethodError
    totalSquareFootageError
    narrativeError
    snapError
    childNutritionSnapError
    totalError
    pricePerSquareFootError
    percentAllocatedError
    agencyCommentsError

    account

    accountChange() {
        this.accountError = null
        for (var i = 0; i < this.data.addAccountOptions.length; ++i) {
            if (this.accountFC.value == this.data.addAccountOptions[i].id) {
                this.account = this.data.addAccountOptions[i]
                break
            }
        }
        // HSNY 1876 Fix narrative not populating during new entry on budget-account page
        if(this.account) {
            if (this.account.narrative) {
                this.narrativeFC.setValue(this.account.narrative)
            }
        }

        this.getAllocationMethodOptionsPromise(this.accountFC.value)
    }

    nameChange() {
        this.nameError = null
    }

    allocationMethodChange() {
        this.allocationMethodError = null
    }

    totalSquareFootageChange() {
        this.totalSquareFootageError = null
    }

    narrativeChange() {
        this.narrativeError = null
    }

    updateTotal() {
        var newTotal = 0
        if (this.snapFC.value) {
            newTotal += parseFloat(this.snapFC.value)
        }
        if (this.childNutritionSnapFC.value) {
            newTotal += parseFloat(this.childNutritionSnapFC.value)
        }
        this.totalFC.setValue(newTotal)
    }

    snapChange() {
        this.snapError = null
        this.updateTotal()
    }

    childNutritionSnapChange() {
        this.childNutritionSnapError = null
        this.updateTotal()
    }

    totalChange() {
        this.totalError = null
    }

    pricePerSquareFootChange() {
        this.pricePerSquareFootError = null
    }

    percentAllocatedChange() {
        this.percentAllocatedError = null
    }

    agencyCommentsChange() {
        this.agencyCommentsError = null
    }

    userFlagChange() {
        //console.log("user flag change", this.userFlagFC.value, this.accountUuid, this.data.entryData[0].finance_uuid)
        this.budgetService.updateUserFlag(this.data.entryData[0].finance_uuid, this.userFlagFC.value).subscribe((response) => {
            if (response.success) {
                this._snackBar.open("User flag updated.", null, {
                    duration: 4000,
                });
            } else {
                this._snackBar.open("Error occurred while updating user flag.", null, {
                    duration: 4000,
                });
            }
        })
    }

    //NOTE: isNumber and isMoney methods assume american-style money formatting, using , as the thousands separator and . as the decimal.
    //may need a locale update to potentially flip these if we ever market to europe, etc.

    //assumes n is a string
    isNumber(n) {
        if (n == null) {
            return false
        }
        if (typeof n == "number") {
            return true
        }
        n = n.replace(/,/g, '')
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    //assumes n is a string
    isMoney(n) {
        if (!this.isNumber(n)) {
            return false
        }
        //ensure has zero to two decimal places
        // this.toString().split(".")[1].length || 0
        var parts = n.toString().split(".")
        return parts.length < 2 || parts[1].length <= 2
    }

    clearErrors() {
        this.saveError = null
        this.accountError = null
        this.nameError = null
        this.totalSquareFootageError = null
        this.narrativeError = null
        this.snapError = null
        this.childNutritionSnapError = null
        this.totalError = null
        this.pricePerSquareFootError = null
        this.percentAllocatedError = null
        this.allocationMethodError = null
    }

    validate() {
        this.clearErrors()
        var errorEncountered = false

        if (!this.accountFC.value) {
            this.accountError = "Please select an account"
            errorEncountered = true
        }

        if (!this.nameFC.value) 
        {
            this.nameError = "Please Enter a Description"
            errorEncountered = true
        }

        if (this.totalSquareFootageFC.value && !this.isNumber(this.totalSquareFootageFC.value)) {
            this.totalSquareFootageError = "Total Square Footage must be a number."
            errorEncountered = true
        }

        if (this.snapFC.value && !this.isMoney(this.snapFC.value)) {
            this.snapError = "SNAP must be a number."
            errorEncountered = true
        }
        if (this.childNutritionSnapFC.value && !this.isMoney(this.childNutritionSnapFC.value)) {
            this.childNutritionSnapError = "Child Nutrition / SNAP must be number."
            errorEncountered = true
        }
        if (this.totalFC.value && !this.isMoney(this.totalFC.value)) {
            this.totalError = "Total must be a number."
            errorEncountered = true
        }
        if (this.pricePerSquareFootFC.value && !this.isNumber(this.pricePerSquareFootFC.value)) {
            this.pricePerSquareFootError = "Price per Square Foot must be a number."
            errorEncountered = true
        }
        if (this.percentAllocatedFC.value && !this.isNumber(this.percentAllocatedFC.value)) {
            this.percentAllocatedError = "Percent Allocated must be a number."
            errorEncountered = true
        }

        /*
        if (this.snapFC.value > this.debitState  - this.debitStateEst) {
            this.snapError = "State Only must be greater than budget state only minus state only spent"
            errorEncountered = true
        }

        if (this.childNutritionSnapFC.value > this.debitFederal - this.debitFederalEst ) {
            this.childNutritionSnapError = "State Match/Federal Match must be greater than federal match budget minus federal match spent"
            errorEncountered = true
        }
        */
         if (this.allocationMethodOptions && this.allocationMethodOptions.length > 0 && !this.allocationMethodFC.value) {
             this.allocationMethodError = "Please select an allocation method."
             errorEncountered = true
         }

        return errorEncountered
    }

    returnToSubmission() {
        if (this.dialogRef) {
            this.dialogRef.close({});
        } else {
            this.router.navigate(["/budget-summary"], { queryParams: { submissionUuid: this.submissionUuid } })
        }
    }

    save() {
        if (this.validate()) {
            return
        }

        if (this.data && this.data.entryData && this.data.entryData.length) {
            this.budgetService.updateAccount(
                this.data.entryData[0].finance_uuid,
                this.nameFC.value,
                this.totalSquareFootageFC.value,
                this.narrativeFC.value,
                this.snapFC.value,
                this.childNutritionSnapFC.value,
                this.totalFC.value,
                this.pricePerSquareFootFC.value,
                this.percentAllocatedFC.value,
                this.allocationMethodFC.value,
                this.agencyCommentsFC.value,
            ).subscribe((response) => {
                if (!response.success) {
                    this.saveError = response.message
                    return
                }
                this.data.snackRoute.snack("Account updated.")
                this.returnToSubmission()
            })
        } else {
            this.budgetService.addAccount(
                this.data.submissionUuid,
                this.accountFC.value,
                this.nameFC.value,
                this.totalSquareFootageFC.value,
                this.narrativeFC.value,
                this.snapFC.value,
                this.childNutritionSnapFC.value,
                this.totalFC.value,
                this.pricePerSquareFootFC.value,
                this.percentAllocatedFC.value,
                this.allocationMethodFC.value,
                this.agencyCommentsFC.value,
            ).subscribe((response) => {
                if (!response.success) {
                    this.saveError = response.message
                    return
                }
                this.data.snackRoute.snack("Account added.")
                this.returnToSubmission()
            })
        }
    }

    cancel() {
        this._snackBar.open("Action cancelled", null, {
            duration: 4000,
        });
        this.returnToSubmission()
    }

    ok() {
        if (this.dialogRef) {
            this.dialogRef.close({});
        } else {
            this.router.navigate(["/submission-review"], { queryParams: { submissionUuid: this.submissionUuid } })
        }
    }

    delete() {
        const dialogRef = this.dialog.open(BudgetSubmissionDeleteConfirmationDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //this.fetchDataPromise()
                this.budgetService.deleteAccount(this.data.entryData[0].finance_uuid).subscribe((response) => {
                    //console.log("delete response", response)
                    if (!response.success) {
                        console.log("delete error", response)
                        return
                    }
                    if (response.success) {
                        this._snackBar.open("Deleted", null, {
                            duration: 4000,
                        });
                        this.returnToSubmission()
                    }
                })
            } else {
            }
        });
    }
}

@Component({
    selector: 'budget-submission-delete-confirmation-dialog',
    templateUrl: './budget-submission-delete-confirmation-dialog.html',
})
export class BudgetSubmissionDeleteConfirmationDialog {
    faExclamationTriangle = faExclamationTriangle
    constructor(
        public dialogRef: MatDialogRef<BudgetSubmissionDeleteConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackRoute: SnackRouteService,
    ) { }
    faTimes = faTimes
    faBan = faBan
    faMinus = faMinus

    cancel() {
        this.snackRoute.snack("Action cancelled.")
        this.dialogRef.close();
    }

    delete() {
        this.dialogRef.close({});
    }
}
