import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OutreachService {

    constructor(
        private http: HttpClient,
    ) { }

    getOutreachCanUserCreateCampaign(): Observable<any> {
        return this.http.get<any>("api/outreachCanUserCreateCampaign/")
    }

    getOutreachMethods(submissionTypeId): Observable<any> {
        return this.http.get<any>("api/outreachMethods/" + submissionTypeId)
    }

    getOutreachSubmission(submissionId): Observable<any> {
        return this.http.get<any>("api/outreachSubmission/" + submissionId)
    }

    saveForLaterOutreachSubmission(body): Observable<any> {
        return this.http.post<any>("api/outreachSubmissionSaveForLater/", body)
    }

    submitOutreachSubmission(body): Observable<any> {
        return this.http.post<any>("api/outreachSubmissionSubmit/", body)
    }

    getOutreachProgramsPromise() {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/outreachPrograms").subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    getOutreachCountiesPromise() {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/outreachCounties").subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    newCampaignPromise(program, slogan, county, referenceNumber, sequence) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.put<any>("api/outreachNewCampaign", {
                program,
                slogan,
                county,
                reference_number: referenceNumber,
                sequence,
            }).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    getOutreachBudget() {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/outreachBudget").subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    getOutreachCampaigns() {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/outreachCampaigns").subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                resolve(response.result)
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }
}
