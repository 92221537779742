import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-manage-site-locations',
    templateUrl: './manage-site-locations.component.html',
})
export class ManageSiteLocationsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
