<h5 mat-dialog-title>HSNY Comment</h5>
<div mat-dialog-content>
  <mat-form-field class="full_width">
    <mat-label>Comment</mat-label>
    <textarea matInput type="text" [formControl]="comment" rows="5" cols="10"></textarea>
  </mat-form-field>
</div>
<mat-dialog-actions align="center">
  <button *ngIf="orgType == 'PRIME'" mat-raised-button color="primary" (click)="saveComment()">Save</button>
  <button *ngIf="orgType == 'PRIME' && commentExists" mat-raised-button color="warn" (click)="deleteComment()">Delete</button>
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>
