<div *ngIf="results$ | async as results" class="container-fluid">
    <div class="row mb-1" style="background-color: lightgrey;">
        <div class="col-8 page-title">
            <fa-icon [icon]="faFileExport"></fa-icon>
            WIC Program (FY22) Grant Application Submissions
        </div>
        <div class="col-4" align="right" style="padding-top: .4em;">
            Entries Per Page&nbsp;
            <app-input-enum [fc]="entriesPerPageFC" [style]="'none'" [class]="'inline-block'"
                [enumValuesNameAndId]="entriesPerPageOptions" [change]="entriesPerPageChange.bind(this)">
            </app-input-enum>
        </div>
    </div>

    <app-page-controls [pageNumber]="currentPage" [pageSize]="entriesPerPageFC.value" [entryCount]="count"
        [setPage]="this.setPage.bind(this)">
    </app-page-controls>
    <div class="row mt-1">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%">
                <thead>
                    <tr style="background-color: lightgrey">
                        <th *ngFor="let header of headers" style="vertical-align: top;" title="{{header.label}}">
                            {{header.title}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let result of results.result">
                        <td *ngFor="let header of headers">
                            <span *ngIf="typeOf(result.valueParsed[header.field]) == 'string'">
                                {{result.valueParsed[header.field]}}
                            </span>

                            <span *ngIf="typeOf(result.valueParsed[header.field]) == 'object'">
                                <span *ngFor="let file of result.data_cognito_forms_value_files">
                                    <div *ngIf="file.field_id == header.field">
                                        <span class="link"
                                            (click)="download(file.id, file.file_name)">{{file.file_name}}</span>
                                    </div>
                                </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>