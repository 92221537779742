import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-split-button',
    templateUrl: './split-button.component.html',
})
export class SplitButtonComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
