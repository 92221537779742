import { Component, OnInit, Input } from '@angular/core';
import { InputDateFormat } from '../input-date/input-date.component';

@Component({
    selector: 'app-input-enum',
    templateUrl: './input-enum.component.html',
    styleUrls: ['./input-enum.component.scss'],
})
export class InputEnumComponent implements OnInit {
    InputDateFormat = InputDateFormat
    @Input() label;
    @Input() fc;
    @Input() enumValues;
    @Input() enumValuesNameAndId;
    @Input() enumValuesDisplayAndId;
    @Input() change;
    @Input() requiredMarker;
    @Input() disabled;
    @Input() includeUnset;
    @Input() includeUnsetDisplay;
    @Input() style;
    @Input() naturalWidth;
    @Input() dateInputs;
    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    attributeIdentifier = this.randomString();
    @Input() rowClass;
    @Input() editClick;
    @Input() editValue;
    @Input() class;
    calcRowClass

    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    constructor() { }

    ngOnInit() {
        this.calcRowClass = {};
        this.calcRowClass[this.rowClass] = true;
    }

    getDisplayValue() {
        if (this.enumValues) {
            return this.fc.value;
        }
        if (this.enumValuesNameAndId) {
            for (var i = 0; i < this.enumValuesNameAndId.length; ++i) {
                if (this.enumValuesNameAndId[i].id == this.fc.value) {
                    return this.enumValuesNameAndId[i].name;
                }
            }
        }
        if (this.enumValuesDisplayAndId) {
            for (var i = 0; i < this.enumValuesDisplayAndId.length; ++i) {
                if (this.enumValuesDisplayAndId[i].id == this.fc.value) {
                    return this.enumValuesDisplayAndId[i].display_name;
                }
            }
        }
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }

}
