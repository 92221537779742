import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
})
export class CheckboxComponent implements OnInit {
    @Input() fc;
    @Input() change;
    @Input() keyupEnter;
    @Input() click
    @Input() id
    @Input() isDisabled

    constructor() { }

    ngOnInit() {
        console.log(this.isDisabled)
    }

    doChange(e) {
        if (this.change) {
            this.change(e);
        }
    }

    doClick(e) {
        if (this.click) {
            this.click(e);
        }
    }

    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }
}
