import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { iOutreachBankHead } from '../model/outreach-bank-head.model';
import { iOutreachBankDetail } from '../model/outreach-bank-detail.model';

@Injectable({
  providedIn: 'root'
})
export class OutreachBankService {

  constructor(private http: HttpClient) { }

  getbankHeadByOrgAndYear(organizationUuid: string, fiscalYearId: string): Observable<iOutreachBankHead[]> 
  {
    return this.http.get<iOutreachBankHead[]>('api/bankHeadByOrgAndYear/' + organizationUuid + '/' + fiscalYearId);
  }

  getbankDetailByOrgAndYear(organizationUuid: string, fiscalYearId: string): Observable<iOutreachBankDetail[]> 
  {
    return this.http.get<iOutreachBankDetail[]>('api/bankDetailByOrgAndYear/' + organizationUuid + '/' + fiscalYearId);
  }

  getbankHead(): Observable<iOutreachBankHead[]> 
  {
    return this.http.get<iOutreachBankHead[]>('api/bankHead/');
  }
}
