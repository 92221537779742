<div *ngIf="disabled">
    {{fc.value | date : disabledFormat}}
</div>
<div *ngIf="!disabled" style="display: inline-block" [class]="class">
    <div class="calendar-input">
        <input #editInput class="appinput" type="text" (keyup)="doChange()" [formControl]="displayFormControl" />
        <fa-icon class="dropdown-arrow py-1" [icon]="faCalendarDay" (click)="toggleShowCalendar()" size="1x"
            style="cursor: pointer; right: .8em; top: 0em;"></fa-icon>
        <span class="calendar-container" *ngIf="showCalendar">
            <div style="text-align: center; width: 100%">
                <fa-icon [icon]="faArrowLeft" size="1x" title="Previous Month" (click)="previousMonth()"
                    style="cursor: pointer;"></fa-icon>
                <div style="width: 5em; display: inline-block; text-align: center;">{{calendarDisplayMonthName}}</div>
                <fa-icon [icon]="faArrowRight" size="1x" title="Next Month" (click)="nextMonth()"
                    style="cursor: pointer;">
                </fa-icon>
                <fa-icon [icon]="faArrowLeft" class="ml-3" size="1x" title="Previous Year" (click)="previousYear()"
                    style="cursor: pointer;"></fa-icon>
                {{calendarDisplayYear}}
                <fa-icon [icon]="faArrowRight" size="1x" title="Next Year" (click)="nextYear()"
                    style="cursor: pointer;">
                </fa-icon>
            </div>
            <table style="width: 100%;">
                <tr>
                    <td align="center">
                        Sun
                    </td>
                    <td align="center">
                        Mon
                    </td>
                    <td align="center">
                        Tue
                    </td>
                    <td align="center">
                        Wed
                    </td>
                    <td align="center">
                        Thu
                    </td>
                    <td align="center">
                        Fri
                    </td>
                    <td align="center">
                        Sat
                    </td>
                </tr>
                <tr *ngFor="let week of days; let i = index">
                    <td *ngFor="let day of week; let j = index" (click)="clickDate(day)" align="center" [id]="day"
                        class="day" style="cursor: pointer;"
                        [ngClass]="{'selected-day': day.isSelected, 'current-day': day.isCurrent, 'off-month': !day.isCurrentMonth}">
                        {{day.time | date : 'd'}}
                    </td>
                </tr>
            </table>
        </span>
    </div>
</div>
<br *ngIf="!validDateInput" />
<span *ngIf="!validDateInput" class="validation-error">
    Format: mm/dd/yyyy or mm/dd/yy
</span>