import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from "rxjs";
import { Md5 } from 'ts-md5';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { iUser } from './model/user.model';
import { iUserStatus } from './model/user-status.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private _profileImageId = new BehaviorSubject(null);

    get profileImageId() {
        return this._profileImageId;
    }

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
    }

    isAppropriator() {
        return this.http.get<any>("api/userIsAppropriator")
    }

    validEmail(email) {
        return /\S+@\S+(\.\S+)?/.test(email)
    }

    userEmailExists(email): Observable<any> {
        return this.http.get<any>("api/userEmailExists/" + email)
    }

    addUser(
        organizationUuid,
        fullName,
        email,
        userStatusId,
        phone,
        photoId,
        permissions,
        primaryContact
    ): Observable<any> {
        var urlPrefix = window.location.href.substring(0, window.location.href.length - window.location.pathname.length - window.location.search.length);
        return this.http.put<any>("api/user", {
            organizationUuid,
            fullName,
            email,
            userStatusId,
            phone,
            photoId,
            urlPrefix,
            permissions,
            primaryContact
        }, httpOptions)
    }

    updateUser(
        uuid,
        fullName,
        email,
        userStatusId,
        phone,
        photoId,
        permissions,
        primaryContact
    ): Observable<any> {
        return this.http.post<any>("api/user", {
            uuid,
            fullName,
            email,
            userStatusId,
            phone,
            photoId,
            permissions,
            primaryContact
        }, httpOptions)
    }

    getUser(userUuid): Observable<any> {
        return this.http.get<any>("api/user/" + userUuid)
    }


    getSubmissionTypes(): Observable<any> {
        return this.http.get<any>("api/getSubmissionTypes")
    }

    getUserRoles(): Observable<any> {
        return this.http.get<any>("api/getUserRoles")
    }

    getUserRoleOptions(): Observable<any> {
        return this.http.get<any>("api/getUserRoleOptions")
    }

    passwordCheckAndSum(p1, p2, isOptionalPassword) {
        if (p1 == null) {
            p1 = "";
        }
        if (p2 == null) {
            p2 = "";
        }
        var passwordSpecified;
        if (isOptionalPassword) {
            passwordSpecified = !!p1;
        } else {
            passwordSpecified = true;
            if (!p1) {
                return { error: "A password must be specified." };
            }
        }
        if (passwordSpecified && !p2) {
            return { error: "The password must be confirmed." };
        }
        if (p1 != p2) {
            return { error: "Passwords do not match." };
        }
        if (passwordSpecified && p1.length < 8) {
            return { error: "Password must be at least 8 characters in length." };
        }

        if (passwordSpecified && !/.*[a-z].*/.test(p1)) {
            return { error: "Password must contain at least one lowercase letter." };
        }

        if (passwordSpecified && !/.*[A-Z].*/.test(p1)) {
            return { error: "Password must contain at least one uppercase letter." };
        }

        if (passwordSpecified && !/.*[0-9].*/.test(p1)) {
            return { error: "Password must contain at least one number." };
        }

        if (passwordSpecified && !/.*[!@#\$%\^&\*].*/.test(p1)) {
            return { error: "Password must contain at least one special character (!, @, #, $, %, ^, &, or *)." };
        }
        if (passwordSpecified) {
            return { hash: new Md5().appendStr(p1).end() };
        }
        return {};
    }

    setPassword(userId, newPassword, token, tokenType): Observable<any> {
        return this.http.post<any>("api/userForgotPasswordReset/", {
            id: userId,
            password: newPassword,
            token: token,
            tokenType: tokenType,
        }, httpOptions);
    }

    changePassword(userId, newPassword): Observable<any> {
        return this.http.post<any>("api/userChangePassword/", {
            id: userId,
            password: newPassword,
        }, httpOptions);
    }

    sendPasswordRecoveryEmail(username) {
        var urlPrefix = window.location.href.substring(0, window.location.href.length - window.location.pathname.length - window.location.search.length);
        return this.http.post<any>("api/userSendForgotPasswordEmail", {
            username,
            urlPrefix
        });
    }

    userByEmailForPasswordReset(email): Observable<any> {
        return this.http.get<any>("api/userByEmailForPasswordReset/" + email);
    }

    logLogout() {
        return this.http.post<any>("api/logLogout", null, httpOptions);
    }

    navigateToInitialPage() {
        this.router.navigate(["/dashboard"])
    }

    phoneUpdate(fc) {
        if (/^\d$/.test(fc.value)) {
            fc.setValue("(" + fc.value);
        }
        if (/^\(\d\d\d$/.test(fc.value)) {
            fc.setValue(fc.value + ") ");
        }
        if (/^\(\d\d\d\) \)$/.test(fc.value)) {
            fc.setValue(fc.value.substring(0, 6));
        }
        if (/^\(\d\d\d\) \d\d\d$/.test(fc.value)) {
            fc.setValue(fc.value + "-");
        }
        if (/^\(\d\d\d\) \d\d\d--$/.test(fc.value)) {
            fc.setValue(fc.value.substring(0, 10));
        }
    }

    getUserPermissions(): Observable<any> {
        return this.http.get<any>("api/userPermissions")
    }

    canUserPerformAction(userPermissions, actionId) {
        //note: in the future, if a user can belong to multiple organizations, this will need to check organization uuid
        if (userPermissions) {
            for (var i = 0; i < userPermissions.length; ++i) {
                if (userPermissions[i].action == "master") {
                    return true
                }
                if (userPermissions[i].action == actionId) {
                    return true;
                }
            }
        }
        return false
    }

    assignRoles(userId: string, orgId: string): Observable<any> {
        return this.http.post<any>("api/assignRoles", {
            userId: userId,
            orgId: orgId
        }, httpOptions)
    }

    removeRoles(userId: string): Observable<any> {
        return this.http.post<any>("api/removeRoles", {
            userId: userId
        }, httpOptions)
    }

    rolesExist(userId: string, orgId: string): Observable<any> {
        return this.http.get<any>("api/rolesExist/" + userId + "/" + orgId)
    }

    // aarons code starts here

    getAllUsers(): Observable<iUser[]>
    {
        return this.http.get<iUser[]>('api/allUsers')
    }

    getUserStatuses(): Observable<iUserStatus[]> 
    {
        return this.http.get<iUserStatus[]>("api/userStatuses")
    }

    lockUser(uuid: string): Observable<iUser>
    {
        return this.http.post<iUser>("api/lockUser", { uuid })
    }

    activateUser(uuid: string): Observable<iUser>
    {
        return this.http.post<iUser>("api/activateUser", { uuid })
    }

    inactivateUser(uuid: string): Observable<iUser>
    {
        return this.http.post<iUser>("api/inactivateUser", { uuid })
    }
}
