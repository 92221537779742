import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    clientError = [];
    profileFileURL = "api/profileFile";

    constructor(
        private http: HttpClient,
    ) { }

    getProfileFile(uuid) {
        var options: any = { responseType: 'blob' };
        return this.http.get<any>(this.profileFileURL + "/" + uuid, options);
    }

    downloadDocumentFile(uuid) {
        var options: any = { responseType: 'blob' };
        return this.http.get<any>("api/documentFile/" + uuid, options);
    }

    deleteDocumentFile(uuid) {
        return this.http.delete<any>("api/documentFile/" + uuid);
    }

    getDocumentFileMeta(uuid) {
        return this.http.get<any>("api/documentFileMeta" + "/" + uuid);
    }

    getInactivityTimeout() {
        return this.http.get<any>("api/inactivityTimeoutInSeconds");
    }

    getMenuItems() {
        return this.http.get<any>("api/menuItems");
    }

    getImageHeight(width, height, newWidth) {
        var ratio = width / height;
        return newWidth / ratio;
    }

    getImageWidth(width, height, newHeight) {
        var ratio = width / height;
        return ratio * newHeight;
    }

    getUserFirstLetter(userFullName, username) {
        if (userFullName && userFullName.trim()) {
            return userFullName.trim().substring(0, 1).toUpperCase();
        }
        if (username && username.trim()) {
            return username.trim().substring(0, 1).toUpperCase();
        }
        return " "
    }

    loadProfileImage(photo, profileImageElement, userFullName, username) {
        if (photo) {
            console.log("debug loading photo", photo)
            this.getProfileFile(photo).subscribe((response) => {
                var reader = new FileReader();
                reader.readAsDataURL(<any>response);
                reader.onloadend = () => {
                    var img = document.createElement('img');
                    (<any>img.src) = reader.result;
                    img.onload = function () {
                        var newHeight = 32;
                        var newWidth = this.getImageWidth(img.width, img.height, newHeight);
                        img.width = newWidth;
                        img.height = newHeight;
                        console.log("loading into element", profileImageElement)
                        var elem = document.getElementById(profileImageElement)
                        if (elem) {
                            while (elem.firstChild) {
                                elem.removeChild(elem.firstChild);
                            }
                            elem.appendChild(img);
                        }
                    }.bind(this);
                }
            });
        } else {
            var img = document.createElement('div');
            img.style.display = "inline-block";
            img.style.backgroundColor = "#ea6820";
            img.style.height = "32px";
            img.style.width = "32px";
            img.style.borderRadius = "16px";
            img.style.color = "white"
            img.align = "center"
            img.style.position = "relative"

            var letter = document.createElement('span');
            letter.style.fontSize = "28px"
            letter.style.fontWeight = "normal"
            letter.innerHTML = this.getUserFirstLetter(userFullName, username);
            letter.className = "avatar-letter-center";
            img.appendChild(letter);
            var elem = document.getElementById(profileImageElement)
            if (elem) {
                while (elem.firstChild) {
                    elem.removeChild(elem.firstChild);
                }
                elem.appendChild(img);
            }
        }
    }

    // doLoadProfileImage() {
    //     setTimeout(() => {
    //         this.userService.profileImageId.next(this.photo);
    //         this.userService.profileImageId.subscribe((photoId) => {
    //             this.photo = photoId;
    //             this.loadProfileImage();
    //         });
    //     }, 0)
    // }

    getColumnTypePromise(tableName, columnName) {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.http.get<any>("api/columnType" + "/" + tableName + "/" + columnName).subscribe((response) => {
                if (!response.success) {
                    return reject(response)
                }
                if (!response.result || response.result.length != 1) {
                    return reject("nonsingular type definition")
                }
                return resolve(response.result[0])
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }

    getEnvironmentType() {
        return this.http.get<any>("api/getEnvironmentType");
    }

    getSystemProperty(propertyName) {
        return this.http.get<any>("api/systemProperty/" + propertyName);
    }

    getEmailTemplate(id) {
        return this.http.get<any>("api/emailTemplate/" + id);
    }

}
