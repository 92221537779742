<div class="row">
    <div class="col-4 bold data-row">
        {{label}}
        <fa-icon *ngIf="commentId && showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick(commentId)"
            [title]="commentData[commentId].showInputContainer.value ? 'Cancel' : 'Edit'">
        </fa-icon>
    </div>
    <div class="col-8">
        <input *ngIf="!disabled" type="text" style="border-color: lightgrey;"
            class="labeled-input mt-1" [formControl]="fc"
            (keyup)="doChange($event)" /><span *ngIf="disabled">{{fc.value}}</span> %
    </div>
</div>
<app-outreach-submission-comment *ngIf="commentId" [submissionUuid]="submissionUuid" [inputId]="commentId"
    [commentUuid]="commentData[commentId].commentUuid" [comment]="commentData[commentId].comment"
    [showInputContainer]="commentData[commentId].showInputContainer" [showFlag]="showFlag" [odd]="false"
    [targetUuid]="targetUuid">
</app-outreach-submission-comment>