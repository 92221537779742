<span *ngIf="typeNode.fc.value">
    <h4>{{ typeNode.fc.value | dbtitlecase | titlecase }}</h4>
    <span *ngFor="let column of schema.result[typeNode.fc.value].definition; let index = index">
        <div class="row mt-2">
            <div class="col-4 bold">
                {{column.Field}}
            </div>
            <div class="col-8">
                <app-typed [fc]="entry[column.Field + 'FC']" [disabled]="!newEntry && column.Key == 'PRI'"
                    [columnDefinition]="column"
                    [values]="referencedTypes[column.referenced_table_name] ? referencedTypes[column.referenced_table_name].enumNameAndId : null">
                </app-typed>
            </div>
        </div>
        <!-- <div *ngIf="showFieldGenerateUuid(column)" class="row">
            <div class="col-4">
            </div>
            <div class="col-8">
                <span (click)="fieldGenerateUuid(column)" style="font-size: .8em; cursor: pointer;">(generate)</span>
            </div>
        </div> -->
    </span>
    <div *ngFor="let child of typeNode.children; let index = index" style="margin-left: 2em;">
        <span *ngFor="let childValue of entry[child.fc.value + 's']">
            <app-crud-child-node [schema]="schema" [entry]="childValue" [referencedTypes]="referencedTypes"
                [typeNode]="child" [depth]="depth + 1">
            </app-crud-child-node>
        </span>
        <!-- this.attachFCs(schema, node.children[i], value[node.children[i].fc.value + "s"][j]) -->
    </div>

    <div align="center">
        <app-button label="Save" *ngIf="depth == 0"></app-button>
    </div>
</span>