<span *ngIf="initialized">

    <!-- <div *ngIf="editable" class="row">
        <div class="col px-0 text-right">
            <app-button3 label="Add Personnel" [clickFunction]="addPersonnel.bind(this)" [icon]="faPlusSquare"
                [small]="true">
            </app-button3>
            <app-button3 label="Add &nbsp;Other&nbsp;" [clickFunction]="addOther.bind(this)" [icon]="faPlusSquare"
                [small]="true" class="ml-2">
            </app-button3>
        </div>
    </div> -->

    <span *ngIf="this.groups.length > 0">
        <div class="row">
            <div class="col px-0">
                <table width="100%">
                    <tr *ngFor="let entry of flattenedEntries" [ngStyle]="{ 'background-color' : entry.backgroundColor}"
                        [ngClass]="{ 'user-flagged' : entry.user_flag && 
                        (this.userOrganizationType == 'PRIME' || this.userOrganizationType == 'SYSTEM') }">
                        <td *ngIf="entry.uiEntryType == UIEntryType.title" colspan="7"
                            style="padding-top: .8em; padding-left: .5em;">
                            <h3 style="display: inline-block;">{{entry.group}}</h3>
                        </td>

                        <th *ngIf="entry.uiEntryType == UIEntryType.columnNames">
                            <span class="sort-column-header" style="padding-left: 2em;"
                                (click)="sortClick(entry.ui_column_names, 'account_name')">
                                Account
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                                [sortIsDescending]="this.sortDirection" [columnFieldName]="'account_name'">
                            </app-sort-indicator>
                        </th>
                        <th *ngIf="entry.uiEntryType == UIEntryType.columnNames">
                            <span class="sort-column-header" (click)="sortClick(entry.group, 'name')">
                                Description
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                                [sortIsDescending]="this.sortDirection" [columnFieldName]="'name'">
                            </app-sort-indicator>
                        </th>
                        <th *ngIf="entry.uiEntryType == UIEntryType.columnNames" class="right-aligned-money-header">
                            <span class="sort-column-header" (click)="sortClick(entry.group, 'todo')">
                                State Only
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                                [sortIsDescending]="this.sortDirection" [columnFieldName]="'todo'">
                            </app-sort-indicator>
                        </th>
                        <th *ngIf="entry.uiEntryType == UIEntryType.columnNames" class="right-aligned-money-header">
                            <span class="sort-column-header" (click)="sortClick(entry.group, 'todo')">
                                State / Federal Match
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                                [sortIsDescending]="this.sortDirection" [columnFieldName]="'todo'">
                            </app-sort-indicator>
                        </th>
                        <th *ngIf="entry.uiEntryType == UIEntryType.columnNames" class="right-aligned-money-header">
                            <span class="sort-column-header" (click)="sortClick(entry.group, 'todo')">
                                Total
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                                [sortIsDescending]="this.sortDirection" [columnFieldName]="'todo'">
                            </app-sort-indicator>
                        </th>
                        <th *ngIf="entry.uiEntryType == UIEntryType.columnNames" class="right-aligned-money-header">
                            <span class="sort-column-header" (click)="sortClick(entry.group, 'todo')">
                                Annual Budget
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                                [sortIsDescending]="this.sortDirection" [columnFieldName]="'todo'">
                            </app-sort-indicator>
                        </th>
                        <th *ngIf="entry.uiEntryType == UIEntryType.columnNames" class="right-aligned-money-header">
                            <span class="sort-column-header" (click)="sortClick(entry.group, 'todo')">
                                Balance
                            </span>
                            <app-sort-indicator class="ml-1" [sortFieldName]="this.sortField"
                                [sortIsDescending]="this.sortDirection" [columnFieldName]="'todo'">
                            </app-sort-indicator>
                        </th>

                        <td *ngIf="entry.uiEntryType == UIEntryType.entry && (entry.screen == '_PERSONNEL' || entry.screen == '_BENEFITS')"
                            [ngStyle]="{paddingLeft: entry.mark_new || entry.mark_modified ? '.74em' : '2em'}">
                            <fa-icon *ngIf="entry.mark_new" class="mr-1" [icon]="faCircle" size="1x" title="New"
                                style="color: green;">
                            </fa-icon>
                            <fa-icon *ngIf="entry.mark_modified" class="mr-1" [icon]="faCircle" size="1x"
                                title="Modified" style="color: yellow;">
                            </fa-icon>

                            <a routerLink="/voucher-edit"
                                [queryParams]=" { accountUuid: entry.uuid, submissionUuid: submissionUuid, editable: editable, showFlag: showFlag, dd: dd }">{{entry.account_name}}</a>
                            <fa-icon *ngIf="entry.has_comment" [icon]="faFlag" class="has-comment-flag ml-2"
                                [title]="'Has Comment'">
                            </fa-icon>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.entry && (entry.screen != '_PERSONNEL' && entry.screen != '_BENEFITS')"
                            [ngStyle]="{paddingLeft: entry.mark_new || entry.mark_modified ? '.74em' : '2em'}">
                            <fa-icon *ngIf="entry.mark_new" class="mr-1" [icon]="faCircle" size="1x" title="New"
                                style="color: green;">
                            </fa-icon>
                            <fa-icon *ngIf="entry.mark_modified" class="mr-1" [icon]="faCircle" size="1x"
                                title="Modified" style="color: yellow;">
                            </fa-icon>

                            <a routerLink="/voucher-edit"
                                [queryParams]="{accountUuid: entry.uuid, submissionUuid: submissionUuid, editable: editable, showFlag: showFlag, dd: dd}">{{entry.account_name}}</a>

                            <fa-icon *ngIf="entry.has_comment" [icon]="faFlag" class="has-comment-flag ml-2"
                                [title]="'Has Comment'">
                            </fa-icon>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.entry">
                            {{entry.name}}
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.entry" class="right-aligned-money-header">
                            <span class="right-aligned-money">
                                {{entry.total_state | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.entry" class="right-aligned-money-header">
                            <span class="right-aligned-money">
                                {{entry.total_federal | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.entry" class="right-aligned-money-header">
                            <span class="right-aligned-money">
                                {{entry.total | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.entry" class="right-aligned-money-header">
                            <span class="right-aligned-money">
                                {{entry.total_spent | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.entry" class="right-aligned-money-header">
                            <span class="right-aligned-money">
                                {{entry.balance | currency}}
                            </span>
                        </td>

                        <td *ngIf="entry.uiEntryType == UIEntryType.totals" class="right-aligned-money-header bold"
                            colspan="2">
                            Totals
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.totals" class="right-aligned-money-header bold">
                            <span class="right-aligned-money">
                                {{entry.totals.total_state | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.totals" class="right-aligned-money-header bold">
                            <span class="right-aligned-money">
                                {{entry.totals.total_federal | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.totals" class="right-aligned-money-header bold">
                            <span class="right-aligned-money">
                                {{entry.totals.total | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.totals" class="right-aligned-money-header bold">
                            <span class="right-aligned-money">
                                {{entry.totals.total_spent | currency}}
                            </span>
                        </td>
                        <td *ngIf="entry.uiEntryType == UIEntryType.totals" class="right-aligned-money-header bold">
                            <span class="right-aligned-money">
                                {{entry.totals.balance | currency}}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </span>

    <div class="row allocation-row mt-5">
        <div class="col-2 text-center">
            State Only Starting Balance
        </div>
        <div class="col-2 text-center">
            State Only Current Voucher
        </div>
        <div class="col-2 text-center">
            State Only Ending Balance
        </div>
        <div class="col-2 text-center">
            State/Fed Match Starting Balance
        </div>
        <div class="col-2 text-center">
            State/Fed Match Current Voucher
        </div>
        <div class="col-2 text-center">
            State/Fed Match Ending Balance
        </div>
        <div class="col-2 text-center">
        </div>
    </div>
    <div class="row">
        <div class="col-2 text-center">
            <span class="right-aligned-money">
                {{allocation.spent_state | currency}}
            </span>
        </div>
        <div class="col-2 text-center">
            <span class="right-aligned-money">
                {{allocation.total_state | currency}}
            </span>
        </div>
        <div class="col-2 text-center">
            <span class="right-aligned-money">
                {{allocation.balance_state | currency}}
            </span>
        </div>
        <div class="col-2 text-center">
            <span class="right-aligned-money">
                {{allocation.spent_federal | currency}}
            </span>
        </div>
        <div class="col-2 text-center">
            <span class="right-aligned-money">
                {{allocation.total_federal | currency}}
            </span>
        </div>
        <div class="col-2 text-center">
            <span class="right-aligned-money">
                {{allocation.balance_federal | currency}}
            </span>
        </div>
    </div>
    <!--
    <div *ngIf="allocation.federal_spent != 0 || allocation.state_spent != 0" class="row mt-3">
        <div class="col text-center">
            All federal and state funds allocated, total remaining must be $0, in order to submit.
        </div>
    </div>
    -->

    <!--
    <div class="row mt-3 grey-bordered">
        <div class="col">
            <div class="row mt-3 ">
                <div class="col">
                    Total Granted: {{allocation.state_budget + allocation.federal_budget | currency}}
                </div>
                <div class="col">
                    15% of Total: {{(allocation.state_budget + allocation.federal_budget) * .15 | currency}}
                </div>
                <div class="col">
                    Total Administrative Allocated: {{administrativeAllocated | currency}}
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-center">
                    Total administrative allocated cannot exceed 15% of the total budget granted
                </div>
            </div>
        </div>
    </div>
    -->

    <div *ngIf="editable" class="row mt-3">
        <div class="col text-center">
            <app-button3 label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan" class="mx-2">
            </app-button3>
            <app-button3 label="Save for Later" [clickFunction]="saveForLater.bind(this)" [icon]="faSave" class="mx-2"
                [isFinal]="true">
            </app-button3>
            <app-button3 label="Finish Voucher" [clickFunction]="submit.bind(this)" [icon]="faShare" class="mx-2"
                [disabled]="allocation.federal_spent != 0 || allocation.state_spent != 0" [isFinal]="true">
            </app-button3>
        </div>
    </div>
</span>