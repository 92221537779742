<app-button3 *ngIf="showFlag && showCreateButton && !(showInputContainer.value || commentValue)" class="mx-1" label="Add Comment"
    [small]="true" [clickFunction]="showCommentInput.bind(this)" [icon]="faEdit">
</app-button3>
<div *ngIf="showInputContainer.value || commentValue" class="row pt-1" [ngClass]="{ 'odd-row': odd }">
    <div class="col-3">
        <app-button3 *ngIf="showFlag && showCreateButton && !showInputContainer.value && commentValue" class="mx-1"
            label="Edit Comment" [small]="true" [clickFunction]="showCommentInput.bind(this)" [icon]="faEdit">
        </app-button3>
        <span *ngIf="!showFlag && showCreateButton && !showInputContainer.value && commentValue">
            Agency Comment
        </span>
        <span *ngIf="!showFlag && showHSNYLabel && !showInputContainer.value && commentValue">
            HSNY Comment
        </span>
    </div>
    <div class="col-6">
        <fa-icon *ngIf="showInputContainer.value || commentValue" style="font-size: 1.25em;"
            [icon]="faExclamationTriangle" class="alert-icon" (click)="toggleCommentInput()"
            [title]="showInputContainer.value ? 'Cancel' : 'Edit'">
        </fa-icon>

        <span *ngIf="!showInputContainer.value && commentValue" class="comment-text">{{commentValue}}</span>
        <span *ngIf="showInputContainer.value">
            <textarea [rows]="1" [formControl]="commentFC" class="ml-2"
                style="vertical-align: top; resize: both; border-radius: .3em; width: 16em; border-color: lightgrey;">
            </textarea>
            <div class="comment-button" (click)="saveComment()" [title]="commentUuid ? 
                    (commentFC.value ? 'Update comment' : 'Delete comment') : 
                    (commentFC.value ? 'Create comment' : 'Cancel')">
                <fa-icon [icon]="faCheck"></fa-icon>
            </div>
            <div class="comment-button" (click)="deleteComment()" [title]="commentUuid ? 'Delete comment' : 'Cancel'">
                <fa-icon [icon]="faTimes"></fa-icon>
            </div>
        </span>
    </div>
</div>