import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { QuestionService } from '../../question.service';
import { iAnswerSpecial } from '../../model/answer-special.model';

@Component({
  selector: 'answer-dialog',
  templateUrl: './answer-dialog.html',
  styleUrls: ['../edit-question.css']
})
export class AnswerDialog 
{
  answer: iAnswerSpecial;
  isEdit: boolean;
  questionUuid: string;

  answerForm = new UntypedFormGroup({
    display: new UntypedFormControl(0, [Validators.required]),
    name: new UntypedFormControl('', [Validators.required])
  });

  constructor(
    public dialogRef: MatDialogRef<AnswerDialog>,
    private questionService: QuestionService,
    @Inject(MAT_DIALOG_DATA) public data: { answer?: iAnswerSpecial, isEdit: boolean, questionUuid?: string }
  ) {
    this.isEdit = data.isEdit;
    this.answer = { display: 0, name: '', questionUuid: '' };
    this.questionUuid = data.questionUuid

    if (this.isEdit) 
    {
      this.answerForm.patchValue({
        display: data.answer.display,
        name: data.answer.name
      });
    }
    console.log(this.data.answer)
  }

  onSubmit(): void 
  {
    if (this.answerForm.valid) 
    {
      this.answer.display = this.answerForm.get("display").value;
      this.answer.name = this.answerForm.get("name").value;
      this.answer.questionUuid = this.questionUuid;

      if (this.isEdit) 
      {
        this.answer.uuid = this.data.answer.uuid

        this.questionService.updateAnswer(this.answer).subscribe({
          next: (result) => {
            console.log(`Answer updated: ${this.answer}`);
            this.dialogRef.close(this.answer);
          },
          error: (error) => {
            console.log(error);
          }
        });
      } else 
      {
        this.questionService.addAnswer(this.answer).subscribe({
          next: (result) => {
            console.log(`Answer added: ${this.answer}`);
            this.dialogRef.close(this.answer);
          },
          error: (error) => {
            console.log(error);
          }
        });
      }

    } else 
    {
      return;
    }
  }

  onDelete(): void 
  {
    if (this.isEdit) 
    {
      this.questionService.deleteAnswer(this.data.answer).subscribe({
        next: (result) => {
          console.log(`Deletion result: ${result.message}`);
          this.dialogRef.close();
        },
        error: (error) => {
          console.log(error);
        }
      });
    }
  }
}
