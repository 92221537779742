<h1 mat-dialog-title>Agency ID: {{ data.agency_uuid }}</h1>
<h2 mat-dialog-title>Agency Name: {{ data.agency_name }}</h2>
<h3 mat-dialog-title>County Name: {{ data.county_name }}</h3>


<mat-dialog-content [formGroup]="form" style="display: flex; flex-direction: column; align-items: center;">
    <p><mat-form-field>
        <mat-label>Main Target</mat-label>
        <input matInput
                type="number"
                placeholder="Main Target"
                value="{{data.main_target}}"
                formControlName="main_target" required>
    </mat-form-field></p>
    <p><mat-form-field>
        <mat-label>Outreach Campaigns</mat-label>
        <input matInput
                type="number"
                placeholder="Outreach Campaigns"
                value="{{data.outreach_target}}"
                formControlName="outreach_target" required>
    </mat-form-field></p>
    <p><mat-form-field>
        <mat-label>Person To Person</mat-label>
        <input matInput
                type="number"
                placeholder="Person To Person"
                value="{{data.person_target}}"
                formControlName="person_target" required>
    </mat-form-field></p>
    <p><mat-form-field>
        <mat-label>Prescreens</mat-label>
        <input matInput
                type="number"
                placeholder="Prescreens"
                value="{{data.prescreen_target}}"
                formControlName="prescreen_target" required>
    </mat-form-field></p>
    <p><mat-form-field>
        <mat-label>Recertifications</mat-label>
        <input matInput
                type="number"
                placeholder="Recertifications"
                value="{{data.recertification_target}}"
                formControlName="recertification_target" required>
    </mat-form-field></p>
    <p><mat-form-field>
        <mat-label>Barrier Narratives</mat-label>
        <input matInput
                type="number"
                placeholder="Barrier Narratives"
                value="{{data.barrier_target}}"
                formControlName="barrier_target" required>
    </mat-form-field></p>
    <p><mat-form-field>
        <mat-label>Collaboration Narratives</mat-label>
        <input matInput
                type="number"
                placeholder="Collaboration Narratives"
                value="{{data.collaboration_target}}"
                formControlName="collaboration_target" required>
    </mat-form-field></p>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="save()">Save</button>
    <button mat-raised-button (click)="close()" style="margin-right: 5px;">Close</button>
</mat-dialog-actions>