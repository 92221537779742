<span *ngIf="!initialized">
    <div class="container container mt-4">
        Initializing...
    </div>
</span>

<div *ngIf="initialized" class="container container mt-4">
    <div class="row pb-1">
        <div class="col">
            <h1>PRIVACY POLICY</h1>
            <h5 class="update">Last updated September 23, 2018</h5>

            <h3>INTRODUCTION</h3>

            <p>HSNYonline (“we” or “us” or “our”) respects the privacy of our users
                (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and
                safeguard your information when you visit our website,
                including any other media form, media channel, or mobile website related
                or connected thereto (collectively, the “Site”). Please read this
                privacy policy carefully. If you do not agree with the terms of this privacy policy, please
                do not access the site.</p>

            <p>We reserve the right to make changes to this Privacy Policy at any time and for any
                reason. We will alert you about any changes by updating the “Last Updated” date of
                this Privacy Policy. Any changes or modifications will be effective immediately upon
                posting the updated Privacy Policy on the Site, and you waive the right to receive
                specific notice of each such change or modification.</p>

            <p>You are encouraged to periodically review this Privacy Policy to stay informed of
                updates. You will be deemed to have been made aware of, will be subject to, and will be
                deemed to have accepted the changes in any revised Privacy Policy by your continued
                use of the Site after the date such revised Privacy Policy is posted.</p>

            <h3>COLLECTION OF YOUR INFORMATION</h3>
            <p>We may collect information about you in a variety of ways. The information we may
                collect on the Site includes:</p>

            <h5>Personal Data</h5>

            <p>Personally identifiable information, such as your name, shipping address, email
                address, and telephone number, and demographic information, such as your age,
                gender, hometown, and interests, that you voluntarily give to us when you register with
                the Site or when you choose to participate in various
                activities related to the Site, such as online chat and
                message boards. You are under no obligation to provide us with personal information of
                any kind other than your email address, however your refusal to do so may
                prevent you from using certain features of the Site.</p>

            <h5>Derivative Data</h5>
            <p>Information our servers automatically collect when you access the Site, such as your IP
                address, your browser type, your operating system, your access times, and the pages
                you have viewed directly before and after accessing the Site. This information may
                also include your device name and type, your phone number, your country,
                and other interactions with the application and other users via server log files, as well as
                any other information you choose to provide.</p>

            <h5>Financial Data</h5>

            <p>Financial information, such as data related to your payment method (e.g. valid credit
                card number, card brand, expiration date) that we may collect when you purchase,
                order, return, exchange, or request information about our services from the Site.</p>

            <h5>Data From Social Networks</h5>

            <p>User information from social networking sites, such as Apple’s Game Center,
                Facebook, Google+, Instagram, Pinterest, Twitter, etc, including your name, your social
                network username, location, gender, birth date, email address, profile picture, and
                public data for contacts, if you connect your account to such social networks.
                This information may also include the contact information of
                anyone you invite to use and/or join our Site.</p>

            <h5>Mobile Device Data</h5>

            <p>Device information, such as your mobile device ID, model, and manufacturer, and
                information about the location of your device, if you access the Site from a mobile
                device.</p>

            <h5>Third-Party Data</h5>

            <p>Information from third parties, such as personal information or network friends, if you
                connect your account to the third party and grant the Site permission to access this
                information.</p>

            <h5>Data From Contests, Giveaways, and Surveys</h5>

            <p>Personal and other information you may provide when entering contests or giveaways
                and/or responding to surveys.</p>

            <h3>DISCLOSURE OF YOUR INFORMATION</h3>

            <p>We may share information we have collected about you in certain situations. Your
                information may be disclosed as follows: </p>

            <h5>By Law or to Protect Rights</h5>
            <p>If we believe the release of information about you is necessary to respond to legal
                process, to investigate or remedy potential violations of our policies, or to protect the
                rights, property, and safety of others, we may share your information as permitted or
                required by any applicable law, rule, or regulation. This includes exchanging
                information with other entities for fraud protection and credit risk reduction.</p>

            <h5>Third-Party Service Providers</h5>

            <p>We may share your information with third parties that perform services for us or on our
                behalf, including payment processing, data analysis, email delivery, hosting services,
                customer service, and marketing assistance.</p>

            <h5>Marketing Communications</h5>

            <p>With your consent, or with an opportunity for you to withdraw consent, we may share
                your information with third parties for marketing purposes, as permitted by law.</p>

            <h5>Interactions with Other Users</h5>

            <p>If you interact with other users of the Site, those users may
                see your name, profile photo, and descriptions of your activity, including sending
                invitations to other users, chatting with other users, liking posts, following blogs.</p>

            <h5>Online Postings</h5>

            <p>When you post comments, contributions or other content to the Site, your posts
                may be viewed by all users and may be publicly distributed outside the Site in perpetuity.
            </p>

            <h5>Third-Party Advertisers</h5>

            <p>We may use third-party advertising companies to serve ads when you visit the Site.
                These companies may use information about your visits to the
                Site and other websites that are contained in web cookies
                in order to provide advertisements about goods and services of interest to you.</p>

            <h5>Affiliates</h5>
            <p>We may share your information with our affiliates, in which case we will require those
                affiliates to honor this Privacy Policy. Affiliates include our parent company and any
                subsidiaries, joint venture partners or other companies that we control or that are under
                common control with us.</p>

            <h5>Business Partners</h5>
            <p>We may share your information with our business partners to offer you certain products,
                services or promotions.</p>

            <h5>Other Third Parties</h5>
            <p>We may share your information with advertisers and investors for the purpose of
                conducting general business analysis. We may also share your information with such
                third parties for marketing purposes, as permitted by law.</p>

            <h5>Sale or Bankruptcy</h5>

            <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired
                by another entity, we may transfer your information to the successor entity. If we go out
                of business or enter bankruptcy, your information would be an asset transferred or
                acquired by a third party. You acknowledge that such transfers may occur and that the
                transferee may decline to honor commitments we made in this Privacy Policy.</p>

            <p>We are not responsible for the actions of third parties with whom you share personal or
                sensitive data, and we have no authority to manage or control third-party solicitations. If
                you no longer wish to receive correspondence, emails or other communications from
                third parties, you are responsible for contacting the third party directly.</p>

            <h3>TRACKING TECHNOLOGIES</h3>

            <h5>Cookies and Web Beacons</h5>

            <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on
                the Site to help customize the Site and improve your experience. When you access the Site
                your personal information is not collected through the use of tracking
                technology. Most browsers are set to accept cookies by default. You can remove or
                reject cookies, but be aware that such action could affect the availability and
                functionality of the Site. You may not decline web beacons.</p>

            <h5>Internet-Based Advertising</h5>

            <p>We may use third-party software to serve ads on the Site, implement email
                marketing campaigns, and manage other interactive
                marketing initiatives. This third-party software may use cookies or similar tracking
                technology to help manage and optimize your online experience with us.</p>

            <h5>Website Analytics</h5>

            <p>We may also partner with selected third-party vendors to allow
                tracking technologies and remarketing services on the Site
                through the use of first party cookies and third-party cookies, to, among other things,
                analyze and track users’ use of the Site, determine the
                popularity of certain content and better understand online activity. By accessing the
                Site, you consent to the collection and use of your information
                by these third-party vendors. You are encouraged to review their privacy policy and
                contact them directly for responses to your questions.</p>

            <h3>THIRD-PARTY WEBSITES</h3>
            <p>The Site may contain links to third-party websites and
                applications of interest, including advertisements and external services, that are not
                affiliated with us. Once you have used these links to leave the Site, any
                information you provide to these third parties is not covered by this
                Privacy Policy, and we cannot guarantee the safety and privacy of your information.
                Before visiting and providing any information to any third-party websites, you should
                inform yourself of the privacy policies and practices (if any) of the third party
                responsible
                for that website, and should take those steps necessary to, in your discretion, protect
                the privacy of your information. We are not responsible for the content or privacy and
                security practices and policies of any third parties, including other sites, services or
                applications that may be linked to or from the Site.</p>

            <h3>SECURITY OF YOUR INFORMATION</h3>
            <p>We use administrative, technical, and physical security measures to help protect your
                personal information. While we have taken reasonable steps to secure the personal
                information you provide to us, please be aware that despite our efforts, no security
                measures are perfect or impenetrable, and no method of data transmission can be
                guaranteed against any interception or other type of misuse. Any information disclosed
                online is vulnerable to interception and misuse by unauthorized parties. Therefore, we
                cannot guarantee complete security if you provide personal information.</p>

            <h3>POLICY FOR CHILDREN</h3>
            <p>We do not knowingly solicit information from or market to children under the age of 13. If
                you become aware of any data we have collected from children under age 13, please
                contact us using the contact form present on the website.</p>

            <h3>CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
            <p>Web browsers and some mobile operating systems may
                include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy
                preference not to have data about your online browsing activities monitored and
                collected. No uniform technology standard for recognizing and implementing DNT
                signals has been finalized. As such, we do not currently respond to DNT browser
                signals or any other mechanism that automatically communicates your choice not to be
                tracked online.</p>

            <h3>ACCOUNT TERMINATION</h3>

            <p>Upon your request to terminate your account, we will deactivate or delete your account
                and information from our active databases. However, some information may be retained
                in our files to prevent fraud, troubleshoot problems, assist with any investigations,
                enforce our Terms of Use and/or comply with legal requirements.</p>

            <p>If you no longer wish to receive correspondence, emails, or other communications from
                third parties, you are responsible for contacting the third party directly.</p>

            <h3>CONTACT US</h3>
            <p>If you have questions or comments about this Privacy Policy, please contact us using
                the contact form on our website.</p>
        </div>
    </div>
</div>