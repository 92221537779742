import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan, faCheck, faCircle, faExclamationTriangle, faFlag, faHourglass, faMinus, faMoneyBillWave, faPlusSquare, faSave, faShare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../auth.service';
import { BudgetService } from '../budget.service';
import { CrudService } from '../crud.service';
import { SnackRouteService } from '../snack-route.service';
import { SubmissionCommentService } from '../submission-comment.service';
import { SubmissionService } from '../submission.service';

@Component({
    selector: 'app-budget-submission',
    templateUrl: './budget-submission.component.html',
    styleUrls: ['./budget-submission.component.scss']
})
export class BudgetSubmissionComponent implements OnInit {
    @Input() showFlag: boolean
    @Input() editable: boolean

    faFlag = faFlag

    inputDataById = {}
    flagClick(inputId) {
        var inputData = this.inputDataById[inputId]
        if (inputData) {
            inputData.showInputContainer.value = !inputData.showInputContainer.value
        } else {
            this.inputDataById[inputId] = {
                showInputContainer: { value: true }
            }
        }
    }

    initialized: boolean = false
    faPlusSquare = faPlusSquare
    faBan = faBan
    faShare = faShare
    faSave = faSave
    faCircle = faCircle
    constructor(
        private budgetService: BudgetService,
        private activatedRoute: ActivatedRoute,
        private _snackBar: MatSnackBar,
        public dialog: MatDialog,
        private snackRoute: SnackRouteService,
        private router: Router,
        private authService: AuthService,
        private submissionService: SubmissionService,
        private crudService: CrudService,
    ) { }

    UIEntryType = {
        title: 0,
        columnNames: 1,
        entry: 2,
        totals: 3,
        comment: 4,
    }

    subscriptions = []
    submissionUuid

    allocation: any = {};

    sortField = "account_name"
    sortDirection
    columns = [
        {
            display: "Account",
            fieldName: "account_name",
        }, {
            display: "Description",
            fieldName: "name",
        }, {
            display: "SNAP",
            fieldName: "total_state",
            isMoney: true,
        }, {
            display: "SNAP / Child Nutrition",
            fieldName: "total_federal",
            isMoney: true,
        }, {
            display: "Total",
            fieldName: "total",
            isMoney: true,
        }, {
            display: "Spent",
            fieldName: "total_spent",
            isMoney: true,
        }, {
            display: "Balance",
            fieldName: "balance",
            isMoney: true,
        }
    ]

    personnelRedirect(financeUuid: string, masterUuid: string, submissionUuid: string) {
        let queryParams: any
        
        if(this.userOrganizationType == 'SUB' && this.submission.status_id == 'MORE_INFO') {
            this.showFlag = true
            queryParams = {financeUuid: financeUuid, submissionUuid: submissionUuid, showFlag: this.showFlag}
        }
        
        if(this.showFlag === true) {
            queryParams = {financeUuid: financeUuid, masterUuid: masterUuid, submissionUuid: submissionUuid, showFlag: this.showFlag}
        } else {
            queryParams = {financeUuid: financeUuid, masterUuid: masterUuid, submissionUuid: submissionUuid}
        }

        this.router.navigate(['/personnel'], { queryParams })
    }

    groups = []
    paramPromise() {
        return new Promise((resolve, reject) => {
            this.subscriptions.push(this.activatedRoute.queryParams.subscribe(params => {
                this.submissionUuid = params["submissionUuid"]
                resolve(null)
            }))
        })
    }

    flattenedEntries

    initTotals(totals) {
        totals.total_state = 0
        totals.total_federal = 0
        totals.total = 0
        totals.total_spent = 0
        totals.balance = 0
    }

    sumTotals(totals, entry) {
        totals.total_state += entry.total_state
        totals.total_federal += entry.total_federal
        totals.total += entry.total
        totals.total_spent += entry.total_spent
        totals.balance += entry.balance
    }

    addAccountOptions
    getAddAccountOptionsPromise() {
        return new Promise((resolve, reject) => {
            this.subscriptions.push(this.budgetService.getAddAccountOptions(this.submissionUuid).subscribe(response => {
                //console.log("add account options response", response)
                this.addAccountOptions = response.result
                for (var i = 0; i < this.addAccountOptions.length; ++i) {
                    this.addAccountOptions[i].id = this.addAccountOptions[i].uuid
                }
                resolve(null)
            }))
        })
    }

    administrativeAllocated = 0
    getBudgetSummaryPromise() {
        return new Promise((resolve, reject) => {

            this.budgetService.getSummary(this.submissionUuid, this.fiscal_uuid).subscribe(response => {
                console.log("summary response", response)
                this.flattenedEntries = []

                var lastGroup
                var totals
                var isOdd = true
                for (var i = 0; i < response.result.length; ++i) {
                    response.result[i].uiEntryType = this.UIEntryType.entry

                    if (response.result[i].reporting_group == "Overhead") {
                        this.administrativeAllocated += response.result[i].total_state
                        this.administrativeAllocated += response.result[i].total_federal
                    }

                    if (lastGroup && lastGroup.reporting_group == response.result[i].reporting_group) {

                        isOdd = !isOdd
                        response.result[i].isOdd = isOdd

                        if (isOdd) {
                            response.result[i].backgroundColor = '#f0f0f0'
                        }

                        lastGroup.children.push(response.result[i])
                        this.flattenedEntries.push(response.result[i])
                        this.sumTotals(totals, response.result[i])
                    } else {
                        lastGroup = response.result[i]
                        lastGroup.children = [response.result[i]]
                        this.groups.push(lastGroup)

                        if (totals) {
                            isOdd = !isOdd
                            this.flattenedEntries.push({ uiEntryType: this.UIEntryType.totals, totals, backgroundColor: isOdd ? '#f0f0f0' : null })
                        }
                        totals = {}
                        this.initTotals(totals)

                        this.flattenedEntries.push({ uiEntryType: this.UIEntryType.title, group: response.result[i].reporting_group_name })
                        this.flattenedEntries.push({ uiEntryType: this.UIEntryType.comment, group: response.result[i].reporting_group })

                        if (!this.inputDataById[response.result[i].reporting_group]) {
                            this.inputDataById[response.result[i].reporting_group] = {
                                showInputContainer: { value: false }
                            }
                        }

                        this.flattenedEntries.push({ uiEntryType: this.UIEntryType.columnNames, group: response.result[i].reporting_group, backgroundColor: 'lightgrey' })
                        isOdd = true
                        response.result[i].isOdd = isOdd
                        response.result[i].backgroundColor = '#f0f0f0'
                        this.flattenedEntries.push(response.result[i])
                        this.sumTotals(totals, response.result[i])
                    }
                }
                if (totals) {
                    isOdd = !isOdd
                    this.flattenedEntries.push({ uiEntryType: this.UIEntryType.totals, totals, backgroundColor: isOdd ? '#f0f0f0' : null })
                }
                resolve(null)
            })
        })
    }

    getBudgetAllocationPromise() {
        return new Promise((resolve, reject) => {
            this.budgetService.getAllocation(this.submissionUuid).subscribe((response) => {
                // console.log("allocation response", response)
                if (response.success && response.result.length > 0) {
                    this.allocation = response.result[0]
                }
                resolve(null)
            })
        })
    }

    benefits
    getBudgetBenefitsPromise() {
        return new Promise((resolve, reject) => {
            this.budgetService.getBenefits().subscribe((response) => {
                //console.log("benefits response", response)
                if (response.success) {
                    this.benefits = response.result
                }
                resolve(null)
            })
        })
    }

    fetchDataPromise() {
        return Promise.all(
            [
                this.getBudgetSummaryPromise(),
                this.getBudgetAllocationPromise()
            ])
    }

    userOrganizationType
    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                //console.log("org type", response)
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    submission
    fiscal_uuid: string
    getSubmissionPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmission(this.submissionUuid).subscribe((response) => {
                console.log(response.result)
                if (response.success) {
                    this.submission = response.result[0]
                    this.fiscal_uuid = this.submission.fiscal_uuid
                    console.log("submission for review", this.submission)
                } else {
                    console.log("error while getting submission", response)
                }
                resolve(null)
            })
        })
    }

    ngOnInit() {
        Promise.all([
            this.paramPromise(),
            this.getBudgetBenefitsPromise(),
            this.getUserOrganizationType(),  
            this.getSubmissionPromise(),
        ]).then(() => {
            return Promise.all([
                this.getAddAccountOptionsPromise(),
                this.fetchDataPromise()
            ])
        }).then(() => {
            this.initialized = true
        })
    }

    ngOnDestroy() {
        for (var i = 0; i < this.subscriptions.length; ++i) {
            this.subscriptions[i].unsubscribe()
        }
    }

    sortClick(group, fieldName) {
        console.log("sort todo")
    }


    openAddPersonnelDialog()
    {
        this.dialog.open(AddPersonnelProcedureDialog, {
            width: '300px',
            data: 
            {
                submissionUuid: this.submissionUuid,
                fiscal_uuid: this.fiscal_uuid
            }
        })
    }

    addPersonnel() {
        this.router.navigate(["/budget-personnel"], { queryParams: { submissionUuid: this.submissionUuid } })
    }

    addOther() {
        this.router.navigate(["/budget-account"], { queryParams: { submissionUuid: this.submissionUuid, editable: true } })
    }

    entryClick(entry) {
        if (entry.screen == "_PERSONNEL" || entry.screen == "_BENEFITS") {
            let personnelEntryUuid = entry.uuid
            let financeUuid = entry.uuid
            let masterUuid = entry.master_uuid

            if (entry.screen == "_BENEFITS") {
                personnelEntryUuid = entry.master_uuid
            }

            if(this.fiscal_uuid == '3') {
                this.router.navigate(["/budget-personnel"], { queryParams: { personnelEntryUuid: personnelEntryUuid, submissionUuid: this.submissionUuid } })
            } else {
                this.router.navigate(['/personnel'], {queryParams: {financeUuid: financeUuid, masterUuid: masterUuid, submissionUuid: this.submissionUuid, showFlag: this.showFlag}})
            }
        } else {
            this.router.navigate(["/budget-account"], { queryParams: { accountUuid: entry.uuid, submissionUuid: this.submissionUuid, editable: this.editable, showFlag: this.showFlag } })
        }
    }

    cancel() {
        this.snackRoute.snackRoute("Action cancelled", "/dashboard")
    }

    updateSubmissionStatus() {
        this.budgetService.updateSubmissionStatus(this.submissionUuid, 'DRAFT').subscribe(response => {
            if(response.success === true) {
                this.snackRoute.snackRoute("Saved for later", "/dashboard")
            } else {
                console.log(response.message)
            }
        })
    }

    saveForLater() {
        if(this.submission.status_id == 'NEW') {
            this.updateSubmissionStatus()
        } else {
            this.snackRoute.snackRoute("Saved for later", "/dashboard")
        }
    }
    
    totalAdministrative
    fifteenPercentExceeded = false
    submit() {
        this.fifteenPercentExceeded = false
        if (this.allocation.federal_difference != 0 || this.allocation.state_difference != 0) {
            this.snackRoute.snack("All federal and state funds allocated, total remaining must be $0, in order to submit.")
        } else {
            this.budgetService.getAdministrativeAllocated(this.submissionUuid).subscribe((result) => {
                //console.log("total admin result", result)
                if (result && result.length > 0) {
                    this.totalAdministrative = result[0].total_administrative
                }
                //console.log("TTLADM", this.totalAdministrative, (this.allocation.state_granted + this.allocation.federal_granted) * .15)
                //this.totalAdministrative = 0 //DEBUG BAD
                if (this.totalAdministrative > (this.allocation.state_granted + this.allocation.federal_granted) * .15) {
                    this.fifteenPercentExceeded = true
                    this.snackRoute.snack("Total administrative allocated cannot exceed 15% of the total budget granted")
                } else {
                    this.router.navigate(["/submission-review"], { queryParams: { submissionUuid: this.submissionUuid } })
                }
            })
        }
    }
}

@Component({
    selector: 'budget-submission-account-dialog',
    templateUrl: './budget-submission-account-dialog.html',
})
export class BudgetSubmissionAccountDialog {
    faTimes = faTimes
    accountName

    constructor(
        public dialogRef: MatDialogRef<BudgetSubmissionAccountDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private budgetService: BudgetService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private submissionCommentService: SubmissionCommentService,
    ) {
        if (this.data.entryData) {
            var entry = this.data.entryData[0]
            this.accountName = entry.account_name
        }
    }
}

@Component({
    selector: 'app-add-personnel-procedure-dialog',
    templateUrl: './add-personnel-dialog.component.html',
    styleUrls: ['./budget-submission.component.css']
})
 
export class AddPersonnelProcedureDialog {s

    fiscal_uuid: string;
    submissionUuid: string;
    personnelType = new UntypedFormControl('NOEP_PRSNL_OH');

    constructor
    (
        public dialogRef: MatDialogRef<AddPersonnelProcedureDialog>,
        private budgetService: BudgetService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data:
        {
            fiscal_uuid: string,
            submissionUuid: string
        }
    ) 
    {
        this.submissionUuid = data.submissionUuid
        this.fiscal_uuid = data.fiscal_uuid
    }

    addPersonnelProcedure(submissionUuid: string, personnelType: string) 
    {
        this.budgetService.addPersonnelProcedure(submissionUuid, personnelType).subscribe(response => {
            if(response.success === true) {
                console.log('New Personnel Created')
                this.dialogRef.close();
                location.reload();
            }
            else
            {
                console.log('error creating new personnel')
            }
        })
    }

    addPersonnel() 
    {
        const personnel: string = this.personnelType.value
        if(this.fiscal_uuid == '3') {
            this.router.navigate(["/budget-personnel"], { queryParams: { submissionUuid: this.submissionUuid } })
        } else {
            this.addPersonnelProcedure(this.submissionUuid, personnel)
        }
    }

    ngOnInit() {
        this.dialogRef.afterClosed().subscribe(response =>  {
            window.location.reload()
        })
    }
 }