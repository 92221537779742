<div *ngIf="initialized" class="container-fluid">
    <div class="row">
        <div class="col-10 page-title">
          <fa-icon [icon]="faUsers"></fa-icon>
          User Management
        </div>
      
        <div class="col-2 page-title" style="font-size: medium;">
            <mat-slide-toggle [formControl]="pageSelect" color="primary">New Page</mat-slide-toggle>
        </div>
    </div>
      

    <div *ngIf="!pageSelect.value">
        <div class="row py-1">
            <div class="col-9">
                Select Agency
                <app-input-enum label="Select Agency" [fc]="agencySelectFC" [enumValuesNameAndId]="agencySelectValues"
                    [style]="'none'" [change]="changeAgencySelection.bind(this)">
                </app-input-enum>
            </div>
            <div class="col-3" align="right">
                <app-button3 *ngIf="canAddAgency" class="mx-1" label="Add Agency"
                    [clickFunction]="addAgencyClick.bind(this)" [icon]="faPlus" [small]="true"></app-button3>
            </div>
        </div>

        <span *ngFor="let agency of agencies">

            <div class="row mt-3" style="background-color: lightgrey;">
                <div class="col-9">
                    <span style="font-style: italic; font-weight: bold;">{{agency.name}}</span>&nbsp;<a routerLink="/agency"
                        [queryParams]="{ agencyUuid: agency.uuid }">[Edit]</a>&nbsp;
                        <!-- using button3 component -->
                        <!-- <app-button3 class="mx-1" label="Assign Role" (click)="assignRoleDialog(this.claims.user_id, agency.uuid)" [small]="true"></app-button3> -->
                        <!-- using existing a link logic -->
                        <a *ngIf="agency.uuid != 'HSNY' && isAdmin" (click)="assignRoleDialog($event, this.claims.user_id, agency.uuid)" href="">[Assign Role]</a>
                </div>
                <div *ngIf="canAddUser" class="col-3" align="right">
                    <app-button3 class="mx-1" label="Add User"
                        [clickFunction]="addUserClick.bind(this, agency.name, agency.uuid)" [icon]="faPlus" [small]="true">
                    </app-button3>
                </div>
            </div>

            <div class="row" style="background-color: lightgrey;">
                <div class="col-2">
                    Full Name
                </div>
                <div class="col-2">
                    User Name
                </div>
                <div class="col-2">
                    Last Login
                </div>
                <div class="col-2">
                    Status
                </div>
                <div class="col-2">
                    Actions
                </div>
            </div>

            <div *ngIf="agency.users.length == 0" class="row userRow py-1">
                <div class="col-12" align="center">
                    No users
                </div>
            </div>

            <div *ngFor="let user of agency.users" class="row userRow py-1">
                <div class="col-2">
                    <a routerLink="/user" [queryParams]="{ userUuid: user.uuid, viewMode: true }">{{user.full_name}}</a>
                </div>
                <div class="col-2" style="overflow: auto;">
                    {{user.email}}
                </div>
                <div class="col-2">
                    {{user.last_login | date : 'M/d/y h:mm aaaaa\'m\''}}
                </div>
                <div class="col-2">
                    {{user.user_status_id}}
                </div>
                <div class="col-2">
                    <app-input-split-button *ngIf="user.actions && user.actions.length > 0" [options]="user.actions"
                        [clickAction]="actionChange.bind(this, user)"></app-input-split-button>
                </div>
            </div>
        </span>
    </div>
</div>

<app-manage-users-mat *ngIf="pageSelect.value"></app-manage-users-mat>