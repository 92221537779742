<div class="row page-title mb-2 align-items-center">
    <div class="col pl-4">
        <span class="pi pi-chart-line" style="font-size: 2rem;"></span>
        Trend Report
    </div>

    <div class="col-auto pr-4">
        <form [formGroup]="fiscalYearForm">
            <p-dropdown
                formControlName="fiscalYear"
                [options]="fiscalYearOptions"
                optionLabel="name"
            ></p-dropdown>
        </form>
    </div>
</div>

<div class="table-container">
    <p-table [columns]="trendReportOrganizationColumns" [value]="organizations">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th pSortableColumn="organizationName">
                    Agency <p-sortIcon field="organizationName"></p-sortIcon>
                </th>
                <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td class="link" (click)="goToTrendReport(rowData.organizationUuid)">
                    {{ rowData.organizationName }}
                </td>
                <td *ngFor="let col of columns">
                    $ {{ rowData[col.field] | number:'1.0-0' }}
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
