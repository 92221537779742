import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-enum',
    templateUrl: './enum.component.html',
})
export class EnumComponent implements OnInit {
    @Input() fc;
    @Input() enum;
    @Input() disabled;
    @Input() unsetDisplay;
    @Input() maxWidth;

    constructor() { }

    ngOnInit() {
    }
}
