import { Component, Input, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { iFringe } from 'src/app/model/fringe.model';
import { CommentService } from 'src/app/services/comment.service';
// import { MockFringeService } from 'src/app/services/mock-fringe.service';
import { PersonnelService } from 'src/app/services/personnel.service';
import { SessionService } from 'src/app/session.service';

@Component({
  selector: 'app-personnel-fringe',
  templateUrl: './personnel-fringe.component.html',
  styleUrls: ['./personnel-fringe.component.css']
})
export class PersonnelFringeComponent implements OnInit {
  faThumbsUp = faThumbsUp;

  @Input() estimatedPercentTimeOnContract: number;
  @Input() amountChargedToContract: number;
  @Input() masterUuid: string;
  @Input() submissionUuid: string;
  @Input() accountUuid: string;
  @Input() fringeAmount: number;
  @Input() showFlag: boolean;
  @Input() status: string;

  tableOneData: iFringe[];
  tableTwoData: iFringe[];
  tableCalculatedAmount: number;
  commentExists: boolean = false;
  showTableOne: boolean = false;

  displayedColumnsTableOne: string[] = [
    'benefit',
    'amount',
    'percent',
    'calculatedAmount',
    'amountChargedToContract',
    'stateOnly',
    'stateFederalMatch',
    'stateOnlyPlus',
    'stateMatchPlus',
    'federalMatchPlus',
    'totalChargedToContract',
    // 'agencyComment'
  ];

  displayedColumnsTableTwo: string[] = [
    'benefit',
    'annualPremium',
    'employerPercent',
    // 'employeePercent',
    'calculatedAmount',
    'amountChargedToContract',
    'stateOnly',
    'stateFederalMatch',
    'stateOnlyPlus',
    'stateMatchPlus',
    'federalMatchPlus',
    'totalChargedToContract',
    // 'agencyComment'
  ];

  constructor(
    // private mockFringeService: MockFringeService,
    private dialog: MatDialog,
    private personnelService: PersonnelService,
    private commentService: CommentService
  ) { }

  getTableData() {
    this.personnelService.getFringeDataByUuid(this.masterUuid, this.submissionUuid).subscribe((data) => {
      let tableData = data;
      // console.log(tableData)
      
      this.tableOneData = tableData.filter((item) => {
        return item.isFixed === 1;
      });

      this.tableTwoData = tableData.filter((item) => {
        return item.isFixed === 0;
      });

      this.fetchCommentsForTableData(this.tableOneData)
      this.fetchCommentsForTableData(this.tableTwoData)

    });
  }
  
  fetchCommentsForTableData(tableData: iFringe[]) {
    tableData.forEach((fringe) => {
      this.personnelService.fringeCommentExists(this.masterUuid, this.submissionUuid, fringe.accountUuid)
        .subscribe((response) => {
          fringe.commentExists = response.success;
        });
    });
  }

  openDialog(fringe: iFringe): void
  {
    let dialogRef: any

    // IF WE EVER ADD THIS TOP TABLE BACK WE NEED TO CHECK
    // THE ISFIXED VALUE TO DETERMINE WHEN TO OPEN WHICH DIALOG
    // if (fringe.accountUuid === 'NOEP_FICA' || fringe.accountUuid === 'NOEP_METRO' || fringe.accountUuid === 'NOEP_401k')
    // {
    //   dialogRef = this.dialog.open(EditTopFringeDialog, {
    //     minWidth: '480px',
    //     data: { 
    //       fringe, 
    //       amountChargedToContract: this.amountChargedToContract, 
    //       masterUuid: this.masterUuid, 
    //       submissionUuid: this.submissionUuid,
    //       showFlag: this.showFlag,
    //       status: this.status,
    //     }
    //   });
    //
    dialogRef = this.dialog.open(EditBottomFringeDialog, {
      minWidth: '480px',
      data: {
        fringe, 
        estimatedPercentTimeOnContract: this.estimatedPercentTimeOnContract, 
        fringeAmount: this.fringeAmount,
        masterUuid: this.masterUuid,
        submissionUuid: this.submissionUuid,
        showFlag: this.showFlag,
        status: this.status
      }
    })
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed having data: ' + JSON.stringify(result));
      this.getTableData();
    })
  }

  viewAgencyComment(comment: string): void {
    let commentDialog: any

    commentDialog = this.dialog.open(ViewAgencyComment, {
      minWidth: `400px`,
      maxWidth: `600px`,
      data: { comment: comment }
    })
  }

  ngOnInit() 
  {
    this.commentService.getCommentExists().subscribe((commentExists) => {
      this.commentExists = commentExists;
      // console.log(this.commentExists)
    });
    this.getTableData();
  }

}

@Component({
  selector: 'fringe-top-edit-dialog',
  templateUrl: './edit-top-fringe-dialog.html',
  styleUrls: ['./personnel-fringe.component.css']
})
export class EditTopFringeDialog implements OnInit
{
  amountChargedToContract: number;
  fringe: iFringe;
  masterUuid: string;
  submissionUuid: string;
  showCalculations: boolean = false;
  orgType: string;
  showFlag: boolean;
  
  constructor(
    public DialogRef: MatDialogRef<EditTopFringeDialog>,
    // private mockFringeService: MockFringeService,
    private personnelService: PersonnelService,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      fringe: iFringe, 
      amountChargedToContract: number, 
      masterUuid: string,
      submissionUuid: string,
      showFlag: boolean,
      status: string
    }
  ) {
    this.amountChargedToContract = data.amountChargedToContract
    this.fringe = data.fringe
    this.masterUuid = data.masterUuid
    this.submissionUuid = data.submissionUuid
  }

  // amount charged to contract decimal place solution
  actc = Number(this.data.fringe.unitPrice)

  tableOneForm = new UntypedFormGroup({
    benefit: new UntypedFormControl(this.data.fringe.accountName),
    amount: new UntypedFormControl(this.data.amountChargedToContract),
    percent: new UntypedFormControl(this.data.fringe.percentage),
    calculatedAmount: new UntypedFormControl(Math.ceil(this.data.amountChargedToContract * (this.data.fringe.percentage / 100))),
    amountChargedToContract: new UntypedFormControl(this.actc.toFixed(2)),
    stateOnlyCalc: new UntypedFormControl(this.data.fringe.debitState),
    stateMatchCalc: new UntypedFormControl(this.data.fringe.debitFederal / 2),
    fedMatchCalc: new UntypedFormControl(this.data.fringe.debitFederal / 2),
    stateFedMatch: new UntypedFormControl(this.data.fringe.debitFederal),
    stateOnly: new UntypedFormControl(this.data.fringe.debitState),
    totalChargedToContractCalc: new UntypedFormControl(+this.data.fringe.debitState + +this.data.fringe.debitFederal),
    agencyComment: new UntypedFormControl(this.data.fringe.agencyComment || '')
  });

  updateTopFringe() {
    this.personnelService.updateTopFringe(
      this.masterUuid,
      this.submissionUuid,
      this.data.fringe.accountUuid,
      this.tableOneForm.get('percent').value,
      this.tableOneForm.get('amountChargedToContract').value,
      this.tableOneForm.get('stateOnly').value,
      this.tableOneForm.get('stateFedMatch').value,
      this.tableOneForm.get('agencyComment').value
    ).subscribe(response => {
      console.log(response)
    })
  }

  calcCalculatedAmount(): number
  {
    const amount = this.tableOneForm.get('amount').value || 0;
    const percent = this.tableOneForm.get('percent').value / 100;
    
    let finalNumber = Math.ceil(amount * percent);
    this.tableOneForm.get('calculatedAmount').setValue(finalNumber);
    return finalNumber
  }

  disableInputs()
  {
    this.tableOneForm.get('benefit').disable();
    this.tableOneForm.get('amount').disable();
    this.tableOneForm.get('calculatedAmount').disable();
    this.tableOneForm.get('stateOnlyCalc').disable();
    this.tableOneForm.get('stateMatchCalc').disable();
    this.tableOneForm.get('fedMatchCalc').disable();
    this.tableOneForm.get('totalChargedToContractCalc').disable();
  }

  readOnlyDisableInputs() {
    this.tableOneForm.disable()
  }

  stateOnlyChanges()
  {
    let stateOnlyCalc = this.tableOneForm.get('stateOnlyCalc');
    let newStateOnly = Number(this.tableOneForm.get('stateOnly').value) || 0;

    stateOnlyCalc.setValue(newStateOnly);
    this.calcTotalCharged();
  }

  stateFedMatchChanges()
  {
    const stateFed = Number(this.tableOneForm.get('stateFedMatch').value) || 0;
    let value = stateFed / 2;
    value = (value * 100) / 100
    const fedMatchCalc = this.tableOneForm.get('fedMatchCalc');
    const stateMatchCalc = this.tableOneForm.get('stateMatchCalc');

    fedMatchCalc.setValue(value);
    stateMatchCalc.setValue(value);
    this.calcTotalCharged();
  }

  calcTotalCharged()
  {
    const stateFed = Number(this.tableOneForm.get('stateFedMatch').value) || 0;
    const stateOnly = Number(this.tableOneForm.get('stateOnly').value) || 0;

    const total = Math.ceil(stateFed) + Math.ceil(stateOnly);

    this.tableOneForm.get('totalChargedToContractCalc').setValue(total);
  }

  async validateForm(): Promise<boolean>
  {
    this.tableOneForm.markAllAsTouched();
    const amountChargedToContract = this.tableOneForm.get('amountChargedToContract');
    const calculatedAmount = this.tableOneForm.get('calculatedAmount');
    const totalChargedToContract = this.tableOneForm.get('totalChargedToContractCalc');

    amountChargedToContract.setErrors(null);
    calculatedAmount.setErrors(null);
    totalChargedToContract.setErrors(null);

    const isValidAmount = Number(amountChargedToContract.value) <= calculatedAmount.value
    const isValidTotal = Number(amountChargedToContract.value) === Number(totalChargedToContract.value)

    if(!isValidAmount) {
      amountChargedToContract.setErrors({invalidAmount: true})
    }

    if(!isValidTotal) {
      amountChargedToContract.setErrors({amountMismatch: true})
    }

    return isValidAmount && isValidTotal;
  }

  async onSave()
  {
    this.tableOneForm.get('amountChargedToContract').setValue(Math.ceil(this.tableOneForm.get('amountChargedToContract').value))
    this.tableOneForm.get('stateOnly').setValue(Math.ceil(this.tableOneForm.get('stateOnly').value))
    this.tableOneForm.get('stateFedMatch').setValue(Math.ceil(this.tableOneForm.get('stateFedMatch').value))
    const isValid = await this.validateForm()
    if(isValid === true) {
      this.updateTopFringe()
      this.DialogRef.close()
    }
  }

  ngOnInit()
  {
    const claims = this.sessionService.getClaims()
    this.orgType = claims.organization_type
    this.showFlag = this.data.showFlag


    if(this.orgType == 'PRIME') {
      this.readOnlyDisableInputs()
    }

    if(this.orgType == 'SUB' && this.data.status == 'SUBMITTED' || this.data.status == 'APPROVED') {
      this.readOnlyDisableInputs()
    }

    this.disableInputs();
    this.tableOneForm.get('amount').setValue(this.amountChargedToContract);

    this.tableOneForm.get('percent').valueChanges.subscribe((value) => {
    this.calcCalculatedAmount();
    });

    this.tableOneForm.get('stateOnly').valueChanges.subscribe((value) => {
      this.stateOnlyChanges();
    });

    this.tableOneForm.get('stateFedMatch').valueChanges.subscribe((value) => {
      this.stateFedMatchChanges();
    })

  }
}

@Component({
  selector: 'fringe-bottom-edit-dialog',
  templateUrl: './edit-bottom-fringe-dialog.html',
  styleUrls: ['./personnel-fringe.component.css']
})
export class EditBottomFringeDialog implements OnInit
{
  estimatedPercentTimeOnContract: number;
  fringe: iFringe;
  fringeAmount: number;
  masterUuid: string;
  submissionUuid: string;
  showCalculations: boolean = false;
  orgType: string;
  showFlag: boolean;
  
  constructor(
    public DialogRef: MatDialogRef<EditBottomFringeDialog>,
    private personnelService: PersonnelService,
    // private mockFringeService: MockFringeService,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      fringe: iFringe, 
      estimatedPercentTimeOnContract: number, 
      fringeAmount: number,
      masterUuid: string,
      submissionUuid: string,
      showFlag: boolean,
      status: string
    }
  ) {
    this.estimatedPercentTimeOnContract = data.estimatedPercentTimeOnContract;
    this.fringe = data.fringe
    this.fringeAmount = data.fringeAmount
    this.masterUuid = data.masterUuid,
    this.submissionUuid = data.submissionUuid
  }


  // amount charged to contract decimal place solution
  actc = Number(this.data.fringe.unitPrice)

  tableTwoForm = new UntypedFormGroup({
    benefit: new UntypedFormControl(this.data.fringe.accountName),
    annualPremium: new UntypedFormControl(this.data.fringe.fringeAmount),
    employerPercent: new UntypedFormControl(this.data.fringe.percentage),
    employeePercent: new UntypedFormControl(100 - this.data.fringe.percentage),
    calculatedAmount: new UntypedFormControl(),
    amountChargedToContract: new UntypedFormControl(this.actc.toFixed(2)),
    stateOnlyCalc: new UntypedFormControl(this.data.fringe.debitState),
    stateMatchCalc: new UntypedFormControl(this.data.fringe.debitFederal / 2),
    fedMatchCalc: new UntypedFormControl(this.data.fringe.debitFederal / 2),
    stateFedMatch: new UntypedFormControl(this.data.fringe.debitFederal),
    stateOnly: new UntypedFormControl(this.data.fringe.debitState),
    totalChargedToContractCalc: new UntypedFormControl(+this.data.fringe.debitState + +this.data.fringe.debitFederal),
    agencyComment: new UntypedFormControl(this.data.fringe.agencyComment !== null
       && this.data.fringe.agencyComment !== undefined
        ? this.data.fringe.agencyComment : '')
  })

  updateBottomFringe() {
    // console.log(this.data.fringe.accountUuid)
    this.personnelService.updateBottomFringe(
      this.masterUuid,
      this.submissionUuid,
      this.data.fringe.accountUuid,
      this.tableTwoForm.get('employerPercent').value,
      this.tableTwoForm.get('amountChargedToContract').value,
      this.tableTwoForm.get('stateOnly').value,
      this.tableTwoForm.get('stateFedMatch').value,
      this.tableTwoForm.get('annualPremium').value,
      this.tableTwoForm.get('agencyComment').value
    ).subscribe(response => {
      console.log(response)
    })
  }

  disableFields()
  {
    this.tableTwoForm.get('benefit').disable();
    this.tableTwoForm.get('calculatedAmount').disable();
    this.tableTwoForm.get('stateOnlyCalc').disable();
    this.tableTwoForm.get('stateMatchCalc').disable();
    this.tableTwoForm.get('fedMatchCalc').disable();
    this.tableTwoForm.get('totalChargedToContractCalc').disable();
  }

  calcCalculatedAmount()
  {
    const annualPremium = this.tableTwoForm.get('annualPremium').value || 0;
    const employerPercent = this.tableTwoForm.get('employerPercent').value || 0;

    const value = Math.ceil(annualPremium * this.estimatedPercentTimeOnContract * (employerPercent / 100));
    this.tableTwoForm.get('calculatedAmount').setValue(value);
  }

  calcEmployerPercent() 
  {
    const employeePercent = this.tableTwoForm.get('employeePercent').value || 0;
    const employerPercent = 100 - employeePercent;
    this.tableTwoForm.get('employerPercent').setValue(employerPercent, { emitEvent: false });
  }
  
  calcEmployeePercent() 
  {
    const employerPercent = this.tableTwoForm.get('employerPercent').value || 0;
    const employeePercent = 100 - employerPercent;
    this.tableTwoForm.get('employeePercent').setValue(employeePercent, { emitEvent: false });
  }

  stateOnlyChanges()
  {
    let stateOnlyCalc = this.tableTwoForm.get('stateOnlyCalc');
    let newStateOnly = Number(this.tableTwoForm.get('stateOnly').value) || 0;

    stateOnlyCalc.setValue(newStateOnly);
    this.calcTotalCharged();
  }

  stateFedMatchChanges()
  {
    const stateFed = Number(this.tableTwoForm.get('stateFedMatch').value) || 0;
    let value = stateFed / 2;
    value = (value * 100) / 100
    const fedMatchCalc = this.tableTwoForm.get('fedMatchCalc');
    const stateMatchCalc = this.tableTwoForm.get('stateMatchCalc');

    fedMatchCalc.setValue(value);
    stateMatchCalc.setValue(value);
    this.calcTotalCharged();
  }

  calcTotalCharged()
  {
    const stateFed = Number(this.tableTwoForm.get('stateFedMatch').value) || 0;
    const stateOnly = Number(this.tableTwoForm.get('stateOnly').value) || 0;

    const total = Math.ceil(stateFed) + Math.ceil(stateOnly);

    this.tableTwoForm.get('totalChargedToContractCalc').setValue(total);
  }

  async validateForm(): Promise<boolean>
  {
    this.tableTwoForm.markAllAsTouched();
    const amountChargedToContract = this.tableTwoForm.get('amountChargedToContract');
    const calculatedAmount = this.tableTwoForm.get('calculatedAmount');
    const totalChargedToContract = this.tableTwoForm.get('totalChargedToContractCalc');
    const employerPercent = this.tableTwoForm.get('employerPercent');
    const employeePercent = this.tableTwoForm.get('employeePercent');
    const anuualPremium = this.tableTwoForm.get('annualPremium');
    const stateFederalMatch = this.tableTwoForm.get('stateFedMatch');
    const stateOnly = this.tableTwoForm.get('stateOnly');
    const agencyComment = this.tableTwoForm.get('agencyComment');

    amountChargedToContract.setValue(Math.ceil(amountChargedToContract.value))
    calculatedAmount.setValue(Math.ceil(calculatedAmount.value))
    totalChargedToContract.setValue(Math.ceil(totalChargedToContract.value))
    employerPercent.setValue(Math.ceil(employerPercent.value))
    employeePercent.setValue(Math.ceil(employeePercent.value))
    anuualPremium.setValue(Math.ceil(anuualPremium.value))
    stateFederalMatch.setValue(Math.ceil(stateFederalMatch.value))
    stateOnly.setValue(Math.ceil(stateOnly.value))

    amountChargedToContract.setErrors(null);
    calculatedAmount.setErrors(null);
    totalChargedToContract.setErrors(null);
    employerPercent.setErrors(null);
    employeePercent.setErrors(null);
    agencyComment.setErrors(null)

    // let isValid = true;

    const isValidAmount = Number(amountChargedToContract.value) <= calculatedAmount.value
    const isValidTotal = Number(amountChargedToContract.value) === Number(totalChargedToContract.value)
    const isValidEmployerPercent = Number(employerPercent.value) <= 100
    const isValidEmployeePercent = Number(employeePercent.value) <= 100
    const isValidAgencyComment = !!agencyComment.value

    if(!isValidAmount)
      {
      amountChargedToContract.setErrors({invalidAmount: true})
    }

    if(!isValidTotal)
      {
      amountChargedToContract.setErrors({amountMismatch: true})
    }

    if(!isValidEmployerPercent)
      {
      employerPercent.setErrors({invalidPercentage: true})
    }

    if(!isValidEmployeePercent)
      {
      employeePercent.setErrors({invalidPercentage: true})
    }

    if(!isValidAgencyComment)
      {
      agencyComment.setErrors({isRequired: true})
    }

    return isValidAmount && isValidTotal && isValidEmployerPercent && isValidEmployeePercent && isValidAgencyComment
  }
  
  async onSave()
  {
    const isValid = await this.validateForm()
    if(isValid === true) {
      this.updateBottomFringe()
      this.DialogRef.close()
    }
  }

  readOnlyDisableInputs() {
    this.tableTwoForm.disable()
  }

  ngOnInit(): void 
  {
    const claims = this.sessionService.getClaims()
    this.orgType = claims.organization_type
    this.showFlag = this.data.showFlag

    if(this.orgType == 'PRIME') {
      this.readOnlyDisableInputs()
    }

    if(this.orgType == 'SUB' && this.data.status == 'SUBMITTED' || this.data.status == 'APPROVED') {
      this.readOnlyDisableInputs()
    }

    // initialize calculated amount as it was not pulling in to the edit dialog properly
    this.calcCalculatedAmount()

    this.disableFields();
    this.tableTwoForm.get('annualPremium').valueChanges.subscribe((value) => {
      this.calcCalculatedAmount();
    });
  
    this.tableTwoForm.get('employerPercent').valueChanges.subscribe((value) => {
      this.calcEmployeePercent();
      this.calcCalculatedAmount();
    });
  
    this.tableTwoForm.get('employeePercent').valueChanges.subscribe((value) => {
      this.calcEmployerPercent();
      this.calcCalculatedAmount();
    });
    this.tableTwoForm.get('stateOnly').valueChanges.subscribe((value) => {
      this.stateOnlyChanges();
    });

    this.tableTwoForm.get('stateFedMatch').valueChanges.subscribe((value) => {
      this.stateFedMatchChanges();
    })
  }
  
}


@Component({
  selector: 'fringe-view-agency-comment',
  templateUrl: './agency-comment.component.html',
  styleUrls: ['./personnel-fringe.component.css']
})
export class ViewAgencyComment implements OnInit {
  comment: string

  constructor(
    private dialogRef: MatDialogRef<ViewAgencyComment>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      comment: string
    }
  ) {}

  ngOnInit(): void {
    this.comment = this.data.comment
  }
}