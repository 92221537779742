import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubmissionAnswersService {

    constructor(
        private http: HttpClient,

    ) { }

    postSubmissionAnswers(
        submission_uuid,
        question_step_id,
        isDone,
        answerData,
    ): Observable<any> {
        return this.http.post<any>("api/stepAnswers", {
            submission_uuid,
            question_step_id,
            isDone,
            answerData,
        })
    }

    deleteAnswerGroup(answer_group_uuid: string, additional_answer_sequence_number: number): Observable<any> {
        return this.http.post<any>("api/deleteAnswerGroup", {
            answer_group_uuid, 
            additional_answer_sequence_number
        })
    }

    deleteAnotherAnswer(answer_uuid): Observable<any> {
        return this.http.post<any>("api/deleteAnotherAnswer", {answer_uuid})
    }

    addAnswerGroup(answer_group_uuid): Observable<any> {
        return this.http.post<any>("api/addAnswerGroup", {answer_group_uuid})
    }

    addAnotherAnswer(answer_uuid): Observable<any> {
        return this.http.post<any>("api/addAnotherAnswer", {
            answer_uuid,
        })
    }

}
