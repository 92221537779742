import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faBan, faCheck, faExclamationTriangle, faFlag, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { of } from 'rxjs';
import { AuthService } from '../auth.service';
import { InputDateFormat } from '../input-date/input-date.component';
import { OutreachService } from '../outreach.service';
import { SnackRouteService } from '../snack-route.service';
import { SubmissionAttachmentsComponent } from '../submission-attachments/submission-attachments.component';
import { SubmissionCommentService } from '../submission-comment.service';
import { SubmissionService } from '../submission.service';
import { SystemService } from '../system.service';

@Component({
    selector: 'app-outreach-submission',
    templateUrl: './outreach-submission.component.html',
    styleUrls: ['./outreach-submission.component.scss'],
})
export class OutreachSubmissionComponent implements OnInit, OnDestroy {
    InputDateFormat = InputDateFormat
    @Input() showFlag
    @Input() editable: boolean

    initialized = false
    paramSubscription
    submissionUuid

    sloganFC = new UntypedFormControl()
    fedValueFC = new UntypedFormControl()
    stateValueFC = new UntypedFormControl()
    fedStateTotal
    outreachMethodFC = new UntypedFormControl()
    agencyDateFC = new UntypedFormControl()
    locationFC = new UntypedFormControl()
    commentFC = new UntypedFormControl()
    saveError
    faBan = faBan
    faSave = faSave
    faCheck = faCheck
    faTimes = faTimes
    faFlag = faFlag
    faExclamationTriangle = faExclamationTriangle
    submitterName
    approverName

    doValidation
    sloganValidationError
    fedValidationError
    stateValidationError
    methodValidationError
    agencyDateValidationError
    locationValidationError
    commentValidationError

    inputIds = [
        "slogan",
        "fed",
        "state",
        "method",
        "agency_date",
        "location",
        "comment"
    ]
    inputDataById: any = {}

    flagClick(inputId) {
        var inputData = this.inputDataById[inputId]
        if (inputData) {
            inputData.showInputContainer.value = !inputData.showInputContainer.value
        }
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private outreachService: OutreachService,
        private router: Router,
        private systemService: SystemService,
        private snackRouteService: SnackRouteService,
        private submissionCommentService: SubmissionCommentService,
        private submissionService: SubmissionService,
        private authService: AuthService,
    ) { }

    paramPromise() {
        return new Promise((resolve, reject) => {
            this.paramSubscription = this.activatedRoute.queryParams.subscribe(params => {
                this.submissionUuid = params["submissionUuid"]
                resolve(null)
            })
        })
    }

    outreachSubmission
    getOutreachSubmission() {
        return new Promise((resolve, reject) => {
            this.outreachService.getOutreachSubmission(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.outreachSubmission = response.result[0]
                    console.log('outreach submission', this.outreachSubmission)
                    this.sloganFC.setValue(this.outreachSubmission.name)
                    this.fedValueFC.setValue(this.outreachSubmission.credit_federal_est)
                    this.stateValueFC.setValue(this.outreachSubmission.credit_state_est)

                    this.agencyDateFC.setValue(formatDate(this.outreachSubmission.agency_date, 'yyyy-MM-dd', 'en'))
                   
                    /*
                    if(this.outreachSubmission.agency_date == null) {
                        this.agencyDateFC.setValue(formatDate(this.outreachSubmission.agency_date, 'yyyy-MM-dd', 'en'))
                    }
                    */

                    if(this.agencyDateFC.value == '1969-12-31') {
                        //this.agencyDateFC.setValue(formatDate('2022-01-01', 'yyyy-MM-dd', 'en'))
                        this.agencyDateFC.setValue(null)
                    }
                    
                    this.locationFC.setValue(this.outreachSubmission.finance_description)
                    this.outreachMethodFC.setValue(this.outreachSubmission.document_uuid)
                    this.commentFC.setValue(this.outreachSubmission.agency_comment)
                    resolve(null)
                } else {
                    reject(response)
                }
            })
        })
    }

    outreachMethods
    outreachMethodsById
    getMethodsPromise() {
        return new Promise((resolve, reject) => {
            this.outreachService.getOutreachMethods(this.outreachSubmission.submission_type_id).subscribe((response) => {
                if (response.success) {
                    this.outreachMethods = response.result;
                    this.outreachMethods.sort((a, b) => a.name.localeCompare(b.name));
                    this.outreachMethodsById = {}
                    for (var i = 0; i < this.outreachMethods.length; ++i) {
                        this.outreachMethodsById[this.outreachMethods[i].id] = this.outreachMethods[i]
                    }
                    resolve(null)
                } else {
                    reject(response)
                }
            })
        })
    }

    userOrganizationType
    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    validationCols = [
        "data_submission.name",
        "data_finance.credit_federal_est",
        "data_finance.credit_state_est",
        "data_finance.description",
        "data_comment.comment",
        "data_submission.agency_date",
    ]
    validationDefs: any = {}

    isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    calculateBudgetTotal() {
        this.fedStateTotal = 0
        if (this.isNumber(this.fedValueFC.value)) {
            this.fedStateTotal += parseFloat(this.fedValueFC.value)
        }
        if (this.isNumber(this.stateValueFC.value)) {
            this.fedStateTotal += parseFloat(this.stateValueFC.value)
        }
        this.inputChange()
    }

    ngOnInit() {
        for (var i = 0; i < this.inputIds.length; ++i) {
            this.inputDataById[this.inputIds[i]] = {
                //fc: new FormControl(),
                showInputContainer: { value: false }
            }
        }
        var promises = []
        for (var i = 0; i < this.validationCols.length; ++i) {
            promises.push(this.systemService.getColumnTypePromise(
                this.validationCols[i].substring(0, this.validationCols[i].indexOf(".")),
                this.validationCols[i].substring(this.validationCols[i].indexOf(".") + 1, this.validationCols[i].length
                )).then((def: any) => {
                    this.validationDefs[def.TABLE_NAME + "." + def.COLUMN_NAME] = def
                }))
        }
        promises.push(this.paramPromise())
        promises.push(this.getUserOrganizationType())
        Promise.all(promises).then(() => {
            return this.getOutreachSubmission()
        }).then(() => {
            return this.getMethodsPromise()
        }).then(() => {
            return this.submissionCommentService.getSubmissionCommentsPromise(this.submissionUuid, null).then((result: any) => {
                //console.log("comments result", result)
                for (var i = 0; i < result.length; ++i) {
                    var data = this.inputDataById[result[i].target_column]
                    if (data) {
                        //data.fc.setValue(result[i].comment)
                        data.comment = result[i].comment
                        data.commentUuid = result[i].uuid
                    }
                }
            })
        }).then(() => {
            // console.log("outreach submission", this.outreachSubmission)
            // console.log("methods", this.outreachMethods)
            // console.log("validation types", this.validationDefs)
            this.calculateBudgetTotal()
            // this.editable = this.outreachSubmission.status_id == 'DRAFT' || this.outreachSubmission.status_id == 'MORE_INFO'
            this.initialized = true
        }).catch((error) => {
            of({})
        })
    }

    ngOnDestroy() {
        this.paramSubscription.unsubscribe()
    }

    cancel() {
        this.router.navigate(["/dashboard"])
    }

    inputChange() {
        if (this.doValidation) {
            this.validate()
        }
    }

    validateSaveForLater() {
        this.saveError = null

        if (this.fedValueFC.value && !this.isNumber(this.fedValueFC.value)) {
            this.fedValidationError = "Please specify a number."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (this.stateValueFC.value && !this.isNumber(this.stateValueFC.value)) {
            this.stateValidationError = "Please specify a number."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        return !!this.saveError
    }

    validate() {
        this.doValidation = true//after the first press of "submit", fields will validate on the fly
        this.saveError = null

        this.sloganValidationError = null
        this.fedValidationError = null
        this.stateValidationError = null
        this.methodValidationError = null
        this.agencyDateValidationError = null
        this.locationValidationError = null
        this.commentValidationError = null

        if (!this.sloganFC.value) {
            this.sloganValidationError = "Please specify a slogan."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (this.fedValueFC.value == null || this.fedValueFC.value == undefined || this.fedValueFC.value + "" == "") {
            this.fedValidationError = "Please specify a federal value."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (!this.isNumber(this.fedValueFC.value)) {
            this.fedValidationError = "Please specify a number."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (this.stateValueFC.value == null || this.stateValueFC.value == undefined || this.stateValueFC.value + "" == "") {
            this.stateValidationError = "Please specify a state value."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (!this.isNumber(this.stateValueFC.value)) {
            this.stateValidationError = "Please specify a number."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (!this.outreachMethodFC.value) {
            this.methodValidationError = "Please specify an outreach method."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        if (!this.locationFC.value) {
            this.locationValidationError = "Please specify a location."
            this.saveError = "Please address the issues highlighted above then click the submit button again."
        }

        return !!this.saveError
    }

    getSaveRequest() {
        return {
            submission_uuid: this.submissionUuid,
            slogan: this.sloganFC.value,
            credit_federal_est: this.fedValueFC.value,
            credit_state_est: this.stateValueFC.value,
            method: this.outreachMethodFC.value,
            location: this.locationFC.value,
            agency_date: this.agencyDateFC.value,
            comment: this.commentFC.value,
            status_id: this.outreachSubmission.status_id == "NEW" ? 'DRAFT' : this.outreachSubmission.status_id,
        }
    }

    saveForLater() {
        if (this.validateSaveForLater()) {
            return
        }
        this.outreachService.saveForLaterOutreachSubmission(this.getSaveRequest()).subscribe((response) => {
            if (!response.success) {
                this.saveError = "Error while saving: " + response.message
                return
            }
            this.snackRouteService.snackRoute("Outreach submission saved for later.", "/dashboard")
        })
    }

    submit() {
        if (this.validate()) {
            return
        }
        this.submissionService.submissionHasDocumentsPromise(this.submissionUuid).then((hasDocuments) => {
            if (!hasDocuments) {
                this.saveError = "Please attach one or more documents before submitting."
                return
            }
            this.outreachService.saveForLaterOutreachSubmission(this.getSaveRequest()).subscribe((response) => {
                if (!response.success) {
                    this.saveError = "Error while saving: " + response.message
                    return
                }
                this.router.navigate(["/submission-review"], { queryParams: { submissionUuid: this.submissionUuid } })
            })
        })
    }

    ok() {
        history.back()
    }
}
