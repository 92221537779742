import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
const helper = new JwtHelperService();
import { SystemService } from './system.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    loggedInCheckIntervalId
    inactivityCheckIntervalId
    inactivityTimeoutInSeconds
    lastActivityTimestamp

    constructor(
        private router: Router,
        private userService: UserService,
        private _snackBar: MatSnackBar,
        private http: HttpClient,
        private systemService: SystemService,
    ) {
        this.lastActivityTimestamp = Date.now()
        var updateLastActivityTimestamp = (event) => {
            //console.log("activity detected")
            this.lastActivityTimestamp = Date.now()
        }
        document.addEventListener('mousemove', updateLastActivityTimestamp);
        document.addEventListener('keydown', updateLastActivityTimestamp);
        document.addEventListener('keyup', updateLastActivityTimestamp);
        window.addEventListener('scroll', updateLastActivityTimestamp, true);
        document.addEventListener('click', updateLastActivityTimestamp);
    }

    hasValidAuthenticationToken() {
        return localStorage.getItem('authToken') != null
            && helper.decodeToken(localStorage.getItem("authToken"))
            && !helper.isTokenExpired(localStorage.getItem("authToken"))
    }

    doLogout(reason?) {
        var snack = "You have been signed out"
        if (reason) {
            snack += " due to " + reason
        }
        snack += "."
        this._snackBar.open(snack, null, { duration: 2000, });

        this.userService.logLogout().subscribe(() => {

        })
        localStorage.removeItem("authToken");
        if (this.loggedInCheckIntervalId) {
            clearInterval(this.loggedInCheckIntervalId)
            this.loggedInCheckIntervalId = null
        }
        if (this.inactivityCheckIntervalId) {
            clearInterval(this.inactivityCheckIntervalId)
            this.inactivityCheckIntervalId = null
        }
        this.router.navigate(['/login']);
    }

    stillLoggedInCheck() {
        if (!this.loggedInCheckIntervalId && this.hasValidAuthenticationToken()) {
            this.loggedInCheckIntervalId = setInterval(() => {
                if (!this.hasValidAuthenticationToken()) {
                    this.doLogout("invalid auth token");
                }
            }, 100);
        }

        if (!this.inactivityCheckIntervalId && this.hasValidAuthenticationToken()) {
            this.systemService.getInactivityTimeout().subscribe((response) => {
                if (response.success) {
                    this.inactivityTimeoutInSeconds = response.inactivityTimeoutInSeconds
                } else {
                    this.inactivityTimeoutInSeconds = 360
                }
                this.inactivityCheckIntervalId = setInterval(() => {
                    if (Date.now() - this.lastActivityTimestamp > this.inactivityTimeoutInSeconds * 1000) {
                        if (this.hasValidAuthenticationToken()) {
                            this.doLogout("inactivity")
                        }
                    }
                }, 1000)
            })
        }
    }

    private getField(fieldName) {
        const decodedToken = helper.decodeToken(localStorage.getItem("authToken"));
        //console.log("decoded", decodedToken)
        if (!decodedToken) {
            return null;
        }
        return decodedToken[fieldName];
    }

    // debug() {
    //     const decodedToken = helper.decodeToken(localStorage.getItem("authToken"));
    //     if (!decodedToken) {
    //         return null;
    //     }
    //     console.log(decodedToken);
    // }

    get username() {
        return this.getField("username");
    }

    get companyname() {
        var orgs = this.getField("organizations")
        if (orgs.length > 0) {
            return orgs[0].name
        }
        return null
    }

    get companyId() {
        var orgs = this.getField("organizations")
        if (orgs.length > 0) {
            return orgs[0]
        }
        return null
    }

    get organizations() {
        return this.getField("organizations");
    }

    get userrole() {
        return this.getField("role");
    }

    get userId() {
        return this.getField("userId");
    }

    getUserOrganizationType(): Observable<any> {
        return this.http.get<any>("api/userOrganizationType")
    }

    getUserOrganizations(): Observable<any> {
        return this.http.get<any>("api/userOrganizations")
    }

}
