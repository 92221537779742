<div class="row" style="padding: 20px;">
    <div class="col-6">
        <div class="row py-2">
            <div class="col-4 bold">
                Agency
            </div>
            <div class="col-8">
                {{ organization_name }}
            </div>
        </div>
        <div class="row py-2">
            <div class="col-4 bold">
                Description
            </div>
            <div class="col-8">
                {{ description }}
            </div>
        </div>
    </div>
</div>