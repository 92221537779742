import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { iTrendReportOrganization } from '../model/trend-report-organization.model';
import { iTrendReportDetail } from '../model/trend-report-detail.model';
import { iTrendReportQtr } from '../model/trend-report-qtr.model';

@Injectable({
  providedIn: 'root',
})
export class BudgetService 
{
    
    constructor(private http: HttpClient) { }

    getTrendReportOrganizations(fiscalYearId: string): Observable<iTrendReportOrganization[]>
    {
        return this.http.get<iTrendReportOrganization[]>('api/trendReportOrganizations/' + fiscalYearId)
    }
    
    getTrendReportDetial(organizationUuid: string): Observable<iTrendReportDetail[]>
    {
        return this.http.get<iTrendReportDetail[]>('api/trendReportDetail/' + organizationUuid)
    }

    getTrendReportQtr(organizationUuid: string): Observable<iTrendReportQtr[]>
    {
        return this.http.get<iTrendReportQtr[]>('api/trendReportQtr/' + organizationUuid)
    }

    getTrendReportHead(organizationUuid: string, fiscalYearId: string)
    {
        return this.http.get<iTrendReportOrganization>('api/trendReportHead/' + organizationUuid + '/' + fiscalYearId)
    }

    postTrendData(organizationUuid: string, fiscalYearId: string) 
    {
        const body = 
        {
            organizationUuid: organizationUuid,
            fiscalYearId: fiscalYearId
        }
        
        return this.http.post<any>('api/trendReportData', body);
    }
    
}