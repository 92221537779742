import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SubmissionScheduleService {

    constructor(
        private http: HttpClient,
    ) { }

    // getSubmissionSchedules(sortField: string, isSortDescending: boolean, limit: number, offset: number): Observable<any> {
    //     return this.http.get<any>("api/submissionSchedule/" + sortField + "/" + isSortDescending + "/" + limit + "/" + offset)
    // }

    getSubmissionSchedule(fiscalUuid): Observable<any> {
        return this.http.get<any>("api/submissionSchedule/" + fiscalUuid)
    }

    getBudgetAppropriation(uuid): Observable<any> {
        return this.http.get<any>("api/budgetAppropriation/" + uuid)
    }

    getScheduleMainTarget(uuid): Observable<any> {
        return this.http.get<any>("api/scheduleMainTarget/" + uuid)
    }

    submissionWizardStepThreeSubmit(uuid): Observable<any> {
        return this.http.get<any>("api/submissionWizardStepThreeSubmit/" + uuid)
    }

    getNewSubmissionEmailData(fiscalUuid): Observable<any> {
        return this.http.get<any>("api/newSubmissionEmailData/" + fiscalUuid)
    }

    submissionWizardFinish(uuid): Observable<any> {
        return this.http.get<any>("api/submissionWizardFinish/" + uuid)
    }

    // new route api calls - created by aaron

    getSubmissionSchedules(): Observable<any>
    {
        return this.http.get<any>('api/getSubmissionSchedule')
    }

}
