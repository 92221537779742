<div [ngClass]="{ 'button3': true, 
'final' : isFinal, 
'delete' : isDelete && !isFinal,
'isSmall': small,
'skinny': skinny,
'selected' : selected,
'unselected': unselected}" (click)="click()"
    [title]="title" #theButton align="center" style="display: inline-block;">
    <fa-icon *ngIf="processing" [icon]="faSpinner" [spin]="true" size="1x"></fa-icon>
    <fa-icon *ngIf="icon && !processing" class="mr-1" [icon]="icon" size="1x"></fa-icon>
    {{label}}
</div>