<div *ngIf="submissionSchedule$ | async as submission; else loading" class="container-fluid">
    <span *ngIf="missingMapping$ | async as missingMapping">
        <span *ngIf="viewMode$ | async as viewMode">
            <div class="row mt-1"></div>

            <div class="row">
                <div class="col-6">
                    <h2 style="display: inline-block;" data-cy="program_header">Submission Maintenance Wizard
                    </h2>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-3" *ngFor="let step of steps; let index = index">
                    <div class="step"
                        [ngClass]="{'selected-step': currentStepIndex == index, 'unselected-step': currentStepIndex != index}">
                        {{step}}
                    </div>
                </div>
            </div>

            <span *ngIf="currentStepIndex == 0">
                <div class="row mt-3">
                    <div class="col-6">

                        <div class="row">
                            <div class="col">
                                Program
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <app-input-enum-async [enum]="programs$ | async" [fc]="programFC"
                                    [disabled]="viewMode.disabled">
                                </app-input-enum-async>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                Fiscal Year
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <app-input-enum-async [enum]="fiscalYear$ | async" [fc]="fiscalYearFC"
                                    [disabled]="viewMode.disabled">
                                </app-input-enum-async>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                Submission Name
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <input *ngIf="!viewMode.disabled" type="text" class="input-style"
                                    [formControl]="submissionNameFC" maxLength="255">
                                <span class="ml-4" *ngIf="viewMode.disabled">{{submissionNameFC.value}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                Scheduled Run Date
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <app-input-date *ngIf="!viewMode.disabled" [fc]="runDateFC"
                                    [format]="InputDateFormat.none"></app-input-date>
                                <span class="ml-4" *ngIf="viewMode.disabled">{{runDateFC.value | date}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                *Date the submission records are to be created and notification sent to the agency
                                users.
                            </div>
                        </div>

                    </div>

                    <div class="col-6">


                        <div class="row">
                            <div class="col">
                                Submission Type
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <app-input-enum-async [enum]="submissionType$ | async" [fc]="submissionTypeFC"
                                    [disabled]="viewMode.disabled">
                                </app-input-enum-async>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                Status
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <app-input-enum-async [enum]="submissionStatus$ | async" [fc]="statusFC"
                                    [disabled]="viewMode.disabled">
                                </app-input-enum-async>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col">
                                Sequence (Campaign #)
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <input *ngIf="!viewMode.disabled" type="text" class="input-style"
                                    [formControl]="sequenceNumberFC">
                                <span class="ml-4" *ngIf="viewMode.disabled">{{sequenceNumberFC.value}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                Agency Due Date
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <app-input-date *ngIf="!viewMode.disabled" [fc]="dueDateFC"
                                    [format]="InputDateFormat.none"></app-input-date>
                                <span class="ml-4" *ngIf="viewMode.disabled">{{dueDateFC.value | date}}</span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                *Date that will appear on the Agency dashboard and be used for email reminder.
                            </div>
                        </div>

                    </div>
                </div>
            </span>

            <span *ngIf="currentStepIndex == 1">
                <div *ngIf="missingMapping.length > 0" class="row">
                    <div class="col">
                        <fa-icon [icon]="faTimes" style="color: red;"> </fa-icon>
                        Correct missing user role mappings for the
                        following Agencies.
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <table *ngIf="missingMapping.length > 0" class="table table-striped table-hover"
                            style="min-width: 100%">
                            <thead>
                                <tr style="background-color: lightgrey">
                                    <th *ngFor="let column of prerequisitesColumns">
                                        <span class="sort-column-header">
                                            {{column.label}}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row" *ngFor="let entry of missingMapping">
                                    <td class="px-1">
                                        {{entry.organization_name}}
                                    </td>
                                    <td class="px-1">
                                        {{entry.program_name}}
                                    </td>
                                    <td class="px-1">
                                        {{entry.submission_type_name}}
                                    </td>
                                    <td class="px-1">
                                        {{entry.jurisdiction_name}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <h3>Additional Approval</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <span>
                            <span
                                *ngIf="(this.submissionTypeFC.value == 'NOEP_BUDGET' || this.submissionTypeFC.value == 'NOEP_PROGRAM') && !submission.data_entry_complete">
                                Waiting for additional data entry to be completed.
                            </span>
                            <span
                                *ngIf="!(this.submissionTypeFC.value == 'NOEP_BUDGET' || this.submissionTypeFC.value == 'NOEP_PROGRAM') || submission.data_entry_complete">
                                <fa-icon [icon]="faCheck" style="color: green;"> </fa-icon>All set, ready to go!
                            </span>
                        </span>
                    </div>
                </div>
            </span>

            <span *ngIf="currentStepIndex == 2">
                <span *ngIf="newSubmissions$ | async as newSubmissions">
                    <div class="row">
                        <div class="col">
                            <table *ngIf="newSubmissions.length > 0" class="table table-striped table-hover"
                                style="min-width: 100%">
                                <thead>
                                    <tr style="background-color: lightgrey">
                                        <th *ngFor="let column of verifySubmissionsColumns">
                                            <span class="sort-column-header">
                                                {{column.label}}
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row" *ngFor="let entry of newSubmissions">
                                        <td class="px-1">
                                            {{entry.agency_name}}
                                        </td>
                                        <td class="px-1">
                                            {{entry.program_name}}
                                        </td>
                                        <td class="px-1">
                                            {{entry.submission_type_name}}
                                        </td>
                                        <td class="px-1">
                                            {{entry.jurisdiction_name}}
                                        </td>
                                        <td class="px-1">
                                            {{entry.submission_due_date | date : 'M/d/yy'}}
                                        </td>
                                    </tr>
                                    <tr class="table-row text-right">
                                        <td colspan="5">Total Agency Submissions: {{newSubmissions.length}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </span>
            </span>

            <span *ngIf="currentStepIndex == 3">

                <div class="row mt-2">
                    <div class="col">
                        <table class="table table-bordered">
                            <tr>
                                <td>
                                    Subject:
                                </td>
                                <td id="newSubmissionEmailSubject"></td>
                            </tr>
                            <tr>
                                <td>
                                    Contents:
                                </td>
                                <td id="newSubmissionEmailContents"></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="col" align="right">
                        <input type="checkbox" id="verifyCorrect" /> <label for="verifyCorrect"
                            style="vertical-align: super;">&nbsp;I verify the information is correct and understand
                            selecting Finish will send email notifications to appropriate users.</label>
                    </div>
                </div>
                <span *ngIf="newSubmissionEmailTemplate$ | async as newSubmissionEmailTemplate">
                </span>
            </span>

            <div class="row">
                <div class="col">
                    <app-button3 *ngIf="!viewMode.disabled && isFirstStep" class="mx-2" label="Cancel"
                        (click)="cancel()" [icon]="faBan">
                    </app-button3>
                    <app-button3 *ngIf="!viewMode.disabled && isFirstStep && canDelete" class="mx-2" label="Delete"
                        [clickFunction]="delete.bind(this)" [icon]="faMinus" [isDelete]="true">
                    </app-button3>
                    <app-button3 *ngIf="!viewMode.disabled" class="mx-2" style="float:right;" label="Save for Later"
                        [clickFunction]="saveForLater.bind(this)" [icon]="faSave" [isFinal]="true">
                    </app-button3>
                    <app-button3
                        *ngIf="!viewMode.disabled && !isLastStep && !(currentStepIndex == 1 && viewMode.id == 'e')"
                        class="mx-2" style="float:right;" label="Next" (click)="next(submission, missingMapping)"
                        [icon]="faLongArrowAltRight" [isFinal]="true">
                    </app-button3>
                    <app-button3 *ngIf="isLastStep" class="mx-2" style="float:right;" label="Finish" (click)="finish()"
                        [icon]="faShare" [isFinal]="true">
                    </app-button3>
                    <app-button3 *ngIf="viewMode.disabled || !isFirstStep" class="mx-2" label="Previous"
                        (click)="previous()" [icon]="faLongArrowAltLeft">
                    </app-button3>
                </div>
            </div>
        </span>
    </span>
</div>

<ng-template #loading>
    Loading...
</ng-template>