<div class="row">
    <div class="col" style="font-size: 1.2em;">
        <fa-icon [icon]="faHistory" class="mr-2"></fa-icon>
        History
    </div>
</div>
<div class="row" style="background-color: lightgrey;">
    <div class="col-4">
        Status
    </div>
    <div class="col-4">
        Last Updated
    </div>
    <div class="col-4">
        Last Updated By
    </div>
</div>

<div *ngIf="!submissionHistory || submissionHistory.length == 0" class="row">
    <div class="col" align="center">
        &lt;No History&gt;
    </div>
</div>

<span *ngIf="submissionHistory && submissionHistory.length > 0">
    <div *ngFor="let history of submissionHistory" class="row mt-2">
        <div class="col-4">
            {{history.status_name}}
        </div>
        <div class="col-4">
            {{history.status_date | date : 'M/d/y h:mm aaaaa\'m\''}}
        </div>
        <div class="col-4">
            {{history.principal_name}}
        </div>
    </div>
</span>
