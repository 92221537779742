import { Component, OnInit, Input } from '@angular/core';
import { BudgetService } from '../budget.service';

@Component({
  selector: 'app-budget-summary-header',
  templateUrl: './budget-summary-header.component.html',
  styleUrls: ['./budget-summary-header.component.css']
})
export class BudgetSummaryHeaderComponent implements OnInit {
  @Input() submission_uuid: string

  organization_name: string
  description: string

  constructor(private budgetService: BudgetService) { }

  ngOnInit() {
    if(this.submission_uuid) {
      this.budgetService.getSummaryHeader(this.submission_uuid).subscribe(results => {
        this.organization_name = results.result[0].organization_name
        this.description = results.result[0].description
      })
    }
  }

}
