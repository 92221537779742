import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { QuestionService } from '../question.service';
import { SnackRouteService } from '../snack-route.service';
import { faEdit, faSave, faBan, faUndo, faPlus, faExclamationTriangle, faMinus, faTimes, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ParamService } from '../param.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BudgetSubmissionAccountDialog } from '../budget-submission/budget-submission.component';
import { iAnswerSpecial } from '../model/answer-special.model';
import { AnswerDialog } from './answer-dialog/answer-dialog.component';

@Component({
    selector: 'app-edit-question',
    templateUrl: './edit-question.component.html',
    styleUrls: ['./edit-question.css']
})
export class EditQuestionComponent implements OnInit {
    faEdit = faEdit
    faSave = faSave
    faBan = faBan
    faUndo = faUndo
    faPlus = faPlus
    faMinus = faMinus
    faQuestionCircle = faQuestionCircle

    stepFC = new UntypedFormControl()
    groupNumberFC = new UntypedFormControl()
    groupNameFC = new UntypedFormControl()
    questionNumberFC = new UntypedFormControl()
    questionFC = new UntypedFormControl()
    helpHtmlFC = new UntypedFormControl()
    popupHtmlFC = new UntypedFormControl()
    answerTypeFC = new UntypedFormControl()
    activeFC = new UntypedFormControl(false)
    requiredFC = new UntypedFormControl(false)
    allowDuplicateFC = new UntypedFormControl(false)
    excludeExternalFC = new UntypedFormControl(false)
    initialized = false
    question
    answerSet = []
    showAnswerEdit = false

    constructor(
        private _questionService: QuestionService,
        private snackRouteService: SnackRouteService,
        private paramService: ParamService,
        public dialog: MatDialog,
    ) { }

    get questionService() {
        return this._questionService
    }

    // assignQuestionData(response)
    // {
    //     this.question = response.result[0]
    //     this.stepFC.setValue(response.result[0].step_name)
    //     this.groupNumberFC.setValue(this.question.group_number)
    //     this.groupNameFC.setValue(this.question.group_name)
    //     this.questionNumberFC.setValue(this.question.question_number)
    //     this.questionFC.setValue(this.question.name)
    //     this.helpHtmlFC.setValue(this.question.help_html)
    //     this.popupHtmlFC.setValue(this.question.popup_html)
    //     this.answerTypeFC.setValue(this.question.answer_datatype)
    //     this.activeFC.setValue(this.question.active)
    //     this.requiredFC.setValue(this.question.required)
    //     this.allowDuplicateFC.setValue(this.question.allow_duplicate)
    //     this.excludeExternalFC.setValue(this.question.exclude)
    //     if (response.result[0].answer_uuid) {
    //         for (var i = 0; i < response.result.length; ++i) {
    //             const answer: iAnswerSpecial = 
    //             {
    //                 uuid: response.result[i].answer_uuid,
    //                 questionUuid: this.questionId,
    //                 name: response.result[i].answer_name,
    //                 display: response.result[i].answer_display

    //             };
              
    //             this.answerSet.push(answer);
    //         }
    //     }
    // }

    // getQuestionData()
    // {
    //     this.questionService.getQuestionById(this.questionId).subscribe((response) => {
    //         console.log(response)
    //         if (response.success)
    //         {
    //             this.assignQuestionData(response);
    //         }
    //         else
    //         {
    //             console.log('error getting question data', response)
    //         }
    //     })
    // }

    // openDialog(answer: any): void
    // {
    //     console.log(answer)
    //     const dialogRef = this.dialog.open(EditAnswerDialog, 
    //     {
    //         minWidth: '480px',
    //         data: answer
    //     })
    //     dialogRef.afterClosed().subscribe(result => {
    //         console.log('The dialog was closed having data: ' + JSON.stringify(result));
    //         window.location.reload();
    //     })
    // }

    openDialog(isEdit: boolean, answer?: iAnswerSpecial): void 
    {
        if (this.hasFormChanged())
        {
            this.save(false);
        }

        const dialogRef = this.dialog.open(AnswerDialog, {
          data: {
            questionUuid: this.questionId,
            isEdit: isEdit,
            answer: answer,
          },
        });
      
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed having data: ' + JSON.stringify(result));
          window.location.reload();
        });
      }

      // use this function to see if the form control values match the original values pulled from the db
      hasFormChanged(): boolean
      {
        const currentValues = 
        {
            step_name: this.stepFC.value,
            group_number: this.groupNumberFC.value,
            group_name: this.groupNameFC.value,
            question_number: this.questionNumberFC.value,
            name: this.questionFC.value,
            help_html: this.helpHtmlFC.value,
            popup_html: this.popupHtmlFC.value,
            answer_datatype: this.answerTypeFC.value,
            active: this.activeFC.value,
            required: this.requiredFC.value,
            allow_duplicate: this.allowDuplicateFC.value,
            exclude: this.excludeExternalFC.value
        };
    
        // compare each property of currentValues with the original values in this.question
        for (const key in currentValues) 
        {
            if (currentValues[key] !== this.question[key]) 
            {
                return true; // difference found
            }
        }

        return false; // no difference found
    }
    
      

    getQuestionPromise() {
        return new Promise((resolve, reject) => {
            console.log(this.questionId)
            this.questionService.getQuestionById(this.questionId).subscribe((response) => {
                if (response.success) {
                    //console.log("response", response)
                    if (response.result.length < 1) {
                        console.log("unexpected empty result encountered")
                    } else {
                        this.question = response.result[0]
                        this.stepFC.setValue(response.result[0].step_name)
                        this.groupNumberFC.setValue(this.question.group_number)
                        this.groupNameFC.setValue(this.question.group_name)
                        this.questionNumberFC.setValue(this.question.question_number)
                        this.questionFC.setValue(this.question.name)
                        this.helpHtmlFC.setValue(this.question.help_html)
                        this.popupHtmlFC.setValue(this.question.popup_html)
                        this.answerTypeFC.setValue(this.question.answer_datatype)
                        this.activeFC.setValue(this.question.active)
                        this.requiredFC.setValue(this.question.required)
                        this.allowDuplicateFC.setValue(this.question.allow_duplicate)
                        this.excludeExternalFC.setValue(this.question.exclude)
                        if (response.result[0].answer_uuid) {
                            for (var i = 0; i < response.result.length; ++i) {
                                this.answerSet.push({
                                    uuid: response.result[i].answer_uuid,
                                    name: response.result[i].answer_name,
                                    display: response.result[i].answer_display,
                                    questionUuid: this.questionId
                                })
                            }
                        }
                    }
                } else {
                    console.log("error while fetching question", response)
                }
                resolve(null)
            })
        })
    }

    getGroupPromise() {
        return new Promise((resolve, reject) => {
            this.questionService.getGroupById(this.groupId).subscribe((response) => {
                if (response.success) {
                    if (response.result.length != 1) {
                        console.log("unexpected nonsingular result encountered")
                    } else {
                        //console.log("got group", response)
                        this.stepFC.setValue(response.result[0].step_name)
                        this.groupNumberFC.setValue(response.result[0].group_number)
                        this.groupNameFC.setValue(response.result[0].name)
                    }
                } else {
                    console.log("error while fetching group", response)
                }
                resolve(null)
            })
        })
    }

    answerDatatypes
    getAnswerDatatypesPromise() {
        return new Promise((resolve, reject) => {
            this.questionService.getAnswerDatatypes().subscribe((response) => {
                console.log("answer types", response)
                if (response.success) {
                    this.answerDatatypes = response.result
                } else {
                    console.log("error while fetching group", response)
                }
                resolve(null)
            })
        })
    }

    groupId
    questionId

    ngOnInit() {
        this.paramService.getParamPromise("groupId").then((paramValue) => {
            this.groupId = paramValue
            return this.paramService.getParamPromise("questionId")
        }).then((paramValue) => {
            this.questionId = paramValue

            if (!this.groupId && !this.questionId) {
                return this.snackRouteService.snackRoute("No group or question specified", "/manage-questions")
            }

            var initPromise
            if (this.questionId != null) {
                initPromise = this.getQuestionPromise()
            } else {
                initPromise = this.getGroupPromise()
            }

            return Promise.all(
                [
                    initPromise,
                    this.getAnswerDatatypesPromise()
                ]
            )
        }).then(() => {
            this.initialized = true
        })
    }

    error
    validate() {
        this.error = null
        if (this.questionNumberFC.value && !Number.isInteger(parseFloat(this.questionNumberFC.value))) {
            return this.error = "Question # must be an integer."
        }
        if (!this.questionFC.value) {
            return this.error = "Please specify the question."
        }
        if (!this.answerTypeFC.value) {
            return this.error = "Please specify an answer type."
        }
    }

    save(redirectToManageQuestions = true) 
    {
        this.validate();
        if (this.error) {
            return;
        }
    
        this.questionService.saveQuestion(
            this.groupId,
            this.questionId,
            this.questionFC.value,
            this.questionNumberFC.value,
            this.answerTypeFC.value,
            this.helpHtmlFC.value,
            this.popupHtmlFC.value,
            this.activeFC.value,
            this.requiredFC.value,
            this.allowDuplicateFC.value,
            this.excludeExternalFC.value
        ).subscribe((response) => {
            if (response.success) 
            {
                if (redirectToManageQuestions) 
                {
                    this.snackRouteService.snackRoute("Question successfully saved", "/manage-questions");
                } else 
                {
                    this.snackRouteService.snack("Question successfully saved");
                }
            } else 
            {
                console.log("Error while saving question", response);
                this.snackRouteService.snack("Error encountered while saving question");
            }
        });
    }
    

    cancel() {
        return this.snackRouteService.snackRoute("Action Cancelled", "/manage-questions")
    }

    //todo - open popup!
    delete() {
        const dialogRef = this.dialog.open(EditQuestionDeleteConfirmationDialog, {
            panelClass: 'custom-dialog-container-no-reason',
            data: {
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                //this.fetchDataPromise()
                this.questionService.deleteQuestion(this.questionId).subscribe((response) => {
                    //console.log("delete response", response)
                    if (!response.success) {
                        console.log("delete error", response)
                        return
                    }
                    if (response.success) {
                        this.snackRouteService.snackRoute("Question deleted", "/manage-questions")
                        // this._snackBar.open("Deleted", null, {
                        //     duration: 4000,
                        // });
                        //this.dialogRef.close({});
                    }
                })
            } else {
            }
        });
        // this.questionService.deleteQuestion(this.questionId).subscribe((response) => {
        //     if (!response.success) {
        //         console.log("delete error", response)
        //         return
        //     }
        //     this.snackRouteService.snackRoute("Question deleted", "/manage-questions")
        // })
    }

    editAnswersClick() {
        this.showAnswerEdit = !this.showAnswerEdit
    }

    cancelCurrentEdit() {
        for (var i = 0; i < this.answerSet.length; ++i) {
            if (this.answerSet[i]) {
                this.cancelEditAnswer(this.answerSet[i])
                break
            }
        }
    }

    addAnswerClick() {
        this.cancelCurrentEdit()
    }

    removeAnswer(index) {
        this.answerSet.splice(index, 1)
    }

    oldName
    oldDisplay
    editAnswer(answer) {
        this.cancelCurrentEdit()

        this.oldName = answer.name.value
        this.oldDisplay = answer.display.value
        answer.edit = true
    }

    cancelEditAnswer(answer) {
        answer.name.setValue(this.oldName)
        answer.display.setValue(this.oldDisplay)
        answer.edit = false
    }

    setEditAnswer(answer) {
        answer.edit = false
        this.answerSet.sort((a, b) => {
            return a.display - b.display
        })
    }

}

@Component({
    selector: 'edit-question-delete-confirmation-dialog',
    templateUrl: './edit-question-delete-confirmation-dialog.html',
})
export class EditQuestionDeleteConfirmationDialog {
    faExclamationTriangle = faExclamationTriangle
    constructor(
        public dialogRef: MatDialogRef<EditQuestionDeleteConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private snackRoute: SnackRouteService,
    ) { }
    faBan = faBan
    faMinus = faMinus
    faTimes = faTimes

    cancel() {
        this.snackRoute.snack("Action cancelled.")
        this.dialogRef.close();
    }

    delete() {
        this.dialogRef.close({});
    }
}