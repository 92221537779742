import { Component, OnInit, Input } from '@angular/core';

export enum DisplayType {
    fk,
    date,
    textArea,
    number,
    text
}

@Component({
    selector: 'app-typed',
    templateUrl: './typed.component.html',
})
export class TypedComponent implements OnInit {
    @Input() fc
    @Input() disabled
    @Input() columnDefinition
    @Input() values
    @Input() width
    DisplayType = DisplayType
    displayType: DisplayType
    maxlength
    mantissalength
    align
    
    constructor() { }

    isColumnTypeAlignedRight(type) {
        if (type
            && (type.startsWith('decimal')
                || type.startsWith('int')
                || type.startsWith('tinyint'))) {
            return true
        }
        return false
    }


    showFieldAsFK(column) {
        return column.referenced_table_name && this.values
    }

    showFieldAsDate(column) {
        return !this.showFieldAsFK(column) && column.Type == 'datetime'
    }

    showFieldAsTextArea(column) {
        if (this.showFieldAsFK(column)) {
            return false
        }
        const typePrefix = "varchar("
        if (!column.Type.startsWith(typePrefix)) {
            return false
        }
        var lenString = column.Type.substring(typePrefix.length, column.Type.indexOf(")", typePrefix.length))
        if (parseInt(lenString) > 256) {
            return true
        }
        return false
    }

    showFieldAsNumber(column) {
        if (this.showFieldAsFK(column)) {
            return false
        }
        return column.Type.startsWith("int(") || column.Type.startsWith("decimal(")
    }

    showFieldAsText(column) {
        return !this.showFieldAsFK(column) && !this.showFieldAsDate(column) && !this.showFieldAsTextArea(column) && !this.showFieldAsNumber(column)
    }


    ngOnInit() {
        //
        if (this.isColumnTypeAlignedRight(this.columnDefinition.Type)) {
            this.align = "right"
        }
        ["varchar(", "int("].forEach((item) => {
            if (this.columnDefinition.Type.startsWith(item)) {
                this.maxlength = this.columnDefinition.Type.substring(item.length,
                    this.columnDefinition.Type.indexOf(")"))
            }
        })
        if (this.columnDefinition.Type.startsWith("decimal(")) {
            this.maxlength = this.columnDefinition.Type.substring("decimal(".length,
                this.columnDefinition.Type.indexOf(","))
            this.mantissalength = this.columnDefinition.Type.substring(
                this.columnDefinition.Type.indexOf(",") + 1,
                this.columnDefinition.Type.indexOf(")"))
        }

        if (this.showFieldAsFK(this.columnDefinition)) {
            this.displayType = DisplayType.fk
        }
        if (this.showFieldAsDate(this.columnDefinition)) {
            this.displayType = DisplayType.date
        }
        if (this.showFieldAsTextArea(this.columnDefinition)) {
            this.displayType = DisplayType.textArea
        }
        if (this.showFieldAsNumber(this.columnDefinition)) {
            this.displayType = DisplayType.number
        }
        if (this.showFieldAsText(this.columnDefinition)) {
            this.displayType = DisplayType.text
        }
    }
}
