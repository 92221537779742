<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faFileInvoiceDollar"></fa-icon>
            Voucher Summary
        </div>
    </div>
    <!-- HSNHY-2088 Remove print detail button -->
    <div class="row">
        <div class="col px-0 text-right">
            <app-button3 label="Print Summary" [clickFunction]="print.bind(this)" [icon]="faPrint" [small]="true">
            </app-button3>
            
            <app-button3 label="Print Detail" [clickFunction]="printDetail.bind(this)" [icon]="faPrint" [small]="true"
                class="ml-2">
            </app-button3>
           
        </div>
    </div>
    <app-submission-header [submission]="submission$ | async" [statusesById]="submissionStatuses$ | async"
        [hideRecertificationDate]="'true'"></app-submission-header>

    <app-voucher-submission [showFlag]="showFlag$ | async" [editable]="editable" [dd]="dd"></app-voucher-submission>

    <app-submission-activity-feed [submissionActivityFeed]="submissionActivityFeed$ | async">
    </app-submission-activity-feed>

    <app-submission-history [submissionHistory]="submissionHistory$ | async"></app-submission-history>

    <app-submission-attachments [submissionUuid]="submissionUuid"></app-submission-attachments>

    <app-submission-attachment-controls [submissionUuid]="submissionUuid">
    </app-submission-attachment-controls>

</div>