<div *ngIf="!style" class="row edit-height" [ngClass]="calcRowClass">
    <div class="col-4 unedit-padding bold">
        {{label}}
    </div>
    <div class="col-8">
        <textarea *ngIf="!disabled" [rows]="rowCount" [formControl]="fc" (keyup)="doChange()" [maxlength]="maxlength"
            style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
        </textarea>
        <span *ngIf="disabled">{{fc.value}}</span>
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row mt-3">
    <div class="col bold" style="color: darkgrey">
        {{label}}
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row mt-1">
    <div class="col">
        <textarea [rows]="rowCount" [formControl]="fc" (keyup)="doChange()"
            style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
        </textarea>
    </div>
</div>
<div *ngIf="style == 'rebrand3'" class="row">
    <div class="col bold" style="color: darkgrey">
        {{label}}
    </div>
</div>
<div *ngIf="style == 'rebrand3'" class="row mt-1">
    <div class="col">
        <textarea [rows]="rowCount" [formControl]="fc" (keyup)="doChange()"
            style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
        </textarea>
    </div>
</div>
<div *ngIf="style == 'edit-sbl'" class="row mt-1">
    <div class="col">
        <textarea *ngIf="!disabled" [rows]="rowCount" [formControl]="fc" (keyup)="doChange()"
            style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
        </textarea>
        <span *ngIf="disabled">{{fc.value}}</span>
    </div>
</div>
<span *ngIf="style == 'no-container'">
    <textarea *ngIf="!disabled" [rows]="rowCount" [formControl]="fc" (keyup)="doChange()"
        style="vertical-align: top; width: 100%; resize: both; border-radius: .3em;">
        </textarea>
    <span *ngIf="disabled">{{fc.value}}</span>
</span>