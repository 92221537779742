import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-personnel-calculation-worksheet',
  templateUrl: './personnel-calculation-worksheet.component.html',
  styleUrls: ['./personnel-calculation-worksheet.component.css']
})
export class PersonnelCalculationWorksheetComponent implements OnInit 
{

  @Input() annualSalary: number;
  @Input() numberOfPaychecks: number;
  @Input() hoursPerPayPeriod: number;
  @Input() newAnnualSalary: number;
  @Input() preRaiseDays: number;
  @Input() hoursPerDay: number;
  @Input() preRaiseHourlyRate: number;
  @Input() postRaiseDays: number;
  @Input() postRaiseHourlyRate: number;
  @Input() bonus: number;
  @Input() estimatedPercentTimeOnContract: number;
  @Input() hourlyRateFromSalaryTotal: number;
  @Input() newHourlyRateFromSalaryTotal: number;
  @Input() preRaiseAmountTotal: number;
  @Input() postRaiseAmountTotal: number;
  @Input() calculatedAmountTotal: number;
  @Input() receiveRaise: boolean;
  @Input() receiveBonus: boolean;

  constructor() { }

  ngOnInit() 
  {

  }

}
