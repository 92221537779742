<div class="row">
    <div class="col" style="font-size: 1.2em;">
        <fa-icon [icon]="faSpinner" class="mr-2"></fa-icon>
        Activity
    </div>
</div>
<div class="row" style="background-color: lightgrey;">
    <div class="col">
        &nbsp;
    </div>
</div>

<div *ngIf="!submissionActivityFeed || submissionActivityFeed.length == 0" class="row">
    <div class="col" align="center">
        &lt;No Activity&gt;
    </div>
</div>

<span *ngIf="submissionActivityFeed && submissionActivityFeed.length > 0">
    <div *ngFor="let activity of submissionActivityFeed" class="row activity-row mt-2">
        <div class="col-2">
            <span id="photo_{{activity.uuid}}"></span>
        </div>
        <div class="col">
            <div class="row">
                <div class="col-6">
                    <span *ngIf="activity.full_name">{{activity.full_name}}</span>
                    <span *ngIf="!activity.full_name">{{activity.email}}</span>
                </div>
                <div class="col-6">
                    {{activity.activity_created | date : 'M/d/y h:mm aaaaa\'m\''}}
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    {{activity.comment}}
                </div>
            </div>
        </div>
    </div>
</span>
