import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BudgetService } from '../budget.service';
import { faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-report-budget',
  templateUrl: './report-budget.component.html',
  styleUrls: ['./report-budget.component.css']
})
export class ReportBudgetComponent implements OnInit {
  faFileInvoiceDollar = faFileInvoiceDollar
  faPrint = faPrint

  submission_uuid: string

  // header variables
  organization_name: string
  description: string
  contract: string
  submitted_by: string
  approved_by: string
  approved_on: Date

  // report variables
  report_data = []
  report_allocation = []

  total_state_only: number = 0
  total_state_match: number = 0
  total_federal_match: number = 0
  total_totals: number = 0

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private budgetService: BudgetService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.submission_uuid = params['submissionUuid']
    })
 
    this.budgetService.getReportHeader(this.submission_uuid).subscribe((response) => {
      this.organization_name = response.result[0].organization_name
      this.description = response.result[0].name
      this.contract = response.result[0].contract
      this.submitted_by = response.result[0].submitted_by
      this.approved_by = response.result[0].full_name
      this.approved_on = response.result[0].approval_date
    })

    this.budgetService.getReport(this.submission_uuid).subscribe((response) => {
      this.report_data = response.result
      // console.log(this.report_data)

      for(let i = 0; i < this.report_data.length; i++) {
        if(this.report_data[i].total_state) {
          this.total_state_only += this.report_data[i].total_state
        }
        if(this.report_data[i].total_federal) {
          this.total_state_match += this.report_data[i].total_federal / 2
          this.total_federal_match += this.report_data[i].total_federal / 2
        }
        if(this.report_data[i].total) {
          this.total_totals += this.report_data[i].total
        }
      }

      // console.log(this.total_state_only, this.total_state_match, this.total_federal_match, this.total_totals)

    })

    this.budgetService.getReportAllocation(this.submission_uuid).subscribe((response) => {
      this.report_allocation = response.result
    })
  }

  print() {
    window.print()
  }

  printDetail() {
      const url = this.router.serializeUrl(this.router.createUrlTree(['budget-summary-detail'], {queryParams: {submissionUuid: this.submission_uuid}}))
      window.open(url, '_blank')
  }

}
