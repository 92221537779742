<h1 mat-dialog-title>Attachment Comment</h1>


<mat-dialog-content [formGroup]="form" >
    <p><mat-form-field style="width: 100%">
        <textarea matInput
            type="text"
            placeholder="Flag Comment"
            value="{{data.comment}}"
            formControlName="comment"
            maxlength="255"
        >
        </textarea>
    </mat-form-field></p>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="saveComment()" [disabled]="!form.valid">Save</button>&nbsp;
    <button mat-raised-button color="warn" (click)="deleteComment()">Delete </button>&nbsp;
    <button mat-raised-button (click)="close()" style="margin-right: 5px;">Close </button>
</mat-dialog-actions>