<hr>

<div class="col">
    <fa-icon [icon]="faThumbsUp" class="mr-2" title="Fringe">
    </fa-icon>
    <h4 style="display: inline-block;">Fringe</h4>
</div>


<table mat-table [dataSource]="tableOneData" class="mat-elevation-z8" *ngIf="showTableOne">
    <!-- Benefit Column -->
    <ng-container matColumnDef="benefit">
        <th mat-header-cell *matHeaderCellDef>Benefit</th>
        <td mat-cell *matCellDef="let fringe" class="benefit-cell">
            <!-- {{fringe.accountName}} <mat-icon *ngIf="showFlag && fringe.commentExists" class="flagIcon">flag</mat-icon>
            <span style="overflow-inline: hidden;" *ngIf="fringe.agencyComment"><br />{{fringe.agencyComment}}</span> -->
            <div class="name-container">
                <div 
                class="benefit-text" 
                (click)="openDialog(fringe)">
                    {{fringe.accountName}} <mat-icon *ngIf="showFlag && fringe.commentExists" class="flagIcon">flag</mat-icon>
                </div>
                <div 
                class="agency-comment"
                *ngIf="fringe.agencyComment" 
                (click)="viewAgencyComment(fringe.agencyComment)">
                    <!-- <textarea cols="25" style="resize: none;" disabled>{{fringe.agencyComment}}</textarea> -->
                    View Agency Comment
                </div>
            </div>
        </td>
    </ng-container>
    
    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amt</th>
        <td mat-cell *matCellDef="let fringe">{{ amountChargedToContract | currency }}</td>
    </ng-container>
    
    <!-- Percent Column -->
    <ng-container matColumnDef="percent">
        <th mat-header-cell *matHeaderCellDef>%</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.percentage | number:'1.0-2' }}</td>
    </ng-container>
    
    <!-- Calculated Amount Column -->
    <ng-container matColumnDef="calculatedAmount">
        <th mat-header-cell *matHeaderCellDef>Calc Amt</th>
        <td mat-cell *matCellDef="let fringe">{{ amountChargedToContract * (fringe.percentage / 100) | roundUp | currency }}</td>
    </ng-container>
    
    <!-- Amount Charged to Contract Column -->
    <ng-container matColumnDef="amountChargedToContract">
        <th mat-header-cell *matHeaderCellDef>Amt Charged</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.unitPrice | currency }}</td>
    </ng-container>
    
    <!-- State Only Column -->
    <ng-container matColumnDef="stateOnly">
        <th mat-header-cell *matHeaderCellDef>State Only</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitState | currency }}</td>
    </ng-container>
    
    <!-- State/Federal Match Column -->
    <ng-container matColumnDef="stateFederalMatch">
        <th mat-header-cell *matHeaderCellDef>State/Federal Match</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitFederal | currency }}</td>
    </ng-container>
    
    <!-- State Only+ Column -->
    <ng-container matColumnDef="stateOnlyPlus">
        <th mat-header-cell *matHeaderCellDef>State Only+</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitState | currency }}</td>
    </ng-container>
    
    <!-- State Match+ Column -->
    <ng-container matColumnDef="stateMatchPlus">
        <th mat-header-cell *matHeaderCellDef>State Match+</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitFederal / 2 | currency }}</td>
    </ng-container>
    
    <!-- Federal Match+ Column -->
    <ng-container matColumnDef="federalMatchPlus">
        <th mat-header-cell *matHeaderCellDef>Federal Match+</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitFederal / 2 | currency }}</td>
    </ng-container>
    
    <!-- Total Charged to Contract Column -->
    <ng-container matColumnDef="totalChargedToContract">
        <th mat-header-cell *matHeaderCellDef>= Total Charged</th>
        <td mat-cell *matCellDef="let fringe">{{ +fringe.debitState + +fringe.debitFederal | currency }}</td>
    </ng-container>

    <!-- Agency Comment Column -->
    <!-- <ng-container matColumnDef="agencyComment">
        <th mat-header-cell *matHeaderCellDef>Agency Comment</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.agencyComment }}</td>
    </ng-container> -->
    
    <!-- Define the header row -->
    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableOne"></tr>
    <!-- Define the data row -->
    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableOne;"></tr>
</table>


<table mat-table [dataSource]="tableTwoData" class="mat-elevation-z8">
    <!-- Benefit Column -->
    <ng-container matColumnDef="benefit">
        <th mat-header-cell *matHeaderCellDef>Benefit</th>       
        <td mat-cell *matCellDef="let fringe" class="benefit-cell">
   <!-- <td mat-cell *matCellDef="let fringe" (click)="openDialog(fringe)" class="benefit-text">
            {{fringe.accountName}} <mat-icon *ngIf="showFlag && fringe.commentExists" class="flagIcon">flag</mat-icon> -->
            <div class="name-container">
                <div 
                class="benefit-text" 
                (click)="openDialog(fringe)">
                    {{fringe.accountName}} <mat-icon *ngIf="showFlag && fringe.commentExists" class="flagIcon">flag</mat-icon>
                </div>
                <div 
                class="agency-comment"
                *ngIf="fringe.agencyComment" 
                (click)="viewAgencyComment(fringe.agencyComment)">
                    <!-- <textarea cols="25" style="resize: none;" disabled>{{fringe.agencyComment}}</textarea> -->
                    View Agency Comment
                </div>
            </div>
        </td>
    </ng-container>
    
    <!-- Amount Column -->
    <ng-container matColumnDef="annualPremium">
        <th mat-header-cell *matHeaderCellDef>Annual Premium</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.fringeAmount | currency }}</td>
    </ng-container>
    
    <!-- Percent Column -->
    <ng-container matColumnDef="employerPercent">
        <th mat-header-cell *matHeaderCellDef>Employer %</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.percentage | number:'1.0-2' }}</td>
    </ng-container>
      
    
    <!-- Calculated Amount Column -->
    <!-- <ng-container matColumnDef="employeePercent">
        <th mat-header-cell *matHeaderCellDef>Employee %</th>
        <td mat-cell *matCellDef="let fringe">{{ 100 - fringe.percentage | number:'1.0-2' }}</td>
    </ng-container> -->
    
    <!-- Amount Charged to Contract Column -->
    <ng-container matColumnDef="calculatedAmount">
        <th mat-header-cell *matHeaderCellDef>Calc Amt({{ estimatedPercentTimeOnContract * 100 }}% on Contract)</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.fringeAmount * estimatedPercentTimeOnContract * (fringe.percentage / 100) | roundUp | currency }}</td>
    </ng-container>

    <ng-container matColumnDef="amountChargedToContract">
        <th mat-header-cell *matHeaderCellDef>Amt Charged</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.unitPrice | currency }}</td>
    </ng-container>
    
    <!-- State Only Column -->
    <ng-container matColumnDef="stateOnly">
        <th mat-header-cell *matHeaderCellDef>State Only</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitState | currency }}</td>
    </ng-container>
    
    <!-- State/Federal Match Column -->
    <ng-container matColumnDef="stateFederalMatch">
        <th mat-header-cell *matHeaderCellDef>State/Federal Match</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitFederal | currency }}</td>
    </ng-container>
    
    <!-- State Only+ Column -->
    <ng-container matColumnDef="stateOnlyPlus">
        <th mat-header-cell *matHeaderCellDef>State Only+</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitState | currency }}</td>
    </ng-container>
    
    <!-- State Match+ Column -->
    <ng-container matColumnDef="stateMatchPlus">
        <th mat-header-cell *matHeaderCellDef>State Match+</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitFederal / 2 | currency }}</td>
    </ng-container>
    
    <!-- Federal Match+ Column -->
    <ng-container matColumnDef="federalMatchPlus">
        <th mat-header-cell *matHeaderCellDef>Federal Match+</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.debitFederal / 2 | currency }}</td>
    </ng-container>
    
    <!-- Total Charged to Contract Column -->
    <ng-container matColumnDef="totalChargedToContract">
        <th mat-header-cell *matHeaderCellDef>= Total Charged</th>
        <td mat-cell *matCellDef="let fringe">{{ +fringe.debitState + +fringe.debitFederal | currency }}</td>
    </ng-container>
    
    <!-- Agency Comment Column -->
    <!-- <ng-container matColumnDef="agencyComment">
        <th mat-header-cell *matHeaderCellDef>Agency Comment</th>
        <td mat-cell *matCellDef="let fringe">{{ fringe.agencyComment }}</td>
    </ng-container> -->
        
    <!-- Define the header row -->
    <tr mat-header-row *matHeaderRowDef="displayedColumnsTableTwo"></tr>
    <!-- Define the data row -->
    <tr mat-row *matRowDef="let row; columns: displayedColumnsTableTwo;"></tr>
</table>
