import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsCommunicationService 
{
  private attachmentUploadSubject = new Subject<void>();

  attachmentUpload$ = this.attachmentUploadSubject.asObservable();

  triggerButtonClick() 
  {
    this.attachmentUploadSubject.next();
  }
}
