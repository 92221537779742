<h1 mat-dialog-title>Select New User Organization</h1>
<mat-dialog-content>
  <form [formGroup]="orgForm">
    <mat-form-field appearance="fill" class="custom-mat-field">
      <mat-label>Organization</mat-label>
      <mat-select formControlName="organization" required>
        <mat-option *ngFor="let org of organizations" [value]="org.uuid">
          {{ org.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="orgForm.get('organization').hasError('required')">
        Please select an Organization
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="addUser()">Add User</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
