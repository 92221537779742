import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { VoucherService } from '../voucher.service'



@Component({
    selector: 'voucher-lines-edit',
    templateUrl: './voucher-lines-edit.component.html',
    styleUrls: ['./voucher-lines-edit.component.css'],
})
export class VoucherLinesEditComponent implements OnInit {
    form!: UntypedFormGroup

    line_uuid: string
    account_uuid: string
    submission_uuid: string
    description: string
    name: string
    period: string
    quantity_est: number
    quantity: number
    unit_price: number
    invoice_number: string
    premium: number
    dates: string
    line_date: string
    state_only: number
    state_federal: number
    other_amount: number
    line_reference_number: string
    line_reference_date: string
    check_amount: number
    metadata: string
    method: string
    line_type: string
    line_number: number
    percent: number
    allow_percent: string

    saveError = false

	constructor(
        private dialog: MatDialog,
        private voucherService: VoucherService,
        @Inject(MAT_DIALOG_DATA) public data: any) 
        {
            this.line_uuid = data.line_uuid
            this.account_uuid = data.account_uuid
            this.description = data.description
            this.name = data.name
            this.period = data.period
            this.quantity_est = data.quantity_est
            this.quantity = data.quantity
            this.unit_price = data.unit_price
            this.invoice_number = data.invoice_number
            this.premium = data.premium
            this.dates = data.dates
            this.line_date = data.line_date
            this.other_amount = data.other_amount
            this.state_only = data.state_only
            this.state_federal = data.state_federal
            this.line_reference_number = data.line_reference_number
            this.line_reference_date = data.line_reference_date
            this.check_amount = data.check_amount
            this.metadata = data.metadata
            this.method = data.method
            this.line_type = data.line_type
            this.line_number = data.line_number
            this.percent = data.percent
            this.allow_percent = data.allow_percent
        }


    close() {
        this.dialog.closeAll()
    }

    deleteLine() {
        this.voucherService.deleteVoucherLine(this.line_uuid).subscribe((result: any) => {
          if(result.success == true) {
            this.close()
          }
        })
    }

    editLine() {
        if(this.form.value.other_amount < +this.form.value.state_only + +this.form.value.state_federal) {
            this.saveError = true
        } else {
            this.saveError = false
            this.voucherService.editVoucherLine(
                this.line_uuid = this.line_uuid,
                this.description = this.form.value.description,
                this.name = this.form.value.name,
                this.period = this.form.value.period,
                this.quantity_est = this.form.value.quantity_est,
                this.quantity = this.form.value.quantity,
                this.unit_price = this.form.value.unit_price,
                this.invoice_number = this.form.value.invoice_number,
                this.premium = this.form.value.premium,
                this.dates = this.form.value.dates,
                this.line_date = this.form.value.line_date,
                this.state_only = this.form.value.state_only,
                this.state_federal = this.form.value.state_federal,
                this.other_amount = this.form.value.other_amount,
                this.line_reference_number = this.form.value.line_reference_number,
                this.line_reference_date = this.form.value.line_reference_date,
                this.check_amount = this.form.value.check_amount,
                this.metadata = this.form.value.metadata,
                this.method = this.form.value.method,
                this.line_number = this.line_number,
                this.percent = this.form.value.percent
            ).subscribe((result) => {
                if(result.success == true) {
                    this.close()

                }
            }, err => console.log("error:", err))
        }
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            description: new UntypedFormControl(this.description, [
                Validators.required
            ]),
            name: new UntypedFormControl(this.name, [
            ]),
            period: new UntypedFormControl(this.period, []),
            quantity_est: new UntypedFormControl(this.quantity_est, []),
            quantity: new UntypedFormControl(this.quantity, []),
            unit_price: new UntypedFormControl(this.unit_price, []),
            invoice_number: new UntypedFormControl(this.invoice_number, []),
            premium: new UntypedFormControl(this.premium, []),
            dates: new UntypedFormControl(this.dates, []),
            line_date: new UntypedFormControl(this.line_date, [
            ]),
            other_amount: new UntypedFormControl(this.other_amount, [Validators.required
            ]),
            state_only: new UntypedFormControl(this.state_only, [
                Validators.required
            ]),
            state_federal: new UntypedFormControl(this.state_federal, [
                Validators.required
            ]),
            line_reference_number: new UntypedFormControl(this.line_reference_number, [
            ]),
            line_reference_date: new UntypedFormControl(this.line_reference_date, []),
            check_amount: new UntypedFormControl(this.check_amount, []),
            metadata: new UntypedFormControl(this.metadata, []),
            method: new UntypedFormControl(this.method, []),
            percent: new UntypedFormControl(this.percent, [])
        })
    }
}
