<div class="row">
    <div class="col-4 bold data-row">
        {{label}}
        <fa-icon *ngIf="commentId && showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick(commentId)"
            [title]="commentData[commentId].showInputContainer.value ? 'Cancel' : 'Edit'">
        </fa-icon>
    </div>
    <div class="col-8">
        <textarea [rows]="rowCount" [formControl]="fc" (keyup)="doChange($event)" class="input-style mt-1"
            style="border-color: lightgrey; padding-right: .25em; vertical-align: top; width: 100%; resize: both; border-radius: .3em;"
            [maxlength]="maxlength">
        </textarea>
    </div>
</div>
<app-outreach-submission-comment *ngIf="commentId" [submissionUuid]="submissionUuid" [inputId]="commentId"
    [commentUuid]="commentData[commentId].commentUuid" [comment]="commentData[commentId].comment"
    [showInputContainer]="commentData[commentId].showInputContainer" [showFlag]="showFlag" [odd]="false"
    [targetUuid]="targetUuid">
</app-outreach-submission-comment>