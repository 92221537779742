import { Component, OnInit, AfterViewInit, ViewChild} from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../auth.service'
import { faBullseye, faSave, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { MainTargetService } from '../main-target.service'
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, UntypedFormBuilder} from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { MainTargetEntityModel } from './main-target-entity.model'
import { MatDialog } from "@angular/material/dialog";
import { MainTargetEntityEditComponent } from './main-target-entity-edit.component'
import { SessionService } from '../session.service'


@Component({
    selector: 'main-target-entity',
    templateUrl: './main-target-entity.component.html',
    styleUrls: ['./main-target-entity.component.css']
})
export class MainTargetEntityComponent implements OnInit {
    mtDataSource: MatTableDataSource<MainTargetEntityModel>

    fiscalYear: string;

    form: UntypedFormGroup

    faSave = faSave
    faBullseye = faBullseye
    faPencil = faPencilAlt

    main_target = new UntypedFormControl()
    outreach_target = new UntypedFormControl()
    person_target = new UntypedFormControl()
    prescreen_target = new UntypedFormControl()
    recertification_target = new UntypedFormControl()
    barrier_target = new UntypedFormControl()
    collaboration_target = new UntypedFormControl()

    
	constructor(
        private router: Router,
        private authService: AuthService,
        private mainTargetService: MainTargetService,
        private _formBuilder: UntypedFormBuilder,
        private dialog: MatDialog,
        private sessionService: SessionService
    ) 
	{ 
	}

    editDialog(
        agency_uuid: string,
        agency_name: string,
        county_name: string,
        county_id: string,
        main_target: number,
        outreach_target: number,
        person_target: number,
        prescreen_target: number,
        recertification_target: number,
        barrier_target: number,
        collaboration_target: number
    ) { 
        this.dialog.open(
            MainTargetEntityEditComponent, 
            {
                data: {
                    agency_uuid: agency_uuid,
                    agency_name: agency_name,
                    county_name: county_name,
                    county_id: county_id,
                    main_target: main_target,
                    outreach_target: outreach_target,
                    person_target: person_target,
                    prescreen_target: prescreen_target,
                    recertification_target: recertification_target,
                    barrier_target: barrier_target,
                    collaboration_target: collaboration_target
                }
            }
        )
    }

    mainTargets: any
    ngOnInit() {
        this.fiscalYear = this.sessionService.getFiscalYear()
        // console.log(this.fiscalYear)
        this.form = this._formBuilder.group({
            mainTargets: this._formBuilder.array([])
        })

        this.mainTargetService.mainTargetEntity(this.fiscalYear).subscribe((response) => {
            if(response.length) {
                if(response.length > 0) {
                    response.sort((a: any, b: any) => a.agency_name.localeCompare(b.agency_name))

                    this.mtDataSource = new MatTableDataSource(response)
                }
            }
        })
    }

    get mainTargetData(): UntypedFormArray {
        return this.form.get('mainTargets') as UntypedFormArray
    }
}
