import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SubmissionService } from '../submission.service';
import { DashboardService } from '../dashboard.service';
import { UntypedFormControl } from '@angular/forms';
import { SystemService } from '../system.service';
import { Router } from '@angular/router';
import {
    faChevronLeft, faUpload, faSave, faShare, faBan, faFileAlt
} from '@fortawesome/free-solid-svg-icons';
import { SubmissionAttachmentsComponent } from '../submission-attachments/submission-attachments.component';
import { InputDocumentComponent } from '../input-document/input-document.component';
import { ParamService } from '../param.service';
import { shareReplay } from 'rxjs/operators';
import { InputDateFormat } from '../input-date/input-date.component';

@Component({
    selector: 'app-document-submission',
    templateUrl: './document-submission.component.html',
})
export class DocumentSubmissionComponent implements OnInit {
    InputDateFormat = InputDateFormat
    faFileAlt = faFileAlt
    faChevronLeft = faChevronLeft
    faUpload = faUpload
    faSave = faSave
    faShare = faShare
    faBan = faBan
    initialized = false
    submission
    statuses
    statusesById
    validThroughDateFC = new UntypedFormControl()
    documentDescriptionFC = new UntypedFormControl()
    submissionNotesFC = new UntypedFormControl()
    attachmentFC = new UntypedFormControl()
    submissionActivityFeed
    submissionHistory
    showEditFields = false
    @ViewChild(SubmissionAttachmentsComponent) private _attachmentsComponent: SubmissionAttachmentsComponent;
    @ViewChild(InputDocumentComponent) private inputDocumentComponent: InputDocumentComponent;

    constructor(
        private dashboardService: DashboardService,
        private submissionService: SubmissionService,
        private systemService: SystemService,
        private router: Router,
        private paramService: ParamService,
    ) { }

    get attachmentsComponent() {
        return this._attachmentsComponent
    }

    getStatusesPromise() {
        return new Promise((resolve, reject) => {
            this.dashboardService.getStatuses().subscribe((response) => {
                if (response.success) {
                    this.statuses = response.result
                    this.statusesById = {}
                    for (var i = 0; i < this.statuses.length; ++i) {
                        this.statusesById[this.statuses[i].id] = this.statuses[i]
                    }
                    //console.log("statuses", this.statuses)
                } else {
                    console.log("error while getting statuses", response)
                }
                resolve(null)
            })
        })
    }

    getSubmissionPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmission(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.submission = response.result[0]
                    console.log("submission", this.submission)
                    if (this.submission.agency_date) {
                        this.validThroughDateFC.setValue(new Date(this.submission.agency_date).toISOString().slice(0, 10))
                    }
                } else {
                    console.log("error while getting submission", response)
                }
                resolve(null)
            })
        })
    }

    getSubmissionActivityFeedPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmissionActivityFeed(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.submissionActivityFeed = response.result
                    //console.log("feed", this.submissionActivityFeed)
                } else {
                    console.log("error while getting activity feed", response)
                }
                resolve(null)
            })
        })
    }

    getSubmissionHistoryPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmissionHistory(this.submissionUuid).subscribe((response) => {
                if (response.success) {
                    this.submissionHistory = response.result
                    //console.log("history", this.submissionHistory)
                } else {
                    console.log("error while getting history", response)
                }
                resolve(null)
            })
        })
    }

    submissionActivityFeed$
    submissionHistory$
    submission$
    submissionStatuses$
    submissionUuid
    ngOnInit() {
        this.paramService.getParamPromise("submissionUuid").then((paramValue) => {
            this.submissionUuid = paramValue
            var promises = [
                this.getSubmissionPromise(),
                this.getStatusesPromise(),
                this.getSubmissionActivityFeedPromise(),
                this.getSubmissionHistoryPromise(),
            ]
            return Promise.all(promises)
        }).then(() => {
            this.showEditFields = this.submission.status_id == 'NEW' || this.submission.status_id == 'DRAFT' || this.submission.status_id == 'MORE_INFO'
            this.initialized = true
            this.submission$ = this.submissionService.fetchSubmissionHeaders(this.submissionUuid).pipe(shareReplay(1))
            this.submissionStatuses$ = this.dashboardService.getStatusesById()
            this.submissionActivityFeed$ = this.submissionService.fetchFeedAndAppendImagesToDom(this.submissionUuid)
            this.submissionHistory$ = this.submissionService.fetchSubmissionHistory(this.submissionUuid)

            for (var i = 0; i < this.submissionActivityFeed.length; ++i) {
                setTimeout(function (i) {
                    // console.log("arr", "photo_" + this.submissionActivityFeed[i].activity_uuid)
                    // console.log("blargh", document.getElementById("photo_" + this.submissionActivityFeed[i].activity_uuid))
                    this.systemService.loadProfileImage(
                        this.submissionActivityFeed[i].photo_id,
                        "photo_" + this.submissionActivityFeed[i].activity_uuid,
                        this.submissionActivityFeed[i].full_name,
                        this.submissionActivityFeed[i].email,
                    )
                }.bind(this, i), 0)
            }
        })
    }

    attachmentUploadComplete(eventBody) {
        //console.log("attachment upload complete", eventBody)

        var fileInfo = {
            document_description: this.documentDescriptionFC.value,
            file_size: eventBody.file_size,
            last_modified: eventBody.last_modified,
            mime_type: eventBody.mime_type,
            document_uuid: eventBody.uuid,
        }

        this.submissionService.stageSubmissionAttachment(
            eventBody.uuid,
            this.submissionUuid,
            this.documentDescriptionFC.value,
            eventBody.file_name,
            eventBody.mime_type,
            eventBody.file_size,
        ).subscribe((response) => {
            if (!response.success) {
                console.log("error staging file", response)
            }
            this.documentDescriptionFC.setValue(null)
            this.attachmentsComponent.getSubmissionAttachmentsPromise()
        })
    }

    attachmentOriginalFilename
    descriptionError
    preprocess(file) {
        if (!file) {
            this.descriptionError = "Please choose a file to attach."
            return false
        }
        this.attachmentOriginalFilename = file.name
        this.descriptionError = null
        if (!this.documentDescriptionFC.value) {
            this.descriptionError = "Please provide an attachment description."
            return false
        }
        return true
    }

    fileSelect(files) {
        this.descriptionError = null
        //console.log("file select", files)
        if (!this.documentDescriptionFC.value && files && files.length > 0 && files[0].name) {
            var nameWithoutExtension = files[0].name
            var lastDotIndex = files[0].name.lastIndexOf(".")
            if (lastDotIndex >= 0) {
                nameWithoutExtension = files[0].name.substring(0, lastDotIndex)
            }
            this.documentDescriptionFC.setValue(nameWithoutExtension)
        }
    }

    clear() {
        this.descriptionError = null
        this.documentDescriptionFC.setValue(null)
    }

    previous() {
        this.router.navigate(["/dashboard"])
    }

    saveForLater() {
        var sub = {
            uuid: this.submissionUuid,
            valid_through: this.validThroughDateFC.value,
            comment: this.submissionNotesFC.value,
            attachments: this.attachmentsComponent.attachments
        }
        console.log("saving submission", sub)
        this.submissionService.saveSubmission(sub).subscribe((response) => {
            if (response.success) {
                //TODO - snackbar message "submission saved for later"
                this.router.navigate(["/dashboard"])
            } else {
                console.log("error while saving submission", response)
            }
        })
    }

    saveError

    submit() {
        this.saveError = null

        if (!this.validThroughDateFC.value) {
            this.saveError = "Please specify a recertification / valid through date before continuing."
            return
        }

        this.submissionService.submissionHasDocumentsPromise(this.submissionUuid).then((hasDocuments) => {
            if (!hasDocuments) {
                this.saveError = "Please attach one or more documents before continuing."
                return
            }

            var sub = {
                uuid: this.submissionUuid,
                valid_through: this.validThroughDateFC.value,
                comment: this.submissionNotesFC.value,
                attachments: this.attachmentsComponent.attachments
            }
            //console.log("saving submission", sub)
            this.submissionService.saveSubmission(sub).subscribe((response) => {
                if (response.success) {
                    this.router.navigate(["/submission-review"], { queryParams: { submissionUuid: this.submissionUuid } })
                } else {
                    console.log("error while saving submission", response)
                }
            })
        })
    }

    clearForm() {
        this.inputDocumentComponent.clearForm()
    }

    handleFileInput() {
        this.inputDocumentComponent.handleFileInput()
    }
}
