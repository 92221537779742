<div *ngIf="initialized" class="container-fluid">

    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faShoePrints"></fa-icon>
            Program Submission Steps
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>

    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">

            <app-submission-header [submission]="submission$ | async" [statusesById]="submissionStatuses$ | async"
                [hideRecertificationDate]="'true'"></app-submission-header>

            <div class="row py-2">
                <div class="col px-0 text-right" style="margin-right: 275px;">
                    <!--<button mat-raised-button 
                    style="background-color: green; color: white;"
                    routerLink="/agency-main-target">
                    Main Target Report</button> -->
                    <app-button3 
                        class="mx-1" 
                        label="Main Target Report" 
                        [routerLink]="['/agency-main-target']"
                        [queryParams]="{uuid: submissionUuid}" 
                        [isFinal]="true">
                    </app-button3>
                </div>
            </div>

            <div class="row" style="background-color: lightgrey;">
                <div class="col-3">
                    Section
                </div>
                <div class="col-3">
                    Status
                </div>
                <div class="col-3">
                    Modified
                </div>
                <div class="col-3">
                    Modified By
                </div>
            </div>

            <div *ngFor="let questionStep of questionSteps" class="row">
                <div class="col-3 bold">
                    <a *ngIf="orgType == 'PRIME'; else sub_answers" 
                        routerLink="/submission-review"
                        [queryParams]="{ submissionUuid: this.submissionUuid, stepUuid: questionStep.step_id}">
                        {{questionStep.step_name}}
                    </a>

                    <ng-template #sub_answers>
                        <a *ngIf="status == 'Submitted' || status == 'Approved'; else sub_editable"
                        routerLink="/submission-review"
                        [queryParams]="{ submissionUuid: this.submissionUuid, stepUuid: questionStep.step_id}">
                        {{questionStep.step_name}}
                        </a>
                        <ng-template #sub_editable>
                            <a routerLink="/program-submission-answers"
                                [queryParams]="{ submissionUuid: this.submissionUuid, stepUuid: questionStep.step_id}">
                                {{questionStep.step_name}}
                            </a>
                        </ng-template>

                    </ng-template>

                    <!-- <a *ngIf="claims.organization_type == 'PRIME'; else sub_answers" routerLink="/submission-review"
                        [queryParams]="{ submissionUuid: this.submissionUuid, stepUuid: questionStep.step_id }">
                        {{questionStep.step_name}}
                    </a>
                    <ng-template #sub_answers>
                        <a *ngIf="status == 'Submitted' || status == 'Approved'" routerLink="/submission-review"
                            [queryParams]="{ submissionUuid: this.submissionUuid }">
                            {{questionStep.step_name}}
                        </a> 
                        <a routerLink="/program-submission-answers"
                            [queryParams]="{ submissionUuid: this.submissionUuid, stepUuid: questionStep.step_id }">
                            {{questionStep.step_name}}
                        </a> -->
                    <!-- </ng-template> -->
                </div>
                <div class="col-3">
                    <span class="status_badge" [ngClass]="[ 'step_status_' + questionStep.step_status_id ]">
                        {{questionStep.step_status_name | uppercase}}
                    </span>
                </div>
                <div class="col-3">
                    {{questionStep.step_modified | date: 'short'}}
                </div>
                <div class="col-3">
                    {{questionStep.step_modifier}}
                </div>
            </div>

            <app-submission-attachments [submissionUuid]="submissionUuid"></app-submission-attachments>
            
            <!-- add missing attachment controls HSNY-1899 -->
            <app-submission-attachment-controls [submissionUuid]="submissionUuid">
            </app-submission-attachment-controls>

            <app-submission-activity-feed [submissionActivityFeed]="submissionActivityFeed$ | async">
            </app-submission-activity-feed>

            <app-submission-history [submissionHistory]="submissionHistory$ | async"></app-submission-history>

            <div *ngIf="status == 'Draft' || status == 'Revisions Requested' || status == 'New';" class="row">
                <div class="col bold form-check" align="center" style="font-size: 20px;">
                    <input class="form-check-input" type="checkbox" value="" id="mainTargetReviewCheck">
                    <label class="form-check-label bold" style="margin-left: 15px;" for="mainTargetReviewCheck">
                        I certify that all information in this report is accurate, and I have reviewed my Main Target Report prior to submission.
                    </label>
                </div>
            </div>
            <div *ngIf="claims.organization_type == 'PRIME' && (status == 'Submitted') ||
                        claims.organization_type == 'SUB' && (status == 'Draft' || status == 'Revisions Requested' || status == 'New');"
                        class="row mt-3">
                <div class="col" align="center">
                    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                    </app-button3>
                    <app-button3 class="mx-1" label="Review and Submit" [clickFunction]="submit.bind(this)" [icon]="faSave"
                        [disabled]="!allStepsDone" [isFinal]="true">
                    </app-button3>
                </div>
            </div>
            <div *ngIf="claims.organization_type == 'SUB' && (status == 'Approved' || status == 'Submitted');"
                  class="col" align="center">
                <app-button3 class="mx-1" label="OK" routerLink="/dashboard">
                </app-button3>
            </div>
        </div>
    </div>
</div>