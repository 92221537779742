<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faLayerGroup"></fa-icon>
            <span *ngIf="groupId == null">
                New
            </span>
            <span *ngIf="groupId != null">
                Edit
            </span>
            Group
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <app-input-text label="Step" [fc]="stepNameFC" disabled="true">
            </app-input-text>
            <app-input-text label="Group #" [fc]="groupNumberFC" [maxlength]="6">
            </app-input-text>
            <app-input-text label="Name" [fc]="nameFC" [maxlength]="100">
            </app-input-text>
            <app-input-text-area label="Long Name" [fc]="longNameFC" [maxlength]="255">
            </app-input-text-area>

            <div class="row edit-height">
                <div class="col-10 unedit-padding">
                    <span class="bold">Subtotal Group</span> - This will subtotal ALL numeric
                    questions within the group and display the value in the group footer.
                </div>
                <div class="col-2">
                    <app-checkbox [fc]="subtotalGroupFC"></app-checkbox>
                </div>
            </div>

            <div class="row edit-height">
                <div class="col-10 unedit-padding">
                    <span class="bold">Allow Additional</span> - This will allow the user to
                    add the group again, as many times as necessary.
                </div>
                <div class="col-2">
                    <app-checkbox [fc]="allowAdditionalFC" [change]="allowAdditionalChange.bind(this)"></app-checkbox>
                </div>
            </div>

            
            <div class="row edit-height">
                <div class="col-10 unedit-padding" *ngIf="this.subtotalAdditionalFC.disabled;else show_normal " style="color: lightgrey;">
                    <span class="bold">Subtotal Additional</span> - This will subtotal EACH
                    numeric question within the group and display the value in the group footer.
                </div>
                <ng-template #show_normal>
                    <div class="col-10 unedit-padding">
                        <span class="bold">Subtotal Additional</span> - This will subtotal EACH
                        numeric question within the group and display the value in the group footer.
                    </div>
                </ng-template>
                <div class="col-2">
                    <app-checkbox [fc]="subtotalAdditionalFC"></app-checkbox>
                </div>
            </div>

            <div *ngIf="error" class="row">
                <div class="col" align="center" style="color: red;">
                    {{error}}
                </div>
            </div>

            <div class="row mt-3">
                <div class="col" align="center">
                    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                    </app-button3>
                    <app-button3 class="mx-1" label="Save" [clickFunction]="save.bind(this)" [icon]="faSave"
                        [isFinal]="true">
                    </app-button3>
                </div>
            </div>
        </div>
    </div>
</div>