<div class="container-fluid" style="margin-top: 15px; margin-bottom: 15px;">
    <div class="row">
        <div class="col voucher-lines-title">
            <fa-icon [icon]="faFileInvoiceDollar"></fa-icon>
            Voucher Lines
        </div>
        <div class="row">
            <div class="col add-line-button" align="right" *ngIf="status != 'SUBMITTED' && status != 'APPROVED' && showFlag != true && canEdit == true">
                <button (click)="addLine()" mat-raised-button style="background-color: green; color: white;">
                    <fa-icon [icon]="faPlus"></fa-icon>&nbsp;Add Line
                </button>
            </div>
            <div class="col" align="right" *ngIf="accountType == 'NOEP_OUTREACH' && (status == 'NEW' || status == 'DRAFT' || status == 'MORE_INFO')">
                <button (click)="addOutreach(submissionUuid, accountUuid)" mat-raised-button style="background-color: green; color: white;">
                    <fa-icon [icon]="faPlus"></fa-icon>&nbsp;Add Outreach
                </button>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 table-container">
        <table mat-table [dataSource]="vlDataSource" class="mat-table">
            <!-- Information Column -->
            <ng-container matColumnDef="information">
                <th mat-header-cell *matHeaderCellDef> Information </th>
                <td mat-cell [innerHTML]="row.information" *matCellDef="let row"></td>
            </ng-container>
    
            <!-- State Only Column -->
            <ng-container matColumnDef="state_only">
                <th mat-header-cell *matHeaderCellDef> State Only </th>
                <td mat-cell *matCellDef="let row"> {{ row.state_only | currency }} </td>
            </ng-container>
    
            <!-- State/Fed Match Column -->
            <ng-container matColumnDef="state_federal">
                <th mat-header-cell *matHeaderCellDef> State/Fed Match </th>
                <td mat-cell *matCellDef="let row"> {{ row.state_federal | currency }} </td>
            </ng-container>
    
            <!-- Amount Charged Column -->
            <ng-container matColumnDef="charged_amount">
                <th mat-header-cell *matHeaderCellDef> Amount Charged </th>
                <td mat-cell *matCellDef="let row"> {{ row.charged_amount | currency }} </td>
            </ng-container>
    
            <!-- Check/ACH Info Column -->
            <ng-container matColumnDef="check_info">
                <th mat-header-cell *matHeaderCellDef> Check/ACH Info </th>
                <td mat-cell *matCellDef="let row"> {{ row.check_info || "#" }} </td>
            </ng-container>

            <!-- Check/ACH amount Column -->
            <ng-container matColumnDef="check_amount">
                <th mat-header-cell *matHeaderCellDef> Check/ACH Amount </th>
                <td mat-cell *matCellDef="let row"> {{ row.check_amount }} </td>
            </ng-container>
    
            <!-- Flag Comment Column -->
            <ng-container matColumnDef="flag_comment">
                <th mat-header-cell *matHeaderCellDef> Flag Comment </th>
                <td mat-cell *matCellDef="let row"> {{ row.flag_comment }} </td>
            </ng-container>
    
            <!-- Edit Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button color="primary" 
                    *ngIf="status != 'SUBMITTED' && status != 'APPROVED' && showFlag != true && canEdit == true"
                    (click)="editLine(
                        row.uuid,
                        row.description,
                        row.name,
                        row.period,
                        row.quantity_est,
                        row.quantity,
                        row.unit_price,
                        row.invoice_number,
                        row.premium,
                        row.dates,
                        row.line_date,
                        row.state_only, 
                        row.state_federal,
                        row.other_amount,
                        row.line_reference_number,
                        row.line_reference_date,
                        row.check_amount,
                        row.metadata,
                        row.method,
                        row.line_type,
                        row.line_number,
                        row.percent
                        )">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="accent" *ngIf="claims.organization_type == 'PRIME' && status != 'APPROVED'"
                    (click)="flagComment(row.uuid, row.flag_comment)">
                        <mat-icon>flag</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <!-- Header Row -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    
            <!-- Data Rows -->
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>