<h4 mat-dialog-title>Confirm Void</h4>
<div mat-dialog-content>
    <p>Voiding this submission will remove it from your dashboard permanently. Please confirm you want this submission voided.</p>
    <div class="void-checkbox">
        <mat-checkbox [(ngModel)]="voidCheck" color="primary">Yes, please void</mat-checkbox>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="closeVoidDialog()">Cancel</button>
    <button mat-raised-button color="warn" (click)="voidDialog()" [disabled]="!voidCheck">OK, Void</button>
</div>