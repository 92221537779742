<table mat-table [dataSource]="adminDocuments" class="mat-elevation-z8">

  <!-- Document Column -->
  <ng-container matColumnDef="submissionName">
    <th mat-header-cell *matHeaderCellDef> Document </th>
    <td mat-cell *matCellDef="let document"> {{ document.submissionName }} </td>
  </ng-container>

  <!-- Org Name Column -->
  <ng-container matColumnDef="orgName">
    <th mat-header-cell *matHeaderCellDef> Org. Name </th>
    <td mat-cell *matCellDef="let document"> {{ document.orgName }} </td>
  </ng-container>

  <!-- Days Until Due Column -->
  <ng-container matColumnDef="daysUntilDue">
    <th mat-header-cell *matHeaderCellDef> Days Until Due </th>
    <td mat-cell *matCellDef="let document"> {{ document.daysUntilDue }} </td>
  </ng-container>

  <!-- User Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> User Email </th>
    <td mat-cell *matCellDef="let document"> {{ document.email }} </td>
  </ng-container>

  <!-- Due Date Column -->
  <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef> Due Date </th>
      <td mat-cell *matCellDef="let document"> {{ document.dueDate | date:'MM/dd/yyyy' }} </td>
    </ng-container>

  <!-- Button Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef> Actions </th>
    <td mat-cell *matCellDef="let document">
      <button mat-raised-button color="primary" (click)="onSendEmail(document)">Send Email</button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="['submissionName', 'orgName', 'daysUntilDue', 'email', 'dueDate', 'actions']"></tr>
  <tr mat-row *matRowDef="let row; columns: ['submissionName', 'orgName', 'daysUntilDue', 'email', 'dueDate', 'actions'];"></tr>
</table>