import { Component, Input, OnInit } from '@angular/core';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
})
export class InputNumberComponent implements OnInit {

    @Input() label;
    @Input() fc;
    @Input() uneditable;
    @Input() change;
    @Input() keyupEnter;
    @Input() requiredMarker;
    @Input() commentId
    @Input() commentData
    @Input() flagClick
    @Input() showFlag
    @Input() submissionUuid
    @Input() targetUuid
    @Input() maxlength
    @Input() mantissalength
    faFlag = faFlag

    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    attributeIdentifier = this.randomString();
    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    constructor() {
    }

    ngOnInit() {
    }

    doChange(e) {
        if (this.change) {
            this.change(e);
        }
    }

    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }

    round() {
        if (this.fc.value) {
            if (typeof this.fc.value == "string") {
                this.fc.setValue(parseFloat(this.fc.value))
            }
            this.fc.setValue(this.fc.value.toFixed(this.mantissalength))
        }
    }
}
