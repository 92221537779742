import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, switchMap, map, tap, startWith } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { CrudService } from '../crud.service';
import { SubmissionService } from '../submission.service';
import { FilterComponent } from './filter/filter.component';
import { FiscalYearService } from '../services/fiscal-year.service';
import { SessionService } from '../session.service';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
    @ViewChildren(FilterComponent) filters: QueryList<FilterComponent>;
    faSearch = faSearch

    userOrganizationType
    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                //console.log("org type", response)
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    reloadPage() {
        location.reload()
    }

    closeAllFilters() {
        this.filters.forEach(filter => {
            filter.closeFilterInput()
        });
    }

    entriesPerPageOptions = [
        //{ id: 3, name: "3" },//for testing
        // { id: 10, name: "10" },
        { id: 25, name: "25" },
        { id: 50, name: "50" },
        { id: 100, name: "100" },
    ]


    logicOptions = [
        { id: "false", name: "Match All" },
        { id: "true", name: "Match Any" },
    ]

    agencies$ = this.submissionService.searchAgencies().pipe(
        tap((res) => {
            //console.log("TAP RES agencies", res)
        }));
    counties$ = this.crudService.search({ type: "sys_jurisdiction" });
    programs$ = this.crudService.search({ type: "sys_program" });
    statuses$ = this.crudService.search({ type: "sys_status", where: "display >= 0" });
    submissions$ = this.crudService.search({ type: "sys_submission_type" });

    columns = [
        {
            Field: "organization_name",
            searchField: "organization_uuid",
            name: "Agency",
            values: this.agencies$,
        },
        {
            Field: "program_name",
            searchField: "program_id",
            name: "Program",
            values: this.programs$,
        },
        {
            Field: "submission_type_name",
            searchField: "submission_type_id",
            name: "Submission Type",
            values: this.submissions$,
        },
        {
            Field: "jurisdiction_name",
            searchField: "jurisdiction_id",
            name: "County",
            values: this.counties$,
        },
        {
            Field: "name",
            name: "Submission Name",
        },
        {
            Type: "int",
            Field: "reference_number", // was sequence
            name: "#",
        },
        {
            Type: "datetime",
            Field: "due_date",
            name: "Due Date",
        },
        {
            Field: "status_name",
            searchField: "status_id",
            name: "Status",
            values: this.statuses$,
        },
        {
            Field: "agency_date",
            name: "Agency Date",
        },
        {
            Type: "int",
            Field: "total",
            name: "Total",
        },
    ]
    columnLogicFC = new UntypedFormControl(false)
    keywordSearchFC = new UntypedFormControl()
    entriesPerPageFC = new UntypedFormControl(25)
    filterHandlers$ = new BehaviorSubject<any>({});
    paginationHandlers$ = new BehaviorSubject<any>({});
    sortHandlers$ = new BehaviorSubject<any>({});

    handleFilterEvent(event) {
        //console.log("handling filter event", event)
        this.filterHandlers$.next({})
    }

    filterValue$ = combineLatest(
        this.filterHandlers$,
        this.columnLogicFC.valueChanges.pipe(startWith("false"))
    ).pipe(
        switchMap(() => {
            if (!this.filters) {
                return combineLatest(
                    this.authService.getUserOrganizationType(),
                    this.authService.getUserOrganizations(),
                );
            }
            return combineLatest(
                this.authService.getUserOrganizationType(),
                this.authService.getUserOrganizations(),
                ...this.filters.map((entry) => {
                    return entry.filterChange;
                })
            );
        }),
        debounceTime(50),
        switchMap(([orgTypeResponse, orgsResponse, ...filters]) => {
            var orgType = orgTypeResponse.result;
            
            var allFilters = "";
            var allArgs = [];
            
            for (var i = 0; i < filters.length; ++i) {
                if (typeof filters[i] === 'object') {
                    // Handle object type
                    const filterObj = filters[i] as { where: string; whereArgs: any[]; };
                    if (filterObj.where) {
                        if (allFilters) {
                            if (this.columnLogicFC.value == "true") {
                                allFilters += " or ";
                            } else {
                                allFilters += " and ";
                            }
                        }
                        allFilters += filterObj.where;
                        allArgs.push(...filterObj.whereArgs);
                    }
                } else if (typeof filters[i] === 'string') {
                    // Handle string type
                    if (allFilters) {
                        if (this.columnLogicFC.value == "true") {
                            allFilters += " or ";
                        } else {
                            allFilters += " and ";
                        }
                    }
                    allFilters += filters[i];
                }
            }
            
            if (orgType == "SUB") {
                if (allFilters) {
                    allFilters = "(" + allFilters;
                    allFilters += " ) and ";
                }
                for (var i = 0; i < orgsResponse.result.length; ++i) {
                    allFilters += "organization_uuid = ?";
                    allArgs.push(orgsResponse.result[i].uuid);
                }
            }
            
            return of({ where: allFilters, whereArgs: allArgs });
        })
    );
    

    searchResults$ =
        combineLatest(
            this.filterValue$,
            this.sortHandlers$,
            this.paginationHandlers$,
            this.keywordSearchFC.valueChanges.pipe(startWith(null)),
        ).pipe(switchMap(([filter, sort, paging]) => {
            var sortVal = null
            if (sort && sort.columnName) {
                sortVal = [{ columnName: sort.columnName, isDescending: sort.isDescending }]
            }

            const criteria: {
                sort: any;
                type: string;
                limit: any;
                offset: number;
                keyword: any;
                where: string;
                whereArgs: string[];
            } = {
                sort: sortVal,
                type: "vw_submission_search",
                limit: this.entriesPerPageFC.value,
                offset: (this.currentPage - 1) * this.entriesPerPageFC.value,
                keyword: this.keywordSearchFC.value,
                where: filter.where,
                whereArgs: filter.whereArgs
            };

            if(criteria.where == "") {
                criteria.where = `fiscal_year_id = ${this.sessionService.getFiscalYear()}`
            } else {
                criteria.where += ` and fiscal_year_id = ${this.sessionService.getFiscalYear()}`
                // console.log(criteria.where)
            }
            // console.log(criteria)
            return this.crudService.search(criteria);
        }), tap((response) => {
            // console.log("count?", response.count)
            this.count = response.count
        }), map((response) => {
            // console.log(response.sql)

            for (var i = 0; i < response.result.length; ++i) {
                switch (response.result[i].submission_type_id) {
                    case "NOEP_PROGRAM":
                        response.result[i].editRoute = "/submission-question-steps"
                        break
                    case "MASTER_DOCUMENT":
                        response.result[i].editRoute = "/submission"
                        break
                    case "NOEP_OUTREACH":
                        response.result[i].editRoute = "/outreach-campaign-request"
                        break
                    case "NOEP_BUDGET":
                        response.result[i].editRoute = "/budget-summary"
                        break
                }
            }
            return response
        }))

    constructor(
        private submissionService: SubmissionService,
        private crudService: CrudService,
        private authService: AuthService,
        private fiscalYearService: FiscalYearService,
        private sessionService: SessionService
    ) { }

    fiscalYearOptions: any;
    selectedYear: string;
    getFiscalYearDropDown() {
        this.fiscalYearService.getFiscalYearOptions().subscribe((response) => {
            this.fiscalYearOptions = response.result;

            this.fiscalYearOptions.forEach((option: any) => {
                if(option.id == 'NONE') {
                    this.fiscalYearOptions.splice(this.fiscalYearOptions.indexOf(option), 1);
                }
            })

            if(this.sessionService.getFiscalYear()) {
                this.selectedYear = this.sessionService.getFiscalYear()
            } else {
                this.selectedYear = this.fiscalYearOptions[0].id;
            }
        })
    }

    fiscalYearChange() {
        this.sessionService.setFiscalYear(this.selectedYear)
        this.selectedYear = this.sessionService.getFiscalYear()
        window.location.reload()
    }

    fiscalYearId: string
    ngOnInit() {
        this.getFiscalYearDropDown();

        
        console.log(this.selectedYear)
        this.fiscalYearId = this.sessionService.getFiscalYear()
        console.log(this.fiscalYearId)

        //setup alignment by type
        for (var i = 0; i < this.columns.length; ++i) {
            if (this.columns[i].Type
                && (this.columns[i].Type == 'varchar(36)'
                    || this.columns[i].Type.startsWith('decimal')
                    || this.columns[i].Type.startsWith('int')
                    || this.columns[i].Type.startsWith('tinyint'))) {
                (<any>this.columns[i]).align = "right"
            }
        }


        this.getUserOrganizationType()
    }

    currentPage = 1
    count

    setPage(page) {
        this.currentPage = page
        this.paginationHandlers$.next(page)
    }

    entriesPerPageChange() {
        this.setPage(1)
    }
    sortField
    sortIsDescending

    sortClick(sortField) {
        if (this.sortField == sortField) {
            this.sortIsDescending = !this.sortIsDescending
        } else {
            this.sortField = sortField
            this.sortIsDescending = false
        }
        this.sortHandlers$.next({ columnName: this.sortField, isDescending: this.sortIsDescending })
        //this.fetchData()
    }
}
