import { Component, OnInit, Input, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export enum InputDateFormat {
    verticalLabel,
    horizontalLabel,
    none,
}

@Component({
    selector: 'app-input-date',
    templateUrl: './input-date.component.html',
})
export class InputDateComponent implements OnInit {
    InputDateFormat = InputDateFormat
    @Input() label;
    @Input() format: InputDateFormat;
    @Input() tooltip;
    @Input() fc;
    @Input() change;
    @Input() disabled;
    @Input() disabledFormat;
    faInfoCircle = faInfoCircle

    constructor(
    ) {
    }

    ngOnInit() {
        if (!this.disabledFormat) {
            this.disabledFormat = 'M/d/y'
        }
    }

    doChange() {
        if (this.change) {
            //console.log("calling change");
            this.change();
        }
    }
}
