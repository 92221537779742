import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-input-text-area',
    templateUrl: './input-text-area.component.html',
})
export class InputTextAreaComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() style;
    @Input() rows;
    @Input() rowClass;
    @Input() maxlength;
    calcRowClass

    defaultRows = 4;
    rowCount

    constructor() { }

    ngOnInit() {
        this.rowCount = this.defaultRows;
        if (this.rows) {
            this.rowCount = this.rows;
        }
        this.calcRowClass = {};
        this.calcRowClass[this.rowClass] = true;
    }

    doChange() {
        if (this.change) {
            this.change();
        }
    }
}
