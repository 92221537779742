import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class DocumentMasterService {

    constructor(
        private http: HttpClient,
    ) {
    }

    getDocumentMasterListing(programId?): Observable<any> {
        return this.http.get<any>("api/getDocumentMasterListing/" + (programId ? programId : ""))
    }

    getDocumentMasterListing2(programId?): Observable<any> {
        return this.http.get<any>("api/getDocumentMasterListing2/" + (programId ? programId : ""))
    }

    getDocument(documentUuid): Observable<any> {
        return this.http.get<any>("api/document/" + documentUuid)
    }

    getSubmissionTypes(): Observable<any> {
        return this.http.get<any>("api/submissionTypes")
    }

    getDocumentOrganizationTargets(): Observable<any> {
        return this.http.get<any>("api/documentOrganizationTargets")
    }

    getFrequencies(): Observable<any> {
        return this.http.get<any>("api/frequencies")
    }

    getAgencyDates(): Observable<any> {
        return this.http.get<any>("api/agencyDates")
    }

    addDocument(
        name,
        organization_type_id,
        required,
        instructions,
        reoccur,
        interval_number,
        frequency_id,
        days_in_advance,
        agency_date_id,
        reoccur_start_date,
        applies_to,
    ): Observable<any> {
        return this.http.put<any>("api/document", {
            name,
            organization_type_id,
            required,
            instructions,
            reoccur,
            interval_number,
            frequency_id,
            days_in_advance,
            agency_date_id,
            reoccur_start_date,
            applies_to,
        }, httpOptions)
    }

    updateDocument(
        uuid,
        name,
        organization_type_id,
        required,
        instructions,
        reoccur,
        interval_number,
        frequency_id,
        days_in_advance,
        agency_date_id,
        reoccur_start_date,
        applies_to,
    ): Observable<any> {
        return this.http.post<any>("api/document", {
            uuid,
            name,
            organization_type_id,
            required,
            instructions,
            reoccur,
            interval_number,
            frequency_id,
            days_in_advance,
            agency_date_id,
            reoccur_start_date,
            applies_to,
        }, httpOptions)
    }
}
