import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { iAdminDocument } from '../model/admin-document.model';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  sendEmail(emailData: any): Observable<any> {
    // console.log(emailData)
    return this.http.post<any>('api/sendEmail', emailData);
  }

  getDocsInfo(): Observable<any> {
    return this.http.get<iAdminDocument>('api/getDocsInfo')
  }
}
