import { Component, Input } from '@angular/core';
import { SessionService } from '../session.service';

@Component({
    selector: 'app-submission-header',
    templateUrl: './submission-header.component.html',
})
export class SubmissionHeaderComponent {
    @Input() submission: any
    @Input() statusesById
    @Input() hideRecertificationDate
    @Input() showReferenceNumber
    @Input() submissionUuid: string

    claims: any

    constructor(
        private sessionService: SessionService
    ) { }

    ngOnInit() {
        this.claims = this.sessionService.getClaims()
        console.log(this.claims)
        console.log(this.submissionUuid)
        console.log(this.submission)
        console.log(this.statusesById)
    }
}
