<div class="container-fluid" *ngIf="data">
    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            Payment Receipt
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <div class="row py-2">
                <div class="col-3 bold">
                    Agency: 
                </div>
                <div class="col-9">
                    {{ data.organization_name }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Address:
                </div>
                <div class="col-9">
                    {{ data.address }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Voucher Period:
                </div>
                <div class="col-9">
                    {{ data.voucher_period }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Voucher Number:
                </div>
                <div class="col-9">
                    {{ data.voucher_number }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Voucher Amount:
                </div>
                <div class="col-9">
                    {{ data.payment_amount | currency }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Advance Recoup:
                </div>
                <div class="col-9">
                    {{ data.advance_recoup | currency }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Final Payment:
                </div>
                <div class="col-9">
                    {{ data.final_payment | currency }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Method:
                </div>
                <div class="col-9">
                    {{ data.payment_method }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Date Paid:
                </div>
                <div class="col-9">
                    {{ data.pay_date | date: 'M/d/yyyy' }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Reference Number:
                </div>
                <div class="col-9">
                    {{ data.payment_number }}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-3 bold">
                    Notes:
                </div>
                <div class="col-9">
                    {{ data.payment_notes }}
                </div>
            </div>
        </div>
    </div>
</div>
