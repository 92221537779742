import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(
        private http: HttpClient,
    ) { }

    getDashboard(sortField, sortDirection, programFilter, submissionTypeFilter, fiscalYearId, sortField2, sortDirection2): Observable<any> {
        return this.http.get<any>("api/dashboard/" + 
            sortField + "/" + 
            sortDirection + "/" + 
            programFilter + "/" + 
            submissionTypeFilter + "/" +
            fiscalYearId + "/" + 
            sortField2 + "/" + 
            sortDirection2
        )
    }

    getStatuses(): Observable<any> {
        return this.http.get<any>("api/statuses")
    }

    getStatusesById() {
        return this.getStatuses().pipe(
            map((response: any) => {
                if (response.success) {
                    const statuses = response.result;
                    const statusesById = {};
                    for (var i = 0; i < statuses.length; ++i) {
                        statusesById[statuses[i].id] = statuses[i];
                    }
                    return statusesById;
                } else {
                    console.log("error while getting statuses", response);
                }
            })
        );
    }
}
