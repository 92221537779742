import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpRequest, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { SystemService } from '../system.service';
import { faUpload, faBan, faImage } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-input-document',
  templateUrl: './input-document.component.html',
  styleUrls: ['./input-document.component.css']
})
export class InputDocumentComponent implements OnInit {
    faUpload = faUpload;
    faBan = faBan;
    faImage = faImage

    @Input() label;
    @Input() icon;
    @Input() fc;
    @Input() change;
    @Input() disabled;
    @Input() style;
    @Input() unsetIcon;
    @Input() preprocess;
    @Input() onFileSelect;
    @Input() clear;
    initialized = false;
    currentSingleUploadFile = null;
    fileFormControl = new UntypedFormControl();
    uploadClicked = false;
    percentDone = null;
    uploadStatus = null;
    error = null;
    none;

    constructor(
        private http: HttpClient,
        private systemService: SystemService,
    ) { }

    ngOnInit() {
        this.initialized = true;
    }

    getUnsetIcon() {
        if (this.unsetIcon) {
            return this.unsetIcon;
        }
        return this.faImage;
    }

    getImageHeight(width, height, newWidth) {
        var ratio = width / height;
        return newWidth / ratio;
    }

    doChange(responseBody) {
        if (this.change) {
            this.change(responseBody);
        }
    }

    handleFileInput() {
        var files = (<HTMLInputElement>document.getElementById('file')).files
        this.currentSingleUploadFile = files[0];
        if (this.preprocess) {
            if (!this.preprocess(this.currentSingleUploadFile)) {
                return;
            }
        }
        this.upload();
    }

    upload() {
        if (this.uploadClicked) {
            this.error = "Upload has already been clicked.";
            return;
        }

        if (this.currentSingleUploadFile == null) {
            this.error = "Please select a file to attach."
            return;
        }

        this.uploadStatus = "Uploading";

        this.uploadClicked = true;
        const status: { [key: string]: { progress: Observable<number> } } = {};

        // create a new multipart-form for every file
        const formData: FormData = new FormData();
        formData.append('fileKey', this.currentSingleUploadFile, this.currentSingleUploadFile.name);
        formData.append("file_name", this.currentSingleUploadFile.name);

        var url = "api/uploadDocumentFile";
        const req = new HttpRequest('PUT', url, formData, {
            reportProgress: true
        });

        const progress = new BehaviorSubject<number>(0);
        this.percentDone = 0;
        this.http.request(req).subscribe(event => {
            //console.log("event", event);
            if (event.type === HttpEventType.UploadProgress) {

                this.percentDone = Math.round(100 * event.loaded / event.total);
                if (this.percentDone == 100) {
                    //this.uploadStatus = "Processing";
                    this.uploadStatus = null
                }
                //console.log("progress report", this.percentDone);
                progress.next(this.percentDone);
            } else if (event instanceof HttpResponse) {
                this.error = null;
                progress.complete();
                this.percentDone = null;
                //console.log(event);
                this.uploadClicked = false;
                if ((<any>event.body).success) {
                    this.fc.setValue((<any>event.body).uuid);
                    //console.log("debug file info", event)
                    this.doChange(event.body);
                } else {
                    this.error = "File could not be uploaded: " + (<any>event.body).message;
                }
                this.clearForm();
            }
        });

        // Save every progress-observable in a map of all observables
        status[this.currentSingleUploadFile.name] = {
            progress: progress.asObservable()
        };

        // return the map of progress.observables
        progress.asObservable().subscribe((result) => {
            console.log("progress:", result);
        })
        return status;
    }

    clearForm() {
        this.fileFormControl.setValue(null);
        this.currentSingleUploadFile = null;
        if (this.clear) {
            this.clear()
        }
    }

    fileSelect(files) {
        if (this.onFileSelect) {
            this.onFileSelect(files)
        }
    }

}
