import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faTicketAlt, faPrint } from '@fortawesome/free-solid-svg-icons';
import { BudgetService } from '../budget.service';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.css']
})
export class VoucherDetailComponent implements OnInit {
  faTicketAlt = faTicketAlt
  faPrint = faPrint

  submissionUuid: string
  fiscalYearId: string
  showPrintButton: boolean = true

  constructor(        
    private activatedRoute: ActivatedRoute,
    private budgetService: BudgetService
  ) { }

  // there is likely a better way to handle this
  // preferably something that is more human readable, usage of switchMap is not required.
  entries$ = this.activatedRoute.queryParams.pipe(
    switchMap((params) => {
        this.submissionUuid = params["submissionUuid"]
        this.fiscalYearId = params['fiscalYearId']
        return this.budgetService.getVoucherSummary(this.submissionUuid, this.fiscalYearId)
    }),
    tap((summaryResponse: any) => {
        console.log("voucher summary", summaryResponse)
    })
  )

  print() {
    this.showPrintButton = false
    setTimeout(() => { window.print() }, 0);
  }

  ngOnInit() {
  }

}
