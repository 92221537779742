export class MainTargetReportView {
    id: string
    rowId: string
    description: string
    q0: number
    q1: number
    q2: number
    q3: number
    q4: number

    constructor(
        uuid: string,
        rowId: string,
        description: string
    ) {
        this.id = uuid
        this.rowId = rowId
        this.description = description
        this.q0 = 0
        this.q1 = 0
        this.q2 = 0
        this.q3 = 0
        this.q4 = 0
    }
}