<span *ngIf="submissionType == 'Budget'">
    <app-button3 
        *ngIf="amendFlag == 'Y'"
        label="Create Amendment"
        [icon]="faEdit"
        [small]="true"
        (click)="confirmCreateDialog()">
    </app-button3>
</span>

<span *ngIf="submissionType == 'Voucher' && status == 'NEW'">
    <app-button3 
        *ngIf="amendFlag == 'Y'"
        label="Start"
        [small]="true"
        (click)="confirmCreateDialog()">
    </app-button3>
</span>
    
<!-- <app-button3 *ngIf="item.amend_flag == 'Y'" label="Create Amendment"
    [clickFunction]="amendBudget.bind(this, item.submission_uuid)" [icon]="faEdit"
    [small]="true">
</app-button3> -->