import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteElements } from './confirm-delete-elements.component';
import { faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.css']
})
export class ConfirmDeleteDialogComponent implements OnInit {
  @Input() groupData: any
  @Input() dialogMessage: string

  faMinus = faMinus

  answerGroup: string
  addSequence: number
  
  constructor(public dialog: MatDialog) { }

  openDialog() {
    this.dialog.open(ConfirmDeleteElements, {
      data: {
        dialogMessage: this.dialogMessage,
        answerGroup: this.answerGroup,
        addSequence: this.addSequence
      }
    })
  }

  ngOnInit() {
    console.log(this.groupData)
    let child = this.groupData.children
    let c = child[child.length - 1]

    this.addSequence = c.additional_answer_sequence_number
    this.answerGroup = c.answer_group_uuid
  }

}
