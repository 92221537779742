<p-table #dt [styleClass]="'p-datatable-gridlines'" [columns]="trendReportColumns" [value]="trendReportQtr">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{ col.header }}
            </th>
            <th>$ Spent YTD</th>
            <th>% Spent YTD</th>
            <th>Remaining</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns" [ngClass]="{'text-right': isNumber(rowData[col.field])}">
                {{ isNumber(rowData[col.field]) ? ((rowData[col.field] | number:'1.0-0')) : rowData[col.field] }}
            </td>
            <td class="text-right">{{ calculateTotalSpent(rowData) | number:'1.0-0' }}</td>
            <td class="text-right">{{ calculatePercentageSpent(rowData) | number:'1.0-0' }} %</td>
            <td class="text-right text-bold">{{ calculateRemainingBudget(rowData) | number:'1.0-0' }}</td>
        </tr>
    </ng-template>

    <ng-template pTemplate="footer">
        <tr class="italic-text">
            <td>Total (State Only)</td>
            <td></td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q1 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q2 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q3 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q4 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ stateOnlyBudget | number:'1.0-0'  }}</td>
            <td class="text-right">$ {{ stateOnlyTotalSpent | number:'1.0-0'  }}</td>
            <td class="text-right">{{ stateOnlyTotalPercent | number:'1.0-0'  }} %</td>
            <td class="text-right">$ {{ stateOnlyRemainingBudget | number:'1.0-0'  }}</td>
        </tr>
    
        <tr class="italic-text">
            <td>Total (State/Fed)</td>
            <td></td>
            <td class="text-right">$ {{ quarterlyTotals.stateFed.q1 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateFed.q2 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateFed.q3 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateFed.q4 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ stateFedBudget | number:'1.0-0'  }}</td>
            <td class="text-right">$ {{ stateFedTotalSpent | number:'1.0-0'  }}</td>
            <td class="text-right">{{ stateFedTotalPercent | number:'1.0-0'  }} %</td>
            <td class="text-right">$ {{ stateFedRemainingBudget | number:'1.0-0'  }}</td>
        </tr>
        <tr>
            <td>Total</td>
            <td></td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q1 + quarterlyTotals.stateFed.q1 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q2 + quarterlyTotals.stateFed.q2 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q3 + quarterlyTotals.stateFed.q3 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ quarterlyTotals.stateOnly.q4 + quarterlyTotals.stateFed.q4 | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ (stateOnlyBudget + stateFedBudget) | number:'1.0-0' }}</td>
            <td class="text-right">$ {{ (stateOnlyTotalSpent + stateFedTotalSpent) | number:'1.0-0' }}</td>
            <td class="text-right">{{ totalPercent | number:'1.0-0' }} %</td>
            <td class="text-right">$ {{ (stateOnlyRemainingBudget + stateFedRemainingBudget) | number:'1.0-0' }}</td>
        </tr>
    </ng-template>
</p-table>
