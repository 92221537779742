<div class="container-fluid">
    <div class="row page-title mb-2">
        <div class="col">
            <fa-icon [icon]="faWrench"></fa-icon>
            Utility Report
        </div>
    </div>

    <app-input-enum label="Report" [fc]="reportFC" [enumValuesNameAndId]="reports$ | async"
        includeUnsetDisplay="Select a report">
    </app-input-enum>

    <span *ngIf="entries$ | async as entries">
        <!-- <div class="row">
                <div class="col-6">
                    <h2 style="display: inline-block;">{{typeFC.value | dbtitlecase | titlecase}}
                    </h2>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col">
                            Entries Per Page&nbsp;
                            <app-input-enum label="Entries Per Page" [fc]="entriesPerPageFC" [style]="'none'"
                                [class]="'inline-block'" [enumValuesNameAndId]="entriesPerPageOptions">
                            </app-input-enum>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <app-button3 *ngIf="showNewButton()" style="float: right;" class="mx-1" label="New"
                        [clickFunction]="new.bind(this, entries.definition)" [icon]="faPlus" [small]="true">
                    </app-button3>
                </div>
            </div>
    
            <app-page-controls [pageNumber]="entries.currentPage" [pageSize]="entries.entriesPerPage"
                [entryCount]="entries.count" [setPage]="this.setPage.bind(this)">
            </app-page-controls> -->

        <div class="row mt-3">
            <div class="col px-0">
                <table class="table table-striped table-hover" style="min-width: 100%">
                    <thead>
                        <tr style="background-color: lightgrey">
                            <th *ngFor="let column of entries.definition; let index = index">
                                <span class="sort-column-header"
                                    (click)="sortClick(entries.sortField, column.Field, entries.sortIsDescending)">
                                    {{column.Field | dbtitlecase | titlecase}}
                                </span>
                                <app-sort-indicator class="ml-1" [sortFieldName]="entries.sortField"
                                    [sortIsDescending]="entries.sortIsDescending" [columnFieldName]="column.Field">
                                </app-sort-indicator>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="entries.result && entries.result.length == 0" class="table-row">
                            <td [attr.colspan]="entries.definition.length" align="center" style="font-style: italic;">No
                                results</td>
                        </tr>
                        <tr *ngIf="entries.error" class="table-row">
                            <td align="center" style="font-style: italic;">
                                {{entries.error.errno}}
                                {{entries.error.code}}
                                {{entries.error.sqlMessage}}
                            </td>
                        </tr>
                        <tr class="table-row" *ngFor="let entry of entries.result">
                            <td class="px-1" *ngFor="let column of entries.definition; let index = index" [ngStyle]="{'text-align': 
                            column.Type == 'varchar(36)' 
                            || column.Type.startsWith('decimal')
                            || column.Type.startsWith('int')
                            || column.Type.startsWith('tinyint')
                            ? 'right' : '' }">
                                <span [ngStyle]="{
                                'padding-right': 
                                column.Type == 'varchar(36)' 
                                || column.Type.startsWith('decimal')
                                || column.Type.startsWith('int')
                                || column.Type.startsWith('tinyint')
                                ? '.75rem' : '',
                                'padding-left': 
                                column.Type != 'varchar(36)' 
                                && !column.Type.startsWith('decimal')
                                && !column.Type.startsWith('int')
                                && !column.Type.startsWith('tinyint')
                                ? '.75rem' : '' 
                                    }">
                                    <span *ngIf="column.Type == 'datetime'">
                                        {{entry[column.Field] | date : 'M/d/y'}}
                                    </span>
                                    <span *ngIf="column.Type != 'datetime'">
                                        {{entry[column.Field]}}
                                    </span>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- <app-page-controls [pageNumber]="entries.currentPage" [pageSize]="entries.entriesPerPage" [entryCount]="entries.count"
                [setPage]="this.setPage.bind(this)" [isFooter]="true">
            </app-page-controls> -->
        <!-- <hr />
        <div class="row">
            <div class="col">
                <span
                    style="font-family: 'Courier New', Courier, monospace; white-space: pre-wrap;">{{entries.create_table
                    | createTable}}
                </span>
            </div>
        </div> -->
    </span>
</div>