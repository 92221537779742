import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { iProgram } from '../model/program.model';
import { iCounty } from '../model/county.model';
import { iCampaign } from '../model/campaign.model';

@Injectable({
  providedIn: 'root',
})
export class OutreachService 
{
    constructor(private http: HttpClient) {}

    getCountyDropdown(userUuid: string, organizationUuid: string)
    {
        return this.http.get<iCounty[]>('api/countyDropdownOptions/' + userUuid + '/' + organizationUuid);
    }

    getCampaignDropdown(organizationUuid: string, jurisdictionId: string, fiscalYearId: string)
    {
        return this.http.get<iCampaign[]>('api/campaignDropdownOptions/' + organizationUuid + '/' + jurisdictionId + '/' + fiscalYearId);
    }

    createNewOutreach(userUuid: string, organizationUuid: string, program: string, slogan: string, county: string, referenceNumber: string, sequence: string) {
        const body = 
        {
            userUuid: userUuid,
            organizationUuid: organizationUuid,
            program: program,
            slogan: slogan,
            county: county,
            referenceNumber: referenceNumber,
            sequence: sequence
        };
    
        return this.http.put<any>('api/newOoutreachCampaign', body);
    }
    
}