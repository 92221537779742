<div *ngIf="initialized && showPopupHeader" mat-dialog-title style="width: 100%; height: 2.5em; position: relative;">
    <span *ngIf="newEntry">Add</span>
    <span *ngIf="!newEntry">Edit</span>
    {{accountNameFC.value}}
    <div #theButton style="position: absolute; right: .5em; top: 0;">
        <fa-icon [icon]="faTimes" title="Cancel" (click)="cancel()" style="cursor: pointer"
            (mouseenter)="theButton.style.color='blue'" (mouseleave)="theButton.style.color=null">
        </fa-icon>
    </div>
</div>

<div *ngIf="initialized" style="padding-left: .5em" class="mt-3">
    <div class="container-fluid">
        <app-input-enum-validatable *ngIf="newEntry" label="Select Account" [fc]="accountFC"
            [enumValuesNameAndId]="data ? data.addAccountOptions : null" [change]="accountChange.bind(this)">
        </app-input-enum-validatable>

        <app-input-text-validatable *ngIf="!newEntry" label="Account" [fc]="accountNameFC" [disabled]="true"
            [maxlength]="36">
        </app-input-text-validatable>

        <app-validation-error [error]="accountError">
        </app-validation-error>

        <app-input-text-validatable label="Description" [fc]="nameFC" [disabled]="!editable" [commentId]="'name'"
            [showFlag]="showFlag" [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
            [targetUuid]="data ? data.accountUuid : null" [commentData]="inputDataById" [maxlength]="36">
        </app-input-text-validatable>
        <app-validation-error [error]="nameError">
        </app-validation-error>

        <app-input-money *ngIf="account && account.display_unit_price" [label]="account.unit_price_label"
            [fc]="pricePerSquareFootFC" [change]="pricePerSquareFootChange.bind(this)" [uneditable]="!editable"
            [commentId]="'unit_price'" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
            [submissionUuid]="submissionUuid" [targetUuid]="data ? data.accountUuid : null"
            [commentData]="inputDataById" (change)="round(pricePerSquareFootFC, 3)">
        </app-input-money>
        <app-validation-error [error]="pricePerSquareFootError">
        </app-validation-error>

        <app-input-money *ngIf="account && account.display_quantity && account.quantity_label == 'Price per Color'"
            [label]="account.quantity_label" [fc]="totalSquareFootageFC" [change]="totalSquareFootageChange.bind(this)"
            [uneditable]="!editable" [commentId]="'quantity'" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
            [submissionUuid]="submissionUuid" [targetUuid]="data ? data.accountUuid : null"
            [commentData]="inputDataById">
        </app-input-money>

        <app-input-text-validatable
            *ngIf="account && account.display_quantity && account.quantity_label != 'Price per Color'"
            [label]="account.quantity_label" [fc]="totalSquareFootageFC" [change]="totalSquareFootageChange.bind(this)"
            [disabled]="!editable" [commentId]="'quantity'" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
            [submissionUuid]="submissionUuid" [targetUuid]="data ? data.accountUuid : null"
            [commentData]="inputDataById">
        </app-input-text-validatable>
        <app-validation-error [error]="totalSquareFootageError">
        </app-validation-error>

        <app-input-percent *ngIf="account && account.allow_allocation" label="Percent Allocated"
            [fc]="percentAllocatedFC" [disabled]="!editable" [change]="percentAllocatedChange.bind(this)"
            [commentId]="'percent_allocated'" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
            [submissionUuid]="submissionUuid" [targetUuid]="data ? data.accountUuid : null"
            [commentData]="inputDataById">
        </app-input-percent>
        <app-validation-error [error]="percentAllocatedError">
        </app-validation-error>

        <app-input-text-area-validatable label="Narrative" [fc]="narrativeFC" [change]="narrativeChange.bind(this)"
            [disabled]="true" [commentId]="'narrative'" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
            [submissionUuid]="submissionUuid" [targetUuid]="data ? data.accountUuid : null"
            [commentData]="inputDataById">
        </app-input-text-area-validatable>
        <app-validation-error [error]="narrativeError">
        </app-validation-error>

        <app-input-enum-validatable *ngIf="allocationMethodOptions && allocationMethodOptions.length > 0"
            [disabled]="!editable" label="Allocation Method" [fc]="allocationMethodFC"
            [enumValuesNameAndId]="allocationMethodOptions" [change]="allocationMethodChange.bind(this)">
        </app-input-enum-validatable>
        <app-validation-error [error]="allocationMethodError">
        </app-validation-error>

        <app-input-money label="State Only" [fc]="snapFC" [change]="snapChange.bind(this)" [uneditable]="!editable"
            [commentId]="'snap'" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
            [submissionUuid]="submissionUuid" [targetUuid]="data ? data.accountUuid : null"
            [commentData]="inputDataById" (change)="round(snapFC)">
        </app-input-money>


        <app-validation-error [error]="snapError">
        </app-validation-error>

        <app-input-money label="State Match/Federal Match" [fc]="childNutritionSnapFC" [uneditable]="!editable"
            [change]="childNutritionSnapChange.bind(this)" [commentId]="'child_nutrition_snap'" [showFlag]="showFlag"
            [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
            [targetUuid]="data ? data.accountUuid : null" [commentData]="inputDataById"
            (change)="round(childNutritionSnapFC)">
        </app-input-money>
        <app-validation-error [error]="childNutritionSnapError">
        </app-validation-error>

        <app-input-money label="Total" [fc]="totalFC" [change]="totalChange.bind(this)" [uneditable]="'true'">
        </app-input-money>
        <app-validation-error [error]="totalError">
        </app-validation-error>


        
        <div class="row mt-2">
            <div class="col-4 pb-2 unedit-padding bold">
            </div>
            <div class="col-1 pb-2 unedit-padding bold" style="text-align: right;">
                New
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding bold" style="text-align: right;">
                Previous
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding bold" style="text-align: right;">
                Change
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-4 pb-2 unedit-padding bold">
                State Only
            </div>
            <div class="col-1 pb-2 unedit-padding" style="text-align: right;">
                {{ snapFC.value || 0 | currency}} +
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ debitStateEst || 0 | currency }} +
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ snapFC.value - debitStateEst | currency }} +
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-4 pb-2 unedit-padding bold">
                State Match
            </div>
            <div class="col-1 pb-2 unedit-padding" style="text-align: right;">
                {{ childNutritionSnapFC.value / 2 | currency}} +
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ debitFederalEst / 2 | currency }} +
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ childNutritionSnapFC.value / 2 - debitFederalEst / 2 | currency }} +
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-4 pb-2 unedit-padding bold">
                Federal Match
            </div>
            <div class="col-1 unedit-padding" style="text-align: right;">
                {{ childNutritionSnapFC.value / 2 | currency}}
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ debitFederalEst / 2 | currency }}
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ +childNutritionSnapFC.value / 2 - +debitFederalEst / 2 | currency }}
            </div>
        </div>

        <div class="row">
            <div class="col-4">
            </div>
            <div class="col-1" style="border-style: solid; border-color: black; border-width: 1px 0 0 0;">
            </div>
            <div class="col-1">
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-1" style="border-style: solid; border-color: black; border-width: 1px 0 0 0;">
            </div>
            <div class="col-1">
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-1" style="border-style: solid; border-color: black; border-width: 1px 0 0 0;">
            </div>
        </div>

        <div class="row">
            <div class="col-4 pb-2 unedit-padding bold">
                Total Allocation
            </div>
            <div class="col-1 unedit-padding" style="text-align: right;">
                {{ +snapFC.value + +childNutritionSnapFC.value | currency}}
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ +debitStateEst + +debitFederalEst | currency }}
            </div>
            <div *ngIf="submission && submission.sequence != 0" class="col-2 pb-2 unedit-padding" style="text-align: right;">
                {{ childNutritionSnapFC.value / 2 - debitFederalEst / 2 ++ childNutritionSnapFC.value / 2 - debitFederalEst / 2 ++ snapFC.value - debitStateEst | currency}}
            </div>
        </div>

        <app-input-text-area-validatable label="Agency Comments" [fc]="agencyCommentsFC"
            [change]="agencyCommentsChange.bind(this)" [disabled]="!editable" [commentId]="'agency_comments'"
            [showFlag]="showFlag" [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
            [targetUuid]="data ? data.accountUuid : null" [commentData]="inputDataById" [maxlength]="4000">
        </app-input-text-area-validatable>
        <app-validation-error [error]="agencyCommentsError">
        </app-validation-error>

        <div *ngIf="this.userOrganizationType == 'PRIME' || this.userOrganizationType == 'SYSTEM'" class="row mt-2">
            <div class="col bold data-row" align="center">
                <app-checkbox [id]="'user_flag'" [fc]="userFlagFC" [change]="userFlagChange.bind(this)"></app-checkbox>
                <label [for]="'user_flag'" class="ml-1 pointer">Apply user flag to record (HSNY use only – line will be highlighted on summary page)</label>
            </div>
        </div>

        <div class="row mt-0">
            <div class="col bold" style="color: red; font-size: .8em;" align="center">
                {{saveError}} &nbsp;
            </div>
        </div>
    </div>
</div>

<div *ngIf="initialized && editable" class="mt-2 mb-3" style="width: 100%;" align="center">
    <app-button3 *ngIf="!newEntry" class="mx-1" label="Delete" [clickFunction]="delete.bind(this)" [icon]="faMinus"
        [isDelete]="true">
    </app-button3>
    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
    </app-button3>
    <app-button3 class="mx-1" label="Save" [clickFunction]="save.bind(this)" [icon]="faSave" [isFinal]="true">
    </app-button3>
</div>

<div *ngIf="initialized && !editable && !hideButtons" class="mt-2 mb-3" style="width: 100%;" align="center">
    <app-button3 class="mx-1" label="Ok" [clickFunction]="ok.bind(this)" [icon]="faCheck">
    </app-button3>
</div>