<div *ngIf="!style && !halfLabelSize" class="row" [ngClass]="calcRowClass">
    <div class="col-4 pb-2 unedit-padding bold" [ngClass]="calcColClass">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
    </div>
    <div *ngIf="!disabled" class="col-8">
        <input type="text" class="input-style" [ngStyle]="{ 'width': units ? '50%' : 'inherit'}" [formControl]="fc"
            (keyup)="doChange($event)" (keyup.enter)="doEnter()" [maxlength]="maxlength">
        <span *ngIf="units"> {{units}}</span>
    </div>
    <div *ngIf="disabled" class="col-8 unedit-padding">
        {{fc.value}}
    </div>
</div>
<div *ngIf="!style && halfLabelSize" class="row">
    <div class="col-2 pb-2 unedit-padding bold">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
    </div>
    <div *ngIf="!disabled" class="col-10">
        <input type="text" class="input-style" [formControl]="fc" (keyup)="doChange($event)" (keyup.enter)="doEnter()">
    </div>
    <div *ngIf="disabled" class="col-10 unedit-padding">
        {{fc.value}}
    </div>
</div>
<div *ngIf="!style && exampleText && !disabled" class="row">
    <div class="col-4 pb-2">
    </div>
    <div class="col-8 pb-2" style="font-size: .8em">
        {{exampleText}}
    </div>
</div>
<div *ngIf="style == 'rebrand'" class="row">
    <div *ngIf="disabled" class="col unedit-padding" align="right">
        <span class="bold">{{label}}:</span><span style="color: darkgrey;"> {{fc.value}}</span>
        <div style="padding-right: 20px; display: inline-block;"></div>
    </div>
</div>

<div *ngIf="style == 'rebrand2'" class="row mt-3">
    <div class="col bold" style="color: darkgrey">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
    </div>
</div>
<div *ngIf="style == 'rebrand2'" class="row mt-1">
    <div class="col">
        <input type="text" class="input-style ml-2" style="color: darkgrey;" [formControl]="fc"
            (keyup)="doChange($event)" (keyup.enter)="doEnter()">
    </div>
</div>
<div *ngIf="style == 'rebrand2' && exampleText && !disabled" class="row">
    <div class="col" style="font-size: .8em; color: darkgrey;" align="right">
        {{exampleText}}
    </div>
</div>
<div *ngIf="style == 'edit-sbl'" class="row mt-1">
    <div class="col" [ngClass]="calcColClass">
        <input *ngIf="!disabled" type="text" class="input-style" [formControl]="fc" (keyup)="doChange($event)" (keyup.enter)="doEnter()">
        <span *ngIf="disabled">{{fc.value}}</span>
    </div>
</div>
<div *ngIf="style == 'edit-unpadded'" class="row">
    <div class="col">
        <input type="text" class="input-style" [formControl]="fc" (keyup)="doChange($event)" (keyup.enter)="doEnter()">
    </div>
</div>

<div *ngIf="style == 'rebrand2-prefixed'" class="row mt-3">
    <div class="col bold" style="color: darkgrey">
        {{label}}<span *ngIf="requiredMarker" style="position: relative;"
            (mouseenter)="getElementById(attributeIdentifier + '_required_mouseover').style.display='block';"
            (mouseleave)="getElementById(attributeIdentifier + '_required_mouseover').style.display='none';">*<span
                style="position: absolute; display: none; top: 0px; left: 1.0em;"
                id="{{attributeIdentifier}}_required_mouseover">(required)</span></span>
    </div>
</div>
<div *ngIf="style == 'rebrand2-prefixed'" class="row mt-1">
    <div class="col pr-2" style="color: darkgrey" align="right">
        {{prefix}}
        <input type="text" class="input-style ml-2" style="color: darkgrey; width: 50%;" [formControl]="fc"
            (keyup)="doChange($event)" (keyup.enter)="doEnter()">
    </div>
</div>