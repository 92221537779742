<h1 mat-dialog-title>Balance Error</h1>
<mat-dialog-content>
    <p>Please check the following submissions for any balance discrepancies</p>

    <div class="header-row">
        <div class="header-cell">Reporting Group</div>
        <div class="header-cell">Account</div>
        <div class="header-cell">Description</div>
    </div>

    <p *ngFor="let submission of submissions" class="dialog-text">
        <span class="data-cell">{{submission.reportingGroup}}</span>
        <span class="data-cell">{{submission.accountName}}</span>
        <span class="data-cell">{{submission.name}}</span>
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>OK</button>
</mat-dialog-actions>