<div class="container-fluid">
    <!-- page title -->
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faFileInvoiceDollar"></fa-icon>
            Report Budget
        </div>
    </div>

    <!-- budget report data -->
    <div class="row">
        <div class="col px-0">
            <div class="report_header">
                <p>Agency: {{organization_name}}</p>
                <p>Description: {{description}}</p>
            </div>
            <div style="padding: 10px;">
                <p><span style="font-weight: bold;">Approved By: </span> {{approved_by}} on {{approved_on | date }}</p>
            </div>
            <table width="100%" class="table">
                <!-- <tr class="report_header">
                    <td colspan="5">
                        Agency: {{organization_name}}<br /><br />
                        Description: {{description}}
                    </td>
                </tr>
                <tr>
                    <td style="border: none; font-weight: bold;">
                        Approved By:
                    </td>
                    <td style="border: none;">
                        {{approved_by}}
                    </td>
                </tr> -->
                <ng-container *ngFor="let report of report_data; let i = index">

                    <ng-container *ngIf="i >= 1;else first_row">
                        <ng-container *ngIf="report.reporting_group_name != report_data[i-1].reporting_group_name">
                            <!-- <tr>
                                <td colspan="5" class="report_table_header">
                                    {{report.reporting_group_name}}
                                </td>
                            </tr> -->
                            <!-- <tr>
                                <th>Account</th>
                                <th>State Only</th>
                                <th>State Match</th>
                                <th>Federal Match</th>
                                <th>Total</th>
                            </tr> -->
                        </ng-container>
                    </ng-container>

                    <ng-template #first_row>
                        <!-- <tr>
                            <td colspan="5" class="report_table_header">
                                {{report.reporting_group_name}}
                            </td>
                        </tr> -->
                        <tr>
                            <th>Account</th>
                            <th>State Only</th>
                            <th>State Match</th>
                            <th>Federal Match</th>
                            <th>Total</th>
                        </tr>
                    </ng-template>
                
                    <tr>
                        <td>{{report.reporting_group_name}}</td>
                        <td>{{report.total_state | currency}}</td>
                        <td>{{report.total_federal / 2 | currency}}</td>
                        <td>{{report.total_federal / 2 | currency}}</td>
                        <td>{{report.total | currency}}</td>
                    </tr>
                </ng-container>
                <tr style="font-weight: bold;">
                    <td>Total Allocated</td>
                    <td>{{total_state_only | currency}}</td>
                    <td>{{total_state_match | currency}}</td>
                    <td>{{total_federal_match | currency}}</td>
                    <td>{{total_totals | currency}}</td>
                </tr>
            </table>
        </div>
    </div>
    <!-- <div class="row">
        <table width="100%">
            <tr>
                <th>State Only Allocated</th>
                <th>State Match Allocated</th>
                <th>Federal Match Allocated</th>
                <th>Total Budget Allocated</th>
            </tr>
            <ng-container *ngFor="let allocation of report_allocation">
                <tr>
                    <td>{{allocation.state_granted | currency}}</td>
                    <td>{{allocation.federal_granted / 2 | currency}}</td>
                    <td>{{allocation.federal_granted / 2 | currency}}</td>
                    <td>{{allocation.state_granted + allocation.federal_granted | currency}}</td>
                </tr>
            </ng-container>
        </table>
    </div> -->

</div>
