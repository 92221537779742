import { Component, OnInit, ViewChild } from '@angular/core';
import { SubmissionStepService } from '../submission-step.service';
import { SubmissionService } from '../submission.service';
import { UntypedFormControl } from '@angular/forms';
import { faSave, faBan, faShoePrints } from '@fortawesome/free-solid-svg-icons';
import { SnackRouteService } from '../snack-route.service';
import { Router } from '@angular/router';
import { ParamService } from '../param.service';
import { shareReplay } from 'rxjs/operators';
import { DashboardService } from '../dashboard.service';
import { SubmissionAttachmentsComponent } from '../submission-attachments/submission-attachments.component';
import { SessionService } from '../session.service';

@Component({
    selector: 'app-submission-question-steps',
    templateUrl: './submission-question-steps.component.html',
})
export class SubmissionQuestionStepsComponent implements OnInit {
    faShoePrints = faShoePrints
    @ViewChild(SubmissionAttachmentsComponent) private _attachmentsComponent: SubmissionAttachmentsComponent;
    claims: any;

    constructor(
        private submissionService: SubmissionService,
        private submissionStepService: SubmissionStepService,
        private snackRouteService: SnackRouteService,
        private router: Router,
        private paramService: ParamService,
        private dashboardService: DashboardService,
        private sessionService: SessionService
    ) { }


    faSave = faSave
    faBan = faBan
    initialized = false
    questionSteps
    referenceNumberFC = new UntypedFormControl()
    program
    organization
    status
    submissionName
    dueDate
    county
    allStepsDone = false

    //todo - these two variables are never set
    submitterName = null
    approverName = null

    submissionUuid
    submissionActivityFeed$
    submissionHistory$


    get attachmentsComponent() {
        return this._attachmentsComponent
    }

    getQuestionStepsPromise() {
        return new Promise((resolve, reject) => {
            this.submissionStepService.getStepsBySubmission(this.submissionUuid).subscribe((response) => {
                this.questionSteps = response.result

                if (this.questionSteps && this.questionSteps.length > 0) {
                    this.program = this.questionSteps[0].program_name
                    this.organization = this.questionSteps[0].organization_name
                    this.status = this.questionSteps[0].status_name
                    this.submissionName = this.questionSteps[0].submission_name
                    this.dueDate = this.questionSteps[0].due_date
                    this.county = this.questionSteps[0].jurisdiction_name
                    this.referenceNumberFC.setValue(this.questionSteps[0].reference_number)

                    //commented because this will be blank until submitted, and this page is only displayed pre-submission
                    //this.submitterName = this.questionSteps[0].updated_by
                    this.approverName = this.questionSteps[0].approver_name

                    this.allStepsDone = true
                    for (var i = 0; i < this.questionSteps.length; ++i) {
                        if (this.questionSteps[i].step_status_id != 'D') {
                            this.allStepsDone = false
                            break
                        }
                    }
                }
                resolve(null)
            })
        })
    }

    submission$
    submissionStatuses$
    orgType: string
    ngOnInit() {
        this.claims = this.sessionService.getClaims()
        this.orgType = this.claims['organization_type']

        console.log(this.orgType)
        
        this.paramService.getParamPromise("submissionUuid").then((paramValue) => {
            this.submissionUuid = paramValue
            this.submission$ = this.submissionService.fetchSubmissionHeaders(this.submissionUuid).pipe(shareReplay(1))
            this.submissionStatuses$ = this.dashboardService.getStatusesById()
            this.submissionActivityFeed$ = this.submissionService.fetchFeedAndAppendImagesToDom(this.submissionUuid);
            this.submissionHistory$ = this.submissionService.fetchSubmissionHistory(this.submissionUuid);
            return this.getQuestionStepsPromise()
        }).then(() => {
            this.initialized = true
            console.log("questions steps", this.questionSteps)
        })
    }

    cancel() {
        this.router.navigate(["/dashboard"])
    }

    submit() {
        if (!this.allStepsDone) {
            this.snackRouteService.snack("All steps must be complete before submitting.")
        } else {
            this.router.navigate(["/submission-review"], { queryParams: { submissionUuid: this.submissionUuid } })
        }
    }
}
