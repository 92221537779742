import { Component, OnInit, Input } from '@angular/core';
import { faFlag } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-percent',
    templateUrl: './input-percent.component.html',
})
export class InputPercentComponent implements OnInit {
    @Input() label;
    @Input() fc;
    @Input() disabled;
    @Input() change;
    @Input() keyupEnter;
    @Input() requiredMarker;
    @Input() commentId
    @Input() commentData
    @Input() flagClick
    @Input() showFlag
    @Input() submissionUuid
    @Input() targetUuid
    faFlag = faFlag

    letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    attributeIdentifier = this.randomString();
    randomString() {
        var str = "";
        for (var i = 0; i < 20; ++i) {
            str += this.letters.charAt(Math.random() * this.letters.length);
        }
        return str;
    }

    getElementById(id) {
        return document.getElementById(id);
    }

    constructor() {
    }

    ngOnInit() {
    }

    doChange(e) {
        if (this.change) {
            this.change(e);
        }
    }

    doEnter() {
        if (this.keyupEnter) {
            this.keyupEnter();
        }
    }
}
