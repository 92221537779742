import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-page-controls',
    templateUrl: './page-controls.component.html',
    styleUrls: ['./page-controls.component.css']
})
export class PageControlsComponent implements OnInit {

    constructor() { }

    @Input() title
    @Input() pageNumber
    @Input() pageSize
    @Input() entryCount
    @Input() setPage
    @Input() isFooter

    get firstResultNum() {
        if (this.entryCount == 0) {
            return 0
        }
        return ((this.pageNumber - 1) * this.pageSize) + 1
    }

    get lastResultNum() {
        return Math.min((this.pageNumber) * this.pageSize, this.entryCount)
    }

    get maxPage() {
        if (this.entryCount % this.pageSize == 0) {
            return this.entryCount / this.pageSize
        }
        return Math.floor(this.entryCount / this.pageSize) + 1
    }

    ngOnInit() {
    }

    previous() {
        if (this.pageNumber > 1) {
            this.setPage(this.pageNumber - 1)
        }
    }

    current() {
        this.setPage(this.pageNumber)
    }

    next() {
        if (this.pageNumber < this.maxPage) {
            this.setPage(this.pageNumber + 1)
        }
    }

    afterNext() {
        this.setPage(this.pageNumber + 2)
    }

    first() {
        this.setPage(1)
    }

    last() {
        this.setPage(this.maxPage)
    }
}
