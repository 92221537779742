import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { QuestionService } from '../question.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faBan, faSave, faShoePrints } from '@fortawesome/free-solid-svg-icons';
import { ParamService } from '../param.service';

@Component({
    selector: 'app-edit-question-step',
    templateUrl: './edit-question-step.component.html',
})
export class EditQuestionStepComponent implements OnInit {
    faBan = faBan
    faSave = faSave
    faShoePrints = faShoePrints
    stepFC = new UntypedFormControl()
    displayFC = new UntypedFormControl()
    instructionsFC = new UntypedFormControl()
    initialized = false
    step

    constructor(
        private _questionService: QuestionService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private paramService: ParamService,
    ) { }

    get questionService() {
        return this._questionService
    }

    getStepPromise() {
        return new Promise((resolve, reject) => {
            this.questionService.getStepById(this.stepId).subscribe((response) => {
                if (response.success) {
                    if (response.result.length != 1) {
                        console.log("unexpected nonsingular result encountered")
                    } else {
                        this.step = response.result[0]
                        //console.log("step!", this.step)
                        this.stepFC.setValue(this.step.name)
                        this.displayFC.setValue(this.step.display)
                        this.instructionsFC.setValue(this.step.instructions)
                    }
                } else {
                    console.log("error while fetching step", response)
                }
                resolve(null)
            })
        })
    }

    stepId
    ngOnInit() {
        this.paramService.getParamPromise("stepId").then((paramValue) => {
            this.stepId = paramValue
            if (this.stepId != null) {
                return this.getStepPromise()
            }
        }).then(() =>{
            this.initialized = true
        })
    }

    save() {
        this.questionService.saveStep(
            this.stepId,
            this.stepFC.value,
            this.displayFC.value,
            this.instructionsFC.value).subscribe((response) => {
                if (response.success) {
                    var snack = "Step successfully saved"
                    this._snackBar.open(snack, null, { duration: 2000, });
                    this.router.navigate(["/manage-questions"])
                } else {
                    console.log("error while saving step", response)
                }

            })
    }

    cancel() {
        var snack = "Action Cancelled"
        this._snackBar.open(snack, null, { duration: 2000, });
        this.router.navigate(["/manage-questions"])
    }

}
