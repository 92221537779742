import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-money',
    templateUrl: './money.component.html',
})
export class MoneyComponent implements OnInit {

    @Input() fc;
    @Input() disabled;
    @Input() maxlength;

    constructor() {
    }

    ngOnInit() {
    }
}
