<div *ngIf="initialized && entryData$ | async as entryData" class="container-fluid">
    <span *ngIf="salaryCalculatedAmount$ | async as salaryCalculatedAmount">
        <span *ngIf="hourlyCalculatedAmount$ | async as hourlyCalculatedAmount">
            <div class="row page-title">
                <div class="col">
                    <fa-icon [icon]="faUser"></fa-icon>
                    Budget Personnel
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <fa-icon [icon]="faUser" class="mr-2" title="Employee Details">
                    </fa-icon>
                    <h4 style="display: inline-block;">Employee Details</h4>
                </div>
            </div>

            <!-- note: name and title labels are INTENTIONALLY swapped, as per HSNY-1454 and HSNY-1455 -->
            <app-input-text-validatable label="Employee Title" [fc]="employeeNameFC" (keyup)="employeeNameError = null"
                [disabled]="!editable" [commentId]="'employee_name'" [showFlag]="showFlag"
                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid" [targetUuid]="personnelEntryUuid"
                [commentData]="inputDataById" [maxlength]="255">
            </app-input-text-validatable>
            <app-validation-error [error]="employeeNameError">
            </app-validation-error>

            <app-input-text-validatable label="Employee Name" [fc]="jobDescriptionFC"
                (keyup)="jobDescriptionError = null;" [disabled]="!editable" [commentId]="'job_title'"
                [showFlag]="showFlag" [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById" [maxlength]="255">
            </app-input-text-validatable>
            <app-validation-error [error]="jobDescriptionError">
            </app-validation-error>

            <div class="row mt-1">
            </div>

            <app-input-date-validatable label="Date of Hire" [fc]="dateOfHireFC" (click)="dateOfHireError = null;"
                [disabled]="!editable" [commentId]="'date_of_hire'" [commentData]="inputDataById" [showFlag]="showFlag"
                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid" [targetUuid]="personnelEntryUuid">
            </app-input-date-validatable>
            <app-validation-error [error]="dateOfHireError">
            </app-validation-error>

            <div class="row mt-1">
            </div>

            <app-input-date-validatable label="Date of Termination" [fc]="dateOfTerminationFC" [disabled]="!editable"
                (click)="dateOfTerminationError = null;" [commentId]="'date_of_termination'"
                [commentData]="inputDataById" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
                [submissionUuid]="submissionUuid" [targetUuid]="personnelEntryUuid">
            </app-input-date-validatable>
            <app-validation-error [error]="dateOfTerminationError">
            </app-validation-error>

            <div class="row mt-1">
            </div>

            <div class="row">
                <div class="col-4 bold">
                    Personnel Type
                    <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick('type_of_personnel')"
                        [title]="inputDataById['type_of_personnel'].showInputContainer.value ? 'Cancel' : 'Edit'">
                    </fa-icon>
                </div>
                <div class="col-8 pt-1">
                    <div class="row">
                        <div class="col">
                            <input class="mr-2" type="radio" [id]="'personnel_admin'" [name]="'typeOfPersonnel'"
                                [value]="'NOEP_PRSNL_OH'" [formControl]="personnelTypeFC"
                                (change)="personnelTypeChange()" />
                            <label class="mb-0" [for]="'personnel_admin'"
                                [ngClass]="{'role-selected': personnelTypeFC.value=='personnel_admin'}"
                                style="vertical-align: top;">Administrative</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <input class="mr-2" type="radio" [id]="'personnel_program'" [name]="'typeOfPersonnel'"
                                [value]="'NOEP_PRSNL'" [formControl]="personnelTypeFC"
                                (change)="personnelTypeChange()" />
                            <label class="mb-0" [for]="'personnel_program'"
                                [ngClass]="{'role-selected': personnelTypeFC.value=='personnel_program'}"
                                style="vertical-align: top;">Program</label>
                        </div>
                    </div>
                </div>
            </div>
            <app-outreach-submission-comment [submissionUuid]="submissionUuid" [inputId]="'type_of_personnel'"
                [commentUuid]="inputDataById['type_of_personnel'].commentUuid"
                [comment]="inputDataById['type_of_personnel'].comment"
                [showInputContainer]="inputDataById['type_of_personnel'].showInputContainer" [showFlag]="showFlag"
                [odd]="false" [targetUuid]="personnelEntryUuid">
            </app-outreach-submission-comment>
            <app-validation-error [error]="personnelTypeError">
            </app-validation-error>

            <div class="row mt-1">
            </div>

            <app-input-radio-validatable [label]="'Contract Time Allocation'"
                [enumValuesNameAndId]="employeeAllocationOptions" [fc]="employeeAllocationFC"
                [change]="contractTimeAllocationChange.bind(this)" [commentId]="'employee_allocation'"
                [disabled]="!editable" [commentData]="inputDataById" [showFlag]="showFlag"
                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid" [targetUuid]="personnelEntryUuid">
            </app-input-radio-validatable>

            <app-validation-error [error]="employeeAllocationError">
            </app-validation-error>

            <div class="row mt-2">
                <div class="col">
                    <div class="py-2 pl-1"
                        style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;"
                        [ngStyle]="{'backgroundColor': !this.employeeAllocationFC.value || this.employeeAllocationFC.value == '3' ? 'lightgrey' : ''}">
                        <div class="mx-2">

                            <app-input-percent label="Estimated % Time on Contract"
                                [fc]="estimatedPercentTimeOnContractFC" [disabled]="!editable"
                                (keyup)="estimatedPercentTimeOnContractError = null;"
                                [commentId]="'estimated_percent_time_on_contract'" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById">
                            </app-input-percent>
                            <app-validation-error [error]="estimatedPercentTimeOnContractError">
                            </app-validation-error>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
            </div>

            <hr />
            <div class="row mt-2">
                <div class="col">
                    <fa-icon [icon]="faMoneyBillWave" class="mr-2" title="Payroll Information">
                    </fa-icon>
                    <h4 style="display: inline-block;">Payroll Information</h4>
                </div>
            </div>

            <div class="row">
                <div class="col" align="center">
                    <app-button3 class="mx-1" label="Salary" [clickFunction]="salary.bind(this)"
                        [icon]="faMoneyBillWave" [unselected]="!salarySelected" [isFinal]="true">
                    </app-button3>
                    <app-button3 class="mx-1" label="Hourly" [clickFunction]="hourly.bind(this)" [icon]="faHourglass"
                        [unselected]="!hourlySelected" [isFinal]="true">
                    </app-button3>
                </div>
            </div>

            <app-validation-error [error]="salaryOrHourlyError">
            </app-validation-error>

            <div *ngIf="salarySelected" class="row mt-2">
                <div class="col">
                    <div class="py-2"
                        style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;"
                        [ngStyle]="{'backgroundColor': !salarySelected ? 'lightgrey' : ''}">
                        <div class="mx-2">

                            <app-input-enum-validatable label="Pay Period" [fc]="salaryPayPeriodFC"
                                [enumValuesNameAndId]="payPeriodOptions" [disabled]="!salarySelected || !editable"
                                [commentId]="'pay_period'" [commentData]="inputDataById" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid">
                            </app-input-enum-validatable>
                            <app-validation-error [error]="salaryPayPeriodError">
                            </app-validation-error>

                            <app-input-money label="Annual Salary" [fc]="annualSalaryFC" [commentId]="'annual_salary'"
                                [uneditable]="!editable" [commentData]="inputDataById" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" (change)="round(annualSalaryFC)">
                            </app-input-money>
                            <app-validation-error [error]="annualSalaryError">
                            </app-validation-error>

                            <app-input-text-validatable label="Hours Per Pay Period" [fc]="salaryHoursPerPayPeriodFC"
                                [disabled]="!editable" (keyup)="salaryHoursPerPayPeriodError = null;"
                                [commentId]="'salary_hours_per_pay_period'" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById">
                            </app-input-text-validatable>
                            <app-validation-error [error]="salaryHoursPerPayPeriodError">
                            </app-validation-error>

                            <div class="row">
                                <div class="col-4 pb-2 unedit-padding bold">
                                    Hourly Rate
                                </div>
                                <div *ngIf="payPeriodsById[salaryPayPeriodFC.value]" class="col-8 unedit-padding">
                                    {{ annualSalaryFC.value / (payPeriodsById[salaryPayPeriodFC.value].paychecks *
                                    salaryHoursPerPayPeriodFC.value) | currency }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="hourlySelected" class="row mt-2">
                <div class="col">
                    <div class="py-2"
                        style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;"
                        [ngStyle]="{'backgroundColor': !hourlySelected ? 'lightgrey' : ''}">
                        <div class="mx-2">
                            <app-input-enum-validatable label="Pay Period" [fc]="hourlyPayPeriodFC"
                                [enumValuesNameAndId]="payPeriodOptions" [disabled]="!hourlySelected || !editable"
                                [commentId]="'pay_period'" [commentData]="inputDataById" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid">
                            </app-input-enum-validatable>
                            <app-validation-error [error]="hourlyPayPeriodError">
                            </app-validation-error>

                            <app-input-money label="Hourly Rate" [fc]="hourlyRateFC" [commentId]="'hourly_rate'"
                                [uneditable]="!editable" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
                                [submissionUuid]="submissionUuid" [targetUuid]="personnelEntryUuid"
                                [commentData]="inputDataById" (change)="round(hourlyRateFC, 2)">
                            </app-input-money>

                            <app-input-text-validatable label="Hours Per Pay Period" [fc]="hourlyHoursPerPayPeriodFC"
                                [disabled]="!editable" (keyup)="hourlyHoursPerPayPeriodError = null;"
                                [commentId]="'hourly_hours_per_pay_period'" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById">
                            </app-input-text-validatable>
                            <app-validation-error [error]="hourlyHoursPerPayPeriodError">
                            </app-validation-error>

                            <div class="row">
                                <div class="col-4 pb-2 unedit-padding bold">
                                    Annual Salary
                                </div>
                                <div *ngIf="payPeriodsById[hourlyPayPeriodFC.value]" class="col-8 unedit-padding">
                                    {{ hourlyRateFC.value * (payPeriodsById[hourlyPayPeriodFC.value].paychecks *
                                    hourlyHoursPerPayPeriodFC.value) | currency }}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
            </div>

            <div class="row mt-1">
                <div class="col" align="center">
                    <input type="checkbox" [formControl]="anticipateRaiseFC" (change)="anticipateRaiseChange($event)"
                        style="cursor: pointer; vertical-align: top;" id="raise_box">
                    <label for="raise_box" class="ml-2">We anticipate the employee will receive a raise this
                        year?</label>
                </div>
            </div>

            <div *ngIf="salarySelected" class="row mt-2">
                <div class="col">
                    <div class="py-2 pr-1"
                        style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;"
                        [ngStyle]="{'backgroundColor': !salarySelected || !anticipateRaiseFC.value ? 'lightgrey' : ''}">
                        <div class="mx-2">
                            <app-input-money label="New Salary Amount" [fc]="newSalaryAmountFC" [uneditable]="!editable"
                                [commentId]="'new_salary_amount'" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById"
                                (change)="round(newSalaryAmountFC)">
                            </app-input-money>

                            <app-input-date-validatable label="Estimated Date of Raise"
                                [fc]="salaryEstimatedDateOfRaiseFC" (click)="salaryEstimatedDateOfRaiseError = null;"
                                [disabled]="!editable || !salarySelected || !anticipateRaiseFC.value"
                                [commentId]="'salary_estimated_date_of_raise'" [submissionUuid]="submissionUuid"
                                [commentData]="inputDataById"
                                [targetUuid]="personnelEntryUuid" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)">
                            </app-input-date-validatable>
                            <app-validation-error [error]="salaryEstimatedDateOfRaiseError">
                            </app-validation-error>

                            <div class="row">
                                <div class="col-4 pb-2 unedit-padding bold">
                                    New Hourly Rate
                                </div>
                                <div *ngIf="payPeriodsById[salaryPayPeriodFC.value]" class="col-8 unedit-padding">
                                    {{ newSalaryAmountFC.value / (payPeriodsById[salaryPayPeriodFC.value].paychecks *
                                    salaryHoursPerPayPeriodFC.value) | currency }}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="hourlySelected" class="row mt-2">
                <div class="col">
                    <div class="py-2 pl-1"
                        style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;"
                        [ngStyle]="{'backgroundColor': !hourlySelected || !anticipateRaiseFC.value ? 'lightgrey' : ''}">
                        <div class="mx-2">
                            <app-input-money label="New Hourly Rate" [fc]="newHourlyRateFC"
                                [commentId]="'new_hourly_rate'" [uneditable]="!editable" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById"
                                (change)="round(newHourlyRateFC, 2)">
                            </app-input-money>
                            <app-input-date-validatable label="Estimated Date of Raise"
                                [fc]="hourlyEstimatedDateOfRaiseFC" (click)="hourlyEstimatedDateOfRaiseError = null;"
                                [disabled]="!editable || !hourlySelected || !anticipateRaiseFC.value"
                                [commentId]="'hourly_estimated_date_of_raise'" [commentData]="inputDataById"
                                [submissionUuid]="submissionUuid" [targetUuid]="personnelEntryUuid" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)">
                            </app-input-date-validatable>
                            <app-validation-error [error]="hourlyEstimatedDateOfRaiseError">
                            </app-validation-error>

                            <div class="row">
                                <div class="col-4 pb-2 unedit-padding bold">
                                    New Salary Amount
                                </div>
                                <div *ngIf="payPeriodsById[hourlyPayPeriodFC.value]" class="col-8 unedit-padding">
                                    {{ newHourlyRateFC.value * (payPeriodsById[hourlyPayPeriodFC.value].paychecks *
                                    hourlyHoursPerPayPeriodFC.value) | currency }}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1">
                <div class="col" align="center">
                    <input type="checkbox" [formControl]="anticipateBonusFC" (change)="anticipateBonusChange($event)"
                        style="cursor: pointer; vertical-align: top;" id="bonus_box">
                    <label for="bonus_box" class="ml-2">We anticipate the employee will receive a bonus this
                        year?</label>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <div class="py-2 pl-1"
                        style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;"
                        [ngStyle]="{'backgroundColor': !anticipateBonusFC.value ? 'lightgrey' : ''}">
                        <div class="mx-2">
                            <app-input-money label="Estimated Bonus Amount Charged to Contract"
                                [fc]="estimatedBonusAmountFC" [uneditable]="!editable"
                                [commentId]="'estimated_bonus_amount'" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById"
                                (change)="round(estimatedBonusAmountFC)">
                            </app-input-money>
                            <app-validation-error [error]="estimatedBonusAmountError">
                            </app-validation-error>

                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div class="row">
                <div class="col">
                    <fa-icon [icon]="faFileSignature" class="mr-2" title="Contract Information">
                    </fa-icon>
                    <h4 style="display: inline-block;">Contract Information</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    Estimated # of Hours Per Pay Period on Contract
                </div>
                <div *ngIf="salarySelected" class="col-2 unedit-padding" style="text-align: right;">
                    {{
                    salaryHoursPerPayPeriodFC.value
                    * (employeeAllocationFC.value == 4 ? estimatedPercentTimeOnContractFC.value / 100: 1)
                    | number:'1.0-2'
                    }}
                </div>
                <div *ngIf="hourlySelected" class="col-2 unedit-padding" style="text-align: right;">
                    {{
                    hourlyHoursPerPayPeriodFC.value
                    * (employeeAllocationFC.value == 4 ? estimatedPercentTimeOnContractFC.value / 100 : 1)
                    | number:'1.0-2'
                    }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    Estimated # of Annual Hours on Contract
                </div>
                <div *ngIf="salarySelected && payPeriodsById[salaryPayPeriodFC.value]" class="col-2 unedit-padding"
                    style="text-align: right;">
                    {{
                    salaryHoursPerPayPeriodFC.value * payPeriodsById[salaryPayPeriodFC.value].paychecks *
                    (employeeAllocationFC.value == 4 ?
                    estimatedPercentTimeOnContractFC.value / 100 : 1)
                    | number:'1.0-2'
                    }}
                </div>
                <div *ngIf="hourlySelected && payPeriodsById[hourlyPayPeriodFC.value]" class="col-2 unedit-padding"
                    style="text-align: right;">
                    {{
                    hourlyHoursPerPayPeriodFC.value * payPeriodsById[hourlyPayPeriodFC.value].paychecks *
                    (employeeAllocationFC.value == 4 ?
                    estimatedPercentTimeOnContractFC.value / 100 : 1)
                    | number:'1.0-2'
                    }}
                </div>
            </div>
            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    Calculated Amount
                </div>
                <div *ngIf="salarySelected" class="col-2 unedit-padding" style="text-align: right;">
                    {{ salaryCalculatedAmount.calculatedAmount | currency }}
                </div>
                <div *ngIf="hourlySelected" class="col-2 unedit-padding" style="text-align: right;">
                    {{ hourlyCalculatedAmount.calculatedAmount | currency }}
                </div>
            </div>

            <hr />
            <div class="row">
                <div class="col">
                    <fa-icon [icon]="faStamp" class="mr-2" title="Total Allocation">
                    </fa-icon>
                    <h4 style="display: inline-block;">Total Allocation</h4>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <div class="py-2 pl-1"
                        style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;">
                        <div class="mx-2">
                            <app-input-money label="Amount Charged to Contract" [fc]="amountChargedToContractFC"
                                (keyup)="amountChargedToContractError = null" [uneditable]="!editable"
                                [commentId]="'amount_charged_to_contract'" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById"
                                (change)="round(amountChargedToContractFC)">
                            </app-input-money>
                            <app-validation-error [error]="amountChargedToContractError">
                            </app-validation-error>

                            <app-input-money label="State Only" [fc]="stateOnlyFC" [uneditable]="!editable"
                                [commentId]="'state_only'" [showFlag]="showFlag" [flagClick]="flagClick.bind(this)"
                                [submissionUuid]="submissionUuid" [targetUuid]="personnelEntryUuid"
                                [commentData]="inputDataById" (change)="round(stateOnlyFC)">
                            </app-input-money>
                            <app-validation-error [error]="stateOnlyError">
                            </app-validation-error>

                            <app-input-money label="State Match/Federal Match" [fc]="stateMatchFederalMatchFC"
                                [uneditable]="!editable" [commentId]="'state_match_federal_match'" [showFlag]="showFlag"
                                [flagClick]="flagClick.bind(this)" [submissionUuid]="submissionUuid"
                                [targetUuid]="personnelEntryUuid" [commentData]="inputDataById"
                                (change)="round(stateMatchFederalMatchFC)">
                            </app-input-money>
                            <app-validation-error [error]="stateMatchFederalMatchError">
                            </app-validation-error>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-4 pb-2 unedit-padding bold">
                </div>
                <div class="col-1 pb-2 unedit-padding bold" style="text-align: right;">
                    New
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 pb-2 unedit-padding bold" style="text-align: right;">
                    Previous 
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 pb-2 unedit-padding bold" style="text-align: right;">
                    Change
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-4 pb-2 unedit-padding bold">
                    State Only
                </div>
                <div class="col-1 unedit-padding" style="text-align: right;">
                    {{ stateOnlyFC.value || 0 | currency}} +
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ debitStateEst || 0 | currency}} +
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ stateOnlyFC.value - debitStateEst | currency }} +
                </div>
            </div>

            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    State Match
                </div>
                <div class="col-1 unedit-padding" style="text-align: right;">
                    {{ stateMatchFederalMatchFC.value / 2 | currency}} +
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ debitFederalEst / 2 | currency}} +
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ +stateMatchFederalMatchFC.value / 2 - +debitFederalEst / 2 | currency }} +
                </div>
            </div>

            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    Federal Match
                </div>
                <div class="col-1 unedit-padding" style="text-align: right;">
                    {{ stateMatchFederalMatchFC.value / 2 | currency}}
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ debitFederalEst / 2 | currency}}
                </div>
                <div *ngIf="submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ +stateMatchFederalMatchFC.value / 2 - +debitFederalEst / 2 | currency }}
                </div>
            </div>

            <div class="row">
                <div class="col-4">
                </div>
                <div class="col-1" style="border-style: solid; border-color: black; border-width: 1px 0 0 0;">
                </div>
                <div class="col-1">
                </div>
                <div *ngIf="submission.sequence != 0" class="col-1" style="border-style: solid; border-color: black; border-width: 1px 0 0 0;">
                </div>
                <div class="col-1">
                </div>
                <div *ngIf="submission.sequence != 0" class="col-1" style="border-style: solid; border-color: black; border-width: 1px 0 0 0;">
                </div>
            </div>

            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    Total Allocation
                </div>
                <div class="col-1 unedit-padding" style="text-align: right;">
                    {{ +stateOnlyFC.value + +stateMatchFederalMatchFC.value | currency}}
                </div>
                <div *ngIf=" submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ +debitStateEst + +debitFederalEst | currency}}
                </div>
                <div *ngIf=" submission.sequence != 0" class="col-2 unedit-padding" style="text-align: right;">
                    {{ stateMatchFederalMatchFC.value / 2 - debitFederalEst / 2 ++ stateMatchFederalMatchFC.value / 2 - debitFederalEst / 2 ++ stateOnlyFC.value - debitStateEst | currency }}
                </div>
            </div>

            <!-- <span *ngIf="showWorksheet"> showWorksheet boolean should be true only for Prime users HSNY-1689 -->
                <hr />
                <div class="row mt-2">
                    <div class="col">
                        <h4>Calculation Worksheet</h4>
                    </div>
                </div>
                <div *ngIf="salarySelected" class="py-2 pl-1"
                    style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;">
                    <div class="mx-2 row">
                        <div class="col">
                            Hourly Rate From Salary Calculation<br />
                            <span class="worksheet-hint">
                                annual salary / (number of paychecks * hours per pay period)<br />
                                {{salaryCalculatedAmount.annualSalary | number:'1.0-2'}}
                                /
                                ({{salaryCalculatedAmount.numberOfPaychecks |
                                number:'1.0-2'}}*{{salaryCalculatedAmount.hoursPerPayPeriod | number:'1.0-2'}})
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{salaryCalculatedAmount.hourlyRate | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            New Hourly Rate From New Salary Calculation<br />
                            <span class="worksheet-hint">
                                new annual salary / (number of paychecks * hours per pay period)<br />
                                {{salaryCalculatedAmount.newAnnualSalary | number:'1.0-2'}}
                                /
                                ({{salaryCalculatedAmount.numberOfPaychecks |
                                number:'1.0-2'}}*{{salaryCalculatedAmount.hoursPerPayPeriod | number:'1.0-2'}})
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{salaryCalculatedAmount.newHourlyRate | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Hours Per Day<br />
                            <span class="worksheet-hint">
                                hours per pay period * paychecks / 260<br />
                                {{salaryCalculatedAmount.hoursPerPayPeriod | number:'1.0-2'}}
                                *
                                ({{salaryCalculatedAmount.numberOfPaychecks | number:'1.0-2'}}/260)
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{salaryCalculatedAmount.hoursPerDay | number:'1.0-2'}} hours
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Pre Raise Amount<br />
                            <span class="worksheet-hint">
                                (business days between fiscal start and raise day + 1) * hours per day * pre raise
                                hourly
                                rate<br />
                                (weekdaysSince({{salaryCalculatedAmount.fiscalStart | date : 'M/d/y'}},
                                {{salaryCalculatedAmount.raiseDate | date : 'M/d/y'}}) + 1)
                                * {{salaryCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                * {{salaryCalculatedAmount.hourlyRate | number:'1.0-2'}}<br />
                                {{salaryCalculatedAmount.preDayCount | number:'1.0-2'}}
                                *
                                {{salaryCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                *
                                {{salaryCalculatedAmount.hourlyRate | number:'1.0-2'}}<br />
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{salaryCalculatedAmount.preRaiseAmount | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Post Raise Amount<br />
                            <span class="worksheet-hint">
                                (business days between raise date and fiscal end - 0) * hours per day * post raise
                                hourly
                                rate<br />
                                261 - (weekdaysSince({{salaryCalculatedAmount.fiscalStart | date : 'M/d/y'}},
                                {{salaryCalculatedAmount.raiseDate | date : 'M/d/y'}}) + 1)
                                * {{salaryCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                * {{salaryCalculatedAmount.hourlyRate | number:'1.0-2'}}<br />
                                {{salaryCalculatedAmount.postDayCount | number:'1.0-2'}}
                                *
                                {{salaryCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                *
                                {{salaryCalculatedAmount.newHourlyRate | number:'1.0-2'}}
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{salaryCalculatedAmount.postRaiseAmount | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Bonus
                        </div>
                        <div class="col-2" align="right">
                            {{salaryCalculatedAmount.bonus | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Calculated Amount<br />
                            <span class="worksheet-hint">
                                (Pre Raise Amount + Post Raise Amount) * estimated percent time on contract +
                                Bonus<br />
                                ({{salaryCalculatedAmount.preRaiseAmount | number:'1.0-2'}}
                                *
                                {{salaryCalculatedAmount.postRaiseAmount | number:'1.0-2'}})
                                *
                                {{salaryCalculatedAmount.estimatedPercentTimeOnContract | number:'1.0-2'}}
                                +
                                {{salaryCalculatedAmount.bonus | number:'1.0-2'}}
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{salaryCalculatedAmount.calculatedAmount | currency}}
                        </div>
                    </div>
                </div>

                <div *ngIf="hourlySelected" class="py-2 pl-1"
                    style="border-radius: .5em; border-style: solid; border-width: 2px; border-color: lightgrey;">
                    <div class="mx-2 row">
                        <div class="col">
                            Hourly Rate
                        </div>
                        <div class="col-2" align="right">
                            {{hourlyCalculatedAmount.hourlyRate | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            New Hourly Rate
                        </div>
                        <div class="col-2" align="right">
                            {{hourlyCalculatedAmount.newHourlyRate | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Hours Per Day<br />
                            <span class="worksheet-hint">
                                hours per pay period * paychecks / 260<br />
                                {{hourlyCalculatedAmount.hoursPerPayPeriod | number:'1.0-2'}}
                                *
                                ({{hourlyCalculatedAmount.numberOfPaychecks | number:'1.0-2'}}/260)
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{hourlyCalculatedAmount.hoursPerDay | number:'1.0-2'}} hours
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Pre Raise Amount<br />
                            <span class="worksheet-hint">
                                (business days between fiscal start and raise day + 1) * hours per day * pre raise
                                hourly
                                rate<br />
                                (weekdaysSince({{salaryCalculatedAmount.fiscalStart | date : 'M/d/y'}},
                                {{hourlyCalculatedAmount.raiseDate | date : 'M/d/y'}}) + 1)
                                * {{hourlyCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                * {{hourlyCalculatedAmount.hourlyRate | number:'1.0-2'}}<br />
                                {{hourlyCalculatedAmount.preDayCount | number:'1.0-2'}}
                                *
                                {{hourlyCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                *
                                {{hourlyCalculatedAmount.hourlyRate | number:'1.0-2'}}<br />
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{hourlyCalculatedAmount.preRaiseAmount | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Post Raise Amount<br />
                            <span class="worksheet-hint">
                                (business days between raise date and fiscal end - 0) * hours per day * post raise
                                hourly
                                rate<br />
                                261 - (weekdaysSince({{hourlyCalculatedAmount.fiscalStart | date : 'M/d/y'}},
                                {{hourlyCalculatedAmount.raiseDate | date : 'M/d/y'}}) + 1)
                                * {{hourlyCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                * {{hourlyCalculatedAmount.hourlyRate | number:'1.0-2'}}<br />
                                {{hourlyCalculatedAmount.postDayCount | number:'1.0-2'}}
                                *
                                {{hourlyCalculatedAmount.hoursPerDay | number:'1.0-2'}}
                                *
                                {{hourlyCalculatedAmount.newHourlyRate | number:'1.0-2'}}
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{hourlyCalculatedAmount.postRaiseAmount | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Bonus
                        </div>
                        <div class="col-2" align="right">
                            {{hourlyCalculatedAmount.bonus | currency}}
                        </div>
                    </div>
                    <hr />
                    <div class="mx-2 row">
                        <div class="col">
                            Calculated Amount<br />
                            <span class="worksheet-hint">
                                (Pre Raise Amount + Post Raise Amount) * estimated percent time on contract +
                                Bonus<br />
                                ({{hourlyCalculatedAmount.preRaiseAmount | number:'1.0-2'}}
                                *
                                {{hourlyCalculatedAmount.postRaiseAmount | number:'1.0-2'}})
                                *
                                {{hourlyCalculatedAmount.estimatedPercentTimeOnContract | number:'1.0-2'}}
                                +
                                {{hourlyCalculatedAmount.bonus | number:'1.0-2'}}
                            </span>
                        </div>
                        <div class="col-2" align="right">
                            {{hourlyCalculatedAmount.calculatedAmount | currency}}
                        </div>
                    </div>
                </div>

            <!--</span>-->

            <hr />
            <span *ngIf="!hideFringe">
                <div class="row mt-2">
                    <div class="col">
                        <fa-icon [icon]="faThumbsUp" class="mr-2" title="Fringe">
                        </fa-icon>
                        <h4 style="display: inline-block;">Fringe</h4>
                    </div>
                </div>

                <div class="py-2 pl-1">
                    <div class="px-2">
                        <div class="row">
                            <table class="table-borderless grey-bordered"
                                style="border-collapse: separate; min-width: 100%;" cellspacing="0" cellpadding="4">
                                <thead align="center" style="font-size: .8em;">
                                    <th style="vertical-align: top;">
                                        Benefit
                                    </th>
                                    <th style="vertical-align: top;">
                                        Amount
                                    </th>
                                    <th style="vertical-align: top;">
                                        Percent
                                    </th>
                                    <th style="vertical-align: top;">

                                    </th>
                                    <th style="vertical-align: top;">
                                        Calculated Amount
                                    </th>
                                    <th style="vertical-align: top;">
                                        Amount Charged to Contract
                                    </th>
                                    <th style="vertical-align: top;">
                                        State Only
                                    </th>
                                    <th style="vertical-align: top;">
                                        State/ Federal Match
                                    </th>
                                    <th style="vertical-align: top;">
                                        State Only<br />+
                                    </th>
                                    <th style="vertical-align: top;">
                                        State Match<br />+
                                    </th>
                                    <th style="vertical-align: top;">
                                        Federal Match<br />+
                                    </th>
                                    <th style="vertical-align: top;">
                                        = Total Charged to Contract
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let benefitTableEntry of fixedFringeTableEntries"
                                        [ngClass]="{'odd-row': benefitTableEntry.odd}">
                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry">
                                            {{benefitTableEntry.entry.name}}
                                            <fa-icon *ngIf="showFlag" [icon]="faFlag" class="flag ml-2"
                                                (click)="flagClick('hsny_comment_' + benefitTableEntry.entry.account_uuid)"
                                                [title]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].showInputContainer.value ? 'Cancel' : 'Edit'">
                                            </fa-icon>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry && (salarySelected || (!salarySelected && !hourlySelected))"
                                            style="font-size: .8em; text-align: right;">
                                            {{salaryCalculatedAmount.calculatedAmount | number:'1.0-2'}}
                                        </td>
                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry && hourlySelected"
                                            style="font-size: .8em; text-align: right;">
                                            {{hourlyCalculatedAmount.calculatedAmount | number:'1.0-2'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.percentAllocationFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)" />
                                            <span
                                                *ngIf="!editable">{{benefitTableEntry.entry.percentAllocationFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;">
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry && salarySelected"
                                            style="font-size: .8em; text-align: right;">
                                            {{salaryCalculatedAmount.calculatedAmount *
                                            benefitTableEntry.entry.percentAllocationFC.value / 100 | number:'1.0-0'}}
                                        </td>
                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry && hourlySelected"
                                            style="font-size: .8em; text-align: right;">
                                            {{hourlyCalculatedAmount.calculatedAmount *
                                            benefitTableEntry.entry.percentAllocationFC.value / 100 | number:'1.0-0'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.unitPriceFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)"
                                                (change)="round(benefitTableEntry.entry.unitPriceFC)" />
                                            <span *ngIf="!editable">{{benefitTableEntry.entry.unitPriceFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.stateFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)"
                                                (change)="round(benefitTableEntry.entry.stateFC)" />
                                            <span *ngIf="!editable">{{benefitTableEntry.entry.stateFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.federalFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)"
                                                (change)="round(benefitTableEntry.entry.federalFC)" />
                                            <span *ngIf="!editable">{{benefitTableEntry.entry.federalFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.stateFC.value | number:'1.0-2'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.federalFC.value / 2 | number:'1.0-2'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.federalFC.value / 2 | number:'1.0-2'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{ benefitTableEntry.entry.stateFC.value * 1
                                            + benefitTableEntry.entry.federalFC.value * 1 | number:'1.0-2'}}
                                        </td>

                                        <td colspan="12" *ngIf="benefitTableEntry.type == fringeTableEntryType.comment">
                                            <app-outreach-submission-comment [submissionUuid]="submissionUuid"
                                                [inputId]="'hsny_comment_' + benefitTableEntry.entry.account_uuid"
                                                [commentUuid]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].commentUuid"
                                                [comment]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].comment"
                                                [showInputContainer]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].showInputContainer"
                                                [showFlag]="showFlag" [odd]="false" [targetUuid]="personnelEntryUuid"
                                                [showHSNYLabel]="false">
                                            </app-outreach-submission-comment>

                                            <app-budget-agency-comment
                                                [commentFC]="benefitTableEntry.entry.agencyCommentFC"
                                                [organizationType]="this.userOrganizationType"
                                                [prime_action]="this.submission.prime_action"
                                                [sub_action]="this.submission.sub_action"
                                                [submission_status]="this.submission_status"
                                            >
                                            </app-budget-agency-comment>
                                        </td>

                                        <td colspan="12" *ngIf="benefitTableEntry.type == fringeTableEntryType.error"
                                            align="right">
                                            <span *ngIf="benefitTableEntry.error"
                                                style="color: red; font-size: .8em; font-weight: bold;">{{benefitTableEntry.error}}</span>
                                        </td>
                                    </tr>
                                </tbody>

                                <thead align="center" style="font-size: .8em;">
                                    <th style="vertical-align: top;">
                                        Benefit
                                    </th>
                                    <th style="vertical-align: top;">
                                        Annual Premium
                                    </th>
                                    <th style="vertical-align: top;">
                                        Employer %
                                    </th>
                                    <th style="vertical-align: top;">
                                        Employee %
                                    </th>
                                    <th style="vertical-align: top;">
                                        Calculated Amount ({{estimatedPercentTimeOnContractFC.value}}% on contract)
                                    </th>
                                    <th style="vertical-align: top;">
                                        Amount Charged to Contract
                                    </th>
                                    <th style="vertical-align: top;">
                                        State Only
                                    </th>
                                    <th style="vertical-align: top;">
                                        State/ Federal Match
                                    </th>
                                    <th style="vertical-align: top;">
                                        State Only<br />+
                                    </th>
                                    <th style="vertical-align: top;">
                                        State Match<br />+
                                    </th>
                                    <th style="vertical-align: top;">
                                        Federal Match<br />+
                                    </th>
                                    <th style="vertical-align: top;">
                                        = Total Charged to Contract
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let benefitTableEntry of controllableFringeTableEntries"
                                        [ngClass]="{'odd-row': benefitTableEntry.odd}">

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry">
                                            {{benefitTableEntry.entry.name}}
                                            <fa-icon *ngIf="benefitTableEntry.entry.account_uuid && showFlag" [icon]="faFlag" class="flag ml-2"
                                                (click)="flagClick('hsny_comment_' + benefitTableEntry.entry.account_uuid)"
                                                [title]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].showInputContainer.value ? 'Cancel' : 'Edit'">
                                            </fa-icon>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.fringeAmountFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)"
                                                (change)="round(benefitTableEntry.entry.fringeAmountFC)" />
                                            <span
                                                *ngIf="!editable">{{benefitTableEntry.entry.fringeAmountFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.percentAllocationFC"
                                                (keyup)="fixedBenefitPercentChange(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)" />
                                            <span
                                                *ngIf="!editable">{{benefitTableEntry.entry.percentAllocationFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.employeePercentFC"
                                                (keyup)="fixedBenefitEmployeePercentChange(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)" />
                                            <span
                                                *ngIf="!editable">{{benefitTableEntry.entry.employeePercentFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry && salarySelected"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.fringeAmountFC.value
                                            * estimatedPercentTimeOnContractFC.value / 100
                                            * benefitTableEntry.entry.percentAllocationFC.value / 100 | number:'1.0-0'}}
                                        </td>
                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry && hourlySelected"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.fringeAmountFC.value
                                            * estimatedPercentTimeOnContractFC.value / 100
                                            * benefitTableEntry.entry.percentAllocationFC.value / 100 | number:'1.0-0'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.unitPriceFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)" />
                                            <span *ngIf="!editable">{{benefitTableEntry.entry.unitPriceFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.stateFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)" />
                                            <span *ngIf="!editable">{{benefitTableEntry.entry.stateFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em;" align="center">
                                            <input *ngIf="editable" type="text"
                                                style="border-color: lightgrey; width: 4.5em; text-align: right;"
                                                class="mt-1" [formControl]="benefitTableEntry.entry.federalFC"
                                                (keyup)="setFringeTotals(benefitTableEntry.entry, salaryCalculatedAmount, hourlyCalculatedAmount)" />
                                            <span *ngIf="!editable">{{benefitTableEntry.entry.federalFC.value}}</span>
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.stateFC.value | number:'1.0-2'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.federalFC.value / 2 | number:'1.0-2'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{benefitTableEntry.entry.federalFC.value / 2 | number:'1.0-2'}}
                                        </td>

                                        <td *ngIf="benefitTableEntry.type == fringeTableEntryType.entry"
                                            style="font-size: .8em; text-align: right;">
                                            {{ benefitTableEntry.entry.stateFC.value * 1
                                            + benefitTableEntry.entry.federalFC.value * 1 | number:'1.0-2'}}
                                        </td>
                                        <td colspan="12"
                                         *ngIf="benefitTableEntry.type == fringeTableEntryType.comment">

                                            <app-outreach-submission-comment [submissionUuid]="submissionUuid"
                                                [inputId]="'hsny_comment_' + benefitTableEntry.entry.account_uuid"
                                                [commentUuid]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].commentUuid"
                                                [comment]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].comment"
                                                [showInputContainer]="inputDataById['hsny_comment_' + benefitTableEntry.entry.account_uuid].showInputContainer"
                                                [showFlag]="showFlag" [odd]="false" [targetUuid]="personnelEntryUuid"
                                                [showHSNYLabel]="false">
                                            </app-outreach-submission-comment>

                                            <app-budget-agency-comment
                                                [commentFC]="benefitTableEntry.entry.agencyCommentFC"
                                                [organizationType]="this.userOrganizationType"
                                                [prime_action]="this.submission.prime_action"
                                                [sub_action]="this.submission.sub_action"
                                                [submission_status]="this.submission_status"
                                            >
                                            </app-budget-agency-comment>
                                        </td>

                                        <td colspan="12" *ngIf="benefitTableEntry.type == fringeTableEntryType.error"
                                            align="right">
                                            <span *ngIf="benefitTableEntry.error"
                                                style="color: red; font-size: .8em; font-weight: bold;">{{benefitTableEntry.error}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </span>

            <div *ngIf="this.userOrganizationType == 'PRIME' || this.userOrganizationType == 'SYSTEM'" class="row mt-2">
                <div class="col bold data-row" align="center">
                    <app-checkbox [id]="'user_flag'" [fc]="userFlagFC" [change]="userFlagChange.bind(this)">
                    </app-checkbox>
                    <label [for]="'user_flag'" class="ml-1 pointer">
                        <!--Apply user flag to record (HSNY only)-->
                        Apply user flag to record (HSNY use only – line will be highlighted on summary page)
                    </label>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col bold" style="color: red; font-size: .8em;" align="center">
                    {{saveError}} &nbsp;
                </div>
            </div>
            <div *ngIf="editable" class="mt-2 mb-3" style="width: 100%;" align="center">
                <app-button3 *ngIf="!newEntry" class="mx-1" label="Delete" [clickFunction]="delete.bind(this)"
                    [icon]="faMinus" [isDelete]="true">
                </app-button3>
                <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                </app-button3>
                <app-button3 class="mx-1" label="Save"
                    [clickFunction]="save.bind(this, salaryCalculatedAmount, hourlyCalculatedAmount)" [icon]="faSave"
                    [isFinal]="true">
                </app-button3>
            </div>

            <div *ngIf="!editable && !hideButtons" class="mt-2 mb-3" style="width: 100%;" align="center">
                <app-button3 class="mx-1" label="Ok" [clickFunction]="ok.bind(this)" [icon]="faCheck">
                </app-button3>
            </div>
        </span>
    </span>
</div>