<app-button3 *ngIf="showCreateButton" class="mx-1" label="Add Comment" [small]="true"
    [clickFunction]="showCommentInput.bind(this)" [icon]="faEdit">
</app-button3>
<span *ngIf="!showCreateButton">
    <span *ngIf="editable">
        Agency Comment:&nbsp;
        <textarea [rows]="1" [formControl]="commentFC" class="ml-2"
            style="vertical-align: top; resize: both; border-radius: .3em; width: 16em; border-color: lightgrey;">
        </textarea>
    </span>

    <span *ngIf="!editable && commentFC.value">
        Agency Comment:&nbsp;
        {{commentFC.value}}
    </span>
</span>