import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs"

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class FiscalYearService {

  constructor(
    private http: HttpClient
  ) { }

  // get fiscal year options for dropdowns
  getFiscalYearOptions(): Observable<any> {
    return this.http.get<any>('api/fiscalYearOptions')
  }
  
  // get fiscal year
  getFiscalYear(fiscalYearId: string): Observable<any> {
    return this.http.get<any>('api/getFiscalYear/' + fiscalYearId)
  }

  // set fiscal year
  setFiscalYear(): Observable<any> {
    return this.http.get<any>('api/setFiscalYear')
  }
}