import { Component, OnInit } from '@angular/core';
import { SystemService } from '../system.service';

@Component({
    selector: 'app-system-error',
    templateUrl: './system-error.component.html',
})
export class SystemErrorComponent implements OnInit {

    constructor(
        private _systemService: SystemService,
    ) { }

    ngOnInit() {
    }

    get systemService() {
        return this._systemService;
    }
}
