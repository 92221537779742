import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SubmissionService } from '../submission.service'


@Component({
    selector: 'attachment-comment',
    templateUrl: './attachment-comment.component.html',
})
export class AttachmentCommentComponent implements OnInit {
    form!: UntypedFormGroup
    attachment_uuid: string
    comment: string
    saveError = false
    voucherUuid?: string

	constructor(
        private dialog: MatDialog,
        private submissionService: SubmissionService,
        @Inject(MAT_DIALOG_DATA) public data: any) 
        {
            this.attachment_uuid = data.attachment_uuid
            this.comment = data.comment
            this.voucherUuid = data.voucherUuid
        }


    close() {
        this.dialog.closeAll()
    }

    saveComment() {
        this.comment = this.form.value.comment
        console.log(this.attachment_uuid, this.comment)

        this.submissionService.attachmentComment(this.attachment_uuid, this.comment, this.voucherUuid).subscribe((result) => {
            if(result.success === true) {
                this.close()
            }
        })
    }

    deleteComment() {
        this.submissionService.deleteAttachmentComment(this.attachment_uuid, this.voucherUuid).subscribe((result) => {
            if(result.success === true) {
                this.close()
            }
        })
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            comment: new UntypedFormControl(this.comment, [])
        })
    }
}
