import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { iOutreachBankHead } from '../model/outreach-bank-head.model';
import { FiscalYearService } from '../services/fiscal-year.service';
import { SessionService } from '../session.service';
import { OutreachBankService } from '../services/outreach-bank-service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { filter } from 'rxjs';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-outreach-bank-account',
  templateUrl: './outreach-bank-account.component.html',
  styleUrl: './outreach-bank-account.component.css'
})
export class OutreachBankAccountComponent implements OnInit, AfterViewInit
{
  filterForm: FormGroup;
  bankAccounts: MatTableDataSource<iOutreachBankHead> = new MatTableDataSource<iOutreachBankHead>([])
  fiscalYearOptions: [];
  fiscalYear: string;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = 
  [
    'fiscalYear',
    'organizationName',
    'debitState',
    'debitFederal'
  ]


  constructor
  (
    private fiscalYearService: FiscalYearService,
    private sessionService: SessionService,
    private outreachBankService: OutreachBankService,
    private router: Router
  ){}

  getFiscalYearOptions() {
    this.fiscalYearService.getFiscalYearOptions().subscribe({
      next: (results) => {
        this.fiscalYearOptions = results.result.filter((option: any) => option.id !== 'NONE');
      },
      error: (error) => {
        console.error('Error fetching fiscal year options:', error);
      },
      complete: () => {
        console.log('Fiscal year options fetched successfully.');
      }
    });
  }

  getBankAccounts()
  {
    this.outreachBankService.getbankHead().subscribe({
      next: (results) => {
        console.log(results)
        this.bankAccounts.data = results;
      },
      error: (error) => {
        console.error('Error fetching bank account data: ', error);
      },
      complete: () => {
        console.log('Bank Account data fetched successfully');
      }
    })
  }

  initForm(): void
  {
    this.filterForm = new FormGroup
    ({
      selectedFiscalYear: new FormControl(this.fiscalYear),
      filter: new FormControl('')
    })
  }

  applyFilter(): void {
    let filterValue;
    const selectedFiscalYear = this.fiscalYear;
    if (this.filterForm) {
      filterValue = this.filterForm.get('filter').value.trim().toLowerCase();
    }
  
    if (filterValue && selectedFiscalYear) 
    {
      console.log('filtering with both')
      this.setFilterPredicateBoth(filterValue, selectedFiscalYear);
    } else if (selectedFiscalYear) 
    {
      console.log('filtering with fiscal year')
      this.setFilterPredicateFiscalYear(selectedFiscalYear);
    } else 
    {
      this.bankAccounts.filter = '';
    }
  }
  
  // Function to apply filter based on both filter value and selected fiscal year
  private setFilterPredicateBoth(filterValue: string, selectedFiscalYear: string): void {
    selectedFiscalYear = selectedFiscalYear.trim().toLowerCase();
  
    this.bankAccounts.filterPredicate = (data: iOutreachBankHead) => {
      console.log('Data:', data);
      console.log('Filter value:', filterValue);
      console.log('Selected fiscal year:', selectedFiscalYear);
      const matchesFilter = this.displayedColumns.some(column => {
        const value = data[column] ? data[column].toString().toLowerCase() : '';
        return value.includes(filterValue);
      });
  
      const fiscalYearMatch = data['fiscalYearId'].toString().toLowerCase().includes(selectedFiscalYear);
      return matchesFilter && fiscalYearMatch;
    };
    this.bankAccounts.filter = filterValue;
  }
  
  
  // Function to apply filter based only on selected fiscal year
  private setFilterPredicateFiscalYear(selectedFiscalYear: string): void 
  {
    selectedFiscalYear = selectedFiscalYear.trim().toLowerCase();
  
    this.bankAccounts.filterPredicate = (data: iOutreachBankHead) => {
      return data.fiscalYearId === selectedFiscalYear;
    };
    this.bankAccounts.filter = selectedFiscalYear;
  } 
  
  viewBankAccountReport(organizationUuid: string)
  {
    const fiscalYearId = this.fiscalYear
    this.router.navigate(['/outreach-bank-account-report'], { queryParams: { organizationUuid, fiscalYearId} })
  }

  ngOnInit(): void 
  {
    this.fiscalYear = this.sessionService.getFiscalYear();
    this.getFiscalYearOptions();
    this.getBankAccounts();
    this.initForm();
    this.applyFilter();

    this.filterForm.get('filter').valueChanges.subscribe(value => {
      this.applyFilter();
    });
    this.filterForm.get('selectedFiscalYear').valueChanges.subscribe({
      next: (value) => {
        this.fiscalYear = value;
        this.sessionService.setFiscalYear(value);
        this.applyFilter();
      }
    })
  }

  ngAfterViewInit() {
    this.bankAccounts.sort = this.sort;
  }
}
