import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { iOrganization } from './model/organization.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class AgencyService {
    agencyFilterItemName = "noep_agencyFilterValue"

    setAgencyFilter(value) {
        if (value == null) {
            localStorage.removeItem(this.agencyFilterItemName)
            return
        }
        localStorage.setItem(this.agencyFilterItemName, value)
    }

    get agencyFilter() {
        return localStorage.getItem(this.agencyFilterItemName)
    }

    constructor(
        private http: HttpClient,
    ) { }

    addAgency(
        name,
        shortName,
        address,
        mbeCertified,
        mbeDate,
        wbeCertified,
        wbeDate,
        organization_status_id,
        logo,
        programs,
    ): Observable<any> {
        return this.http.put<any>("api/agency", {
            name,
            shortName,
            address,
            mbeCertified,
            mbeDate,
            wbeCertified,
            wbeDate,
            organization_status_id,
            logo,
            programs,
        }, httpOptions)
    }

    updateAgency(
        uuid,
        name,
        shortName,
        address,
        mbeCertified,
        mbeDate,
        wbeCertified,
        wbeDate,
        organization_status_id,
        logo,
        programs,
    ): Observable<any> {
        return this.http.post<any>("api/agency", {
            uuid,
            name,
            shortName,
            address,
            mbeCertified,
            mbeDate,
            wbeCertified,
            wbeDate,
            organization_status_id,
            logo,
            programs,
        }, httpOptions)
    }

    getAllAgenciesUsers(): Observable<any> {
        return this.http.get<any>("api/getAllAgenciesAndUsers")
    }

    getAgency(agencyUuid): Observable<any> {
        return this.http.get<any>("api/agency/" + agencyUuid)
    }

    getAgencyType(agencyUuid): Observable<any> {
        return this.http.get<any>("api/agency/" + agencyUuid)
    }

    getViewableAgencies(): Observable<any> {
        return this.http.get<any>("api/getViewableAgencies/")
    }

    getAgenciesAndUsersByAgencyUuid(agencyUuid): Observable<any> {
        return this.http.get<any>("api/getAgenciesAndUsersByAgencyUuid/" + agencyUuid)
    }

    getAgenciesAndUsersByAgencyUuids(agencyUuids): Observable<any> {
        return this.http.post<any>("api/getAgenciesAndUsersByAgencyUuids", { agencyUuids }, httpOptions)
    }

    getPrograms(): Observable<any> {
        return this.http.get<any>("api/getPrograms/")
    }

    getProgramsWithAll(): Observable<any> {
        return this.http.get<any>("api/getProgramsWithAll/")
    }

    getCounties(): Observable<any> {
        return this.http.get<any>("api/getCounties/")
    }

    getCountiesForOrganization(organization_uuid): Observable<any> {
        return this.http.get<any>("api/getCountiesForOrganization/" + organization_uuid)
    }

    agenciesByUser(userUuid): Observable<any> {
        return this.http.get<any>("api/agenciesByUser/" + userUuid)
    }

    getAgencyStatuses(): Observable<any> {
        return this.http.get<any>("api/agencyStatuses/")
    }

    getAgencyPayPeriods(): Observable<any> {
        return this.http.get<any>("api/agencyPayPeriods/")
    }

    // aarons code starts here

    getAgencies(userUuid: string): Observable<any>
    {
        return this.http.get<any>('api/agencies/' + userUuid)
    }

    getAllOrganizations(): Observable<iOrganization[]>
    {
        return this.http.get<iOrganization[]>('api/allOrganizations')
    }
}
