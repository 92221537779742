<div *ngIf="budgetAppropriation$ | async as budgetAppropriation" class="container-fluid">
    <div class="row page-title-no-font">
        <div class="col">
            <span class="page-title-font">
                <fa-icon [icon]="faFileInvoiceDollar"></fa-icon>
                Budget Appropriation
            </span>
            <a routerLink="/submission-schedule">
                <app-button3 style="float: right;" class="mx-1" label="Back" [icon]="faLongArrowAltLeft" [small]="true">
                </app-button3>
            </a>
        </div>
    </div>

    <!-- old table style
    <div class="row mt-3">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%;">
                <tbody>
                    <tr class="table-row">
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Program</span>
                            <span class="top-value">{{budgetAppropriation[0].program_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Type</span>
                            <span class="top-value">{{budgetAppropriation[0].submission_type_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Submission</span>
                            <span class="top-value">{{budgetAppropriation[0].fiscal_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Status</span>
                            <span class="top-value">{{budgetAppropriation[0].schedule_status_name}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    -->
    <div class="row mt-3">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%;">
                <tbody>
                    <tr class="table-row">
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Program</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Type</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Submission</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Status</span>
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="px-1">
                            <span class="top-value">{{budgetAppropriation[0].program_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="top-value">{{budgetAppropriation[0].submission_type_name}}</span>
                        </td>
                        <td class="px-1" style="overflow: auto;">
                            <span class="top-value">{{budgetAppropriation[0].fiscal_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="top-value">{{budgetAppropriation[0].schedule_status_name}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%">
                <thead>
                    <tr style="background-color: lightgrey">
                        <th *ngFor="let column of columns; let index = index"
                            [ngStyle]="{'text-align': index == 0 ? '' : 'right'}">
                            <span class="sort-column-header">
                                {{column.label}}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let entry of budgetAppropriation">
                        <td class="px-1">
                            {{entry.organization_name}}
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <span *ngIf="canEdit">
                                $ <input type="text" [formControl]="entry.federalFC" style="text-align: right;" />
                            </span>
                            <span *ngIf="!canEdit">
                                {{entry.federal | currency}}
                            </span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <span *ngIf="canEdit">
                                $ <input type="text" [formControl]="entry.stateFC" style="text-align: right;" />
                            </span>
                            <span *ngIf="!canEdit">
                                {{entry.state | currency}}
                            </span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            {{entry.total | currency}}
                        </td>
                    </tr>
                    <tr class="table-row">
                        <td class="px-1">
                            Grand Total
                        </td>
                        <td class="px-1" style="text-align: right;">
                            {{ federalGrandTotal | currency }}
                        </td>
                        <td class="px-1" style="text-align: right;">
                            {{ stateGrandTotal | currency }}
                        </td>
                        <td class="px-1" style="text-align: right;">
                            {{ grandGrandTotal | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="canEdit" class="row">
        <div class="col">
            <a routerLink="/submission-schedule">
                <app-button3 class="mx-2" label="Cancel" [icon]="faBan">
                </app-button3>
            </a>
            <app-button3 class="mx-2" style="float:right;" label="Finish" (click)="finish(budgetAppropriation)"
                [icon]="faFlagCheckered" [isFinal]="true">
            </app-button3>
            <app-button3 class="mx-2" style="float:right;" label="Save for Later"
                (click)="saveForLater(budgetAppropriation)" [icon]="faSave" [isFinal]="true">
            </app-button3>
        </div>
    </div>
    <div *ngIf="!canEdit" class="row">
        <div class="col" align="center">
            <a routerLink="/submission-schedule">
                <app-button3 class="mx-2" label="OK" [icon]="faCheck">
                </app-button3>
            </a>
        </div>
    </div>
</div>