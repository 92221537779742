import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFileInvoiceDollar, faPrint } from '@fortawesome/free-solid-svg-icons';
import { of } from 'rxjs';
import { combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { DashboardService } from '../dashboard.service';
import { SubmissionAttachmentsComponent } from '../submission-attachments/submission-attachments.component';
import { SubmissionService } from '../submission.service';

@Component({
    selector: 'app-voucher-summary',
    templateUrl: './voucher-summary.component.html',
})
export class VoucherSummaryComponent implements OnInit {

    @ViewChild(SubmissionAttachmentsComponent) private _attachmentsComponent: SubmissionAttachmentsComponent;
    initialized = false
    paramSubscription
    submissionUuid
    submissionActivityFeed$
    submissionHistory$
    submission$
    submissionStatuses$
    faPrint = faPrint
    editable$
    editable = true
    showFlag$
    faFileInvoiceDollar = faFileInvoiceDollar
    fiscalUuid: string

    constructor(
        private activatedRoute: ActivatedRoute,
        private submissionService: SubmissionService,
        private dashboardService: DashboardService,
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    get attachmentsComponent() {
        return this._attachmentsComponent
    }

    fiscalYearId: string
    paramPromise() {
        return new Promise((resolve, reject) => {
            this.paramSubscription = this.activatedRoute.queryParams.subscribe(params => {
                this.submissionUuid = params['submissionUuid']
                this.fiscalYearId = params['fiscalYearId']
                resolve(null)
            })
        })
    }

    dd: Date
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if(params['dd']) {
                this.dd = params['dd']
            }
        })

        this.paramPromise().then(() => {
            this.submissionActivityFeed$ = this.submissionService.fetchFeedAndAppendImagesToDom(this.submissionUuid)
            this.submissionHistory$ = this.submissionService.fetchSubmissionHistory(this.submissionUuid)
            this.submission$ = this.submissionService.fetchSubmissionHeaders(this.submissionUuid).pipe(shareReplay(1))
            this.submissionStatuses$ = this.dashboardService.getStatusesById()
            // this.editable$ = combineLatest(this.submission$,
            //     this.authService.getUserOrganizationType(),
            // ).pipe(switchMap(([submission, orgTypeResponse]: [any, any]) => {
            //     //console.log("editable return")//why doesn't this print?
            //     return of(orgTypeResponse.result == "SUB" && submission.sub_action == 'EDIT')
            // }))

            this.showFlag$ = combineLatest(this.submission$,
                this.authService.getUserOrganizationType(),
            ).pipe(
                switchMap(([submission, orgTypeResponse]: [any, any]) => {
                    return of((orgTypeResponse.result == "PRIME" || orgTypeResponse.result == "SYSTEM")
                        && submission.prime_action == 'AUDIT')
                })
            )
            this.submission$.subscribe((result: any) => {
                console.log('fyid find', result)
                this.fiscalUuid = result.fiscal_uuid
            },(error: any) => {
                console.log(error)
            })
            this.initialized = true
        })
    }

    ngOnDestroy() {
        this.paramSubscription.unsubscribe()
    }

    print() {
        this.router.navigate(['voucher-report'], { queryParams: { submissionUuid: this.submissionUuid } })    }

    printDetail() {
        const url = this.router.serializeUrl(this.router.createUrlTree(['voucher-detail'], {
            queryParams: {
                submissionUuid: this.submissionUuid, 
                fiscalYearId: this.fiscalYearId
            }
        }))
        window.open(url, '_blank')
    }

}
