import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { iTrendReportQtr } from '../model/trend-report-qtr.model'; // Assuming you have this model
import { iPrimengColumn } from '../model/primeng-column.model';
import { iTotalsPerQuarter } from '../model/quartly-totals.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-trend-report-quarterly-table',
  templateUrl: './trend-report-quarterly-table.component.html',
  styleUrl: './trend-report-quarterly-table.component.css'
})
export class TrendReportQuarterlyTableComponent implements OnInit
{

  @Input() trendReportQtr: iTrendReportQtr[];

  stateOnlyTotalSpent: number = 0;
  stateFedTotalSpent: number = 0;
  stateOnlyBudget: number = 0;
  stateFedBudget: number = 0;
  stateOnlyTotalPercent: number = 0;
  stateFedTotalPercent: number = 0;
  totalPercent: number = 0;
  stateOnlyRemainingBudget: number = 0;
  stateFedRemainingBudget: number = 0;
  quarterlyTotals: iTotalsPerQuarter = 
  {
    stateOnly: { q1: 0, q2: 0, q3: 0, q4: 0 },
    stateFed: { q1: 0, q2: 0, q3: 0, q4: 0 }
  };

  trendReportColumns: iPrimengColumn[] = [
    { header: 'Account', field: 'account' },
    { header: 'Dollar Type', field: 'fundingType' },
    { header: 'Q1', field: 'q1' },
    { header: 'Q2', field: 'q2' },
    { header: 'Q3', field: 'q3' },
    { header: 'Q4', field: 'q4' },
    { header: 'Budget', field: 'budget' }
  ];
  @ViewChild('dt') dataTable!: Table;

  ngOnInit() 
  {
    this.calculateQuarterlyTotals();
    this.calculateBudgetsForFundingTypes(this.trendReportQtr);
    this.calculateTotalSpentByFundingType(this.trendReportQtr);
    this.calculateRemainingBudgetByFundingType(this.trendReportQtr);
    this.calculateTotalPercentageSpentByFundingType(this.trendReportQtr)
  }

  isNumber(value: any): boolean
  {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  calculateTotalSpent(data: iTrendReportQtr): number
  {
    const quarters = [data.q1, data.q2, data.q3, data.q4];
    return quarters.reduce((total, quarter) => total + Number(quarter), 0);
  }

  calculateTotalSpentByFundingType(dataArray: iTrendReportQtr[])
  {
    this.stateOnlyTotalSpent = 0;
    this.stateFedTotalSpent = 0;

    dataArray.forEach(data => {
      const quarters = [data.q1, data.q2, data.q3, data.q4];
      const totalSpent = quarters.reduce((total, quarter) => total + Number(quarter), 0);

      if (data.fundingType === 'State Only')
      {
        this.stateOnlyTotalSpent += totalSpent;
      } else if (data.fundingType === 'State/Fed')
      {
        this.stateFedTotalSpent += totalSpent;
      }
    });
  }

  calculatePercentageSpent(data: iTrendReportQtr): number
  {
    const budgetAmount = Number(data.budget);
    const totalSpent = this.calculateTotalSpent(data);
    return budgetAmount === 0 ? 0 : (totalSpent / budgetAmount) * 100;
  }

  calculateRemainingBudget(data: iTrendReportQtr): number
  {
    const budgetAmount = Number(data.budget);
    const totalSpent = this.calculateTotalSpent(data);
    return budgetAmount - totalSpent;
  }

  calculateBudgetsForFundingTypes(dataArray: iTrendReportQtr[])
  {
    dataArray.forEach(data => {
      const budget = isNaN(Number(data.budget)) ? 0 : Number(data.budget);

      if (data.fundingType === 'State Only')
      {
        this.stateOnlyBudget += budget;
      } else if (data.fundingType === 'State/Fed')
      {
        this.stateFedBudget += budget;
      }
    });
  }

  calculateTotalPercentageSpentByFundingType(dataArray: iTrendReportQtr[])
  {
    this.stateOnlyTotalPercent = 0;
    this.stateFedTotalPercent = 0;

    let totalStateOnlySpent = 0;
    let totalStateOnlyBudget = 0;
    let totalStateFedSpent = 0;
    let totalStateFedBudget = 0;

    dataArray.forEach(data => {
      const totalSpent = this.calculateTotalSpent(data);
      const budgetAmount = Number(data.budget);

      if (data.fundingType === 'State Only')
      {
        totalStateOnlySpent += totalSpent;
        totalStateOnlyBudget += budgetAmount;
      } else if (data.fundingType === 'State/Fed')
      {
        totalStateFedSpent += totalSpent;
        totalStateFedBudget += budgetAmount;
      }
    });

    this.stateOnlyTotalPercent = totalStateOnlyBudget === 0 ? 0 : (totalStateOnlySpent / totalStateOnlyBudget) * 100;
    this.stateFedTotalPercent = totalStateFedBudget === 0 ? 0 : (totalStateFedSpent / totalStateFedBudget) * 100;

    const totalSpent = totalStateOnlySpent + totalStateFedSpent;
    const totalBudget = totalStateOnlyBudget + totalStateFedBudget;
    this.totalPercent = totalBudget === 0 ? 0 : (totalSpent / totalBudget) * 100;
  }

  calculateRemainingBudgetByFundingType(dataArray: iTrendReportQtr[])
  {
    this.stateOnlyRemainingBudget = 0;
    this.stateFedRemainingBudget = 0;

    dataArray.forEach(data => {
      const remainingBudget = this.calculateRemainingBudget(data);

      if (data.fundingType === 'State Only')
      {
        this.stateOnlyRemainingBudget += remainingBudget;
      } else if (data.fundingType === 'State/Fed')
      {
        this.stateFedRemainingBudget += remainingBudget;
      }
    });
  }

  calculateQuarterlyTotals()
  {
    this.quarterlyTotals =
    {
      stateOnly: { q1: 0, q2: 0, q3: 0, q4: 0 },
      stateFed: { q1: 0, q2: 0, q3: 0, q4: 0 }
    };

    this.trendReportQtr.forEach((item) => {
      if (item.fundingType === 'State Only')
      {
        this.quarterlyTotals.stateOnly.q1 += Number(item.q1);
        this.quarterlyTotals.stateOnly.q2 += Number(item.q2);
        this.quarterlyTotals.stateOnly.q3 += Number(item.q3);
        this.quarterlyTotals.stateOnly.q4 += Number(item.q4);
      } else if (item.fundingType === 'State/Fed')
      {
        this.quarterlyTotals.stateFed.q1 += Number(item.q1);
        this.quarterlyTotals.stateFed.q2 += Number(item.q2);
        this.quarterlyTotals.stateFed.q3 += Number(item.q3);
        this.quarterlyTotals.stateFed.q4 += Number(item.q4);
      }
    });
  }

  exportToCSV()
  {
    this.dataTable.exportCSV();
  }
}