import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-document-search',
    templateUrl: './document-search.component.html',
})
export class DocumentSearchComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
