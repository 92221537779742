<div class="container-fluid">
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faBullseye"></fa-icon>
            Main Target 
            <span>({{ fiscalYear }})</span>
        </div>
    </div>
    <mat-table [dataSource]="mtDataSource">
        <ng-container matColumnDef="agency_name">
            <mat-header-cell *matHeaderCellDef> Agency </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.agency_name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="county_name">
            <mat-header-cell *matHeaderCellDef> County </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.county_name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="main_target">
            <mat-header-cell *matHeaderCellDef> Main Target</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.main_target}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="outreach_target">
            <mat-header-cell *matHeaderCellDef> Outreach Campaigns </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.outreach_target}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="person_target">
            <mat-header-cell *matHeaderCellDef> Direct Contacts </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.person_target}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="prescreen_target">
            <mat-header-cell *matHeaderCellDef> Prescreens </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.prescreen_target}} </mat-cell> 
        </ng-container>
        <ng-container matColumnDef="recertification_target">
            <mat-header-cell *matHeaderCellDef> Recertifications </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.recertification_target}} </mat-cell> 
        </ng-container>
        <ng-container matColumnDef="barrier_target">
            <mat-header-cell *matHeaderCellDef> Barrier Narratives </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.barrier_target}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="collaboration_target">
            <mat-header-cell *matHeaderCellDef> Collaboration Narratives </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.collaboration_target}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button (click)="editDialog(
                    row.uuid,
                    row.agency_name,
                    row.county_name,
                    row.county_id,
                    row.main_target,
                    row.outreach_target,
                    row.person_target,
                    row.prescreen_target,
                    row.recertification_target,
                    row.barrier_target,
                    row.collaboration_target
                )" 
                mat-raised-button color="primary"><fa-icon [icon]="faPencil"></fa-icon>&nbsp;Edit</button>
            </mat-cell> 
        </ng-container>


        
        <mat-header-row *matHeaderRowDef="
        [
        'agency_name', 
        'county_name',
        'main_target',
        'outreach_target',
        'person_target',
        'prescreen_target',
        'recertification_target',
        'barrier_target',
        'collaboration_target',
        'edit'
        ]
        ">
        </mat-header-row>
        <mat-row *matRowDef="
        let row; columns: [
        'agency_name', 
        'county_name',
        'main_target',
        'outreach_target',
        'person_target',
        'prescreen_target',
        'recertification_target',
        'barrier_target',
        'collaboration_target',
        'edit'
        ]
        ">
        </mat-row>
    </mat-table>
</div>
