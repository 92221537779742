<div class="container-fluid" *ngIf="!isODTA">
    <table style="min-width: 100%;" *ngIf="headerData">
        <tr style="font-weight: bold;">
            <td>Year:&nbsp;<span style="font-weight: normal;">{{ headerData.fiscal_year }}</span></td>
            <td>Agency:&nbsp;<span style="font-weight: normal;">{{ headerData.organization_name }}</span></td>
        </tr>
        <tr style="font-weight: bold;">
            <td>Main Target:&nbsp;<span style="font-weight: normal;">{{ headerData.main_target }}</span></td>
            <td>County:&nbsp;<span style="font-weight: normal;">{{ headerData.jurisdiction_name }}</span></td>
        </tr>

    </table>

    <div class="rot mt-3">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%">
                <thead>
                    <tr class="agency-table-header" style="text-align: right;">
                        <th class="px-1">
                        </th>
                        <th class="px-1">
                            Quarter 1
                        </th>
                        <th class="px-1">
                            Quarter 2
                        </th>
                        <th class="px-1">
                            Quarter 3
                        </th>
                        <th class="px-1">
                            Quarter 4
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row agency-table-row-title">
                        <td class="px-1">
                            Prescreening Information
                        </td>
                        <td class="px-1">
                        </td>
                        <td class="px-1">
                        </td>
                        <td class="px-1">
                        </td>
                        <td class="px-1">
                        </td>
                    </tr>
                    
                    <tr class="table-row" *ngFor="let t of tableData">
                        <td class="px-1" *ngIf="t.rowId == '1'">
                            {{ t.description }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '1'">
                            {{ t.q1 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '1'">
                            {{ t.q2 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '1'">
                            {{ t.q3 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '1'">
                            {{ t.q4 || "0" }}
                        </td>
                    </tr>

                    <tr class="table-row" *ngFor="let t of tableData">
                        <td class="px-1" *ngIf="t.rowId == '2'">
                            {{ t.description }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '2'">
                            {{ t.q1 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '2'">
                            {{ t.q2 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '2'">
                            {{ t.q3 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '2'">
                            {{ t.q4 || "0" }}
                        </td>
                    </tr>


                    <tr class="table-row agency-table-row-title">
                        <td class="px-1">
                            Tracking Information
                        </td>
                        <td class="px-1">
                        </td>
                        <td class="px-1">
                        </td>
                        <td class="px-1">
                        </td>
                        <td class="px-1">
                        </td>
                    </tr>


                    <tr class="table-row" *ngFor="let t of tableData">
                        <td class="px-1" *ngIf="t.rowId == '3'">
                            {{ t.description }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '3'">
                            {{ t.q1 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '3'">
                            {{ t.q2 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '3'">
                            {{ t.q3 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '3'">
                            {{ t.q4 || "0" }}
                        </td>
                    </tr>

                    <tr class="table-row" *ngFor="let t of tableData">
                        <td class="px-1" *ngIf="t.rowId == '4'">
                            {{ t.description }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '4'">
                            {{ t.q1 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '4'">
                            {{ t.q2 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '4'">
                            {{ t.q3 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '4'">
                            {{ t.q4 || "0" }}
                        </td>
                    </tr>

                    <tr class="table-row" *ngFor="let t of tableData">
                        <td class="px-1" *ngIf="t.rowId == '5'">
                            {{ t.description }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '5'">
                            {{ t.q1 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '5'">
                            {{ t.q2 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '5'">
                            {{ t.q3 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '5'">
                            {{ t.q4 || "0" }}
                        </td>
                    </tr>

                    <tr class="table-row" *ngFor="let t of tableData">
                        <td class="px-1" *ngIf="t.rowId == '6'">
                            {{ t.description }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '6'">
                            {{ t.q1 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '6'">
                            {{ t.q2 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '6'">
                            {{ t.q3 || "0" }}
                        </td>
                        <td class="px-1" align="right" *ngIf="t.rowId == '6'">
                            {{ t.q4 || "0" }}
                        </td>
                    </tr>

                    <!-- need to create custom variables in ts component to calculate for columns -->
                    <tr class="table-row agency-table-row-title">
                        <td class="px-1">
                            Number of Households Tracked
                        </td>
                        <td class="px-1" align="right">
                            {{ nhQ1 || "0" }}
                        </td>
                        <td class="px-1" align="right">
                            {{ nhQ2 || "0" }}
                        </td>
                        <td class="px-1" align="right">
                            {{ nhQ3 || "0" }}
                        </td>
                        <td class="px-1" align="right">
                            {{ nhQ4 || "0" }}
                        </td>
                    </tr>

                    <!-- has custom calculation in ts components -->
                    <tr class="table-row">
                        <td class="px-1">
                            Tracking Percentage (35%)
                        </td>
                        <td class="px-1" align="right">
                            {{ trackingPercentages['q1'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ trackingPercentages['q2'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ trackingPercentages['q3'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ trackingPercentages['q4'] | number: '1.2-2' | percent }}
                        </td>
                    </tr>

                    <!-- has custom calculation in ts component -->
                    <tr class="table-row">
                        <td class="px-1">
                            Cumulative Tracking Percentage
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeTrackingPercentages['q1'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeTrackingPercentages['q2'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeTrackingPercentages['q3'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeTrackingPercentages['q4'] | number: '1.2-2' | percent }}
                        </td>
                    </tr>

                    <!-- has custom calculation in ts component -->
                    <tr class="table-row">
                        <td class="px-1">
                            Quarterly Success Rate
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeSuccessRates['q1'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeSuccessRates['q2'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeSuccessRates['q3'] | number: '1.2-2' | percent }}
                        </td>
                        <td class="px-1" align="right">
                            {{ cumulativeSuccessRates['q4'] | number: '1.2-2' | percent }}
                        </td>
                    </tr>
                    <tr class="table-row agency-table-row-title">
                        <td class="px-1">
                            Main Target Result
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q1'] | number: '1.0-0' }}
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q2'] | number: '1.0-0' }}
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q3'] | number: '1.0-0' }}
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q4'] | number: '1.0-0' }}
                        </td>
                    </tr>
                </tbody>
            </table>
            
            <div class="row"></div>
            
            <table class="table table-striped table-hover" style="min-width: 100%">
                <thead>
                    <tr class="agency-table-header" style="text-align: right;">
                        <th class="px-1">
                        </th>
                        <th class="px-1">
                            Annual Target
                        </th>
                        <th class="px-1">
                            # Achieved to Date
                        </th>
                        <th class="px-1">
                            % Achieved to Date
                        </th>
                    </tr>
                </thead>
                
                <tr class="table-row" *ngFor="let t of ytdTableData">
                    <td class="px-1" >
                        {{ t.description }}
                    </td>
                    <td class="px-1" align="right">
                        {{ t.goal || "0"}}
                    </td>
                    <td class="px-1" align="right">
                        {{ t.value || "0" }}
                    </td> 
                    <td class="px-1" align="right">
                        {{ t.value / t.goal | percent }}
                    </td>
                </tr>

                <tr class="table-row agency-table-row-title">
                    <td class="px-1">
                        Main Target Result
                    </td>
                    <td class="px-1" align="right">
                        {{ mainTargetGoal }}
                    </td>
                    <td class="px-1" align="right">
                        {{ mainTargetResults['q1'] + mainTargetResults['q2'] + mainTargetResults['q3'] + mainTargetResults['q4'] | number: '1.0-0' }}
                    </td>
                    <td class="px-1" align="right">
                        {{ (mainTargetResults['q1'] + mainTargetResults['q2'] + mainTargetResults['q3'] + mainTargetResults['q4']) / mainTargetGoal | percent }}
                    </td>
                </tr>
            </table>
            &nbsp;

            <!-- no line graph for now, this may change
            <canvas
            width="400"
            height="200"
            baseChart 
            [datasets]="lineChartData" 
            [labels]="lineChartLabels"
            [options]="lineChartOptions" 
            [chartType]="lineChartType">
            </canvas>
            -->

            <div class="row mt-3">
                <div class="col" align="center">
                    <app-button3 class="mx-1" label="OK" [clickFunction]="goBack.bind(this)" [icon]="faCheck">
                    </app-button3>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" *ngIf="isODTA && headerData">
    <div class="rot mt-3">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%">
                <tbody>
                    <tr class="table-row agency-table-row-title">
                        <td class="px-1">
                            Main Target Result
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q1'] | number: '1.0-0' }}
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q2'] | number: '1.0-0' }}
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q3'] | number: '1.0-0' }}
                        </td>
                        <td class="px-1" align="right">
                            {{ mainTargetResults['q4'] | number: '1.0-0' }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div *ngIf="isODTA && !headerData">
    <p>No Main Target Results for selected drop down values</p>
</div>