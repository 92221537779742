<!-- this fa-icon doesnt work for me but it should work for everyon eles -AM-->
<div class="row page-title">
  <div class="col">
      <fa-icon [icon]="faFileInvoiceDollar" class="title"></fa-icon>
      Voucher Report
  </div>
</div>

<!-- need to get organization nsame and description from voucher page when hooking up the backend and the uuid -->
<tr class="report_header">
  <td colspan="5" class="header">
      Agency: {{organization_name}}<br /><br />
      Description: {{description}}
  </td>
</tr>

<table mat-table [dataSource]="sortOrder_data" class="mat-elevation-z8">

    <ng-container matColumnDef="account">
      <th mat-header-cell *matHeaderCellDef class="main-table-header"> Account </th>
      <td mat-cell *matCellDef="let sortOrder_data"> {{sortOrder_data.reporting_group_name}} </td>
      <td mat-footer-cell *matFooterCellDef class="totals-font"> Total </td>
    </ng-container>
  
    <ng-container matColumnDef="stateOnly">
      <th mat-header-cell *matHeaderCellDef class="main-table-header"> State Only Spent</th>
      <td mat-cell *matCellDef="let sortOrder_data"> {{sortOrder_data.total_state | currency}} </td>
      <td mat-footer-cell *matFooterCellDef="let sortOrder_data" class="totals-font"> {{ finalStateTotal | currency }} </td>
    </ng-container>

    <ng-container matColumnDef="stateMatch">
      <th mat-header-cell *matHeaderCellDef class="main-table-header"> State Match Spent</th>
      <td mat-cell *matCellDef="let sortOrder_data"> {{sortOrder_data.total_federal / 2 | currency}} </td>
      <td mat-footer-cell *matFooterCellDef="let sortOrder_data" class="totals-font"> {{ finalStateMatchTotal | currency }} </td>
    </ng-container>

    <ng-container matColumnDef="fedMatch">
      <th mat-header-cell *matHeaderCellDef class="main-table-header"> Federal Match Spent</th>
      <td mat-cell *matCellDef="let sortOrder_data"> {{sortOrder_data.total_federal / 2 | currency}} </td>
      <td mat-footer-cell *matFooterCellDef="let sortOrder_data" class="totals-font"> {{ finalFederalMatchTotal | currency }} </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef class="main-table-header"> Total Voucher</th>
      <td mat-cell *matCellDef="let sortOrder_data"> {{ sortOrder_data.total | currency}} </td>
      <td mat-footer-cell *matFooterCellDef="let sortOrder_data" class="totals-font"> {{ finalVoucherTotal | currency }} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>

  