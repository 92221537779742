import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { iAnswerSpecial } from './model/answer-special.model';

@Injectable({
    providedIn: 'root'
})
export class QuestionService {

    constructor(
        private http: HttpClient,
    ) { }

    getStepsByProgram(programId): Observable<any> {
        return this.http.get<any>("api/stepsByProgram/" + programId)
    }
    getGroupsByStep(stepId): Observable<any> {
        return this.http.get<any>("api/groupsByStep/" + stepId)
    }

    getStepById(stepId): Observable<any> {
        return this.http.get<any>("api/stepById/" + stepId)
    }
    saveStep(
        id,
        name,
        display,
        instructions): Observable<any> {
        return this.http.put<any>("api/saveStep/", {
            id,
            name,
            display,
            instructions
        })
    }

    getGroupById(groupId): Observable<any> {
        return this.http.get<any>("api/groupById/" + groupId)
    }
    saveGroup(
        step_id,
        id,
        group_number,
        name,
        long_name,
        subtotal_group,
        allow_additional,
        subtotal_additional): Observable<any> {
        return this.http.put<any>("api/saveGroup/", {
            step_id,
            id,
            group_number,
            name,
            long_name,
            subtotal_group,
            allow_additional,
            subtotal_additional
        })
    }

    getQuestionById(question_uuid): Observable<any> {
        return this.http.get<any>('api/questionById/' + question_uuid)
    }

    updateAnswer(answer: iAnswerSpecial): Observable<iAnswerSpecial>
    {
        return this.http.post<iAnswerSpecial>('api/updateAnswer', answer)
    }

    deleteAnswer(answer: iAnswerSpecial): Observable<any>
    {
        return this.http.delete<any>(`api/deleteAnswer/` + answer.uuid)
    }

    addAnswer(answer: any): Observable<any>
    {
        return this.http.post<any>(`api/addAnswer`, answer)
    }

    saveQuestion(
        group_id,
        id,
        name,
        question_number,
        answer_datatype,
        help_html,
        popup_html,
        active,
        required,
        allow_duplicate,
        exclude_external,
    ): Observable<any> {
        return this.http.put<any>("api/saveQuestion/", {
            group_id,
            id,
            name,
            question_number,
            answer_datatype,
            help_html,
            popup_html,
            active,
            required,
            allow_duplicate,
            exclude_external,
        })
    }

    getAnswerDatatypes(): Observable<any> {
        return this.http.get<any>("api/answerDatatypes")
    }

    deleteQuestion(questionId): Observable<any> {
        return this.http.delete<any>("api/programQuestion/" + questionId)
    }

    reloadTestProgram(): Observable<any> {
        return this.http.put<any>("api/reloadTestProgram", {})
    }
}
