<h1 mat-dialog-title>Edit Fringe</h1>
<mat-dialog-content>
<form class="example-form" [formGroup]="tableOneForm">
    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Benefit</mat-label>
          <input matInput formControlName="benefit">
        </mat-form-field>
    </p>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Amount</mat-label>
          <input matInput formControlName="amount">
        </mat-form-field>
    </p>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Percent</mat-label>
          <input matInput type="number" formControlName="percent">
        </mat-form-field>

        <app-flag-comments 
        *ngIf="showFlag"
        class="flagButton" 
        [masterUuid]="masterUuid" 
        [submissionUuid]="submissionUuid"
        [accountUuid]="data.fringe.accountUuid"
        targetColumn="percentage">
      </app-flag-comments>
    </p>


    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Calculated Amount</mat-label>
          <input matInput formControlName="calculatedAmount">
        </mat-form-field>
    </p>


    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Amount Charged to Contract</mat-label>
          <input matInput type="number" formControlName="amountChargedToContract" appRoundUp>
          <mat-error *ngIf="tableOneForm.get('amountChargedToContract').hasError('invalidAmount')">
            Amount charged to contract is greater than calculated amount
          </mat-error>
          <mat-error *ngIf="tableOneForm.get('amountChargedToContract').hasError('amountMismatch')">
            Amount charged to contract is not equal to total charged to contract
          </mat-error>
        </mat-form-field>

        <app-flag-comments
        *ngIf="showFlag"
        class="flagButton" 
        [masterUuid]="masterUuid" 
        [submissionUuid]="submissionUuid"
        [accountUuid]="data.fringe.accountUuid"
        targetColumn="unit_price">
      </app-flag-comments>
    </p>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>State Only</mat-label>
          <input matInput type="number" formControlName="stateOnly" appRoundUp>
        </mat-form-field>

        <app-flag-comments
        *ngIf="showFlag"
        class="flagButton" 
        [masterUuid]="masterUuid" 
        [submissionUuid]="submissionUuid"
        [accountUuid]="data.fringe.accountUuid"
        targetColumn="debit_state">
      </app-flag-comments>
    </p>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>State/Federal Match</mat-label>
          <input matInput type="number" formControlName="stateFedMatch" appRoundUp>
        </mat-form-field>

        <app-flag-comments 
        *ngIf="showFlag"
        class="flagButton" 
        [masterUuid]="masterUuid" 
        [submissionUuid]="submissionUuid"
        [accountUuid]="data.fringe.accountUuid"
        targetColumn="debit_federal">
      </app-flag-comments>
    </p>

    <ng-container *ngIf="showCalculations">
      <p class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>State Only +</mat-label>
            <input matInput formControlName="stateOnlyCalc">
          </mat-form-field>
      </p>

      <p class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>State Match +</mat-label>
            <input matInput formControlName="stateMatchCalc">
          </mat-form-field>
      </p>

      <p class="form-field">
          <mat-form-field appearance="fill">
            <mat-label>Federal Match +</mat-label>
            <input matInput formControlName="fedMatchCalc">
          </mat-form-field>
      </p>
    </ng-container>

    <p class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>= Total Charged to Contract</mat-label>
          <input matInput formControlName="totalChargedToContractCalc">
        </mat-form-field>
    </p>

    <p class="form-field">
      <mat-form-field appearance="fill">
        <mat-label> Agency Comment</mat-label>
        <textarea matInput formControlName="agencyComment"></textarea>
      </mat-form-field>

      <app-flag-comments 
      *ngIf="showFlag"
      class="flagButton" 
      [masterUuid]="masterUuid" 
      [submissionUuid]="submissionUuid"
      [accountUuid]="data.fringe.accountUuid"
      targetColumn="agency_comment">
    </app-flag-comments>
    </p>
</form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button class="save-button" color="primary" (click)="onSave()">Save</button>
    <button mat-raised-button mat-dialog-close class="cancel-button">Cancel</button>
</mat-dialog-actions>