<h4 mat-dialog-title>Assign Roles</h4>

<div mat-dialog-content>
    <p>Once roles have been updated you will need to log out and back in for roles to apply.</p>
</div>
<div *ngIf="dialogMessage">
  <p style="color: red;">{{dialogMessage}}</p>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button (click)="assignRoles()" color="primary">Assign Roles</button>
  <button mat-raised-button (click)="removeRoles()" color="warn">Remove Roles</button>
  <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
