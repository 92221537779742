<h4 mat-dialog-title>Welcome</h4>

<div mat-dialog-content>
  <h6>There Are New Changes</h6>
  <p>A dropdown for fiscal year has been added to the dashboard.</p>
  <p>If your dashboard is blank or you don't see your submission, make sure you select the year(s) that pertain to your submissions.</p>
  <div style="text-align: center;">
    <img src="https://i.ibb.co/QJRt3VP/fyear.png" alt="fyear" border="0" style="margin: 0 auto;">
  </div>
  
  <mat-checkbox [formControl]="dontShow">Don't show this again</mat-checkbox>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>Close</button>
</mat-dialog-actions>

