import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { iTrendReportDetail } from '../model/trend-report-detail.model';
import { iTotalsPerMonth } from '../model/monthly-totals.model';
import { iTotalsPerQuarter } from '../model/quartly-totals.model';
import { iPrimengColumn } from '../model/primeng-column.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-trend-report-monthly-table',
  templateUrl: './trend-report-monthly-table.component.html',
  styleUrl: './trend-report-monthly-table.component.css'
})
export class TrendReportMonthlyTableComponent implements OnInit
{
  @Input() trendReportDetails: iTrendReportDetail[];

  stateOnlyTotalSpent: number = 0;
  stateFedTotalSpent: number = 0;
  stateOnlyBudget: number = 0;
  stateFedBudget: number = 0;
  stateAmount: number;
  federalAmount: number;
  stateOnlyTotalPercent: number = 0;
  stateFedTotalPercent: number = 0;
  totalPercent: number = 0;
  stateOnlyRemainingBudget: number = 0;
  stateFedRemainingBudget: number = 0;
  monthlyTotals: iTotalsPerMonth = 
  {
    stateOnly: 
    {
      m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0,
      m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0,
    },
    stateFed: 
    {
      m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0,
      m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0,
    }
  };
  trendReportColumns: iPrimengColumn[] = 
  [
    { header: 'Account', field: 'account' },
    { header: 'Dollar Type', field: 'fundingType' },
    { header: 'Jul', field: 'm07' },
    { header: 'Aug', field: 'm08' },
    { header: 'Sep', field: 'm09' },
    { header: 'Oct', field: 'm10' },
    { header: 'Nov', field: 'm11' },
    { header: 'Dec', field: 'm12' },
    { header: 'Jan', field: 'm01' },
    { header: 'Feb', field: 'm02' },
    { header: 'Mar', field: 'm03' },
    { header: 'Apr', field: 'm04' },
    { header: 'May', field: 'm05' },
    { header: 'Jun', field: 'm06' },
    { header: 'Budget', field: 'budget' }
  ];
  @ViewChild('dt') dataTable!: Table;

  ngOnInit()
  {
    this.calculateMonthlyTotals();
    this.calculateBudgetsForFundingTypes(this.trendReportDetails);
    this.calculateTotalSpentByFundingType(this.trendReportDetails);
    this.calculateRemainingBudgetByFundingType(this.trendReportDetails);
    this.calculateTotalPercentageSpentByFundingType(this.trendReportDetails)
  }

  isNumber(value: any): boolean
  {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  calculateTotalSpent(data: iTrendReportDetail): number 
  {
    const months = 
    [
      data.m01, data.m02, data.m03, data.m04, data.m05, data.m06,
      data.m07, data.m08, data.m09, data.m10, data.m11, data.m12
    ];
  
    return months.reduce((total, month) => total + Number(month), 0);
  }

  calculateTotalSpentByFundingType(dataArray: iTrendReportDetail[])
  {
    this.stateOnlyTotalSpent = 0;
    this.stateFedTotalSpent = 0;

    dataArray.forEach(data => {
      const months = 
      [
        data.m01, data.m02, data.m03, data.m04, data.m05, data.m06,
        data.m07, data.m08, data.m09, data.m10, data.m11, data.m12
      ];

      const totalSpent = months.reduce((total, month) => total + Number(month), 0);

      if (data.fundingType === 'State Only') 
      {
        this.stateOnlyTotalSpent += totalSpent;
      } 
      else if (data.fundingType === 'State/Fed') 
      {
        this.stateFedTotalSpent += totalSpent;
      }
    });
  }

  calculatePercentageSpent(data: iTrendReportDetail): number 
  {
    const budgetAmount = Number(data.budget);
    const totalSpent = this.calculateTotalSpent(data);
    return budgetAmount === 0 ? 0 : (totalSpent / budgetAmount) * 100;
  }

  calculateRemainingBudget(data: iTrendReportDetail): number 
  {
    const budgetAmount = Number(data.budget);
    const totalSpent = this.calculateTotalSpent(data);
    return budgetAmount - totalSpent;
  }

  calculateBudgetsForFundingTypes(dataArray: iTrendReportDetail[]) 
  {
    dataArray.forEach(data => {
      const budget = isNaN(Number(data.budget)) ? 0 : Number(data.budget);
  
      if (data.fundingType === 'State Only') 
      {
        this.stateOnlyBudget += budget;
      } else if (data.fundingType === 'State/Fed') 
      {
        this.stateFedBudget += budget;
      }
    });
  }

  calculateTotalPercentageSpentByFundingType(dataArray: iTrendReportDetail[])
  {
    this.stateOnlyTotalPercent = 0;
    this.stateFedTotalPercent = 0;
    this.totalPercent = 0;
  
    let totalStateOnlySpent = 0;
    let totalStateOnlyBudget = 0;
    let totalStateFedSpent = 0;
    let totalStateFedBudget = 0;
  
    dataArray.forEach(data => {
      const totalSpent = this.calculateTotalSpent(data);
      const budgetAmount = Number(data.budget);
  
      if (data.fundingType === 'State Only')
      {
        totalStateOnlySpent += totalSpent;
        totalStateOnlyBudget += budgetAmount;
      } else if (data.fundingType === 'State/Fed')
      {
        totalStateFedSpent += totalSpent;
        totalStateFedBudget += budgetAmount;
      }
    });
  
    this.stateOnlyTotalPercent = totalStateOnlyBudget === 0 ? 0 : (totalStateOnlySpent / totalStateOnlyBudget) * 100;
  
    this.stateFedTotalPercent = totalStateFedBudget === 0 ? 0 : (totalStateFedSpent / totalStateFedBudget) * 100;
  
    const totalSpent = totalStateOnlySpent + totalStateFedSpent;
    const totalBudget = totalStateOnlyBudget + totalStateFedBudget;
    this.totalPercent = totalBudget === 0 ? 0 : (totalSpent / totalBudget) * 100;
  }

  calculateRemainingBudgetByFundingType(dataArray: iTrendReportDetail[]) 
  {
    this.stateOnlyRemainingBudget = 0;
    this.stateFedRemainingBudget = 0;

    dataArray.forEach(data => {
      const remainingBudget = this.calculateRemainingBudget(data);
  
      if (data.fundingType === 'State Only') 
      {
        this.stateOnlyRemainingBudget += remainingBudget;
      } else if (data.fundingType === 'State/Fed') 
      {
        this.stateFedRemainingBudget += remainingBudget;
      }
    });
  }

  calculateMonthlyTotals()
  {
    this.monthlyTotals = 
    {
      stateOnly: 
      {
        m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0,
        m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0,
      },
      stateFed: 
      {
        m01: 0, m02: 0, m03: 0, m04: 0, m05: 0, m06: 0,
        m07: 0, m08: 0, m09: 0, m10: 0, m11: 0, m12: 0,
      }
    };

    this.trendReportDetails.forEach((item) => {
      if (item.fundingType === 'State Only') 
      {
        this.monthlyTotals.stateOnly.m01 += Number(item.m01);
        this.monthlyTotals.stateOnly.m02 += Number(item.m02);
        this.monthlyTotals.stateOnly.m03 += Number(item.m03);
        this.monthlyTotals.stateOnly.m04 += Number(item.m04);
        this.monthlyTotals.stateOnly.m05 += Number(item.m05);
        this.monthlyTotals.stateOnly.m06 += Number(item.m06);
        this.monthlyTotals.stateOnly.m07 += Number(item.m07);
        this.monthlyTotals.stateOnly.m08 += Number(item.m08);
        this.monthlyTotals.stateOnly.m09 += Number(item.m09);
        this.monthlyTotals.stateOnly.m10 += Number(item.m10);
        this.monthlyTotals.stateOnly.m11 += Number(item.m11);
        this.monthlyTotals.stateOnly.m12 += Number(item.m12);
      } else if (item.fundingType === 'State/Fed') 
      {
        this.monthlyTotals.stateFed.m01 += Number(item.m01);
        this.monthlyTotals.stateFed.m02 += Number(item.m02);
        this.monthlyTotals.stateFed.m03 += Number(item.m03);
        this.monthlyTotals.stateFed.m04 += Number(item.m04);
        this.monthlyTotals.stateFed.m05 += Number(item.m05);
        this.monthlyTotals.stateFed.m06 += Number(item.m06);
        this.monthlyTotals.stateFed.m07 += Number(item.m07);
        this.monthlyTotals.stateFed.m08 += Number(item.m08);
        this.monthlyTotals.stateFed.m09 += Number(item.m09);
        this.monthlyTotals.stateFed.m10 += Number(item.m10);
        this.monthlyTotals.stateFed.m11 += Number(item.m11);
        this.monthlyTotals.stateFed.m12 += Number(item.m12);
      }
    });
  }
  
  exportToCSV()
  {
    this.dataTable.exportCSV();
  }
}
