import { AfterViewInit, Component, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faBan, faPaperclip, faUpload } from '@fortawesome/free-solid-svg-icons';
import { InputDocumentComponent } from '../input-document/input-document.component';
import { SubmissionService } from '../submission.service';
import { AttachmentsCommunicationService } from '../services/attachments-communication.service';

@Component({
    selector: 'app-submission-attachment-controls',
    templateUrl: './submission-attachment-controls.component.html',
})
export class SubmissionAttachmentControlsComponent implements OnInit {
    @ViewChild(InputDocumentComponent) private inputDocumentComponent: InputDocumentComponent;
    @Input() submissionUuid
    @Input() voucherAccountUuid
    @Input() refreshAttachmentList
    @Output() attachmentChange: EventEmitter<any> = new EventEmitter();
    attachmentOriginalFilename
    descriptionError
    documentDescriptionFC = new UntypedFormControl()
    attachmentFC = new UntypedFormControl()

    faPaperclip = faPaperclip
    faBan = faBan
    faUpload = faUpload

    constructor(
        private submissionService: SubmissionService,
        private attachmentCommunicationService: AttachmentsCommunicationService,
    ) { }

    refreshAttachments()
    {
        this.attachmentCommunicationService.triggerButtonClick();
    }

    attachmentUploadComplete(eventBody) {
        //console.log("attachment upload complete", eventBody)

        var fileInfo = {
            document_description: this.documentDescriptionFC.value,
            file_size: eventBody.file_size,
            last_modified: eventBody.last_modified,
            mime_type: eventBody.mime_type,
            document_uuid: eventBody.uuid,
        }

        this.submissionService.stageSubmissionAttachment(
            eventBody.uuid,
            this.submissionUuid,
            this.documentDescriptionFC.value,
            eventBody.file_name,
            eventBody.mime_type,
            eventBody.file_size,
            this.voucherAccountUuid,
        ).subscribe((response) => {
            if (response.success) { 
                this.refreshAttachments();
            } else {
                console.log("error staging file", response)
            }
            this.documentDescriptionFC.setValue(null)
            // if (this.refreshAttachmentList) {
            //     this.refreshAttachmentList()
            // }
        })
    }

    preprocess(file) {
        if (!file) {
            this.descriptionError = "Please choose a file to attach."
            return false
        }
        this.attachmentOriginalFilename = file.name
        this.descriptionError = null
        if (!this.documentDescriptionFC.value) {
            this.descriptionError = "Please provide an attachment description."
            return false
        }
        return true
    }

    fileSelect(files) {
        this.descriptionError = null
        //console.log("file select", files)
        if (!this.documentDescriptionFC.value && files && files.length > 0 && files[0].name) {
            var nameWithoutExtension = files[0].name
            var lastDotIndex = files[0].name.lastIndexOf(".")
            if (lastDotIndex >= 0) {
                nameWithoutExtension = files[0].name.substring(0, lastDotIndex)
            }
            this.documentDescriptionFC.setValue(nameWithoutExtension)
        }
    }

    clear() {
        this.descriptionError = null
        this.documentDescriptionFC.setValue(null)
    }

    clearForm() {
        this.inputDocumentComponent.clearForm()
    }

    handleFileInput() {
        this.inputDocumentComponent.handleFileInput()
    }

    ngOnInit()
    {
        console.log('refresh attachement: ' + this.refreshAttachmentList);
    }
}