<div class="container-fluid">
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faTicketAlt"></fa-icon>
            Voucher Detail
        </div>
    </div>
    <div class="row">
        <div class="col px-0 text-right">
            <app-button3 *ngIf="showPrintButton" label="Print" [clickFunction]="print.bind(this)"
                [icon]="faPrint" [small]="true">
            </app-button3>
        </div>
    </div>
</div>
<!-- will need to utilize voucher-edit or build new display component for voucher details -->
<div *ngIf="entries$ | async as entries">
    <app-voucher-summary-header [submissionUuid]="submissionUuid"></app-voucher-summary-header>

    <div *ngFor="let row of entries.result" style="page-break-after: always">
        <!-- {{row.uuid}} -->
        <app-voucher-edit 
        [accUuid]="row.uuid"
        [canEdit]="false"
        >
        </app-voucher-edit>
    </div>
</div>
