<app-logo-header></app-logo-header>

<div class="container noep-container-small mt-4 shadow">
    <form (submit)="loginUser($event)">
        <div class="row mt-2">
            <div class="col mt-3 bold" style="font-size: 1.2em;" align="center">
                Welcome back.
            </div>
        </div>
        <div class="row mt-2">
            <div class="col bold" style="font-size: 1em;" align="center">
                Please log in to your account.
            </div>
        </div>
        <div *ngIf="loginFailure" class="row mt-3">
            <div class="col" align="center">
                Invalid login credentials.
            </div>
        </div>
        <div *ngIf="loginError" class="row mt-3">
            <div class="col" align="center">
                An error occurred while attempting to log in.
            </div>
        </div>
        <div *ngIf="loginMessage" class="row mt-3">
            <div class="col" align="center">
                {{loginMessage}}
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                Email
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                <div class="username-wrapper">
                    <input type="text" class="login-input-style" style="width: 100%;" placeholder="Enter email"
                        id="username" value="{{loginUsername}}" #username maxlength="255" />
                    <fa-icon [icon]="faEnvelope" class="errspan"></fa-icon>
                </div>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                Password
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                <input type="password" class="login-input-style" style="width: 100%;" placeholder="Enter password"
                    id="password" #password (keypress)="detectCapsLock($event)" />
                <fa-icon [icon]="faLock" class="errspan"></fa-icon>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div *ngIf="capsLockDetected" class="row mt-1">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10">
                <fa-icon class="alert-icon" [icon]="faExclamationTriangle"></fa-icon>
                <span class="ml-1" style="font-size: .8em;">Caps lock is on</span>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-6 col-md-5 bold">
                <input type="checkbox" class="checkbox-style" id="rememberme" [checked]="!!loginUsername" name="cb"
                    (change)="remembermeChanged($event)" /><label for="rememberme" style="font-size: .8em;">Remember
                    me</label>
            </div>
            <div class="col-6 col-md-5 bold" align="right">
                <a class="mx-1 bold" routerLink="/forgot-password" style="font-size: .8em;">Forgot Password?</a>
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col" align="center">
            </div>
        </div>
        <div class="row">
            <div class="col-1 d-none d-md-block">
            </div>
            <div class="col-12 col-md-10 bold">
                <input class="button-style mt-3 mb-4" type="submit" value="Log In" #loginButton />
            </div>
            <div class="col-1 d-none d-md-block">
            </div>
        </div>
    </form>
    <div class="row mt-2" *ngIf="destination">
        <div class="col mb-4" align="center">
            After logging in, you will be redirected to {{destination}}
        </div>
    </div>
</div>