<select *ngIf="!disabled" class="appinput" [formControl]="fc">
    <option value="null">{{unsetDisplay}}</option>
    <option *ngFor="let enumValue of enum" value={{enumValue.id}}>{{enumValue.name}}</option>
</select>
<span *ngIf="disabled">
    <span *ngFor="let enumValue of enum">
        <span *ngIf="enumValue.id == fc.value" class="ml-4">
            {{enumValue.name}}
        </span>
    </span>
</span>