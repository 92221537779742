<div *ngIf="initialized" class="container-fluid">

    <div class="row mb-2">
        <div class="col page-title-no-font">
            <span class="page-title-font">
                <fa-icon [icon]="faClock"></fa-icon>
                Submission Schedule
            </span>
            <app-button3 *ngIf="showNewButton()" style="float: right;" class="mx-1" label="New"
                [clickFunction]="new.bind(this)" [icon]="faPlus" [small]="true">
            </app-button3>
        </div>
    </div>

    <div class="card">
        <p-table [columns]="columns"
        [value]="subs"
        [tableStyle]="{ 'min-width': '50rem' }"
        [paginator]="true"
        [rows]="rows"
        [rowsPerPageOptions]="[10, 20 , 30]">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" pSortableColumn="{{col.field}}">
                        {{ col.header }}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns">
                    <ng-container *ngIf="col.field">
                      <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'submissionTypeName'">
                          <p-tag [value]="rowData[col.field]" [severity]="getSeverity(rowData[col.field])"></p-tag>
                        </ng-container>
                        <ng-container *ngSwitchCase="'a'">
                            <p-button
                              icon="pi pi-pencil"
                              [rounded]="true"
                              severity="success"
                              [outlined]="true"
                              (click)="navigateEditSubmission(rowData)"/>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          {{ formatValue(rowData[col.field]) }}
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
        </p-table>
    </div>
</div>