import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private commentExistsSource = new BehaviorSubject<boolean>(false);
  private commentExistsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  setCommentExists(value: boolean) {
    this.commentExistsSource.next(value);
  }

  getCommentExists(): Observable<boolean> {
    return this.commentExistsSubject.asObservable();
  }
}
