import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faCheck, faEdit, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SnackRouteService } from '../snack-route.service';
import { SubmissionCommentService } from '../submission-comment.service';

@Component({
    selector: 'app-outreach-submission-comment',
    templateUrl: './outreach-submission-comment.component.html',
})
export class OutreachSubmissionCommentComponent implements OnInit {
    @Input() submissionUuid
    commentFC = new UntypedFormControl()
    @Input() commentUuid
    @Input() comment
    @Input() inputId
    @Input() showInputContainer
    faTimes = faTimes
    faCheck = faCheck
    @Input() showFlag
    @Input() odd
    @Input() targetUuid
    @Input() showCreateButton
    @Input() showHSNYLabel
    faEdit = faEdit

    faExclamationTriangle = faExclamationTriangle
    constructor(
        private submissionCommentService: SubmissionCommentService,
        private snackRouteService: SnackRouteService,
    ) { }

    commentValue
    ngOnInit() {
        this.commentFC.setValue(this.comment)
        this.commentValue = this.commentFC.value
    }

    toggleCommentInput() {
        if (!this.showFlag) {
            return
        }
        if (this.showInputContainer.value) {
            this.hideCommentInput()
        } else {
            this.showCommentInput()
        }
    }

    showCommentInput() {
        this.showInputContainer.value = true
    }

    hideCommentInput() {
        this.showInputContainer.value = false
        this.commentFC.setValue(this.commentValue)
    }

    deleteComment() {
        if (this.commentUuid) {
            this.submissionCommentService.deleteCommentPromise(this.commentUuid).then(() => {
                this.commentUuid = null
                this.commentValue = null
                this.commentFC.setValue(null)
                this.snackRouteService.snack("Comment deleted.")
                this.hideCommentInput()
            })
        } else {
            this.snackRouteService.snack("Action cancelled.")
            this.hideCommentInput()
        }
    }

    updateComment() {
        this.submissionCommentService.postCommentPromise(this.commentUuid, this.commentFC.value).then(() => {
            this.commentValue = this.commentFC.value
            this.snackRouteService.snack("Comment updated.")
            this.hideCommentInput()
        })
    }

    insertComment() {
        this.submissionCommentService.putCommentPromise(
            this.submissionUuid,
            "data_submission",
            this.targetUuid,
            this.inputId,
            this.commentFC.value).then((uuid) => {
                this.commentUuid = uuid
                this.commentValue = this.commentFC.value
                this.snackRouteService.snack("Comment created.")
                this.hideCommentInput()
            })
    }

    saveComment() {
        if (this.commentUuid) {
            if (this.commentFC.value) {
                this.updateComment()
            } else {
                this.deleteComment()
            }
        } else {
            if (this.commentFC.value) {
                this.insertComment()
            } else {
                this.snackRouteService.snack("Action cancelled.")
                this.hideCommentInput()
            }
        }
    }
}
