<div class="container-fluid">

    <div class="row page-title-no-font mb-2">
        <div class="col">
            <span class="page-title-font">
                <fa-icon [icon]="faDatabase"></fa-icon>
                Data Joiner
            </span>
        </div>
    </div>

    <div *ngFor="let type of typeDisplay$ | async; let index = index">
        <app-enum *ngIf="type.values && type.values.length" label="Type" [fc]="type.fc" [enum]="type.values"
            unsetDisplay="Select a type" [ngStyle]="{'margin-left': (type.depth * 2) + 'em'}">
        </app-enum>
        <app-button *ngIf="index == 0" label="Clear" [small]="true" [clickFunction]="clear.bind(this)"></app-button>
    </div>

    <div class="row mt-2">
        <div class="col">
        </div>
    </div>

    <div *ngIf="typeRawDisplay$ | async as typeRaw" class="row page-title-no-font mb-2">
        <div class="col">
            <span class="page-title-font">
                <fa-icon [icon]="faDatabase"></fa-icon>
                {{ typeRaw }}
            </span>
        </div>
    </div>

    <!-- <div *ngIf="firstEntry$ | async as entry" class="row mb-2">
        <div class="col">
            <app-crud-child-node [schema]="schema$ | async" [entry]="entry" [referencedTypes]="referencedTypes"
                [typeNode]="typeValuesTree" [depth]="0">
            </app-crud-child-node>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col">
            <span style="white-space: pre;">{{ typeRawResults$ | async }}</span>
        </div>
    </div> -->
    <table *ngIf="resultsRows$ | async as rrows" class="table table-striped table-hover" style="min-width: 100%">
        <thead>
            <tr style="background-color: lightgrey">
                <th *ngFor="let column of cols; let index = index"
                    [ngStyle]="{'text-align': index == 0 ? '' : 'right'}">
                    <span class="sort-column-header">
                        {{column}}
                    </span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="table-row" *ngFor="let entry of rrows">
                <td *ngFor="let column of cols" class="px-1">
                    {{entry[column]}}
                </td>
            </tr>
        </tbody>
    </table>

    <!-- <div *ngFor="let entry of results$ | async" class="row mb-2">
        <div class="col">
            <span style="white-space: pre;">{{ entry.rawValue }}</span>

            <app-crud-child-node [schema]="schema$ | async" [entry]="entry" [referencedTypes]="referencedTypes"
                [typeNode]="typeValuesTree" [depth]="0">
            </app-crud-child-node>
        </div>
    </div> -->

    <div class="row mb-2" *ngIf="typeQuery$ | async as query">
        <div class="col" style="background-color: lightgrey;">
            <span style="white-space: pre;">{{ query }}</span>
        </div>
    </div>
</div>