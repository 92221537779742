<div class="row">
    <div class="col-4 bold data-row">
        {{label}}
        <fa-icon *ngIf="commentId && showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick(commentId)"
            [title]="commentData[commentId].showInputContainer.value ? 'Cancel' : 'Edit'">
        </fa-icon>
    </div>
    <div class="col-8">
        <!-- removed class="col-8 pb-2 unedit-padding" from div below, for text display issue HSNY-1993 -Brock -->
        <div *ngIf="disabled">
            <span>{{getDisplayValue()}}</span>
        </div>
        <select *ngIf="!disabled" class="input-style" [formControl]="fc" (change)="doChange($event)"
            style="border-color: lightgrey; min-width: 4em">
            <option *ngFor="let enumValue of enumValuesNameAndId" value={{enumValue.id}}>
                {{enumValue.name}}
            </option>
        </select>
    </div>
</div>
<app-outreach-submission-comment *ngIf="commentId" [submissionUuid]="submissionUuid" [inputId]="commentId"
    [commentUuid]="commentData[commentId].commentUuid" [comment]="commentData[commentId].comment"
    [showInputContainer]="commentData[commentId].showInputContainer" [showFlag]="showFlag" [odd]="false"
    [targetUuid]="targetUuid">
</app-outreach-submission-comment>