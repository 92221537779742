import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faDatabase, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';

@Component({
    selector: 'app-crud-ddl',
    templateUrl: './crud-ddl.component.html',
})
export class CrudDdlComponent implements OnInit {
    faPlus = faPlus
    faTimes = faTimes
    newColumns = []
    newColumnsChange = new BehaviorSubject(null)

    dbTypes = [
        {
            id: "boolean",
            name: "boolean",
            sized: false,
        },
        {
            id: "tinyint",
            name: "tinyint",
            sized: true,
        },
        {
            id: "varchar",
            name: "varchar",
            sized: true,
        },
        {
            id: "int",
            name: "int",
            sized: true,
        },
    ]

    dbTypesById = {}

    createColumn(fieldName, definition, typeName, typeSize) {
        return {
            nameMouseOver: false,
            nameFC: new UntypedFormControl(fieldName),
            definition: definition,
            typeMouseOver: false,
            typeFC: new UntypedFormControl(typeName),
            sizeFC: new UntypedFormControl(typeSize),
        }

    }

    getTypeNameFromType(type) {
        var parenIndex = type.indexOf("(")
        if (parenIndex > 0) {
            return type.substring(0, parenIndex)
        }
        return type
    }

    getTypeSizeFromType(type) {
        var typeName = type
        var parenIndex = type.indexOf("(")
        if (parenIndex > 0) {
            typeName = type.substring(0, parenIndex)
            var closeIndex = type.indexOf(")")
            return type.substring(parenIndex + 1, closeIndex)
        }
        return null
    }

    faDatabase = faDatabase
    schema$ = this.crudService.getSchema().pipe(shareReplay(1))
    schemaTypes$ = this.schema$.pipe(map((schema) => {
        return Object.entries(schema.result).sort((a, b) => a[0].localeCompare(b[0])).map((item) => {
            return { id: item[0], name: item[0].replace(/_/g, " ") }
        });
    }))
    typeFC = new UntypedFormControl(localStorage.getItem("crud-ddl-type"))
    typeFCs$ = combineLatest(
        this.schema$,
        this.typeFC.valueChanges.pipe(startWith(this.typeFC.value)),
    ).pipe(
        switchMap(([schema, type]) => {
            localStorage.setItem("crud-ddl-type", type)
            console.log("schema", schema, type)
            if (!type) {
                return of([])
            }

            return of(schema.result[type].definition.map((item) => {
                return this.createColumn(
                    item.Field,
                    item,
                    this.getTypeNameFromType(item.Type),
                    this.getTypeSizeFromType(item.Type))
            }))
        }),
        tap((cols) => {
            this.allFCs = []
            for (var i = 0; i < cols.length; ++i) {
                this.allFCs.push(cols[i].nameFC)
                this.allFCs.push(cols[i].typeFC)
                this.allFCs.push(cols[i].sizeFC)
            }
            console.log("tapped", cols)
        }),
        shareReplay(1)
    )

    updateSQL$ = combineLatest(
        this.newColumnsChange.pipe(switchMap(() => {
            if (!this.newColumns.length) {
                return of([])
            }
            return combineLatest(...this.newColumns.map((item) => {
                return combineLatest(
                    item.nameFC.valueChanges.pipe(startWith(item.nameFC.value)),
                    item.typeFC.valueChanges.pipe(startWith(item.typeFC.value)),
                    item.sizeFC.valueChanges.pipe(startWith(item.sizeFC.value)),
                )
            }))
        })),
        this.typeFCs$
    ).pipe(
        switchMap(([dummy, cols]) => {
            console.log("cols?", cols)
            var allFCs = []
            for (var i = 0; i < cols.length; ++i) {
                allFCs.push(cols[i].nameFC.valueChanges.pipe(startWith(cols[i].nameFC.value)))
                allFCs.push(cols[i].typeFC.valueChanges.pipe(startWith(cols[i].typeFC.value)))
                allFCs.push(cols[i].sizeFC.valueChanges.pipe(startWith(cols[i].sizeFC.value)))
            }
            return combineLatest(of(cols), ...allFCs)
        }),
        switchMap(([types, ...allFCs]) => {

            var updateSQL = ""
            // console.log("types", types)
            // console.log("fcs", allFCs)
            types.forEach((item) => {
                if (item.nameFC.value != item.definition.Field
                    || item.typeFC.value != this.getTypeNameFromType(item.definition.Type)
                    || item.sizeFC.value != this.getTypeSizeFromType(item.definition.Type)) {
                    updateSQL += "alter table " + this.typeFC.value + " change column " + item.definition.Field + " "
                        + item.nameFC.value + " " + item.typeFC.value
                    if (this.dbTypesById[item.typeFC.value] && this.dbTypesById[item.typeFC.value].sized) {
                        updateSQL += "(" + item.sizeFC.value + ")"
                    }
                    updateSQL += ";\n"
                }
            });

            this.newColumns.forEach((item) => {
                updateSQL += "alter table " + this.typeFC.value + " add column " + item.nameFC.value + " " + item.typeFC.value
                if (this.dbTypesById[item.typeFC.value] && this.dbTypesById[item.typeFC.value].sized) {
                    updateSQL += "(" + item.sizeFC.value + ")"
                }
                updateSQL += ";\n"
            })

            return of(updateSQL)
        })
    )

    allFCs = []

    get showButtons() {
        if (this.newColumns.length) {
            return true
        }
        for (var i = 0; i < this.allFCs.length; ++i) {
            if (this.allFCs[i].dirty) {
                return true
            }
        }
        return false
    }

    addColumn() {
        this.newColumns.push(this.createColumn("", {}, "", ""))
        this.newColumnsChange.next(null)
    }

    save() {
        console.log("save todo")
    }

    cancel() {
        console.log("cancel todo")
    }

    cancelType(col) {
        console.log("cancel todo")
    }

    deleteColumn(i) {
        console.log("delete todo")
    }

    // showButtons$ = this.typeFCs$.pipe(
    //     switchMap(([...cols]) => {
    //         var ret = []
    //         console.log("cols1", cols)
    //         for (var i = 0; i < cols.length; ++i) {
    //             ret.push(cols[i].nameFC.valueChanges.pipe(startWith(cols[i].nameFC.value)))
    //             ret.push(cols[i].typeFC.valueChanges.pipe(startWith(cols[i].typeFC.value)))
    //             ret.push(cols[i].sizeFC.valueChanges.pipe(startWith(cols[i].sizeFC.value)))
    //         }
    //         console.log("RETTTT", ret)
    //         // return combineLatest(of(cols), ...ret)
    //         return combineLatest(of(cols), ...ret)
    //     }),
    //     switchMap(([cols, ...fcs]) => {
    //         console.log("cols?", cols)
    //         console.log("fcs?", fcs)
    //         // return of(cols.reduce((acc, item) => {
    //         //     return acc || item.nameFC.dirty || item.typeFC.dirty || item.sizeFC.dirty
    //         // }, false))
    //         return of(true)
    //     }),
    //     tap((val) => {
    //         console.log("show button tap", val)
    //     })
    // )
    // showButtons$ = this.typeFCs$.pipe(
    //     switchMap(([...cols]) => {
    //         var ret = []
    //         console.log("cols1", cols)
    //         for (var i = 0; i < cols.length; ++i) {
    //             ret.push(cols[i].nameFC.valueChanges.pipe(startWith(cols[i].nameFC.value), tap((val) => { console.log("name tap!", val)})))
    //             ret.push(cols[i].typeFC.valueChanges.pipe(startWith(cols[i].typeFC.value)))
    //             ret.push(cols[i].sizeFC.valueChanges.pipe(startWith(cols[i].sizeFC.value)))
    //         }
    //         console.log("RETTTT", ret)
    //         // return combineLatest(of(cols), ...ret)
    //         return combineLatest(...ret)
    //     }),
    //     tap(() => {
    //         console.log("FIRE")
    //     })
    // )

    colNameLeave(col) {
        if (!col.nameFC.dirty) {
            col.nameMouseOver = false
        }
    }

    colTypeLeave(col) {
        if (!col.typeFC.dirty && !col.sizeFC.dirty) {
            col.typeMouseOver = false
        }
    }

    /*

    only available in dev environment

    interface for
        creating table
        rename column
        change column type
        add column
        remove column
        add/remove foreign key

    want to capture delta script
        or
    want to do a diff of schema
        table names
        table column names and types
        primary keys
        foreign keys
        unique keys
            but this can be later. idea tho is that it could be done on the fly instead of saved
    
CREATE TABLE `sys_navigation_sub` (
  `id` varchar(25) NOT NULL,
  `name` varchar(50) DEFAULT NULL,
  `navigation_id` varchar(15) DEFAULT NULL,
  `display` int(11) DEFAULT NULL,
  `icon` varchar(50) DEFAULT NULL,
  `router_link` varchar(255) DEFAULT NULL,
  PRIMARY KEY (`id`),
  UNIQUE KEY `uk_sys_navigation_sub_name` (`name`),
  KEY `FK_sys_navigation_sub_navigation_id` (`navigation_id`),
  CONSTRAINT `FK_sys_navigation_sub_navigation_id` FOREIGN KEY (`navigation_id`) REFERENCES `sys_navigation` (`id`) ON DELETE NO ACTION
) ENGINE=InnoDB DEFAULT CHARSET=latin1 |

    
as you mouse over names, they are replaced with text inputs
if you click, it stays as text input unless you click off it
if you change the data, save and cancel buttons appear, and it remains as input until saved or cancelled
as data is changed, the diff script is created

next to each column are badges for pk, uk, fk with a dropdown
they're like, red yellow and blue, but pale/grey if disabled
    clicking adds the bit to the table definition, and again, save/cancel pops up



            
    




    */

    constructor(
        private crudService: CrudService,
    ) { }

    ngOnInit() {
        this.dbTypes.forEach((item) => {
            this.dbTypesById[item.id] = item
        })
    }

}
