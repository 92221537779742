import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { DashboardService } from '../dashboard.service';
import { SubmissionAttachmentsComponent } from '../submission-attachments/submission-attachments.component';
import { SubmissionService } from '../submission.service';

@Component({
    selector: 'app-outreach-campaign-request',
    templateUrl: './outreach-campaign-request.component.html',
})
export class OutreachCampaignRequestComponent implements OnInit, OnDestroy {
    @ViewChild(SubmissionAttachmentsComponent) private _attachmentsComponent: SubmissionAttachmentsComponent;
    initialized = false
    paramSubscription
    submissionUuid
    submissionActivityFeed$
    submissionHistory$
    submission$
    submissionStatuses$
    faHandsHelping = faHandsHelping

    constructor(
        private activatedRoute: ActivatedRoute,
        private submissionService: SubmissionService,
        private dashboardService: DashboardService
    ) { }

    get attachmentsComponent() {
        return this._attachmentsComponent
    }

    paramPromise() {
        return new Promise((resolve, reject) => {
            this.paramSubscription = this.activatedRoute.queryParams.subscribe(params => {
                this.submissionUuid = params.submissionUuid;
                resolve(null);
            });
        });
    }

    ngOnInit() {
        this.paramPromise().then(() => {
            this.submissionActivityFeed$ = this.submissionService.fetchFeedAndAppendImagesToDom(this.submissionUuid);
            this.submissionHistory$ = this.submissionService.fetchSubmissionHistory(this.submissionUuid);
            this.submission$ = this.submissionService.fetchSubmissionHeaders(this.submissionUuid);
            this.submissionStatuses$ = this.dashboardService.getStatusesById();
            this.initialized = true;

            console.log(this.submission$)
        })

    }

    ngOnDestroy() {
        this.paramSubscription.unsubscribe()
    }
}
