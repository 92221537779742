<div *ngIf="!stickyBypass" class="sticky-footer-container">
    <div class="sticky-footer-content">
        <!--<app-banner-message *ngIf="showMainMenu"></app-banner-message>-->
        <app-main-menu *ngIf="showMainMenu"></app-main-menu>
        <router-outlet></router-outlet>
        <br />
    </div>
    <footer class="sticky-footer-footer" style="position: absolute; right: 0; bottom: 0; left: 0;">
        <div class="container-fluid mb-0 py-2" style="height: 4em;">
            <div class="row">
                <div class="col" style="text-align:center;">
                    &copy; 2022 <a class="bold" href="http://www.hsny.online" target="_blank"><span
                            class="hsny-orange">HS</span><span class="hsny-purple">NY</span>online</a>. All
                    rights reserved.
                    <a class="mx-1 bold" routerLink="/terms-of-use" target="_blank">Terms of Use</a>
                    |
                    <a class="mx-1 bold" routerLink="/privacy-policy" target="_blank">Privacy Policy</a>
                    |
                    <a class="mx-1 bold" href="https://www.hsny.online/faqs" target="_blank">Help Me</a>
                </div>
            </div>
        </div>
    </footer>
</div>
<router-outlet *ngIf="stickyBypass"></router-outlet>