import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class AwsSesService {

    private sendErrorEmailUrl = 'api/sendErrorEmail';
    private sendSubmissionEmailUrl = 'api/sendSubmissionEmail';

    constructor(
        private http: HttpClient,
    ) { }

    clearCircularRefs(circ) {
        var cache = [];
        var stringed = JSON.stringify(circ, function(key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Duplicate reference found, discard key
                    return;
                }
                // Store value in our collection
                cache.push(value);
            }
            return value;
        });
        cache = null; // Enable garbage collection
        return JSON.parse(stringed);
    }

    //clone into an anonymous object so that the stringify doesn't do special handling for an error class
    cloneError(err) {
        var _return = {};
        Object.getOwnPropertyNames(err).forEach(function (key) {
            _return[key] = err[key];
        });
        return this.clearCircularRefs(_return);
    };

    //extract only the message and stack because ngDebugContext can be very large
    errorMessageAndStack(error) {
        return this.cloneError({message: error.message, stack: error.stack})
    }

    sendErrorEmail(error, route) {
        //console.log("error stringed", JSON.stringify(this.errorMessageAndStack(error)))
        return this.http.put<any>(this.sendErrorEmailUrl,
            { error: this.errorMessageAndStack(error), route: route }, httpOptions);
    }

    sendSubmissionEmail(to, cc, bcc, from, subject, contents): Observable<any> {
        return this.http.put<any>(this.sendSubmissionEmailUrl,
            { to: to, cc: cc, bcc: bcc, from: from, subject: subject, contents: contents }, httpOptions);
    }
}
