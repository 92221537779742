import { Component, OnInit, Input } from '@angular/core';
import { VoucherService } from '../voucher.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-voucher-payment-report',
  templateUrl: './voucher-payment-report.component.html',
  styleUrls: ['./voucher-payment-report.component.css']
})
export class VoucherPaymentReportComponent implements OnInit {
  //@Input() submissionUuid: string;
  submissionUuid: string
  constructor(private voucherService: VoucherService, private router: ActivatedRoute) { }

  data: any
  getPaymentReport() {
    //console.log(this.router.snapshot.queryParamMap.get('submissionUuid'))
    this.voucherService.voucherPaymentReport(this.submissionUuid).subscribe((result) => {
      if (result.success == true) {
        this.data = result.response[0]
      }
    })
  }

  ngOnInit() {
    this.submissionUuid = this.router.snapshot.queryParamMap.get('submissionUuid')
    this.getPaymentReport()
  }

}
