import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faExclamationCircle, faUndo } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-submission-maintenance-wizard-error',
    templateUrl: './submission-maintenance-wizard-error.component.html',
    styleUrls: ['./submission-maintenance-wizard-error.component.css']
})
export class SubmissionMaintenanceWizardErrorComponent implements OnInit {
    faExclamationCircle = faExclamationCircle
    faUndo = faUndo

    constructor(
        private router: Router,
    ) { }

    ngOnInit() {
    }

    returnToDashboard() {
        this.router.navigate(["/submission-schedule"])
    }
}
