import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AgencyService } from '../agency.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { faBan, faCheck, faKey, faLock, faPlus, faUnlock, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';
import { AssignRoleDialog } from './assign-role-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { SessionService } from '../session.service';

@Component({
    selector: 'app-manage-users',
    templateUrl: './manage-users.component.html',
    styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
    initialized = false
    faPlus = faPlus
    faUsers = faUsers
    agencySelectValues = [{ id: "all", name: "(All)" }]
    agencySelectFC = new UntypedFormControl(null)
    agencies

    pageSelect = new FormControl(true);

    constructor(
        private router: Router,
        private agencyService: AgencyService,
        private userService: UserService,
        private _snackBar: MatSnackBar,
        private authService: AuthService,
        private dialog: MatDialog,
        private sessionService: SessionService
    ) { }

    mapOrganization(row) {
        var agency = <any>{}
        agency.uuid = row.org_uuid
        agency.name = row.name
        // agency.address = row.address
        // agency.photo_uuid = row.photo_uuid
        // agency.owner_uuid = row.owner_uuid
        // agency.organization_type_id = row.organization_type_id
        // agency.organization_status_id = row.organization_status_id
        // agency.wbe = row.wbe
        // agency.wbe_date = row.wbe_date
        // agency.mbe = row.mbe
        // agency.mbe_date = row.mbe_date
        // agency.creator = row.creator
        // agency.created = row.created
        // agency.modifier = row.modifier
        // agency.modified = row.modified

        agency.users = []
        return agency
    }

    getUserActions(user) {

        var reset = {
            display: "Reset Password",
            icon: faKey,
        }
        var lock = {
            display: "Lock Account",
            icon: faLock,
        }
        var unlock = {
            display: "Unlock Account",
            icon: faUnlock,
        }
        var activate = {
            display: "Activate User",
            icon: faCheck,
        }
        var inactivate = {
            display: "Inactivate User",
            icon: faBan,
        }

        var userActions = [
            {
                display: "View User",
                icon: faUser,
            }
        ]
        if (this.canUserPerformAction("reset-user-password") || this.authService.userId == user.uuid) {
            userActions.push(reset)
        }
        if (this.canUserPerformAction("inactivate-user")) {
            switch (user.user_status_id) {
                case "A":
                    userActions.push(lock)
                    userActions.push(inactivate)
                    break
                case "I":
                    userActions.push(lock)
                    userActions.push(activate)
                    break
                case "L":
                    userActions.push(unlock)
                    userActions.push(inactivate)
                    break
            }
        }
        return userActions
    }

    //todo - need special rows for overlapping fields, creator, etc
    mapUser(row) {
        var user = <any>{}
        user.uuid = row.user_uuid
        user.full_name = row.full_name
        user.last_login = row.last_login
        user.email = row.email
        user.user_status_id = row.user_status_id
        // user.phone = row.phone
        // user.photo_id = row.photo_id
        // user.password = row.password
        // user.question_1 = row.question_1
        // user.answer_1 = row.answer_1
        // user.question_2 = row.question_2
        // user.answer_2 = row.answer_2
        // user.question_3 = row.question_3
        // user.answer_3 = row.answer_3
        // user.password_reset_token = row.password_reset_token
        // user.temp_pass_hash = row.temp_pass_hash
        // user.invalid_login_count = row.invalid_login_count
        // user.creator = row.creator
        // user.created = row.created
        // user.modifier = row.modifier
        // user.modified = row.modified
        user.actionFC = new UntypedFormControl()
        user.actions = this.getUserActions(user)
        return user
    }

    viewableAgencyUuids = []

    getViewableAgenciesPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getViewableAgencies().subscribe((response) => {
                if (response.success) {
                    for (var i = 0; i < response.result.length; ++i) {
                        this.agencySelectValues.push({ id: response.result[i].uuid, name: response.result[i].name })
                        this.viewableAgencyUuids.push(response.result[i].uuid)
                    }
                    resolve(null)
                } else {
                    console.log("error when getting viewable agencies", response)
                    reject("error when getting viewable agencies")
                }
            })
        })
    }

    changeAgencySelection() {
        //console.log("change", this.agencySelectFC.value)
        this.agencyService.setAgencyFilter(this.agencySelectFC.value)
        if (this.agencyService.agencyFilter == "all") {
            this.getAllAgenciesAndUsers()
        } else {
            this.getSelectedAgencyAndUsers(this.agencySelectFC.value)
        }
    }

    getSelectedAgencyAndUsers(orgUuid) {
        this.agencyService.getAgenciesAndUsersByAgencyUuid(orgUuid).subscribe((response) => {
            if (response.success) {

                this.processAgencyResponse(response)

            } else {
                console.log("get agency error", response)
            }
        })
    }

    getAllAgenciesAndUsers() {
        this.agencyService.getAgenciesAndUsersByAgencyUuids(this.viewableAgencyUuids).subscribe((response) => {
            if (response.success) {
                this.processAgencyResponse(response)
            } else {
                console.log("get agency error", response)
            }
        })
    }

    private processAgencyResponse(response) {
        this.agencies = []
        var curAgency = null
        for (var i = 0; i < response.result.length; ++i) {
            if (!response.result[i].org_uuid) {
                continue
            }
            if (!curAgency || response.result[i].org_uuid != curAgency.uuid) {
                curAgency = this.mapOrganization(response.result[i])
                this.agencies.push(curAgency)
            }
            if (response.result[i].user_uuid) {
                curAgency.users.push(this.mapUser(response.result[i]))
            }
        }
    }

    userPermissions
    canAddUser
    canAddAgency
    getUserPermissionsPromise() {
        return new Promise((resolve, reject) => {
            this.userService.getUserPermissions().subscribe((response) => {
                if (response.success) {
                    this.userPermissions = response.result
                    this.canAddUser = this.canUserPerformAction('add-user')
                    this.canAddAgency = this.canUserPerformAction('add-agency-organization')
                    //console.log(response)
                } else {
                    console.log("get user permissions error")
                    console.log(response)
                }
                resolve(null)
            })
        })
    }


    assignRoleDialog(event: Event, userId: string, orgId: string) {
        event.preventDefault()
        
        console.log(userId, orgId)
        const dialogRef = this.dialog.open(AssignRoleDialog, {
            width: '550px',
            data: { userId: userId, orgId: orgId }
        })
        dialogRef.afterClosed().subscribe(_result => {
            console.log(_result)
        })
    }

    claims: any
    isAdmin: boolean = false
    ngOnInit() {
        this.claims = this.sessionService.getClaims()
        console.log('manage get claims', this.claims)

        if(this.claims.is_admin == 1) {
            this.isAdmin = true
        } else {
            this.isAdmin = false
        }

        console.log('manage is admin', this.isAdmin)

        this.getViewableAgenciesPromise().then(() => {
            return this.getUserPermissionsPromise()
        }).then(() => {
            this.agencySelectFC.setValue('all')
            // if (this.agencyService.agencyFilter) {
            //     for (var i = 0; i < this.viewableAgencyUuids.length; ++i) {
            //         if (this.viewableAgencyUuids[i] == this.agencyService.agencyFilter) {
            //             this.agencySelectFC.setValue(this.agencyService.agencyFilter)
            //             break;
            //         }
            //     }
            // }
            this.changeAgencySelection()
            this.initialized = true
        })
    }

    addAgencyClick() {
        this.router.navigate(["agency"])
    }

    addUserClick(organizationName, organizationUuid) {
        this.router.navigate(["user"], {
            queryParams: {
                createUserOrganizationUuid: organizationUuid,
                createUserOrganizationName: organizationName,
                viewMode: false,
            }
        })
    }

    actionChange(user, value) {
        switch (value.display) {
            case "View User":
                this.router.navigate(["/user"], { queryParams: { userUuid: user.uuid, viewMode: true } })
                break;
            case "Reset Password":
                this.userService.sendPasswordRecoveryEmail(user.email).subscribe((response) => {
                    if (response.success) {
                        var snack = "Reset password email has been sent to " + user.email
                        this._snackBar.open(snack, null, { duration: 2000, });
                    } else {
                        var snack = "Error occurred while sending reset password email to " + user.email + ": " + response
                        this._snackBar.open(snack, null, { duration: 2000, });
                    }
                })
                break
            case "Lock Account":
                this.userService.lockUser(user.uuid).subscribe((response) => {
                    if (response) {
                        var snack = "User " + user.email + " has been locked."
                        this._snackBar.open(snack, null, { duration: 2000, });
                        this.changeAgencySelection()
                    } else {
                        var snack = "Error occurred while locking user " + user.email + ": " + response
                        this._snackBar.open(snack, null, { duration: 2000, });
                    }
                })
                break
            case "Unlock Account":
                this.userService.activateUser(user.uuid).subscribe((response) => {
                    if (response) {
                        var snack = "User " + user.email + " has been unlocked."
                        this._snackBar.open(snack, null, { duration: 2000, });
                        this.changeAgencySelection()
                    } else {
                        var snack = "Error occurred while unlocking user " + user.email + ": " + response
                        this._snackBar.open(snack, null, { duration: 2000, });
                    }
                })
                break
            case "Inactivate User":
                this.userService.inactivateUser(user.uuid).subscribe((response) => {
                    if (response) {
                        var snack = "User " + user.email + " has been inactivated."
                        this._snackBar.open(snack, null, { duration: 2000, });
                        this.changeAgencySelection()
                    } else {
                        var snack = "Error occurred while inactivating user " + user.email + ": " + response
                        this._snackBar.open(snack, null, { duration: 2000, });
                    }
                })
                break
            case "Activate User":
                this.userService.activateUser(user.uuid).subscribe((response) => {
                    if (response) {
                        var snack = "User " + user.email + " has been activated."
                        this._snackBar.open(snack, null, { duration: 2000, });
                        this.changeAgencySelection()
                    } else {
                        var snack = "Error occurred while activating user " + user.email + ": " + response
                        this._snackBar.open(snack, null, { duration: 2000, });
                    }
                })
                break
        }
        user.actionFC.setValue(null)
    }

    private canUserPerformAction(actionId) {
        return this.userService.canUserPerformAction(this.userPermissions, actionId)
    }
}
