<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col-2 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faBuilding"></fa-icon>
            Agency
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">


            <app-input-text label="Name" [fc]="nameFC" [maxlength]="255">
            </app-input-text>

            <app-input-text label="Short Name" [fc]="shortName" [maxlength]="25"></app-input-text>

            <app-input-text-area label="Remit to Address" [fc]="addressFC" [maxlength]="255">
            </app-input-text-area>

            <div class="row edit-height mt-2">
                <div class="col-4 unedit-padding bold">
                    MBE Certified?
                </div>
                <div class="col-8">
                    <app-checkbox [fc]="mbeCertifiedFC"></app-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    MBE Certification Date
                </div>
                <div class="col-8 pb-2">
                    <app-input-date [fc]="mbeDateFC" [format]="InputDateFormat.none"></app-input-date>
                </div>
            </div>

            <div class="row edit-height mt-2">
                <div class="col-4 unedit-padding bold">
                    WBE Certified?
                </div>
                <div class="col-8">
                    <app-checkbox [fc]="wbeCertifiedFC"></app-checkbox>
                </div>
            </div>

            <div class="row">
                <div class="col-4 pb-2 unedit-padding bold">
                    WBE Certification Date
                </div>
                <div class="col-8 pb-2">
                    <app-input-date [fc]="wbeDateFC" [format]="InputDateFormat.none"></app-input-date>
                </div>
            </div>

            <app-input-enum label="Status" [fc]="statusFC" [enumValuesNameAndId]="agencyStatuses">
            </app-input-enum>
            <app-input-image label="Logo" [fc]="logoFC"></app-input-image>

            <div class="row mt-4" style="background-color: lightgrey;">
                <div class="col-auto mr-auto bold" style="font-size: 1.2em;">
                    Program
                </div>
                <div class="col-auto">
                    <app-input-enum *ngIf="canAddAgency" [fc]="newProgramFC" [enumValuesNameAndId]="addablePrograms"
                        [style]="'none'">
                    </app-input-enum>
                </div>
                <div class="col-auto">
                    <app-button3 *ngIf="canAddAgency" label="Add Program" [clickFunction]="addProgram.bind(this)"
                        [icon]="faSave" [small]="true">
                    </app-button3>
                </div>
            </div>
            <div *ngIf="addProgramError" class="row" style="background-color: lightgrey;">
                <div class="col" style="color: red">
                    {{addProgramError}}
                </div>
            </div>

            <span *ngFor="let program of agencyPrograms">
                <div class="row">
                    <div class="col-10">
                        {{program.name}}
                    </div>
                    <!-- <div class="col-2">
                        <app-button3 [icon]="faTimes" [clickFunction]="removeProgram.bind(this, program)"
                            [small]="true" [isDelete]="true">
                        </app-button3>
                    </div> -->
                </div>

                <div class="row" style="background-color: lightgrey;">
                    <div class="col-2" style="background-color: white;"></div>
                    <div class="col-auto mr-auto bold" style="font-size: 1.2em;">
                        County
                    </div>
                    <div class="col-auto pt-1">
                        <app-input-enum *ngIf="canAddAgency" [fc]="program.newCountyFC"
                            [enumValuesNameAndId]="program.addableCounties" [style]="'none'">
                        </app-input-enum>
                    </div>
                    <div class="col-auto">
                        <app-button3 *ngIf="canAddAgency" label="Add Jurisdiction"
                            [clickFunction]="addCounty.bind(this, program)" [icon]="faSave" [small]="true">
                        </app-button3>
                    </div>
                </div>
                <div *ngIf="program.addCountyError" class="row" style="background-color: lightgrey;">
                    <div class="col" style="color: red">
                        {{program.addCountyError}}
                    </div>
                </div>
                <div *ngFor="let county of program.counties" class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-6">
                        {{county.name}}
                    </div>
                    <div class="col-2">
                        <!-- <app-button3 *ngIf="canAddAgency" [icon]="faTimes" [clickFunction]="removeCounty.bind(this, program, county)"
                            [small]="true" [isDelete]="true">
                        </app-button3> -->
                    </div>
                </div>
            </span>

            <div *ngIf="saveError" class="row mt-2">
                <div class="col bold" style="color: red" align="center">
                    {{saveError}}
                </div>
            </div>

            <div class="row mt-4 mb-1">
                <div class="col mb-4" align="center">
                    <app-button3 class="mx-1" label="Cancel" [clickFunction]="cancel.bind(this)" [icon]="faBan">
                    </app-button3>
                    <app-button3 *ngIf="!agencyUuid" class="mx-1" label="Add Agency"
                        [clickFunction]="addAgency.bind(this)" [icon]="faSave" [isFinal]="true">
                    </app-button3>
                    <app-button3 *ngIf="agencyUuid" class="mx-1" label="Update Agency"
                        [clickFunction]="updateAgency.bind(this)" [icon]="faSave" [isFinal]="true">
                    </app-button3>
                </div>
            </div>
        </div>
    </div>
</div>