import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'createTable'
})
export class CreateTablePipe implements PipeTransform {

    transform(value: any, ...args: any[]): any {
        if (!value) {
            return value;
        }
        //return value.replace(/\n/g, "<br />");
        return value.replace(/,/g, ",\n").replace(/\n\n/g, "\n");
    }

}
