<span *ngIf="submission && statusesById">
    <div class="row">
        <div class="col-6">
            <div class="row py-2">
                <div class="col-4 bold">
                    Agency
                </div>
                <div class="col-8">
                    {{submission.agency_name}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    County
                </div>
                <div class="col-8">
                    {{submission.jurisdiction_name}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Description
                </div>
                <div class="col-8">
                    {{submission.submission_name}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Due Date
                </div>
                <div class="col-8">
                    {{submission.submission_due_date | date : 'M/d/y'}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Status
                </div>
                <div class="col-8">
                    <span class="status_badge" [ngClass]="['submission_status_' + submission.status_id ]">
                        {{submission.status_name | uppercase}}
                    </span>
                </div>
            </div>

            <div *ngIf="!hideRecertificationDate" class="row py-2">
                <div class="col-4 bold">
                    {{submission.agency_date_label}}
                </div>
                <div class="col-8">
                    {{submission.agency_date | date : 'M/d/y'}}
                </div>
            </div>

        </div>
        <div class="col-6">

            <div class="row py-2">
                <div class="col-4 bold">
                    Program (Contract)
                </div>
                <div class="col-8">
                    {{submission.program_name}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Submitted By
                </div>
                <div class="col-8" *ngIf="submission.status_id == 'SUBMITTED' || submission.status_id == 'APPROVED' || submission.status_id == 'MORE_INFO'">
                    {{submission.submitted_by}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Submitted Date
                </div>
                <div class="col-8">
                    {{submission.submission_date | date : 'M/d/y'}}
                </div>
            </div>
            <div class="row py-2">
                <div class="col-4 bold">
                    Approved By
                </div>
                <div class="col-8">
                    {{submission.approved_by}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Approved Date
                </div>
                <div class="col-8">
                    {{submission.approval_date | date : 'M/d/y'}}
                </div>
            </div>

            <app-void-submission
            *ngIf="submission.allow_void == 'Y' && submission.voidable == 'Y' && claims.organization_type == 'SUB'"
            [submissionUuid]="submissionUuid"
            [submission]="submission"
            style="float: right;">
            </app-void-submission>

        </div>
    </div>
</span>
