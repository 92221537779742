import { Component, OnInit, Input } from '@angular/core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmCreateElements } from './confirm-create-elements.component';

@Component({
  selector: 'app-confirm-create-dialog',
  templateUrl: './confirm-create-dialog.component.html',
  styleUrls: ['./confirm-create-dialog.component.css']
})
export class ConfirmCreateDialogComponent implements OnInit {
  faEdit = faEdit

  @Input() amendFlag: string
  @Input() submissionUuid: string
  @Input() submissionType: string
  @Input() status: string
  @Input() sysArea: string
  @Input() budgetId: string

  dialogName: string
  dialogMessage: string

  constructor(public dialog: MatDialog) { }

  confirmCreateDialog() {
    if(this.submissionType == 'Budget') {
      this.dialogName = 'budget amendment'
      this.dialogMessage = 'budget amendment, will delete any voucher entries that are in a draft or revisions requested status'
    } else if(this.submissionType == 'Voucher') {
      this.dialogName = 'voucher'
      this.dialogMessage = 'voucher'
    }

    this.dialog.open(ConfirmCreateElements, {
      width: "400px",
      data: {
        dialogName: this.dialogName,
        dialogMessage: this.dialogMessage,
        submissionUuid: this.submissionUuid,
        submissionType: this.submissionType,
        status: this.status,
        sysArea: this.sysArea,
        budgetId: this.budgetId
      }
    }).afterClosed().subscribe(_result => { window.location.reload() })
  }


  ngOnInit() {
  }

}

