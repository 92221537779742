import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PersonnelService } from '../services/personnel.service';
import { SessionService } from '../session.service';
import { CommentService } from '../services/comment.service';

@Component({
    selector: 'app-flag-comment-dialog',
    templateUrl: './flag-comment-dialog.component.html',
    styleUrls: ['./flag-comments.component.css']
  })
  export class FlagCommentDialog implements OnInit 
  {
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: {
        masterUuid: string,
        submissionUuid: string,
        targetColumn: string,
        comment: string,
        userUuid: string,
        userName: string
      },
      private personnelService: PersonnelService,
      private sessionService: SessionService,
      private commentService: CommentService,
      private dialogRef: MatDialogRef<FlagCommentDialog>
    ) {}
  
    commentExists: boolean
    userUuid: string
    userName: string
  
    comment = new UntypedFormControl()
  
    saveComment() {
      const commentValue = this.comment.value
  
      // console.log(this.data.targetColumn)
      this.personnelService.addPersonnelComment(
        this.data.masterUuid,
        this.data.submissionUuid,
        this.data.targetColumn,
        commentValue,
        this.userUuid,
        this.userName
      ).subscribe(response => {
        console.log(response)
        if(response.success === true) {
          console.log('success')
          this.dialogRef.close('success')
        } else {
          console.log(response.error)
        } 
      })
    }

    deleteComment() {
      this.personnelService.deletePersonnelComment(
        this.data.masterUuid,
        this.data.submissionUuid,
        this.data.targetColumn
      ).subscribe(response => {
        if(response.success === true) {
          console.log('flag deleted successfully')
          this.dialogRef.close('deleted')
        } else {
          console.log(response.error)
        }
      })
    }
    
    orgType: string
    ngOnInit() {
        this.personnelService.getPersonnelComment(this.data.masterUuid, this.data.submissionUuid, this.data.targetColumn).subscribe(response => {
            // console.log(response)
            if(response.success === true) {
              this.commentExists = true
              this.comment.setValue(response.result)
              // console.log(this.commentExists, this.comment.value)
            } else {
              this.commentExists = false
            }
            this.commentService.setCommentExists(this.commentExists)
        })
        
        const claims = this.sessionService.getClaims()

        this.orgType = claims.organization_type
  
        if(claims) {
            this.userUuid = claims.user_id
            this.userName = claims.user_name
        }



        if(this.orgType == 'SUB') {
          this.comment.disable()
        }
    }
  }
  
  
  