<div class="container-fluid">
  <div class="row page-title">
    <div class="col">
          <fa-icon [icon]="faUser"></fa-icon>
          Budget Personnel
      </div>
  </div>
  <div class="row">
      <div class="col">
          <fa-icon [icon]="faUser" class="mr-2" title="Employee Details" style="padding-left: 10px;">
          </fa-icon>
          <h4 style="display: inline-block;">Employee Details</h4>
      </div>
  </div>

  <div class="form-container">
      <form [formGroup]="employeeDetails">
          <div class="form-field">
            <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
              <mat-label>Employee Title</mat-label>
              <input matInput formControlName="employeeTitle" required>
              <mat-error *ngIf="employeeDetails.get('employeeTitle').hasError('required')">Employee Title is a required field</mat-error>
            </mat-form-field>

              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="employee_title">
              </app-flag-comments>

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>Employee Name</mat-label>
                <input matInput  formControlName="employeeName" required>
                <mat-error *ngIf="employeeDetails.get('employeeName').hasError('required')">Employee Name is a required field</mat-error>
              </mat-form-field>
              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="employee_name">
              </app-flag-comments>
          </div>
          
          <div class="form-field">
              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>Start Date on NOEP</mat-label>
                <input matInput [matDatepicker]="picker1" formControlName="hireDate" required>
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-error *ngIf="employeeDetails.get('hireDate').errors?.invalidDate">
                  Start date is after End Date
                </mat-error>
                <mat-error *ngIf="employeeDetails.get('hireDate').hasError('required')">Start Date is a required field</mat-error>
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="hire_date">
              </app-flag-comments>
            

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>End Date on NOEP</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="terminationDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="employeeDetails.get('terminationDate').errors?.invalidDate">
                  End Date is before Start Date
                </mat-error>
              </mat-form-field> 

              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="termination_date">
              </app-flag-comments>             
          </div>
          
          <div class="radio-group">
              <label>Personnel Type</label>
              <mat-radio-group formControlName="personnelType">
                <mat-radio-button value="NOEP_PRSNL_OH" (change)="getPersonnelType($event)">Administrative</mat-radio-button>
                <mat-radio-button value="NOEP_PRSNL" (change)="getPersonnelType($event)">Program</mat-radio-button>
              </mat-radio-group>
          </div>
          
          <div class="radio-group">
              <label>Contract Time Allocation</label>
              <mat-radio-group formControlName="contractTimeAllocation">
                <mat-radio-button value="equalTo100">Employee is allocated exclusively to the contract (100%)</mat-radio-button>
                <mat-radio-button value="lessThan100">Employee allocation is shared across multiple contracts (less than 100%)</mat-radio-button>
              </mat-radio-group>
          </div>

          <div class="form-field" [ngClass]="{'form-field-disabled': employeeDetails.controls.estimatedTimeOnContract.disabled}">
              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>Estimated % Time on Contract</mat-label>
                <input matInput type="number" formControlName="estimatedTimeOnContract" required>
                <mat-error *ngIf="employeeDetails.get('estimatedTimeOnContract').hasError('required')">Estimated % Time on Contract is a required field</mat-error>
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag && !employeeDetails.controls.estimatedTimeOnContract.disabled"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="percent_time_on_contract">
              </app-flag-comments>
          </div>
      </form>
  </div>

  <hr />
  <div class="row mt-2">
      <div class="col">
          <fa-icon [icon]="faMoneyBillWave" class="mr-2" title="Payroll Information" style="padding-left: 10px;">
          </fa-icon>
          <h4 style="display: inline-block;">Payroll Information</h4>
      </div>
  </div>

  <div class="row" *ngIf="hideButtons == false">
      <div class="col" align="center" style="padding-bottom: 10px;">
        <app-button3 class="mx-1" label="Salary" [icon]="faMoneyBillWave" [isFinal]="true"
          [unselected]="selectedButton !== 'salary'"
          (click)="selectButton('salary')">
        </app-button3>
        <app-button3 class="mx-1" label="Hourly" [icon]="faHourglass" [isFinal]="true"
          [unselected]="selectedButton !== 'hourly'"
          (click)="selectButton('hourly')">
        </app-button3>
      </div>
  </div>

  <div class="form-container">
      <form [formGroup]="payrollInfo">
          <div class="form-field">
              <mat-form-field class="employee-input" appearance="fill">
                <mat-label>Pay Period</mat-label>
                <mat-select formControlName="payPeriod" required>
                  <mat-option *ngFor="let option of payPeriodOptions" [value]="option.id">
                      {{ option.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="payrollInfo.get('payPeriod').hasError('required')">Pay Period is a required field</mat-error>
              </mat-form-field>
              
              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="pay_period">
              </app-flag-comments>


              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>{{ payRateOrSalaryLabel }}</mat-label>
                <input matInput type="number" formControlName="payRateOrSalary" required>
                <span matPrefix>$&nbsp;</span>
                <mat-error *ngIf="payrollInfo.get('payRateOrSalary').hasError('required')">Hourly Rate / Annual Salary is a required field</mat-error>
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag && selectedButton == 'salary'"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="annual_salary">
              </app-flag-comments>

              <app-flag-comments *ngIf="showFlag && selectedButton == 'hourly'"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="hourly_rate">
              </app-flag-comments>
          </div>

          <div class="form-field">
              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>Estimated Hours Per Day</mat-label>
                <input matInput type="number" formControlName="hoursPerDay" required>
                <mat-error *ngIf="payrollInfo.get('hoursPerDay').hasError('required')">Estimated Hours Per Day is a required field</mat-error>
              </mat-form-field>
              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="hours_per_day">
              </app-flag-comments>

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>{{ salaryOrPayRateLabel }}</mat-label>
                <input matInput type="number" formControlName="salaryOrPayRate">
                <span matPrefix>$&nbsp;</span>
              </mat-form-field>
          </div>      

          <div class="center-checkbox">
              <mat-checkbox formControlName="receivingRaise">
                  We anticipate the employee will receive a raise this year?
              </mat-checkbox>             
          </div>

          <div class="form-field" [ngClass]="{'form-field-disabled': payrollInfo.controls.newHourlyRateOrSalary.disabled}">
              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>{{ newHourlyRateOrSalaryLabel }}</mat-label>
                <input matInput type="number" formControlName="newHourlyRateOrSalary">
                <span matPrefix>$&nbsp;</span>
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag && selectedButton == 'salary'"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="new_annual_salary">
              </app-flag-comments>

              <app-flag-comments *ngIf="showFlag && selectedButton == 'hourly'"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="new_hourly_rate">
              </app-flag-comments>


              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>Estimated Date of Raise</mat-label>
                <input matInput [matDatepicker]="picker3" formControlName="estimatedRaiseDate">
                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                <mat-datepicker #picker3></mat-datepicker>
                <mat-error *ngIf="payrollInfo.get('estimatedRaiseDate').errors?.invalidDate">
                  Estimated Raise Date is before Start Date or after end End Date
                </mat-error>
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="raise_date">
              </app-flag-comments>

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                  <mat-label>{{ newSalaryOrHourlyRateLabel }}</mat-label>
                  <input matInput type="number" formControlName="newSalaryAmountOrHourlyRate">
                  <span matPrefix>$&nbsp;</span>
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag && selectedButton == 'salary'"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="new_hourly_rate">
              </app-flag-comments>

              <app-flag-comments *ngIf="showFlag && selectedButton == 'hourly'"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="new_annual_salary">
              </app-flag-comments>
          </div>

          <div class="center-checkbox">
              <mat-checkbox formControlName="receivingBonus">
                  We anticipate the employee will receive a bonus this year?
              </mat-checkbox>             
          </div>

          <div class="form-field" [ngClass]="{'form-field-disabled': payrollInfo.controls.estimatedBonusAmount.disabled}">
            <app-flag-comments *ngIf="showFlag"
            [masterUuid]="masterUuid" 
            [submissionUuid]="submissionUuid"
            targetColumn="bonus_amount">
            </app-flag-comments>

            <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
              <mat-label>Estimated Bonus Amount</mat-label>
              <input matInput type="number" formControlName="estimatedBonusAmount">
              <span matPrefix>$&nbsp;</span>
            </mat-form-field>
          </div>
          
      </form>
  </div>

  <hr />
  <div class="row">
      <div class="col">
          <fa-icon [icon]="faFileSignature" class="mr-2" title="Contract Information" style="padding-left: 10px;">
          </fa-icon>
          <h4 style="display: inline-block;">Contract Information</h4>
      </div>
  </div>

  <!-- Contract Info Table -->
  <div class="contractInfoContainer">
    <table>
      <tr>
        <th>Estimated # of Hours Per Pay Period</th>
        <th>Estimated # of Annual Hours on Contract</th>
        <th>Calculated Amount</th>
      </tr>
      <tr>
        <td>{{estimatedHoursPerPayPeriod}}</td>
        <td>{{annualHoursOnContract}}</td>
        <td>{{calculatedAmount | currency}}</td>
      </tr>
    </table>
      <!-- <form [formGroup]="contractInfo">
          <div class="form-field">
              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label class="dontHide">Estimated # of Hours Per Pay Period on Contract</mat-label>
                {{estimatedHoursPerPayPeriod | currency}}
                <input class="dontHide" matInput type="number" formControlName="estimatedHoursPerPayPeriod">
              </mat-form-field>

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label class="dontHide">Estimated # of Annual Hours on Contract</mat-label>
                <input class="dontHide" matInput type="number" formControlName="annualHoursOnContract">
              </mat-form-field>

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                  <mat-label class="dontHide">Calculated Amount</mat-label>
                  <input class="dontHide" matInput type="number" formControlName="calculatedAmount" appNumberFormat>
                  <span matPrefix>$&nbsp;</span>
              </mat-form-field>
          </div>
      </form> -->
  </div>

  <hr />
  <div class="row">
      <div class="col">
          <fa-icon [icon]="faStamp" class="mr-2" title="Total Allocation" style="padding-left: 10px;">
          </fa-icon>
          <h4 style="display: inline-block;">Total Allocation</h4>
      </div>
  </div>

  <div class="form-container">
      <form [formGroup]="totalAllocation">
          <div class="form-field">
              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>Amount Charged to Contract</mat-label>
                <input matInput type="number" formControlName="amountChargedToContract" required>
                <span matPrefix>$&nbsp;</span>
                <mat-error *ngIf="isInvalidTotal('amountChargedToContract')">
                  Total of State Only and State Match/Federal Match must be equal to Amount Charged to Contract
                </mat-error>
                <mat-error *ngIf="totalAllocation.get('amountChargedToContract').hasError('invalidAmount')">
                  Amount Charged to Contract cannot exceed Calculated Amount
                </mat-error>
                <mat-error *ngIf="totalAllocation.get('amountChargedToContract').hasError('required')">Amount Charged to Contract is a required field</mat-error>
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="unit_price">
              </app-flag-comments>

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                <mat-label>State Only</mat-label>
                <input matInput type="number" formControlName="stateOnly" required>
                <span matPrefix>$&nbsp;</span>
                <mat-error *ngIf="isInvalidTotal('stateOnly')">
                  Total of State Only and State Match/Federal Match must be equal to Amount Charged to Contract
                </mat-error>
    
              </mat-form-field>

              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="debit_state_est">
              </app-flag-comments>

              <mat-form-field class="employee-input" appearance="fill" floatLabel="always">
                  <mat-label>State Match/Federal Match</mat-label>
                  <input matInput type="number" formControlName="stateFedMatch" required>
                  <span matPrefix>$&nbsp;</span>
                  <mat-error *ngIf="isInvalidTotal('stateFedMatch')">
                    Total of State Only and State Match/Federal Match must be equal to Amount Charged to Contract
                  </mat-error>
                  <mat-error *ngIf="totalAllocation.get('stateFedMatch').hasError('required')">State Only is a required field</mat-error>
              </mat-form-field>
              
              <app-flag-comments *ngIf="showFlag"
              [masterUuid]="masterUuid" 
              [submissionUuid]="submissionUuid"
              targetColumn="debit_federal_est">
              </app-flag-comments>
          </div>

          <div class="form-field">
            <mat-form-field appearance="fill" floatLabel="always" class="comment-textarea">
              <mat-label>Agency Comment</mat-label>
              <textarea matInput type="text" formControlName="agencyComment"></textarea>
            </mat-form-field> 
          </div>
      </form>
  </div>

  <table mat-table
  *ngIf="sequence == 0; else all_rows"
  [dataSource]="allocationTableData"
  class="mat-elevation-z8 original-budget-table"
  style="margin: 0 auto;">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let allocation"> {{allocation.title}} </td>
    </ng-container>

    <ng-container matColumnDef="new">
      <th mat-header-cell *matHeaderCellDef> New </th>
      <td mat-cell *matCellDef="let allocation"> {{allocation.new | currency}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="firstColumnsDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: firstColumnsDisplay;" [ngClass]="{'bold-row': isLastRow(row)}"></tr>
  </table>

  <ng-template #all_rows>
    <table mat-table 
      [dataSource]="allocationTableData" 
      class="mat-elevation-z8" 
      style="margin: 0 auto;">
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let allocation"> {{allocation.title}} </td>
        </ng-container>
      
        <ng-container matColumnDef="new">
          <th mat-header-cell *matHeaderCellDef> New </th>
          <td mat-cell *matCellDef="let allocation"> {{allocation.new | currency}} </td>
        </ng-container>
      
        <ng-container matColumnDef="previous">
          <th mat-header-cell *matHeaderCellDef> Previous </th>
          <td mat-cell *matCellDef="let allocation"> {{allocation.previous | currency}} </td>
        </ng-container>
      
        <ng-container matColumnDef="change">
          <th mat-header-cell *matHeaderCellDef> Change </th>
          <td mat-cell *matCellDef="let allocation"> {{allocation.change | currency}} </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="allColumnsDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: allColumnsDisplay;" [ngClass]="{'bold-row': isLastRow(row)}"></tr>
    </table>
  </ng-template>


  <div class="calculation-worksheet">
      <app-personnel-calculation-worksheet
      [annualSalary]="annualSalary"
      [numberOfPaychecks]="numberOfPaychecks"
      [hoursPerPayPeriod]="hoursPerPayPeriod"
      [newAnnualSalary]="newAnnualSalary"
      [preRaiseDays]="preRaiseDays"
      [hoursPerDay]="hoursPerDay"
      [preRaiseHourlyRate]="preRaiseHourlyRate"
      [postRaiseDays]="postRaiseDays"
      [postRaiseHourlyRate]="postRaiseHourlyRate"
      [bonus]="bonus"
      [estimatedPercentTimeOnContract]="estimatedPercentTimeOnContract"
      [hourlyRateFromSalaryTotal]="hourlyRateFromSalaryTotal"
      [newHourlyRateFromSalaryTotal]="newHourlyRateFromSalaryTotal"
      [preRaiseAmountTotal]="preRaiseAmountTotal"
      [postRaiseAmountTotal]="postRaiseAmountTotal"
      [calculatedAmountTotal]="calculatedAmountTotal"
      [receiveRaise]="receiveRaise"
      [receiveBonus]="receiveBonus"
    ></app-personnel-calculation-worksheet>
  </div>

  <div class="fringe" *ngIf="hideFringe == false">
      <app-personnel-fringe
      [amountChargedToContract]="amountChargedToContract"
      [estimatedPercentTimeOnContract]="estimatedPercentTimeOnContract"
      [masterUuid]="masterUuid"
      [submissionUuid]="submissionUuid"
      [accountUuid]="accountUuid"
      [fringeAmount]="fringeAmount"
      [showFlag]="showFlag"
      [status]="status">
      </app-personnel-fringe>
  </div>


  <div class="center-container">
    <div *ngIf="this.organizationType == 'PRIME' || this.organizationType == 'SYSTEM'">
      <mat-checkbox [formControl]="userFlag" [id]="'user_flag'">
        Apply user flag to record (HSNY use only – line will be highlighted on summary page)
      </mat-checkbox>  
    </div>
  </div>
  
  <!-- add *ngIf="editable" -->
  <span *ngIf="hideButtons == false">
    <div *ngIf="status != 'SUBMITTED';else ok_button" class="mt-2 mb-3" style="width: 100%;" align="center"> 
        <app-button3 class="mx-1" label="Delete" [icon]="faMinus" [isDelete]="true" (click)="openDeleteDialog()">
        </app-button3>
        <app-button3 class="mx-1" label="Cancel"  [icon]="faBan" (click)="cancel()">
        </app-button3>
        <app-button3 class="mx-1" label="Save" [icon]="faSave" (click)="onSave()">
        </app-button3>
        <app-button3 class="mx-1" label="Save & Exit" [icon]="faSave" (click)="saveAndExit()">
        </app-button3>
    </div>
  </span>
  <ng-template #ok_button>
    <div class="mt-2 mb-3" style="width: 100%" align="center">
      <app-button3 class="mx-1" label="Ok" [icon]="faCheck" (click)="goBack()">
      </app-button3>
    </div>
  </ng-template>
  <div *ngIf="saveError === false">
    <p style="color: red; text-align: center;">{{ errorMessage }}</p>
  </div>
<!-- 
  <div *ngIf="!editable && !hideButtons" class="mt-2 mb-3" style="width: 100%;" align="center">
      <app-button3 class="mx-1" label="Ok" [icon]="faCheck">
      </app-button3>
  </div> -->
</div>

  
