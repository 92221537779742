<h1 mat-dialog-title>Edit User Status</h1>
<mat-dialog-content>
  <form [formGroup]="userForm">
    <mat-form-field appearance="fill" class="custom-mat-field">
      <mat-label>User Status</mat-label>
      <mat-select formControlName="status" required>
        <mat-option *ngFor="let status of statusOptions" [value]="status.id">
          {{ status.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="userForm.get('status').hasError('required')">
        Please select an Status
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="updateUserStatus()">Update</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>