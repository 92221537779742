import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard.service';
import { AgencyService } from '../agency.service';
import { UntypedFormControl } from '@angular/forms';
import { SubmissionService } from '../submission.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { faBalanceScaleRight, faBook, faEdit, faFileAlt, faHandsHelping, faHome, faListAlt, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { BudgetService } from '../budget.service';
import { OutreachService } from '../outreach.service';
import { FiscalYearService } from '../services/fiscal-year.service';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../session.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
    initialized = false
    faHome = faHome
    faListAlt = faListAlt
    faBook = faBook
    faBalanceScaleRight = faBalanceScaleRight
    faEdit = faEdit

    faHandsHelping = faHandsHelping

    userOrganizationType
    userOrganization
    dash
    statuses
    statusesById

    showOutreachBudget = false

    lists = [
        {
            title: "Program",
            submission_type_id: "NOEP_PROGRAM",
            sortField: "display_date",
            sortDirection: 0,
            sortField2: "name",
            sortDirection2: 0,
            icon: faBook,
            columns: [
                {
                    display: "Description",
                    fieldName: "name",
                    styleClass: "description"
                }, {
                    display: "",//"Agency Ref",
                    fieldName: "reference_number",
                    styleClass: "reference-number"
                }, {
                    display: "Status",
                    fieldName: "status_name",
                    styleClass: "county"
                }, {
                    display: "County",
                    fieldName: "jurisdiction_name",
                    styleClass: "status-long"
                }, {
                    display: "Due Date",
                    fieldName: "display_date",
                    styleClass: ""
                }
            ],
            rows: [],
        }, {
            title: "Document",
            submission_type_id: "MASTER_DOCUMENT",
            sortField: "display_date",
            sortDirection: 0,
            sortField2: "name",
            sortDirection2: 0,
            icon: faFileAlt,
            columns: [
                {
                    display: "Document Name",
                    fieldName: "name",
                    styleClass: "description"
                }, {
                    //display: "Agency Ref",
                    display: "",
                    fieldName: "reference_number",
                    styleClass: "reference-number-long"
                }, {
                    display: "Status",
                    fieldName: "status_name",
                    styleClass: "status-long"
                }, {
                    display: "Due Date",
                    fieldName: "display_date",
                    styleClass: ""
                }, {
                    display: "Updated By",
                    fieldName: "updated_by",
                    styleClass: ""
                }
            ],
            rows: [],
        }, {
            title: "Outreach",
            submission_type_id: "NOEP_OUTREACH",
            sortField: "display_date",
            sortDirection: 0,
            sortField2: null,
            sortDirection2: 0,
            icon: faHandsHelping,
            columns: [
                {
                    display: "Slogan",
                    fieldName: "name",
                    styleClass: "description"
                }, {
                    display: "#",
                    fieldName: "reference_number",
                    styleClass: "reference-number"
                }, {
                    display: "County",
                    fieldName: "jurisdiction_name",
                    styleClass: "county"
                }, {
                    display: "Status",
                    fieldName: "status_name",
                    styleClass: "status"
                }, {
                    display: "Total",
                    fieldName: "total",
                    styleClass: "total"
                }, {
                    display: "Due Date",
                    fieldName: "display_date",
                    styleClass: ""
                }, {
                    display: "Date Placed",
                    fieldName: "agency_date",
                    styleClass: ""
                }
            ],
            rows: [],
        }, {
            title: "Budget",
            submission_type_id: "NOEP_BUDGET",
            sortField: "display_date",
            sortDirection: 0,
            sortField2: "name",
            sortDirection2: 0,
            icon: faBalanceScaleRight,
            columns: [
                {
                    display: "Description",
                    fieldName: "name",
                    styleClass: "description"
                }, {
                    display: "Status",
                    fieldName: "status_name",
                    styleClass: "reference-number"
                }, {
                    display: "State Only",
                    fieldName: "total",
                    styleClass: "county"
                }, {
                    display: "State / Federal Match",
                    fieldName: "status",
                }, {
                    display: "Total",
                    fieldName: "total",
                    styleClass: "total"
                }, {
                    display: "Due Date",
                    fieldName: "display_date",
                    styleClass: ""
                }, {
                    display: "Updated By",
                    fieldName: "updated_by",
                    styleClass: ""
                }, {
                    display: "",//actions (i.e., amend button)
                    fieldName: "",
                    styleClass: ""
                }
            ],
            rows: [],
        }, {
            title: "Voucher",
            submission_type_id: "NOEP_VOUCHER",
            sortField: "display_date",
            sortDirection: 0,
            sortField2: "name",
            sortDirection2: 0,
            icon: faTicketAlt,
            columns: [
                {
                    display: "Description",
                    fieldName: "name",
                    styleClass: "description"
                }, {
                    display: "Status",
                    fieldName: "status_name",
                    styleClass: "reference-number"
                }, {
                    display: "State Only",
                    fieldName: "total",
                    styleClass: "county"
                }, {
                    display: "State / Federal Match",
                    fieldName: "status",
                }, {
                    display: "Total",
                    fieldName: "total",
                    styleClass: "total"
                }, {
                    display: "Due Date",
                    fieldName: "display_date",
                    styleClass: ""
                }, {
                    display: "Updated By",
                    fieldName: "updated_by",
                    styleClass: ""
                }, {
                    display: "Paid",
                    fieldName: "paid",
                    styleClass: ""
                }, {
                    display: "",//actions (i.e., amend button)
                    fieldName: "",
                    styleClass: ""
                }
            ],
            rows: [],
        },
    ]

    cacheDashboardSort(dashboard, column, direction, column2, direction2) {
        localStorage.setItem("dashboard_sort_col_" + dashboard, column)
        localStorage.setItem("dashboard_sort_dir_" + dashboard, direction)
        localStorage.setItem("dashboard_sort_col2_" + dashboard, column2)
        localStorage.setItem("dashboard_sort_dir2_" + dashboard, direction2)
    }

    cacheDashboardSortByIndex(listViewIndex) {
        var column = this.lists[listViewIndex].sortField
        var direction = this.lists[listViewIndex].sortDirection
        var column2 = this.lists[listViewIndex].sortField2
        var direction2 = this.lists[listViewIndex].sortDirection2
        this.cacheDashboardSort(listViewIndex, column, direction, column2, direction2)
    }

    fetchCachedDashboardPageSort() {
        for (var i = 0; i < this.lists.length; ++i) {
            var sortColumn = localStorage.getItem("dashboard_sort_col_" + i);
            if (sortColumn) {
                this.lists[i].sortField = sortColumn;
            }
            var sortDirection = localStorage.getItem("dashboard_sort_dir_" + i);
            if (sortDirection) {
                this.lists[i].sortDirection = parseInt(sortDirection);
            }

            var sortColumn2 = localStorage.getItem("dashboard_sort_col2_" + i);
            if (sortColumn2) {
                this.lists[i].sortField2 = sortColumn2;
            }
            var sortDirection2 = localStorage.getItem("dashboard_sort_dir2_" + i);
            if (sortDirection2) {
                this.lists[i].sortDirection2 = parseInt(sortDirection2);
            }
        }
    }

    constructor(
        private dashboardService: DashboardService,
        private agencyService: AgencyService,
        private submissionService: SubmissionService,
        private router: Router,
        private authService: AuthService,
        private budgetService: BudgetService,
        private outreachService: OutreachService,
        private fiscalYearService: FiscalYearService,
        private sessionService: SessionService,
        private dialog: MatDialog,
    ) { }
    
    // fiscal year handling (should use jwt claim)
    fiscalYearOptions: any;
    selectedYear: string;
    getFiscalYearDropDown(): void {
        this.fiscalYearService.getFiscalYearOptions().subscribe((response) => {
            this.fiscalYearOptions = response.result;
            // Remove 'NONE' option if present
            this.fiscalYearOptions = this.fiscalYearOptions.filter((option: any) => option.id !== 'NONE');

            // Set the selectedYear to the saved fiscal year or the first option
            const savedFiscalYear = this.sessionService.getFiscalYear();

            if(savedFiscalYear) {
                this.selectedYear = savedFiscalYear
            } else {
                this.selectedYear = this.fiscalYearOptions[0].id;
            }

            // this.selectedYear = savedFiscalYear || this.fiscalYearOptions[0].id;
        });
      }

    fiscalYearChange() {
        this.sessionService.setFiscalYear(this.selectedYear);
        this.getDashboardPromise();
      }

    setFiscalYear() {
        this.fiscalYearService.setFiscalYear().subscribe((response) => {
            return response.result
        })
    }


    openDialog(): void
    {
        const dialogRef = this.dialog.open(TempDashboardDialog, {
            width: '550px',
        })
    }

    goToOutreachBudgetDetails()
    {
        const fiscalYearId = this.selectedYear;
        const organizationUuid = this.userOrganization[0].uuid;
    
        this.router.navigate(['/outreach-bank-account-report'], {
            queryParams: { organizationUuid, fiscalYearId }
        });
    }
    // Promises
    getStatusesPromise() {
        return new Promise((resolve, reject) => {
            this.dashboardService.getStatuses().subscribe((response) => {
                if (response.success) {
                    this.statuses = response.result
                    this.statusesById = {}
                    for (var i = 0; i < this.statuses.length; ++i) {
                        this.statusesById[this.statuses[i].id] = this.statuses[i]
                    }
                } else {
                    console.log("error while getting statuses", response)
                }
                resolve(null)
            })
        })
    }

    getListDataPromise(list) {
        return new Promise((resolve, reject) => {

            this.dashboardService.getDashboard(list.sortField,
                list.sortDirection,
                this.programFC.value,
                list.submission_type_id,
                this.selectedYear,
                list.sortField2,
                list.sortDirection2
                ).subscribe((response) => {
                    if (response.success)
                    {
                        list.rows = response.result
                        resolve(null)
                    } else {
                        reject(response)
                    }
                })
        })
    }

    getDashboardPromise() {
        var promises = []
        for (var i = 0; i < this.lists.length; ++i) {
            //this.lists[i].rows = []
            promises.push(this.getListDataPromise(this.lists[i]))
        }
        return Promise.all(promises)
    }

    programs
    programFC = new UntypedFormControl()
    getProgramsPromise() {
        return new Promise((resolve, reject) => {
            this.agencyService.getProgramsWithAll().subscribe((response) => {
                if (response.success) {
                    this.programs = response.result
                }
                if (this.programs && this.programs.length > 0) {
                    this.programFC.setValue(this.programs[0].id)
                }
                resolve(null)
            })
        })
    }

    changeProgram() {
        this.getDashboardPromise()
    }

    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    getUserOrganization() 
    {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizations().subscribe((response) => {
                if (response.success) {
                    this.userOrganization = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }
    
    outreachBudget
    outreachBalance
    estimatedBudget
    outreachState
    outreachFederal
    getOutreachBudget() {
        return this.outreachService.getOutreachBudget().then((response) => {
            this.outreachState = (<any>response)[0].outreach_state
            this.outreachFederal = (<any>response[0]).outreach_federal
            this.outreachBudget = (<any>response)[0].outreach_budget
            this.outreachBalance = (<any>response)[0].outreach_balance
            this.estimatedBudget = (<any>response)[0].estimated_budget
        })
    }
    // End promises

    sortClick(listIndex, fieldName) {
        if (this.lists[listIndex].sortField == fieldName) {
            if (this.lists[listIndex].sortDirection == 0) 
            {
                this.lists[listIndex].sortDirection = 1
            } else
            {
                this.lists[listIndex].sortDirection = 0
            }
            return this.getDashboardPromise()
        }
        this.lists[listIndex].sortDirection2 = this.lists[listIndex].sortDirection
        this.lists[listIndex].sortField2 = this.lists[listIndex].sortField
        this.lists[listIndex].sortDirection = 0;
        this.lists[listIndex].sortField = fieldName;
        return this.getDashboardPromise()
    }

    amendBudget(uuid) {
        this.budgetService.amendBudget(uuid).subscribe((response) => {
            this.getDashboardPromise()
        })
    }

    // Initialization
    ngOnInit() {
        if(this.sessionService.getDontShowDialog() == '0') {
            this.openDialog()
        }

        this.selectedYear = this.sessionService.getFiscalYear()



        this.getFiscalYearDropDown()
        this.fetchCachedDashboardPageSort()

        // this.openDialog();

        var promises = []
        promises.push(
            this.getProgramsPromise().then(() => {
                return this.getDashboardPromise()
            })

        )
        
        promises.push(this.getStatusesPromise())
        promises.push(this.getUserOrganizationType())
        promises.push(this.getUserOrganization())

        Promise.all(promises).then(() => {
            var outreachPromise = Promise.resolve()
            if (this.userOrganizationType == 'PRIME' || this.userOrganizationType == 'SYSTEM') {
                for (var i = 0; i < this.lists.length; ++i) {
                    this.lists[i].columns.unshift({
                        display: "Agency Name",
                        fieldName: "organization_name",
                        styleClass: ""
                    })

                    this.lists[i].columns.forEach(column => {
                        if(column.display == 'Due Date') {
                            column.display = 'Submit Date'
                        }
                    })

                    if(this.lists[i].title == 'Outreach') {
                        this.lists[i].sortField = 'display_date'
                        this.lists[i].sortField2 = null
                        this.lists[i].sortDirection = 1
                    }
                    if(this.lists[i].title == 'Budget') {
                        this.lists[i].sortField = 'organization_name'
                        this.lists[i].sortField2 = 'display_date'
                    }

                    if(this.lists[i].title == 'Voucher') {
                        this.lists[i].sortField = 'organization_name'
                        this.lists[i].sortField2 = 'display_date'
                    }

                }
            } else {
                outreachPromise = this.getOutreachBudget()
            }
            this.initialized = true
        })
    }
    // Initialization End
}

@Component({
    selector: 'app-dashboard-temp-dialog',
    templateUrl: './temp-dashboard-dialog.component.html',
    styleUrls: ['./dashboard.component.css'],
})
 export class TempDashboardDialog 
 {

    dontShow = new UntypedFormControl();
    isChecked: string;


    constructor(private sessionService: SessionService) {}

    ngOnInit() {
        this.dontShow.valueChanges.subscribe((checked) => {
            if(checked === true) {
                this.isChecked = '1'
                this.sessionService.setDontShowDialog(this.isChecked)

            } else {
                this.isChecked = '0'
                this.sessionService.setDontShowDialog(this.isChecked)
            }
        })
    }
 }
