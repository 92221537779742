<div class="row">
    <div class="col" style="font-size: 1.2em;">
        <fa-icon [icon]="faFile" class="mr-2"></fa-icon>
        Attachments
    </div>
</div>

<div class="row" style="background-color: lightgrey;">
    <div class="col-3" (click)="sortClick('document_description')">
        Description
        <app-sort-indicator 
            [sortFieldName]="sortField" 
            [sortIsDescending]="sortDirection"
            columnFieldName="document_description">
        </app-sort-indicator>
    </div>
    <div class="col-3" (click)="sortClick('created')">
        Created<app-sort-indicator [sortFieldName]="sortField" [sortIsDescending]="sortDirection"
            columnFieldName="created"></app-sort-indicator>
    </div>
    <div class="col-2" (click)="sortClick('file_size')">
        Size<app-sort-indicator [sortFieldName]="sortField" [sortIsDescending]="sortDirection"
            columnFieldName="file_size"></app-sort-indicator>
    </div>
    <div class="col-2">
        Action
    </div>
    <div class="col-2">
        Flagged Comments
    </div>
</div>

<div *ngIf="attachments.length == 0" class="row">
    <div class="col" align="center">
        &lt;No Attachments&gt;
    </div>
</div>

<span *ngFor="let attachment of attachments; let i = index">
    <!-- All other attachment comments -->
    <div *ngIf="attachment.can_view && attachment.flag_comment;else no_comment" class="row" style="background-color: lightyellow; ">
        <div class="col-3 link" *ngIf="voucherAccountUuid;else no_voucher_uuid">
            <fa-icon 
                *ngIf="attachment.allow_flagging"
                [icon]="faFlag"
                (click)="flagClick(attachment.attachment_uuid, attachment.flag_comment, voucherAccountUuid)"
                class="flag ml-2" 
                [title]="attachment.flag_comment ? 'Cancel' : 'Edit'">
            </fa-icon>
            <span (click)="download(attachment)"> {{attachment.document_description}}</span>
        </div>
        <ng-template #no_voucher_uuid>
            <div class="col-3 link">
                <fa-icon 
                    *ngIf="attachment.allow_flagging"
                    [icon]="faFlag"
                    (click)="flagClick(attachment.attachment_uuid, attachment.flag_comment)"
                    class="flag ml-2" 
                    [title]="attachment.flag_comment ? 'Cancel' : 'Edit'">
                </fa-icon>
                <span (click)="download(attachment)"> {{attachment.document_description}}</span>
            </div>
        </ng-template>
        
        <div class="col-3">
            {{attachment.created | date: 'M/d/y h:mm aaaaa\'m\''}}
        </div>
        <div class="col-2">
            {{attachment.file_size | fileSize}}
        </div>
        <div class="col-2">
            <span *ngIf="attachment.can_delete" class="link-rebrand" (click)="deleteAttachment(attachment)">delete</span>
        </div>

        <div class="col-2">
            {{attachment.flag_comment}}
        </div>
    </div>
    <ng-template #no_comment>
        <div *ngIf="attachment.can_view" class="row">
            <div class="col-3 link">
                <fa-icon 
                    *ngIf="attachment.allow_flagging"
                    [icon]="faFlag"
                    (click)="flagClick(attachment.attachment_uuid, attachment.flag_comment)"
                    class="flag ml-2" 
                    [title]="attachment.flag_comment ? 'Cancel' : 'Edit'">
                </fa-icon>
                <span (click)="download(attachment)"> {{attachment.document_description}}</span>
            </div>
            <div class="col-3">
                {{attachment.created | date: 'M/d/y h:mm aaaaa\'m\''}}
            </div>
            <div class="col-2">
                {{attachment.file_size | fileSize}}
            </div>
            <div class="col-2">
                <span *ngIf="attachment.can_delete" class="link-rebrand" (click)="deleteAttachment(attachment)">delete</span>
            </div>
    
            <div class="col-2">
                {{attachment.flag_comment}}
            </div>
        </div>
    </ng-template>
</span>