import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    public constructor(
        private router: Router,
        private authService: AuthService,
    ) {
    }

    get showMainMenu() {
        return this.authService.hasValidAuthenticationToken() && !this.router.url.startsWith('/login');
    }

    get stickyBypass() {
        return this.authService.hasValidAuthenticationToken() && this.router.url.startsWith('/budget-summary-detail');
    }

    ngOnInit() {
        this.authService.stillLoggedInCheck();
    }
}
