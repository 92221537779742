import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { REPORTVOUCHER } from '../mock/mock-report-voucher';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { iReportVoucher } from '../model/report-voucher.model';
import { VoucherService } from '../voucher.service';

@Component({
  selector: 'app-voucher-report',
  templateUrl: './voucher-report.component.html',
  styleUrls: ['./voucher-report.component.css']
})
export class VoucherReportComponent implements OnInit {
  //used for mock data
  //voucher = REPORTVOUCHER
  voucher: any
  displayedColumns: string[] = ['account', 'stateOnly', 'stateMatch', 'fedMatch', 'total'];

  faFileInvoiceDollar = faFileInvoiceDollar
  submission_uuid: string
  account_name: string
  total_state: number
  total_federal: number
  total: number
  display: number
  description: string
  organization_name: string
  
  report_data = []
  sortOrder_data = []


  finalStateTotal: number = 0
  finalStateMatchTotal: number = 0
  finalFederalMatchTotal: number = 0
  finalVoucherTotal: number = 0

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private voucherService: VoucherService,
  ) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.submission_uuid = params['submissionUuid']
    })

    this.voucherService.getVoucherReport(this.submission_uuid).subscribe(response => {
      this.voucher = response.result
      console.log(this.voucher)

      
      for(let i = 0; i < this.voucher.length; i++) {
        if(this.submission_uuid == this.voucher[i].submission_id) {
          this.report_data.push(this.voucher[i])
          this.description = this.voucher[i].description
          this.organization_name = this.voucher[i].organization_name
        }
        if(this.submission_uuid == this.voucher[i].submissionId){
          this.report_data.push(this.voucher[i])
        }
      }
      this.sortOrder_data = this.report_data.sort(function(a,b){
        return a.display - b.display
      })
  
      console.log(this.sortOrder_data)
  
      for(let i = 0; i < this.sortOrder_data.length; i++) {
        //Calulates State Only Spent Total
        if(this.sortOrder_data[i].total_state){
          //console.log(this.voucher[i].totalState)
          this.finalStateTotal += this.sortOrder_data[i].total_state
          //console.log(this.finalStateTotal)
        }
  
        //Calulates State Match Spent Total
        if(this.sortOrder_data[i].total_federal){
          //console.log(this.sortOrder_data[i].totalFederal)
          this.finalStateMatchTotal += this.sortOrder_data[i].total_federal / 2
          //console.log(this.finalStateMatchTotal)
        }
  
        //Calulates Federal Match Spent Total
        if(this.sortOrder_data[i].total_federal){
          //console.log(this.sortOrder_data[i].totalFederal)
          this.finalFederalMatchTotal += this.sortOrder_data[i].total_federal / 2
          //console.log(this.finalFederalMatchTotal)
        }
  
        //Calulates Total Voucher
        if(this.sortOrder_data[i].total){
          //console.log(this.sortOrder_data[i].total)
          this.finalVoucherTotal += this.sortOrder_data[i].total
          //console.log(this.finalVoucherTotal)
        }   
      }  
    })

    // for(let i = 0; i < this.voucher.length; i++) {
    //   if(this.submission_uuid == this.voucher[i].submissionId){
    //     this.report_data.push(this.voucher[i])
    //   }
    // }

    //sorting based on display number
  //   this.sortOrder_data = this.report_data.sort(function(a,b){
  //     return a.display - b.display
  //   })

  //   console.log(this.sortOrder_data)

  //   for(let i = 0; i < this.sortOrder_data.length; i++) {
  //     //Calulates State Only Spent Total
  //     if(this.sortOrder_data[i].totalState){
  //       //console.log(this.voucher[i].totalState)
  //       this.finalStateTotal += this.sortOrder_data[i].totalState
  //       //console.log(this.finalStateTotal)
  //     }

  //     //Calulates State Match Spent Total
  //     if(this.sortOrder_data[i].totalFederal){
  //       //console.log(this.sortOrder_data[i].totalFederal)
  //       this.finalStateMatchTotal += this.sortOrder_data[i].totalFederal / 2
  //       //console.log(this.finalStateMatchTotal)
  //     }

  //     //Calulates Federal Match Spent Total
  //     if(this.sortOrder_data[i].totalFederal){
  //       //console.log(this.sortOrder_data[i].totalFederal)
  //       this.finalFederalMatchTotal += this.sortOrder_data[i].totalFederal / 2
  //       //console.log(this.finalFederalMatchTotal)
  //     }

  //     //Calulates Total Voucher
  //     if(this.sortOrder_data[i].total){
  //       //console.log(this.sortOrder_data[i].total)
  //       this.finalVoucherTotal += this.sortOrder_data[i].total
  //       //console.log(this.finalVoucherTotal)
  //     }   
  //   }  
  // }
  }
}
