import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { CrudService } from '../crud.service';
import { UtilityReportService } from '../utility-report.service';

@Component({
    selector: 'app-utility-report',
    templateUrl: './utility-report.component.html',
})
export class UtilityReportComponent implements OnInit {
    faWrench = faWrench
    reports$: Observable<any> = this.crudService.search({ type: "config_utility_report", where: "active = true", }).pipe(
        map((reportsResponse) => {
            return reportsResponse.result
        })
    )
    localStorageReportName = "hsny_utility_report"
    localStorageSortFieldName = "hsny_utility_report_sort_field"
    localStorageSortIsDescending = "hsny_utility_report_sort_is_descending"
    reportFC = new UntypedFormControl(localStorage.getItem(this.localStorageReportName))

    entries$ = combineLatest(
        this.reportFC.valueChanges.pipe(
            startWith(localStorage.getItem(this.localStorageReportName)),
            tap((typeName) => {
                localStorage.setItem(this.localStorageReportName, typeName)
            })
        )
    ).pipe(
        switchMap(([value]) => {
            //console.log("value", value)
            return combineLatest(this.utilityReportService.getReport(value), this.utilityReportService.getReportSchema(value))
        })
    ).pipe(
        map(([reportResponse, reportSchemaResponse]) => {
            // console.log("report response", reportResponse)
            // console.log("report schema response", reportSchemaResponse)
            reportResponse.definition = reportSchemaResponse.result
            return reportResponse
        })
    )

    constructor(
        private crudService: CrudService,
        private utilityReportService: UtilityReportService,
    ) { }

    ngOnInit() {

    }

    sortClick(oldSortField, newSortField, sortIsDescending) {

    }
}
