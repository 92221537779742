import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { VoucherService } from '../voucher.service'




@Component({
    selector: 'voucher-lines-add',
    templateUrl: './voucher-lines-add.component.html',
    styleUrls: ['./voucher-lines-add.component.css'],
})
export class VoucherLinesAddComponent implements OnInit {
    form!: UntypedFormGroup

    account_uuid: string
    submission_uuid: string
    description: string
    name: string
    line_date: string
    state_only: number
    state_federal: number
    other_amount: number
    line_reference_number: string
    comment: string
    


	constructor(
        private dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private voucherService: VoucherService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.account_uuid = data.account_uuid
        this.submission_uuid = data.submission_uuid
    }


    close() {
        this.dialog.closeAll()
        //window.location.reload()
    }

    createLine() {
        this.voucherService.addVoucherLine(
            this.account_uuid,
            this.description = this.form.value.description,
            this.name = this.form.value.name,
            this.line_date = this.form.value.line_date,
            this.state_only = this.form.value.state_only,
            this.state_federal = this.form.value.state_federal,
            this.other_amount = this.form.value.other_amount,
            this.line_reference_number = this.form.value.line_reference_number,
            this.comment = this.form.value.comment
        ).subscribe((result) => {
            if(result.success == true) {
                this.close()
            }
        }, err => console.log("error:", err))
        
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            description: new UntypedFormControl(this.description, [
                Validators.required
            ]),
            name: new UntypedFormControl(this.name, [
            ]),
            line_date: new UntypedFormControl(this.line_date, [
            ]),
            other_amount: new UntypedFormControl(this.other_amount, [
            ]),
            state_only: new UntypedFormControl(this.state_only, [
            ]),
            state_federal: new UntypedFormControl(this.state_federal, [
            ]),
            line_reference_number: new UntypedFormControl(this.line_reference_number, [
            ]),
            comment: new UntypedFormControl(this.comment, [])
        })
    }
}
