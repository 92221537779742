<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col">
            <fa-icon [icon]="faFileAlt"></fa-icon>
            Submission Type: {{submission.submission_type_name}}
        </div>
    </div>

    <div class="row">
        <div class="col-6">
            <div class="row py-2">
                <div class="col-4 bold">
                    Agency
                </div>
                <div class="col-8">
                    {{submission.agency_name}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Submission Name
                </div>
                <div class="col-8">
                    {{submission.submission_name}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Due Date
                </div>
                <div class="col-8">
                    {{submission.submission_due_date | date : 'M/d/y'}}
                </div>
            </div>

            <div class="row py-2">
                <div class="col-4 bold">
                    Status
                </div>
                <div class="col-8">
                    <span class="status_badge" [ngClass]="['submission_status_' + submission.status_id ]">
                        {{submission.status_name | uppercase}}
                    </span>
                </div>
            </div>

            <app-input-date label="Recertification / Valid Through Date" [fc]="validThroughDateFC"
                [disabled]="!showEditFields" [format]="InputDateFormat.horizontalLabel"></app-input-date>
            <span *ngIf="showEditFields">

                <app-input-text-area label="Submission Notes" [fc]="submissionNotesFC" [maxlength]="4000">
                </app-input-text-area>

                <app-submission-attachment-controls [submissionUuid]="submissionUuid">
                </app-submission-attachment-controls>

            </span>
        </div>
        <div class="col-6">
            <div class="row py-2">
                <div class="col">
                    Instructions
                </div>
            </div>
            <div class="row">
                <div class="col" style="margin-bottom: 2rem;white-space:pre-wrap;">{{submission.document_instructions}}
                </div>
            </div>
        </div>
    </div>

    <app-submission-attachments [submissionUuid]="submissionUuid"></app-submission-attachments>
    <app-submission-activity-feed [submissionActivityFeed]="submissionActivityFeed$ | async">
    </app-submission-activity-feed>
    <app-submission-history [submissionHistory]="submissionHistory$ | async"></app-submission-history>

    <div *ngIf="saveError" class="row mt-3">
        <div class="col" align="center" style="white-space: pre-wrap;">
            <span class="overall-validation-error">
                {{saveError}}
            </span>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-4">
            <app-button3 class="mx-1" label="Previous" [clickFunction]="previous.bind(this)" [icon]="faChevronLeft">
            </app-button3>
        </div>
        <div *ngIf="showEditFields" class="col-8" align="right">
            <app-button3 class="mx-1" label="Save for Later" [clickFunction]="saveForLater.bind(this)" [icon]="faSave"
                [isFinal]="true">
            </app-button3>
            <app-button3 class="mx-1" label="Next" [clickFunction]="submit.bind(this)" [icon]="faShare"
                [isFinal]="true">
            </app-button3>
        </div>
    </div>

</div>