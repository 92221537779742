import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { VoidDialogElements } from './void-submission-dialog.component';


@Component({
  selector: 'app-void-submission',
  templateUrl: './void-submission.component.html',
  styleUrls: ['./void-submission.component.css']
})
export class VoidSubmissionComponent implements OnInit {
  @Input() submissionUuid: any
  @Input() submission: any
  @Input() sub: any

  previousUuid: string

  constructor(public dialog: MatDialog) { }
  
  openVoidDialog() {
    console.log(this.submissionUuid, this.submission.previous_uuid)

    this.previousUuid = this.submission.previous_uuid
    console.log(this.previousUuid)

    // if(this.sub) {
    //   this.previousUuid = this.sub.previous_uuid
    // } else {
    //   this.previousUuid = this.submission.previous_uuid
    // }

    this.dialog.open(VoidDialogElements, {
      maxWidth: '400px',
      data: {
        submissionUuid: this.submissionUuid,
        previousUuid: this.previousUuid
      }
    })
  }

  ngOnInit() {
    // this.previousUuid = this.submission.previous_uuid
  }

}