import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-text-area',
    templateUrl: './text-area.component.html',
})
export class TextAreaComponent implements OnInit {
    @Input() fc;
    @Input() disabled;
    @Input() maxlength;

    constructor() { }

    ngOnInit() {
    }

}
