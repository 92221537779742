import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-input-split-button',
    templateUrl: './input-split-button.component.html',
    styleUrls: ['./input-split-button.component.scss'],
})
export class InputSplitButtonComponent implements OnInit {
    @Input() options
    @Input() clickAction
    faPlus = faPlus
    faChevronDown = faChevronDown
    faChevronUp = faChevronUp
    @ViewChild("dropdown", { read: ElementRef }) dropdownElem: ElementRef;
    subOptions

    constructor() { }

    ngOnInit() {
        this.subOptions = []
        for (var i = 1; i < this.options.length; ++i) {
            this.subOptions.push(this.options[i])
        }
    }
    clickDocumentEventListener
    toggleDropdown() {
        var display = this.dropdownElem.nativeElement.style.display;
        if (display == 'none') {
            this.dropdownElem.nativeElement.style.display = 'block';
            this.clickDocumentEventListener = this.hideActionsMenu.bind(this)
            setTimeout(() => {
                document.addEventListener("click", this.clickDocumentEventListener);
            }, 0)
        }
    }
    hideActionsMenu() {
        document.removeEventListener("click", this.clickDocumentEventListener);
        if (!this.dropdownElem.nativeElement) {
            return;
        }
        this.dropdownElem.nativeElement.style.display = 'none'
    }
}
