<div class="row">
    <div class="col-4 bold">
        {{label}}
        <fa-icon *ngIf="commentId && showFlag" [icon]="faFlag" class="flag ml-2" (click)="flagClick(commentId)"
            [title]="commentData[commentId].showInputContainer.value ? 'Cancel' : 'Edit'">
        </fa-icon>
    </div>
    <div class="col-8 pt-1">
        <span *ngFor="let enumValue of enumValuesNameAndId">
            <div class="row">
                <div class="col">
                    <input class="mr-2" type="radio" [id]="enumValue.id" [name]="attributeIdentifier"
                        [value]="enumValue.id" [formControl]="fc" (change)="doChange($event)" />
                    <label class="mb-0" [for]="enumValue.id" [ngClass]="{'role-selected': fc.value==enumValue.id}"
                        style="vertical-align: top;">{{enumValue.name}}</label>
                </div>
            </div>
        </span>
    </div>
</div>
<app-outreach-submission-comment *ngIf="commentId" [submissionUuid]="submissionUuid" [inputId]="commentId"
    [commentUuid]="commentData[commentId].commentUuid" [comment]="commentData[commentId].comment"
    [showInputContainer]="commentData[commentId].showInputContainer" [showFlag]="showFlag" [odd]="false"
    [targetUuid]="targetUuid">
</app-outreach-submission-comment>