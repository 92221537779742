<div *ngIf="mainTarget$ | async as mainTarget" class="container-fluid">
    <div class="row page-title-no-font">
        <div class="col">
            <span class="page-title-font">
                <fa-icon [icon]="faBullseye"></fa-icon>
                Main Target
            </span>
            <a routerLink="/submission-schedule">
                <app-button3 style="float: right;" class="mx-1" label="Back" [icon]="faLongArrowAltLeft" [small]="true">
                </app-button3>
            </a>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%">
                <tbody>
                    <tr class="table-row">
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Program</span>
                            <span class="top-value">{{mainTarget[0].program_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Submission Type</span>
                            <span class="top-value">{{mainTarget[0].submission_type_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Fiscal Year</span>
                            <span class="top-value">{{mainTarget[0].fiscal_name}}</span>
                        </td>
                        <td class="px-1">
                            <span class="sort-column-header" style="cursor: unset;">Schedule Status</span>
                            <span class="top-value">{{mainTarget[0].schedule_status_name}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col px-0">
            <table class="table table-striped table-hover" style="min-width: 100%">
                <thead>
                    <tr style="background-color: lightgrey">
                        <th *ngFor="let column of columns; let index = index"
                            [ngStyle]="{'text-align': index == 0 ? '' : 'right', 'vertical-align': 'top'}">
                            <span class="column-header">
                                {{column.label}}
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="table-row" *ngFor="let entry of mainTarget">
                        <td class="px-1" style="text-align: right;">
                            {{entry.organization_name}}
                        </td>
                        <td class="px-1" style="text-align: right;">
                            {{entry.jurisdiction_name}}
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.mainTargetFC"
                                style="text-align: right;" size="4" />
                            <span *ngIf="!canEdit">{{entry.mainTargetFC.value}}</span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.outreachTargetFC"
                                style="text-align: right;" size="4" />
                            <span *ngIf="!canEdit">{{entry.outreachTargetFC.value}}</span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.personTargetFC"
                                style="text-align: right;" size="4" />
                            <span *ngIf="!canEdit">{{entry.personTargetFC.value}}</span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.prescreenTargetFC"
                                style="text-align: right;" size="4" />
                            <span *ngIf="!canEdit">{{entry.prescreenTargetFC.value}}</span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.recertificationTargetFC"
                                style="text-align: right;" size="4" />
                            <span *ngIf="!canEdit">{{entry.recertificationTargetFC.value}}</span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.barrierTargetFC"
                                style="text-align: right;" size="4" />
                            <span *ngIf="!canEdit">{{entry.barrierTargetFC.value}}</span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.collaborationTargetFC"
                                style="text-align: right;" size="4" />
                            <span *ngIf="!canEdit">{{entry.collaborationTargetFC.value}}</span>
                        </td>
                        <td class="px-1" style="text-align: right;">
                            <input *ngIf="canEdit" type="text" [formControl]="entry.trackingTargetFC"
                                style="text-align: right;" size="4" /> <span *ngIf="canEdit">%</span>
                            <span *ngIf="!canEdit">{{entry.trackingTargetFC.value}} %</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="canEdit" class="row">
        <div class="col">
            <a routerLink="/submission-schedule">
                <app-button3 class="mx-2" label="Cancel" [icon]="faBan">
                </app-button3>
            </a>
            <app-button3 class="mx-2" style="float:right;" label="Finish" (click)="finish(mainTarget)"
                [icon]="faFlagCheckered" [isFinal]="true">
            </app-button3>
            <app-button3 class="mx-2" style="float:right;" label="Save for Later" (click)="saveForLater(mainTarget)"
                [icon]="faSave" [isFinal]="true">
            </app-button3>
        </div>
    </div>
    <div *ngIf="!canEdit" class="row">
        <div class="col" align="center">
            <a routerLink="/submission-schedule">
                <app-button3 class="mx-2" label="OK" [icon]="faCheck">
                </app-button3>
            </a>
        </div>
    </div>
</div>