import { Component, Input, OnInit } from '@angular/core';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { InputDateFormat } from '../input-date/input-date.component';

@Component({
    selector: 'app-input-date-validatable',
    templateUrl: './input-date-validatable.component.html',
})
export class InputDateValidatableComponent implements OnInit {
    InputDateFormat = InputDateFormat
    @Input() fc
    @Input() change
    @Input() disabled
    @Input() disabledFormat
    @Input() label

    @Input() commentId
    @Input() commentData
    @Input() flagClick
    @Input() showFlag
    @Input() submissionUuid
    @Input() targetUuid
    faFlag = faFlag
    constructor(
    ) { }

    ngOnInit() {
    }
}
