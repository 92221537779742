<h1 mat-dialog-title>Create new voucher line</h1>


<mat-dialog-content [formGroup]="form" >
    
    <p><mat-form-field>
        <input matInput
                type="text"
                placeholder="Description/Title"
                value=""
               formControlName="description" required>
    </mat-form-field></p>

    <p><mat-form-field>
        <input matInput
                type="text"
                placeholder="Vendor/Employee Name"
                value=""
                formControlName="name" >
    </mat-form-field></p>

    <p><mat-form-field appearance="fill">
        <mat-label>Invoice/Pay Date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="line_date" >
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field></p>
    <!--
    <p style="color: red;" *ngIf="form.controls['line_date'].errors">
        Please enter correct date format MM/DD/YY
    </p>
    -->

    <p><mat-form-field>
        <input matInput
                type="number"
                placeholder="Total Invoice"
                value="{{data.other_amount}}"
                formControlName="other_amount" >
    </mat-form-field></p>
    
    <p><mat-form-field>
        <input matInput
                type="number"
                placeholder="State Only"
                value=""
                formControlName="state_only" >
    </mat-form-field></p>
    
    <p><mat-form-field>
        <input matInput
                type="number"
                placeholder="State/Fed Match"
                value=""
                formControlName="state_federal" >
    </mat-form-field></p>
    <!--
    <p><mat-form-field>
        <input matInput
                type="number"
                placeholder="Amount Charged to Contract"
                value=""
                formControlName="other_amount">
    </mat-form-field></p>
    -->
    <p><mat-form-field>
        <input matInput
                type="text"
                placeholder="Check/ACH &#35;"
                value=""
                formControlName="line_reference_number" >
    </mat-form-field></p>
    <p><mat-form-field>
        <textarea matInput
                type="text"
                placeholder="Agency Comment"
                value=""
                formControlName="comment">
        </textarea>
    </mat-form-field></p>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button class="mat-raised-button mat-primary" (click)="createLine()" [disabled]="!form.valid">Create</button>
    <button class="mat-raised-button" (click)="close()" style="margin-right: 5px;">Close</button>
</mat-dialog-actions>