<h1 mat-dialog-title>{{ isEdit ? 'Edit Answer' : 'Add Answer' }}</h1>
<form [formGroup]="answerForm">
  <div mat-dialog-content>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Display</mat-label>
        <input matInput required type="number" formControlName="display">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input matInput required type="text" formControlName="name">
      </mat-form-field>
    </div>
  </div>
</form>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="onSubmit()">{{ isEdit ? 'Save' : 'Add' }}</button>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button *ngIf="isEdit" mat-raised-button color="warn" (click)="onDelete()">Delete</button>
</mat-dialog-actions>


  