import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BudgetService } from '../budget.service';
import { SnackRouteService } from '../snack-route.service';

@Component({
    selector: 'app-budget-account-wrapper',
    templateUrl: './budget-account-wrapper.component.html',
})
export class BudgetAccountWrapperComponent implements OnInit {

    submissionUuid$ = this.activatedRoute.queryParams.pipe(
        map(o => o.submissionUuid),
        shareReplay(1),
    )

    addAccountOptions$ = this.submissionUuid$.pipe(
        switchMap(submissionUuid => this.budgetService.getAddAccountOptions(submissionUuid)),
        map((response) => {
            response.result.forEach((item) => {
                item.id = item.uuid
            })
            return response.result
        })
    )

    accountUuid$ = this.activatedRoute.queryParams.pipe(
        map(o => o.accountUuid),
        shareReplay(1),
    )

    accountData$ = this.accountUuid$.pipe(
        switchMap(accountUuid => this.budgetService.getAccount(accountUuid)),
    )

    editable$ = this.activatedRoute.queryParams.pipe(
        map(o => o.editable == "true"),
        shareReplay(1),
    )

    showFlag$ = this.activatedRoute.queryParams.pipe(
        map(o => o.showFlag == "true"),
        shareReplay(1),
    )

    data$ = combineLatest(
        this.submissionUuid$,
        this.accountUuid$,
        this.accountData$,
        this.addAccountOptions$,
        this.editable$,
        this.showFlag$,
    ).pipe(
        map(([submissionUuid, accountUuid, accountData, addAccountOptions, editable, showFlag]) => {
            return {
                submissionUuid: submissionUuid,
                addAccountOptions,
                snackRoute: this.snackRoute,
                accountUuid,
                entryData: accountData.result,
                editable,
                showFlag,
            }
        })
    )

    constructor(
        private activatedRoute: ActivatedRoute,
        private budgetService: BudgetService,
        private snackRoute: SnackRouteService,
    ) { }

    ngOnInit() {
    }

}
