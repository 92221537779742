<div *ngIf="initialized" class="container-fluid">
    <div class="row page-title">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <fa-icon [icon]="faQuestionCircle"></fa-icon>
            Program Submission Questions
        </div>
        <div class="col-1 d-none d-md-block">
        </div>
    </div>
    <div class="row">
        <div class="col-1 d-none d-md-block">
        </div>
        <div class="col-12 col-md-10">
            <app-submission-header [submission]="submission$ | async" [statusesById]="submissionStatuses$ | async"
                [hideRecertificationDate]="true">
            </app-submission-header>

            <app-program-submission [submissionUuid]="submissionUuid" [stepUuid]="stepUuid" [showFlag]="false"
                [editable]="true">
            </app-program-submission>
        </div>
    </div>
</div>