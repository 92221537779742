<div *ngIf="addSequence > 0">
    <h4 mat-dialog-title>Confirm Action</h4>
    <div mat-dialog-content>
        <p>Deleting {{ dialogMessage }}. Do you wish to continue?</p>
        <div class="confirm-checkbox">
            <mat-checkbox [(ngModel)]="confirmCheck" color="primary">Yes, confirm deletion.</mat-checkbox>
        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="closeDialog()">Cancel</button>
        <button mat-raised-button color="warn" (click)="deleteGroup()" [disabled]="!confirmCheck">OK, Delete</button>
    </div>
</div>

<div *ngIf="addSequence == 0">
    <div mat-dialog-content>
        <p>You may not delete the first question group.</p>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="closeDialog()">Cancel</button>
    </div>
</div>
