<hr />
<app-input-document label="Attachment" [fc]="attachmentFC" [change]="this.attachmentUploadComplete.bind(this)"
    [preprocess]="this.preprocess.bind(this)" [onFileSelect]="this.fileSelect.bind(this)"
    [clear]="this.clear.bind(this)" [icon]="faPaperclip">
</app-input-document>

<app-input-text label="Document Description" [fc]="documentDescriptionFC" [maxlength]="255"></app-input-text>
<div *ngIf="descriptionError" class="row py-2">
    <div class="col-12 bold">
        {{descriptionError}}
    </div>
</div>

<div class="row">
    <div class="col" align="center">
        <app-button3 class="mx-1" label="Clear" [clickFunction]="clearForm.bind(this)" [icon]="faBan" [small]="true">
        </app-button3>
        <app-button3 class="mx-1" label="Upload Attachment" [clickFunction]="handleFileInput.bind(this)"
            [icon]="faUpload" [small]="true" [isFinal]="true">
        </app-button3>
    </div>
</div>