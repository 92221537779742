import { OnInit, Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "../user.service"
import { SessionService } from "../session.service";

@Component({
    selector: 'assign-role-dialog',
    templateUrl: './assign-role-dialog.component.html',
    styleUrls: ['./manage-users.component.css']
  })
  
  export class AssignRoleDialog implements OnInit
  {
    dialogMessage: string
    displayAssign: boolean = true

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: 
        {
          userId: string,
          orgId: string
        },
        private dialog: MatDialogRef<AssignRoleDialog>,
        private userService: UserService,
        private sessionService: SessionService
    ){}
  
    assignRoles() {
        this.userService.assignRoles(this.data.userId, this.data.orgId).subscribe(result => {
            if(result.success === true) 
            {
                this.sessionService.setMirrorSubOrg(this.data.orgId);
                this.dialog.close('roles assigned')
            } else {
                if(result.response.driverError) {
                    if(result.response.driverError.sqlMessage.includes('Duplicate')) {
                        this.dialogMessage = 'Duplicate entry error! Roles have already been assigned to an agency. '
                        // this.dialogMessage += result.response.driverError.sqlMessage
                    }
                }
            }
        })
    }

    removeRoles() {
        this.userService.removeRoles(this.data.userId).subscribe(result => {
            if(result.success === true) 
            {
                this.sessionService.removeMirrorSubOrg();
                this.dialog.close('roles deleted')
            } else {
                if(result.response.driverError) {
                    this.dialogMessage = result.response.driverError.sqlMessage
                }
            }
        })
    }

    cancel() {
        this.dialog.close('canceled')
    }
  
    ngOnInit() {
        // this.userService.rolesExist(this.data.userId, this.data.orgId).subscribe(result => {
        //     if(result.response.length >= 1) {
        //         this.displayAssign = false
        //         this.dialogMessage = 'Roles have been assigned for this agency.'
        //     } else {
        //         this.displayAssign = true
        //     }
        // })
    }
  }
  