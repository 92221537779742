import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faFile, faFlag } from '@fortawesome/free-solid-svg-icons';
import { SubmissionService } from '../submission.service';
import { SystemService } from '../system.service';
import { AuthService } from '../auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentCommentComponent } from './attachment-comment.component';
import { AttachmentsCommunicationService } from '../services/attachments-communication.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-submission-attachments',
    templateUrl: './submission-attachments.component.html',
})
export class SubmissionAttachmentsComponent implements OnInit, OnDestroy {
    @Input() submissionUuid
    @Input() voucherAccountUuid
    faFile = faFile
    faFlag = faFlag

    sortField = "document_description"
    sortDirection = 0
    initialized = false
    attachments = []
    userOrganizationType
    showFlag = true
    private attachmentUploadSubscription: Subscription;

    getUserOrganizationType() {
        return new Promise((resolve, reject) => {
            this.authService.getUserOrganizationType().subscribe((response) => {
                if (response.success) {
                    this.userOrganizationType = response.result
                } else {
                    console.log("error while getting user organization type", response)
                }
                resolve(null)
            })
        })
    }

    flagClick(attachment_uuid: string, comment: string, voucherUuid?: string) {
        console.log(attachment_uuid, comment, voucherUuid)
        this.dialog.open(
            AttachmentCommentComponent,
            {
              width: '400px',
              data: {
                attachment_uuid: attachment_uuid,
                comment: comment,
                voucherUuid: this.voucherAccountUuid
              }
            }
          ).afterClosed().subscribe(_result => { window.location.reload() }
        )
    }

    refreshAttachmentList()
    {
        this.getSubmissionAttachmentsPromise();
    }

    attachment_uuid: string
    getSubmissionAttachmentsPromise() {
        return new Promise((resolve, reject) => {
            this.submissionService.getSubmissionAttachments(this.submissionUuid, this.sortField, this.sortDirection, this.voucherAccountUuid).subscribe((response) => {
                if (response.success) {
                    this.attachments = response.result
                    console.log('attachments: ' + this.attachments)

                    for (var i = 0; i < this.attachments.length; ++i) {
                        var effectiveUserOrgType = this.userOrganizationType
                        if (effectiveUserOrgType == "SYSTEM") {
                            effectiveUserOrgType = "PRIME"
                        }
                        var effectiveAttachmentOrgType = this.attachments[i].organization_type_id
                        if (effectiveAttachmentOrgType == "SYSTEM") {
                            effectiveAttachmentOrgType = "PRIME"
                        }

                        let attachmentStatus = this.attachments[i].attachment_status_id

                        this.attachments[i].can_delete = effectiveAttachmentOrgType == effectiveUserOrgType && attachmentStatus == "S"
                        this.attachments[i].can_view = effectiveUserOrgType == "PRIME" || effectiveAttachmentOrgType != "PRIME"
                        this.attachments[i].attachment_uuid = this.attachments[i].uuid
                        this.attachment_uuid = this.attachments[i].uuid

                        if(effectiveUserOrgType == "PRIME" && this.attachments[i].status_id != "APPROVED") {
                            this.attachments[i].allow_flagging = true
                        } else {
                            this.attachments[i].allow_flagging = false
                        }
                    }
                } else {
                    console.log("error while getting attachments", response)
                }
                resolve(null)
            })
        })
    }

    constructor(
        private submissionService: SubmissionService,
        private systemService: SystemService,
        private authService: AuthService,
        private dialog: MatDialog,
        private attachmentCommunicationService: AttachmentsCommunicationService
    ) 
    { 
        this.attachmentUploadSubscription = this.attachmentCommunicationService.attachmentUpload$.subscribe(
            () => {
                this.refreshAttachmentList();
            })
    }

    ngOnInit() {
        this.getUserOrganizationType().then(() => {
            return this.getSubmissionAttachmentsPromise()
        }).then(() => {
            this.initialized = true
        })
    }

    download(doc) {
        this.systemService.downloadDocumentFile(doc.document_uuid).subscribe((response) => {
            var tmp: any = response;
            var blob = new Blob([tmp]);
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.download = doc.file_name;
            link.click();
        });
    }

    sortClick(fieldName) {
        if (this.sortField == fieldName) {
            if (this.sortDirection == 0) {
                this.sortDirection = 1
            } else {
                this.sortDirection = 0
            }
            return this.getSubmissionAttachmentsPromise()
        }
        this.sortDirection = 0;
        this.sortField = fieldName;
        return this.getSubmissionAttachmentsPromise()
    }

    deleteAttachment(attachment: any) {
        if(this.voucherAccountUuid) {
            this.submissionService.deleteVoucherAttachment(attachment.uuid).subscribe((response) => {
                this.getSubmissionAttachmentsPromise()
            })
        } else {
            this.submissionService.deleteSubmissionAttachment(attachment.uuid).subscribe((response) => {
                this.getSubmissionAttachmentsPromise()
            })
        }
    }

    ngOnDestroy()
    {
        this.attachmentUploadSubscription.unsubscribe();
    }
}
