import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-crud-child-node',
    templateUrl: './crud-child-node.component.html',
})
export class CrudChildNodeComponent implements OnInit {
    newEntry = false

    @Input() schema
    @Input() typeNode
    @Input() entry
    @Input() referencedTypes
    @Input() depth

    constructor() { }

    ngOnInit() {
        //console.log("CNODE init", this.typeNode)
        console.log("BLAGRRR", this.schema.result[this.typeNode.fc.value], this.typeNode, this.entry)
    }

}
