import { Component, OnInit } from '@angular/core';
import { BudgetService } from '../services/budget.service';
import { SessionService } from '../session.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { iTrendReportOrganization } from '../model/trend-report-organization.model';
import { iPrimengColumn } from '../model/primeng-column.model';
import { Router } from '@angular/router';
import { FiscalYearService } from '../services/fiscal-year.service';

@Component({
  selector: 'app-trend-report-table',
  templateUrl: './trend-report-table.component.html',
  styleUrls: ['./trend-report-table.component.css']
})
export class TrendReportTableComponent implements OnInit 
{
  fiscalYearForm: FormGroup;
  fiscalYearOptions: any[];
  organizations: iTrendReportOrganization[];

  trendReportOrganizationColumns: iPrimengColumn[] = 
  [
    { header: 'State', field: 'state' },
    { header: 'Federal', field: 'federal' }
  ];

  constructor(
    private budgetsService: BudgetService,
    private sessionService: SessionService,
    private fiscalYearService: FiscalYearService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit()
  {
    this.initForm();
    this.loadFiscalYearOptions();
  }

  initForm()
  {
    this.fiscalYearForm = this.fb.group({
      fiscalYear: ['']
    });

    this.fiscalYearForm.valueChanges.subscribe({
      next: (value) => {
        const fiscalYearId = value.fiscalYear?.id;
        if (fiscalYearId) 
        {
          this.sessionService.setFiscalYear(fiscalYearId);
          this.loadOrganizations(fiscalYearId);
        }
      }
    });
  }

  loadFiscalYearOptions()
  {
    const fiscalYearId = this.sessionService.getFiscalYear();

    this.fiscalYearService.getFiscalYearOptions().subscribe({
      next: (options) => {
        this.fiscalYearOptions = options.result.filter((option: any) => option.id !== 'NONE');
        const selectedOption = this.fiscalYearOptions.find(option => option.id === fiscalYearId);
        this.fiscalYearForm.patchValue({ fiscalYear: selectedOption });
      },
      error: (error) => {
        console.error('Error while getting fiscal year options', error);
      }
    });
  }

  loadOrganizations(fiscalYearId: string)
  {
    this.budgetsService.getTrendReportOrganizations(fiscalYearId).subscribe({
      next: (organizations) => {
        this.organizations = organizations;
      },
      error: (error) => {
        console.error('Error while getting organizations', error);
      }
    });
  }

  goToTrendReport(organizationUuid: string)
  {
    this.router.navigate(['trend-report'], { queryParams: { organizationUuid } });
  }
}
