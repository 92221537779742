import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AwsSesService } from './aws-ses.service';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {
        if (error.message && error.message.startsWith("ExpressionChangedAfterItHasBeenCheckedError")) {
            console.log("skipping log of ExpressionChangedAfterItHasBeenCheckedError error");
        } else if (error.message && error.message.startsWith("ViewDestroyedError")) {
            console.log("skipping log of ViewDestroyedError error");
        } else {
            console.log("sending error email");
            const ses = this.injector.get(AwsSesService);
            const router = this.injector.get(Router);
            ses.sendErrorEmail(error, router.url).subscribe((response) => {
                if (response.success) {
                    console.log("error email successfully sent");
                } else {
                    console.log("error email unsuccessfully sent", response);
                }
            });
            console.error(error);
        }
    }
}
