<div *ngIf="initialized" class="container-fluid">
    <div class="row mb-2">
        <div class="col page-title-no-font">
            <span class="page-title-font">
                <fa-icon [icon]="faCheck"></fa-icon>
                Submission Confirmation
            </span>
        </div>
    </div>

    <div class="row mb-2">
        <div class="col-3">
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col bold" style="font-size: 1.2em;">
                    Whats Next?
                </div>
            </div>
            <div class="row pt-1">
                <div class="col">
                    <p class="ml-3">Your submission is complete and pending review with Hunger Solutions New York.</p>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="ml-3">Hunger Solutions New York will review your submission and respond electronically
                        with our decision
                        or request additional information.</p>
                </div>
            </div>
            <div class="row py-1">
                <div class="col bold" style="font-size: 1.2em;">
                    Statuses During Review Process:
                </div>
            </div>
            <div class="row py-1">
                <div class="col">
                    <ul>
                        <li class="py-1">
                            Submitted - The submission has been submitted to Hunger Solutions New York.
                        </li>
                        <li class="py-1">
                            Pending - The submission is pending review by a Hunger Solutions New
                            York Auditor.
                        </li>
                        <li class="py-1">
                            Approved - The submission has been approved and no further action is needed.
                        </li>
                        <li class="py-1">
                            Additional Information Requested - More information is required to complete the review. You
                            will receive a notification with additional information by email. You will have the ability
                            to edit your submission and attach additional documentation if required. The status will
                            return to Pending Approval when the additional information is supplied.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col" align="center">
            <app-button3 class="mx-1" label="OK" [clickFunction]="returnToDashboard.bind(this)" [icon]="faCheck">
            </app-button3>
        </div>
    </div>
</div>