import { Component, Input, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-submission-activity-feed',
    templateUrl: './submission-activity-feed.component.html',
})
export class SubmissionActivityFeedComponent {
    @Input() submissionActivityFeed;
    faSpinner = faSpinner
}
