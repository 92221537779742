import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { faBullseye, faCheck } from '@fortawesome/free-solid-svg-icons';
import { MainTargetService } from '../main-target.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-agency-main-target',
  templateUrl: './agency-main-target.component.html',
  styleUrls: ['./agency-main-target.component.css']
})
export class AgencyMainTargetComponent implements OnInit {
  faBullseye = faBullseye
  faCheck = faCheck

  @Input() isODTA: boolean = false;
  @Input() submissionUuid: string;

  dataLoadError: boolean;

  constructor(
    private mainTargetService: MainTargetService,
    private route: ActivatedRoute
  ) { 
  }

  goBack() {
    history.back()
  }
  
  // row & column data model
  tableData: any
  ytdTableData: any

  // number of households tracked calculation variables
  nhQ1: number
  nhQ2: number
  nhQ3: number
  nhQ4: number

  // main target goal
  mainTargetGoal: number
  
  // percentage calculations
  trackingPercentages = {}
  cumulativeTrackingPercentages = {}
  cumulativeSuccessRates = {}
  mainTargetResults = {}
  percentAchievedToDate: any

  // submission uuid paramater
  submission_uuid: string

  // main target report uuid parameters
  main_target_report_uuid: string
  
  // header data
  headerData: any

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.submissionUuid && !changes.submissionUuid.firstChange){
      this.loadData();
    }
  }

  ngOnInit() {
   this.loadData();
  }

  private loadData() {
    if (this.isODTA) {
      this.submission_uuid = this.submissionUuid;
    } else {
      this.route.queryParams.subscribe(params => {
        console.log(params);
        this.submission_uuid = params['uuid'];
      });
    }
    console.log(this.submission_uuid);

    // page header
    this.mainTargetService.getMainTargetHeader(this.submission_uuid).subscribe(rows => {
      if(rows.result.length > 0)
      {
        console.log(rows)
        this.headerData = rows.result[0];
        console.log(encodeURIComponent(this.headerData));

        // first table
        this.mainTargetService.getMainTargetReport(encodeURIComponent(this.headerData.uuid)).subscribe(rows => {
          // rows.result for SQL data just 'rows' for mock
          this.tableData = this.mainTargetService.convertEntityToModel(rows.result);
          console.log(this.tableData);

          // set main target goal value
          this.mainTargetGoal = this.tableData[0].q0;

          // set number of households tracked values
          this.nhQ1 = +this.tableData[3].q1 + +this.tableData[4].q1 + +this.tableData[5].q1 + +this.tableData[6].q1;
          this.nhQ2 = +this.tableData[3].q2 + +this.tableData[4].q2 + +this.tableData[5].q2 + +this.tableData[6].q2;
          this.nhQ3 = +this.tableData[3].q3 + +this.tableData[4].q3 + +this.tableData[5].q3 + +this.tableData[6].q3;
          this.nhQ4 = +this.tableData[3].q4 + +this.tableData[4].q4 + +this.tableData[5].q4 + +this.tableData[6].q4;

          // set tracking percentages
          this.trackingPercentages = {
            'q1': this.nhQ1 / this.tableData[2].q1 || 0,
            'q2': this.nhQ2 / this.tableData[2].q2 || 0,
            'q3': this.nhQ3 / this.tableData[2].q3 || 0,
            'q4': this.nhQ4 / this.tableData[2].q4 || 0
          };

          // set cumulative tracking percentages
          this.cumulativeTrackingPercentages = {
            'q1': this.nhQ1 / this.tableData[2].q1 || 0,
            'q2': (+this.nhQ1 + +this.nhQ2) / (+this.tableData[2].q1 + +this.tableData[2].q2) || 0,
            'q3': (+this.nhQ1 + +this.nhQ2 + +this.nhQ3) / (+this.tableData[2].q1 + +this.tableData[2].q2 + +this.tableData[2].q3) || 0,
            'q4': (+this.nhQ1 + +this.nhQ2 + +this.nhQ3 + +this.nhQ4) / (+this.tableData[2].q1 + +this.tableData[2].q2 + +this.tableData[2].q3 + +this.tableData[2].q4) || 0
          };

          // set cumulative success rates
          this.cumulativeSuccessRates = {
            'q1': (+this.tableData[3].q1 + +this.tableData[5].q1) / this.nhQ1 || 0,
            'q2': (+this.tableData[3].q2 + +this.tableData[5].q2) / this.nhQ2 || 0,
            'q3': (+this.tableData[3].q3 + +this.tableData[5].q3) / this.nhQ3 || 0,
            'q4': (+this.tableData[3].q4 + +this.tableData[5].q4) / this.nhQ4 || 0
          };

          // set main target results
          this.mainTargetResults = {
            'q1': Math.round(this.cumulativeSuccessRates['q1'] * this.tableData[2].q1) || 0,
            'q2': Math.round(this.cumulativeSuccessRates['q2'] * this.tableData[2].q2) || 0,
            'q3': Math.round(this.cumulativeSuccessRates['q3'] * this.tableData[2].q3) || 0,
            'q4': Math.round(this.cumulativeSuccessRates['q4'] * this.tableData[2].q4) || 0
          };

          // second table
          this.mainTargetService.getMainTargetYtdReport(this.headerData.uuid).subscribe(rows => {
            this.ytdTableData = rows.result;

            this.percentAchievedToDate = (+this.mainTargetResults['q1'] + +this.mainTargetResults['q2'] + +this.mainTargetResults['q3'] + +this.mainTargetResults['q4']) / this.mainTargetGoal;
            console.log(this.ytdTableData);
        });
      });
      }
      else
      {
        console.log('error while main target data')
      }
    });
  }
}