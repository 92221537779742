import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ParamService {

    constructor(
        private activatedRoute: ActivatedRoute,
    ) { }

    getParamPromise(paramName): any {
        var subscription
        return new Promise((resolve, reject) => {
            subscription = this.activatedRoute.queryParams.subscribe(params => {
                resolve(params[paramName])
            })
        }).finally(() => {
            subscription.unsubscribe()
        })
    }
}
