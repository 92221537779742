<div class="container mt-5">
    <div class="row">
        <div class="col" align="center">
            <img src="assets/logo.png" />
        </div>
    </div>
    <div class="row">
        <div class="col" align="center" style="font-weight: bold; color: #117ac9; font-size: 1.2em;">
            <span class="hsny-orange">H</span><span class="hsny-orange">S</span><span class="hsny-purple">N</span><span class="hsny-purple">Y</span><span class="hsny-green">o</span><span class="hsny-green">nline</span>
        </div>
    </div>
</div>
