import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BudgetService } from '../budget.service';
import { SnackRouteService } from '../snack-route.service';

@Component({
  selector: 'app-confirm-create-elements',
  templateUrl: 'confirm-create-elements.component.html',
  styleUrls: ['confirm-create-dialog.component.css']
})
export class ConfirmCreateElements
{
  dialogName: string
  dialogMessage: string
  submissionUuid: string
  confirmCheck: boolean
  sysArea: string
  budgetId: string
  isConfirming: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmCreateElements>,
    public budgetService: BudgetService,
    public snackService: SnackRouteService,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    {
        this.dialogName = data.dialogName,
        this.dialogMessage = data.dialogMessage,
        this.submissionUuid = data.submissionUuid,
        this.sysArea = data.sysArea,
        this.budgetId = data.budgetId
    }

  closeDialog() {
    this.dialog.closeAll()
  }

  confirmDialog()
  {
    if (this.isConfirming) return
    this.isConfirming = true;

    if (this.dialogName === 'budget amendment')
    {
      this.budgetService.amendBudget(this.submissionUuid).subscribe({
        next: (response) => {
          if (response.success === true)
          {
            this.snackService.snack('Budget amendment has been successfully created!');
            this.closeDialog();
          }
        },
        error: (error) => {
          console.error("Error creating budget amendment:", error);
        },
        complete: () => {
          this.isConfirming = false;
        }
      });
    } else if (this.dialogName === 'voucher')
    {
      console.log(this.budgetId, this.submissionUuid);
      this.budgetService.createVoucher(this.budgetId, this.submissionUuid).subscribe({
        next: (response) => {
          if (response.success === true)
          {
            this.snackService.snack('Voucher has been changed to draft state.');
            this.closeDialog();
          }
        },
        error: (error) => {
          console.error("Error creating voucher:", error);
        },
        complete: () => {
          this.isConfirming = false;
        }
      });
    } else
    {
      this.isConfirming = false;
    }
  } 
}