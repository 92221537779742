import { Component, Input } from '@angular/core';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-submission-history',
    templateUrl: './submission-history.component.html',
})
export class SubmissionHistoryComponent {
    faHistory = faHistory
    @Input() submissionHistory

}
